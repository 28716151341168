import React, { useState } from 'react';
import './ItemsUpload.scss';
import { http, AlertService, FxUploader, localstorage } from '../../fx-core';
import { Button, Dialog, Link } from '@material-ui/core';

const ItemsUpload = (props) => {

    const acceptTypeDoc = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const acceptTypeZip = 'application/zip, application/x-zip-compressed, multipart/x-zip';
    const email = localstorage.getItem('email');

    const [open, setOpen] = useState(false);

    const onUploadDocument = (files: any) => {
        console.log('files=', files);
        if (files && files.length > 0) {
            const formData = new FormData()
            formData.append('file', files[0]);

            let apiOptions = { url: `community/${props.communityId}/UploadItems`, data: formData };
            http.post(apiOptions).then((response: any) => {
                console.log('Upload file success');
                onUploadDocumentCallBack(response);
            }).catch(error => {
                console.log('Upload error=', error);
            });
        }
    }

    const onUploadDocumentCallBack = (res) => {
        AlertService.showMsg({ message: 'Items uploaded successfully' });
        handleClose();
    }

    const onUploadZip = (files: any) => {
        console.log('files=', files);
        if (files && files.length > 0) {
            const formData = new FormData()
            formData.append('file', files[0]);

            let apiOptions = { url: `community/${props.communityId}/item/UploadImages`, data: formData };
            http.post(apiOptions).then((response: any) => {
                console.log('Upload zip success');
                onUploadZipCallBack(response);
            }).catch(error => {
                console.log('Upload zip error=', error);
            });
        }
    }

    const onUploadZipCallBack = (res) => {
        AlertService.showMsg({ message: 'Items images uploaded successfully' });
        handleClose();
    }

    const showEmailDialog = () => {
        setOpen(true);
    }

    const handleEmailDialogClose = () => {
        setOpen(false);
    }

    const handleClose = () => {
        props.handleItemsUploadClose();
    }

    return (
        <div className='itemsupload-ui'>
            <div className='row title-font py-1 px-2 drawer-header'>
                <span className="col-11">
                    Upload Items
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>
            <div className='px-5 pt-5 pb-4'>
                <div className='font-weight-bold'>Bulk items upload procedure</div>
                <div>
                    <ol>
                        <li>Email <Link className='pointer' underline="always" onClick={showEmailDialog}>the sample format excel here</Link></li>
                        <li>Fill in item information in appropriate columns</li>
                        <li>Upload the filled-in file</li>
                        <li>Hurrah! new items added</li>
                    </ol>
                </div>
                <div>
                    <FxUploader onUpload={onUploadDocument} acceptType={acceptTypeDoc} />
                </div>
            </div>
            <hr />
            <div className='px-5 pt-4'>
                <div className='font-weight-bold'>Items Images Zip</div>
                <div className='mt-2'>
                    <FxUploader onUpload={onUploadZip} acceptType={acceptTypeZip} />
                </div>
                <div className='ml-2 mt-2'>
                    Zip File content : folder with item images <br />
                    Folder name - ItemCode <br />
                    For Ex: apple/image1.jpg, orange/image2.jpg
                </div>
            </div>

            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={handleEmailDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <SendEmailDialog email={email} communityId={props.communityId} handleClose={handleEmailDialogClose} />
            </Dialog>
        </div >
    )
}

const SendEmailDialog = (props) => {
    const sendEmailTemplate = () => {
        let inputData = {
            email: props.email,
            communityId: props.communityId
        }
        let apiOptions = {
            url: `item/community/sendtemplateemail`,
            data: inputData
        };
        http.post(apiOptions).then((res: any) => {
            sendEmailTemplateCallBack(res);
        });
    };

    const sendEmailTemplateCallBack = (res) => {
        AlertService.showMsg({ message: "Template sent successfully" });
        props.handleClose();
    }

    return (
        <div className="confirmContainer confirm-dialog">
            <div className="row justify-content-between title-font px-3">
                <div className="py-1 pl-3">
                    <span>Item Template</span>
                </div>
                <div className="text-right pointer my-auto">
                    <i className="fas fa-times-circle font-16 pr-3" onClick={props.handleClose}></i>
                </div>
            </div>
            <div className='m-2 pl-3'>
                Email: {props.email}
            </div>
            <div className="row justify-content-end pb-2 pr-4">
                <div>
                    <span className="mr-2">
                        <Button variant="contained" size="small" onClick={props.handleClose}>
                            Cancel
                        </Button>
                    </span>
                    <span>
                        <Button variant="contained" size="small" color="primary" onClick={sendEmailTemplate}>
                            Send Email
                        </Button>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ItemsUpload;