import React, { useState, useEffect } from 'react';
import './ManageAddress.scss';
import { http, AlertService } from '../../fx-core';
import { Button, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { AddressForm } from "../AddressForm/AddressForm";
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import IconPhone from '@material-ui/icons/Phone';

const ManageAddress = (props) => {
    const [addressList, setAddressList] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [customerId, setCustomerId] = useState(props.customerId);
    const [message, setMessage] = useState(null);
    const [selectedItem, setSelectedItem] = React.useState({});
    const [addressId, setAddressId] = useState(null);

    const getAddressList = () => {
        let inputData = {
            id: props.userId
        };
        let apiOptions: any = {
            url: `address/query/${inputData.id}`,
            data: null
        };
        http.post(apiOptions).then(res => {
            getAddressListCallBack(res);
        })
    };

    const getAddressListCallBack = (res) => {
        const result = res.data;
        setAddressList(result);
    };

    const openAddressForm = (id: any) => {
        setAddressId(id);
        setOpen(true);
    };

    const closeAddressForm = () => {
        setOpen(false);
    };

    const confirmDelete = (item: any) => {
        setSelectedItem(item);
        setConfirmOpen(true);
        setMessage("Do you want to delete this address?");
    }

    const handleDialogClose = () => {
        setConfirmOpen(false);
    };

    const onConfirmCallback = () => {
        setConfirmOpen(false);
        deleteAddress(selectedItem);
    }

    const deleteAddress = (item: any) => {
        let inputData = {
            id: item.id,
        };
        let apiOptions: any = {
            url: `address/${inputData.id}`
        };
        http.delete(apiOptions).then(response => {
            AlertService.showSuccessMsg();
            getAddressList();
        })
    };

    const onSaveCallback = () => {
        setOpen(false);
        getAddressList();
    }

    useEffect(() => {
        getAddressList();
    }, []);

    return (
        <div className="customerAddress-ui">

            {/* Desktop View */}
            <div className='deskview'>
                <div className="row py-1 px-1 mx-auto">
                    <div className="col-9 pl-0">
                        <h5>Addresses</h5>
                    </div>
                    <div className="col-3 pr-0 text-right">
                        <Button variant="contained" size="small" color="primary" onClick={() => openAddressForm(0)}>
                            Add New Address
                        </Button>
                    </div>
                </div>
                <div className='my-4'>
                    <TableContainer component={Paper}>
                        <Table className="table-striped" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {addressList.map((row, i) => (
                                    <TableRow>
                                        <TableCell>
                                            {row.addressTypeId === 1 && <span>Home</span>}
                                            {row.addressTypeId === 2 && <span>Office</span>}
                                            {row.addressTypeId === 3 && <span>Address Card</span>}
                                        </TableCell>
                                        <TableCell>{row.fullName}</TableCell>
                                        <TableCell>
                                            <Link href="tel:{{row.mobile}}">
                                                <span>{row.mobile}</span>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <span>{row.fullAddress}</span>
                                            {
                                                row.pinCode && <span className="ml-1"> - {row.pinCode}</span>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='outlined' size='small' className='button-style' onClick={() => openAddressForm(row.id)}>Edit</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='outlined' size='small' className='button-style' onClick={() => confirmDelete(row)}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>


            {/* Tablet View */}
            <div className='tabview'>
                <div className="row py-1 px-1 mx-auto">
                    <div className="col-9 pl-0">
                        <h5>Addresses</h5>
                    </div>
                    <div className="col-3 pr-0 text-right">
                        <Button variant="contained" size="small" color="primary" onClick={() => openAddressForm(0)}>
                            Add New Address
                        </Button>
                    </div>
                </div>
                <div className='my-4'>
                    <TableContainer component={Paper}>
                        <Table className="table-striped" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {addressList.map((row, i) => (
                                    <TableRow>
                                        <TableCell>
                                            {row.addressTypeId === 1 && <span>Home</span>}
                                            {row.addressTypeId === 2 && <span>Office</span>}
                                            {row.addressTypeId === 3 && <span>Address Card</span>}
                                        </TableCell>
                                        <TableCell>{row.fullName}</TableCell>
                                        <TableCell>
                                            <Link href="tel:{{row.mobile}}">
                                                <span>{row.mobile}</span>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <span>{row.fullAddress}</span>
                                            {
                                                row.pinCode && <span className="ml-1"> - {row.pinCode}</span>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='outlined' size='small' className='button-style' onClick={() => openAddressForm(row.id)}>Edit</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='outlined' size='small' className='button-style' onClick={() => confirmDelete(row)}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>


            {/* Mobile View */}
            <div className='mobileview'>
                <div className="row py-1 justify-content-between mx-1 mb-3">
                    <div className="pl-0">
                        <h6>Addresses</h6>
                    </div>
                    <div className="pr-0">
                        <Button variant="contained" size="small" color="primary" className='button-style' onClick={() => openAddressForm(0)}>
                            Add New Address
                        </Button>
                    </div>
                </div>
                {addressList.map((row, i) => (
                    <div>
                        <Card className="mb-3 py-3 px-3">
                            <div className='row justify-content-between pr-2 pl-3'>
                                <div className='font-14 bold-text'>
                                    {row.addressTypeId === 1 && <span>Home</span>}
                                    {row.addressTypeId === 2 && <span>Office</span>}
                                    {row.addressTypeId === 3 && <span>Address Card</span>}
                                </div>
                                <div className='row pr-4'>
                                    <div><i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row)}></i></div>
                                    <div className='pl-4 ml-2'><i className="fas fa-pen font-13" onClick={() => openAddressForm(row.id)}></i></div>
                                </div>
                            </div>
                            <div className='font-13'>
                                <div>{row.fullName}</div>
                                <div className='py-1'>
                                    <span>{row.fullAddress}</span>
                                    {
                                        row.pinCode && <span className="ml-1"> - {row.pinCode}</span>
                                    }
                                </div>
                                <div>
                                    <Link href="tel:{{row.mobile}}">
                                        <span>{row.mobile}</span>
                                    </Link>
                                </div>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>

            <div>
                <Dialog
                    open={open}
                    maxWidth="sm"  
                    fullWidth                  
                    onClose={closeAddressForm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <AddressForm userId={props.userId} addressId={addressId}
                        closeAddressForm={closeAddressForm} onSaveCallback={onSaveCallback} />
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={confirmOpen}
                    maxWidth="sm"
                    fullWidth
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
                </Dialog>
            </div>
        </div>
    );
}

export default ManageAddress;