import { applyMiddleware, createStore, combineReducers, Action } from "redux";
import thunk from "redux-thunk";

import { logger } from "./middleware/LoggerMiddleware";
import { localstorage } from "../helpers/localStorageService";

const navDataReducer = (state: any = {}, action: IAppAction): any => {
  console.log("inside navDataReducer", state, action);
  switch (action.type) {
    case "nav-push":
      let draftHead = null;
      if (state.navStack.length > 0 && action.payload.sourceState) {
        let head = state.navStack[state.navStack.length - 1];
        draftHead = { ...head, ...{ pageState: action.payload.sourceState } };
      }
      if (draftHead) {
        const draftNavStack = state.navStack.filter(
          (e: any, idx: number) => idx < state.navStack.length - 1
        );
        return {
          ...state,
          ...{ navStack: [...draftNavStack, draftHead, action.payload] },
        };
      } else {
        return {
          ...state,
          ...{ navStack: [...state.navStack, action.payload] },
        };
      }
    case "nav-goTo":
      const index = action.payload.index;
      const draftNavStack = state.navStack.filter(
        (e: any, idx: number) => idx < index + 1
      );
      return { ...state, ...{ navStack: draftNavStack } };
    case "nav-pop":
      if (state.navStack.length > 0) {
        let index = state.navStack.length - 1;
        const draftNavStack = state.navStack.filter(
          (e: any, idx: number) => idx < index
        );
        return { ...state, ...{ navStack: draftNavStack } };
      }
      return state;
    case "nav-clear":
      state.navStack = [];
      return state;
    default:
      return state;
  }
};

const dialogReducer = (state: any = {}, action: IAppAction): any => {
  console.log("inside dialogReducer", state, action);
  switch (action.type) {
    case "dialog-open":
      let dialogParams = { ...action.payload, ...{ isOpen: true } };
      return {
        ...state,
        ...{
          dialogMap: { ...state.dialogMap, [dialogParams.key]: dialogParams },
        },
        latestDialogKey: dialogParams.key,
      };
    case "dialog-close":
      let dialogParamsClose = state.dialogMap[action.payload.key];
      dialogParamsClose = { ...dialogParamsClose, ...{ isOpen: false } };
      return {
        ...state,
        ...{
          dialogMap: {
            ...state.dialogMap,
            [action.payload.key]: dialogParamsClose,
          },
        },
        latestDialogKey: "",
      };
    default:
      return state;
  }
};

const drawerReducer = (state: any = {}, action: IAppAction): any => {
  console.log("inside drawerReducer", state, action);
  switch (action.type) {
    case "drawer-open":
      let drawerParams = { ...action.payload, ...{ isOpen: true } };
      return {
        ...state,
        ...{
          drawerMap: { ...state.drawerMap, [drawerParams.key]: drawerParams },
        },
        latestDrawerKey: drawerParams.key,
      };
    case "drawer-close":
      let drawerParamsClose = state.drawerMap[action.payload.key];
      drawerParamsClose = { ...drawerParamsClose, ...{ isOpen: false } };
      return {
        ...state,
        ...{
          drawerMap: {
            ...state.drawerMap,
            [action.payload.key]: drawerParamsClose,
          },
        },
        latestDrawerKey: "",
      };
    default:
      return state;
  }
};

const communityReducer = (state: any = {}, action: IAppAction): any => {
  console.log("inside communityReducer", state, action);
  switch (action.type) {
    case "set-community-details":
      let community = action.payload;
      if (community.id > 0) {
        localstorage.setItem("communityId", community.id);
        localstorage.setItem("communityCode", community.code);
      }
      return { ...state, ...action.payload };
      case "clear-community-details":
          localstorage.removeItem("communityId");
          localstorage.removeItem("communityCode");
        return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const loginReducer = (state: any = {}, action: IAppAction): any => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state, ...action.payload,
      };
    case "LOGOUT":
      localstorage.removeItem('userId');
      return {
        ...state, ...action.payload,
      };
    default:
      return state
  }
}

export const cartReducer = (state: any = {}, action: IAppAction) => {
  switch (action.type) {
    case "UPDATE_CART":
      let totalAmountVal = 0;
      let prodList = action.payload.items;
      let stallId = action.payload.stallId;
      for (let item of prodList) {
        totalAmountVal = totalAmountVal + item.totalPrice;
      }
      let cartDataObj = {
        items: prodList,
        cartCount: prodList.length,
        totalAmount: totalAmountVal,
        stallId: stallId
      }
      return {
        ...state, ...cartDataObj,
      };
    case "ADD_CART":
      let total = 0;
      let totalQuantity = 0;
      let isProdExists = false;
      let items: any = Object.assign([], state.items);
      let prod = action.payload.item;
      let stallIdVal = action.payload.stallId;
      if (state.stallId > 0 && stallIdVal != state.stallId) {
        items = []; // If stall changed then previous stall items not be considered
      }
      for (let item of items) {
        if (prod.isCustomized && item.uid === prod.uid) {
          item.quantity += 1;
          item.modifierAmt = item.modifierAmt > 0 ? (item.quantity * item.modifierAmt) : 0;
          item.totalPrice = (item.quantity * item.price) + item.modifierAmt;
          isProdExists = true;
        }
        if (!prod.isCustomized && item.id == prod.id) {
          item.quantity += 1;
          item.modifierAmt = item.modifierAmt > 0 ? (item.quantity * item.modifierAmt) : 0;
          item.totalPrice = (item.quantity * item.price) + item.modifierAmt;
          isProdExists = true;
        }
      }
      if (!isProdExists) {
        prod.quantity = 1;
        prod.modifierAmt = prod.modifierAmt > 0 ? (prod.quantity * prod.modifierAmt) : 0;
        prod.totalPrice = (prod.quantity * prod.price) + prod.modifierAmt;
        items.push(prod);
      }
      for (let item of items) {
        total = total + item.totalPrice;
        totalQuantity = totalQuantity + item.quantity;
      }
      let cartObj = {
        items: items,
        cartCount: totalQuantity,
        totalAmount: total,
        stallId: stallIdVal
      }
      return {
        ...state, ...cartObj
      };
    case "REDUCE_CART":
      let totalVal = 0;
      let totalQty = 0;
      let removeItemId = 0;
      let removeCusItemUid = "";
      let cartItems: any = Object.assign([], state.items);
      let product = action.payload.item;
      let stallIdValue = action.payload.stallId;
      for (let item of cartItems) {
        if (product.isCustomized && item.uid === product.uid) {
          item.quantity -= 1;
          if (item.quantity <= 0) {
            removeCusItemUid = product.uid;
          }
        }
        if (!product.isCustomized && item.id === product.id) {
          item.quantity -= 1;
          if (item.quantity <= 0) {
            removeItemId = product.id;
          }
        }
      }
      if (removeItemId > 0) {
        cartItems = cartItems.filter((i: { id: number; }) => i.id !== removeItemId);
      }
      if (removeCusItemUid.length > 0) {
        cartItems = cartItems.filter((i: { uid: any; }) => i.uid !== removeCusItemUid);
      }
      for (let item of cartItems) {
        item.modifierAmt = item.modifierAmt > 0 ? (item.quantity * item.modifierAmt) : 0;
        item.totalPrice = (item.quantity * item.price) + item.modifierAmt;
        totalVal = totalVal + item.totalPrice;
        totalQty = totalQty + item.quantity;
      }
      let updatedCartObj = {
        items: cartItems,
        cartCount: totalQty,
        totalAmount: totalVal,
        stallId: stallIdValue
      }
      return {
        ...state, ...updatedCartObj,
      };
    case "CLEAR_CART":
      let cartObject = {
        items: [],
        cartCount: 0,
        totalAmount: 0
      }
      return {
        ...state, ...cartObject,
      };
    default:
      return state
  }
}

const listingCartReducer = (state: any = {}, action: IAppAction) => {
  switch (action.type) {
    case "ADD_LISTING_CART":
      let total = 0;
      let isProdExists = false;
      let items: any = Object.assign([], state.items);
      let prod = action.payload.item;
      let stallIdVal = action.payload.stallId;
      let saleIdVal = prod.saleId;
      if (state.stallId > 0 && stallIdVal != state.stallId) {
        items = []; // If stall changed then previous stall items not be considered
      }
      if (state.saleId > 0 && saleIdVal != state.saleId) {
        items = []; // If sale id changed then previous sale items not be considered
      }
      for (let item of items) {
        if (item.id == prod.id) {
          item.quantity += 1;
          item.totalPrice = (item.quantity * item.price);
          isProdExists = true;
        }
      }
      if (!isProdExists) {
        prod.quantity = 1;
        prod.totalPrice = (prod.quantity * prod.price);
        items.push(prod);
      }
      for (let item of items) {
        total = total + item.totalPrice;
      }
      let cartObj = {
        items: items,
        cartCount: items.length,
        totalAmount: total,
        stallId: stallIdVal,
        saleId: saleIdVal
      }
      return {
        ...state, ...cartObj
      };
    case "REDUCE_LISTING_CART":
      let totalVal = 0;
      let removeItemId = 0;
      let cartItems: any = Object.assign([], state.items);
      let product = action.payload.item;
      let stallIdValue = action.payload.stallId;
      for (let item of cartItems) {
        if (item.id === product.id) {
          item.quantity -= 1;
          if (item.quantity <= 0) {
            removeItemId = product.id;
          }
        }
      }
      if (removeItemId > 0) {
        cartItems = cartItems.filter((i: { id: number; }) => i.id !== removeItemId);
      }
      for (let item of cartItems) {
        item.totalPrice = (item.quantity * item.price);
        totalVal = totalVal + item.totalPrice;
      }
      let updatedCartObj = {
        items: cartItems,
        cartCount: cartItems.length,
        totalAmount: totalVal,
        stallId: stallIdValue
      }
      return {
        ...state, ...updatedCartObj,
      };
    case "CLEAR_LISTING_CART":
      let cartObject = {
        items: [],
        cartCount: 0,
        totalAmount: 0,
        stallId: 0,
        saleId: 0
      }
      return {
        ...state, ...cartObject,
      };
    default:
      return state
  }
}

const chatReducer = (state: any = {}, action: IAppAction): any => {
  console.log("inside chatReducer", state, action);
  switch (action.type) {
    case "LATEST_CHAT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  navData: navDataReducer,
  dialogInfo: dialogReducer,
  drawerInfo: drawerReducer,
  community: communityReducer,
  login: loginReducer,
  cart: cartReducer,
  listingCart: listingCartReducer,
  chat: chatReducer
});
const initialState = {
  navData: {
    navStack: [],
  },
  dialogInfo: {
    latestDialogKey: "",
    dialogMap: {},
  },
  drawerInfo: {
    latestDrawerKey: "",
    drawerMap: {},
  },
  community: {
  },
  login: {
    isLogin: false
  },
  cart: {
    items: []
  },
  listingCart: {
    items: []
  },
  chat: {
    message: {}
  }
};

function configureStore(rootReducer?: any, preloadedState?: any) {
  const middlewares = [logger, thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rootReducer, preloadedState, middlewareEnhancer);
  return store;
}

export interface IAppAction extends Action<string> {
  payload?: any;
}

export const store = configureStore(rootReducer, initialState);
