import React, { useEffect, useState } from 'react';
import './FeatureCouponList.scss';
import { http, AlertService, FxCurrency, FxDateDisplay, useNavigationService } from '../../fx-core';
import { Dialog, Drawer, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab, Link } from '@material-ui/core';
import _ from "lodash";
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import FeatureCouponForm from '../FeatureCouponForm/FeatureCouponForm';
import ShareOptions from '../../LocalDrCard/ShareOptions/ShareOptions';
import { NoRecords } from '../../NoRecords/NoRecords';
import share from "../../../assets/share.png";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const FeatureCouponList = (props) => {
  const { routeData, back } = useNavigationService();
  const classes = styles();
  const [featureCoupons, setFeatureCoupons] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedId, setSelectedId] = useState(0);
  const [shareOpen, setShareOpen] = useState(false);

  const getFeatureBasedCoupons = () => {
    let inputData = {
      communityId: routeData.communityId
    };
    let apiOptions: any = {
      url: `coupon/feature/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setFeatureCoupons(result);
    });
  };

  const addOrEditCouponForm = (id) => {
    setSelectedId(id);
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setSelectedId(0);
    getFeatureBasedCoupons();
  };

  const confirmDelete = (item) => {
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + item.couponCode + "?");
    setSelectedId(item.id);
  };

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setSelectedId(0);
    setMessage("");
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    deleteCoupon();
  }

  const deleteCoupon = () => {
    let apiOptions: any = {
      url: `coupon/${selectedId}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showMsg({ message: "Deleted successfully" });
      setSelectedId(0);
      setMessage("");
      getFeatureBasedCoupons();
    })
  };

  const openShareDialog = (event: any, id: any) => {
    setSelectedId(id);
    setShareOpen(true);
    event.stopPropagation();
  }

  const closeShareDialog = () => {
    setSelectedId(0);
    setShareOpen(false);
  };

  const navToBack = () => {
    back();
  };

  useEffect(() => {
    getFeatureBasedCoupons();
  }, []);

  return (
    <div className='stallcouponlist-ui pt-4 mt-3'>    
         <div className="title-font custom-head pl-4 pt-5">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                <b>{routeData.communityName}</b>
              </Link>
              <Typography color="primary" className="breadcrumbs-style">Coupons</Typography>
            </Breadcrumbs>
          </div>
      <div className='content-container'>
        <div className="content">       
          <div className="mt-4 px-4 pt-2">
            <span className='d-flex justify-content-end pr-3 pb-3'>
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={() => addOrEditCouponForm(0)}
              >
                <i className="fas fa-plus pr-2"></i>
                Add
              </Fab>
            </span>
          </div>
          <div className='px-3 pb-3 pt-1'>
            <div className='mb-4 mx-4 pb-5'>
              <TableContainer component={Paper}>
                <Table stickyHeader size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Valid</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {featureCoupons.length == 0 &&
                    <TableCell colSpan={5}>
                      <NoRecords />
                    </TableCell>
                  }
                  {featureCoupons.length > 0 && featureCoupons.map((row: any, i) => (
                    <TableBody key={i}>
                      <TableRow>
                        <TableCell>
                          <span>{row.couponCode}</span>
                        </TableCell>
                        <TableCell>
                          <span>{row.couponValueType}</span>
                        </TableCell>
                        <TableCell>
                          <span><FxCurrency value={row.couponValue} /></span>
                        </TableCell>
                        <TableCell>
                          <span><FxDateDisplay value={row.validFrom} /></span> -
                          <span className='ml-1'><FxDateDisplay value={row.validTo} /></span>
                        </TableCell>
                        <TableCell>
                          <div>
                            <span className='pointer'><i className="fas fa-pencil-alt font-13" onClick={() => addOrEditCouponForm(row.id)}></i></span>
                            <span className='ml-3 pointer'><i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row)}></i></span>
                            <span className='ml-3 pointer' onClick={(e) => openShareDialog(e, row.id)}>
                              <img src={share} height="20px" className='pointer icon-style-share' />
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={formOpen} onClose={handleFormClose}>
        <FeatureCouponForm id={selectedId} communityId={routeData.communityId} handleClose={handleFormClose} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
      <Dialog
        open={shareOpen}
        maxWidth="sm"
        fullWidth
        onClose={closeShareDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ShareOptions closeShareDialog={closeShareDialog} couponId={selectedId} />
      </Dialog>
    </div>
  );
}
export default FeatureCouponList;