import React, { useEffect, useState } from 'react';
import './StallHeader.scss';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import { http, general, formatService, localstorage, AlertService } from '../../fx-core';
import dummyImage from "./../../../assets/images/dummy.png";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clicking from '../../../assets/clicking.svg';
import { Drawer, makeStyles } from '@material-ui/core';
import MessageContainer from '../../MessageContainer/MessageContainer';
import { useSelector } from 'react-redux';
import LoginContainer from '../../LoginContainer/LoginContainer';
import verifyTick from "./../../../assets/img/verifytick.png";
import notliked from '../../../assets/notliked.png';
import liked from '../../../assets/liked.png';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const StallHeader = (props: any) => {
  const classes = styles();
  let defaultItem = {
    name: "",
    website: "",
    address: "",
    stallOpenStatus: "",
    providerMobile: "",
    providerEmail: "",
    isDeliveryEnabled: false,
    isPickupEnabled: false,
    imageUrl: "",
    id: 0,
    isVerified: false
  };
  let defaultFollowItem = {
    userId: 0,
    id: 0,
    entityId: 0,
    entityTypeId: 0,
    isFollow: false
  };
  const login = useSelector<any, any>(s => s.login);
  const [item, setItem] = useState({ stall: defaultItem, categories: [] });
  const [followItem, setFollowItem] = useState(defaultFollowItem);
  const [qrcode, setQrcode] = useState(null);
  const [linkUrl, setLinkUrl] = useState(null);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [userId, setUserId] = useState(localstorage.getItem("userId") ? parseInt(localstorage.getItem("userId")) : 0);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [currentContext, setCurrentContext] = useState({ followedUsers: 0 });

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const toggleDrawerLogin = () => {
    setOpenLogin(!openLogin);
  };

  const getStallDetailsById = () => {
    let inputData = {
      stallId: props.stallId,
      currentDate: formatService.getCurrentDateForServer()
    };
    let apiOptions: any = {
      url: `portal/stall/${props.stallId}/withcategory/serviceIds`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      result.stall.imageUrl = general.getImageUrl(result.stall.imagePath);
      setItem(result);
      setLinkUrl(result.linkUrl);
      props.callBackData({
        linkUrl: result.linkUrl
      });
    });
  }

  const getStallQrCode = () => {
    let apiOptions: any = {
      url: `portal/stall/${props.stallId}/qrcode`,
      method: 'get'
    };

    apiOptions.callback = viewImage;
    http.view(apiOptions)
  };

  const viewImage = (res: any) => {
    let qrCode = res.base64data;
    setQrcode(qrCode);
  }

  const openLinkUrl = () => {
    window.open(linkUrl, '_blank');
  }

  const navToMessage = () => {
    if (login.isLogin && userId) {
      setOpenMessage(true);
    }
    else {
      AlertService.showMsg({ message: "Please login to chat" });
      navToLogin();
      return;
    }
  }

  const navToLogin = () => {
    setOpenLogin(true);
  }

  const handleLoginClose = () => {
    setOpenLogin(false);
  }

  const onLoginCallBack = () => {
    handleLoginClose();
  }

  const handleMessageClose = () => {
    setOpenMessage(false);
  }

  const getFollowInfoByUserId = () => {
    let loggedInUserId = userId;
    if (login.isLogin) {
      loggedInUserId = parseInt(localstorage.getItem("userId"));
      setUserId(loggedInUserId);
    }
    let inputData = {
      userId: loggedInUserId > 0 ? loggedInUserId : 0,
      entityId: props.stallId
    };
    let apiOptions: any = {
      url: `portal/follow/query`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      if (result && result.follow) {
        let folloObj = result.follow;
        folloObj.isFollow = folloObj.isFollow > 0 ? true : false;
        setFollowItem(folloObj);
      }
      const data = { followedUsers: result.followedUsers };
      setCurrentContext(prevState => {
        return { ...prevState, ...data }
      });
    });
  }

  const saveFollow = () => {
    if (login.isLogin && userId) {
      if (followItem.id == 0) {
        addFollow();
      }
      else if (followItem.id > 0) {
        if (followItem.isFollow) {
          unFollowStall();
        }
        else {
          followStall();
        }
      }
    }
    else {
      AlertService.showMsg({ message: "Please login to follow" });
      navToLogin();
      return;
    }
  }

  const addFollow = () => {
    followItem.entityId = props.stallId;
    let apiOptions: any = {
      url: `follow/add`,
      data: followItem
    };
    http.post(apiOptions).then(response => {
      saveFollowCallBack();
    });
  }

  const followStall = () => {
    let apiOptions: any = {
      url: `follow/${followItem.id}/updatefollow`,
      data: null
    };
    http.put(apiOptions).then(response => {
      saveFollowCallBack();
    });
  }

  const unFollowStall = () => {
    let apiOptions: any = {
      url: `follow/${followItem.id}/updateunfollow`,
      data: null
    };
    http.put(apiOptions).then(response => {
      saveFollowCallBack();
    });
  }

  const saveFollowCallBack = () => {
    getFollowInfoByUserId();
  }

  const openStallWebsite = () => {
    let url = item.stall.website;
    if (url)
      window.open(url, '_blank');
  }

  useEffect(() => {
    getStallDetailsById();
    getStallQrCode();
    getFollowInfoByUserId();
  }, [login]);

  return (
    <div className="pt-4 stallheader-ui">

      {/* Desktop View */}
      <div className="row deskview pb-2">
        <div className="pl-3">
          {/* <img src={shop1} alt="Item" width="250" height="200" className="itemimage" /> */}
          {!item.stall.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
          {item.stall.imageUrl && <img src={item.stall.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
        </div>
        <div className="d-flex flex-column justify-content-between pl-4 ml-2 pb-2 col-6">
          <div className="d-flex">
            <span className='font-30'><b>{item.stall.name}</b>
              {
                item.stall.isVerified &&
                <span className='ml-1'>
                  <img src={verifyTick} width="20" height="20" />
                </span>
              }
              <span className="fans-style ml-2">
                <img src={liked} height="15" className='pr-1' />
                {currentContext.followedUsers} True Fans
              </span>
            </span>
          </div>
          <div>
            <div className="font-15 addresscolor">
              {
                item.categories && item.categories.length > 0 && item.categories.map((category, i) => (
                  <span className="pr-2">
                    {category}
                  </span>
                ))
              }
            </div>
            <div className="font-15 addresscolor">
              {item.stall.address}
            </div>
          </div>

          <div className="row justify-content-between my-3 ml-1">
            {
              item.stall.website &&
              <div className="text-center col-2 stall-info-card py-1 pointer" onClick={openStallWebsite}>
                <i className="fas fa-globe mt-2 font-18"></i>
              </div>
            }
            {
              !item.stall.website &&
              <div className="text-center col-2 stall-info-card py-1 disable-link-color">
                <i className="fas fa-globe mt-2 font-18"></i>
              </div>
            }
            {
              item.stall.providerMobile &&
              <div className="text-center col-2 stall-info-card py-1 pointer" title={item.stall.providerMobile}>
                <a className="link-color ml-1">
                  <i className="fas fa-phone mt-2 font-18"></i>
                </a>
              </div>
            }
            {
              !item.stall.providerMobile &&
              <div className="text-center col-2 stall-info-card py-1">
                <a className="disable-link-color ml-1">
                  <i className="fas fa-phone mt-2 font-18"></i>
                </a>
              </div>
            }
            {
              item.stall.providerEmail &&
              <div className="text-center col-2 stall-info-card py-1 pointer">
                <a className="link-color ml-1" href={'mailto:' + item.stall.providerEmail}>
                  <i className="fas fa-envelope mt-2 font-18"></i>
                </a>
              </div>
            }
            {
              !item.stall.providerEmail &&
              <div className="text-center col-2 stall-info-card py-1">
                <a className="disable-link-color ml-1">
                  <i className="fas fa-envelope mt-2 font-18"></i>
                </a>
              </div>
            }
            <div className="text-center stall-info-card col-2 py-1 pointer" onClick={navToMessage} >
              <i className="fas fa-paper-plane mt-2 font-18"></i>
            </div>
            <div className="text-center stall-info-card col-2 py-1 pointer" onClick={saveFollow} >
              {!followItem.isFollow &&
                <span><img src={notliked} className='mt-2' /></span>
              }
              {followItem.isFollow &&
                <span><img src={liked} className='mt-2' /></span>
              }
            </div>
            <div className="col-2"></div>
          </div>

          {/* <div className='d-flex align-items-center'>
            <div className="d-flex pr-3 font-14">
              <span><i className="fas fa-phone-alt"></i></span>
              {item.stall.providerMobile &&
                <span className="pl-1 font-13">{item.stall.providerMobile}</span>
              }
              {!item.stall.providerMobile &&
                <span className="pl-1 font-13 pr-4">-----</span>
              }
            </div>


            <div className="d-flex pr-3 font-14">
              <span><i className="fas fa-envelope"></i></span>
              {item.stall.providerEmail &&
                <span className="pl-1 font-13">{item.stall.providerEmail}</span>
              }
              {!item.stall.providerEmail &&
                <span className="pl-1 font-13 pr-4">-----</span>
              }
            </div>


            <div className="d-flex font-14 pr-3">
              <span><i className="fas fa-globe"></i></span>
              {item.stall.website &&
                <span className='pl-1 font-13'>{item.stall.website}</span>
              }
              {!item.stall.website &&
                <span className="pl-1 font-13 pr-4">-----</span>
              }
            </div>

            <div className="font-14 pr-3 float-right">
              <span onClick={navToMessage}>
                <i className="fas fa-paper-plane font-14 text-success pointer"></i>
                <span className='text-success font-13 pl-1 pointer'>Direct</span>
              </span>
            </div>
          </div> */}

          <div className="d-flex align-items-center">
            <div className="d-flex pr-5 pl-1">
              <span className="text-success font-13"><b>{item.stall.stallOpenStatus}</b></span>
            </div>
            <div className="d-flex font-13 pr-5">
              {item.stall.isDeliveryEnabled &&
                <span>
                  Delivery
                </span>
              }
              {item.stall.isDeliveryEnabled && item.stall.isPickupEnabled && <span className='pr-1'>,</span>}
              {item.stall.isPickupEnabled &&
                <span>
                  Pickup
                </span>
              }
            </div>
            {/* <div className="d-flex" onClick={saveFollow}>
              {!followItem.id &&
                <span className='pointer'>
                  <span> <img src={notliked} /></span>
                  <span className='pl-1 font-13'>Follow</span>
                </span>
              }
              {
                followItem.id > 0 &&
                <span className='pointer'>
                  {!followItem.isFollow &&
                    <span>
                      <span> <img src={notliked} /></span>
                      <span className='pl-1 font-13'>Follow</span>
                    </span>
                  }
                  {followItem.isFollow &&
                    <span>
                      <span> <img src={liked} /></span>
                      <span className='pl-1 font-13'>UnFollow</span>
                    </span>
                  }
                </span>
              }
            </div> */}
          </div>

        </div>
        <div className="col-4 text-right pr-0">
          <span className="pr-4 font-20"><b>Scan to Order </b> <span><ArrowForwardIcon /></span></span>
          <img src={qrcode} alt="Qrcode Image" width="150" height="150" />
        </div>
      </div>

      {/* Tablet View */}
      <div className="row tabview pl-3 pb-2">
        <div className="pl-4 col-2" onClick={openLinkUrl}>
          <div className="pt-1 text-center">
            {!item.stall.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
            {item.stall.imageUrl && <img src={item.stall.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
          </div>
          <div className="font-15 text-center py-1 color-primary">
            <img src={clicking} alt="thumb" width="17" height="17"></img>
            <b><u>To Order</u></b>
          </div>
        </div>
        <div className="pb-2 col-10 pr-4 pl-0">
          <div>
            <div className="font-22">
              <b>{item.stall.name}</b>
              {
                item.stall.isVerified &&
                <span className='ml-1'>
                  <img src={verifyTick} width="20" height="20" />
                </span>
              }
              <span className="fans-style ml-2">
                <img src={liked} height="15" className='pr-1' />
                {currentContext.followedUsers} True Fans
              </span>
            </div>


            <div className="row font-15 pl-3 pb-1">
              {item.stall.isDeliveryEnabled &&
                <span>
                  Delivery
                </span>
              }
              {item.stall.isDeliveryEnabled && item.stall.isPickupEnabled && <span>,</span>}
              {item.stall.isPickupEnabled &&
                <span>
                  Pickup
                </span>
              }
            </div>
            <div>

              <div className="font-15 addresscolor">
                {
                  item.categories && item.categories.length > 0 && item.categories.map((category, i) => (
                    <span className="pr-2">
                      {category}
                    </span>
                  ))
                }
              </div>
              <div className="font-15 addresscolor text-truncate pt-1">
                {item.stall.address}
              </div>
            </div>

            <div className="row justify-content-between mt-3 mb-1 ml-0">
              {
                item.stall.website &&
                <div className="text-center col-2 stall-info-card py-1 pointer" onClick={openStallWebsite}>
                  <i className="fas fa-globe mt-2 font-18"></i>
                </div>
              }
              {
                !item.stall.website &&
                <div className="text-center col-2 stall-info-card py-1 disable-link-color">
                  <i className="fas fa-globe mt-2 font-18"></i>
                </div>
              }
              {
                item.stall.providerMobile &&
                <div className="text-center col-2 stall-info-card py-1 pointer" title={item.stall.providerMobile}>
                  <a className="link-color ml-1">
                    <i className="fas fa-phone mt-2 font-18"></i>
                  </a>
                </div>
              }
              {
                !item.stall.providerMobile &&
                <div className="text-center col-2 stall-info-card py-1">
                  <a className="disable-link-color ml-1">
                    <i className="fas fa-phone mt-2 font-18"></i>
                  </a>
                </div>
              }
              {
                item.stall.providerEmail &&
                <div className="text-center col-2 stall-info-card py-1 pointer">
                  <a className="link-color ml-1" href={'mailto:' + item.stall.providerEmail}>
                    <i className="fas fa-envelope mt-2 font-18"></i>
                  </a>
                </div>
              }
              {
                !item.stall.providerEmail &&
                <div className="text-center col-2 stall-info-card py-1">
                  <a className="disable-link-color ml-1">
                    <i className="fas fa-envelope mt-2 font-18"></i>
                  </a>
                </div>
              }
              <div className="text-center stall-info-card col-2 py-1 pointer" onClick={navToMessage} >
                <i className="fas fa-paper-plane mt-2 font-18"></i>
              </div>
              <div className="text-center stall-info-card col-2 py-1 pointer" onClick={saveFollow} >
                {!followItem.isFollow &&
                  <span><img src={notliked} className='mt-2' /></span>
                }
                {followItem.isFollow &&
                  <span><img src={liked} className='mt-2' /></span>
                }
              </div>
              <div className="col-2"></div>
            </div>


            {/* <div className="row justify-content-between px-3">
              <div className="font-15">
                <span> <PhoneIcon fontSize="inherit" /></span>
                <span className="pl-1">{item.stall.providerMobile}</span>
              </div>
              <div className="font-15">
                <span> <EmailIcon fontSize="inherit" /></span>
                <span className="pl-1">{item.stall.providerEmail}</span>
              </div>
            </div> */}

            {/* <div className="row pl-3">
              <div className="col-9 d-flex pl-0">
                {item.stall.website &&
                  <span>{item.stall.website}</span>
                }
              </div>
              <div className="col-3">
                <div className="row font-14 pr-0 float-right" onClick={saveFollow}>
                  {!followItem.id &&
                    <span className='pointer float-right'>
                      <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                      <span className='pl-1 pr-3 font-13'>Follow</span>
                    </span>
                  }
                  {
                    followItem.id > 0 &&
                    <span className='pointer float-right'>
                      {!followItem.isFollow &&
                        <span>
                          <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                          <span className='pl-1 pr-3 font-13'>Follow</span>
                        </span>
                      }
                      {followItem.isFollow &&
                        <span>
                          <span> <i className="fas fa-thumbs-down font-14 pointer"></i></span>
                          <span className='pl-1 pr-3 font-13'>UnFollow</span>
                        </span>
                      }
                    </span>
                  }
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="row mobileview pl-3 pb-1">
        <div className="pl-4 col-3" onClick={openLinkUrl}>
          <div className="pt-1">
            {!item.stall.imageUrl && <img src={dummyImage} alt="Item" width="70" height="70" className="itemimage"></img>}
            {item.stall.imageUrl && <img src={item.stall.imageUrl} alt="Item" width="70" height="70" className="itemimage"></img>}
          </div>
          <div className="font-13 text-center pt-1 color-primary">
            <img src={clicking} alt="thumb" width="17" height="17"></img>
            <b><u>To Order</u></b>
          </div>
        </div>
        <div className="pb-2 col-9 pr-4 pl-0">
          <div>
            <div className="font-19">
              <b>{item.stall.name}</b>
              {
                item.stall.isVerified &&
                <span className='ml-1'>
                  <img src={verifyTick} width="20" height="20" />
                </span>
              }
              <span className="fans-style ml-2">
                <img src={liked} height="15" className='pr-1' />
                {currentContext.followedUsers} True Fans
              </span>
            </div>
            <div className="row font-12 pl-3">
              {item.stall.isDeliveryEnabled &&
                <span>
                  Delivery
                </span>
              }
              {item.stall.isDeliveryEnabled && item.stall.isPickupEnabled && <span>,</span>}
              {item.stall.isPickupEnabled &&
                <span>
                  Pickup
                </span>
              }
            </div>
            <div>
              <div className="font-12 addresscolor text-truncate">
                {
                  item.categories && item.categories.length > 0 && item.categories.map((category, i) => (
                    <span className="pr-2">
                      {category}
                    </span>
                  ))
                }
              </div>
              <div className="font-12 addresscolor text-truncate">
                {item.stall.address}
              </div>
            </div>

            <div className="row justify-content-between mt-3 mb-1 ml-0">
              {
                item.stall.website &&
                <div className="text-center col-2 stall-info-card py-1 pointer" onClick={openStallWebsite}>
                  <i className="fas fa-globe mt-2 font-18"></i>
                </div>
              }
              {
                !item.stall.website &&
                <div className="text-center col-2 stall-info-card py-1 disable-link-color">
                  <i className="fas fa-globe mt-2 font-18"></i>
                </div>
              }
              {
                item.stall.providerMobile &&
                <div className="text-center col-2 stall-info-card py-1 pointer" title={item.stall.providerMobile}>
                  <a className="link-color ml-1">
                    <i className="fas fa-phone mt-2 font-18"></i>
                  </a>
                </div>
              }
              {
                !item.stall.providerMobile &&
                <div className="text-center col-2 stall-info-card py-1">
                  <a className="disable-link-color ml-1">
                    <i className="fas fa-phone mt-2 font-18"></i>
                  </a>
                </div>
              }
              {
                item.stall.providerEmail &&
                <div className="text-center col-2 stall-info-card py-1 pointer">
                  <a className="link-color ml-1" href={'mailto:' + item.stall.providerEmail}>
                    <i className="fas fa-envelope mt-2 font-18"></i>
                  </a>
                </div>
              }
              {
                !item.stall.providerEmail &&
                <div className="text-center col-2 stall-info-card py-1">
                  <a className="disable-link-color ml-1">
                    <i className="fas fa-envelope mt-2 font-18"></i>
                  </a>
                </div>

              }
              <div className="text-center stall-info-card col-2 py-1 pointer" onClick={navToMessage} >
                <i className="fas fa-paper-plane mt-2 font-18"></i>
              </div>
              <div className="text-center stall-info-card col-2 py-1 pointer" onClick={saveFollow} >
                {!followItem.isFollow &&
                  <span><img src={notliked} className='mt-2' /></span>
                }
                {followItem.isFollow &&
                  <span><img src={liked} className='mt-2' /></span>
                }
              </div>
              <div className="col-2"></div>
            </div>

            {/* <div className="row justify-content-between px-3">
              <div className="font-12">
                <span> <PhoneIcon fontSize="inherit" /></span>
                <span className="pl-1">{item.stall.providerMobile}</span>
              </div>
              <div className="font-12">
                <span> <EmailIcon fontSize="inherit" /></span>
                <span className="pl-1">{item.stall.providerEmail}</span>
              </div>
            </div> */}

            {/* <div className="row justify-content-between pl-3">
              <div className="col-8 d-flex pl-0">
                {item.stall.website &&
                  <span>{item.stall.website}</span>
                }
              </div>
              <div className="col-4">
                <div className="row font-14 pr-0 float-right" onClick={saveFollow}>
                  {!followItem.id &&
                    <span className='pointer float-right'>
                      <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                      <span className='pl-1 pr-3 font-13'>Follow</span>
                    </span>
                  }
                  {
                    followItem.id > 0 &&
                    <span className='pointer float-right'>
                      {!followItem.isFollow &&
                        <span>
                          <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                          <span className='pl-1 pr-3 font-13'>Follow</span>
                        </span>
                      }
                      {followItem.isFollow &&
                        <span>
                          <span> <i className="fas fa-thumbs-down font-14 pointer"></i></span>
                          <span className='pl-1 pr-3 font-13'>UnFollow</span>
                        </span>
                      }
                    </span>
                  }
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
        <MessageContainer stallId={props.stallId} handleMessageClose={handleMessageClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openLogin} onClose={toggleDrawerLogin}>
        <LoginContainer handleLoginClose={handleLoginClose} onLoginCallBack={onLoginCallBack} />
      </Drawer>
    </div>
  );
}
export default StallHeader;