import React, { useState, useEffect } from 'react';
import './SaleRequestConfirm.scss';
import { formatService, http, general, AlertService, FxDateDisplay, FxDateTimeDisplay, localstorage, FxCurrency } from '../../../fx-core';
import dummyImage from "../../../../assets/images/dummy.png";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Button, Link } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel'
import DateIcon from '@material-ui/icons/DateRange';

const SaleRequestConfirm = (props: any) => {

  let defaultContext = {
    communityId: parseInt(localstorage.getItem("communityId")),
    userId: parseInt(localstorage.getItem("userId")),
    stallId: props.listingInfoDetail.stallId,
    stallName: null,
    accept: false
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [images, setImages] = useState([]);
  const [listingDetail, setListingDetail] = useState(props.listingInfoDetail);
  const [saleItemDetails, setSaleItemDetails] = useState(props.listingInfoDetail.saleItemDetails);

  const handleCheckbox = (event) => {
    handleContextChange({ [event.target.name]: event.target.checked });
  };

  const handleContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getStallById = () => {
    let apiOptions: any = {
      url: `portal/stall/${currentContext.stallId}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      getStallByIdCallBack(result);
    });
  }

  const getStallByIdCallBack = (res) => {
    handleContextChange({ "stallName": res.name });
    getImages();
  }

  const getImages = () => {
    let imageList = [];
    for (let item of listingDetail.imagePathList) {
      let imageUrl = general.getImageUrl(item);
      imageList.push({ imageUrl: imageUrl });
    }
    setImages(imageList);
  }

  const saveSaleRequest = () => {
    if (!currentContext.accept) {
      AlertService.showMsg({ message: "Please agree provider agreement" });
      return;
    }
    let inputData = {
      id: 0,
      serviceId: listingDetail.serviceId,
      ownerId: currentContext.userId,
      communityId: currentContext.communityId,
      currentDate: formatService.getCurrentDateForServer(),
      stallId: currentContext.stallId,
      startDateTime: listingDetail.startDateTime,
      startTime: listingDetail.startTime,
      startDate: listingDetail.startDate,
      endDateTime: listingDetail.endDateTime,
      endTime: listingDetail.endTime,
      endDate: listingDetail.endDate,
      title: listingDetail.title,
      description: listingDetail.description,
      imagePathList: listingDetail.imagePathList,
      saleItemList: listingDetail.saleItemDetails,
      showLink: listingDetail.showLink,
      linkLabel: listingDetail.linkLabel,
      linkText: listingDetail.linkText,
      price: listingDetail.price
    }
    let apiOptions: any = {
      url: `sale/add`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      saveSaleRequestCallback(result);
    });
  }

  const saveSaleRequestCallback = (res) => {
    AlertService.showSuccessMsg("Your sale service request is confirmed");
    navigateToSaleSummary(res.serviceReqId, res.subscriptionStatus);
  }

  const navigateToSaleSummary = (reqId, status) => {
    props.onSaleRequestConfirmCallback({ reqId: reqId, status: status });
  }

  const navigateToBack = () => {
    props.onSaleRequestConfirmCallback();
  }

  const openProviderAgreement = () => {
    let url = general.getProviderAgreementUrl();
    if (url) {
      window.open(url, '_blank');
    }
  }

  useEffect(() => {
    getStallById();
  }, []);

  return (
    <div className="pt-5 salereq-confirm-ui">
      <div className="mx-4 mb-4">
        <div className="row pl-3 justify-content-center">
          <div className="col-6 text-center m-2 py-2"><b>{currentContext.stallName}</b> </div>
          <div className='col-6 text-center'>
            {(images && images.length > 0) &&
              <Carousel>
                {
                  images && images.map((itemObj) => (
                    <div className="text-center">
                      <img src={itemObj.imageUrl} alt="image" width={300} height={300}></img>
                    </div>
                  ))}
              </Carousel>
            }
            {images.length == 0 &&
            <span className='text-center'>
              <img src={dummyImage} alt="dummy" width={300} height={300}></img>
              </span>
            }
          </div>
          {/* <div className="col-12 pl-1 pt-2">
            <span className="col-6">
              <DateIcon fontSize="inherit" />
              <FxDateDisplay value={listingDetail.startDate} />
              {listingDetail.startTime &&
                <span className="pl-1">
                  <i className="far fa-clock color-aaa px-1"></i>
                  <FxDateTimeDisplay value={listingDetail.startTime} displayType="time" />
                </span>
              }
            </span>
            <span className="col-6">
              <DateIcon fontSize="inherit" />
              <FxDateDisplay value={listingDetail.endDate} />
              {listingDetail.endTime &&
                <span className="pl-1">
                  <i className="far fa-clock color-aaa px-1"></i>
                  <FxDateTimeDisplay value={listingDetail.endTime} displayType="time" />
                </span>
              }
            </span>
          </div> */}
          <div className="col-12 p-2">
            <span className='pl-2'>
            <b>{listingDetail.title}</b>
            </span>
          </div>
          <div className="col-12">
            {listingDetail.description &&
              <span className='pl-2'>{listingDetail.description}</span>
            }
          </div>

          {saleItemDetails && saleItemDetails.map((itemObj) => (
            <div className="col-12 pl-2 pt-5">
              <div className='pl-3'> {itemObj.groupName}</div>
              {itemObj.saleItems && itemObj.saleItems.map((item) => (
                <div className='row listitem'>
                  <div className="col-3">
                    {!item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                    {item.imageUrl && <img src={item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
                  </div>
                  <div className="row col-7">
                    <span className='col-10'>{item.itemName}</span>
                    <span className="col-2 float-right">
                      <FxCurrency value={item.price} />
                    </span>
                    <span className='col-12'>{item.description}</span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="row">
          <div className="pl-4 pt-3">
            <Checkbox name="accept" color="primary" value={currentContext.accept} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
            I agree
            <Link className="pointer" onClick={openProviderAgreement}> Provider Agreement </Link>
          </div>
          <div className="col-12 pt-4 m-4">
            <span className='pr-2'>
              <Button variant="contained" size="medium" onClick={navigateToBack}> Back </Button>
            </span>
            <Button variant="contained" size="medium" color="primary" onClick={saveSaleRequest}>  Submit  </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SaleRequestConfirm;