import React, { useEffect, useState } from 'react';
import './SpecialCommunityEntryForm.scss';
import { FxTextarea, FxSelect, http, AlertService, formValidator, FxInput } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import _ from "lodash";
import { nanoid } from 'nanoid';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const SpecialCommunityEntryForm = (props) => {

    let defaultContext = {
        description: "",
        questionTypeId: -1,
        isEdit: false,
        selQueUniqueId: "",
        selOptionText: ""
    };
    let initialArr: any = [];
    const [currentContext, setCurrentContext] = useState(defaultContext);
    const [questions, setQuestions] = useState(initialArr);
    const [queOptions, setQueOptions] = useState(initialArr);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [errors, setErrors] = useState({});
    const [selectedItem, setSelectedItem] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [selOptionItem, setSelOptionItem] = useState({});
    const [optDelOpen, setOptDelOpen] = useState(false);

    const getCommunityQuestionsWithOptions = () => {
        let apiOptions: any = {
            url: `question/community/${props.communityId}`
        };
        http.get(apiOptions).then(res => {
            getCommunityQuestionOptionsCallBack(res);
        });
    };

    const getCommunityQuestionOptionsCallBack = (res) => {
        let result = res.data;
        let questions = result.questions;
        let queOptions = result.questionOptions;
        for (let q of questions) {
            q.queUniqueId = q.id;
            if (q.questionTypeId == 3) { //3-Select
                let options = queOptions.filter(i => i.questionId == q.id);
                if (options.length > 0) {
                    for (let o of options) {
                        o.optUniqueId = o.id;
                        o.isAdd = false;
                    }
                    q.questionOptions = options;
                }
                else {
                    q.questionOptions = [];
                }
            }
        }
        setQuestions(questions);
        setQueOptions(queOptions);
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                QuestionType: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            initLookupCallBack(response);
        })
    }

    const initLookupCallBack = (res) => {
        let result = res.data;
        setQuestionTypes(result.QuestionType);
    };

    const handleInputChange = (item) => {
        handleCurrentContextChange({ [item.name]: item.value });
        item.validations = getInputValidations(item.name);
        const validationResult = formValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleCurrentContextChange = (data) => {
        setCurrentContext(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getValidationMap = () => {
        let validationMap = {
            questionTypeId: ["select"],
            description: ["required"]
        }
        return validationMap;
    };

    const getInputValidations = (name) => {
        let validationMap = getValidationMap();
        return validationMap[name];
    }

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidationMap();
        for (var key in validationMap) {
            let result = { name: key, value: currentContext[key], validations: validationMap[key] }
            items.push(result);
        }
        let validationResult = formValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const addQuestion = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let item: any = [{
                entityId: props.communityId,
                entityTypeId: 1, //1-Community
                description: currentContext.description,
                questionTypeId: currentContext.questionTypeId,
                queUniqueId: nanoid(),
                id: 0
            }];
            if (currentContext.questionTypeId == 3) {
                item[0].questionOptions = [];
            }
            let mergedQues = questions.concat(item);
            setQuestions(mergedQues);
            resetDefaultContext();
        }
    };

    const resetDefaultContext = () => {
        handleCurrentContextChange(defaultContext);
    };

    const updateQuestion = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let mergedQues = questions.map((q) => {
                if (q.queUniqueId == currentContext.selQueUniqueId) {
                    q.description = currentContext.description;
                }
                return q;
            });
            setQuestions(mergedQues);
            resetDefaultContext()
        };
    };

    const editQuestion = (item) => {
        let itemObj = {
            isEdit: true,
            questionTypeId: item.questionTypeId,
            description: item.description,
            selQueUniqueId: item.queUniqueId
        };
        handleCurrentContextChange(itemObj);
    };

    const confirmDelete = (item) => {
        setSelectedItem(item);
        setConfirmOpen(true);
        setMessage("Are you sure to delete ?");
    };

    const handleDialogClose = () => {
        setConfirmOpen(false);
        setMessage("");
        setSelectedItem({});
    };

    const onConfirmCallback = () => {
        const item = selectedItem;
        deleteQuestion(item);
        handleDialogClose();
    };

    const deleteQuestion = (item) => {
        if (item.id > 0) {
            let apiOptions: any = {
                url: `question/${item.id}`
            };
            http.delete(apiOptions).then(res => {
                deleteQuestionCallBack(res);
            });
        }
        else {
            let filterQuestions = questions.filter(i => i.queUniqueId != item.queUniqueId);
            setQuestions(filterQuestions);
            AlertService.showSuccessMsg();
            resetDefaultContext();
        }
    };

    const deleteQuestionCallBack = (res) => {
        let id = res.data;
        let filterQuestions = questions.filter(i => i.id != id);
        setQuestions(filterQuestions);
        AlertService.showSuccessMsg();
        resetDefaultContext();
    };

    const saveQuestions = () => {
        if (questions.length <= 0) {
            AlertService.showErrorMsg("Please add question.");
            return;
        }
        let queOptions: any = [];
        for (let q of questions) {
            if (q.questionTypeId == 3 && q.questionOptions.length > 0) {
                queOptions = [...queOptions, ...q.questionOptions];
            }
        }
        let inputData = {
            questions: questions,
            questionOptions: queOptions
        };
        let apiOptions: any = {
            url: `question/community/save`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveQuestionsCallback(res);
        });
    };

    const saveQuestionsCallback = (res) => {
        AlertService.showSuccessMsg();
        handleClose();
    };

    const addQueOption = (item) => {
        const queArr = questions.map(q => {
            if (q.id == item.id) {
                let isAddedEmptyRow = _.find(q.questionOptions, { isAdd: true });
                if (!isAddedEmptyRow) {
                    q.questionOptions.push({
                        isAdd: true,
                        questionId: q.id,
                        optUniqueId: nanoid(),
                        id: 0,
                        queUniqueId: q.queUniqueId
                    });
                }
            }
            return q;
        });
        setQueOptions(queArr);
    };

    const addOption = (item) => {
        if (!currentContext.selOptionText) {
            AlertService.showErrorMsg("Please specify option text");
            return;
        }
        const queArr = questions.map(q => {
            if (q.id == item.questionId) {
                q.questionOptions.map(qo => {
                    if (qo.optUniqueId == item.optUniqueId) {
                        qo.isAdd = false;
                        qo.optionText = currentContext.selOptionText;
                    }
                });
            }
            return q;
        });
        setQueOptions(queArr);
        let itemObj = {
            selOptionText: ""
        };
        handleCurrentContextChange(itemObj);
    };

    const confirmDeleteOption = (item) => {
        setSelOptionItem(item);
        setOptDelOpen(true);
        setMessage("Are you sure to delete ?");
    };

    const handleOptionDialogClose = () => {
        setOptDelOpen(false);
        setMessage("");
        setSelOptionItem({});
    };

    const onOptionConfirmCallback = () => {
        const item = selOptionItem;
        deleteOption(item);
        handleOptionDialogClose();
    };

    const deleteOption = (item) => {
        if (item.id > 0) {
            let apiOptions: any = {
                url: `question/option/${item.id}`
            };
            http.delete(apiOptions).then(res => {
                deleteOptionCallBack(res);
            });
        }
        else {
            const filterQuestions = questions.map(q => {
                if (q.id == item.questionId) {
                    let filterOptions = q.questionOptions.filter(i => i.optUniqueId != item.optUniqueId);
                    q.questionOptions = filterOptions;
                }
                return q;
            });
            setQuestions(filterQuestions);
            AlertService.showSuccessMsg();
        }
    };

    const deleteOptionCallBack = (res) => {
        let result = res.data;
        const filterQuestions = questions.map(q => {
            if (q.id == result.questionId) {
                let filterOptions = q.questionOptions.filter(i => i.id != result.id);
                q.questionOptions = filterOptions;
            }
            return q;
        });
        setQuestions(filterQuestions);
        AlertService.showSuccessMsg();
    };

    const handleClose = () => {
        props.handleClose();
    };

    useEffect(() => {
        initLookup();
        getCommunityQuestionsWithOptions();
    }, [])

    return (
        <div className='splcommuentryform-ui'>
            <div className='row title-font py-1 px-2 drawer-header'>
                <span className="col-11">
                    {props.communityName} Entry
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>
            <div className='mx-3 pl-2 pt-4 my-3'>
                <div className='pt-2'>
                    <FxSelect name="questionTypeId" variant="outlined" label="Question Type"
                        options={questionTypes}
                        selectedValue={currentContext.questionTypeId}
                        valueField="id"
                        displayField="text"
                        disabled={currentContext.isEdit}
                        size="small"
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("questionTypeId", "select") &&
                        <div className="error">Question Type is required</div>
                    }
                </div>
                <div className="pt-2">
                    <FxTextarea name="description" variant="outlined" fullWidth size="small" label="Question"
                        value={currentContext.description} onValueChange={handleInputChange} maxRows={4} />
                    {
                        hasError("description", "required") &&
                        <div className="error">Question is required</div>
                    }
                </div>
                <div className='text-right pt-2'>
                    {
                        !currentContext.isEdit &&
                        <Button variant='contained' color='primary' className='button-style mr-2' onClick={addQuestion}>Add</Button>
                    }
                    {
                        currentContext.isEdit &&
                        <Button variant='contained' className='button-style mr-2' onClick={resetDefaultContext}>Cancel</Button>
                    }
                    {
                        currentContext.isEdit &&
                        <Button variant='contained' color='primary' className='button-style mr-2' onClick={updateQuestion}>Update</Button>
                    }
                </div>
            </div>
            <div className='mx-3 pl-2 pt-2 pb-5 mb-4'>
                <div>Questions</div>
                {
                    questions.length > 0 && questions.map((item: any, i) => (
                        <div className='listitem' key={i}>
                            <div>
                                <div className='row'>
                                    <span className='col-6'>
                                        {item.description}
                                    </span>
                                    <span className='col-6 text-right'>
                                        {
                                            item.questionTypeId == 3 &&
                                            <Button variant='contained' color='primary' size='small' className='button-style mr-2'
                                                onClick={() => addQueOption(item)}>Add Option
                                            </Button>
                                        }
                                        <Button variant='contained' color='primary' size='small' className='button-style mr-2'
                                            onClick={() => editQuestion(item)}>Edit
                                        </Button>
                                        <Button variant='contained' size='small' className='button-style mr-2'
                                            onClick={() => confirmDelete(item)}>Delete
                                        </Button>
                                    </span>
                                </div>
                                <div>
                                    {
                                        item.questionTypeId == 3 && item.questionOptions.length > 0 &&
                                        item.questionOptions.map((opt, j) => (
                                            <div className='row pt-3' key={j}>
                                                <div className='col-10'>
                                                    {
                                                        opt.isAdd &&
                                                        <FxInput
                                                            name="selOptionText"
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            label=""
                                                            value={currentContext.selOptionText}
                                                            onValueChange={handleInputChange}
                                                        />
                                                    }
                                                    {
                                                        !opt.isAdd &&
                                                        <span> {opt.optionText} </span>
                                                    }
                                                </div>
                                                <div className='col-2'>
                                                    {
                                                        opt.isAdd &&
                                                        <Button variant='contained' color='primary' size='small' className='button-style'
                                                            onClick={() => addOption(opt)}>Add
                                                        </Button>
                                                    }
                                                    {
                                                        !opt.isAdd &&
                                                        <span className='pointer'>
                                                            <i className="fas fa-trash-alt font-13" onClick={() => confirmDeleteOption(opt)}></i>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='px-4 py-2 drawer-footer'>
                <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveQuestions}><b>Save</b></Button>
            </div>
            <Dialog
                open={confirmOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
            </Dialog>
            <Dialog
                open={optDelOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleOptionDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleDialogClose={handleOptionDialogClose} onConfirmCallback={onOptionConfirmCallback} />
            </Dialog>
        </div>
    )
};

export default SpecialCommunityEntryForm;