import React, { useState, useEffect } from "react";
import { http, FxDateDisplay, FxCurrency } from '../../fx-core';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { NoRecords } from '../../NoRecords/NoRecords';

const MyPayments = (props) => {
    const useStyles = makeStyles({
        container: {
            maxHeight: '70vh',
        },
    });
    const classes = useStyles();

    const [paymentList, setPaymentList] = useState([]);

    const getPayments = () => {
        let apiOptions: any = {
            url: `payment/provider/list`,
            data: {}
        };
        http.post(apiOptions).then(res => {
            getPaymentsCallBack(res);
        });
    };

    const getPaymentsCallBack = (res) => {
        let result = res.data;
        for (let item of result) {
            if (item.entityTypeId == 1) {
                item.entityType = "Stall Subscription";
            }
            else if (item.entityTypeId == 2) {
                item.entityType = "Local Directory";
            }
            else if (item.entityTypeId == 3) {
                item.entityType = "Account Credits";
            }
            else if (item.entityTypeId == 4) {
                item.entityType = "Live Stream";
            }
            else if (item.entityTypeId == 5) {
                item.entityType = "Live Stream With Recording";
            }
        }
        setPaymentList(result);
    };

    useEffect(() => {
        getPayments();
    }, [])
    return (
        <div className='maypaymentlist-ui pt-5'>
            <div className='row title-font align-items-center pt-4'>
                <span className='pl-5 pt-3 py-1'>Payments</span>
            </div>            
            <div className='my-4 mx-3 pt-3'>
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader size="small" className='table-striped'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Transaction Date</TableCell>
                                <TableCell>Entity Type</TableCell>
                                <TableCell align='right'>Amount</TableCell>
                                <TableCell align='center'>Payment Mode</TableCell>
                                <TableCell align='center'>Payment Status</TableCell>
                            </TableRow>
                        </TableHead>
                        {paymentList.length == 0 &&
                            <TableCell colSpan={6}>
                                <NoRecords />
                            </TableCell>
                        }
                        {paymentList.length > 0 &&
                            paymentList.map((row: any, i) => (
                                <TableBody key={i}>
                                    <TableRow>
                                        <TableCell>
                                            <FxDateDisplay value={row.createdDate} />
                                        </TableCell>
                                        <TableCell>
                                            {row.entityType}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <FxCurrency value={row.amount} />
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.paymentMode}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {row.paymentStatus}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))
                        }
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default MyPayments;