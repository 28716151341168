import React from 'react';
import './ItemCustomizeAlert.scss'

const ItemCustomizeAlert = (props) => {

    return (
        <div>
            <div className="row justify-content-between title-font px-3">
                <div className="py-1 pl-3">
                    <span>Alert</span>
                </div>
                <div className="text-right pointer my-auto">
                    <i className="fas fa-times-circle font-16 pr-3" onClick={props.handleClose}></i>
                </div>
            </div>
            {
                props.errorMessages.map((item, i) => (
                    <div className='font-15 pl-3 pt-2' key={i}>
                        <div className='text-bold'>
                            {item.groupName}
                        </div>
                        <div className='ml-5'>
                            {item.messsage}
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default ItemCustomizeAlert;