import React from 'react';
import './Coupons.scss';
import { FxCurrency } from './../../fx-core';

export const Coupons = (props) => {

    return (
        <div>
            {
                props.couponList && props.couponList.length > 0 &&
                <div className='font-14 bold-text pt-2'>Coupons</div>
            }
            {
                props.couponList.length > 0 && props.couponList.map((coupon, i) => (
                    <span className="mt-1 col-6 pt-2 pr-2 pl-3" key={i}>
                        <button className={coupon.id == props.selectedUserCouponId || coupon.id == props.selectedCouponId ? "slot-btn slot-btn-active" : "slot-btn"}
                            onClick={() => props.selectedCoupon(coupon)}>
                            <span className="pr-1">{coupon.couponCode}</span>
                        </button>
                    </span>
                ))
            }
        </div>
    );
};