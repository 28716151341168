import React, { useEffect, useState } from 'react';
import './ListingSale.scss';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { http, general, useNavigationService, FxCurrency, localstorage, AlertService } from '../fx-core';
import { useDispatch, useSelector } from 'react-redux';
import secondset from "../../assets/secondset.svg";
import dummyImage from "../../assets/images/dummy.png";
import * as _ from 'lodash';
import LanguageIcon from '@material-ui/icons/Language';
import MessageContainer from '../MessageContainer/MessageContainer';
import LoginContainer from '../LoginContainer/LoginContainer';
import { Drawer, makeStyles } from '@material-ui/core';
import verifyTick from "../../assets/img/verifytick.png";

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ListingSale = (props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const listingCartData = useSelector<any, any>(s => s.listingCart);
  const cartData = useSelector<any, any>(s => s.cart);
  const login = useSelector<any, any>(s => s.login);
  const { routeData, navigateRoot, back } = useNavigationService();

  let defaultHeader: any = {
    stallName: "",
    stallAddress: "",
    title: "",
    startDateTime: "",
    endDateTime: "",
    description: "",
    stallContact: "",
    stallEmail: "",
    isStallVerified: false
  };
  let defaultFollowItem = {
    userId: 0,
    id: 0,
    entityId: 0,
    entityTypeId: 0,
    isFollow: false
  };
  const [serviceId, setServiceId] = useState(props.match.params.serviceId ? parseInt(props.match.params.serviceId) : 0);
  const [saleId, setSaleId] = useState(props.match.params.saleId ? parseInt(props.match.params.saleId) : 0);
  const [serviceName, setServiceName] = useState(null);
  const [saleHeader, setSaleHeader] = useState(defaultHeader);
  const [saleItemList, setSaleItemList] = useState([]);
  const [followItem, setFollowItem] = useState(defaultFollowItem);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [userId, setUserId] = useState(localstorage.getItem("userId") ? parseInt(localstorage.getItem("userId")) : 0);
  const [openLogin, setOpenLogin] = React.useState(false);

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const toggleDrawerLogin = () => {
    setOpenLogin(!openLogin);
  };

  const navToListingHome = () => {
    back();
  };

  const navToCustomerDashboard = (item: any) => {
    let navData = {
      targetRoute: '',
      targetTitle: "Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigateRoot(navData);
  };

  const getServiceById = () => {
    let inputData = {
      id: serviceId
    };
    let apiOptions: any = {
      url: `portal/service/edit/${inputData.id}`,
      data: {}
    };
    http.get(apiOptions).then(response => {
      setServiceName(response.data.serviceName);
    });
  };

  const getSaleById = () => {
    let apiOptions: any = {
      url: `portal/sale/${saleId}/stall`,
      data: {}
    };
    http.get(apiOptions).then(response => {
      getSaleCallBack(response.data);
    });
  }

  const getSaleCallBack = (item: any) => {
    item.imageUrl = general.getImageUrl(item.imagePath);
    setSaleHeader(item);
  }

  const getSaleItemListBySaleId = () => {
    let apiOptions: any = {
      url: `portal/saleitem/available/${saleId}`,
      data: {}
    };
    http.get(apiOptions).then(response => getSaleItemListBySaleIdCallBack(response));
  };

  const getSaleItemListBySaleIdCallBack = (res) => {
    let result = res.data;
    let hasCartData = (listingCartData && listingCartData.items && listingCartData.items.length > 0);
    for (let itemObj of result) {
      for (let item of itemObj.saleItems) {
        item.imageUrl = general.getImageUrl(item.imagePath);
        item.quantity = 0;
        if (hasCartData) {
          let cartItem = _.find(listingCartData.items, { id: item.id });
          if (cartItem) {
            item.quantity = cartItem.quantity;
          }
        }
      }
    }
    setSaleItemList(result);
  };

  const reduceQtyFromCart = (saleItem) => {
    dispatch({ type: "REDUCE_LISTING_CART", payload: { stallId: saleHeader.stallId, item: saleItem } });
  };

  const addQtyToCart = (saleItem) => {
    if (cartData.cartCount > 0) {
      dispatch({ type: "CLEAR_CART", payload: {} }); //Clear the service order items
    }
    dispatch({ type: "ADD_LISTING_CART", payload: { stallId: saleHeader.stallId, item: saleItem } });
  };

  const updateQuntity = () => {
    let cartItemsArr = saleItemList.map((i) => {
      let saleItems = i.saleItems.map((item) => {
        let cartItem = _.find(listingCartData.items, { id: item.id });
        if (cartItem) {
          item.quantity = cartItem.quantity;
        }
        else {
          item.quantity = 0;
        }
        return item;
      });
      i.saleItems = saleItems;
      return i;
    });
    setSaleItemList(cartItemsArr);
  };

  const getFollowInfoByUserId = () => {
    let loggedInUserId = userId;
    if (login.isLogin) {
      loggedInUserId = parseInt(localstorage.getItem("userId"));
      setUserId(loggedInUserId);
    }
    if (loggedInUserId > 0) {
      let inputData = {
        userId: loggedInUserId,
        entityId: props.stallId
      };
      let apiOptions: any = {
        url: `follow/query`,
        data: inputData
      };
      http.post(apiOptions).then(response => {
        let result = response.data;
        if (result && result.follow) {
          let folloObj = result.follow;
          folloObj.isFollow = folloObj.isFollow > 0 ? true : false;
          setFollowItem(folloObj);
        }
      });
    }
  }

  const navToLogin = () => {
    setOpenLogin(true);
  }

  const handleLoginClose = () => {
    setOpenLogin(false);
  }

  const onLoginCallBack = () => {
    handleLoginClose();
  }


  const handleMessageClose = () => {
    setOpenMessage(false);
  }

  const saveFollow = () => {
    if (login.isLogin && userId) {
      if (followItem.id == 0) {
        addFollow();
      }
      else if (followItem.id > 0) {
        if (followItem.isFollow) {
          unFollowStall();
        }
        else {
          followStall();
        }
      }
    }
    else {
      AlertService.showMsg({ message: "Please login to follow" });
      navToLogin();
      return;
    }
  }

  const addFollow = () => {
    followItem.entityId = props.stallId;
    let apiOptions: any = {
      url: `follow/add`,
      data: followItem
    };
    http.post(apiOptions).then(response => {
      saveFollowCallBack();
    });
  }

  const followStall = () => {
    let apiOptions: any = {
      url: `follow/${followItem.id}/updatefollow`,
      data: null
    };
    http.put(apiOptions).then(response => {
      saveFollowCallBack();
    });
  }

  const unFollowStall = () => {
    let apiOptions: any = {
      url: `follow/${followItem.id}/updateunfollow`,
      data: null
    };
    http.put(apiOptions).then(response => {
      saveFollowCallBack();
    });
  }

  const saveFollowCallBack = () => {
    getFollowInfoByUserId();
  }

  const navToMessage = () => {
    if (login.isLogin && userId) {
      setOpenMessage(true);
    }
    else {
      AlertService.showMsg({ message: "Please login to chat" });
      navToLogin();
      return;
    }
  }

  useEffect(() => {
    getServiceById();
    getSaleById();
    getSaleItemListBySaleId();
    getFollowInfoByUserId();
  }, []);

  useEffect(() => {
    updateQuntity();
  }, [listingCartData]);

  useEffect(() => {
    getFollowInfoByUserId();
  }, [login]);

  return (
    <div className='pt-5 listingsale-ui'>
      <div className="title-font custom-head pt-3">
        <Breadcrumbs aria-label="breadcrumb">
          <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
            <b>Dashboard</b>
          </span>
          {routeData.parent != 'dashboard' && <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToListingHome}>
            <b>{serviceName}</b>
          </span>}
          <Typography color="textPrimary" className="breadcrumbs-style">{saleHeader.title}</Typography>
        </Breadcrumbs>
      </div>

      {/* Desktop View */}
      <div className="row pt-5 deskview">
        <div className="col-12 pt-4 pl-0">
          <div className='d-flex pl-5 ml-2 py-2'>
            <div>
              {!saleHeader.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
              {saleHeader.imageUrl && <img src={saleHeader.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
            </div>
            <div className="d-flex flex-column justify-content-between pl-4 ml-2 pb-2 col-10">
              <div>
                <b>{saleHeader.title}</b>
              </div>
              <div>
                {saleHeader.stallName}
                {
                  saleHeader.isStallVerified &&
                  <span className='ml-1'>
                    <img src={verifyTick} width="20" height="20" />
                  </span>
                }
              </div>
              <div className="font-15 addresscolor">
                {saleHeader.stallAddress}
              </div>
              <div className="d-flex align-items-center pt-2">
                <div className="d-flex pr-3 font-14">
                  <span><i className="fas fa-phone-alt"></i></span>
                  {saleHeader.stallContact &&
                    <span className="pl-1 font-13">{saleHeader.stallContact}</span>
                  }
                  {!saleHeader.stallContact &&
                    <span className="pl-1 font-13 pr-4">-----</span>
                  }
                </div>
                <div className="d-flex pr-3 font-14">
              <span><i className="fas fa-envelope"></i></span>
              {saleHeader.stallEmail &&
                <span className="pl-1 font-13">{saleHeader.stallEmail}</span>
              }
              {!saleHeader.stallEmail &&
                <span className="pl-1 font-13 pr-4">-----</span>
              }
            </div>
            <div className="d-flex font-14 pr-4">
              <span><i className="fas fa-globe"></i></span>
              {saleHeader.website &&
                <span className='pl-1 font-13'>{saleHeader.website}</span>
              }
              {!saleHeader.website &&
                <span className="pl-1 font-13 pr-4">-----</span>
              }
            </div>
               
              
                
                  <div className="font-14 pr-4" onClick={saveFollow}>
                    {!followItem.id &&
                      <span className='pointer'>
                        <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                        <span className='pl-1 font-13'>Follow</span>
                      </span>
                    }
                    {
                      followItem.id > 0 &&
                      <span className='pointer'>
                        {!followItem.isFollow &&
                          <span>
                            <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                            <span className='pl-1 font-13'>Follow</span>
                          </span>
                        }
                        {followItem.isFollow &&
                          <span>
                            <span> <i className="fas fa-thumbs-down font-14 pointer"></i></span>
                            <span className='pl-1 font-13'>UnFollow</span>
                          </span>
                        }
                      </span>
                    }
                  </div>
               
                <div>
                  <span onClick={navToMessage}>
                    <i className="fas fa-paper-plane font-14 text-success pointer"></i>
                    <span className='text-success font-13 pl-1 pointer'>Direct</span>
                  </span>
                </div>
              </div>
              <div className="font-12 text-success shopstatus-style pt-2">
                {saleHeader.description}
              </div>
            </div>
          </div>

          <hr />
          <div className='row px-3 pl-5 pb-5'>
            {
              saleItemList.length < 1 &&
              <div className="py-5 mx-auto">
                <img src={secondset} alt="first set"></img>
                <div className="pt-4 font-17"><b> Sorry, No Listings Available.</b></div>
              </div>
            }
            {
              saleItemList.length > 0 && saleItemList.map((itemObj, i) => (
                <div className="col-4" key={i}>
                  <div className='text-center'>
                    <span className="font-22"><u>{itemObj.groupName}</u></span>
                  </div>
                  {
                    itemObj.saleItems.map((item, j) => (
                      <div key={j}>
                        <div className="row">
                          <div className="col-6 text-center py-2">
                            {!item.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
                            {item.imageUrl && <img src={item.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
                          </div>
                          <div className="col-6 text-right pt-3">
                            <div className="row justify-content-end mr-3">
                              <div className="font-24"><b><FxCurrency value={item.price} /></b></div>
                            </div>
                            <div className="row justify-content-end mr-3 font-24 pt-3">
                              <div className="d-flex align-items-center justify-content-between b" style={{ width: 80, height: 29 }}>
                                <span className="count-minus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-minus-square"></i>
                                </span>
                                <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer"
                                  style={{ flex: 1 }}>{item.quantity}</span>
                                <span className="count-plus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-plus-square"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 text-center">
                          {item.itemName}
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>


      {/* Tablet View */}
      <div className="row pt-5 tabview">
        <div className="col-12 pt-4 pl-0">
          <div className='d-flex pl-4 ml-2 py-2'>
            <div>
              {!saleHeader.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
              {saleHeader.imageUrl && <img src={saleHeader.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
            </div>
            <div className="d-flex flex-column justify-content-between pl-3 pb-2">
              <div className='font-15'>
                <b>{saleHeader.title}</b>
              </div>
              <div className='font-14'>
                {saleHeader.stallName}
                {
                  saleHeader.isStallVerified &&
                  <span className='ml-1'>
                    <img src={verifyTick} width="20" height="20" />
                  </span>
                }
              </div>
              <div className="font-13 addresscolor">
                {saleHeader.stallAddress}
              </div>
              <div className="d-flex font-12">
                <div className="d-flex">
                  <span> <PhoneIcon fontSize="inherit" /></span>
                  <span className="pl-1">{saleHeader.stallContact}</span>
                </div>
                <div className="d-flex pl-2">
                  <span> <EmailIcon fontSize="inherit" /></span>
                  <span className="pl-1">{saleHeader.stallEmail}</span>
                </div>
                {saleHeader.website &&
                  <div className="d-flex pl-2">
                    <span><LanguageIcon fontSize='inherit' /></span>
                    <span className='pl-1'>{saleHeader.website}</span>
                  </div>
                }
                <div className="d-flex pl-2">
                  <div className="font-14 pr-0" onClick={saveFollow}>
                    {!followItem.id &&
                      <span className='pointer'>
                        <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                        <span className='pl-1 font-13'>Follow</span>
                      </span>
                    }
                    {
                      followItem.id > 0 &&
                      <span className='pointer'>
                        {!followItem.isFollow &&
                          <span>
                            <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                            <span className='pl-1 font-13'>Follow</span>
                          </span>
                        }
                        {followItem.isFollow &&
                          <span>
                            <span> <i className="fas fa-thumbs-down font-14 pointer"></i></span>
                            <span className='pl-1 font-13'>UnFollow</span>
                          </span>
                        }
                      </span>
                    }
                  </div>
                </div>
                <div className="d-flex pl-2">
                  <span onClick={navToMessage}>
                    <i className="fas fa-paper-plane font-14 text-success pointer"></i>
                    <span className='text-success font-13 pl-1 pointer'>Direct</span>
                  </span>
                </div>
              </div>
              <div className="font-12 text-success shopstatus-style">
                {saleHeader.description}
              </div>
            </div>
          </div>

          <hr />
          <div className='row px-3 pl-5 pb-5'>
            {
              saleItemList.length < 1 &&
              <div className="py-5 mx-auto">
                <img src={secondset} alt="first set"></img>
                <div className="pt-4 font-17"><b> Sorry, No Listings Available.</b></div>
              </div>
            }
            {
              saleItemList.length > 0 && saleItemList.map((itemObj, i) => (
                <div className="col-6" key={i}>
                  <div className='text-center'>
                    <span className="font-22"><u>{itemObj.groupName}</u></span>
                  </div>
                  {
                    itemObj.saleItems.map((item, j) => (
                      <div key={j}>
                        <div className="row">
                          <div className="col-6 text-center py-2">
                            {!item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                            {item.imageUrl && <img src={item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
                          </div>
                          <div className="col-6 text-right pt-3">
                            <div className="row justify-content-end mr-3">
                              <div className="font-24"><b><FxCurrency value={item.price} /></b></div>
                            </div>
                            <div className="row justify-content-end mr-3 pt-3">
                              <div className="d-flex align-items-center justify-content-between font-24 b" style={{ width: 80, height: 29 }}>
                                <span className="count-minus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-minus-square"></i>
                                </span>
                                <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer"
                                  style={{ flex: 1 }}>{item.quantity}</span>
                                <span className="count-plus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-plus-square"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 text-center">
                          {item.itemName}
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>


      {/* Mobile View */}
      <div className="row pt-5 mobileview">
        <div className="col-12 pt-4 pl-0">
          <div className='d-flex pl-4 ml-2 py-2'>
            <div>
              {!saleHeader.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
              {saleHeader.imageUrl && <img src={saleHeader.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
            </div>
            <div className="d-flex flex-column justify-content-between pl-3 pb-2">
              <div className='font-15'>
                <b>{saleHeader.title}</b>
              </div>
              <div className='font-14'>
                {saleHeader.stallName}
                {
                  saleHeader.isStallVerified &&
                  <span className='ml-1'>
                    <img src={verifyTick} width="20" height="20" />
                  </span>
                }
              </div>
              <div className="font-13 addresscolor">
                {saleHeader.stallAddress}
              </div>
              <div className="d-flex font-12">
                <div className="d-flex">
                  <span> <PhoneIcon fontSize="inherit" /></span>
                  <span className="pl-1">{saleHeader.stallContact}</span>
                </div>
                <div className="d-flex pl-2">
                  <span> <EmailIcon fontSize="inherit" /></span>
                  <span className="pl-1">{saleHeader.stallEmail}</span>
                </div>
              </div>
              <div className="d-flex font-12">
                {saleHeader.website &&
                  <div className="d-flex pl-2">
                    <span><LanguageIcon fontSize='inherit' /></span>
                    <span className="pl-1">{saleHeader.website}</span>
                  </div>
                }
                <div className="d-flex pl-2">
                  <div className="font-14 pr-0" onClick={saveFollow}>
                    {!followItem.id &&
                      <span className='pointer'>
                        <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                        <span className='pl-1 font-13'>Follow</span>
                      </span>
                    }
                    {
                      followItem.id > 0 &&
                      <span className='pointer'>
                        {!followItem.isFollow &&
                          <span>
                            <span> <i className="fas fa-thumbs-up font-14 pointer"></i></span>
                            <span className='pl-1 font-13'>Follow</span>
                          </span>
                        }
                        {followItem.isFollow &&
                          <span>
                            <span> <i className="fas fa-thumbs-down font-14 pointer"></i></span>
                            <span className='pl-1 font-13'>UnFollow</span>
                          </span>
                        }
                      </span>
                    }
                  </div>
                </div>
                <div className="d-flex pl-2">
                  <span onClick={navToMessage}>
                    <i className="fas fa-paper-plane font-14 text-success pointer"></i>
                    <span className='text-success font-13 pl-1 pointer'>Direct</span>
                  </span>
                </div>
              </div>

              <div className="font-12 text-success shopstatus-style">
                {saleHeader.description}
              </div>
            </div>
          </div>

          <hr />
          <div className='row px-3 pl-5 pb-5'>
            {
              saleItemList.length < 1 &&
              <div className="py-5 mx-auto">
                <img src={secondset} alt="first set"></img>
                <div className="pt-4 font-17"><b> Sorry, No Listings Available.</b></div>
              </div>
            }
            {
              saleItemList.length > 0 && saleItemList.map((itemObj, i) => (
                <div className="col-12" key={i}>
                  <div className='text-center'>
                    <span className="font-22"><u>{itemObj.groupName}</u></span>
                  </div>
                  {
                    itemObj.saleItems.map((item, j) => (
                      <div key={j}>
                        <div className="row">
                          <div className="col-6 text-center py-2">
                            {!item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                            {item.imageUrl && <img src={item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
                          </div>
                          <div className="col-6 text-right pt-3">
                            <div className="row justify-content-end mr-3">
                              <div className="font-22"><b><FxCurrency value={item.price} /></b></div>
                            </div>
                            <div className="row justify-content-end font-20 mr-3 pt-3">
                              <div className="d-flex align-items-center justify-content-between b" style={{ width: 80, height: 29 }}>
                                <span className="count-minus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-minus-square"></i>
                                </span>
                                <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer"
                                  style={{ flex: 1 }}>{item.quantity}</span>
                                <span className="count-plus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-plus-square"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 text-center">
                          {item.itemName}
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
        <MessageContainer stallId={saleHeader.stallId} handleMessageClose={handleMessageClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openLogin} onClose={toggleDrawerLogin}>
        <LoginContainer handleLoginClose={handleLoginClose} onLoginCallBack={onLoginCallBack} />
      </Drawer>
    </div>
  );
};

export default ListingSale;