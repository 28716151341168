import React, { useEffect, useState } from 'react';
import './LocalDrList.scss';
import LocalDrForm from '../LocalDrForm/LocalDrForm';
import { Card, Drawer, Fab, makeStyles } from '@material-ui/core';
import { AlertService, FxPopper, http, localstorage, useNavigationService } from '../../fx-core';
import Dialog from '@material-ui/core/Dialog';
import ShareOptions from '../ShareOptions/ShareOptions';
import share from "../../../assets/share.png";
import info from "../../../assets/info.png";
import send from "../../../assets/send.png";
import commkit from "../../../assets/img/finduslocalites.jpg";
import MessageContainer from '../../MessageContainer/MessageContainer';
import LoginContainer from '../../LoginContainer/LoginContainer';
import { useSelector, useDispatch } from 'react-redux';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const LocalDrList = (props) => {
  const { navigate } = useNavigationService();
  const classes = styles();
  const businessList = [
    { "premium": true, "stallName": "Ruby Food Trailor", "commkit": true, "shortDesc": "Healthy and tasty food available.", "longDesc": "Healthy and tasty food available. Door delivery and take away available. Shop opening time: 9 am to 9 pm " },
    { "premium": false, "stallName": "Appu's Kitchen", "commkit": true, "shortDesc": "Healthy and tasty food available.", "longDesc": "Healthy and tasty food available. Door delivery and take away available. Shop opening time: 9 am to 9 pm " },
    { "premium": true, "stallName": "Hayagriv", "commkit": false, "shortDesc": "Healthy snacks available.", "longDesc": "Varieties of healthy and tasty snacks available. Door delivery and take away available. Shop opening time: 9 am to 9 pm " },
    { "premium": false, "stallName": "H&H Cafe", "commkit": false, "shortDesc": "Refreshing coffee available.", "longDesc": "Different varieties of coffee available. Door delivery and take away available. Shop opening time: 9 am to 9 pm " },
  ];
  const login = useSelector<any, any>(s => s.login);
  const [FormOpen, setFormOpen] = useState(false);
  const [localBusinessList, setLocalBusinessList] = useState([]);
  const [communityId, setCommunityId] = useState(0);
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [shareOpen, setShareOpen] = useState(false);
  const [localBusinessDirectoryId, setLocalBusinessDirectoryId] = useState(0);
  const [openInfo, setOpenInfo] = React.useState(null);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [postId, setPostId] = React.useState(0);
  const [openLogin, setOpenLogin] = React.useState(false);
  const globalDispatch = useDispatch();
  
  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const toggleDrawerLogin = () => {
    setOpenLogin(!openLogin);
  };

  const handleinfo = (event) => {
    setOpenInfo(event.currentTarget);
  };

  const infoClose = () => {
    setOpenInfo(null);
  };

  const infoOpen = Boolean(openInfo);
  const id = infoOpen ? 'simple-popover' : undefined;

  const openForm = () => {
    if (login.isLogin && userId) {
      setFormOpen(true);
    }
    else {
      AlertService.showMsg({ message: "Please login to add post" });
      navToLogin();
      return;
    }
  }
  const handleForm = () => {
    setFormOpen(false);
    getLocalBusinessList();
  }

  const getLocalBusinessList = (commId = 0) => {
    let inputData = {
      communityId: commId > 0 ? commId : communityId
    };
    let apiOptions: any = {
      url: `localbusinessdirectory/approved`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      setLocalBusinessList(res.data);
    })
  };

  const openShareDialog = (event: any, id: any) => {
    setLocalBusinessDirectoryId(id);
    setShareOpen(true);
    event.stopPropagation();
  }

  const closeShareDialog = () => {
    setLocalBusinessDirectoryId(0);
    setShareOpen(false);
  };

  const navToMessage = (event, postId) => {
    if (login.isLogin && userId) {
      setPostId(postId);
      setOpenMessage(true);
      event.stopPropagation();
    }
    else {
      AlertService.showMsg({ message: "Please login to chat" });
      navToLogin();
      event.stopPropagation();
      return;
    }
  }

  const handleMessageClose = () => {
    setPostId(0);
    setOpenMessage(false);
  }

  const navToLogin = () => {
    setOpenLogin(true);
  }

  const handleLoginClose = () => {
    setOpenLogin(false);
  }

  const onLoginCallBack = () => {
    handleLoginClose();
    setUserId(parseInt(localstorage.getItem("userId")));
  }

  const navToStallWithService = (id: any, serviceId: any) => {
    let parent = 'dashboard';
    let navData = {
      targetRoute: '/stall/' + serviceId + '/' + id + '/' + parent,
      targetTitle: "Stall",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const navToStall = (slug: any) => {
    // if (id > 0) {
    //   getStallService(id);
    // }
    let navData = {
      targetRoute: '/stalls/' + slug,
      targetTitle: "Stall",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);    
  }

  const getStallService = (id: any) => {
    let inputData = {
      communityId: communityId
    };
    let apiOptions: any = {
      url: `portal/stall/${id}/services`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data.services;
      if (result && result.length > 0) {
        navToStallWithService(id, result[0].id);
      };
    });
  }

  const validateCommunity = (code: any) => {
    let inputData = { code: code || "dev" };

    let apiOptions = {
      url: 'portal/validate/community/code',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result: any = response.data;
      let community: any = result.community;
      setCommunityId(community.id);
      localstorage.setItem('imagStorageUrl', result.imageStorageUrl);
      globalDispatch({ type: "set-community-details", payload: community });
      getLocalBusinessList(community.id);
    });
  }

  useEffect(() => {
    const code = props.communityCode;
    if (code && code.length > 0) {
      validateCommunity(code);
    }
  }, [props.communityCode]);

  return (
    <div className='local-dr-list'>

      {/* Desktop View */}
      <div className='deskview py-5 px-5'>
        <div className='row justify-content-center mt-2 px-5 mx-5'>
          <div className='row justify-content-between align-items-center add-post'>
            <div className='font-26'>Local Business Directory</div>
            <div className='text-right'>
              <Fab color="default" variant="extended" onClick={openForm}>
                <i className="fas fa-plus pr-2"></i>
                <b>POST</b>
              </Fab>
            </div>
          </div>
        </div>
        {localBusinessList.length > 0 && localBusinessList.map((item: any, i) => (
          <div className='row justify-content-center mt-4' key={i}>
            <Card className='business-card'>
              <div className='row header-wrapper p-4'>
                <div className='col-9'>
                  {item.premium == true &&
                    <div className="premium-wrapper">
                      <div className="premium-style">Premium</div>
                    </div>
                  }
                  <div>
                    <span className='stall-name'>{item.title}</span>
                    {item.isListingInCommkit &&
                      <span className='ml-2 commkit-wrapper zoom-in-out-box'>
                        <img src={commkit} height="28px" className='pointer' onClick={() => navToStall(item.stallSlug)} />
                      </span>
                    }
                  </div>
                  <div className='short-desc'>{item.shortDescription}</div>
                </div>
                <div className='col-3 pt-2 row justify-content-end pr-0'>
                  <div className='d-flex justify-content-between'>
                    <div className='pointer' onClick={(e) => navToMessage(e, item.id)}>
                      <img src={send} className='pointer icon-style-share' />
                      <div className='font-13'>Direct</div>
                    </div>
                    <div className='ml-5 pointer' onClick={(e) => openShareDialog(e, item.id)} >
                      <img src={share} className='pointer icon-style-share' />
                      <div className='font-13'>Share</div>
                    </div>
                    <div className='pl-5'>
                      <FxPopper image={info}
                        popupId={`localdir-${item.id}`}>
                        <div className='p-3 card-style'>
                          <div className='row'>
                            <span className='col-1'><i className="fas fa-address-card"></i></span>
                            <span className='col-11 pl-1'>{item.address}</span>
                          </div>
                          <div className='pt-1 row'>
                            <span className='col-1'><i className="fas fa-envelope"></i></span>
                            <span className='col-11 pl-1'>{item.contactEmail}</span>
                          </div>
                          {item.isShowPhone == true &&
                            <div className='pt-1 row'>
                              <span className='col-1'> <i className="fas fa-phone-alt"></i></span>
                              <span className='col-11 pl-1'>{item.contactMobile}</span>
                            </div>
                          }
                          <div className='pt-1 row'>
                            <span className='col-1'><i className="fas fa-user"></i></span>
                            <span className='col-11 pl-1'>{item.contactName}</span>
                          </div>
                        </div>
                      </FxPopper>
                      <div className='font-13 pl-1 pt-1 pointer'>Info</div>
                    </div>
                  </div>
                </div>
              </div>
              {item.longDescription &&
                <div className='px-4 py-3 font-20 long-desc'>{item.longDescription}</div>
              }
              {!item.longDescription &&
                <div className='px-4 py-3 long-desc'>{item.shortDescription}</div>
              }
            </Card>
          </div>
        ))
        }
        {localBusinessList.length == 0 &&
          <div className='d-flex justify-content-center align-items-center no-listing font-20 color-aaa'>
            No Listings Available
          </div>
        }
      </div>

      {/* Tablet View */}
      <div className='tabview px-4 py-5'>
        <div className='row justify-content-center mt-2'>
          <div className='row justify-content-between align-items-center add-post'>
            <div className='font-28'>Local Business Directory</div>
            <div className='text-right'>
              <Fab color="default" variant="extended" onClick={openForm}>
                <i className="fas fa-plus pr-2"></i>
                <b>POST</b>
              </Fab>
            </div>
          </div>
        </div>
        {localBusinessList.length > 0 && localBusinessList.map((item: any, i) => (
          <div className='row justify-content-center mt-4' key={i}>
            <Card className='business-card'>
              <div className='row header-wrapper p-4'>
                <div className='col-9'>
                  {item.premium == true &&
                    <div className="premium-wrapper">
                      <div className="premium-style">Premium</div>
                    </div>
                  }
                  <div>
                    <span className='stall-name'>{item.title}</span>
                    {item.isListingInCommkit &&
                      <span className='ml-2 commkit-wrapper zoom-in-out-box'>
                        <img src={commkit} height="23px" className='pointer' onClick={() => navToStall(item.stallSlug)} />
                      </span>
                    }
                  </div>
                  <div className='font-20 short-desc'>{item.shortDescription}</div>
                </div>
                <div className='col-3 pt-2 d-flex'>
                  <div onClick={(e) => navToMessage(e, item.id)} >
                    <img src={send} className='pointer icon-style-share' />
                    <div className='font-13'>Direct</div>
                  </div>
                  <div className='pl-4' onClick={(e) => openShareDialog(e, item.id)} >
                    <img src={share} className='pointer icon-style-share' />
                    <div className='font-13'>Share</div>
                  </div>
                  <div className='pl-4'>
                    <FxPopper image={info}
                      popupId={`localdir-${item.id}`}>
                      <div className='p-3 card-style'>
                        <div className='row'>
                          <span className='col-1'><i className="fas fa-address-card"></i></span>
                          <span className='col-11 pl-1'>{item.address}</span>
                        </div>
                        <div className='pt-1 row'>
                          <span className='col-1'><i className="fas fa-envelope"></i></span>
                          <span className='col-11 pl-1'>{item.contactEmail}</span>
                        </div>
                        {item.isShowPhone == true &&
                          <div className='pt-1 row'>
                            <span className='col-1'><i className="fas fa-phone-alt"></i></span>
                            <span className='col-11 pl-1'>{item.contactMobile}</span>
                          </div>
                        }
                        <div className='pt-1 row'>
                          <span className='col-1'><i className="fas fa-user"></i></span>
                          <span className='col-11 pl-1'>{item.contactName}</span>
                        </div>
                      </div>
                    </FxPopper>
                    <div className='font-13 pl-1 pt-1'>Info</div>
                  </div>
                </div>
              </div>
              {item.longDescription &&
                <div className='px-4 py-3 font-24 long-desc'>{item.longDescription}</div>
              }
              {!item.longDescription &&
                <div className='px-4 py-3 font-24 long-desc'>{item.shortDescription}</div>
              }
            </Card>
          </div>
        ))
        }
        {localBusinessList.length == 0 &&
          <div className='d-flex justify-content-center align-items-center no-listing font-20 color-aaa'>
            No Listings Available
          </div>
        }
      </div>

      {/* Mobile View */}
      <div className='mobileview px-4 py-5'>
        <div className='row justify-content-center mt-2'>
          <div className='row justify-content-between align-items-center add-post'>
            <div className='font-20'>Local Business Directory</div>
            <div className='text-right'>
              <Fab color="default" variant="extended" onClick={openForm}>
                <i className="fas fa-plus pr-2"></i>
                <b>POST</b>
              </Fab>
            </div>
          </div>
        </div>
        {localBusinessList.length > 0 && localBusinessList.map((item: any, i) => (
          <div className='row justify-content-center mt-3 pointer' key={i}>
            <Card className='business-card'>
              <div className='row header-wrapper px-2 py-3'>
                <div className='col-8'>
                  {item.premium == true &&
                    <div className="premium-wrapper">
                      <div className="premium-style">Premium</div>
                    </div>
                  }
                  <div>
                    <span className='stall-name'>{item.title}</span>
                    {item.isListingInCommkit &&
                      <span className='ml-2 commkit-wrapper zoom-in-out-box'>
                        <img src={commkit} height="16px" onClick={() => navToStall(item.stallSlug)} />
                      </span>
                    }
                  </div>
                  <div className='font-13 short-desc'>{item.shortDescription}</div>
                </div>
                <div className='col-4 pt-1 d-flex justify-content-between pl-0'>
                  <div className='text-center' onClick={(e) => navToMessage(e, item.id)} >
                    <img src={send} className='pointer icon-style-share' />
                    <div className='font-10'>Direct</div>
                  </div>
                  <div className='text-center' onClick={(e) => openShareDialog(e, item.id)}>
                    <img src={share} className='pointer icon-style-share' />
                    <div className='font-10'>Share</div>
                  </div>
                  <div className='text-center'>
                    <div className='d-flex justify-content-center'>
                      <FxPopper image={info}
                        popupId={`localdir-${item.id}`}>
                        <div className='p-3 card-style'>
                          <div className='flex'>
                            <span><i className="fas fa-address-card"></i></span>
                            <span className='pl-2'>{item.address}</span>
                          </div>
                          <div className='pt-1 flex'>
                            <span><i className="fas fa-envelope"></i></span>
                            <span className='pl-2'>{item.contactEmail}</span>
                          </div>
                          {item.isShowPhone == true &&
                            <div className='pt-1 flex'>
                              <span><i className="fas fa-phone-alt"></i></span>
                              <span className='pl-2'>{item.contactMobile}</span>
                            </div>
                          }
                          <div className='pt-1 flex'>
                            <span><i className="fas fa-user"></i></span>
                            <span className='pl-2'>{item.contactName}</span>
                          </div>
                        </div>
                      </FxPopper>
                    </div>
                    <div className='font-10'>Info</div>
                  </div>
                </div>

              </div>
              {item.longDescription &&
                <div className='px-2 py-2 font-16 long-desc'>{item.longDescription}</div>
              }
              {!item.longDescription &&
                <div className='px-2 py-2 font-16 long-desc'>{item.shortDescription}</div>
              }
            </Card>
          </div>
        ))
        }
        {localBusinessList.length == 0 &&
          <div className='d-flex justify-content-center align-items-center no-listing font-20 color-aaa'>
            No Listings Available
          </div>
        }
      </div>

      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={FormOpen} onClose={handleForm}>
        <LocalDrForm handleForm={handleForm} ownerId={userId} />
      </Drawer>
      <Dialog
        open={shareOpen}
        maxWidth="sm"
        fullWidth
        onClose={closeShareDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ShareOptions closeShareDialog={closeShareDialog} id={localBusinessDirectoryId} />
      </Dialog>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
        <MessageContainer postId={postId} handleMessageClose={handleMessageClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openLogin} onClose={toggleDrawerLogin}>
        <LoginContainer handleLoginClose={handleLoginClose} onLoginCallBack={onLoginCallBack} />
      </Drawer>
    </div>
  );
}
export default LocalDrList;