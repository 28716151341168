import React, { useState } from 'react';
import './StallListingBehavior.scss';
import Card from '@material-ui/core/Card';
import dummyImage from "./../../../assets/images/dummy.png";
import { FxCurrency, FxHumanDateDisplay, FxPopup } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import ImageSlides from '../../general/ImageSlides/ImageSlides';

const StallListingBehavior = (props: any) => {
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});

  const openLink = (e, link) => {
    e.stopPropagation();
    let substring = "https";
    let result = link.search(new RegExp(substring)) != -1;
    if (result) {
      window.open(link, '_blank');
    } else {
      window.open(`https://${link}`, '_blank');
    }
  }
  const openImages = (item: any) => {
    let config = {
      api: `portal/sale/${item.id}/images`,
      title: item.title
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
  }

  return (
    <div className="listing-behaviour-ui">
      {/* Desktop View */}
      <div className="pt-5 d-flex justify-content-center">
        <Card className="px-3 py-3 business-card">
          <div className='row'>

            <div className="pointer col-3" onClick={() => openImages(props.item)}>
              {!props.item.imageUrl && <img src={dummyImage} alt="Item" className="itemImg"></img>}
              {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" className="itemImg"></img>}
            </div>
            <div className=' col-9 pl-4 d-flex flex-column justify-content-between'>
              <div>
                <div className="font-22 itemname-style">
                  {props.item.title}
                </div>
                <div className='font-16 itemdesc-style'>
                  {props.item.description}
                </div>
                <div className='d-flex justify-content-between align-items-center pt-4'>
                  <div>
                    {props.item.price > 0 &&
                      <div className='font-17 price-ui'>
                        <FxCurrency value={props.item.price} />
                      </div>
                    }
                  </div>
                  <div>
                    {
                      props.item.showLink &&
                      <div>
                        <Button variant='contained' color='primary' size='small' className='button-style' onClick={(e) => openLink(e, props.item.linkText)}>
                          {props.item.linkLabel}
                        </Button>
                      </div>
                    }
                  </div>
                </div>


              </div>

              {/* {props.item.isStart &&
              <div>
                <span className="float-right text-danger font-12 shopstatus-style">
                  <span>Starts
                    <span className="pl-1">
                      <FxHumanDateDisplay value={props.item.startDateTime} displayType="datetime" /></span>
                  </span>
                </span>
              </div>
            } */}
            </div>
          </div>
          {/* <div className="font-12 text-success shopstatus-style pl-2">
            {props.item.description}
          </div> */}
          {/* <div className='text-right'>
            <Button variant='outlined' size='small' className='button-style' onClick={(e) => props.navToListingBehavior(e, props.item.id)}>Order</Button>
          </div> */}
        </Card>
      </div>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
    </div>
  );
}
export default StallListingBehavior;