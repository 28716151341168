import React, { useEffect, useState } from 'react';
import './PendingPaymentList.scss';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@material-ui/core';
import { http, AlertService, FxCurrency, FxDateDisplay, formatService, FxDate, FxSelect, useNavigationService } from '../../fx-core';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { NoRecords } from '../../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const PendingPaymentList = () => {
  const { routeData, back } = useNavigationService();
  let defaultContext: any = {
    paymentDate: formatService.getCurrentDateForServer(),
    paymentStatus: "",
    communityId: 0
  };
  const paymentStatus = [
    { code: "", text: "All" },
    { code: "Pending", text: "Pending" },
    { code: "Paid", text: "Paid" }
  ];
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [payments, setPayments] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selPaymentId, setSelPaymentId] = useState(0);
  const [communityList, setCommunityList] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    if (item.name == "paymentDate") {
      getPendingPayments({ "paymentDate": formatService.getDateStringForServer(item.value), "paymentStatus": currentContext.paymentStatus, communityId: currentContext.communityId });
    }
    else if (item.name == "paymentStatus") {
      getPendingPayments({ "paymentDate": formatService.getDateStringForServer(currentContext.paymentDate), "paymentStatus": item.value, communityId: currentContext.communityId });
    }
    else if (item.name == "communityId") {
      getPendingPayments({ "paymentDate": formatService.getDateStringForServer(currentContext.paymentDate), "paymentStatus": currentContext.paymentStatus, communityId: item.value });
    }
  };

  const handleItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getPendingPayments = (data: any) => {
    let inputData = {
      paymentDate: data.paymentDate,
      paymentStatus: data.paymentStatus,
      communityId: data.communityId
    };
    let apiOptions: any = {
      url: `payment/pending`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      let result = res.data;
      for (let item of result) {
        if (item.entityTypeId == 1) {
          item.entityType = "Stall Subscription";
        }
        else if (item.entityTypeId == 2) {
          item.entityType = "Local Directory";
        }
        else if (item.entityTypeId == 3) {
          item.entityType = "Account";
        }
        else if (item.entityTypeId == 4) {
          item.entityType = "Live Stream";
        }
        else if (item.entityTypeId == 5) {
          item.entityType = "Live Stream With Recording";
        }
        else if (item.entityTypeId == 6) {
          item.entityType = "Service Request";
        }
      }
      setPayments(res.data);
    })
  };

  const showConfirmDialog = (id: any) => {
    setSelPaymentId(id);
    setConfirmOpen(true);
    setMessage("Are you sure to change the payment status?");
  }

  const handleDialogClose = () => {
    setSelPaymentId(0);
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    handleDialogClose();
    updatePaymentStatus();
  }

  const updatePaymentStatus = () => {
    let apiOptions: any = {
      url: `payment/${selPaymentId}/paid`,
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getPendingPayments(currentContext);
    })
  };

  const navToBack = () => {
    back();
  };

  const getUserRoleCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getUserRoleCommunitiesCallBack(res) });
  };

  const getUserRoleCommunitiesCallBack = (res) => {
    setCommunityList(res.data);
    if (res.data.length > 0) {
      const commuId = res.data[0].id;
      handleItemChange({ communityId: commuId });
      getPendingPayments({ paymentDate: currentContext.paymentDate, paymentStatus: currentContext.paymentStatus, communityId: commuId });
    }
  };

  useEffect(() => {
    if (routeData.communityId > 0) {
      getPendingPayments({ paymentDate: currentContext.paymentDate, paymentStatus: currentContext.paymentStatus, communityId: routeData.communityId });
    }
    else {
      getUserRoleCommunities();
    }
  }, []);

  return (
    <div className='pending-payment-list-ui pt-5'>
      {
        routeData.communityId > 0 &&
        <div className="title-font custom-head pl-4 pt-5">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
              <b>{routeData.communityName}</b>
            </Link>
            <Typography color="primary" className="breadcrumbs-style">Pending Payments</Typography>
          </Breadcrumbs>
        </div>
      }
      {
        !routeData.communityId &&
        <div className="title-font custom-head pl-4 pt-5">
          <Typography color="primary" className="breadcrumbs-style">Pending Payments</Typography>
        </div>
      }
      <div className='row my-4 mx-2'>
        <div className="col-2">
          <FxDate name="paymentDate" variant="outlined" size="small" fullWidth label="Payment Date"
            value={currentContext.paymentDate} onValueChange={handleInputChange} />
        </div>
        <div className="col-3 pt-3">
          <FxSelect name="paymentStatus" variant="outlined" label="Payment Status"
            options={paymentStatus}
            selectedValue={currentContext.paymentStatus}
            valueField="code"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>
        {
          !routeData.communityId &&
          <div className="col-3 pt-3">
            <FxSelect name="communityId" variant="outlined" label="Communities"
              options={communityList}
              selectedValue={currentContext.communityId}
              valueField="id"
              displayField="name"
              size="small"
              fullWidth
              onValueChange={handleInputChange} />
          </div>
        }
      </div>

      <div className='my-4 mx-4 pt-2'>      
        <TableContainer component={Paper}>
          <Table className="table-striped" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Provider Name</TableCell>
                <TableCell>Payment Date</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Entity Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.length == 0 &&
                <TableCell colSpan={9}>
                  <NoRecords />
                </TableCell>
              }
              {payments.length > 0 && payments.map((row: any, i) => (
                <TableRow>
                  <TableCell>
                    {row.providerName}
                  </TableCell>
                  <TableCell>
                    <span>
                      {<FxDateDisplay value={row.createdDate} />}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {row.paymentMode}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {row.paymentStatus}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {row.entityType}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {<FxCurrency value={row.amount} />}
                    </span>
                  </TableCell>
                  <TableCell>
                    {
                      row.paymentStatus == "Pending" &&
                      <span>
                        <Button variant='outlined' size='small' className='button-style'
                          onClick={() => showConfirmDialog(row.id)}>Paid</Button>
                      </span>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default PendingPaymentList;