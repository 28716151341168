import React, { useEffect, useState } from 'react';
import './ReviewCart.scss';
import { FxCurrency, useNavigationService } from './../../fx-core';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { Divider } from '@material-ui/core';

export const ReviewCart = (props) => {
    const dispatch = useDispatch();
    const { navigate } = useNavigationService();

    const cartInfo = useSelector<any, any>(store => store.cart);
    const cart = cartInfo;

    const reduceQtyFromCart = (item) => {
        getModifierAmount(item);
        dispatch({ type: "REDUCE_CART", payload: { stallId: props.stallId, item: item } });
    };

    const addQtyToCart = (item) => {
        getModifierAmount(item);
        dispatch({ type: "ADD_CART", payload: { stallId: props.stallId, item: item } });
    };

    const getModifierAmount = (item) => {
        if (item.customizedDetails && item.customizedDetails.length > 0) {
            let totalModifierAmt = _.sumBy(item.customizedDetails, 'stallItemCustomizeDetailPrice');
            item.modifierAmt = totalModifierAmt;
        }
    }

    const backToStallItems = () => {
        let navData = {
            targetRoute: '/stalls/' + props.stallSlug,
            targetTitle: "Stall",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    };

    useEffect(() => {
    }, [cartInfo]);

    return (
        <div className="reviewcart-ui">
            <h3 className="cart-title">
                <span>Order Summary</span>
            </h3>

            <div className='row pt-2 pb-3'>
                <span className='col-7 text-right font-15 font-weight-bold'><u>{props.stallName}</u></span>
                <span className='col-5 text-right'>
                    <a className="link-btn font-13 px-2" onClick={backToStallItems}>Add More Items</a>
                </span>
            </div>
            <div className="cart-inner">
                <div className="cart-item m-0">
                    {
                        cart.items.length > 0 && cart.items.map((item, i) => (
                            <div className="row m-0 mb-2" key={i}>
                                <div className="col-md-8 col-6 px-1">
                                    <span className="cart-text text-truncate bold-text" title={item.itemName}>{item.itemName}</span>
                                    {
                                        item.groupedCustomDetails && item.groupedCustomDetails.length > 0 && item.groupedCustomDetails.map((custom, k) => (
                                            <div>
                                                <span className='color-txt font-11'>{custom.key} - </span>
                                                {
                                                    custom.displayModifiers.length > 0 && custom.displayModifiers.map((detail, idx) => (
                                                        <span className='color-txt font-11'>{detail.description}
                                                            {detail.price > 0 &&
                                                                <span> - <FxCurrency value={detail.price} /></span>
                                                            }
                                                            {idx != (custom.displayModifiers.length - 1) &&
                                                                <span>,</span>
                                                            }
                                                        </span>
                                                    ))
                                                }
                                                {k != (item.groupedCustomDetails.length - 1) &&
                                                    <span className='color-txt font-11'>,</span>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="col-6 col-md-4 px-1 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between" style={{ width: 80, height: 29 }}>
                                        <span className="count-minus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(item)} style={{ flex: 1 }}>
                                            <i className="fas fa-minus-square"></i>
                                        </span>
                                        <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer"
                                            style={{ flex: 1 }}>{item.quantity}</span>
                                        <span className="count-plus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(item)} style={{ flex: 1 }}>
                                            <i className="fas fa-plus-square"></i>
                                        </span>
                                    </div>
                                    <span className='font-14'>
                                        <FxCurrency value={item.totalPrice} />
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                    {cart && cart.items.length == 0 &&
                        <div>No items in the cart</div>
                    }

                </div>
            </div>
            {cart && cart.totalAmount > 0 &&
                <div className="cart-footer">
                    <div className="row mb-2">
                        <div className="col-md-4 col-6">
                            <div className="sub-text">
                                <h6>Total</h6>
                            </div>
                        </div>
                        <div className="col-md-8 col-6 text-right">
                            <FxCurrency value={cart.totalAmount} />
                        </div>
                    </div>
                    <a className="btn btn-block btn-xl text-white confirm-btn" onClick={props.confirmOrder}>Confirm
                        <span>&rarr;</span>
                    </a>
                </div>
            }
        </div>
    )
};