import React, { useEffect, useState } from 'react';
import './ManageCommunities.scss';
import { http, FxSelect, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import _ from "lodash";

const ManageCommunities = (props) => {
  let defaultItem =
  {
    name: "",
    communityId: props.communityId,
    visibleCommId: -1
    //spclCommId: -1,
    //stallId: props.stallId
  };
  const [item, setItem] = useState(defaultItem);
  //const [specialCommunity, setSpecialCommunity] = useState([]);
  const [visibleCommunity, setVisibleCommunity] = useState([]);
  const [error, setError] = useState(false);
  const [stallCommunityMapList, setStallCommunityMapList] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  /*const getSpecialCommunity = () => {
    let apiOptions: any = {
      url: `community/special/loggeduser`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      //const sortedtResult: any = _.orderBy(result, ['tier'], ['desc']);
      setSpecialCommunity(result);
      getCommunityById();
    })
  }*/

  const getCommunityById = () => {
    let apiOptions: any = {
      url: `community/${props.communityId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      const data = { name: result.name };
      setItem(prevState => {
        return { ...prevState, ...data }
      });
    })
  }

  const getVisibleCommunity = () => {
    let inputData = {
      id: props.communityId
    };
    let apiOptions: any = {
      url: `community/visiblecommunity/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      for (let item of result) {
        if (item.id > 0)
          item.name = item.name + " " + "(Tier-" + item.tier + ")";
      }
      setVisibleCommunity(result);
    })
    getStallCommunityMapByStallId();
  }

  const getStallCommunityMapByStallId = () => {
    let apiOptions: any = {
      url: `stallcommunitymap/stall/${props.stallId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setStallCommunityMapList(result);
      for (let item of result) {
        if (item.communityTypeId == 1) { //Normal
          const data = { visibleCommId: item.communityId };
          handleItemChange(data);
        }
        /*else if (item.communityTypeId == 2) { // Special
          const data = { spclCommId: item.communityId };
          handleItemChange(data);
        }*/
      }
    })
  }


  const handleClose = () => {
    props.handleManageCommunityClose();
  }

  const saveStallCommunityMap = () => {
    setError(false);
    /*if (!item.visibleCommId || !item.spclCommId) {
      setError(true);
      return;
    }*/

    if (stallCommunityMapList.length == 0 && item.visibleCommId == -1 /*&& item.spclCommId == -1*/) {
      AlertService.showErrorMsg("Please select visible community.");
      return;
    }

    let mapList: any = [];
    let visCommyuItem: any = _.find(visibleCommunity, { id: item.visibleCommId });
    let visibleLocationId = visCommyuItem ? visCommyuItem.locationId : 0;
    let visItemObj: any = _.find(stallCommunityMapList, { communityTypeId: 1 });
    if (visItemObj && visItemObj.id > 0) {
      visItemObj.communityId = item.visibleCommId;
      visItemObj.locationId = visibleLocationId;
      mapList.push(visItemObj);
    }
    else {
      if (item.visibleCommId > 0) {
        let visibleStallCommMap = {
          stallId: props.stallId,
          communityId: item.visibleCommId,
          locationId: visibleLocationId
        };
        mapList.push(visibleStallCommMap);
      }
    }

    /*let splCommuItem: any = _.find(specialCommunity, { id: item.spclCommId });
    let specialLocationId = splCommuItem ? splCommuItem.locationId : 0;
    let itemObj: any = _.find(stallCommunityMapList, { communityTypeId: 2 });
    if (itemObj && itemObj.id > 0) {
      itemObj.communityId = item.spclCommId;
      itemObj.locationId = specialLocationId;
      mapList.push(itemObj);
    }
    else {
      if (item.spclCommId > 0) {
        let spclStallCommMap = {
          stallId: props.stallId,
          communityId: item.spclCommId,
          locationId: specialLocationId
        };
        mapList.push(spclStallCommMap);
      }
    }*/

    let inputData = {
      stallCommunityMaps: mapList
    }
    let apiOptions: any = {
      url: `stallcommunitymap/add`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      saveStallCommunityMapCallBack(res.data);
    })
  };

  const saveStallCommunityMapCallBack = (res) => {
    AlertService.showSuccessMsg();
    getStallCommunityMapByStallId();
  }

  useEffect(() => {
    //getSpecialCommunity();
    getCommunityById();
    getVisibleCommunity();
  }, []);

  return (
    <div className='managecommunities-ui confirmContainer'>
      <div className="row justify-content-between title-font px-3">
        <div className="py-1 pl-3">
          <span>Manage Communities</span>
        </div>
        <div className="text-right pointer my-auto">
          <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
        </div>
      </div>

      <div className="row my-3 p-3 justify-content-between p-0">
        <div className="col-12 py-3">
          <span>Default Community Name : {item.name}</span>
        </div>
        <div className="col-12 py-3">
          <FxSelect name="visibleCommId" variant="outlined" label="Visible Community"
            options={visibleCommunity}
            selectedValue={item.visibleCommId}
            valueField="id"
            displayField="name"
            size="small"
            onValueChange={handleInputChange} />
          {error && !item.visibleCommId &&
            <div className="validation-msg">Visible Community is required</div>
          }
        </div>
        {/* <div className="col-12 py-3">
          <FxSelect name="spclCommId" variant="outlined" label="Special Community"
            options={specialCommunity}
            selectedValue={item.spclCommId}
            valueField="id"
            displayField="name"
            size="small"
            onValueChange={handleInputChange} />
          {error && !item.spclCommId &&
            <div className="validation-msg">Special Community is required</div>
          }
        </div> */}
      </div>
      <div className="px-3 py-2 drawer-footer">
        <Button variant="contained" color="primary" fullWidth className='btn-style' onClick={saveStallCommunityMap}>
          Save
        </Button>
      </div>
    </div>
  );
}
export default ManageCommunities;