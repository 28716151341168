import React, { useEffect, useState } from 'react';
import './Stall.scss';
import StallHeader from './StallHeader/StallHeader';
import StallItem from './StallItem/StallItem';
import { FxInput } from './../fx-core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ItemFilters from './ItemFilters/ItemFilters';
import ItemFiltersMobile from './ItemFiltersMobile/ItemFiltersMobile';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { useHistory } from "react-router-dom";
import noodles from '../../assets/img/noodles.jpg';
import vegetablecurry from '../../assets/img/vegetablecurry.jpg';
import chutney from '../../assets/img/chutney.jpg';
import butterchicken from '../../assets/img/butterchicken.jpg';
import gulabjamun from '../../assets/img/gulabjamun.jpg';
import roti from '../../assets/img/roti.jpg';
import { http, formatService, general, useNavigationService, localstorage } from '../fx-core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import comingsoon from '../../assets/comingsoon.svg';
import filtericon from '../../assets/filtericon.svg';
import firstset from '../../assets/firstset.svg';
import secondset from '../../assets/secondset.svg';
import thirdset from '../../assets/thirdset.svg';
import ListingServiceCard from '../ListingServiceHome/ListingServiceCard/ListingServiceCard';
import ListingDetail from '../ListingDetail/ListingDetail';
import StallBookingBehavior from './StallBookingBehavior/StallBookingBehavior';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import StallListingBehavior from './StallListingBehavior/StallListingBehavior';
import ListingSaleBehavior from './ListingSaleBehavior/ListingSaleBehavior';
import StallBookingEntry from './StallBookingEntry/StallBookingEntry';
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabs: {
            "& .MuiButtonBase-root.MuiTab-root": {
                fontSize: 15,
            },
            "& .MuiTab-root": {
                fontSize: 15,
                color: "#FFFFFF",
                width: 100
            },

            "& .Mui-selected": {
                outline: 'none',
                background: "white",
                fontWeight: "bold",
                color: "#f69516"
            }
        },
        tabsmobile: {
            "& .MuiButtonBase-root.MuiTab-root": {
                fontSize: 12,
            },
            "& .MuiTab-root": {
                fontSize: 12,
                color: "#FFFFFF",
                width: 100
            },

            "& .Mui-selected": {
                outline: 'none',
                background: "white",
                fontWeight: "bold",
                color: "#f69516"
            }
        },
        drawerPaper: {
            height: '60%',
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
        },
    }),
);

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const customClass = props.customClass || "p-3";
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                //   <Box p={2}>
                <div className={customClass}>
                    {children}
                </div>
                //   </Box>
            )}
        </div>
    );
}

const Stall = (props: any) => {
    const dispatch = useDispatch();
    const cartData = useSelector<any, any>(s => s.cart);
    const listingCartData = useSelector<any, any>(s => s.listingCart);
    const communityData = useSelector<any, any>(s => s.community);
    const classes = useStyles();
    const defaultItem = {
        searchText: "",
        categoryIds: "",
        tagIds: ""
    };
    const serviceBehaviors = [
        { code: "ItemWise", text: "Item Sales", displayOrder: 1, canShow: true },
        { code: "Listings", text: "Listings", displayOrder: 2, canShow: true },
        { code: "Bookings", text: "Bookings", displayOrder: 3, canShow: true }
    ];
    const [item, setItem] = useState(defaultItem);
    const { routeData, navigate, navigateRoot, back, goTo } = useNavigationService();
    const [serviceId, setServiceId] = useState(props.match.params.serviceId ? parseInt(props.match.params.serviceId) : 0);
    const [stallId, setStallId] = useState(props.match.params.stallId ? parseInt(props.match.params.stallId) : 0);
    const [code, setCode] = useState("");
    const [services, setServices] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [serviceName, setServiceName] = useState(null);
    const [stallName, setStallName] = useState(null);
    const [value, setValue] = useState(0);
    const [listingDetails, setListingDetails] = useState([]);
    const [detailComponentOpen, setDetailComponentOpen] = useState(false);
    const [saleOrBookingId, setSaleOrBookingId] = useState(props.match.params.saleOrBookingId ? parseInt(props.match.params.saleOrBookingId) : 0);
    const [selSaleId, setSelSaleId] = useState(0);
    const [stallBookings, setStallBookings] = useState([]);
    const [stallLinkUrl, setStallLinkUrl] = useState(null);
    const [showListingSale, setShowListingSale] = useState(false);
    const [from, setFrom] = useState(props.match.params.from ? props.match.params.from : "")
    const [selBookingId, setSelBookingId] = useState(0);
    const [bookingEntry, setBookingEntry] = useState(false);
    const [metaInfo, setMetaInfo] = useState({ metaTitle: "", metaDescription: "" });

    const navToStallHome = () => {
        back();
    }
    const navToCustomerDashboard = (item: any) => {
        let navData = {
            targetRoute: '',
            targetTitle: "Dashboard",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigateRoot(navData);
    }

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        getStallItemsByStallId({ "searchText": item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getStallBySlug = () => {
        const slug = props.match.params.stallSlug;
        let apiOptions: any = {
            url: `portal/stalls/${slug}`,
            data: {}
        };
        http.get(apiOptions).then(res => {
            getStallBySlugCallBack(res);
        });
    }

    const getStallBySlugCallBack = (res) => {
        let result = res.data;
        if (result && result.stall) {
            let stall = result.stall;
            let service = result.stallService;
            setMetaInfo({ metaTitle: stall.metaTitle, metaDescription: stall.metaDescription });
            setServiceId(service.serviceId);
            setServiceName(service.serviceName);
            setStallId(stall.id);
            setStallName(stall.name);
        }
    };

    const getServiceById = () => {
        let inputData = {
            id: serviceId
        };
        let apiOptions: any = {
            url: `portal/service/edit/${inputData.id}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            setServiceName(response.data.serviceName);
        });
        getStallById();
    }

    const getStallById = () => {
        let inputData = {
            id: stallId
        };
        let apiOptions: any = {
            url: `portal/stall/${inputData.id}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            setStallName(response.data.name);
        });
        //getStallServices();
        getServiceBehavior();
    }

    const getServiceBehavior = () => {
        let apiOptions: any = {
            url: `portal/servicebehavior/stall/${stallId}`,
            data: {}
        };
        http.get(apiOptions).then(res => {
            getServiceBehaviorCallBack(res);
        });
    }

    const getServiceBehaviorCallBack = (res) => {
        let result = res.data;
        let serviceTabs: any = serviceBehaviors.map(s => {
            if (s.code == result.defaultBehaviorCode) {
                s.displayOrder = 0;
            }
            return s;
        });
        serviceTabs = _.orderBy(serviceTabs, ['displayOrder'], ['asc'])
        setServices(serviceTabs);
        getAvailableBehaviorForStall();
    };

    const getAvailableBehaviorForStall = () => {
        let inputData = {
            stallId: stallId,
            currentDate: formatService.getCurrentDateForServer()
        }
        let apiOptions: any = {
            url: `portal/stalls/available/behavior`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getAvailableBehaviorForStallCallBack(res);
        });
    }

    const getAvailableBehaviorForStallCallBack = (res) => {
        const result = res.data;
        let serviceTabs: any = serviceBehaviors.map(s => {
            const code = s.code;
            let count = result[code][code];
            if (count <= 0) {
                s.canShow = false;
            }
            return s;
        });
        serviceTabs = serviceTabs.filter(s => s.canShow);
        if (serviceTabs.length > 0) {
            const featuredTypeObj = localstorage.getItem('featuredType');
            if (featuredTypeObj) {
                const featuredType: any = JSON.parse(featuredTypeObj);
                if (featuredType.type == "listing" && featuredType.id > 0) {
                    let index = _.findIndex(serviceTabs, { code: "Listings" });
                    setCode("Listings");
                    callDetailsByService("Listings");
                    setValue(index); //Hot coded. TODO when add extra behavior
                }
                else if (featuredType.type == "booking" && featuredType.id > 0) {
                    let index = _.findIndex(serviceTabs, { code: "Bookings" });
                    setCode("Bookings");
                    setValue(index);
                    callDetailsByService("Bookings");
                }
                else {
                    setCode(serviceTabs[0].code);
                    callDetailsByService(serviceTabs[0].code);
                }
                localstorage.removeItem('featuredType');
            }
            else {
                setCode(serviceTabs[0].code);
                callDetailsByService(serviceTabs[0].code);                
            }
        }
        else {
            setCode("ItemWise"); // Stall Redirect URL -> Default first one if no items or listing 
        }
        setServices(serviceTabs);
    }

    const getStallServices = () => {
        let apiOptions: any = {
            url: `portal/stall/${stallId}/servicelist`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            let serviceTabs: any = [];
            let result = response.data.services;
            if (result && result.length > 0) {
                let itemSalesItem = result.filter(i => i.serviceCode == "Ready To Eat" || i.serviceCode == "Food Bag" || i.serviceCode == "Fresh Produce");
                if (itemSalesItem && itemSalesItem.length > 0) {
                    serviceTabs.push({ code: "itemsales", text: "Item Sales" });
                }
                let listingItem = result.filter(i => i.serviceCode == "Garage Sale" || i.serviceCode == "Garden" ||
                    i.serviceCode == "Pet" || i.serviceCode == "Professional" || i.serviceCode == "CreativeCraft");
                if (listingItem && listingItem.length > 0) {
                    serviceTabs.push({ code: "listings", text: "Listings" });
                }
                // let bookingItem = result.filter(i => i.serviceCode == "ChildCare");
                // if (bookingItem && bookingItem.length > 0) {
                //     serviceTabs.push({ code : "bookings", text: "Bookings" });
                // }
                setServices(serviceTabs);
                setCode(serviceTabs[0].code);
                callDetailsByService(serviceTabs[0].code);
            }
        });

    }

    const callbackItemFilter = (filter: any) => {
        handleItemChange({ ["categoryIds"]: filter.categoryIds ? filter.categoryIds.join(",") : "" });
        handleItemChange({ ["tagIds"]: filter.tagIds ? filter.tagIds.join(",") : "" });
        getStallItemsByStallId(filter);
    }

    const getStallItemsByStallId = (filter: any) => {
        let filters = {
            currentDate: formatService.getCurrentDateForServer(),
            categoryIds: filter.categoryIds ? filter.categoryIds.join(",") : item.categoryIds,
            tagIds: filter.tagIds ? filter.tagIds.join(",") : item.tagIds,
            stallId: stallId,
            itemName: filter.searchText ? filter.searchText : ""
        };
        let inputData = { filters }
        let apiOptions: any = {
            url: `portal/stallitembatch/itemsbystall/itemsales`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            let hasCartData = (cartData && cartData.items && cartData.items.length > 0);
            for (let item of result) {
                item.stalltemBatch.imageUrl = general.getImageUrl(item.stalltemBatch.imagePath);
                item.stalltemBatch.quantity = 0;
                if (hasCartData) {
                    let cartItem = _.find(cartData.items, { id: item.stalltemBatch.id });
                    if (cartItem) {
                        item.stalltemBatch.quantity = cartItem.quantity;
                    }
                }
            }
            setItemDetails(result);
        });
    }

    const getStallListingsByStallId = () => {
        let inputData = {
            currentDate: formatService.getCurrentDateTime(),
            stallId: stallId,
            communityId: localstorage.getItem("communityId")
        };
        let apiOptions: any = {
            url: `portal/sale/location/listings`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.items;
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
                let currentDateTime = formatService.getCurrentDateTime();
                let startDateTime = formatService.getConvertedDateTime(item.startDateTime);
                if (currentDateTime < startDateTime) {
                    item.isStart = true;
                }
                else {
                    item.isStart = false;
                }
            }
            setListingDetails(result);
            // if (saleOrBookingId > 0) {
            //     setSelSaleId(saleOrBookingId);
            //     setCode("");
            //     setShowListingSale(true);
            //     setSaleOrBookingId(0);
            // }
            // else if (result.length == 1) { //Only one listing navigate to entry page
            //     let saleId = result[0].id
            //     setSelSaleId(saleId);
            //     setCode("");
            //     setShowListingSale(true);
            // }
        });
    }

    const getBookingsByStallId = () => {
        let inputData = {
            filters: {
                stallId: stallId
            }
        };
        let apiOptions: any = {
            url: `portal/stall/bookings`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            let list: any = []
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
                if(item.startDate && item.allowBookingBeforeNDays > 0) {
                    const currentDate = formatService.getCurrentDateForServer();
                    const startDate = formatService.getConvertedDate(item.startDate);
                    const beforNDate = formatService.substractDays(startDate, item.allowBookingBeforeNDays);
                    if(startDate <= currentDate || (startDate > currentDate && currentDate >= beforNDate)) {
                        list.push(item);
                    }
                }
                else {
                    list.push(item);
                }
            }
            setStallBookings(list);
            // if (saleOrBookingId > 0) {
            //     setSelBookingId(saleOrBookingId);
            //     setCode("");
            //     setBookingEntry(true);
            //     setSaleOrBookingId(0);
            // }
            // else if (result.length == 1) { //Only one booking navigate to entry page
            //     let bookingId = result[0].id
            //     setSelBookingId(bookingId);
            //     setCode("");
            //     setBookingEntry(true);
            // }
        });
    }

    const openItems = (item: any) => {
        setCode(item.code);
        handleItemChange({ "searchText": "" });
        callDetailsByService(item.code);
    }

    const resetDefaultVal = () => {
        setShowListingSale(false);
        setSelSaleId(0);
        setBookingEntry(false);
        setSelBookingId(0);
    };

    const callDetailsByService = (code: any) => {
        resetDefaultVal();
        if (code == 'ItemWise') {
            let filter = {
                categoryIds: item.categoryIds,
                tagIds: item.tagIds
            };
            getStallItemsByStallId(filter);
        }
        else if (code == 'Listings') {
            getStallListingsByStallId();
        }
        else if (code == 'Bookings') {
            getBookingsByStallId();
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [openfilter, setOpenfilter] = useState(false);
    const toggleDrawerfilter = () => {
        setOpenfilter(!openfilter);
    };

    const navToDetail = (event, id: any) => {
        event.stopPropagation();
        setSelSaleId(id);
        setDetailComponentOpen(true);
    }

    const handleDetailClose = () => {
        setDetailComponentOpen(false);
    }

    const navToListingSale = (id: any) => {
        setSelSaleId(id);
        let parent = 'listingservicehome';
        let navData = {
            targetRoute: '/sale/' + id + '/' + parent,
            targetTitle: "Sale",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    };

    const callBackData = (data: any) => {
        if (data) {
            setStallLinkUrl(data.linkUrl);
        }
    }

    const handleStallItemCustomized = (customizedDetails, id) => {
        if (customizedDetails.length > 0) {
            let stallItemBatchInstance = _.result(_.find(itemDetails, function (i: any) {
                return i.stalltemBatch.id === id;
            }), 'stalltemBatch');
            let stallItemBatchObj = { ...stallItemBatchInstance };
            stallItemBatchObj.quantity = 1;
            stallItemBatchObj.customizedDetails = customizedDetails;
            //to display by grouped Group Name
            let displayDetails = _.map((_.groupBy(customizedDetails, function (i) {
                return i.groupName
            })), function (items, key) { return { key: key, displayModifiers: items }; });
            stallItemBatchObj.groupedCustomDetails = displayDetails;
            stallItemBatchObj.uid = stallItemBatchObj.id + "_" + uuidv4();
            let totalModifierAmt = _.sumBy(customizedDetails, 'stallItemCustomizeDetailPrice');
            stallItemBatchObj.modifierAmt = stallItemBatchObj.quantity * totalModifierAmt;
            if (listingCartData.cartCount > 0) {
                dispatch({ type: "CLEAR_LISTING_CART", payload: {} }); //Clear the listing order items
            }
            dispatch({ type: "ADD_CART", payload: { stallId: stallId, item: stallItemBatchObj } });
        }
    }

    const updateQuntity = () => {
        let cartItemsArr: any = itemDetails.map((item: any) => {
            let cartItem = _.find(cartData.items, { id: item.stalltemBatch.id });
            if (cartItem) {
                item.stalltemBatch.quantity = cartItem.quantity;
            }
            else {
                item.stalltemBatch.quantity = 0;
            }
            return item;
        });
        setItemDetails(cartItemsArr);
    };

    const navToListingBehavior = (event, saleId) => {
        event.stopPropagation();
        setCode("");
        setSelSaleId(saleId);
        setShowListingSale(true);
    }

    const showListingDetails = () => {
        setCode("Listings");
        setSelSaleId(0);
        setShowListingSale(false);
    }

    const navToBookingEntry = (event, bookingId) => {
        event.stopPropagation();
        setCode("");
        setSelBookingId(bookingId);
        setBookingEntry(true);
    }

    const showBookingDetails = () => {
        setCode("Bookings");
        setSelBookingId(0);
        setBookingEntry(false);
    }

    const getCommunityByCode = () => {
        let inputData = { code: props.match.params.code };

        let apiOptions = {
            url: 'portal/validate/community/code',
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getCommunityByCodeCallBack(res);
        });
    }

    const getCommunityByCodeCallBack = (res) => {
        let result: any = res.data;
        let community: any = result.community;
        localstorage.setItem('imagStorageUrl', result.imageStorageUrl);
        dispatch({ type: "set-community-details", payload: community });
        getStallBySlug();
    }

    useEffect(() => {
        if (communityData.id > 0) {
            if (props.match.params.stallSlug) {
                getStallBySlug();
            }
            else {
                getServiceById(); //TODO: Remove by id
            }
        }
        else {
            getCommunityByCode();
        }
    }, []);

    useEffect(() => {
        if (stallId > 0) {
            getAvailableBehaviorForStall();
        }
    }, [stallId])

    useEffect(() => {
        updateQuntity();
    }, [cartData])

    return (
        <div className="pt-5 pb-4 stall-ui">
            <Helmet>
                {
                    metaInfo.metaTitle &&
                    <title>{metaInfo.metaTitle}</title>
                }
                {
                    metaInfo.metaDescription &&
                    <meta name="description" content={metaInfo.metaDescription} />
                }
                {
                    !metaInfo.metaDescription &&
                    <meta name="description" content="Shop local, made easy" />
                }
            </Helmet>
            <div className="title-font custom-head pt-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
                        <b>Dashboard</b>
                    </span>
                    {routeData.parent != 'dashboard' && <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToStallHome}>
                        <b>{serviceName}</b>
                    </span>}
                    <Typography color="textPrimary" className="breadcrumbs-style">{stallName}</Typography>
                </Breadcrumbs>
            </div>

            {/* Desktop View */}
            <div className="row pt-2 deskview">
                {
                    stallId > 0 &&
                    <div className="col-2 pt-4" style={{ background: "#D7D7D7" }}>
                        <ItemFilters stallId={stallId} code={code} callbackItemFilter={callbackItemFilter} />
                    </div>
                }
                <div className="col-10 pt-5 pl-0">
                    {
                        stallId > 0 &&
                        <div style={{ background: "#D7D7D7" }}>
                            <StallHeader stallId={stallId} callBackData={callBackData} />
                        </div>
                    }
                    <div>
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                                variant="fullWidth" className={classes.tabs}>
                                {
                                    services.length > 0 && services.map((row: any, i) => (
                                        <Tab label={row.text} {...a11yProps(i)} onClick={() => openItems(row)} style={{ fontWeight: 600, maxWidth: "25%" }} />
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    </div>

                    {code == 'ItemWise' &&
                        <span>
                            <div className="pt-5 pb-2 col-12 text-center px-3 pl-5">
                                <FxInput name="searchText" variant="outlined" label="Search for Items"
                                    fullWidth size="small" value={item.searchText} onValueChange={handleInputChange}
                                />
                            </div>

                            <div className="row px-3 pl-5 pb-5">
                                {
                                    itemDetails.length > 0 && itemDetails.map((item, i) => (
                                        <div className="col-4">
                                            <StallItem item={item} stallId={stallId}
                                                handleStallItemCustomized={handleStallItemCustomized} />
                                        </div>
                                    ))
                                }
                                {
                                    itemDetails.length < 1 &&
                                    <div className="py-5 mx-auto">
                                        <img src={firstset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No items available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {code == 'Listings' &&
                        <span>
                            <div className="px-3 pl-5 pb-5">
                                {
                                    listingDetails.length > 0 && listingDetails.map((item, i) => (
                                        <div>
                                            <StallListingBehavior item={item} navToDetail={navToDetail} navToListingBehavior={navToListingBehavior} />
                                        </div>
                                    ))
                                }
                                {
                                    listingDetails.length < 1 &&
                                    <div className="py-5 mx-auto">
                                        <img src={secondset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {code == 'Bookings' &&
                        <span>
                            <div className="row px-3 pl-5 pb-5">
                                {
                                    stallBookings.length > 0 && stallBookings.map((item, i) => (
                                        <div className="col-4">
                                            <StallBookingBehavior item={item} navToBookingEntry={navToBookingEntry} />
                                        </div>
                                    ))
                                }
                                {
                                    stallBookings.length < 1 &&
                                    <div className="py-5 mx-auto">
                                        <img src={thirdset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No booking available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {
                        showListingSale &&
                        <div className="">
                            <ListingSaleBehavior saleId={selSaleId} serviceId={serviceId} showListingDetails={showListingDetails} />                            
                        </div>
                    }
                    {
                        bookingEntry &&
                        <div className="">
                            <StallBookingEntry bookingId={selBookingId} showBookingDetails={showBookingDetails} />
                        </div>
                    }
                </div>
            </div>


            {/* Tablet View */}
            <div className="row pt-2 tabview">
                {/* <div className="col-2 pt-4" style={{ background: "#D7D7D7" }}>
                    <ItemFilters serviceId={serviceId} serviceCode={serviceCode} callbackItemFilter={callbackItemFilter} />
                </div> */}
                <div className="col-12 pt-5 pl-0">
                    {
                        stallId > 0 &&
                        <div style={{ background: "#F1F1F1" }}>
                            <StallHeader stallId={stallId} serviceId={serviceId} callBackData={callBackData} />
                        </div>
                    }
                    <div>
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                                variant="fullWidth" className={classes.tabsmobile}>
                                {
                                    services.length > 0 && services.map((row: any, i) => (
                                        <Tab label={row.text} {...a11yProps(i)} onClick={() => openItems(row)} style={{ fontWeight: 600, maxWidth: "30%" }} />
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    </div>

                    {code == 'ItemWise' &&
                        <span>
                            <div className="row pl-4 pr-3">
                                <div className="tabview pr-0 col-1 my-auto pt-2">
                                    <img src={filtericon} alt="filtericon" onClick={toggleDrawerfilter}></img>
                                </div>
                                <div className="col-11">
                                    <div className="pt-4 pb-2 col-12 px-0">
                                        <FxInput name="searchText" variant="outlined" label="Search for Items"
                                            fullWidth size="small" value={item.searchText} onValueChange={handleInputChange} />
                                    </div>

                                </div>
                            </div>
                            <div className="px-2 row mx-auto">
                                {
                                    itemDetails.length > 0 && itemDetails.map((item, i) => (
                                        <div className="col-6">
                                            <StallItem item={item} stallLinkUrl={stallLinkUrl} stallId={stallId}
                                                handleStallItemCustomized={handleStallItemCustomized} />
                                        </div>
                                    ))
                                }
                                {
                                    itemDetails.length < 1 &&
                                    <div className="py-4 text-center col-12">
                                        <img src={firstset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No items available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {code == 'Listings' &&
                        <span>
                            <div className="px-2 mx-auto">
                                {
                                    listingDetails.length > 0 && listingDetails.map((item, i) => (
                                        <div>
                                            <StallListingBehavior item={item} navToDetail={navToDetail} navToListingBehavior={navToListingBehavior} />
                                        </div>
                                    ))
                                }
                                {
                                    listingDetails.length < 1 &&
                                    <div className="py-4 text-center col-12">
                                        <img src={secondset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {code == 'Bookings' &&
                        <span>
                            <div className="px-2 row mx-auto">
                                {
                                    stallBookings.length > 0 && stallBookings.map((item, i) => (
                                        <div className="col-6">
                                            <StallBookingBehavior item={item} navToBookingEntry={navToBookingEntry} />
                                        </div>
                                    ))
                                }
                                {
                                    stallBookings.length < 1 &&
                                    <div className="py-4 text-center col-12">
                                        <img src={thirdset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No bookings available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {
                        showListingSale &&
                        <div className="">
                            <ListingSaleBehavior saleId={selSaleId} serviceId={serviceId} showListingDetails={showListingDetails} />
                        </div>
                    }
                </div>
            </div>


            {/* Mobile View */}
            <div className="row pt-2 mobileview">
                {/* <div className="col-2 pt-4" style={{ background: "#D7D7D7" }}>
                    <ItemFilters serviceId={serviceId} serviceCode={serviceCode} callbackItemFilter={callbackItemFilter} />
                </div> */}
                <div className="col-12 pt-5 pl-0">
                    {
                        stallId > 0 &&
                        <div style={{ background: "#F1F1F1" }}>
                            <StallHeader stallId={stallId} serviceId={serviceId} callBackData={callBackData} />
                        </div>
                    }
                    <div className="pl-2">
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                                variant="fullWidth" className={classes.tabsmobile}>
                                {
                                    services.length > 0 && services.map((row: any, i) => (
                                        <Tab label={row.text} {...a11yProps(i)} onClick={() => openItems(row)} style={{ fontWeight: 600, maxWidth: "30%" }} />
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    </div>

                    {code == 'ItemWise' &&
                        <span>
                            <div className="row pl-4 pr-3">
                                <div className="mobileview pr-0 col-1 my-auto pt-2">
                                    <img src={filtericon} alt="filtericon" onClick={toggleDrawerfilter}></img>
                                </div>
                                <div className="col-11">
                                    <div className="pt-4 pb-2 col-12 px-0">
                                        <FxInput name="searchText" variant="outlined" label="Search for Items"
                                            fullWidth size="small" value={item.searchText} onValueChange={handleInputChange} />
                                    </div>

                                </div>
                            </div>
                            <div className="px-2">
                                {
                                    itemDetails.length > 0 && itemDetails.map((item, i) => (
                                        <div className="pl-3">
                                            <StallItem item={item} stallLinkUrl={stallLinkUrl} stallId={stallId}
                                                handleStallItemCustomized={handleStallItemCustomized} />
                                        </div>
                                    ))
                                }
                                {
                                    itemDetails.length < 1 &&
                                    <div className="py-4 text-center">
                                        <img src={firstset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No items available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {code == 'Listings' &&
                        <span>
                            <div className="px-2">
                                {
                                    listingDetails.length > 0 && listingDetails.map((item, i) => (
                                        <div className="pl-3">
                                            <StallListingBehavior item={item} navToDetail={navToDetail} navToListingBehavior={navToListingBehavior} />
                                        </div>
                                    ))
                                }
                                {
                                    listingDetails.length < 1 &&
                                    <div className="py-4 text-center">
                                        <img src={secondset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {code == 'Bookings' &&
                        <span>
                            <div className="px-2">
                                {
                                    stallBookings.length > 0 && stallBookings.map((item, i) => (
                                        <div className="pl-3">
                                            <StallBookingBehavior item={item} navToBookingEntry={navToBookingEntry} />
                                        </div>
                                    ))
                                }
                                {
                                    stallBookings.length < 1 &&
                                    <div className="py-4 text-center">
                                        <img src={thirdset} alt="first set"></img>
                                        <div className="pt-4 font-17"><b> Sorry, No bookings available.</b></div>
                                    </div>
                                }
                            </div>
                        </span>
                    }
                    {
                        showListingSale &&
                        <div className="">
                            <ListingSaleBehavior saleId={selSaleId} serviceId={serviceId} showListingDetails={showListingDetails} />
                        </div>
                    }
                </div>
            </div>
            <Drawer anchor="bottom" open={openfilter} onClose={toggleDrawerfilter} classes={{ paper: classes.drawerPaper, }}>
                <ItemFiltersMobile code={code} callbackItemFilter={callbackItemFilter} />
            </Drawer>
            <Drawer anchor={"right"} open={detailComponentOpen} onClose={handleDetailClose}>
                <ListingDetail handleDetailClose={handleDetailClose} saleId={selSaleId} />
            </Drawer>
        </div>

    );

}
export default Stall;