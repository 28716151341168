import React, { useEffect, useState } from 'react';
import './HeaderMenu.scss';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { localstorage, useNavigationService, http } from '../../fx-core';
import readytoeat from '../../../assets/img/readytoeat.svg';
import foodbag from '../../../assets/img/foodbag.svg';
import freshproduce from '../../../assets/img/freshproduce.svg';
import garagesale from '../../../assets/img/garagesale.svg';
import garden from '../../../assets/img/garden.svg';
import pet from '../../../assets/img/pet.svg';
import professional from '../../../assets/img/professional.svg';
import creativecraft from '../../../assets/img/creativecraft.svg';
import * as _ from 'lodash';

const HeaderMenu = (props) => {
    const iconsMap = {
        "webitemssales": [readytoeat, foodbag, freshproduce],
        "websaledeals": [garagesale, garden, pet, professional, creativecraft],
        //"appointments": ["./assets/icons/childcare.svg"]
    }

    const adminMenuList: any = [
        {
            "controlCode": "communityprofile",
            "parentControlCode": null,
            "controlType": "MENU",
            "controlPosition": "LEFT",
            "display": "Community Profile",
            "sRef": "/community/profile",
            "iconRef": "fas fa-user",
            "translateRef": "",
            "displayOrder": 1
        },
        {
            "controlCode": "commadmindashboard",
            "parentControlCode": null,
            "controlType": "MENU",
            "controlPosition": "LEFT",
            "display": "Dashboard",
            "sRef": "/community/communityadmindashboard",
            "iconRef": "fas fa-home",
            "translateRef": "",
            "displayOrder": 2
        },
        {
            "controlCode": "servicerequests",
            "parentControlCode": null,
            "controlType": "MENU",
            "controlPosition": "LEFT",
            "display": "Service Requests",
            "sRef": "/community/servicerequestlist",
            "iconRef": "fas fa-list",
            "translateRef": "",
            "displayOrder": 3
        },
        {
            "controlCode": "pendingpayments",
            "parentControlCode": null,
            "controlType": "MENU",
            "controlPosition": "LEFT",
            "display": "Pending Payments",
            "sRef": "/community/pendingpaymentlist",
            "iconRef": "fas fa-money-bill-alt",
            "translateRef": "",
            "displayOrder": 4
        },
        {
            controlCode: "specialcommunities",
            parentControlCode: null,
            controlType: "MENU",
            controlPosition: "LEFT",
            display: "Special Communities",
            sRef: "/community/speciallist",
            iconRef: "fas fa-list",
            translateRef: "",
            displayOrder: 5
        }
    ];

    const { navigate } = useNavigationService();
    const [adminMenus, setAdminMenus] = useState([]);
    const [providerMenus, setProviderMenus] = useState([]);
    const [superAdminMenus, setSuperAdminMenus] = useState([]);
    const [regionMenus, setRegionMenus] = useState([]);
    const [zoneMenus, setZoneMenus] = useState([]);
    const [roleCode, setRoleCode] = useState(null);
    const [showCreateStall, setShowCreateStall] = useState(false);

    const getWebMenus = () => {
        let inputData = {
            targetType: 'web'
        };
        let apiOptions = {
            url: 'roles/my/controls/targettype',
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getWebMenusCallback(res);
        });
    };

    const handleAdminMenu = () => {
        let userRoles: any = localstorage.getItem('userRoles');
        userRoles = JSON.parse(userRoles);
        let commuAdminIndex = _.findIndex(userRoles, (r) => { return r === "COMMADMIN" });
        if (commuAdminIndex !== -1) {
            let menuList = adminMenuList;
            let splAdminIndex = _.findIndex(userRoles, (r) => { return r === "SPECIALCOMMADMIN" });
            if (splAdminIndex === -1) {
                menuList = adminMenuList.filter(l => l.controlCode !== "specialcommunities");
            }
            setAdminMenus(menuList);
        }
    };

    const getWebMenusCallback = (res) => {
        let items = _.orderBy(res.data, ['displayOrder'], ['asc']);
        let providerMenuItems = [];
        let superadminMenuItems = [];
        let regionMenuItems = [];
        let zoneMenuItems = [];
        let providerMenuMap = {};
        for (let item of items) {
            setRoleCode(item.roleCode);
            item.icons = iconsMap[item.controlCode] ? iconsMap[item.controlCode] : "";
            if (item.roleCode == "PROVIDER") {
                providerMenuMap[item.controlCode] = item;
            }
            else if (item.roleCode == "SUPERADMIN") {
                superadminMenuItems.push(item);
            }
            else if (item.roleCode == "REGIONALMANAGER") {
                regionMenuItems.push(item);
            }
            else if (item.roleCode == "ZONALMANAGER") {
                zoneMenuItems.push(item);
            }
        }
        for (let key in providerMenuMap) {
            let item = providerMenuMap[key];
            if (item.parentControlCode) {
                let parentMenu = providerMenuMap[item.parentControlCode];
                if (parentMenu) {
                    parentMenu.submenu = parentMenu.submenu || [];
                    parentMenu.submenu.push(item);
                }
            }
            else {
                providerMenuItems.push(item);
            }
        }
        setProviderMenus(providerMenuItems);
        setSuperAdminMenus(superadminMenuItems);
        setRegionMenus(regionMenuItems);
        setZoneMenus(zoneMenuItems);
        getCommunityById();
    };

    const clearMenus = () => {
        setAdminMenus([]);
        setProviderMenus([]);
        setRegionMenus([]);
        setZoneMenus([]);
    };

    const navToSelectedMenu = (item) => {
        let navData = {
            targetRoute: item.sRef,
            targetTitle: item.display,
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
        props.closeHeaderMenu();
    }

    const navToProviderServices = () => {
        props.navToProviderServices();
    };

    const getCommunityById = () => {
        let communityId = localstorage.getItem("communityId");
        let apiOptions = {
            url: `community/${communityId}`,
        };
        http.get(apiOptions).then(res => {
            let result = res.data;
            setShowCreateStall(result.isStallCreation);
        });        
    };

    useEffect(() => {
        if (props.toggleMenu) {
            getWebMenus();
            handleAdminMenu();
        }
        else {
            clearMenus();
        }
    }, [props.toggleMenu]);

    return (
        <div className='mt-4 pt-2'>
            {roleCode != "SUPERADMIN" && showCreateStall &&
                <span className='create-stall p-1 px-2 ml-4' onClick={navToProviderServices}>
                    <i className='fas fa-store'></i>
                    <span className='pl-2'>Create Stall</span>
                </span>
            }

            {
                adminMenus.length > 0 &&
                <div className='mt-4 ml-2'>
                    <div>
                        <span className='font-16 text-bold ml-3'>Admin</span>
                    </div>
                    <List>
                        {
                            adminMenus.map((item: any, i) => (
                                <ListItem key={i} onClick={() => navToSelectedMenu(item)}>
                                    <ListItemIcon><i className={item.iconRef + " header-icon-style"}></i></ListItemIcon>
                                    <ListItemText><span className="pointer">{item.display}</span></ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </div>
            }
            {
                providerMenus.length > 0 &&
                <div className='mt-4 ml-2'>
                    <div>
                        <span className='font-16 text-bold ml-3'>Provider</span>
                    </div>
                    <List>
                        {
                            providerMenus.map((item: any, i) => (
                                <span>
                                    {(!item.parentControlCode && !item.sRef) &&
                                        <ListItem key={i}>
                                            <ListItemText><span>{item.display}</span>
                                                <span className='float-right'>
                                                    {item.icons && item.icons.map((icon, i) => (
                                                        <span className='p-1'><img src={icon} alt="Service Image" width="20" height="20"></img></span>
                                                    ))}
                                                </span>
                                            </ListItemText>
                                            <span className="divider"></span>
                                        </ListItem>
                                    }
                                    {item.submenu && item.submenu.map((menuObj, i) => (
                                        <ListItem key={i} onClick={() => navToSelectedMenu(menuObj)}>
                                            <ListItemIcon><i className={menuObj.iconRef + " header-icon-style"}></i></ListItemIcon>
                                            <ListItemText><span className="pointer">{menuObj.display}</span>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                    {item.sRef &&
                                        <ListItem key={i} onClick={() => navToSelectedMenu(item)}>
                                            <ListItemIcon><i className={item.iconRef + " header-icon-style"}></i></ListItemIcon>
                                            <ListItemText><span className="pointer">{item.display}</span>
                                            </ListItemText>
                                        </ListItem>
                                    }
                                </span>
                            ))
                        }
                    </List>
                </div>
            }
            {
                superAdminMenus.length > 0 &&
                <div className='mt-4 ml-2'>
                    <div className='ml-3'>
                        <span className='font-16 text-bold'>Superadmin</span>
                    </div>
                    <List>
                        {
                            superAdminMenus.map((item: any, i) => (
                                <ListItem key={i} onClick={() => navToSelectedMenu(item)}>
                                    <ListItemIcon><i className={item.iconRef + " header-icon-style"}></i></ListItemIcon>
                                    <ListItemText><span className="pointer">{item.display}</span></ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </div>
            }
            {
                regionMenus.length > 0 &&
                <div className='mt-4 ml-2'>
                    <div className='ml-3'>
                        <span className='font-16 text-bold'>Regional Manager</span>
                    </div>
                    <List>
                        {
                            regionMenus.map((item: any, i) => (
                                <ListItem key={i} onClick={() => navToSelectedMenu(item)}>
                                    <ListItemIcon><i className={item.iconRef + " header-icon-style"}></i></ListItemIcon>
                                    <ListItemText><span className="pointer">{item.display}</span></ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </div>
            }
            {
                zoneMenus.length > 0 &&
                <div className='mt-2 ml-2'>
                    <div className='ml-3'>
                        <span className='font-16 text-bold'>Zonal Manager</span>
                    </div>
                    <List>
                        {
                            zoneMenus.map((item: any, i) => (
                                <ListItem key={i} onClick={() => navToSelectedMenu(item)}>
                                    <ListItemIcon><i className={item.iconRef + " header-icon-style"}></i></ListItemIcon>
                                    <ListItemText><span className="pointer">{item.display}</span></ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </div>
            }
        </div>
    );
};

export default HeaderMenu;