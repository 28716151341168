import React from 'react';
import './FeaturedItemList.scss';
import { Card } from '@material-ui/core';
import dummyImage from "./../../../assets/images/dummy.png";
import { FxCurrency } from '../../fx-core';

export const FeaturedItemList = (props: any) => {

  return (
    <div className='featured-item-list'>
      {/* <div className='dialog-header py-1'>      
          <i className="fas fa-times-circle color-primary font-18 pointer" onClick={props.closeFeaturedDialog}></i>       
      </div> */}
      <div className='d-flex justify-content-between dialog-header py-1'>
        <span className="col-10 color-primary font-weight-bold">
          Featured
        </span>
        <span className="col-2 text-right">
          <i className="fas fa-times-circle color-primary font-18 pointer" onClick={props.closeFeaturedDialog}></i>
        </span>
      </div>

      <div className='mt-5 mb-4'>
        {
          props.items.length > 0 && props.items.map((item: any, i) => (
            <div className='pl-3 mt-4 pointer' key={i}>
              <Card className='business-card p-3' onClick={() => props.navToStallDetail(item)}>
                <div className='row'>
                  <div className='col-4'>
                    {!item.itemImageUrl && <img src={dummyImage} alt="img" className="itemImg" ></img>}
                    {item.itemImageUrl && <img src={item.itemImageUrl} alt="img" className="itemImg"></img>}
                  </div>
                  <div className='col-8'>
                    <div className="pt-2 category-ui">
                      {item.itemName}
                    </div>
                    <div className="pt-2 details-ui d-flex justify-content-between">
                      <span>
                        {
                          item.price > 0 &&
                          <div className="font-17 price-ui">
                            <FxCurrency value={item.price} />
                          </div>
                        }
                        {item.unit &&
                          <div className="font-13">{item.unit}</div>
                        }
                      </span>
                      <span className="float-right mb-2">
                        {!item.stallImageUrl && <i className="fas fa-store-alt font-30" ></i>}
                        {item.stallImageUrl && <img src={item.stallImageUrl} alt="img" className="stallImg"></img>}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ))
        }
      </div>

    </div>
  )
};