import React, { useEffect, useState } from 'react';
import { FxInput, http, AlertService, formValidator } from '../../fx-core';
import './ItemTagForm.scss';
import * as _ from 'lodash';
import { Button } from '@material-ui/core';

const ItemTagForm = (props) => {
  const defaultItem = {
    tagName: "",
    id: props.itemTagId ? props.itemTagId : 0,
    communityId: props.communityId
  };

  const [itemTag, setItemTag] = useState(defaultItem);
  const [errors, setErrors] = useState({});

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleItemChange = (data) => {
    setItemTag(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const getValidations = () => {
    const validationMap = {
      tagName: ["required"]
    };
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: itemTag[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const getItemTagById = () => {
    let apiOptions: any = {
      url: `itemtag/${itemTag.id}`
    };
    http.get(apiOptions).then(res => {
      getItemTagByIdCallBack(res);
    });
  }

  const getItemTagByIdCallBack = (res) => {
    const result = res.data;
    setItemTag(result);
  }

  const saveItemTag = () => {
    const isValid = bulkValidate();
    if (isValid) {
      let apiOptions: any = {
        url: `itemtag/save`,
        data: itemTag
      };
      http.post(apiOptions).then(res => {
        saveItemTagCallBack(res.data);
      });
    }
  };

  const saveItemTagCallBack = (res) => {
    AlertService.showSuccessMsg();
    if (res > 0) {
      handleClose();
    }
  }

  const handleClose = () => {
    props.handleItemTagFormClose();
  }

  useEffect(() => {
    if (itemTag.id > 0) {
      getItemTagById();
    }
  }, []);


  return (
    <div className='itemtagform-ui'>
      <div className='row title-font py-1 px-2 drawer-header'>
        <span className="col-11">
          Item Tag Form
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>

      <div className='p-3 pt-5'>

        <div className='col-12 p-3'>
          <FxInput name="tagName" variant="outlined" fullWidth size="small" label="Tag Name"
            value={itemTag.tagName} onValueChange={handleInputChange} />
          {hasError("tagName", "required") &&
            <div className="error">Tag name is required</div>
          }
        </div>
      </div>
      <div className='px-4 py-2 drawer-footer'>
        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveItemTag}><b>Save</b></Button>
      </div>
    </div>
  );
}
export default ItemTagForm;
