import React, {useState} from 'react';
import { IconButton, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import './FxDate.styles.scss';
import moment from 'moment';

export const FxDate = (props:any) => {
    let defaultVal = null;
    let inputValue = "";
    if(props.value) {
        inputValue = moment(props.value, ['YYYY-MM-DD']).format('DD/MM/YYYY');
        defaultVal = new Date(inputValue);
        
    }
    const [dateVal, setDateVal] = useState(defaultVal);

    if (props.visibility === false) {
        return <></>;
    }
    const { name, onValueChange, variant, endIcon, size = "small", fullWidth } = props;
    let inputProps = {};
    if(endIcon) {
        inputProps={
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <FontAwesomeIcon className="datefieldicon" icon={endIcon}/>
                </IconButton>
              </InputAdornment>
            ),
          };
    }
    const onChange = (event:any) => {
        setDateVal(event);
        let value = event ? moment(event, ['DD/MM/YYYY']).format('YYYY-MM-DD') : "";
        const data = { name: props.name, value };
        onValueChange(data);
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                size={size}              
                fullWidth={props.fullWidth}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label={props.label}
                value={dateVal}
                inputValue={inputValue}
                inputVariant={variant == 'outlined' ? 'outlined' : 'standard'}
                onChange={onChange}
                disabled={props.disabled}
                autoOk={true}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                className={props.className}
                minDate={props.minDate || undefined}
                />
        </MuiPickersUtilsProvider>
    );
};
