const processEnv: any = {
  ...process.env,
  ...((window as any).AppSettings || {}),
};

export const AppSettings = {
  APP_API_URL: processEnv.REACT_APP_API_URL,
  APP_API_BASE_URL: processEnv.REACT_APP_API_BASE_URL,
  LIVE_STREAM_URL: processEnv.REACT_APP_LIVE_STREAM_URL
};
