import React from 'react';
import './PickupAddress.scss';

export const PickupAddress = (props) => {

    return (
        <div>
            <div className="font-14 bold-text pb-2">
                Pickup Address
            </div>
            <address>
                <p className="font-14 p-2 p-font">
                    {props.stall.address}
                </p>
            </address>
            <div className='font-11'>
                {props.pickupDeliveryMsg}
            </div>
        </div>
    );
};