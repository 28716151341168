import React, { useState, useEffect } from "react";
import './CreateServiceRequestContainer.scss';
import { http, FxSelect, AlertService, localstorage, FxDateDisplay, FxDateTimeDisplay, useNavigationService } from "../../fx-core";
import Button from '@material-ui/core/Button';
import _ from "lodash";
import ServiceRequestItemForm from "./ServiceRequestItemForm/ServiceRequestItemForm";
import ServiceRequestConfirm from "./ServiceRequestConfirm/ServiceRequestConfirm";
import ServiceRequestSummary from "./ServiceRequestSummary/ServiceRequestSummary";
import Dialog from '@material-ui/core/Dialog';
import MyStallForm from '../MyStallForm/MyStallForm';
import { Drawer, makeStyles } from "@material-ui/core";
import GardenPetProfArtForm from "../GardenPetProfArtForm/GardenPetProfArtForm";
import SaleForm from "../SaleForm/SaleForm";
import DateIcon from '@material-ui/icons/DateRange';
import AddSaleItemForm from "./AddSaleItemForm/AddSaleItemForm";
import SaleRequestConfirm from "./SaleRequestConfirm/SaleRequestConfirm";
import SaleRequestSummary from "./SaleRequestSummary/SaleRequestSummary";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import SubscriptionPlan from "./SubscriptionPlan/SubscriptionPlan";

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const CreateServiceRequestContainer = (props) => {
  const classes = styles();
  const { navigate, routeData, back } = useNavigationService();

  let defaultContext = {
    showSteps: true,
    selectedStallName: "",
    stallId: 0,
    stallIdVal: 0,
    serviceRequestId: 0,
    subscriptionStatus: "",
    serviceCode: "",
    saleItemCount: 0,
    listingInfoFlag: false,
    subscriptionId: 0,
    serviceBehaviorCode: ""
  };
  let defaultListingInfo = {
    startDate: null,
    startDateTime: null,
    startTime: null,
    endDate: null,
    endDateTime: null,
    endTime: null,
    title: null,
    saleItemDetails: [],
    imagePathList: [],
    description: null,
    showLink: false,
    linkLabel: "",
    linkText: "",
    price: 0    
  };
  const initialList = [];
  const [serviceId, setServiceId] = useState(props.match.params.serviceId ? parseInt(props.match.params.serviceId) : 0);
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem('communityId')));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem('userId')));
  const [stalls, setStalls] = useState(initialList);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemCount, setSelectedItemCount] = useState(0);
  const [requestDetails, setRequestDetails] = useState({});
  const [stallFormOpen, setStallFormOpen] = useState(false);
  const [listingInfo, setListingInfo] = useState(defaultListingInfo);
  const [listingInfoDetail, setListingInfoDetail] = useState(defaultListingInfo);
  const [itemDetails, setItemDetails] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [isCreateStall, setIsCreateStall] = useState(false);

  const handleInputChange = (item) => {
    handleContextChange({ [item.name]: item.value });
  };

  const handleContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const checkSerReqExistsForStall = () => {
    if(currentContext.stallId == 0) {
      AlertService.showMsg({ message: "Please select stall to place service request" });
      return;
    }
    let inputData = {
      stallId: currentContext.stallId
    };
    let apiOptions: any = {
      url: `servicerequest/stall/exists`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      checkSerReqExistsForStallCallBack(res);
    });
  };

  const checkSerReqExistsForStallCallBack = (res) => {
    let result = res.data;
    if(result.isExists) {
      AlertService.showErrorMsg(result.message);
      handleContextChange({ stallId: 0 });
    }
    else {
      navigateToNext();
    }
  };

  const getServiceById = () => {
    let inputData = {
      id: serviceId
    };
    let apiOptions: any = {
      url: `portal/service/edit/${inputData.id}`,
      data: {}
    };
    http.get(apiOptions).then(response => {
      const result = response.data;
      if(routeData.defaultBehaviorCode) {
        handleContextChange({ "serviceCode": result.serviceCode, serviceBehaviorCode: routeData.defaultBehaviorCode });
        setIsCreateStall(false);
      }
      else {
        handleContextChange({ "serviceCode": result.serviceCode, serviceBehaviorCode: result.defaultBehaviorCode });
        setIsCreateStall(true);
        openStallForm();
      }
      if (routeData && routeData.stallId > 0) {
        handleNextStepUsingRouteData();
      }
    });
  }

  const openStallInfo = () => {
    setCurrentStep(1);
    getStalls(currentContext.stallId);
  }

  const openItemsInfo = () => {
    if (currentContext.stallId == 0) {
      let context = isCreateStall ? "create" : "select";
      AlertService.showMsg({ message: `Please ${context} stall to place service request` });
      return;
    }
    setCurrentStep(2);
    handleContextChange({ "showSteps": false });
  }

  const openSubscriptionPlan = () => {
    if (currentContext.stallId == 0) {
      let context = isCreateStall ? "create" : "select";
      AlertService.showMsg({ message: `Please ${context} stall to place service request` });
      return;
    }
    if (currentContext.serviceBehaviorCode == 'ItemWise') {
      if (selectedItems.length == 0 && !isCreateStall) {
        AlertService.showMsg({ message: "Please choose atleast one item to place service request" });
        return;
      }
      setCurrentStep(3);
      handleContextChange({ "showSteps": false });      
    }
    else if (currentContext.serviceBehaviorCode == 'Listings') {
      if (!currentContext.listingInfoFlag && !isCreateStall) {
        AlertService.showMsg({ message: "Please enter listing details to place service request" });
        return;
      }
      // if(currentContext.saleItemCount == 0 && !isCreateStall) {
      //   AlertService.showMsg({ message: "Please enter atleast one item to place service request" });
      //   return;        
      // }
      setCurrentStep(3);
      handleContextChange({ "showSteps": false });      
    }
    else if(currentContext.serviceBehaviorCode == 'Bookings') {
      setCurrentStep(3);
      handleContextChange({ "showSteps": false });      
    }
  }

  const navToReview = (isCallback, id) => {
    if (currentContext.stallId == 0) {
      let context = isCreateStall ? "create" : "select";
      AlertService.showMsg({ message: `Please ${context} stall to place service request` });
      return;
    }
    if(currentContext.subscriptionId == 0 && !isCallback) {
      AlertService.showMsg({ message: `Please choose a subscription plan to place service request` });
      return;      
    }
        
    if (currentContext.serviceBehaviorCode == 'ItemWise') {
      if (selectedItems.length == 0 && !isCreateStall) {
        AlertService.showMsg({ message: "Please choose atleast one item to place service request" });
        return;
      }
      let requestDetailInfo = {
        stallId: currentContext.stallId,
        serviceId: serviceId,
        communityId: communityId,
        serviceRequestType: "Sell",
        selectedItems: selectedItems,
        isCreateStall: isCreateStall,
        subscriptionId: isCallback ? id : currentContext.subscriptionId
      }
      setRequestDetails(requestDetailInfo);
    }
    else if (currentContext.serviceBehaviorCode == 'Listings' && !isCreateStall) {
      if (!currentContext.listingInfoFlag) {
        AlertService.showMsg({ message: "Please enter listing details to place service request" });
        return;
      }
      let listingInfoDetail = {
        stallId: currentContext.stallId,
        serviceId: serviceId,
        startDate: listingInfo.startDate,
        startTime: listingInfo.startTime,
        startDateTime: listingInfo.startDateTime,
        endDate: listingInfo.endDate,
        endTime: listingInfo.endTime,
        endDateTime: listingInfo.endDateTime,
        title: listingInfo.title,
        description: listingInfo.description,
        imagePathList: listingInfo.imagePathList,
        saleItemDetails: listingInfo.saleItemDetails,
        showLink: listingInfo.showLink,
        linkLabel: listingInfo.linkLabel,
        linkText: listingInfo.linkText,
        price: listingInfo.price
      }
      setListingInfoDetail(listingInfoDetail);
    }
    else if ((currentContext.serviceBehaviorCode == 'Listings' || currentContext.serviceBehaviorCode == 'Bookings') && isCreateStall) {
      let requestDetailInfo = {
        stallId: currentContext.stallId,
        serviceId: serviceId,
        communityId: communityId,
        serviceRequestType: "Sell",
        selectedItems: selectedItems,
        isCreateStall: isCreateStall,
        subscriptionId: isCallback ? id : currentContext.subscriptionId
      }
      setRequestDetails(requestDetailInfo);
    }
    setCurrentStep(4);
    handleContextChange({ "showSteps": false });
  }

  const getStalls = (stallIdVal: any = 0, isCallback: any = false) => {
    let inputData = {
      communityId: communityId,
      ownerId: userId
    };
    let apiOptions: any = {
      url: `stall/byownerid`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getStallsCallBack(res, stallIdVal, isCallback);
    })
  };

  const getStallsCallBack = (res, stallIdVal, isCallback) => {
    let result = res.data;
    setStalls(result);
    let defaultStallId = result.length > 0 ? result[0].id : 0;
    let stallIdValue = stallIdVal > 0 ? stallIdVal : defaultStallId;
    handleContextChange({ "stallId": stallIdValue });
    if (stallIdVal > 0 && isCallback) {
      let lookupItem: any = _.find(result, { id: stallIdVal });
      if (lookupItem) {
        setCurrentContext(prevState => {
          return { ...prevState, ...{ selectedStallName: lookupItem.text, showSteps: false } }
        });
      }
      if(isCreateStall) {
        setCurrentStep(3);  
      }
      else {
        setCurrentStep(2);
      }
    }
  };

  const navigateToNext = () => {
    //setCurrentStep(0);
    let lookupItem: any = _.find(stalls, { id: currentContext.stallId });
    if (lookupItem) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ selectedStallName: lookupItem.text, showSteps: false } }
      });
    }
    setCurrentStep(2);
  }

  const onServiceReqFormCallback = (itemList) => {
    let itemListResult = itemList;
    setSelectedItems(itemList);
    setSelectedItemCount(itemListResult.length);
    /*setCurrentStep(0);
    setCurrentContext(prevState => {
      return { ...prevState, ...{ showSteps: true } }
    });*/
    setCurrentStep(3);
  }

  const onListingFormCallback = (response) => {
    if (response[0].listingItem && (response[0].listingItem.title /*|| response[0].listingItem.endDate || response[0].listingItem.startDate*/)) {
      handleContextChange({ "listingInfoFlag": true });
      setListingInfo(response[0].listingItem);
      setCurrentStep(3);
    }
    else {
      setCurrentStep(0);
      handleContextChange({ "showSteps": true });
    }
    setListingInfo(prevState => {
      return { ...prevState, ...{ saleItemDetails: listingInfo.saleItemDetails.length > 0 ? listingInfo.saleItemDetails : [] } }
    });
  }

  const navigateToAddSaleItem = () => {
    if (!currentContext.listingInfoFlag) {
      AlertService.showMsg({ message: "Please enter listings details and add items" });
      return;
    }
    setCurrentStep(2.5);
    handleContextChange({ "showSteps": false });
  }

  const onSaleItemCallback = (response, isBack) => {
    if (response) {
      handleContextChange({ "saleItemCount": response.itemCount });
      setListingInfo(prevState => {
        return { ...prevState, ...{ saleItemDetails: response.itemDetails } }
      });
      setItemDetails(response.itemDetails);
      if(response.itemCount > 0) {
        setCurrentStep(3);
      }      
    }
    if(isBack) {
      setCurrentStep(0);
      handleContextChange({ "showSteps": true });
    }
  }

  const onSubscriptionCallback = (data) => {
    if (data) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ subscriptionId: data } }
      });
    }
    if(data > 0) {
      navToReview(true, data);
    }
    else {
      setCurrentStep(0);
      handleContextChange({ "showSteps": true });
    }
  }

  const onRequestConfirmCallback = (data) => {
    if (data) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ serviceRequestId: data.reqId } }
      });
      setCurrentContext(prevState => {
        return { ...prevState, ...{ subscriptionStatus: data.status } }
      });
    }
    else {
      setCurrentStep(0);
      setCurrentContext(prevState => {
        return { ...prevState, ...{ showSteps: true } }
      });
    }
  }

  const onSaleRequestConfirmCallback = (data) => {
    if (data) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ serviceRequestId: data.reqId } }
      });
      setCurrentContext(prevState => {
        return { ...prevState, ...{ subscriptionStatus: data.status } }
      });
    }
    else {
      setCurrentStep(0);
      setCurrentContext(prevState => {
        return { ...prevState, ...{ showSteps: true } }
      });
    }
  }

  const openStallForm = () => {
    setStallFormOpen(true);
  }

  const onFormCallBack = (id) => {
    setStallFormOpen(false);
    getStalls(id, true);
  }

  const handleStallFormClose = () => {
    setStallFormOpen(false);
  }

  const confirmExit = () => {
    setConfirmOpen(true);
    setMessage("Are you sure to exit service request ?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    navigateToServices();
  }

  const navigateToServices = () => {
    if (routeData && routeData.navToBack) {
      back()
    }
    else {
      let navData = {
        targetRoute: '/provider/providerservices/',
        targetTitle: "Create Stall",
        targetData: {},
        sourceState: {},
        eventSource: '',
      };
      navigate(navData);
    }
  };

  const handleNextStepUsingRouteData = () => {
    handleContextChange({
      stallId: routeData.stallId,
      selectedStallName: routeData.stallName,
      showSteps: false
    });
    setCurrentStep(2);
  };

  useEffect(() => {
    getServiceById();
  }, []);

  return (
    <div className="create-servicereq-ui pt-5">
      <div className="pt-5">
        <div>
          {currentContext.showSteps && !isCreateStall &&
            <div className='row mt-2'>
              <div className="col-1">
                <span className="float-right">
                  {currentContext.selectedStallName &&
                    <i className="fa fa-check-circle successIcon"></i>
                  }
                  {!currentContext.selectedStallName &&
                    <i className="fa fa-check-circle disableIcon"></i>
                  }
                </span>
              </div>
              <div className="col-10 pointer" onClick={openStallInfo}>
                <span>
                  Select stall or Create stall
                </span>
                <div><b>{currentContext.selectedStallName}</b></div>
              </div>
              <div className="col-1 pointer" onClick={openStallInfo}>
                <span>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </div>
            </div>
          }
          {currentContext.showSteps && isCreateStall &&
            <div className='row mt-2'>
              <div className="col-1">
                <span className="float-right">
                  {currentContext.selectedStallName &&
                    <i className="fa fa-check-circle successIcon"></i>
                  }
                  {!currentContext.selectedStallName &&
                    <i className="fa fa-check-circle disableIcon"></i>
                  }
                </span>
              </div>
              <div className="col-10 pointer" onClick={openStallForm}>
                <span>
                  Create stall
                </span>
                <div><b>{currentContext.selectedStallName}</b></div>
              </div>
              <div className="col-1 pointer" onClick={openStallForm}>
                <span>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </div>
            </div>
          }          
        </div>


        {/* Select or create stall section starts */}
        <div className="pl-5 mx-5">
          {currentStep == 1 && !isCreateStall &&
            <div className="row p-3 mx-5 align-items-center">
              <div className="col-3">
                <div className="p-2 row">
                  <div>
                    <FxSelect name="stallId" variant="outlined" label="Select Stall"
                      options={stalls}
                      selectedValue={currentContext.stallId}
                      valueField="id"
                      displayField="text"
                      size="small"
                      style={{ width: 250 }}
                      onValueChange={handleInputChange} />
                  </div>
                  {
                    isCreateStall &&
                    <div className="px-2 py-1">
                      <Button variant="contained" size="small" color="primary"
                        onClick={checkSerReqExistsForStall} >
                        Next
                      </Button>
                    </div>
                  }
                  {
                    !isCreateStall &&
                    <div className="px-2 py-1">
                      <Button variant="contained" size="small" color="primary"
                        onClick={navigateToNext} >
                        Next
                      </Button>
                    </div>
                  }                  
                </div>
              </div>
              {/* <div className="col-1 text-center">
                <b>(or)</b>
              </div>
              <div className="col-2">
                <Button variant="contained" size="small" color="primary" onClick={openStallForm} >
                  Create Stall
                </Button>
              </div> */}
            </div>
          }
        </div>
        {/* Select or create stall section ends */}

        <div>
          {currentContext.showSteps && !isCreateStall &&
            <div className='row mt-2'>
              <div className="col-1">
                <span className="float-right">
                  {(currentContext.serviceBehaviorCode == 'ItemWise') &&
                    <span>
                      {selectedItemCount > 0 &&
                        <i className="fa fa-check-circle successIcon"></i>
                      }
                      {selectedItemCount == 0 &&
                        <i className="fa fa-check-circle disableIcon"></i>
                      }
                    </span>
                  }
                  {(currentContext.serviceBehaviorCode == 'Listings') &&
                    <span>
                      {currentContext.listingInfoFlag &&
                        <i className="fa fa-check-circle successIcon"></i>
                      }
                      {!currentContext.listingInfoFlag &&
                        <i className="fa fa-check-circle disableIcon"></i>
                      }
                    </span>
                  }
                </span>
              </div>
              <div className="col-10 pointer" onClick={openItemsInfo}>
                {(currentContext.serviceBehaviorCode == 'ItemWise') &&
                  <span>
                    <span> Select items or create item</span>
                    <div>
                      {selectedItemCount > 0 &&
                        <span><b>{selectedItemCount} items</b></span>
                      }
                    </div>
                  </span>
                }
                {(currentContext.serviceBehaviorCode == 'Listings') &&
                  <span>
                    <span>
                      Enter listing details
                    </span>
                    <div className="row">
                      {currentContext.listingInfoFlag &&
                        <span>
                          {/* <span className="col-6">
                            <DateIcon fontSize="inherit" />
                            <FxDateDisplay value={listingInfo.startDate} />
                            {listingInfo.startTime &&
                              <span className="pl-1">
                                <i className="far fa-clock color-aaa px-1"></i>
                                <FxDateTimeDisplay value={listingInfo.startTime} displayType="time" />
                              </span>
                            }
                          </span>
                          <span className="col-6">
                            <DateIcon fontSize="inherit" />
                            <FxDateDisplay value={listingInfo.endDate} />
                            {listingInfo.endTime &&
                              <span className="pl-1">
                                <i className="far fa-clock color-aaa px-1"></i>
                                <FxDateTimeDisplay value={listingInfo.endTime} displayType="time" />
                              </span>
                            }
                          </span> */}
                          {listingInfo.title &&
                            <div className="col-12 pl-3">
                              {listingInfo.title}</div>
                          }
                        </span>
                      }
                    </div>
                  </span>
                }
              </div>
              <div className="col-1 pointer" onClick={openItemsInfo}>
                <span>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </div>
            </div>
          }
        </div>

        {/* <div>
          {currentContext.showSteps && (currentContext.serviceBehaviorCode == 'Listings') && !isCreateStall &&
            <div className='row mt-2'>
              <div className="col-1">
                <span className="float-right">
                  {currentContext.saleItemCount > 0 &&
                    <i className="fa fa-check-circle successIcon"></i>
                  }
                  {currentContext.saleItemCount == 0 &&
                    <i className="fa fa-check-circle disableIcon"></i>
                  }
                </span>
              </div>
              <div className="col-10 pointer" onClick={navigateToAddSaleItem} >
                <span>
                  <span>Add items</span>
                  <div>
                    {currentContext.saleItemCount > 0 &&
                      <span><b>{currentContext.saleItemCount} items</b></span>
                    }
                  </div>
                </span>
              </div>
              <div className="col-1 pointer" onClick={navigateToAddSaleItem}>
                <span>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </div>
            </div>
          }
        </div> */}

        <div>
          {currentContext.showSteps &&
            <div className='row mt-2'>
              <div className="col-1">
                <span className="float-right">
                  {currentContext.subscriptionId > 0 &&
                    <i className="fa fa-check-circle successIcon"></i>
                  }
                  {currentContext.subscriptionId == 0 &&
                    <i className="fa fa-check-circle disableIcon"></i>
                  }
                </span>
              </div>
              <div className="col-10 pointer" onClick={openSubscriptionPlan} >
                Choose a subscription plan
              </div>
              <div className="col-1 pointer" onClick={openSubscriptionPlan}>
                <span>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </div>
            </div>
          }
        </div>

        <div>
          {currentContext.showSteps &&
            <div className='row mt-2 pb-3'>
              <div className="col-1">
                <span className="float-right">
                  <i className="fa fa-check-circle disableIcon"></i>
                </span>
              </div>
              <div className="col-10 pointer" onClick={() => navToReview(false, 0)} >
                Review and place request
              </div>
              <div className="col-1 pointer" onClick={() => navToReview(false, 0)}>
                <span>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </div>
            </div>
          }
        </div>

        <div>
          {currentStep == 2 && !isCreateStall &&
            <div>
              {(currentContext.serviceBehaviorCode == 'ItemWise') &&
                <ServiceRequestItemForm serviceId={serviceId} selectedItems={selectedItems} onServiceReqFormCallback={onServiceReqFormCallback} />
              }
              {/* {currentContext.serviceBehaviorCode == 'Listings' && currentContext.serviceCode == 'Garage Sale' &&
                <SaleForm isEdit={false} stallId={currentContext.stallId} listingInfo={listingInfo} onListingFormCallback={onListingFormCallback} />
              } */}
              {(currentContext.serviceBehaviorCode == 'Listings') &&
                <GardenPetProfArtForm isEdit={false} stallId={currentContext.stallId} listingInfo={listingInfo} onListingFormCallback={onListingFormCallback} />
              }
            </div>
          }
        </div>
        {/* <div>
          {currentStep == 2.5 && !isCreateStall &&
            <div>
              <AddSaleItemForm itemDetails={itemDetails} onSaleItemCallback={onSaleItemCallback} />
            </div>
          }
        </div> */}
        <div>
          {currentStep == 3 &&
            <div>
              <SubscriptionPlan subscriptionId={currentContext.subscriptionId} userId={userId} stallId={currentContext.stallId} onSubscriptionCallback={onSubscriptionCallback} />
            </div>
          }
        </div>
        <div>
          {currentStep == 4 &&
            <div className="row justify-content-center">
              {currentContext.serviceRequestId == 0 &&
                <span>
                  {(currentContext.serviceBehaviorCode == 'ItemWise') &&
                    <ServiceRequestConfirm requestDetails={requestDetails} onRequestConfirmCallback={onRequestConfirmCallback} />
                  }
                  {isCreateStall && (currentContext.serviceBehaviorCode == 'Listings' || currentContext.serviceBehaviorCode == 'Bookings') &&
                    <ServiceRequestConfirm requestDetails={requestDetails} onRequestConfirmCallback={onRequestConfirmCallback} />
                  }                  
                  {!isCreateStall && currentContext.serviceBehaviorCode == 'Listings' && 
                    <SaleRequestConfirm listingInfoDetail={listingInfoDetail} onSaleRequestConfirmCallback={onSaleRequestConfirmCallback} />
                  }
                </span>
              }
              {currentContext.serviceRequestId > 0 &&
                <span>
                  {(currentContext.serviceBehaviorCode == 'ItemWise') &&
                    <ServiceRequestSummary serviceRequestId={currentContext.serviceRequestId} subscriptionStatus={currentContext.subscriptionStatus} />
                  }
                  {isCreateStall && (currentContext.serviceBehaviorCode == 'Listings' || currentContext.serviceBehaviorCode == 'Bookings') &&
                    <ServiceRequestSummary serviceRequestId={currentContext.serviceRequestId} subscriptionStatus={currentContext.subscriptionStatus} />
                  }                  
                  {!isCreateStall && currentContext.serviceBehaviorCode == 'Listings' &&
                    <SaleRequestSummary serviceRequestId={currentContext.serviceRequestId} subscriptionStatus={currentContext.subscriptionStatus} />
                  }
                </span>
              }
            </div>
          }
        </div>
        <div>
          {currentStep == 0 &&
            <div className='row my-3'>
              <div className="col-1"></div>
              <div className="col-10 pointer">
                <Button variant="contained" color="primary" size="medium" onClick={confirmExit}> Exit Service Request </Button>
              </div>
            </div>
          }
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallFormOpen} onClose={handleStallFormClose}>
        <MyStallForm serviceId={serviceId} handleStallFormClose={handleStallFormClose} onFormCallBack={onFormCallBack} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
};

export default CreateServiceRequestContainer;