import React, { useEffect, useState } from 'react';
import './ReviewList.scss';
import { http, FxHumanDateDisplay } from '../fx-core';
import Rating from '@material-ui/lab/Rating';
import PersonIcon from '@material-ui/icons/Person';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const ReviewList = (props: any) => {

  const initialList = [];
  const [reviewList, setReviewList] = useState(initialList);

  const getReviewList = () => {
    let apiOptions: any = {
      url: `review/community/${props.communityId}`, data: {}
    };
    http.post(apiOptions).then(response => {
      setReviewList(response.data);
    });
  }

  useEffect(() => {
    getReviewList();
  }, []);


  const body = (
    <div className="confirmContainer">
      <div className="row title-font">
        <div className="col-9 py-1 pl-5 font-18">
          <span>Reviews</span>
        </div>
        <div className="col-3 text-right pointer my-auto pr-4" onClick={props.handleReviewList}>
          <HighlightOffIcon color="secondary" />
        </div>
      </div>
      <div>
        {
          reviewList.length == 0 &&
          <div className="px-5 py-5 font-20 mx-5">
            No Reviews Found
          </div>
        }
      </div>
      <div>
        {
          reviewList.map((item) => (
            <div className="row mx-2 pt-1 col-12 alignbox">
              <div className="col-8">
                <Rating name="read-only" value={item.rating} readOnly />
              </div>

              <div className="col-4">
                <span className="float-right">
                  <PersonIcon />
                  {item.userName}
                </span>
              </div>

              <div className="col-8">
                {item.reviewComment}
              </div>
              <div className="col-4">
                <span className="float-right">
                  <FxHumanDateDisplay value={item.ratingDate} displayType="datetime" />
                </span>
              </div>
            </div>
          ))

        }

      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ReviewList;