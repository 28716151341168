import React, { useState, useEffect } from 'react';
import './TaskTracker.scss';
import { http, useNavigationService, localstorage, AlertService } from '../fx-core';
import userImage from './../../assets/img/user.png';
import firstOrderImage from './../../assets/img/firstorder.png';
import freeOrderImage from './../../assets/img/freeorder.png';
import selectedIconImage from './../../assets/img/selectedicon.png';
import inviteaFriendImage from './../../assets/img/invitefriend.png';

const TaskTracker = () => {
    let defaultContext: any = {
        isUpdateProfile: true,
        isInvitedAFriend: false,
        isFirstOrder: false,
        isFreeOrder: false,
        isShowTaskTracker: false,
        profileMessage: null
    };
    const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
    const [currentContext, setCurrentContext] = useState(defaultContext);
    const { navigate } = useNavigationService();

    const getDashboardTaskTrackerInfo = () => {
        let apiOptions: any = {
            url: `user/dashboard/tasktracker/${userId}`
        };
        http.get(apiOptions).then(response => {
            setCurrentContext(response.data);
        });
    }

    const navigateByCode = (code) => {
        if (code == "freeorder") {
            if (!currentContext.isFreeOrder) {
                AlertService.showMsg({ message: "You will get free order, once your invited friend(s) place first order" });
                return;
            }
        }
        else if (code == "profile") {
            if (!currentContext.isUpdateProfile) {
                let navData = {
                    targetRoute: '/myaccountprofile/isProfile/'+ currentContext.profileMessage,
                    targetTitle: "My Account",
                    targetData: {},
                    sourceState: {},
                    eventSource: '',
                };
                navigate(navData);
            }
            else {
                let navData = {
                    targetRoute: '/myaccount',
                    targetTitle: "My Account",
                    targetData: {},
                    sourceState: {},
                    eventSource: '',
                };
                navigate(navData);
            }
        }
        else if (code == "firstorder") {
            getReadyToEatServiceId();
        }
        else if (code == "inviteafriend") {
            let navData = {
                targetRoute: '/myaccountinvite/isInvite',
                targetTitle: "My Account",
                targetData: {},
                sourceState: {},
                eventSource: '',
            };
            navigate(navData);
        }
    }

    const getReadyToEatServiceId = () => {
        let inputData = {
            code: 'Ready To Eat'
        };
        let apiOptions: any = {
            url: `service/${inputData.code}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            const result = response.data;
            navToReadyToEatHome(result.slug);
        });
    }

    const navToReadyToEatHome = (slug) => {
        let navData = {
            targetRoute: '/' + slug + '/itemwise/stalls',
            targetTitle: "Itemwise Stalls",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    }

    useEffect(() => {
        getDashboardTaskTrackerInfo();
    }, []);

    return (
        <div className="task-tracker-ui">
            {
                userId > 0 &&
                <div>
                    {
                        currentContext.isShowTaskTracker &&
                        <div className="row justify-content-center pt-1">
                            <div className="text-center task-card-style px-0 pointer" onClick={() => navigateByCode('profile')}>
                                {currentContext.isUpdateProfile && <img src={selectedIconImage} alt="img" className="img-style"></img>}
                                {!currentContext.isUpdateProfile && <img src={userImage} alt="img" className="img-style"></img>}
                                <div className="pt-1">Update Profile </div>
                            </div>

                            <div className="text-center task-card-style px-0 pointer" onClick={() => navigateByCode('firstorder')}>
                                {currentContext.isFirstOrder && <img src={selectedIconImage} alt="img" className="img-style"></img>}
                                {!currentContext.isFirstOrder && <img src={firstOrderImage} alt="img" className="img-style"></img>}
                                <div className="pt-1">First Order </div>
                            </div>

                            <div className="text-center task-card-style px-0 pointer" onClick={() => navigateByCode('inviteafriend')}>
                                {currentContext.isInviteAFriend && <img src={selectedIconImage} alt="img" className="img-style"></img>}
                                {!currentContext.isInviteAFriend && <img src={inviteaFriendImage} alt="img" className="img-style"></img>}
                                <div className="pt-1">Invite Friends </div>
                            </div>

                            <div className="text-center task-card-style px-0 pointer" onClick={() => navigateByCode('freeorder')}>
                                {currentContext.isFreeOrder && <img src={selectedIconImage} alt="img" className="img-style"></img>}
                                {!currentContext.isFreeOrder && <img src={freeOrderImage} alt="img" className="img-style"></img>}
                                <div className="pt-1">Free Order </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}
export default TaskTracker;