import React, { useEffect, useState } from 'react';
import './ChildCareListingHome.scss';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useNavigationService, http, formatService, general, localstorage, FxInput } from '../fx-core';
import Typography from '@material-ui/core/Typography';
import nostalls from '../../assets/nostalls.svg';
import ChildCareServiceCard from '../ChildCareServiceCard/ChildCareServiceCard';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import RegisterComponent from '../RegisterComponent/RegisterComponent';

const ChildCareListingHome = (props: any) => {
    const { getRouteData, navigate, back, pageState } = useNavigationService();
    const navToCustomerDashboard = (item: any) => {
        back();
    }
    let defaultItem: any = {
        searchText: "",
        categoryIds: [],
        isDeliveryEnabled: false,
        isPickupEnabled: false
    };

    const [item, setItem] = useState(defaultItem);
    const [serviceId, setServiceId] = useState(props.match.params.serviceId ? parseInt(props.match.params.serviceId) : 0);
    const [availableServices, setAvailableServices] = useState([]);
    const [serviceName, setServiceName] = useState(null);
    const [RegisterComponentFormOpen, setRegisterComponentFormOpen] = useState(false);

    const handleRegisterComponentForm = () => {
        setRegisterComponentFormOpen(false);
    }
    const openRegisterComponentFormOpen = () => {
        setRegisterComponentFormOpen(true);
    }
    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        getAvailableServices({ "searchText": item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getServiceById = () => {
        let inputData = {
            id: serviceId
        };
        let apiOptions: any = {
            url: `portal/service/edit/${inputData.id}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            setServiceName(response.data.serviceName);
        });
    }

    const getAvailableServices = (data: any) => {
        let inputData = {
            currentDate: formatService.getCurrentDateTime(),
            stallName: data.searchText ? data.searchText : "",
            communityId: localstorage.getItem("communityId")
        };
        let apiOptions: any = {
            url: `portal/childcareservice/location/available`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.items;
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
            }
            setAvailableServices(result);
        });
    }

    useEffect(() => {
        getServiceById();
        getAvailableServices(defaultItem);
    }, []);


    return (
        <div className="pt-5 pb-5 cclistinghome-ui">
            <div className="title-font custom-head pt-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
                        <b>Dashboard</b>
                    </span>
                    <Typography color="textPrimary" className="breadcrumbs-style">{serviceName}</Typography>
                </Breadcrumbs>
            </div>

            {/* Desktop View */}
            <div className="row pt-2 deskview">
                <div className="col-12 px-3 pl-5 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Listings"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>
                    <div className="row">
                        {
                            availableServices.length > 0 && availableServices.map((item, i) => (
                                <div className="col-4">
                                    <ChildCareServiceCard item={item} />
                                </div>
                            ))
                        }
                        {
                            availableServices.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                            </div>
                        }
                    </div>
                    <div className="row pt-5">
                    <div className="col-2"></div>
                    <div className="col-8 pb-5 pt-3 justify-content-center">
                        <Button variant="contained" size="large" fullWidth color="primary" style={{ color: '#FFFFFF', fontWeight: 600 }}
                            onClick={openRegisterComponentFormOpen}>
                            SIGNUP TO ENROLL
                        </Button>
                    </div>
                    <div className="col-2"></div>
                </div>
                </div>
              
            </div>

            {/* Tablet View */}
            <div className="pb-5 pl-1 text-center tabview">
                <div className="col-12 px-3 pl-4 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Listings"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>
                    <div className="row">
                        {
                            availableServices.length > 0 && availableServices.map((item, i) => (
                                <div className="col-6">
                                    <ChildCareServiceCard item={item} />
                                </div>
                            ))
                        }
                        {
                            availableServices.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                            </div>
                        }
                    </div>
                    <div className="py-5">
                    <Button variant="contained" size="large" fullWidth color="primary" style={{ color: '#FFFFFF', fontWeight: 600 }}
                        onClick={openRegisterComponentFormOpen}>
                        SIGNUP TO ENROLL
                    </Button>
                </div>
                </div>               
            </div>


            {/* Mobile View */}
            <div className="pb-5 pl-1 text-center mobileview">
                <div className="col-12 px-3 pl-4 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Listings"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>
                    <div className="text-center">
                        {
                            availableServices.length > 0 && availableServices.map((item, i) => (
                                <div>
                                    <ChildCareServiceCard item={item} />
                                </div>
                            ))
                        }
                        {
                            availableServices.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                            </div>
                        }
                    </div>
                    <div className="py-4">
                    <Button variant="contained" size="large" fullWidth color="primary" style={{ color: '#FFFFFF', fontWeight: 600 }}
                        onClick={openRegisterComponentFormOpen}>
                        SIGNUP TO ENROLL
                    </Button>
                </div>
                </div>               
            </div>
            <Drawer anchor={"right"} open={RegisterComponentFormOpen} onClose={handleRegisterComponentForm}>
                <RegisterComponent handleRegisterComponentForm={handleRegisterComponentForm} />
            </Drawer>
        </div>
    );
}
export default ChildCareListingHome;