import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useProgressStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      color: '#f69516',
      animationDuration: '400ms',
      position: 'absolute',
      left: 650,
      top: 300,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

const Progress = (props: any) => {
  const classes = useProgressStyles();
  return (
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={35}
        thickness={3}
        {...props}/>
  );
}
export default Progress;
