import React, { useState, useEffect } from 'react';
import './ServiceRequestSummary.scss';
import { http, general, useNavigationService } from '../../../fx-core';
import dummyImage from "../../../../assets/images/dummy.png";
import Button from '@material-ui/core/Button';

const ServiceRequestSummary = (props: any) => {

    let defaultItem = {
        serviceRequestType: "",
        stallId: 0,
        stallName: ""
    };
    const { navigate} = useNavigationService();
    const [serviceRequest, setServiceRequest] = useState(defaultItem);
    const [serviceRequestDetails, setServiceRequestDetails] = useState([]);

    const getServiceRequestSummary = () => {
        let apiOptions: any = {
            url: `servicerequest/detail/${props.serviceRequestId}`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            getServiceRequestSummaryCallBack(result);
        });
    }

    const getServiceRequestSummaryCallBack = (res) => {
        setServiceRequest(res.serviceRequest);
        for (let item of res.serviceRequestDetails) {
            item.imageUrl = general.getImageUrl(item.imagePath);
        }
        setServiceRequestDetails(res.serviceRequestDetails);
    }

    const openMyServiceRequests = () => {
        let navData = {
            targetRoute: '/provider/providerservicerequests',
            targetTitle: 'My Service Request',
            sourceState: {},
            eventSource: 'providerservicerequests',
        };
        navigate(navData);
    }

    useEffect(() => {
        getServiceRequestSummary();
    }, []);

    return (
        <div className="servicereq-confirm-ui">
            <div>
                <div className="successicon text-center">
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
                        <path d="M1 6.83048L6.37273 12L17 1" stroke="#73B934" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </div>
                <div className="font-20 mt-4 mb-2">Success!</div>
                {serviceRequestDetails && serviceRequestDetails.length > 0 &&
                    <div className="successfullymsg">
                        <span>These are the items submitted for
                        <span className='pl-1'>{serviceRequest.serviceRequestType}</span>.</span>
                        <span>Thank you.</span>
                    </div>
                }

                {serviceRequest.stallId > 0 && props.subscriptionStatus == 'InActive' &&
                    <div className='m-3'>
                        <span className='text-danger'>
                            <b>You do not have active subscription for the stall {serviceRequest.stallName}. Please contact
                                administrator.</b>
                        </span>
                    </div>
                }
                {serviceRequest.stallName &&
                    <div className='mb-2 text-center font-18 color-primary stallname'>
                        <span>{serviceRequest.stallName}</span>
                    </div>
                }
                {
                   serviceRequestDetails.length > 0 && 
                    <div className="productsummary mb-3">
                        <span>Product Summary</span>
                    </div>
                }
                <div>
                    {
                        serviceRequestDetails.length > 0 && serviceRequestDetails.map((itemObj, i) => (
                            <div className="pt-2 row ml-1 align-items-center">
                                <span className="col-3">
                                    {!itemObj.imageUrl && <img src={dummyImage} className="itemImg"></img>}
                                    {itemObj.imageUrl && <img src={itemObj.imageUrl} className="itemImg"></img>}
                                </span>

                                <span className="col-9 row">
                                    <span className='pl-2 font-14'>
                                        {itemObj.itemName}
                                    </span>
                                </span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="row p-4">
                <Button variant="contained" size="small" color="primary" fullWidth
                    onClick={openMyServiceRequests} >
                    Click here to view my service requests
                </Button>
            </div>
        </div>

    );
}
export default ServiceRequestSummary;