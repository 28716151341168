import React, { useEffect, useState } from 'react';
import './StallListings.scss';
import { http, useNavigationService, general, FxDateDisplay, FxDateTimeDisplay } from '../../fx-core';
import { Dialog, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import dummyImage from "../../../assets/images/dummy.png";
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const StallListings = (props) => {
  const { navigate } = useNavigationService();

  const initialList = [];
  const [stallId, setStallId] = useState(props.match.params.id ? parseInt(props.match.params.id) : 0);
  const [saleList, setSaleList] = useState(initialList);
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});

  const getStallListings = () => {
    let apiOptions: any = {
      url: `sale/listings/stall/${stallId}`
    };
    http.get(apiOptions).then(res => {
      getSaleListCallBack(res.data);
    })
  };

  const getSaleListCallBack = (res) => {
    for (let item of res) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setSaleList(res);
  };

  const openImages = (item: any) => {
    let config = {
      api: `sale/${item.id}/images`,
      title: item.title
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
  }

  const navToDashboard = () => {
    let navData = {
      targetRoute: '/provider/provdashboard/'+stallId,
      targetTitle: "Provider Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    getStallListings();
  }, []);

  return (
    <div className='stalllistings-ui pt-5'>
      <div className="content-container pt-4">
        <div className="content">
          <div className='pt-2'>
            <div className="title-font custom-head pl-4">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToDashboard}>
                  <b>Dashboard</b>
                </Link>
                <Typography color="primary" className="breadcrumbs-style">Sale Listings</Typography>
              </Breadcrumbs>
            </div>

            <div className='mx-3 my-3'>
              <div className='col-12 my-4'>
                <TableContainer component={Paper}>
                  <Table size="small" className='table-striped'>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell className='text-center'>Status</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {saleList.map((row, i) => (
                        <TableRow>
                          <TableCell className='text-center'>
                            <span onClick={() => openImages(row)}>
                              {!row.imageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                              {row.imageUrl && <img src={row.imageUrl} alt="Stall" width="50" height="50"></img>}
                            </span>
                            <div className='font-weight-bold font-12 pt-2'>
                              {row.stallName}
                            </div>
                          </TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>
                            {row.description}
                          </TableCell>
                          <TableCell className='text-center'>
                            <div className='pt-2 font-13'>{row.saleStatus}</div>
                          </TableCell>
                          <TableCell>
                            <span className="font-12">
                              <FxDateDisplay value={row.startDate} />
                              {row.startTime &&
                                <span>
                                  <i className="far fa-clock color-aaa px-1"></i>
                                  <FxDateTimeDisplay value={row.startTime} displayType="time" />
                                </span>
                              }
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="font-12">
                              <FxDateDisplay value={row.endDate} />
                              {row.endTime &&
                                <span>
                                  <i className="far fa-clock color-aaa px-1"></i>
                                  <FxDateTimeDisplay value={row.endTime} displayType="time" />
                                </span>
                              }
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="col-12 my-3 text-center">
                {saleList && saleList.length == 0 &&
                  <span className='font-20'><b>No Listings</b></span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
    </div>
  );
}
export default StallListings;