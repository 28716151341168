import React, { useEffect, useState } from 'react';
import './ManageListings.scss';
import { http, localstorage, FxSelect, useNavigationService, formatService, general, FxDateDisplay, AlertService, FxDateTimeDisplay, session } from '../../fx-core';
import { Dialog, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Drawer, Fab } from '@material-ui/core';
import dummyImage from "../../../assets/images/dummy.png";
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import ListingFormContainer from '../ListingFormContainer/ListingFormContainer';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import _ from 'lodash';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ManageListings = (props) => {
  const { navigate } = useNavigationService();
  const classes = styles();
  let defaultFilter: any = {
    saleStatusId: -1,
    saleTypeId: -1,
    serviceId: -1,
    stallId: -1
  };
  let defaultContext: any = {
    serviceId: 0,
    stallId: 0,
    saleId: 0
  };
  const uid = localstorage.getItem('userId');
  const cid = localstorage.getItem("communityId")
  const defaultUserId = (uid && uid != null) ? parseInt(uid) : 0;
  const defaultCommId = (cid && cid != null) ? parseInt(cid) : 0;
  const initialList = [];
  const [filter, setFilter] = useState(defaultFilter);
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [communityId, setCommunityId] = useState(defaultCommId);
  const [userId, setUserId] = useState(defaultUserId);
  const [SaleStatus, setSaleStatus] = useState(initialList);
  const [SaleType, setSaleType] = useState(initialList);
  const [ListingServices, setListingServices] = useState(initialList);
  const [ProviderStalls, setProviderStalls] = useState(initialList);
  const [saleList, setSaleList] = useState(initialList);
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});
  const [listingFormOpen, setListingFormOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");
  const [stallServiceMapList, setStallServiceMapList] = useState([]);

  const handleInputFilter = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let filterObj = {
      saleStatusId: filter.saleStatusId,
      saleTypeId: filter.saleTypeId,
      serviceId: filter.serviceId,
      stallId: filter.stallId
    }
    if (item.name == "saleStatusId") {
      filterObj.saleStatusId = item.value;
    }
    else if (item.name == 'saleTypeId') {
      filterObj.saleTypeId = item.value;
    }
    else if (item.name == 'serviceId') {
      filterObj.serviceId = item.value;
    }
    else if (item.name == 'stallId') {
      filterObj.stallId = item.value;
    }
    getProviderListings(filterObj);
  };

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        SaleStatus: { default: true },
        SaleType: { default: true },
        ListingServices: { default: true },
        ProviderStalls: { default: false }
      },
      filters: { communityId: communityId, userId: userId }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      const result = response.data;
      initLookupCallback(result);
    })
  }

  const initLookupCallback = (result) => {
    let stalls = result.ProviderStalls;
    setSaleStatus(result.SaleStatus);
    setSaleType(result.SaleType);
    setListingServices(result.ListingServices);
    setProviderStalls(stalls);
    getStallServiceMapList();
    if (stalls.length > 0) {
      handleInputFilter({ name: "stallId", value: stalls[0].id })
    }
    else {
      getProviderListings(filter);
    }
  }

  const getProviderListings = (data) => {
    let typeId = data.saleTypeId;
    if (typeId == -1) {
      getAllSaleList(data);
    }
    if (typeId == 1) {
      getCompletedSaleList(data);
    }
    if (typeId == 2) {
      getRunningSaleList(data);
    }
    if (typeId == 3) {
      getUpcomingSaleList(data);
    }
  };

  const collectInputData = (data) => {
    let inputData = {
      communityId: communityId,
      ownerId: userId,
      currentDate: formatService.getCurrentDateTime(),
      saleStatusId: data.saleStatusId,
      serviceId: data.serviceId,
      stallId: data.stallId
    };
    return inputData;
  }

  const getCompletedSaleList = (data) => {
    let inputData = collectInputData(data);
    let apiOptions: any = {
      url: 'sale/completed',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getSaleListCallBack(res.data);
    })
  };

  const getRunningSaleList = (data) => {
    let inputData = collectInputData(data);
    let apiOptions: any = {
      url: 'sale/running',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getSaleListCallBack(res.data);
    })
  };

  const getUpcomingSaleList = (data) => {
    let inputData = collectInputData(data);
    let apiOptions: any = {
      url: 'sale/upcoming',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getSaleListCallBack(res.data);
    })
  };

  const getAllSaleList = (data) => {
    let inputData = collectInputData(data);
    let apiOptions: any = {
      url: 'sale/all',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getSaleListCallBack(res.data);
    })
  };

  const getSaleListCallBack = (res) => {
    for (let item of res) {
      item.imageUrl = general.getImageUrl(item.imagePath);
      //item.showEndListing = true;
      //item.showRelist = false;
      item.showSubscriptionMsg = false;
      /*if ((item.serviceCode == 'Garden' || item.serviceCode == 'Pet' ||
        item.serviceCode == 'Professional' || item.serviceCode == 'CreativeCraft')) {
        item.showEndListing = true;
        if (item.saleStatusId == 2) {
          item.showRelist = true;
        }
      }*/
      if (item.saleStatusId == 2) { //2-Approved
        //item.showRelist = true;
        let currentDate = formatService.getCurrentDateForServer();
        if (item.subscriptionEndDate < currentDate) {
          item.showSubscriptionMsg = true;
        }
      }
    }
    setSaleList(res);
  };

  const openSaleForm = (item) => {
    let contextObj = {
      serviceId: item.serviceId,
      stallId: item.stallId,
      saleId: item.id
    };
    setCurrentContext(prevState => {
      return { ...prevState, ...contextObj }
    });
    setListingFormOpen(true);
  }

  const handleSaleFormClose = () => {
    setListingFormOpen(false);
    setCurrentContext(defaultContext);
    getProviderListings(filter);
  }

  const confirmDelete = (item) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...{ saleId: item.id } }
    });
    setConfirmOpen(true);
    setMessage("Are you sure to delete listing " + item.title + "?");
    setAction("delete");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    if (action == "relist") {
      checkEligibilityForRelist(currentContext.saleId);
    }
    else if (action == "delete") {
      deleteSale(currentContext.saleId);
    }
    else if (action == "endlisting") {
      updateListingEndDate(currentContext.saleId);
    }
    setConfirmOpen(false);
  }

  const deleteSale = (id) => {
    let inputData = {
      id: id,
    };
    let apiOptions: any = {
      url: `sale/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderListings(filter);
    })
  };

  const confirmRelist = (item) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...{ saleId: item.id } }
    });
    setConfirmOpen(true);
    setAction("relist");
    setMessage("Are you sure to relist " + item.title + "?");
  }

  const checkEligibilityForRelist = (id) => {
    let inputData = {
      id: id
    };
    let apiOptions: any = {
      url: `sale/relist/eligibility`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg("Relist updated successfully");
      getProviderListings(filter);
    })
  };

  const confirmEndListing = (item) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...{ saleId: item.id } }
    });
    setConfirmOpen(true);
    setAction("endlisting");
    setMessage("Are you sure to end listing " + item.title + "?");
  }

  const updateListingEndDate = (id) => {
    let inputData = {
      id: id,
      endDate: formatService.getPreviousDate()
    };
    let apiOptions: any = {
      url: `sale/updatelisting`,
      data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg("End list updated successfully");
      getProviderListings(filter);
    })
  };


  const openSaleItemForm = (item) => {
    let navData = {
      targetRoute: '/provider/saleitemform/' + item.id,
      targetTitle: "Sale Item Form",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openImages = (item: any) => {
    let config = {
      api: `sale/${item.id}/images`,
      title: item.title
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
  }

  const navigateToServices = () => {
    /*if (saleList.length == 1) {
      session.setItem('otherCategoryItems', JSON.stringify([]));
      const item = saleList[0];
      let navData = {
        targetRoute: '/provider/createservicerequest/' + item.serviceId,
        targetTitle: "Service Home",
        targetData: { stallId: item.stallId, stallName: item.stallName, navToBack: true },
        sourceState: {},
        eventSource: '',
      };
      navigate(navData);
    }
    else if (filter.serviceId > -1) {
      session.setItem('otherCategoryItems', JSON.stringify([]));
      let navData = {
        targetRoute: '/provider/createservicerequest/' + filter.serviceId,
        targetTitle: "Service Home",
        targetData: { navToBack: true },
        sourceState: {},
        eventSource: '',
      };
      navigate(navData);
    }*/
    if (stallServiceMapList.length > 0) {
      let item: any = _.find(stallServiceMapList, { id: filter.stallId });
      let serviceMap = item.stallServiceMapList;
      if (serviceMap && serviceMap.length == 1) {
        session.setItem('otherCategoryItems', JSON.stringify([]));
        let navData = {
          targetRoute: '/provider/createservicerequest/' + serviceMap[0].serviceId,
          targetTitle: "Service Home",
          targetData: { stallId: serviceMap[0].stallId, stallName: item.stallName, defaultBehaviorCode: 'Listings', navToBack: true },
          sourceState: {},
          eventSource: '',
        };
        navigate(navData);
      }
      else {
        let navData = {
          targetRoute: '/provider/providerservices/',
          targetTitle: "Create Stall",
          targetData: {},
          sourceState: {},
          eventSource: '',
        };
        navigate(navData);
      }      
    }
  };

  const getStallServiceMapList = () => {
    let inputData = {
      filters: { communityId: communityId, userId: userId }
    };
    let apiOptions = {
      url: 'stall/providerstalls/withservicemap',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data.providerStalls;
      setStallServiceMapList(result);
    })
  };

  useEffect(() => {
    initLookup();
  }, []);

  return (
    <div className='managelistings-ui pt-5'>
      <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
        <span className="col-8 pt-3 py-1 pl-4">
          <span>Manage Listings</span>
        </span>
        <div className="col-3 pt-3 pr-0 text-right">
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={navigateToServices}
          >
            <i className="fas fa-plus pr-2"></i>
            Add
          </Fab>
        </div>
      </div>

      <div className='mx-3 my-3'>
        <div className="row mt-2 px-5 justify-content-between">
          <div className="col-3 px-0">
            <FxSelect name="saleTypeId" variant="outlined" label="Type"
              options={SaleType}
              selectedValue={filter.saleTypeId}
              valueField="id"
              displayField="text"
              size="small"
              style={{ width: 260 }}
              onValueChange={handleInputFilter} />
          </div>
          <div className="col-3 px-0">
            <FxSelect name="saleStatusId" variant="outlined" label="Sale Status"
              options={SaleStatus}
              selectedValue={filter.saleStatusId}
              valueField="id"
              displayField="text"
              size="small"
              style={{ width: 260 }}
              onValueChange={handleInputFilter} />
          </div>
          <div className="col-3 px-0">
            <FxSelect name="serviceId" variant="outlined" label="Service"
              options={ListingServices}
              selectedValue={filter.serviceId}
              valueField="id"
              displayField="text"
              size="small"
              style={{ width: 260 }}
              onValueChange={handleInputFilter} />
          </div>
          <div className="col-3 px-0">
            <FxSelect name="stallId" variant="outlined" label="Stall"
              options={ProviderStalls}
              selectedValue={filter.stallId}
              valueField="id"
              displayField="text"
              size="small"
              style={{ width: 260 }}
              onValueChange={handleInputFilter} />
          </div>
        </div>

        <div className='col-12 my-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  {/* <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell> */}
                  <TableCell width="10%">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {saleList.map((row: any, i) => (
                  <TableRow key={i}>
                    <TableCell className='text-center'>
                      <span onClick={() => openImages(row)}>
                        {!row.imageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                        {row.imageUrl && <img src={row.imageUrl} alt="Stall" width="50" height="50"></img>}
                      </span>
                      <div className='font-weight-bold font-12 pt-2'>
                        {row.stallName}
                      </div>
                    </TableCell>
                    <TableCell>
                      {row.title}
                      {
                        row.showSubscriptionMsg &&
                        <div>
                          <span className='text-danger'>
                            <b>
                              You do not have active subscription.<br />
                              Sale will not be displayed at consumer side.<br />
                              Please contact administrator.
                            </b>
                          </span>
                        </div>
                      }
                    </TableCell>
                    <TableCell>
                      {row.description}
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='pt-2 font-13'>{row.saleStatus}</div>
                    </TableCell>
                    {/* <TableCell>
                      <span className="font-12">
                        <FxDateDisplay value={row.startDate} />
                        {row.startTime &&
                          <span>
                            <i className="far fa-clock color-aaa px-1"></i>
                            <FxDateTimeDisplay value={row.startTime} displayType="time" />
                          </span>
                        }
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-12">
                        <FxDateDisplay value={row.endDate} />
                        {row.endTime &&
                          <span>
                            <i className="far fa-clock color-aaa px-1"></i>
                            <FxDateTimeDisplay value={row.endTime} displayType="time" />
                          </span>
                        }
                      </span>
                    </TableCell> */}
                    <TableCell>
                      {(row.saleStatusId == 1 || row.saleStatusId == 2) &&
                        <div>
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openSaleForm(row)}>Edit</Button>
                          {row.saleStatus == 'Pending' &&
                            <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmDelete(row)}>Delete</Button>
                          }
                          {!row.isActive &&
                            <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmRelist(row)}>Relist</Button>
                          }
                          {/* <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openSaleItemForm(row)}>Items</Button> */}
                          {row.isActive &&
                            <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmEndListing(row)}>End Listing</Button>
                          }
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="col-12 my-3 text-center">
          {saleList && saleList.length == 0 &&
            <span className='font-20'><b>No Listings</b></span>
          }
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={listingFormOpen} onClose={handleSaleFormClose}>
        <ListingFormContainer isEdit={true} stallId={currentContext.stallId} serviceId={currentContext.serviceId} saleId={currentContext.saleId} handleSaleFormClose={handleSaleFormClose} />
      </Drawer>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default ManageListings;