import React, { useEffect, useState } from 'react';
import './VerificationContainer.scss';
import { http, AlertService, FxInput } from '../fx-core';
import { Button } from '@material-ui/core';

export const VerificationContainer = (props) => {

    const defaultItem = {
        emailOtp: "",
        mobileOtp: ""
    };
    const [item, setItem] = useState(defaultItem);

    const verifyEmail = () => {
        let apiOptions: any = {
            url: `user/verify/email`,
            data: {}
        };
        http.post(apiOptions);
    };

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleClose = () => {
        props.closeDialog()
    };

    const verifyEmailStatus = () => {
        if (item.emailOtp.trim().length <= 0) {
            AlertService.showErrorMsg("Please provide the code");
            return;
        }
        let inputData = {
            otp: item.emailOtp
        };
        let apiOptions: any = {
            url: `user/verify/email/status`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            verifyEmailStatusCallBack(res);
        });
    };

    const verifyEmailStatusCallBack = (res) => {
        if (res && res.data) {
            onSuccessEmailVerify(res.data);
        }
    };

    const onSuccessEmailVerify = (res) => {
        if (res.isVerified) {
            props.handleVerification("success");
        }
        else {
            AlertService.showErrorMsg("Please provide valid code");
        }
    };

    const verifyMobile = () => {
        let apiOptions: any = {
            url: `user/verify/mobile`,
            data: {}
        };
        http.post(apiOptions);
    };

    const verifyMobileStatus = () => {
        if (item.mobileOtp.trim().length <= 0) {
            AlertService.showErrorMsg("Please provide the code");
            return;
        }
        let inputData = {
            otp: item.mobileOtp
        };
        let apiOptions: any = {
            url: `user/verify/mobile/status`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            verifyMobileStatusCallBack(res);
        });
    };

    const verifyMobileStatusCallBack = (res) => {
        if (res && res.data) {
            onSuccessMobileVerify(res.data);
        }
    };

    const onSuccessMobileVerify = (res) => {
        if (res.isVerified) {
            props.handleVerification("success");
        }
        else {
            AlertService.showErrorMsg("Please provide the valid code");
        }
    };

    useEffect(() => {
        verifyEmail();
        verifyMobile();
    }, []);

    return (
        <div className="modalalign">
            <div className="row justify-content-between title-font px-3">
                <div className="py-1 pl-3">
                    <span>Verification</span>
                </div>
                <div className="text-right pointer my-auto">
                    <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
                </div>
            </div>

            <div className="col-12 mt-2 ml-2">
                <span>Please enter the code received in your email</span>
            </div>
            <div className="col-12 mt-2 ml-2">
                <FxInput name="emailOtp" variant="outlined" label="Code" size="small"
                    value={item.emailOtp} onValueChange={handleInputChange} />
            </div>
            <div className="float-right mr-2 mb-4">
                <Button variant="contained" size="small" color="primary" onClick={verifyEmailStatus}>Verify</Button>
            </div>

            <div className="text-center m-4"><b>(or)</b></div>

            <div className="col-12 mt-4 ml-2">
                <span>Please enter the code received in your mobile</span>
            </div>
            <div className="col-12 mt-2 ml-2">
                <FxInput name="mobileOtp" variant="outlined" label="Code" size="small"
                    value={item.mobileOtp} onValueChange={handleInputChange} />
            </div>
            <div className="float-right mr-2 mb-2">
                <Button variant="contained" size="small" color="primary" onClick={verifyMobileStatus}>Verify</Button>
            </div>
        </div>
    );
};