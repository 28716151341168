import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import { localstorage } from './localStorageService';

export type NavParam = {
    targetRoute: string;
    targetData?: any;
    targetTitle: string;
    sourceState?: any;
    roles?: any;
    eventSource?: any;
    ouId?: any;
    url_ouId?: string;
    url_role?: string;
    useQueryParams?: boolean;
    closeMe?: any;
    skipAppendCode?:any;
};

function useNavigationService() {

    const location = useLocation();
    const history = useHistory();
    const navStack = useSelector<any, any>((s) => (s.navData ? s.navData.navStack : null));
    const globalDispatch = useDispatch();

    const getHead = () => {
        var result = null;
        if (navStack.length > 0) {
            result = navStack[navStack.length - 1];
        }
        return result;
    }

    const getItems = () => {
        return navStack;
    }

    const navigateRoot = (data: NavParam) => {
        let head = getHead();
        if (head && head.route == data.targetRoute) {
            return;
        }
        clear();
        navigate(data);
    }

    const navigate = (data: NavParam) => {
        console.log('navigate starts');

        let url = data.targetRoute;
        let code = localstorage.getItem('communityCode');
        let targetUrl = data.skipAppendCode ? url : `/${code}${url}`;
        data.targetRoute = targetUrl;
        
        //close modal 
        if (data.closeMe) {
            const actionType = data.closeMe.variant == 'drawer' ? "drawer-close" : "dialog-close";
            globalDispatch({ type: actionType, payload: { key: data.closeMe.key } });
        }
        
        navigateEntry(data);

        if (data.useQueryParams && data.targetData) {
            url += '&' + getQueryString(data.targetData);
        }

        history.push(targetUrl);
    }

    const navigateEntry = (data : NavParam ) => {
        const navData = {
            route: data.targetRoute,
            targetData: data.targetData,
            title: data.targetTitle,
            sourceRoute: location.pathname,
            sourceState: data.sourceState,
        };
        globalDispatch({ type: "nav-push", payload: navData });
    }

    const getQueryString = (data: any) => {
        let result = "";
        let keys = Object.keys(data);
        for (let key of keys) {
            result += key + "=" + data[key] + "&";
        }
        result = result.substr(0, result.length - 1);
        return result;
    }

    const openModal = (params: any) => {
        const actionType = params.variant == 'drawer' ? 'drawer-open' : 'dialog-open';
        globalDispatch({ type: actionType, payload: params });
    }

    const peek = () => {
        return getHead();
    }

    const getRouteData = () => {
        let head = getHead();
        let result = head ? head.targetData : {};
        const params = QueryString.parse(location.search);
        if (params) {
            result = { ...result, ...params };
        }
        return result;
    }

    const getPageState = () => {
        let head = getHead();
        let result = head ? head.pageState : {};
        return result;
    }

    const pop = () => {
        globalDispatch({ type: "nav-pop", payload: {} });
    }

    const goTo = (index: any) => {
        globalDispatch({ type: "nav-goTo", payload: { index: index } });
        const deleteCount = navStack.length - (index + 1);
        history.go((deleteCount * -1));
    }

    const clear = () => {
        globalDispatch({ type: "nav-clear", payload: {} });
    }

    const back = () => {
        pop();
        history.goBack();
    }

    return useMemo(() => {
        return {
            peek: peek,
            getItems: getItems,
            navigate: navigate,
            navigateRoot: navigateRoot,
            goTo: goTo,
            clear: clear,
            back: back,
            getRouteData: getRouteData,
            getPageState: getPageState,
            openModal: openModal,
            pageState: getPageState(),
            routeData: getRouteData(),
            navigateEntry: navigateEntry
        };
    }, [location, history]);
}

export { useNavigationService };