import React, { useEffect, useState } from 'react';
import './SpecialEventPayment.scss';
import { http, FxSelect, FxCurrency, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as _ from 'lodash';

const SpecialEventPayment = (props) => {

    const defaultItem = {
        paymentModeId: -1,
        amount: props.amount,
        id: props.id
    };
    const defaultCommunity = {
        accountNumber: "",
        accountName: ""
    };
    const [paymentMode, setPaymentMode] = useState([]);
    const [item, setItem] = useState(defaultItem);
    const [community, setCommunity] = useState(defaultCommunity);

    const initLookup = () => {
        let inputData = {
            lookups: {
                PaymentMode: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let paymentMode = response.data.PaymentMode;
            paymentMode = paymentMode.filter(p => p.id != 4); //4-Coupon
            setPaymentMode(paymentMode);
        })
    };

    const handleClose = (res: any = {}) => {
        props.handleClose(res);
    };

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    }

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const updatePaymentMode = () => {
        if (item.paymentModeId == -1) {
            AlertService.showErrorMsg("Please select payment mode.");
            return;
        }
        let selPayMode: any = _.find(paymentMode, { id: item.paymentModeId });
        let inputData = {
            entityId: item.id,
            paymentMode: selPayMode.code
        };
        let apiOptions: any = {
            url: `payment/servicerequest/paymentmode`,
            data: inputData
        };
        http.put(apiOptions).then(res => {
            updatePaymentModeCallBack(res);
        });
    };

    const updatePaymentModeCallBack = (res) => {
        const result = res.data;
        handleClose({ id: result.entityId, paymentMode: result.paymentMode });
    };

    const getSelCommunityInfo = () => {
        let apiOptions: any = {
            url: `community/${props.communityId}`
        };
        http.get(apiOptions).then(res => {
            const result = res.data;
            setCommunity(result);
        })
    }

    useEffect(() => {
        initLookup();
        getSelCommunityInfo();
    }, [])

    return (
        <div>
            <div className="row justify-content-between title-font py-1 mx-auto px-3">
                <div className="py-1">
                    <span>Payment</span>
                </div>
                <div className="float-right pointer" onClick={handleClose}>
                    <HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="m-4">
                <div className='m-1 row'>
                    <span className='font-weight-bold'>Amount</span>
                    <span className='ml-3'>
                        <FxCurrency value={item.amount} />
                    </span>
                </div>
                <div className="m-1 pt-3">
                    <FxSelect name="paymentModeId" variant="outlined" label="Payment Mode"
                        options={paymentMode}
                        selectedValue={item.paymentModeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        fullWidth
                        onValueChange={handleInputChange} />
                </div>
                {
                    item.paymentModeId == 2 &&
                    <div className='m-1 pt-3'>
                        <div>A/C No : {community.accountNumber}</div>
                        <div>Name : {community.accountName}</div>
                        <div className='mt-2 text-danger'>
                            Kindly transfer the amount and then submit. Request to be approved shortly.
                        </div>
                    </div>
                }
                <div className="m-1 pt-3 text-center">
                    <Button variant="contained" size="medium" color="primary" onClick={updatePaymentMode}>
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SpecialEventPayment;