import React, { useEffect, useState } from 'react';
import './CommunityMonthlyIncome.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Breadcrumbs, Typography } from '@material-ui/core';
import { http, FxCurrency, FxDateDisplay, formatService, FxDate, useNavigationService, FxSelect, localstorage } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';

const CommunityMonthlyIncome = (props) => {
    const { routeData, back } = useNavigationService();

    const monthStatDate = formatService.getMonthStartDate();
    let defaultFilter: any = {
        fromDate: formatService.getDateStringForServer(monthStatDate),
        toDate: formatService.getCurrentDateForServer(),
        communityId: routeData.communityId
    };

    const [filters, setFilters] = useState(defaultFilter);
    const [incomeList, setIncomeList] = useState([]);
    const [communityList, setCommunityList] = useState([]);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            communityId: filters.communityId
        }
        if (item.name == "fromDate") {
            filterObj.fromDate = formatService.getDateStringForServer(item.value);
        }
        else if (item.name == "toDate") {
            filterObj.toDate = formatService.getDateStringForServer(item.value);
        }
        else if (item.name == 'communityId') {
            filterObj.communityId = item.value;
        }
        getCommunityMonthlyIncome(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getCommunityMonthlyIncome = (data: any) => {
        let inputData = {
            ds: {
                resultset: [
                    { key: "income", type: "list" }
                ]
            },
            data: {
                communityId: data.communityId,
                fromDate: data.fromDate,
                toDate: data.toDate,
                roleCode: routeData.parent
            }
        };
        let apiOptions: any = {
            url: `general/dbo/GetCommunityMonthlyIncome`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getCommunityMonthlyIncomeCallBack(res);
        });
    };

    const getCommunityMonthlyIncomeCallBack = (res) => {
        let result = res.data;
        setIncomeList(result.income);
    }

    const navToBack = () => {
        back();
    };

    const getMyRegionCommunities = () => {
        let inputData = {
            lookups: {
                MyRegionCommunities: { default: false }
            },
            filters: {
                userId: localstorage.getItem("userId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            getMyRegionCommunitiesCallBack(response);
        })
    };

    const getMyRegionCommunitiesCallBack = (res) => {
        let result = res.data;
        setCommunityList(result.MyRegionCommunities);
    };

    const getMyZoneCommunities = () => {
        let inputData = {
            lookups: {
                MyZoneCommunities: { default: false }
            },
            filters: {
                userId: localstorage.getItem("userId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            getMyZoneCommunitiesCallBack(response);
        })
    }

    const getMyZoneCommunitiesCallBack = (res) => {
        let result = res.data;
        setCommunityList(result.MyZoneCommunities);
    };

    const getMyAdminCommunities = () => {
        let apiOptions: any = {
            url: 'community/role/list',
            data: {}
        };
        http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
    };

    const getMyAdminCommunitiesCallBack = (res) => {
        let result = res.data;
        let commuArr: any = [];
        for (let item of result) {
            let itemObj: any = {
                id: item.id,
                text: item.name
            };
            commuArr.push(itemObj);
        }
        setCommunityList(commuArr);
    };

    const initCalls = () => {
        if (routeData.parent == 'REGIONALMANAGER') {
            getMyRegionCommunities();
        }
        else if (routeData.parent == 'ZONALMANAGER') {
            getMyZoneCommunities();
        }
        else if (routeData.parent == 'COMMADMIN') {
            getMyAdminCommunities();
        }
    }

    useEffect(() => {
        initCalls();
        getCommunityMonthlyIncome(filters);
    }, []);

    return (
        <div className='community-monthly-income-ui pt-5'>
            <div className="title-font custom-head pl-4 pt-5">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                        <b>{routeData.communityName}</b>
                    </Link>
                    <Typography color="primary" className="breadcrumbs-style">Monthly Income</Typography>
                </Breadcrumbs>
            </div>
            <div className='row my-4 mx-2'>
                <div className="col-3">
                    <FxDate name="fromDate" variant="outlined" size="small" fullWidth label="From"
                        value={filters.fromDate} onValueChange={handleInputChange} />
                </div>
                <div className="col-3">
                    <FxDate name="toDate" variant="outlined" size="small" fullWidth label="To"
                        value={filters.toDate} onValueChange={handleInputChange} />
                </div>
                <div className='col-3 pt-3'>
                    <FxSelect name="communityId" variant="outlined"
                        label="Community"
                        options={communityList}
                        selectedValue={filters.communityId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>
            </div>
            <div className='row my-4 mx-4 pt-2'>
                <TableContainer component={Paper}>
                    <Table className="table-striped" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Provider Name</TableCell>
                                <TableCell>Community</TableCell>
                                <TableCell>Payment Date</TableCell>
                                <TableCell>Payment Mode</TableCell>
                                <TableCell>Entity Type</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Revenue</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {incomeList.length == 0 &&
                                <TableCell colSpan={7}>
                                    <NoRecords />
                                </TableCell>
                            }
                            {incomeList.length > 0 && incomeList.map((row: any, i) => (
                                <TableRow>
                                    <TableCell>
                                        {row.providerName}
                                    </TableCell>
                                    <TableCell>
                                        {row.communityName}
                                    </TableCell>                                    
                                    <TableCell>
                                        {<FxDateDisplay value={row.paymentDate} />}
                                    </TableCell>
                                    <TableCell>
                                        {row.paymentMode}
                                    </TableCell>
                                    <TableCell>
                                        {row.entityType}
                                    </TableCell>
                                    <TableCell>
                                        {<FxCurrency value={row.amount} />}
                                    </TableCell>
                                    <TableCell>
                                        {<FxCurrency value={row.revenueAmount} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
export default CommunityMonthlyIncome;