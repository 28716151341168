import React, { useEffect, useState } from 'react';
import './StallFiltersMobile.scss';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { http, localstorage } from '../../fx-core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const customClass = props.customClass || "p-3";
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                //   <Box p={2}>
                <div className={customClass}>
                    {children}
                </div>
                //   </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex', 
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,  
    },
}));


const StallFiltersMobile = (props: any) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let defaultItem: any = {
        categoryIds: props.defaultItem.categoryIds,
        selectedCategory: false,
        isDeliveryEnabled: props.defaultItem.isDeliveryEnabled,
        isPickupEnabled: props.defaultItem.isPickupEnabled
    };

    const [item, setItem] = useState(defaultItem);
    const [categories, setCategories] = useState([]);

    const handleCheckbox = (event) => {
        if (event.target.name == "selectedCategory") {
            let selectedValue = parseInt(event.target.value);
            if (event.target.checked) {
                item.categoryIds.push(selectedValue);
            }
            else {
                let itemIdx = item.categoryIds.indexOf(selectedValue);
                item.categoryIds.splice(itemIdx, 1);
            }
            for (let itemObj of categories) {
                if (itemObj.id == selectedValue) {
                    itemObj.selectedCategory = event.target.checked;
                }
            }
            handleItemChange({ "categoryIds": item.categoryIds });
        }
        else {
            handleItemChange({ [event.target.name]: event.target.checked });
        }

        props.callbackData({
            categoryIds: item.categoryIds,
            isDeliveryEnabled: event.target.name == "isDeliveryEnabled" ? event.target.checked : item.isDeliveryEnabled,
            isPickupEnabled: event.target.name == "isPickupEnabled" ? event.target.checked : item.isPickupEnabled
        });
    }

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookUp = () => {
        getCategories();
    }

    const getCategories = () => {
        let inputData = {
            serviceId: props.serviceId,
            communityId: parseInt(localstorage.getItem('communityId'))
        }
        let apiOptions: any = {
            url: `portal/category/active`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.categories;
            if (item.categoryIds.length == 0) {
                setCategories(result);
            }
            else {
                computeSelectedCategory(result);
            }
        });
    }

    const computeSelectedCategory = (categories) => {
        for (let itemObj of categories) {
            for (let catgId of item.categoryIds) {
                if (itemObj.id == catgId) {
                    itemObj.selectedCategory = true;
                }
            }
        }
        setCategories(categories);
    }

    useEffect(() => {
        initLookUp();
    }, []);


    return (
        <div className={classes.root}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" orientation="vertical" variant="scrollable" className="pt-4" >
                <Tab label="Categories" {...a11yProps(0)} style={{ outline: "none", fontWeight: "bold" }} />
                <Tab label="Delivery Type" {...a11yProps(0)} style={{ outline: "none", fontWeight: "bold" }} />
            </Tabs>
            <TabPanel value={value} index={0} className="pt-1">
                <div>
                    {
                        categories.length > 0 && categories.map((item, i) => (
                            <div className="font-14">
                                <Checkbox name="selectedCategory" color="primary" value={item.id} checked={item.selectedCategory} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon
                                    fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                                {item.categoryName}
                            </div>
                        ))
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="pt-1">
                <div className="font-14">
                    <Checkbox name="isDeliveryEnabled" color="primary" value={item.isDeliveryEnabled} checked={item.isDeliveryEnabled}
                        onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Delivery
                </div>
                <div className="font-14">
                    <Checkbox name="isPickupEnabled" color="primary" value={item.isPickupEnabled} checked={item.isPickupEnabled}
                        onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Pickup
                </div>
            </TabPanel>
        </div>
    );
}
export default StallFiltersMobile;