import React, { useEffect, useState } from 'react';
import './ManageStallUsers.scss';
import { http, FxInput, AlertService, formValidator } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import _ from "lodash";

const ManageStallUsers = (props) => {
  let defaultItem =
  {
    id: 0,
    userId: 0,
    stallId: props.stallId,
    email: ""
  };
  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [stallUsers, setStallUsers] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [message, setMessage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getStallUsers = () => {
    let apiOptions: any = {
      url: `stall/users/${item.stallId}`
    };
    http.get(apiOptions).then(res => {
      getStallUsersCallBack(res);
    });
  };

  const getStallUsersCallBack = (res) => {
    const result = res.data;
    for (let item of result) {
      item.fullName = item.lastName ? item.firstName + " " + item.lastName : item.firstName;
    }
    setStallUsers(result);
  };

  const handleClose = () => {
    props.handleManageUsersClose();
  };

  const validationMap = {
    email: ["required", "email"]
  };

  const bulkValidate = () => {
    let items: any = [];
    for (var key in validationMap) {
      let itemVal = { name: key, value: item[key], validations: validationMap[key] }
      items.push(itemVal);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };

  const sendInviteLink = () => {
    const isValid = bulkValidate();
    if (isValid) {
      let inputData: any = {
        email: item.email,
        stallId: item.stallId
      };
      let apiOptions: any = {
        url: `stall/transfer/mailinvitelink`,
        data: inputData
      };
      http.post(apiOptions).then(res => {
        sendInviteLinkCallBack(res);
      });
    }
  };

  const sendInviteLinkCallBack = (res) => {
    AlertService.showMsg({ message: "Invite link sent successfully" });
    handleClose();
  }

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setConfirmOpen(true);
    setMessage("Do you want to delete this user?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setSelectedItem({});
  };

  const onConfirmCallback = () => {
    const item = selectedItem;
    deleteStallUser(item);
    handleDialogClose()
  }

  const deleteStallUser = (item) => {
    let apiOptions: any = {
      url: `stall/usermap/delete`,
      data: item
    };
    http.post(apiOptions).then(res => {
      deleteStallUserCallBack(res)
    })
  };

  const deleteStallUserCallBack = (res) => {
    AlertService.showSuccessMsg();
    handleClose();
  };

  useEffect(() => {
    getStallUsers();
  }, []);

  return (
    <div className='managestallusers-ui confirmContainer'>
      <div className="row justify-content-between title-font px-3">
        <div className="py-1 pl-3">
          <span>Manage Users</span>
        </div>
        <div className="text-right pointer my-auto">
          <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
        </div>
      </div>

      <div className="row my-2 p-3 justify-content-between">
        <div className="col-8 py-3">
          <FxInput name="email" variant="outlined" label="Email"
            value={item.email}
            fullWidth
            size="small"
            onValueChange={handleInputChange} />
          {
            hasError("email", "required") &&
            <div className="validation-msg">Email is required</div>
          }
          {
            hasError("email", "email") &&
            <div className="validation-msg">Please enter valid Email</div>
          }
        </div>
        <div className="col-4 py-4">
          <Button variant="contained" size="small" color="primary" onClick={sendInviteLink}>
            Invite
          </Button>
        </div>
        {stallUsers.map((row: any, i) => (
          <div className="col-12 row pt-3 pr-0 pl-3">
            <span className='col-8'>
              {row.userName}
              {row.fullName &&
                <span> - {row.fullName}</span>
              }
            </span>
            <span className='col-4 text-right pr-1 pointer'>
              <i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row)}></i>
            </span>
          </div>
        ))
        }
      </div>

      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default ManageStallUsers;