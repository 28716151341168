import React, { useEffect, useState } from 'react';
import './DeliverySlots.scss';
import { FxTime, http, formatService, AlertService, FxInput } from '../../fx-core';
import { Dialog, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Fab } from '@material-ui/core';
import _ from "lodash";
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const DeliverySlots = (props) => {

  let defaultContext = {
    startTime: "",
    endTime: ""
  }
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [deliverySlots, setDeliverySlots] = useState([]);
  const [message, setMessage] = useState(null);
  const [stallDeliverySlotId, setStallDeliverySlotId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleCurrentContextItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleInputChange = (data, item) => {
    if (data.name == 'startTime') {
      item.startTime = data.value;
    }
    else if (data.name == 'endTime') {
      item.endTime = data.value;
    }
    else if (data.name == 'description') {
      item.description = data.value;
    }
    setDeliverySlots(deliverySlots);
    handleCurrentContextItemChange(data);
  }

  const getStallDeliverySlots = () => {
    let inputData = {
      stallId: props.stallId
    }
    let apiOptions: any = {
      url: `stalldeliveryslot/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result.length > 0) {
        for (let item of result) {
          item.startTime = item.startTime ? formatService.getConvertedTimeForDisplay(item.startTime) : null;
          item.endTime = item.endTime ? formatService.getConvertedTimeForDisplay(item.endTime) : null;
        }
        setDeliverySlots(result);
      }
      else {
        addNewStallDeliverySlot();
      }
    })
  };

  const addNewStallDeliverySlot = () => {
    let deliverySlotList: any = [];
    for (let item of deliverySlots) {
      deliverySlotList.push(item);
    }
    let newItem = { id: 0, description: null, startTime: null, endTime: null, stallId: 0 };
    deliverySlotList.push(newItem);
    setDeliverySlots(deliverySlotList);
  }

  const saveStallDeliverySlots = () => {
    const hasEmptyVal = _.filter(deliverySlots, (d) => {
      return !d.startTime || !d.endTime;
    });
    if(hasEmptyVal.length > 0) {
      AlertService.showErrorMsg("Start and End time are required.");
      return;
    }
    for (let item of deliverySlots) {
      item.stallId = props.stallId;
      item.startTime = item.startTime ? formatService.getConvertedTimeForServer(item.startTime) : null;
      item.endTime = item.endTime ? formatService.getConvertedTimeForServer(item.endTime) : null;
    }
    let inputData = {
      stallDeliverySlotList: deliverySlots
    }
    let apiOptions: any = {
      url: `stalldeliveryslot/save`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      saveStallDeliverySlotsCallback();
    })
  }

  const saveStallDeliverySlotsCallback = () => {
    AlertService.showSuccessMsg();
    props.handleDeliverySlotsClose();
  }

  const confirmDelete = (id) => {
    setStallDeliverySlotId(id);
    setConfirmOpen(true);
    setMessage("Are you sure to delete this slot?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    deleteDeliverySlot();
  }

  const handleClose = () => {
    props.handleDeliverySlotsClose();
  }

  const deleteDeliverySlot = () => {
    let inputData = {
      id: stallDeliverySlotId,
    };
    let apiOptions: any = {
      url: `stalldeliveryslot/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getStallDeliverySlots();
    })
  };

  useEffect(() => {
    getStallDeliverySlots();
  }, []);

  return (
    <div className='deliveryslots-ui'>
      <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
        <span className="col-11">
          <span>Delivery Slots</span>
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>
     
        <div className='drawer-combo pt-3'>
          <span className='d-flex justify-content-end mr-4 pr-1 pb-3'>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={addNewStallDeliverySlot}
          >
            <i className="fas fa-plus pr-2"></i>
            Add
          </Fab>
          </span>         
        </div>
        <div className='px-3 py-3 pt-4'>
        <div className='my-4 pb-5 pt-5 delivery-slot-scroll'>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {deliverySlots.map((row, i) => (
                <TableBody>
                  {row.status != 2 &&
                    <TableRow>
                      <TableCell>
                        <FxInput name="description" variant="outlined" fullWidth size="small"
                          value={row.description} onValueChange={(e) => handleInputChange(e, row)} />
                      </TableCell>
                      <TableCell className='pt-0'>
                        <FxTime name="startTime" variant="outlined" value={row.startTime}
                          onValueChange={(e) => handleInputChange(e, row)} className="timestyle" />
                      </TableCell>
                      <TableCell className='pt-0'>
                        <FxTime name="endTime" variant="outlined" value={row.endTime}
                          onValueChange={(e) => handleInputChange(e, row)} className="timestyle" />
                      </TableCell>
                      <TableCell>
                        {row.id > 0 &&
                          <span className='float-right'>
                            <i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row.id)}></i>
                          </span>
                        }
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>        
      </div>
      <div className='px-3 py-2 drawer-footer'>
          <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveStallDeliverySlots}><b>Save</b></Button>
        </div>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default DeliverySlots;