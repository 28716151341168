import React, { useEffect, useState } from 'react';
import './ProviderAccountList.scss';
import { http, FxDateDisplay, FxCurrency, useNavigationService, AlertService } from '../../fx-core';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab, Link, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ProviderAccountForm from '../ProviderAccountForm/ProviderAccountForm';
import { NoRecords } from '../../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import useRazorpay from '../../utils/useRazorPay';

const ProviderAccountList = (props) => {
  const { navigate } = useNavigationService();
  const Razorpay = useRazorpay();

  const useStyles = makeStyles({
    container: {
      maxHeight: '70vh',
    },
  });

  const from = props.match.params.from ? props.match.params.from : "";
  const stallId = props.match.params.id ? props.match.params.id : "";

  const defaultAccount = {
    currentBalance: 0
  };
  const [account, setAccount] = useState(defaultAccount);
  const [accountDetails, setAccountDetails] = useState([]);
  const [open, setOpen] = useState(false);

  const getProviderAccountDetails = () => {
    let apiOptions: any = {
      url: `account/provider/details`
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result.account) {
        setAccount(result.account);
        setAccountDetails(result.accountDetails);
      }
    });
  };

  const openProviderAccountForm = () => {
    setOpen(true);
  };

  const closeProviderAccountForm = (res: any) => {
    setOpen(false);
    if (res && res.id > 0) {
      if (res.paymentMode == "razorpay") {
        initiatePayment(res.id);
      }
      getProviderAccountDetails();
    }
  };

  const initiatePayment = (accdetailId) => {
    let inputData = {
      entityTypeId: 3, // 3-Account
      entityId: accdetailId
    };
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item = resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        getProviderAccountDetails();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const navToMyStallList = () => {
    let navData = {
      targetRoute: '/provider/mystalls',
      targetTitle: "My Stalls",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  };

  const navToProviderDashboard = () => {
    let navData = {
      targetRoute: '/provider/providerdashboard/' + stallId,
      targetTitle: "Provider Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  };

  useEffect(() => {
    getProviderAccountDetails();
  }, []);

  const classes = useStyles();

  return (
    <div className='provideraccountlist-ui pt-5'>
      <div className="title-font custom-head pt-4 mt-3 pl-4">
        <Breadcrumbs aria-label="breadcrumb">
          {
            from == "pd" &&
            <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToProviderDashboard}>
              <b>Provider Dashboard</b>
            </Link>
          }
          {
            from == "ms" &&
            <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToMyStallList}>
              <b>Stalls</b>
            </Link>
          }
          <Typography color="primary" className="breadcrumbs-style">Account Credits</Typography>
        </Breadcrumbs>
      </div>
      <div className='col-12 pb-2 pt-2'>
        <div className='text-right'>
          <span>
            Balance: <b><FxCurrency value={account.currentBalance} /></b>
          </span>
        </div>
        <span className="float-right pointer">
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={openProviderAccountForm}>
            <i className="fas fa-plus pr-2"></i>
            Buy Credit
          </Fab>
        </span>
      </div>
      <div className='my-4 mx-3 pt-3'>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader size="small" className='table-striped'>
            <TableHead>
              <TableRow>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell align='right'>Order Number</TableCell>
                <TableCell align='center'>Order Date</TableCell>
                <TableCell align='right'>Amount</TableCell>
                <TableCell align='center'>Payment</TableCell>
              </TableRow>
            </TableHead>
            {accountDetails.length == 0 &&
              <TableCell colSpan={6}>
                <NoRecords />
              </TableCell>
            }
            {accountDetails.length > 0 &&
              accountDetails.map((row: any, i) => (
                <TableBody key={i}>
                  <TableRow>
                    <TableCell>
                      <FxDateDisplay value={row.transactionDate} />
                    </TableCell>
                    <TableCell>
                      {row.transactionType}
                    </TableCell>
                    <TableCell align='right'>
                      {row.orderNumber}
                    </TableCell>
                    <TableCell align='center'>
                      <FxDateDisplay value={row.orderDate} />
                    </TableCell>
                    <TableCell align='right'>
                      <FxCurrency value={row.amount} />
                    </TableCell>
                    <TableCell align='center'>
                      {
                        row.accountPaymentMode &&
                        <span>
                          {row.accountPaymentMode} - {row.paymentStatus}
                          {
                            row.accountPaymentMode == 'razorpay' && row.paymentStatus == 'Pending' &&
                            <Button variant='outlined' size='small' className='button-style ml-1' onClick={() => initiatePayment(row.id)}>Pay</Button>
                          }
                        </span>
                      }
                      {
                        !row.accountPaymentMode &&
                        <span>{row.paymentStatus}</span>
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))
            }
          </Table>
        </TableContainer>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth
          onClose={closeProviderAccountForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ProviderAccountForm closeProviderAccountForm={closeProviderAccountForm} />
        </Dialog>
      </div>
    </div>
  );
}

export default ProviderAccountList;