import React, { useEffect, useState } from 'react';
import './ManagersReviews.scss';
import { http, FxHumanDateDisplay, useNavigationService, formatService, FxDate } from '../../fx-core';
import Rating from '@material-ui/lab/Rating';
import PersonIcon from '@material-ui/icons/Person';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';

const ManagersReviews = (props: any) => {
    const { routeData, back } = useNavigationService();

    const currentDate = formatService.getCurrentDateForServer();
    let defaultFilter = {
        fromDate: formatService.getYearStartDate(),
        toDate: currentDate
    };
    const [filters, setFilters] = useState(defaultFilter);
    const [reviewList, setReviewList] = useState([]);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            fromDate: filters.fromDate,
            toDate: filters.toDate
        }
        if (item.name == "fromDate") {
            filterObj.fromDate = formatService.getDateStringForServer(item.value);
        }
        else if (item.name == "toDate") {
            filterObj.toDate = formatService.getDateStringForServer(item.value);
        }
        getCommunityReviews(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getCommunityReviews = (data: any) => {
        let inputData = {
            communityId: routeData.communityId,
            fromDate: data.fromDate,
            toDate: data.toDate
        }
        let apiOptions: any = {
            url: `review/query/dashboard`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getCommunityReviewsCallBack(res);
        });
    }

    const getCommunityReviewsCallBack = (res) => {
        setReviewList(res.data);
    }

    const navToBack = () => {
        back();
    }

    useEffect(() => {
        getCommunityReviews(filters);
    }, []);

    return (
        <div className="community-reviews-ui pt-4 mt-3">
            <div className="title-font custom-head pl-4 pt-5">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                        <b>{routeData.communityName}</b>
                    </Link>
                    <Typography color="primary" className="breadcrumbs-style">Reviews</Typography>
                </Breadcrumbs>
            </div>
            <div className='row my-4 mx-2'>
                <div className="col-3">
                    <FxDate
                        name="fromDate"
                        value={filters.fromDate}
                        variant="outlined"
                        label="From Date"
                        fullWidth
                        size="small"
                        onValueChange={handleInputChange}
                    />
                </div>
                <div className="col-3">
                    <FxDate
                        name="toDate"
                        value={filters.toDate}
                        variant="outlined"
                        label="To Date"
                        fullWidth
                        size="small"
                        onValueChange={handleInputChange}
                    />
                </div>
            </div>
            <div className='mx-2 pt-1 col-12 alignbox'></div>
            <div>
                {
                    reviewList.length == 0 &&
                    <div className="px-5 py-5 font-20 mx-5 text-center">
                        No Reviews Found
                    </div>
                }
            </div>
            <div>
                {
                    reviewList.map((item: any) => (
                        <div className="row mx-2 pt-1 col-12 alignbox">
                            <div className="col-8">
                                {item.reviewComment}
                            </div>

                            <div className="col-4">
                                <span className='float-right'>
                                    <Rating name="read-only" value={item.rating} readOnly />
                                </span>
                            </div>

                            <div className="col-8">
                                <span>
                                    <PersonIcon />
                                    {item.userName}
                                </span>
                            </div>

                            <div className="col-2">
                                <span className='bold-text'>
                                    <FxHumanDateDisplay value={item.ratingDate} displayType="datetime" />
                                </span>
                            </div>
                            <div className='col-2'>
                                <span className='float-right'>
                                    {item.communityName}
                                </span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );

}

export default ManagersReviews;