import React, { useState, useEffect } from 'react';
import './MessageList.scss';
import { http, general, FxDateTimeDisplayLocal, formatService } from '../fx-core';
import _ from "lodash";
import dummyImage from "./../../assets/images/dummy.png";
import { Table, TableContainer, TableBody, TableCell, Paper, TableRow, Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';

const MessageList = (props: any) => {
  const chat = useSelector<any, any>(s => s.chat);
  const defaultItem = {
    msgBody: "",
    targetUserId: 0,
    msgByname: "",
    profileImageUrl: "",
    msgEntityTypeId: 0,
    msgEntityDisplayFields: {},
    currentDate: formatService.getCurrentDateForServer()
  };

  const [item, setItem] = useState(defaultItem);
  const [userId, setUserId] = useState(props.userId);
  const [messageList, setMessageList] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getMessagesByUserId = () => {
    let apiOptions: any = {
      url: `message/query/user/${userId}`
    };
    http.get(apiOptions).then(res => {
      getMessagesCallback(res.data);
    })
  };

  const getMessagesCallback = (result) => {
    //compute unread message count
    var msgUnreadMap = {};
    for (let item of result) {
      if (!msgUnreadMap[item.threadId]) {
        msgUnreadMap[item.threadId] = 0;
      }
      if (item.targetUserId == userId && item.msgStatus == 'Sent') {
        msgUnreadMap[item.threadId] = msgUnreadMap[item.threadId] + 1;
      }
    }

    result = _.orderBy(result, ['msgDateTime'], ['desc']);
    result = _.uniqBy(result, 'threadId');
    for (let msg of result) {
      if (msg.msgById == userId) {
        msg.displayName = msg.targetUserName;
      }
      else if (msg.targetUserId == userId) {
        msg.displayName = msg.msgByName;
      }
      msg.unreadCount = msgUnreadMap[msg.threadId];
      msg.profileImageUrl = general.getImageUrl(msg.profileImagePath);
      let convertedMsgDate = formatService.getDateStringForServer(msg.msgDateTime);
      if (item.currentDate == convertedMsgDate) {
        msg.showTime = true;
      }
      else {
        msg.showTime = false;
      }
    }
    setMessageList(result);
  }

  const navToMessageThread = (item) => {
    props.handleMessageListClose(item.threadId);
  }

  useEffect(() => {
    getMessagesByUserId();
  }, []);

  useEffect(() => {
    if (chat && chat.threadId) {
      getMessagesByUserId();
    }
  }, [chat]);

  return (
    <div className="messagelist-ui">
      <TableContainer>
        <Table stickyHeader className="table table-striped" size="small">
          <TableBody>
            {
              messageList.map((row) => (
                <TableRow key={row.id} onClick={() => navToMessageThread(row)}>
                  <TableCell width="10%">
                    {/* {!row.profileImageUrl && <img src={dummyImage} alt="Item" width="50" height="50"></img>} */}
                    {!row.profileImageUrl && <i className="fas fa-user-circle dummy-image"></i>}
                    {row.profileImageUrl && <img src={row.profileImageUrl} alt="Item" width="50" height="50"></img>}
                  </TableCell>
                  <TableCell>
                    <div>
                      <div className='font-weight-bold'>{row.displayName}</div>
                      <div className='font-12 color-grey'>{row.msgEntityDisplayFields.stallName}</div>
                      <div className='font-12 color-grey'>
                        {row.msgEntityTypeId == 2 &&
                          <span>Order#{row.msgEntityDisplayFields.orderIdentifier}</span>
                        }
                        {row.msgEntityTypeId == 3 &&
                          <span>{row.msgEntityDisplayFields.postTitle}</span>
                        }
                        {row.msgEntityTypeId == 4 &&
                          <span>Order#{row.msgEntityDisplayFields.saleOrderIdentifier}</span>
                        }
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <span className='font-13'>{row.msgBody}</span>
                  </TableCell>
                  <TableCell>
                    <div className='float-right font-11 color-aaa'>
                      {
                        row.showTime &&
                        <FxDateTimeDisplayLocal value={row.msgDateTime} displayType="time" />
                      }
                      {
                        !row.showTime &&
                        <FxDateTimeDisplayLocal value={row.msgDateTime} displayType="date" />
                      }
                    </div>
                    <div className='pt-4'>
                      {row.unreadCount > 0 &&
                        <span className='float-right pr-2'>
                          <Badge badgeContent={row.unreadCount} color="primary">
                          </Badge>
                        </span>
                      }
                    </div>
                  </TableCell>
                  {/* <TableCell width="100%" className="font-16 pointer">
                                        <div>
                                            <div>
                                                <span className='font-weight-bold'>{row.displayName}
                                                    {row.entityId > 0 &&
                                                        <span>
                                                            {row.msgEntityTypeId > 0 &&
                                                                <span> _ {row.msgEntityDisplay}</span>
                                                            }
                                                        </span>
                                                    }
                                                </span>
                                                <span className='float-right font-11 color-aaa'>                                                    
                                                    <FxDateTimeDisplayLocal value={row.msgDateTime} displayType="datetime" />
                                                </span>

                                            </div>
                                            <div>
                                                <span className='font-13'>{row.msgBody}</span>
                                                {row.unreadCount > 0 &&
                                                    <span className='float-right pr-2'>
                                                        <Badge badgeContent={row.unreadCount} color="primary">
                                                        </Badge>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </TableCell> */}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default MessageList;