import React, { useState, useEffect } from 'react';
import './ServiceRequestDetail.scss';
import { http, general, useNavigationService, FxDateDisplay, formatService, AlertService } from '../../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import dummyImage from "../../../../assets/images/dummy.png";
import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog';
import ServiceRequestConfirmDialog from '../../ServiceRequestConfirmDialog/ServiceRequestConfirmDialog';

const ServiceRequestDetail = (props: any) => {

  let defaultItem: any = {
    serviceRequestType: "",
    stallId: 0,
    stallName: "",
    serviceRequestStatusId: 0
  };
  let defaultSplEventItem = {
    communityName: "",
    serviceName: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: ""
  };
  const { navigate } = useNavigationService();
  const [serviceRequest, setServiceRequest] = useState(defaultItem);
  const [serviceRequestDetails, setServiceRequestDetails] = useState([]);
  const [showSpecialEvent, setShowSpecialEvent] = useState(false);
  const [specialEventItem, setSpecialEventItem] = useState(defaultSplEventItem);
  const [questions, setQuestions] = useState([]);
  const [queOptions, setQueOptions] = useState([]);
  const [answerMap, setAnswerMap] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");
  const [splEvtConfirmOpen, setSplEvtConfirmOpen] = useState(false);

  const getServiceRequestSummary = () => {
    let apiOptions: any = {
      url: `servicerequest/detail/${props.serviceRequestId}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      getServiceRequestSummaryCallBack(result);
    });
  }

  const getServiceRequestSummaryCallBack = (res) => {
    let serviceRequest = res.serviceRequest;
    setServiceRequest(serviceRequest);
    for (let item of res.serviceRequestDetails) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setServiceRequestDetails(res.serviceRequestDetails);
    if (serviceRequest.communityTypeId == 2) {
      setShowSpecialEvent(true);
      getStallCommunityMapByServiceReqId();
    }
  }

  const getStallCommunityMapByServiceReqId = () => {
    let apiOptions: any = {
      url: `stallcommunitymap/servicerequest/${props.serviceRequestId}`
    };
    http.get(apiOptions).then(res => {
      getStallCommunityMapByServiceReqIdCallBack(res);
    });
  };

  const getStallCommunityMapByServiceReqIdCallBack = (res) => {
    let result = res.data;
    result.startTime = result.startTime != null ? formatService.getConvertedTimeForDisplay(result.startTime) : null;
    result.endTime = result.endTime != null ? formatService.getConvertedTimeForDisplay(result.endTime) : null;
    setSpecialEventItem(result);
    getCommunityQuestionOptions(result.communityId);
  };

  const getCommunityQuestionOptions = (communityId) => {
    let apiOptions: any = {
      url: `question/community/${communityId}`
    };
    http.get(apiOptions).then(res => {
      getCommunityQuestionOptionsCallBack(res);
    });
  }

  const getCommunityQuestionOptionsCallBack = (res) => {
    let result: any = res.data;
    let ansMap: any = {};
    for (let question of result.questions) {
      ansMap[question.id.toString()] = "";
      question.identifier = question.id.toString();
    }
    setAnswerMap(ansMap);
    setQuestions(result.questions);
    setQueOptions(result.questionOptions);
    getServiceReqQueAns(props.serviceRequestId);
  }

  const getServiceReqQueAns = (id) => {
    let inputData = {
      id: id
    };
    let apiOptions: any = {
      url: `servicerequest/question/answers`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getServiceReqQueAnsCallBack(res);
    });
  };

  const getServiceReqQueAnsCallBack = (res) => {
    let result = res.data;
    let ansMap: any = {};
    for (let r of result) {
      ansMap[r.questionId.toString()] = r.answerText;
    }
    setAnswerMap(ansMap);
  };

  const confirmApprove = () => {
    let msg = "Do you want to approve #" + serviceRequest.serviceRequestIdentifier + "?";
    setMessage(msg);
    if (showSpecialEvent) {
      setSplEvtConfirmOpen(true);
    }
    else {
      setConfirmOpen(true);
    }
    setAction("approve");
  }

  const confirmReject = () => {
    let msg = "Do you want to reject #" + serviceRequest.serviceRequestIdentifier + "?";
    setMessage(msg);
    setConfirmOpen(true);
    setAction("reject");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setMessage("");
    setAction("")
  };

  const onConfirmCallback = (ctx, comments) => {
    let item: any = serviceRequest;
    item.comments = comments;
    if (action == "approve") {
      item.serviceRequestStatusId = 2;
    }
    if (action == "reject") {
      item.serviceRequestStatusId = 3;
    }
    updateServiceReqStatus(item);
    handleDialogClose();
  };

  const handleSplEvtDialogClose = () => {
    setSplEvtConfirmOpen(false);
    setMessage("");
    setAction("")
  };

  const onSplEvtConfirmCallback = (itemObj) => {
    let item: any = serviceRequest;
    item.amount = itemObj.amount;
    item.comments = itemObj.comments;
    item.serviceRequestStatusId = 2;
    updateServiceReqStatus(item);
    handleSplEvtDialogClose();
  };

  const updateServiceReqStatus = (item) => {
    let apiOptions = {
      url: 'servicerequest/updatestatus',
      data: item
    };
    http.put(apiOptions).then(res => {
      updateServiceReqStatusCallBack(res);
    });
  }

  const updateServiceReqStatusCallBack = (res) => {
    AlertService.showMsg({ message: "Service request status updated successfully" });
    handleClose();
  }

  const handleClose = () => {
    props.handleClose();
  }

  useEffect(() => {
    getServiceRequestSummary();
  }, []);

  return (
    <div className="servicereq-confirm-ui">
      <div>

        {serviceRequest.stallName &&
          <>
            <div className='mb-2 text-center font-19 font-weight-bold pt-3 color-primary stallname'>
              <span>{serviceRequest.stallName}</span>
            </div>
            <div className='d-flex justify-content-between align-items-center px-4 pb-3'>
              <span className="font-13 font-weight-bold">
                <span><i className="fas fa-barcode pr-2 color-grey font-14"></i></span>
                #{serviceRequest.serviceRequestIdentifier}
              </span>
              <span className="font-13 font-weight-bold">
                <span><i className="fas fa-calendar-alt pr-2 color-grey font-12"></i></span>
                <FxDateDisplay value={serviceRequest.requestDate} />
              </span>
              <span className="font-13 font-weight-bold">
                <span><i className="fas fa-user pr-2 color-grey font-12"></i></span>
                {serviceRequest.requestedByName}
              </span>
              <span className="font-13 font-weight-bold">
                <span><i className="fas fa-user pr-2 color-grey font-12"></i></span>
                {serviceRequest.serviceRequestStatus}
              </span>
              <span>
                {serviceRequestDetails && serviceRequestDetails.length > 0 &&
                  // <div className="successfullymsg">
                  <span className="font-13 font-weight-bold">
                    <span><i className="fas fa-hand-holding-usd pr-2 color-grey font-14"></i></span>
                    {serviceRequest.serviceRequestType}
                  </span>
                  // </div>
                }
              </span>
            </div>
            {/* <>{serviceRequestDetails && serviceRequestDetails.length > 0 &&
                            <div className="successfullymsg">
                                <span className='pl-1'>{serviceRequest.serviceRequestType}</span>
                            </div>
                        }</> */}
          </>
        }
        <div className="productsummary mb-3">
          {
            serviceRequestDetails.length > 0 &&
            <span>Product Summary</span>
          }
        </div>

        <div>
          {
            serviceRequestDetails.length > 0 && serviceRequestDetails.map((itemObj: any, i) => (
              <div className="pt-2 row ml-1 align-items-center">
                <span className="col-3">
                  {!itemObj.imageUrl && <img src={dummyImage} className="itemImg"></img>}
                  {itemObj.imageUrl && <img src={itemObj.imageUrl} className="itemImg"></img>}
                </span>

                <span className="col-9 row">
                  <span className='pl-2 font-14'>
                    {itemObj.itemName}
                  </span>
                </span>
              </div>
            ))
          }
        </div>
      </div>
      {
        showSpecialEvent &&
        <div className="mt-2">
          <div className='row pl-5'>
            <span className='col-4 text-left label'>
              Community
            </span>
            <span className='col-8 text-left'>
              {specialEventItem.communityName}
            </span>
          </div>
          <div className='row pt-3 pl-5'>
            <span className='col-4 text-left label'>
              Service
            </span>
            <span className='col-8 text-left'>
              {specialEventItem.serviceName}
            </span>
          </div>
          <div className='row pt-3 pl-5'>
            <span className='col-4 text-left label'>
              Start Date Time
            </span>
            <span className='col-8 text-left'>
              <FxDateDisplay value={specialEventItem.startDate} />
              <span className='pl-2'>
                <i className="far fa-clock color-aaa px-1"></i>
                {specialEventItem.startTime}
              </span>
            </span>
          </div>
          <div className='row pt-3 pl-5'>
            <span className='col-4 text-left label'>
              End Date Time
            </span>
            <span className='col-8 text-left'>
              <FxDateDisplay value={specialEventItem.endDate} />
              <span className='pl-2'>
                <i className="far fa-clock color-aaa px-1"></i>
                {specialEventItem.endTime}
              </span>
            </span>
          </div>

          <div className='px-5 pt-3 row'>
            <span className='divider'></span>
          </div>

          <div className='pl-5 pt-3 row'>
            <div className='text-left col-4 label'>
              Questions
            </div>
            <div className='col-8 text-left'>
            {
              questions.length > 0 && questions.map((que: any, i) => (
                <div key={i}>
                  {
                    que.questionTypeId == 1 &&
                    <div className='text-left pb-3'>
                      <div className='question'>
                        {que.description}
                      </div>
                      <div className='pl-4 font-weight-bold font-15'>
                        {answerMap[que.identifier]}
                      </div>
                    </div>
                  }
                  {
                    que.questionTypeId == 2 &&
                    <div className='text-left pb-3'>
                      <div className='question'>
                        {que.description}
                      </div>
                      <div className='pl-4 font-weight-bold font-15'>
                        <FxDateDisplay value={answerMap[que.identifier]} />
                      </div>
                    </div>
                  }
                  {
                    que.questionTypeId == 3 &&
                    <div className='text-left pb-3'>
                      <div className='question'>
                        {que.description}
                      </div>
                      <div className='pl-4 font-weight-bold font-15'>
                        {answerMap[que.identifier]}
                      </div>
                    </div>
                  }
                </div>
              ))
            }
            </div>
          </div>
        </div>
      }
      {
        serviceRequest.serviceRequestStatusId == 1 &&
        <div className='px-4 py-2 drawer-footer text-right'>
          <Button variant="outlined" size='small' className='btn-style' onClick={confirmReject}>Reject</Button>
          <Button variant="outlined" size='small' className='btn-style ml-3' onClick={confirmApprove}>Approve</Button>
        </div>
      }

      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} action={"verify"} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
      <Dialog
        open={splEvtConfirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleSplEvtDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ServiceRequestConfirmDialog message={message} handleDialogClose={handleSplEvtDialogClose} onConfirmCallback={onSplEvtConfirmCallback} />
      </Dialog>
    </div>

  );
}
export default ServiceRequestDetail;