import React, { useEffect, useState } from 'react';
import './LiveStreamCardMobile.scss';
import { FxDateTimeDisplay } from '../fx-core';
import { Button } from '@material-ui/core';

const LiveStreamCardMobile = (props) => {
  const defaultItem = {
    liveStreamName: "",
    description: "",
    liveStreamDate: "",
    duration: null,
    startTime: "",
    endTime: "",
    stallName: "",
    stallItemName: "",
    imageUrl: "",
    stallItemId: 0,
    stallItemImage: "",
    liveStreamStatusId: 0
  };
  const [item, setItem] = useState(defaultItem);

  useEffect(() => {
    if (props && props.liveStreamObj) {
      setItem(props.liveStreamObj);
    }
  }, [props]);

  return (
    <div className='lvcardmob-ui'>
      <div className='d-flex card-back-color mx-1 p-2'>
        <div>
          {!item.imageUrl && <i className="fas fa-user-circle dummy-image"></i>}
          {item.imageUrl && <img src={item.imageUrl} alt="Item" width="60" height="60"></img>}
        </div>
        <div className='pl-3'>
          <div>
            <span className='font-weight-bold font-15'> {item.stallName}</span>
          </div>
          <div className='d-flex font-13'>
            <div>
              <span><i className="fas fa-stopwatch color-grey"></i></span>
              <span> {item.duration} mins</span>
            </div>
            <div className='pl-3'>
              <span><i className="fas fa-clock color-grey"></i></span>
              <span> <FxDateTimeDisplay value={item.startTime} displayType="time" /></span>
              <span className='px-1'>-</span>
              <span><FxDateTimeDisplay value={item.endTime} displayType="time" /></span>
            </div>
          </div>
          <div className='font-12'>
            <span><i className="fas fa-file-alt color-grey pr-1"></i></span>
            <span>  {item.description}</span>
          </div>
          <div>
            <span className='font-12 font-weight-bold'>{item.stallItemName}</span>
          </div>
          {
            props.userId > 0 &&
            <div className='py-2'>
              <Button variant='contained' size='small' className='button-style' onClick={props.openOrder}>Order Now</Button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
export default LiveStreamCardMobile;