import React, { useEffect, useState } from 'react';
import './SaleItemDetailForm.scss';
import { FxInput, http, AlertService, general } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import dummyImage from "../../../assets/images/dummy.png";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';

const SaleItemDetailForm = (props) => {
  let defaultItem = {
    price: null,
    quantity: null,
    groupTypeId: 0,
    id: props.saleItemId ? props.saleItemId : 0,
    saleId: props.saleId,
    imageUrl: "",
    itemName: "",
    description: "",
    imagePath: "",
    saleItemGroupId: props.saleItemGroupId
  };
  const [item, setItem] = useState(defaultItem);
  const [error, setError] = useState(null);
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});

  const handleInputChange = (item) => {
    if (item.name == "price") {
      item.value = parseFloat(item.value);
    }
    if (item.name == "quantity") {
      item.value = parseInt(item.value);
    }
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getSaleItemById = () => {
    if (item.id > 0) {
      let inputData = {
        id: item.id
      }
      let apiOptions: any = {
        url: `saleitem/${inputData.id}`
      };
      http.get(apiOptions).then(res => {
        getSaleItemByIdCallback(res.data);
      })
    }
  };

  const getSaleItemByIdCallback = (result) => {
    result.imageUrl = general.getImageUrl(result.imagePath);
    setItem(result);
  }

  const saveSaleItem = () => {
    if (!item.itemName || !item.price || !item.quantity || !item.description) {
      setError("This field is required");
      return;
    }
    let apiOptions: any = {
      url: `saleitem/save`,
      data: item
    };
    http.post(apiOptions).then(res => {
      AlertService.showSuccessMsg();
      props.handleSaleItemDetailClose();
    })
  };

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      groupId: item.saleItemGroupId,
      id: item.id,
      cameraConfig: { url: `saleitem/savecameraimage` },
      fileUploadConfig: { url: `saleitem/savefileimage` }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (item.id > 0) {
      getSaleItemById();
    }
    else {
      let filePath = res.filePath;
      let imagePath = general.getImageUrl(filePath);
      let imgObj = {
        imagePath: filePath,
        imageUrl: imagePath
      }
      handleItemChange(imgObj);
    }
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const handleClose = () => {
    props.handleSaleItemDetailClose();
  }

  useEffect(() => {
    getSaleItemById();
  }, []);

  return (
    <div>
      <div className='row title-font py-1 mx-auto'>
        <span className="col-11">
          Sale Item Detail Form
        </span>
        <span className="col-1">
          <span className='text-center'>
            <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
          </span>
        </span>
      </div>

      <div className='pt-3'>

        <div className='row p-3'>
          <div className="col-2 text-center">
            <div>
              <a className="pointer">
                {!item.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                {item.imageUrl && <img src={item.imageUrl} alt="prod" width={100} height={100}></img>}
              </a>
              <div className="text-center camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 p-3'>
          <FxInput name="itemName" variant="outlined" fullWidth size="small" label="Item Name"
            value={item.itemName} onValueChange={handleInputChange} />
          {
            error && !item.itemName &&
            <div className="error">{error}</div>
          }
        </div>

        <div className='col-12 p-3'>
          <FxInput type="number" name="price" variant="outlined" fullWidth size="small" label="Price"
            value={item.price || ""} onValueChange={handleInputChange} />
          {
            error && !item.price &&
            <div className="error">{error}</div>
          }
        </div>

        <div className='col-12 p-3'>
          <FxInput type="number" name="quantity" variant="outlined" fullWidth size="small" label="Quantity"
            value={item.quantity || ""} onValueChange={handleInputChange} />
          {
            error && !item.quantity &&
            <div className="error">{error}</div>
          }
        </div>

        <div className='col-12 p-3'>
          <FxInput name="description" variant="outlined" fullWidth size="small" label="Description"
            value={item.description} onValueChange={handleInputChange} />
          {
            error && !item.description &&
            <div className="error">{error}</div>
          }
        </div>

        <div className="text-right p-3">
          <span>
            <Button variant="contained" size="small" color="primary" onClick={saveSaleItem}>Save</Button>
          </span>
          <span className="pl-3">
            <Button variant="contained" size="small" onClick={handleClose}>Cancel</Button>
          </span>
        </div>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
    </div>
  );
}
export default SaleItemDetailForm;