import React, { useEffect, useState } from 'react';
import './CustomerProfile.scss';
import { AlertService, FxInput, http, general } from '../../fx-core';
import { Button, Link, Dialog } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import { EmailVerification } from '../../EmailVerification/EmailVerification';
import { MobileVerification } from '../../MobileVerification/MobileVerification';
import { AddressForm } from '../AddressForm/AddressForm';

const CustomerProfile = (props: any) => {

  let userObj = {
    id: 0,
    firstName: null,
    lastName: null,
    mobile: null,
    email: null,
    imageUrl: null,
    newPassword: null,
    confirmPassword: null,
    isMobileVerified: false,
    isEmailVerified: false,
    enableMobileVerification: 0,
    enableEmailVerification: 0
  }

  const [userInfo, setUserInfo] = useState(userObj);
  const [isNameChange, setIsNameChange] = useState(false);
  const [isEmailChange, setIsEmailChange] = useState(false);
  const [isMobileChange, setIsMobileChange] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [error, setError] = useState(null);
  const [minError, setMinError] = useState(null);
  const [throwError, setThrowError] = useState(null);
  const [uploadConfig, setUploadConfig] = useState({});
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [mobileVerifyOpen, setMobileVerifyOpen] = useState(false);
  const [emailVerifyOpen, setEmailVerifyOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setUserInfo(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getUserProfile = () => {
    let apiOptions: any = {
      url: `user/profile/${props.userId}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      result.imageUrl = general.getImageUrl(result.profileImagePath);
      setUserInfo(result);
    })
  };

  const changeName = () => {
    setIsNameChange(true);
  }

  const updateName = () => {
    let inputData = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName
    }
    let apiOptions: any = {
      url: `user/name`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      updateCallBack(res);
    })
  };

  const changeEmail = () => {
    setIsEmailChange(true);
  }

  const updateEmail = () => {
    let inputData = {
      email: userInfo.email
    }
    let apiOptions: any = {
      url: `user/updateemail`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      updateCallBack(res);
    })
  };

  const changeMobile = () => {
    setIsMobileChange(true);
  }

  const updateMobile = () => {
    let inputData = {
      mobile: userInfo.mobile
    }
    let apiOptions: any = {
      url: `user/updatemobile`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      updateCallBack(res);
    })
  };

  const changePassword = () => {
    setIsPasswordChange(true);
  }

  const updatePassword = () => {
    setError(null);
    setMinError(null);
    setThrowError(null);
    if (!userInfo.newPassword || !userInfo.confirmPassword) {
      setError("This field is required");
      return;
    }

    let inputData = {
      newPassword: userInfo.newPassword,
      confirmPassword: userInfo.confirmPassword
    }
    let apiOptions: any = {
      url: `users/my/password`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      AlertService.showSuccessMsg();
      updateCallBack(res);
    }).catch(error => {
      if (error && error.response) {
        setThrowError(error.response.data.message);
      }
    });
  };

  const updateCallBack = (res: any) => {
    setIsNameChange(false);
    setIsEmailChange(false);
    setIsMobileChange(false);
    setIsPasswordChange(false);
    getUserProfile();
    refreshAccountUserProfile();
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: props.userId,
      cameraConfig: { url: `user/savecameraimage` },
      fileUploadConfig: { url: `user/savefileimage` }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    handlePhotoCaptureClose();
    getUserProfile();
    refreshAccountUserProfile();
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const refreshAccountUserProfile = () => {
    props.refreshAccountUserProfile();
  }

  const openMobileVerify = () => {
    setMobileVerifyOpen(true);
  };

  const closeMobileVerify = () => {
    setMobileVerifyOpen(false);
  };

  const onSuccessMobileVerify = (res) => {
    if (res == "success") {
      handleItemChange({ isMobileVerified: true });
    }
    closeMobileVerify();
  };

  const openEmailVerify = () => {
    setEmailVerifyOpen(true);
  };

  const closeEmailVerify = () => {
    setEmailVerifyOpen(false);
  };

  const onSuccessEmailVerify = (res) => {
    if (res == "success") {
      handleItemChange({ isEmailVerified: true });
    }
    closeEmailVerify();
  };

  const openAddressForm = () => {
    setOpen(true);
  };

  const closeAddressForm = () => {
    setOpen(false);
  };

  const onSaveCallback = () => {
    closeAddressForm();
    getAddressList();
  }

  const getAddressList = () => {
    let inputData = {
      id: props.userId
    };
    let apiOptions: any = {
      url: `address/query/${inputData.id}`,
      data: null
    };
    http.post(apiOptions).then(res => {
      getAddressListCallBack(res);
    })
  };

  const getAddressListCallBack = (res) => {
    const result = res.data;
    setAddressList(result);
  };

  useEffect(() => {
    getUserProfile();
    getAddressList();
  }, []);



  const body = (
    <div className="customer-profile-ui">

      {/* Desktop View */}
      <div className='deskview px-4'>
        <div className='pt-4 pb-1'>
          <h5><span>General Information</span></h5>
        </div>
        <div className='row pl-3'>
          <div className='col-7 display-col'>
            <div>
              <a className="pointer">
                {!userInfo.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                {userInfo.imageUrl && <img src={userInfo.imageUrl} alt="prod" width={100} height={100}></img>}
              </a>
              <div className="mt-2 camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </div>
            <div className='row display-row py-2 mr-3 mt-3'>
              <div className='col-3'>Name</div>
              <div className='col-8'>{userInfo.firstName} {userInfo.lastName}</div>
              <div className='col-1'><i className="fas fa-pen pointer" onClick={changeName}></i></div>
            </div>
            <div className='row display-row py-2 mr-3 mt-3'>
              <div className='col-3'>Mobile</div>
              <div className='col-5'>{userInfo.mobile}</div>
              <div className="col-3 text-right">
                {/* {userInfo.enableMobileVerification == 1 &&
                  <span>
                    {!userInfo.isMobileVerified &&
                      <Link className="pointer font-12" onClick={openMobileVerify}>Verify</Link>
                    }
                    {
                      userInfo.isMobileVerified &&
                      <span className="verified">Verified</span>
                    }
                  </span>
                } */}
              </div>
              <div className='col-1'><i className="fas fa-pen pointer" onClick={changeMobile}></i></div>
            </div>
            <div className='row display-row py-2 mr-3 mt-3 mb-3'>
              <div className='col-3'>Email</div>
              <div className='col-5'>{userInfo.email}</div>
              <div className="col-3 text-right">
                {userInfo.enableEmailVerification == 1 &&
                  <span>
                    {!userInfo.isEmailVerified &&
                      <Link className="pointer font-12" onClick={openEmailVerify}>Verify</Link>
                    }
                    {
                      userInfo.isEmailVerified &&
                      <span className="verified">Verified</span>
                    }
                  </span>
                }
              </div>
              <div className='col-1'><i className="fas fa-pen pointer" onClick={changeEmail}></i></div>
            </div>

            {addressList.length == 0 &&
              <div className='row display-row py-2 mr-3 mt-3'>
                <div className='col-3'>Address</div>
                <div className='col-4'></div>
                <div className="col-5 text-right">
                  <span>
                    <Link className="pointer font-12" onClick={openAddressForm}>Update Address</Link>
                  </span>
                </div>
              </div>
            }
          </div>

          <div className='col-5'>
            {isNameChange &&
              <span>
                <div>
                  <FxInput name="firstName" variant="outlined" label="First Name" value={userInfo.firstName}
                    onValueChange={handleInputChange} style={{ width: 280 }} />
                </div>
                <div className="pt-3">
                  <FxInput name="lastName" variant="outlined" label="Last Name" value={userInfo.lastName}
                    onValueChange={handleInputChange} style={{ width: 280 }} />
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updateName}>
                    Update
                  </Button>
                </div>
              </span>
            }
            {isMobileChange &&
              <span>
                <div>
                  <FxInput name="mobile" variant="outlined" label="Mobile" value={userInfo.mobile}
                    onValueChange={handleInputChange} style={{ width: 280 }} />
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updateMobile}>
                    Update
                  </Button>
                </div>
              </span>
            }
            {isEmailChange &&
              <span>
                <div>
                  <FxInput name="email" variant="outlined" label="Email" value={userInfo.email}
                    onValueChange={handleInputChange} style={{ width: 280 }} />
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updateEmail}>
                    Update
                  </Button>
                </div>
              </span>
            }
          </div>
        </div>

        <div className='pt-4 pb-1'>
          <h5>
            <span>Security Settings</span>
          </h5>
        </div>
        <div className='row pl-3'>
          <div className='col-7 display-col'>
            <div className='row display-row py-2 mr-3 my-3'>
              <div className='col-3'>Password</div>
              <div className='col-8'>********</div>
              <div className='col-1'><i className="fas fa-pen pointer" onClick={changePassword}></i></div>
            </div>
          </div>

          <div className='col-5'>
            {isPasswordChange &&
              <span>
                <div>
                  <FxInput name="newPassword" variant="outlined" label="New Password" value={userInfo.newPassword}
                    type="password" onValueChange={handleInputChange} style={{ width: 280 }} />
                  {error && !userInfo.newPassword &&
                    <div className="error">{error}</div>
                  }
                  {minError &&
                    <div className="error"> {minError} </div>}
                </div>
                <div className="pt-3">
                  <FxInput name="confirmPassword" variant="outlined" label="Confirm Password" value={userInfo.confirmPassword}
                    type="password" onValueChange={handleInputChange} style={{ width: 280 }} />
                  {error && !userInfo.confirmPassword &&
                    <div className="error">{error}</div>
                  }
                  {throwError &&
                    <div className="error"> {throwError} </div>}
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updatePassword}>
                    Update
                  </Button>
                </div>
              </span>
            }
          </div>
        </div>
      </div>

      {/* Tablet View */}
      <div className='tabview px-4'>
        <div className='pt-4 pb-1'>
          <h5><span>General Information</span></h5>
        </div>
        <div className='row pl-3'>
          <div className='col-7 display-col'>
            <div>
              <a className="pointer">
                {!userInfo.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                {userInfo.imageUrl && <img src={userInfo.imageUrl} alt="prod" width={100} height={100}></img>}
              </a>
              <div className="mt-2 camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </div>
            <div className='row display-row py-2 mr-3 mt-3'>
              <div className='col-3 pl-1'>Name</div>
              <div className='col-8'>{userInfo.firstName} {userInfo.lastName}</div>
              <div className='col-1 px-0 text-right'><i className="fas fa-pen pointer" onClick={changeName}></i></div>
            </div>
            <div className='row display-row py-2 mr-3 mt-3'>
              <div className='col-3 pl-1'>Mobile</div>
              <div className='col-5'>{userInfo.mobile}</div>
              <div className="col-3 text-right">
                {/* {userInfo.enableMobileVerification == 1 &&
                  <span>
                    {!userInfo.isMobileVerified &&
                      <Link className="pointer font-12" onClick={openMobileVerify}>Verify</Link>
                    }
                    {
                      userInfo.isMobileVerified &&
                      <span className="verified">Verified</span>
                    }
                  </span>
                } */}
              </div>
              <div className='col-1 px-0 text-right'><i className="fas fa-pen pointer" onClick={changeMobile}></i></div>
            </div>
            <div className='row display-row py-2 mr-3 mt-3 mb-3'>
              <div className='col-3 pl-1'>Email</div>
              <div className='col-5'>{userInfo.email}</div>
              <div className="col-3 text-right">
                {userInfo.enableEmailVerification == 1 &&
                  <span>
                    {!userInfo.isEmailVerified &&
                      <Link className="pointer font-12" onClick={openEmailVerify}>Verify</Link>
                    }
                    {
                      userInfo.isEmailVerified &&
                      <span className="verified">Verified</span>
                    }
                  </span>
                }
              </div>
              <div className='col-1 px-0 text-right'><i className="fas fa-pen pointer" onClick={changeEmail}></i></div>
            </div>

            {addressList.length == 0 &&
              <div className='row display-row py-2 mr-3 mt-3 mb-3'>
                <div className='col-3 pl-1'>Address</div>
                <div className='col-4'></div>
                <div className="col-5 text-right">
                  <span>
                    <Link className="pointer font-12" onClick={openAddressForm}>Update Address</Link>
                  </span>
                </div>
              </div>
            }

          </div>

          <div className='col-5'>
            {isNameChange &&
              <span>
                <div>
                  <FxInput name="firstName" variant="outlined" label="First Name" value={userInfo.firstName}
                    onValueChange={handleInputChange} style={{ width: 250 }} />
                </div>
                <div className="pt-3">
                  <FxInput name="lastName" variant="outlined" label="Last Name" value={userInfo.lastName}
                    onValueChange={handleInputChange} style={{ width: 250 }} />
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updateName}>
                    Update
                  </Button>
                </div>
              </span>
            }
            {isMobileChange &&
              <span>
                <div>
                  <FxInput name="mobile" variant="outlined" label="Mobile" value={userInfo.mobile}
                    onValueChange={handleInputChange} style={{ width: 250 }} />
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updateMobile}>
                    Update
                  </Button>
                </div>
              </span>
            }
            {isEmailChange &&
              <span>
                <div>
                  <FxInput name="email" variant="outlined" label="Email" value={userInfo.email}
                    onValueChange={handleInputChange} style={{ width: 250 }} />
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updateEmail}>
                    Update
                  </Button>
                </div>
              </span>
            }
          </div>
        </div>

        <div className='pt-4 pb-1'>
          <h5>
            <span>Security Settings</span>
          </h5>
        </div>
        <div className='row pl-3'>
          <div className='col-7 display-col'>
            <div className='row display-row py-2 mr-3 my-3'>
              <div className='col-3 pl-1'>Password</div>
              <div className='col-8'>********</div>
              <div className='col-1 px-0 text-right'><i className="fas fa-pen pointer" onClick={changePassword}></i></div>
            </div>
          </div>

          <div className='col-5'>
            {isPasswordChange &&
              <span>
                <div>
                  <FxInput name="newPassword" variant="outlined" label="New Password" value={userInfo.newPassword}
                    type="password" onValueChange={handleInputChange} style={{ width: 250 }} />
                  {error && !userInfo.newPassword &&
                    <div className="error">{error}</div>
                  }
                  {minError &&
                    <div className="error"> {minError} </div>}
                </div>
                <div className="pt-3">
                  <FxInput name="confirmPassword" variant="outlined" label="Confirm Password" value={userInfo.confirmPassword}
                    type="password" onValueChange={handleInputChange} style={{ width: 250 }} />
                  {error && !userInfo.confirmPassword &&
                    <div className="error">{error}</div>
                  }
                  {throwError &&
                    <div className="error"> {throwError} </div>}
                </div>
                <div className="text-center my-3">
                  <Button variant="contained" size="small" color="primary" onClick={updatePassword}>
                    Update
                  </Button>
                </div>
              </span>
            }
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className='mobileview'>
        <div className='pt-3'>
          <h6><span>General Information</span></h6>
        </div>
        <div className='display-col px-3'>
          <div>
            <a className="pointer">
              {!userInfo.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
              {userInfo.imageUrl && <img src={userInfo.imageUrl} alt="prod" width={100} height={100}></img>}
            </a>
            <div className="mt-2 camera-align" onClick={openPhotoCapture}>
              <CameraAltIcon className='pointer' />
            </div>
          </div>
          <div className='row display-row pb-2 mt-3 font-13'>
            <div className='col-3 pr-0 pl-1'>Name</div>
            <div className='col-8 pl-0'>
              {!isNameChange &&
                <span>{userInfo.firstName} {userInfo.lastName}</span>
              }
              {isNameChange &&
                <span>
                  <div>
                    <FxInput name="firstName" variant="outlined" label="First Name" value={userInfo.firstName}
                      onValueChange={handleInputChange} style={{ width: 180 }} />
                  </div>
                  <div className="pt-3">
                    <FxInput name="lastName" variant="outlined" label="Last Name" value={userInfo.lastName}
                      onValueChange={handleInputChange} style={{ width: 180 }} />
                  </div>
                </span>
              }
            </div>
            <div className='col-1 px-0'>
              {!isNameChange &&
                <i className="fas fa-pen pointer" onClick={changeName}></i>
              }
              {isNameChange &&
                <i className="fas fa-check pointer color-primary font-16" onClick={updateName}></i>
              }
            </div>
          </div>

          <div className='row display-row py-2 mt-3 font-13'>
            <div className='col-3 pr-0 pl-1'>Mobile</div>
            <div className='col-5 pl-0'>
              {!isMobileChange &&
                <span>{userInfo.mobile}</span>
              }
              {isMobileChange &&
                <span>
                  <div>
                    <FxInput name="mobile" variant="outlined" label="Mobile" value={userInfo.mobile}
                      onValueChange={handleInputChange} style={{ width: 180 }} />
                  </div>
                </span>
              }
            </div>
            <div className="col-3 text-right">
              {/* {userInfo.enableMobileVerification == 1 &&
                <span>
                  {!userInfo.isMobileVerified &&
                    <Link className="pointer font-12" onClick={openMobileVerify}>Verify</Link>
                  }
                  {
                    userInfo.isMobileVerified &&
                    <span className="verified">Verified</span>
                  }
                </span>
              } */}
            </div>
            <div className='col-1 px-0'>
              {!isMobileChange &&
                <i className="fas fa-pen pointer" onClick={changeMobile}></i>
              }
              {isMobileChange &&
                <i className="fas fa-check pointer color-primary font-16" onClick={updateMobile}></i>
              }
            </div>
          </div>

          <div className='row display-row py-2 mt-3 mb-3 font-13'>
            <div className='col-3 pr-0 pl-1'>Email</div>
            <div className='col-5 pl-0'>
              {!isEmailChange &&
                <span>{userInfo.email}</span>
              }
              {isEmailChange &&
                <span>
                  <div>
                    <FxInput name="email" variant="outlined" label="Email" value={userInfo.email}
                      onValueChange={handleInputChange} style={{ width: 180 }} />
                  </div>
                </span>
              }
            </div>
            <div className="col-3 text-right">
              {userInfo.enableEmailVerification == 1 &&
                <span>
                  {!userInfo.isEmailVerified &&
                    <Link className="pointer font-12" onClick={openEmailVerify}>Verify</Link>
                  }
                  {
                    userInfo.isEmailVerified &&
                    <span className="verified">Verified</span>
                  }
                </span>
              }
            </div>
            <div className='col-1 px-0'>
              {!isEmailChange &&
                <i className="fas fa-pen pointer" onClick={changeEmail}></i>
              }
              {isEmailChange &&
                <i className="fas fa-check pointer color-primary font-16" onClick={updateEmail}></i>
              }
            </div>
          </div>

          {addressList.length == 0 &&
            <div className='row display-row py-2 mt-3 mb-3 font-13'>
              <div className='col-3 pr-0 pl-1'>Address</div>
              <div className='col-4 pl-0'>
              </div>
              <div className="col-5 text-right">
                <span>
                  <Link className="pointer font-12" onClick={openAddressForm}>Update Address</Link>
                </span>
              </div>
            </div>
          }

        </div>

        <div className='pt-4'>
          <h6>
            <span>Security Settings</span>
          </h6>
        </div>
        <div className='display-col px-3'>
          <div className='row display-row pb-2 my-3 font-13'>
            <div className='col-3 pr-0 pl-1'>Password</div>
            <div className='col-8 pl-0'>
              {!isPasswordChange &&
                <span>******</span>
              }
              {isPasswordChange &&
                <span>
                  <div>
                    <FxInput name="newPassword" variant="outlined" label="New Password" value={userInfo.newPassword}
                      type="password" onValueChange={handleInputChange} style={{ width: 180 }} />
                    {error && !userInfo.newPassword &&
                      <div className="error">{error}</div>
                    }
                    {minError &&
                      <div className="error"> {minError} </div>}
                  </div>
                  <div className="pt-3">
                    <FxInput name="confirmPassword" variant="outlined" label="Confirm Password" value={userInfo.confirmPassword}
                      type="password" onValueChange={handleInputChange} style={{ width: 180 }} />
                    {error && !userInfo.confirmPassword &&
                      <div className="error">{error}</div>
                    }
                    {throwError &&
                      <div className="error"> {throwError} </div>}
                  </div>
                </span>
              }
            </div>
            <div className='col-1 px-0'>
              {!isPasswordChange &&
                <i className="fas fa-pen pointer" onClick={changePassword}></i>
              }
              {isPasswordChange &&
                <i className="fas fa-check pointer color-primary font-16" onClick={updatePassword}></i>
              }
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
      <Dialog
        open={mobileVerifyOpen}
        onClose={closeMobileVerify}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <MobileVerification handleVerification={onSuccessMobileVerify} closeDialog={closeMobileVerify} />
      </Dialog>
      <Dialog
        open={emailVerifyOpen}
        onClose={closeEmailVerify}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <EmailVerification handleVerification={onSuccessEmailVerify} closeDialog={closeEmailVerify} />
      </Dialog>

      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={closeAddressForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <AddressForm userId={props.userId} addressId={0} closeAddressForm={closeAddressForm} onSaveCallback={onSaveCallback} />
      </Dialog>

    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default CustomerProfile;