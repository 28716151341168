import React, { useState, useEffect } from "react";
import './ProviderOrderContainer.scss';
import { http } from "../../fx-core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProviderItemOrders from "../ProviderItemOrders/ProviderItemOrders";
import ProviderSaleOrders from "../ProviderSaleOrders/ProviderSaleOrders";
import ProviderBookingOrders from "../ProviderBookingOrders/ProviderBookingOrders";

const ProviderOrderContainer = (props) => {
    const [stallId, setStallId] = useState(props.match.params.stallId ? parseInt(props.match.params.stallId) : 0);
    const [isFromOrders, setIsFromOrders] = useState(props.match.params.from ? true : false);
    const [type, setType] = useState(props.match.params.type ? props.match.params.type : "itemsale");

    const handleChange = (event, newValue) => {
        setType(newValue);
    };

    useEffect(() => {
    }, []);

    return (
        <div className="provider-order-container">
            {!isFromOrders &&
                <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
                    <span className="col-11 pt-3 py-1 pl-4">
                        <span>Orders</span>
                    </span>
                </div>
            }
            <div className='mt-5'>
                {!isFromOrders &&
                    <Tabs value={type} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                        <Tab label="Item Sales" value={"itemsale"} />
                        <Tab label="Listings" value={"listing"} />
                        <Tab label="Bookings" value={"booking"} />
                    </Tabs>
                }
                {
                    type === 'itemsale' &&
                    <ProviderItemOrders stallId={stallId} isFromOrders={isFromOrders} />
                }
                {
                    type === 'listing' &&
                    <ProviderSaleOrders stallId={stallId} isFromOrders={isFromOrders} />
                }
                {
                    type === 'booking' &&
                    <ProviderBookingOrders stallId={stallId} isFromOrders={isFromOrders} />
                }
            </div>
        </div>
    );
};

export default ProviderOrderContainer;