import React, { useEffect, useState } from 'react';
import './StallItemCustomize.scss';
import { useNavigationService, FxCurrency, http, general, AlertService, FxInput } from '../../fx-core';
import { Button, Badge, Dialog, Checkbox, Link, Fab, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import StallItemGroupForm from '../StallItemGroupForm/StallItemGroupForm';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import dummyImage from "../../../assets/images/dummy.png";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StallItemCustomize = (props) => {
  const { navigate } = useNavigationService();
  let defaultDetail = {
    imageUrl: "",
    id: 0,
    isDefault: false,
    price: 0,
    description: "",
    stallItemCustomizeHeaderId: 0,
    stallItemId: 0,
    isDetailForm: false,
    isActive: true
  };
  const [detail, setDetail] = useState(defaultDetail);
  const [stallItemId, setStallItemId] = useState(props.match.params.id ? parseInt(props.match.params.id) : 0);
  const [groupDetails, setGroupDetails] = useState([]);
  const [groupFormOpen, setGroupFormOpen] = useState(false);
  const [stallItemCustomizeHeaderId, setStallItemCustomizeHeaderId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [detailId, setDetailId] = useState(0);
  const [error, setError] = useState(null);
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [selectedDetailId, setSelectedDetailId] = useState(0);

  //Group section code starts
  const getGroupDetails = () => {
    let inputData = {
      id: stallItemId
    };
    let apiOptions: any = {
      url: `stallitemcustomize/stallitem/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        for (let detail of item.details) {
          detail.imageUrl = general.getImageUrl(detail.imagePath);
        }
      }
      setGroupDetails(result);
    })
  };

  const openGroupForm = (id: any) => {
    setStallItemCustomizeHeaderId(id);
    setGroupFormOpen(true);
  };

  const handleGroupFormClose = () => {
    setGroupFormOpen(false);
    getGroupDetails();
  };

  const confirmDelete = (item) => {
    setStallItemCustomizeHeaderId(item.id);
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + item.groupName + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if (selectedDetailId > 0) {
      deleteGroupDetail();
    }
    else {
      deleteGroupHeader();
    }
  }

  const deleteGroupHeader = () => {
    let inputData = {
      id: stallItemCustomizeHeaderId,
    };
    let apiOptions: any = {
      url: `stallitemcustomize/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getGroupDetails();
    })
  };
  //Group Section Code Ends

  //Detail section code starts

  const handleInputChange = (item) => {
    handleDetailChange({ [item.name]: item.value });
  }

  const handleCheckbox = (event) => {
    handleDetailChange({ [event.target.name]: event.target.checked });
  }

  const handleDetailChange = (data) => {
    setDetail(prevState => {
      return { ...prevState, ...data }
    });
  };

  const addGroupDetail = (item) => {
    setDetail(defaultDetail);
    let itemObj = {
      stallItemCustomizeHeaderId: item.id,
      stallItemId: item.stallItemId,
      isActive: true,
      isDetailForm: true
    }
    handleDetailChange(itemObj);
  }

  const editGroupDetail = (item) => {
    handleDetailChange({ stallItemCustomizeHeaderId: item.stallItemCustomizeHeaderId });
    setDetailId(item.id);
    getGroupDetailById(item.id);
  }

  const getGroupDetailById = (detailValId) => {
    let inputData = {
      id: detailValId
    }
    let apiOptions: any = {
      url: `stallitemcustomizedetail/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.imageUrl = general.getImageUrl(result.imagePath);
      setDetail(result);
    })
  }

  const saveGroupDetail = () => {
    if (!detail.description) {
      setError("Description is required");
      return;
    }
    let apiOptions: any = {
      url: `stallitemcustomizedetail/save`,
      data: detail
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      saveGroupDetailCallback();
    })
  }

  const saveGroupDetailCallback = () => {
    if (detailId > 0) {
      setDetailId(0);
    }
    else {
      handleDetailChange({ isDetailForm: false });
    }
    setDetail(defaultDetail);
    setError(null);
    getGroupDetails();
  }

  const confirmDetailDelete = (item) => {
    setSelectedDetailId(item.id);
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + item.description + "?");
  }

  const deleteGroupDetail = () => {
    let inputData = {
      id: selectedDetailId,
    };
    let apiOptions: any = {
      url: `stallitemcustomizedetail/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getGroupDetails();
    })
  };

  const cancelEditDetail = () => {
    setDetailId(0);
  }

  const cancelDetail = () => {
    handleDetailChange({ isDetailForm: false });
    handleDetailChange({ "stallItemCustomizeHeaderId": 0 });
    handleDetailChange({ "stallItemId": 0 });
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      headerId: detail.stallItemCustomizeHeaderId,
      id: detailId,
      cameraConfig: { url: `stallitemcustomizedetail/savecameraimage` },
      fileUploadConfig: { url: `stallitemcustomizedetail/savefileimage` }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (detailId > 0) {
      getGroupDetailById(detailId);
    }
    else {
      let filePath = res.filePath;
      let imagePath = general.getImageUrl(filePath);
      handleDetailChange({ "imagePath": filePath });
      handleDetailChange({ "imageUrl": imagePath });
    }
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }
  //Detail section code ends

  const navToStallItemBatchList = () => {
    let navData = {
      targetRoute: '/provider/manageitems',
      targetTitle: "Manage Items",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    getGroupDetails();
  }, []);

  return (
    <div className='stallitemcustomize-ui pt-5'>
      <div className="title-font custom-head pt-4 mt-3 pl-4">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToStallItemBatchList}>
            <b>Manage Items</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Stall Item Customize Form</Typography>
        </Breadcrumbs>
      </div>

      <div className='mx-3 pl-2 pt-2 my-3 text-center'>
        <div className='row col-11 justify-content-between pb-2 pr-0'>
          <span></span>
          <span className="float-right pointer">
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={() => openGroupForm(0)}
            >
              <i className="fas fa-plus pr-2"></i>
              Add Group
            </Fab>
          </span>
        </div>

        <div className='row justify-content-center'>
          <div className='col-10 pb-5'>
            {groupDetails.map((row: any, i) => (
              <div className='pt-3'>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {row.header.groupName}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='col-12 pt-2'>
                      <div className='row col-12'>
                        <span className='col-6 text-left'>
                          <Button variant='contained' color='primary' size='small' className='button-style mr-2' onClick={() => openGroupForm(row.header.id)}>Edit Group</Button>
                          <Button variant='contained' size='small' className='button-style mr-2' onClick={() => confirmDelete(row.header)}>Delete</Button>
                        </span>

                        <span className='col-6 text-right pr-4 pl-0'>
                          <Button variant='contained' color='primary' size='small' className='button-style' onClick={() => addGroupDetail(row.header)}>Add Item</Button>
                        </span>
                      </div>

                      <div className='pt-3 row col-12'>
                        {row.details.map((detailVal, i) => (
                          <div className='row col-12 items-border py-3'>
                            {detailVal.id != detailId &&
                              <div className='row col-12 align-items-center'>
                                <span className='col-1'>
                                  {!detailVal.imageUrl && <img src={dummyImage} alt="Stall" width="60" height="60"></img>}
                                  {detailVal.imageUrl && <img src={detailVal.imageUrl} alt="Stall" width="60" height="60"></img>}
                                </span>
                                <span className='col-2 text-left'>
                                  {detailVal.description}
                                </span>
                                <span className='col-1 font-14 text-right'>
                                  {detailVal.price > 0 &&
                                    <FxCurrency value={detailVal.price} />
                                  }
                                </span>
                                <span className='col-2 text-center'>
                                  {detailVal.isActive &&
                                    <Badge badgeContent="Active" color="primary">
                                    </Badge>
                                  }
                                  {!detailVal.isActive &&
                                    <Badge badgeContent="Inactive" color="primary">
                                    </Badge>
                                  }
                                </span>
                                <span className='col-2 text-center'>
                                  {detailVal.isDefault &&
                                    <Badge badgeContent="Default" color="primary">
                                    </Badge>
                                  }
                                </span>
                                <span className='col-1 row justify-content-between'>
                                  <span onClick={() => editGroupDetail(detailVal)} className="text-center pointer">
                                    <div><i className="fas fa-edit"></i></div>
                                    <div className='font-12'>Edit</div>
                                  </span>
                                  <span onClick={() => confirmDetailDelete(detailVal)} className="text-center pointer">
                                    <div><i className="fas fa-trash-alt"></i></div>
                                    <div className='font-12'>Delete</div>
                                  </span>
                                </span>
                              </div>
                            }
                            {detailVal.id == detailId &&
                              // Edit Detail form section code starts
                              <div className='row col-12 align-items-center'>
                                <span className="col-1 text-center">
                                  <a className="pointer">
                                    {!detail.imageUrl && <img src={dummyImage} alt="dummy" width={60} height={60}></img>}
                                    {detail.imageUrl && <img src={detail.imageUrl} alt="prod" width={60} height={60}></img>}
                                  </a>
                                  <div className="text-center camera-align pointer" onClick={openPhotoCapture}>
                                    <CameraAltIcon fontSize='small' />
                                  </div>
                                </span>

                                <span className="col-2 px-0">
                                  <FxInput name="description" variant="outlined" fullWidth size="small" label="Description"
                                    value={detail.description} onValueChange={handleInputChange} />
                                  {error &&
                                    <div className='error'>{error}</div>
                                  }
                                </span>

                                <span className="col-1 pr-0">
                                  <FxInput type="number" name="price" variant="outlined" fullWidth size="small" label="Price"
                                    value={detail.price} onValueChange={handleInputChange} />
                                </span>

                                <span className='col-2'>
                                  <Checkbox name="isActive" color="primary" checked={detail.isActive} value={detail.isActive} onChange={handleCheckbox} />
                                  Active
                                </span>
                                <span className='col-2'>
                                  <Checkbox name="isDefault" color="primary" checked={detail.isDefault} value={detail.isDefault} onChange={handleCheckbox} />
                                  Default
                                </span>
                                <span className="col-2 text-left pl-0">
                                  <span>
                                    <Button variant="contained" size="small" className='button-style' onClick={cancelEditDetail}>Cancel</Button>
                                  </span>
                                  <span className="pl-4">
                                    <Button variant="contained" size="small" color="primary" className='button-style' onClick={saveGroupDetail}>Save</Button>
                                  </span>
                                </span>
                              </div>
                              // Edit Detail form section code ends 
                            }
                          </div>
                        ))
                        }
                      </div>

                      {/* Detail form section code starts */}
                      <div className='pt-2'>
                        {row.header.id == detail.stallItemCustomizeHeaderId && detail.isDetailForm &&
                          <div className='row'>
                            <span className="col-2">
                              <div>
                                <a className="pointer">
                                  {!detail.imageUrl && <img src={dummyImage} alt="dummy" width={80} height={80}></img>}
                                  {detail.imageUrl && <img src={detail.imageUrl} alt="prod" width={80} height={80}></img>}
                                </a>
                                <div className="camera-align mt-n3 pl-4" onClick={openPhotoCapture}>
                                  <CameraAltIcon />
                                </div>
                              </div>
                            </span>

                            <span className="col-4">
                              <FxInput name="description" variant="outlined" fullWidth size="small" label="Description"
                                value={detail.description} onValueChange={handleInputChange} />
                              {error &&
                                <div className='error'>{error}</div>
                              }
                            </span>

                            <span className="col-2">
                              <FxInput type="number" name="price" variant="outlined" fullWidth size="small" label="Price"
                                value={detail.price} onValueChange={handleInputChange} />
                            </span>

                            <span className='col-1'>
                              <Checkbox name="isActive" color="primary" checked={detail.isActive} value={detail.isActive} onChange={handleCheckbox} />
                              Active
                            </span>

                            <span className='col-1'>
                              <Checkbox name="isDefault" color="primary" checked={detail.isDefault} value={detail.isDefault} onChange={handleCheckbox} />
                              Default
                            </span>                            

                            <span className="text-right col-3 px-3">
                              <span>
                                <Button variant="contained" size="small" onClick={cancelDetail}>Cancel</Button>
                              </span>
                              <span className="pl-3">
                                <Button variant="contained" size="small" color="primary" onClick={saveGroupDetail}>Save</Button>
                              </span>
                            </span>
                          </div>
                        }
                      </div>

                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <Dialog
          open={groupFormOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleGroupFormClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <StallItemGroupForm stallItemId={stallItemId} stallItemCustomizeHeaderId={stallItemCustomizeHeaderId}
            handleGroupFormClose={handleGroupFormClose} />
        </Dialog>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
        </Dialog>
        <Dialog
          open={photoCaptureOpen}
          onClose={handlePhotoCaptureClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
        </Dialog>
      </div>
    </div>
  );
}
export default StallItemCustomize;