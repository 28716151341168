import React, { useState } from 'react';
import './StallBookingBehavior.scss';
import Card from '@material-ui/core/Card';
import dummyImage from "../../../assets/images/dummy.png";
import { FxCurrency, FxPopup } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import info from "../../../assets/info.png";
import ImageSlides from '../../general/ImageSlides/ImageSlides';

const StallBookingBehavior = (props: any) => {

    const [imageSlideOpen, setImageSlideOpen] = useState(false);
    const [imageConfig, setImageConfig] = useState({});

    const openImages = (item: any) => {
        let config = {
            api: `portal/stallbooking/${item.id}/images`,
            title: item.title
        };
        setImageConfig(config);
        setImageSlideOpen(true);
    }

    const handleImageSlideClose = () => {
        setImageSlideOpen(false);
    }

    return (
        <div className="bookingservice-ui">
            {/* Desktop View */}
            <div className="deskview pt-5">
                <Card style={{ width: "350px", height: "350px" }} className="d-flex flex-column px-2 py-3 pointer">
                    <div className="row justify-content-center pointer" onClick={() => openImages(props.item)}>
                        {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
                        {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
                    </div>
                    <div className='mt-3 text-center'>
                        <span className="font-22 itemname-style">
                            {props.item.name}
                        </span>
                        {
                            props.item.description &&
                            <span className='info-placement pl-2 zoom-in-out-box'>
                                <FxPopup image={info}
                                    popupId={`booking-${props.item.id}`} maxWidth="370px" fontSize="12px">
                                    <div className='p-3 card-style'>
                                        <span>{props.item.description}</span>
                                    </div>
                                </FxPopup>
                            </span>
                        }
                    </div>
                    <div className='mt-3 row justify-content-between'>
                        <div className='ml-2'>
                            <span className='bold-text pl-3'>Total Time : </span>
                            <span className='pl-2'>{props.item.durationInMins} mins</span>
                        </div>
                        <div className='pr-2 pl-2'>
                            {
                                !props.item.unit &&
                                <span className='bold-text pl-3'>Price : </span>
                            }
                            {
                                props.item.unit &&
                                <span className='bold-text pl-3'>Price / {props.item.unit} : </span>
                            }
                            <span className='pl-2'>
                                <FxCurrency value={props.item.pricePerSeat} />
                            </span>
                        </div>
                    </div>
                    {/* <div className="mt-3 font-12 text-success">
                        {props.item.description}
                    </div> */}
                    <div className='text-center pt-4'>
                        <Button color="primary" variant="contained" onClick={(e) => props.navToBookingEntry(e, props.item.id)}><b>Book Now</b></Button>
                    </div>
                </Card>
            </div>
            <Dialog
                open={imageSlideOpen}
                onClose={handleImageSlideClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
            </Dialog>
        </div>
    );
}
export default StallBookingBehavior;