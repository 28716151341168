import React, { useEffect, useState } from 'react';
import './SpecialCommunityPayments.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Breadcrumbs, Typography } from '@material-ui/core';
import { http, FxCurrency, FxDateDisplay, formatService, FxDate, useNavigationService, FxSelect } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';

const SpecialCommunityPayments = (props) => {
    const { routeData, back } = useNavigationService();

    let defaultFilter: any = {
        fromDate: formatService.getYearStartDate(),
        toDate: formatService.getCurrentDateForServer(),
        communityId: routeData.communityId,
        paymentStatus: "Paid"
    };

    const paymentStatus = [
        { code: "", text: "All" },
        { code: "Pending", text: "Pending" },
        { code: "Paid", text: "Paid" }
    ];

    const [filters, setFilters] = useState(defaultFilter);
    const [paymentList, setPaymentList] = useState([]);
    const [communityList, setCommunityList] = useState([]);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            communityId: filters.communityId,
            paymentStatus: filters.paymentStatus
        }
        if (item.name == "fromDate") {
            filterObj.fromDate = formatService.getDateStringForServer(item.value);
        }
        else if (item.name == "toDate") {
            filterObj.toDate = formatService.getDateStringForServer(item.value);
        }
        else if (item.name == 'communityId') {
            filterObj.communityId = item.value;
        }
        else if (item.name == 'paymentStatus') {
            filterObj.paymentStatus = item.value;
        }
        getSpecialCommunityPayments(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getSpecialCommunityPayments = (data: any) => {
        let inputData = {
            ds: {
                resultset: [
                    { key: "payments", type: "list" }
                ]
            },
            data: {
                communityId: data.communityId,
                fromDate: data.fromDate,
                toDate: data.toDate,
                paymentStatus: data.paymentStatus
            }
        };
        let apiOptions: any = {
            url: `general/dbo/GetSpecialCommunityPayments`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getSpecialCommunityPaymentsallBack(res);
        });
    };

    const getSpecialCommunityPaymentsallBack = (res) => {
        let result = res.data;
        setPaymentList(result.payments);
    }

    const navToBack = () => {
        back();
    };

    const getMyAdminCommunities = () => {
        let apiOptions: any = {
            url: 'community/role/list',
            data: {}
        };
        http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
    };

    const getMyAdminCommunitiesCallBack = (res) => {
        let result = res.data;
        let commuArr: any = [];
        for (let item of result) {
            if (item.communityTypeId == 2) { //2-Special
                let itemObj: any = {
                    id: item.id,
                    text: item.name
                };
                commuArr.push(itemObj);
            }
        }
        setCommunityList(commuArr);
    };

    useEffect(() => {
        getMyAdminCommunities();
        getSpecialCommunityPayments(filters);
    }, []);

    return (
        <div className='special-community-payment-ui pt-5'>
            <div className="title-font custom-head pl-4 pt-5">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                        <b>{routeData.communityName}</b>
                    </Link>
                    <Typography color="primary" className="breadcrumbs-style">Payments</Typography>
                </Breadcrumbs>
            </div>
            <div className='row my-4 mx-2'>
                <div className="col-3">
                    <FxDate name="fromDate" variant="outlined" size="small" fullWidth label="From"
                        value={filters.fromDate} onValueChange={handleInputChange} />
                </div>
                <div className="col-3">
                    <FxDate name="toDate" variant="outlined" size="small" fullWidth label="To"
                        value={filters.toDate} onValueChange={handleInputChange} />
                </div>
                <div className='col-3 pt-3'>
                    <FxSelect name="communityId" variant="outlined"
                        label="Community"
                        options={communityList}
                        selectedValue={filters.communityId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>
                <div className="col-3 pt-3">
                    <FxSelect name="paymentStatus" variant="outlined" label="Payment Status"
                        options={paymentStatus}
                        selectedValue={filters.paymentStatus}
                        valueField="code"
                        displayField="text"
                        size="small"
                        fullWidth
                        onValueChange={handleInputChange} />
                </div>
            </div>
            <div className='row my-4 mx-4 pt-2'>
                <TableContainer component={Paper}>
                    <Table className="table-striped" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Provider Name</TableCell>
                                <TableCell>Community</TableCell>                            
                                <TableCell>Entity Type</TableCell>
                                <TableCell>Payment Date</TableCell>
                                <TableCell>Payment Mode</TableCell>
                                <TableCell>Payment Status</TableCell>                                
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentList.length == 0 &&
                                <TableCell colSpan={7}>
                                    <NoRecords />
                                </TableCell>
                            }
                            {paymentList.length > 0 && paymentList.map((row: any, i) => (
                                <TableRow>
                                    <TableCell>
                                        {row.providerName}
                                    </TableCell>
                                    <TableCell>
                                        {row.communityName}
                                    </TableCell>
                                    <TableCell>
                                        {row.entityType}
                                    </TableCell>                                    
                                    <TableCell>
                                        {<FxDateDisplay value={row.paymentDate} />}
                                    </TableCell>
                                    <TableCell>
                                        {row.paymentMode}
                                    </TableCell>
                                    <TableCell>
                                        {row.paymentStatus}
                                    </TableCell>                                    
                                    <TableCell>
                                        {<FxCurrency value={row.amount} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
export default SpecialCommunityPayments;