import React, { useState, useEffect } from 'react';
import './ServiceRequestItemForm.scss';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { FxInput, http, general, useNavigationService, localstorage, session, AlertService } from '../../../fx-core';
import ItemCard from '../ItemCard/ItemCard';
import dummyImage from "../../../../assets/images/dummy.png";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from "lodash";
import Button from '@material-ui/core/Button';
import NewCategoryItemForm from '../NewCategoryItemForm/NewCategoryItemForm';
import Drawer from '@material-ui/core/Drawer';
import { Fab } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      "& .MuiButtonBase-root.MuiTab-root": {
        fontSize: 15,
      },
      "& .MuiTab-root": {
        fontSize: 15,
        color: "#FFFFFF",
        width: 100
      },

      "& .Mui-selected": {
        outline: 'none',
        background: "white",
        fontWeight: "bold",
        color: "#f69516"
      }
    },
    drawerPaper: {
      height: '60%',
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
    },
    drawerWidth: {
      [theme.breakpoints.down(599)]: {
        width: '90%'
      },
      [theme.breakpoints.up(600)]: {
        width: '70%'
      },
      [theme.breakpoints.up(1025)]: {
        width: '40%'
      }
    }
  }),
);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const customClass = props.customClass || "p-3";
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        //   <Box p={2}>
        <div className={customClass}>
          {children}
        </div>
        //   </Box>
      )}
    </div>
  );
}

const ServiceRequestItemForm = (props: any) => {
  const classes = useStyles();
  const { getRouteData, navigate, back, pageState } = useNavigationService();
  console.log('pageState', pageState);
  let defaultItem: any = {
    searchText: "",
    categoryId: 0,
    categoryName: ""
  };

  if (pageState && pageState.item) {
    defaultItem = { ...defaultItem, ...pageState.item };
  }

  const [item, setItem] = useState(defaultItem);
  const routeData = getRouteData();
  const [serviceId, setServiceId] = useState(props.serviceId ? parseInt(props.serviceId) : 0);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem('communityId')));
  const [itemDetails, setItemDetails] = useState([]);
  const [selectedItemList, setSelectedItemList] = useState(props.selectedItems);
  const [value, setValue] = useState(0);
  const [categories, setCategories] = useState([]);
  const [openCatgItemForm, setCatgItemFormOpen] = React.useState(false);
  const [otherCategoryItems, setOtherCategoryItems] = useState(JSON.parse(session.getItem('otherCategoryItems')) ? JSON.parse(session.getItem('otherCategoryItems')) : []);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    getItemsByCategory({ "searchText": item.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCategories = () => {
    let inputData = {
      communityId: communityId,
      serviceId: serviceId
    }
    let apiOptions: any = {
      url: `category/active`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      getCategoriesCallBack(result);
    });
  }

  const getCategoriesCallBack = (res) => {
    let categoryList = [];
    categoryList = res.categories;
    let allCategories = computeCategoriesWithOtherCategoryItems(categoryList);
    let categoryId = allCategories && allCategories.length > 0 ? allCategories[0].id : 0;
    let categoryName = allCategories && allCategories.length > 0 ? allCategories[0].categoryName : "";
    handleItemChange({ ["categoryId"]: categoryId });
    handleItemChange({ ["categoryName"]: categoryName });
    getItemsByCategory({ "categoryId": categoryId });
  }

  const computeCategoriesWithOtherCategoryItems = (categoryList) => {
    if (otherCategoryItems && otherCategoryItems.length > 0) {
      for (let item of otherCategoryItems) {
        let newCategoryAvailable = _.filter(categoryList, { id: -1, categoryName: item.categoryName });
        if (item.categoryId == -1 && newCategoryAvailable.length == 0) {
          let otherCategory =
          {
            communityId: communityId,
            serviceId: serviceId,
            id: item.categoryId,
            categoryName: item.categoryName
          };
          categoryList.push(otherCategory);
        }
      }
    }
    setCategories(categoryList);
    return categoryList;
  }

  const tabSelect = (item: any) => {
    handleItemChange({ ["categoryId"]: item.id });
    handleItemChange({ ["categoryName"]: item.categoryName });
    getItemsByCategory({ "categoryId": item.id, categoryName: item.categoryName });
  }

  const getItemsByCategory = (data: any) => {
    if (data.categoryId > 0) {
      let inputData = {
        filters: {
          categoryId: data.categoryId > 0 ? data.categoryId : item.categoryId,
          name: item.searchText,
          serviceId: serviceId,
          communityId: communityId
        }
      };
      let apiOptions: any = {
        url: `portal/service/itemsbycategory`,
        data: inputData
      };
      http.post(apiOptions).then(response => {
        getItemsByCategoryCallback(response.data, data.categoryId);
      });
    }
    else if (data.categoryId == -1) {
      otherItems(data.categoryName);
    }
  }

  const getItemsByCategoryCallback = (res, catgId) => {
    let itemList: any = [];
    for (let item of res) {
      item.imageUrl = general.getImageUrl(item.imagePath);
      item.isSelected = false;
    }
    itemList = res;
    if (otherCategoryItems && otherCategoryItems.length > 0) {
      for (let itemObj of otherCategoryItems) {
        if (itemObj.categoryId == catgId) {
          let otherItem =
          {
            communityId: communityId,
            serviceId: serviceId,
            id: itemObj.itemId,
            name: itemObj.itemName
          };
          itemList.push(otherItem);
        }
      }
    }
    for (let item of itemList) {
      let lookupItem = _.find(selectedItemList, { id: item.id });
      if (lookupItem) {
        item.isSelected = lookupItem.isSelected;
      }
    }
    setItemDetails(itemList);
  }

  const otherItems = (catgName) => {
    let itemList: any = [];
    if (otherCategoryItems && otherCategoryItems.length > 0) {
      for (let itemObj of otherCategoryItems) {
        if (itemObj.categoryName == catgName) {
          let otherItem =
          {
            communityId: communityId,
            serviceId: serviceId,
            id: itemObj.itemId,
            name: itemObj.itemName,
            categoryName: itemObj.categoryName
          };
          itemList.push(otherItem);
        }
      }
    }
    for (let item of itemList) {
      let lookupItem = _.find(selectedItemList, { id: item.id, name: item.name });
      if (lookupItem) {
        item.isSelected = lookupItem.isSelected;
      }
    }
    setItemDetails(itemList);
  }

  const navToSetup = () => {
    if (selectedItemList.length == 0) {
      AlertService.showMsg({ message: "Please select atleast one item to place service request" });
      return;
    }
    props.onServiceReqFormCallback(selectedItemList);
  }

  const callbackItemCard = (itemObj) => {
    if (itemObj.isSelected) {
      if (itemObj.categoryId > 0) {
        let lookupItem: any = _.find(categories, { id: itemObj.categoryId });
        if (lookupItem) {
          itemObj.categoryName = lookupItem.categoryName;
        }
      }
      selectedItemList.push(itemObj);
    }
    else {
      let itemIdx = _.findIndex(selectedItemList, { id: itemObj.id, name: itemObj.name });
      selectedItemList.splice(itemIdx, 1);
    }
    setSelectedItemList(selectedItemList);
  }

  const addNewCategoryItem = () => {
    setCatgItemFormOpen(true);
  }

  const handleFormClose = () => {
    setCatgItemFormOpen(false);
  }

  const onSaveFormCallback = (data) => {
    setCatgItemFormOpen(false);
    let selectedCategoryId = item.categoryId;
    let selectedCategoryName = item.categoryName;
    let otherItemList: any = [];
    if (data && data.otherCategoryItems && data.otherCategoryItems.length > 0) {
      for (let itemObj of data.otherCategoryItems) {
        otherItemList.push(itemObj);
        otherCategoryItems.push(itemObj);
      }
    }
    setOtherCategoryItems(otherCategoryItems);
    session.setItem('otherCategoryItems', JSON.stringify(otherCategoryItems));
    let categoryList: any = categories || [];
    if (otherItemList && otherItemList.length > 0) {
      for (let item of otherItemList) {
        let newCategoryAvailable = _.filter(categoryList, { id: -1, categoryName: item.categoryName });
        if (item.categoryId == -1 && newCategoryAvailable.length == 0) {
          let otherCategory =
          {
            communityId: communityId,
            serviceId: serviceId,
            id: item.categoryId,
            categoryName: item.categoryName
          };
          categoryList.push(otherCategory);
        }
      }
    }
    setCategories(categoryList);
    if (categoryList && categoryList.length == 1) {
      selectedCategoryId = categoryList[0].id;
      selectedCategoryName = categoryList[0].categoryName;
    }
    handleItemChange({ ["categoryId"]: selectedCategoryId });
    handleItemChange({ ["categoryName"]: selectedCategoryName });

    let itemList: any = itemDetails || [];
    for (let itemObj of otherItemList) {
      if (itemObj.categoryId == selectedCategoryId && itemObj.categoryName == selectedCategoryName) {
        let otherItem =
        {
          communityId: communityId,
          serviceId: serviceId,
          id: itemObj.itemId,
          name: itemObj.itemName,
          categoryName: itemObj.categoryName
        };
        itemList.push(otherItem);
      }
    }
    setItemDetails(itemList);
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="servicereq-form-ui">
      {/* Desktop View */}
      <div className="deskview">
        <div className="text-center px-4 pb-4 pt-2 row justify-content-center align-items-center">
          <div className='col-2 text-right font-17'>Select the Items and Click</div>
          <div className='col-3 text-center'>
            <Button variant="contained" color="primary" fullWidth
              onClick={navToSetup} >
              Next
            </Button>
          </div>
        </div>
        <div className='col-12'>
          <AppBar position='static'>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
              variant="scrollable" scrollButtons="auto" className={classes.tabs}>
              {
                categories.length > 0 && categories.map((row: any, i) => (
                  <Tab label={row.categoryName} {...a11yProps(i)} onClick={() => tabSelect(row)} style={{ fontWeight: 600, maxWidth: "25%" }} />
                ))
              }
            </Tabs>
          </AppBar>
        </div>
        <div className="col-12 px-5 py-5">
          <div className='row justify-content-between px-5 pb-3'>
            <div className="col-3">
              {/* <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Items" fullWidth
                onValueChange={handleInputChange} size="small"
              /> */}
            </div>
            <div className="col-4 text-center font-weight-bold">{itemDetails.length} varieties</div>
            <div className="col-4 text-right">
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={addNewCategoryItem}
              >
                <i className="fas fa-plus pr-2"></i>
                Add New Item
              </Fab>
            </div>
          </div>


          <div className="row">
            {
              itemDetails.length > 0 && itemDetails.map((itemObj, i) => (
                <div className="col-3">
                  <ItemCard item={itemObj} categoryId={item.categoryId} callbackItemCard={callbackItemCard} />
                </div>
              ))
            }
            {
              itemDetails.length == 0 &&
              <div className="py-5 mx-auto">
                <img src={dummyImage} alt="no items"></img>
                <div className="pt-4 font-17"><b> Sorry, No items available.</b></div>
              </div>
            }
          </div>

        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openCatgItemForm} onClose={handleFormClose}>
        <NewCategoryItemForm serviceId={serviceId} onSaveFormCallback={onSaveFormCallback} handleFormClose={handleFormClose} />
      </Drawer>
    </div>

  );
}
export default ServiceRequestItemForm;