import React, { useEffect, useState } from 'react';
import './LiveStreamCard.scss';
import { FxDateTimeDisplay } from '../fx-core';
import noImage from "../../assets/images/no-image.png";
import { Button } from '@material-ui/core';

const LiveStreamCard = (props) => {
  const defaultItem = {
    liveStreamName: "",
    description: "",
    liveStreamDate: "",
    duration: null,
    startTime: "",
    endTime: "",
    stallName: "",
    stallItemName: "",
    imageUrl: "",
    stallItemId: 0,
    stallItemImage: "",
    liveStreamStatusId: 0
  };
  const [item, setItem] = useState(defaultItem);

  useEffect(() => {
    if (props && props.liveStreamObj) {
      setItem(props.liveStreamObj);
    }
  }, [props]);

  return (
    <div className='lvcard-ui row align-items-center justify-content-center mt-3 card-back-color mx-5'>
      <div className='col-2 text-right'>
        {!item.imageUrl && <i className="fas fa-user-circle dummy-image"></i>}
        {item.imageUrl && <img src={item.imageUrl} alt="Item" width="100" height="100"></img>}
      </div>
      <div className='col-6 font-16 pl-0'>
        <div>
          <span className='justify-content-center d-flex font-weight-bold font-22 pb-2'> {item.liveStreamName}</span>
        </div>
        <div className='col-12 d-flex'>
          <div>
            <span className='font-weight-bold font-17'> {item.stallName}</span>
          </div>
          <div className='pl-5'>
            <span><i className="fas fa-stopwatch color-grey"></i></span>
            <span> {item.duration} mins</span>
          </div>
          <div className='pl-5'>
            <span><i className="fas fa-clock color-grey"></i></span>
            <span> <FxDateTimeDisplay value={item.startTime} displayType="time" /></span>
            <span className='px-1'>-</span>
            <span><FxDateTimeDisplay value={item.endTime} displayType="time" /></span>
          </div>
        </div>
        <div className='col-12 pt-3 pb-2 color-grey font-15'>
          <span><i className="fas fa-file-alt color-grey pr-1"></i></span>
          <span>  {item.description}</span>
        </div>
      </div>
      <div className='col-4 d-flex align-items-center'>
        <span>
          {item.stallItemId > 0 &&
            <span>
              {!item.stallItemImage && <img src={noImage} alt="Item" width="100" height="100"></img>}
              {item.stallItemImage && <img src={item.stallItemImage} alt="Item" width="100" height="100"></img>}
              <span className='pl-3 font-weight-bold'>{item.stallItemName}</span>
            </span>
          }
        </span>
        {
          props.userId > 0 &&
          <span className='pl-4'>
            <Button variant='contained' size='small' className='button-style' onClick={props.openOrder}>Order Now</Button>
          </span>
        }
      </div>
    </div>
  );
}
export default LiveStreamCard;