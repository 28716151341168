import React, { useState, useEffect } from 'react';
import './ListingOrderConfirmation.scss';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { AlertService, http, general, formatService, useNavigationService, localstorage } from '../fx-core';
import { ListingPaymentMethod } from './ListingPaymentMethod/ListingPaymentMethod';
import { ListingReviewCart } from "./ListingReviewCart/ListingReviewCart";

const ListingOrderConfirmation = (props) => {

  const listingCartData = useSelector<any, any>(store => store.listingCart);
  const [userId, setUserId] = useState(props.userId ? parseInt(props.userId) : 0);
  const [stallId, setStallId] = useState(listingCartData.stallId);

  const allPaymentOptions = [
    { text: 'Cash', name: 'paymentoption', value: 'cod', checked: false },
    { text: 'Razorpay (UPI, Credit/Debit Card, NetBanking)', name: 'paymentoption', value: 'razorpay', checked: true }
  ];

  let defaultContex = {
    paymentMode: 'razorpay'
  };

  let defaultStall = {
    id: 0,
    name: ''
  };

  const { navigate } = useNavigationService();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [currentContext, setCurrentContext] = useState(defaultContex);
  const [stall, setStall] = useState(defaultStall);

  const onInit = () => {
    getStallById();
  };

  const getStallById = () => {
    let apiOptions: any = {
      url: `stall/${stallId}`
    };
    http.get(apiOptions).then(res => {
      getStallCallBack(res.data);
    })
  };

  const getStallCallBack = (res) => {
    setStall(res);
    getPaymentOptions();
  };

  const getPaymentOptions = () => {
    let apiOptions: any = {
      url: `paymentoption/active/stall/${stallId}`
    };
    http.get(apiOptions).then(res => {
      getPaymentsCallBack(res);
    })
  };

  const getPaymentsCallBack = (res) => {
    let paymentOptionList = res.data;
    let paymentModes: any = [];
    for (let item of paymentOptionList) {
      let lookupItem = _.find(allPaymentOptions, { value: item.paymentOptionCode });
      if (lookupItem) {
        paymentModes.push(lookupItem);
      }
    }
    setPaymentOptions(paymentModes);
  };

  const onPaymentModeChange = (selected) => {
    const data = { paymentMode: selected.value };
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
    let tmpPayModes = paymentOptions.map((item) => {
      if (item.value === selected.value) {
        item.checked = true;
      }
      else {
        item.checked = false;
      }
      return item;
    });
    setPaymentOptions(tmpPayModes);
  };


  const confirmOrder = () => {

    if (listingCartData.items.length === 0) {
      AlertService.showErrorMsg("Please select products to order");
      return;
    }
    if (listingCartData.totalAmount === 0) {
      AlertService.showErrorMsg("Order total should not be zero");
      return;
    }

    prepareDataAndPlaceOrder();
  };

  const prepareDataAndPlaceOrder = () => {
    const inputData = getOrderInputData();
    placeOrder(inputData);
  };

  const getOrderInputData = () => {
    const orderLines = [];

    for (let item of listingCartData.items) {
      if (item.quantity > 0) {
        let newItem = {
          ItemName: item.itemName,
          Price: item.price,
          ItemQuantity: item.quantity,
          LineTotalPrice: (item.quantity * item.price),
          SaleItemId: item.id
        };
        orderLines.push(newItem);
      }
    }

    const order = {
      id: 0,
      stallId: stallId,
      saleId: listingCartData.saleId,
      orderAmount: listingCartData.totalAmount,
      orderTypeId: 1,
      paymentMode: currentContext.paymentMode,
      orderDate: formatService.getCurrentDateForServer(),
      communityId: localstorage.getItem('communityId')
    };
    let inputData = {
      SaleOrder: order,
      SaleOrderLines: orderLines
    };
    return inputData;
  };

  const placeOrder = (inputData) => {
    let apiOptions: any = {
      url: `saleorder/place`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      createOrderCallBack(res);
    })
  };

  const createOrderCallBack = (res) => {
    if (res.status === 200) {
      navToOrderSummary(res.data.saleOrderId);
    }
  };

  const navToOrderSummary = (saleOrderId) => {
    let navData = {
      targetRoute: '/listingordersummary/' + saleOrderId,
      targetTitle: "Order Summary",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
  }, [listingCartData]);

  return (
    <div className="listingorderconfirmation-ui pt-5">
      <div className="content-container">

        {/* Desktop View */}
        <div className='deskview'>
          <div className="row col-12 p-4">
            <div className="row col-12 pb-4 pt-3 pl-5">
              <h5>Order Confirmation</h5>
            </div>
            <div className="col-1"></div>
            <div className='col-3'>
              <div className='row'>                             
                <div className='pb-4'>
                  <ListingPaymentMethod
                    paymentOptions={paymentOptions}
                    paymentMode={currentContext.paymentMode}
                    onPaymentModeChange={onPaymentModeChange}
                  />
                </div>
              </div>
              <div>
                <span>Stall owner will reach out to further discuss specifics and confirm.</span>
              </div>
            </div>
            <div className='col-6'>
              <ListingReviewCart userId={userId} stallId={stallId} confirmOrder={confirmOrder} stallName={stall.name} />
            </div>
            <div className="col-2"></div>
          </div>
        </div>


        {/* Tablet View */}
        <div className='tabview'>
          <div className="row col-12 p-4">
            <div className="row col-12 pb-4 pt-3 pl-4">
              <h5>Order Confirmation</h5>
            </div>
            <div className='col-12 pl-4'>
              <div className='row'>                
                <div className='col-6 pb-4'>
                  <ListingPaymentMethod
                    paymentOptions={paymentOptions}
                    paymentMode={currentContext.paymentMode}
                    onPaymentModeChange={onPaymentModeChange}
                  />
                </div>
              </div>
              <div>
                <span>Stall owner will reach out to further discuss specifics and confirm.</span>
              </div>
            </div>
            <div className='col-12'>
              <ListingReviewCart userId={userId} stallId={stallId} confirmOrder={confirmOrder} stallName={stall.name} />
            </div>
          </div>
        </div>


        {/* Mobile View */}
        <div className='mobileview'>
          <div className="p-3">
            <div className="row col-12 pb-4 pt-3">
              <h5>Order Confirmation</h5>
            </div>
            <div>
              <ListingPaymentMethod
                paymentOptions={paymentOptions}
                paymentMode={currentContext.paymentMode}
                onPaymentModeChange={onPaymentModeChange}
              />
            </div>
            <div>
                <span>Stall owner will reach out to further discuss specifics and confirm.</span>
            </div>
            <div className='col-12 px-0 pt-3'>
              <ListingReviewCart userId={userId} stallId={stallId} confirmOrder={confirmOrder} stallName={stall.name} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ListingOrderConfirmation;