import moment from 'moment';
import { Constants } from './constants';

export const formatService = {
    getCurrentDate: getCurrentDate,
    getCurrentTime: getCurrentTime,
    parseDate: parseDate,
    getCurrentDateForServer: getCurrentDateForServer,
    getDateStringForServer: getDateStringForServer,
    getNextDate: getNextDate,
    getFormattedTimeForServer: getFormattedTimeForServer,
    getConvertedTime: getConvertedTime,
    getConvertedDate: getConvertedDate,
    getTimeDisplay: getTimeDisplay,
    getCurrentDateTime: getCurrentDateTime,
    isBefore: isBefore,
    addDays: addDays,
    substractDays: substractDays,
    getConvertToDateFormat: getConvertToDateFormat,
    substractDaysInDisplayFormat: substractDaysInDisplayFormat,
    getConvertedDateTime: getConvertedDateTime,
    getDayByDate: getDayByDate,
    getDateByAddedHrs: getDateByAddedHrs,
    getConvertedTimeForDisplay: getConvertedTimeForDisplay,
    getTimeString: getTimeString,
    getConvertedTimeForServer: getConvertedTimeForServer,
    futureDateValidator: futureDateValidator,
    getTimeForServer: getTimeForServer,
    getComputedDateFromInput: getComputedDateFromInput,
    getPreviousDate: getPreviousDate,
    getCurrentTimeForServer: getCurrentTimeForServer,
    getDateDisplayForChat: getDateDisplayForChat,
    getLocalDateFromUtc: getLocalDateFromUtc,
    getTimeByAddedMins: getTimeByAddedMins,
    getMonthEndDate: getMonthEndDate,
    getYearEndDate: getYearEndDate,
    getYearStartDate: getYearStartDate,
    getMonthStartDate: getMonthStartDate,
    getDayFromDate: getDayFromDate
}

const predefinedMap: any = {
    currentDate: {},
    monthStart: { "addValue": 0, "addPart": "", "startOrEnd": "start", "startOrEndPart": "" },
    dayPlusSeven: { "addValue": 7, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" },
    dayPlusSix: { "addValue": 6, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" },
    previousDay: { "addValue": -1, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" },
};

function parseDate(strInput: any) {
    var dateOptions = predefinedMap[strInput];
    if (dateOptions) {
        return getComputedDate(dateOptions);
    }

    var result = "";
    if (strInput) {
        result = moment(strInput, [Constants.ServerDateFormat, Constants.DateFormat]).format(Constants.DateFormat);
    }
    return result;
}

function getComputedDate(opts: any) {
    var result = moment();
    if (opts.addPart) {
        result = result.add(opts.addValue || 0, opts.addPart)
    }
    if (opts.startOrEnd) {
        result = opts.startOrEnd == 'start' ? result.startOf(opts.startOrEndPart) : result.endOf(opts.startOrEndPart);
    }
    if (opts.addDayValue) {
        result = result.day(opts.addDayValue)
    }
    return result.format(Constants.DateFormat);
}

function getCurrentDateForServer() {
    let result = moment().format(Constants.ServerDateFormat);
    return result;
}

function getDateStringForServer(inputDate: any) {
    if (inputDate) {
        let result = moment(inputDate, [Constants.ServerDateFormat, Constants.DateFormat]).format(Constants.ServerDateFormat);
        return result;
    }
    return inputDate;
}

function getNextDate(inputDate: any) {
    let result = moment(inputDate).add(1, 'days').format(Constants.ServerDateFormat)
    return result;
}

function getCurrentDate() {
    let result = moment().format(Constants.DateFormat);
    return result;
}

function getCurrentTime() {
    let result = moment().format(Constants.TimeFormat12Hour);
    return result;
}

function getConvertedTime(inputDate: any) {
    let result = moment(inputDate, ["hh:mm A"]).format(Constants.ServerTimeFormat);
    return result;
}

function getFormattedTimeForServer(inputDate: any) {
    let result = moment(inputDate, ["hh:mm A"]).format(Constants.ServerTimeFormat);
    return result;
}

function getConvertedDate(inputDate: any) {
    let result = moment(inputDate).format(Constants.ServerDateFormat);
    return result;
}

function getTimeDisplay(inputDate: any) {
    let result = moment(inputDate, ["hh:mm:ss"]).format(Constants.TimeFormat12Hour);
    return result;
}

function getCurrentDateTime() {
    var result = "";
    result = moment().format(Constants.DateTimeFormat);
    return result;
}

function isBefore(startDate: any, endDate: any) {
    return moment(endDate, [Constants.ServerDateFormat, Constants.DateFormat]).isBefore(moment(startDate, [Constants.ServerDateFormat, Constants.DateFormat]));
}

function addDays(inputDate: any, number: any) {
    var result = "";
    result = moment(inputDate).add(number, 'days').format(Constants.ServerDateFormat);
    return result;
}

function substractDays(inputDate: any, number: any) {
    var result = "";
    result = moment(inputDate).add(-number, 'days').format(Constants.ServerDateFormat);
    return result;
}

function getConvertToDateFormat(inputDate: any) {
    let result = moment(inputDate, [Constants.DateFormat]).format(Constants.DateFormat);
    return result;
}

function substractDaysInDisplayFormat(inputDate: any, number: any) {
    var result = "";
    result = moment(inputDate, [Constants.DateFormat]).add(-number, 'days').format(Constants.DateFormat);
    return result;
}

function getConvertedDateTime(inputDate: any) {
    let result = moment(inputDate).format(Constants.DateTimeFormat);
    return result;
}

function getDayByDate(inputDate: any) {
    let result = moment(inputDate).format('dd');
    return result;
}

function getDateByAddedHrs(inputHrs: any) {
    var result = "";
    result = moment().add(inputHrs, 'hours').format(Constants.ServerDateFormat);
    return result;
}

function getConvertedTimeForDisplay(inputDate: any) {
    let result = moment(inputDate, [Constants.TimeFormat, Constants.TimeFormatForServer]).format(Constants.TimeDisplayFormat);
    return result;
}

function getTimeString(inputDate: any) {
    var result = "";
    if (inputDate) {
        result = moment(inputDate, [Constants.TimeDisplayFormat]).format(Constants.TimeFormat);
    }
    return result;
}

function getConvertedTimeForServer(inputDate: any) {
    var result = "";
    result = moment(inputDate, [Constants.TimeDisplayFormat]).format(Constants.TimeFormatForServer);
    return result;
}

function futureDateValidator(inputDate: any) {
    const dateVal = moment(inputDate).format(Constants.ServerDateFormat);
    const currentDate = moment().format(Constants.ServerDateFormat);
    var result = moment(dateVal).isSameOrBefore(currentDate);
    return result;
}

function getTimeForServer(inputDate: any) {
    var result = "";
    if (inputDate) {
        result = moment(inputDate, [Constants.TimeFormat]).format(Constants.TimeFormat);
    }
    return result;
}

function getComputedDateFromInput(opts: any, inputDate: any) {
    var result = inputDate ? moment(inputDate) : moment();
    if (opts.addPart) {
        result = result.add(opts.addValue || 0, opts.addPart)
    }
    if (opts.startOrEnd) {
        result = opts.startOrEnd == 'start' ? result.startOf(opts.startOrEndPart) : result.endOf(opts.startOrEndPart);
    }
    return result.format(Constants.ServerDateFormat);
}

function getPreviousDate() {
    let result = moment().subtract(1, 'day').format(Constants.ServerDateFormat);
    return result;
}

function getCurrentTimeForServer() {
    let result = moment().format(Constants.TimeFormatForServer);
    return result;
}

function getDateDisplayForChat(inputDate: any) {
    let result = moment(inputDate).format(Constants.DisplayDateForChat);
    return result;
}

function getLocalDateFromUtc(inputDate: any) {
    let utctoLocalDate = moment.utc(inputDate).local();
    let result = moment(utctoLocalDate).format(Constants.ServerDateFormat)
    return result;
}

function getTimeByAddedMins(inputTime: any, inputSecs: any) {
    var result = moment(inputTime, [Constants.TimeDisplayFormat]).add(inputSecs, 'minutes').format(Constants.TimeDisplayFormat);
    return result;
}

function getMonthEndDate(inputDate: any) {
    var result = "";
    var monthName = moment(inputDate).format('MMM');
    if (monthName == 'Jan' || monthName == 'Mar' || monthName == 'May' || monthName == 'Jul' || monthName == 'Aug'
        || monthName == 'Oct' || monthName == 'Dec') {
        result = moment(inputDate).add(30, 'day').format(Constants.ServerDateFormat);
    }
    else if (monthName == 'Apr' || monthName == 'Jun' || monthName == 'Sep' || monthName == 'Nov') {
        result = moment(inputDate).add(29, 'day').format(Constants.ServerDateFormat);
    }
    else if (monthName == 'Feb') {
        result = moment(inputDate).add(27, 'day').format(Constants.ServerDateFormat);
    }
    return result;
}

function getYearEndDate(inputDate: any) {
    var result = "";
    if (inputDate) {
        result = moment(inputDate).add(364, 'day').format(Constants.ServerDateFormat);
    }
    return result;
}

function getYearStartDate() {
    var result = moment().startOf('year').format(Constants.ServerDateFormat);
    return result;
}

function getMonthStartDate() {
    var result = moment().startOf('month').format(Constants.ServerDateFormat);
    return result;
}

function getDayFromDate(inputDate: any) {
    let result = "";
    if(inputDate) {
        result = moment(inputDate).format(Constants.DayFromat);
    }
    return result;
}