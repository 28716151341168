import { constants } from 'buffer';
import moment from 'moment';
import { localstorage }  from './localStorageService';

export const general = {
    getFormattedDate: getFormattedDate,
    getFormattedTime: getFormattedTime,
    getImageUrl: getImageUrl,
    comboValidator: comboValidator,
    getYearsFromDates: getYearsFromDates,
    getLocalCurrentDateForServer: getLocalCurrentDateForServer,
    getDateStringForServer: getDateStringForServer,
    getProviderAgreementUrl: getProviderAgreementUrl
}

function getFormattedDate(date: any) {
    let formattedDate = moment(date).format("DD/MM/yyyy");
    return formattedDate;
}
function getFormattedTime(time: any) {
    let formattedTime = moment(time, 'hhmm').format('hh:mm A');
    return formattedTime;
}

function getImageUrl(imagePath:any) {
    if (imagePath) {
        let imagStorageUrl = localstorage.getItem("imagStorageUrl");
        let result = imagStorageUrl + imagePath;
        return result;
    }
    return "";
}

function comboValidator(control: any) {
    let comboValue = control.value;
    if (comboValue && comboValue == -1) {
        return {
            comborequired: {
                comboValue
            }
        }
    }
    return null;
}

function getYearsFromDates(date:any) {
    date = moment(date);
    let currentDate = moment(new Date());
    let years = currentDate.diff(date, 'year');
    return years;
  }

  function getLocalCurrentDateForServer() {
    let result = moment().local().format("YYYY-MM-DD");
    return result;
}

function getDateStringForServer(inputDate:any) {
    let result = moment(inputDate).format("YYYY-MM-DD");
    return result;
}

function getProviderAgreementUrl() {
    let pdfUrl =  localstorage.getItem("imagStorageUrl");
    let result = pdfUrl + "provider-agreement.pdf";
    return result;
}

