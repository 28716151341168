import React, { useEffect, useState } from 'react';
import './ListingStalls.scss';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useNavigationService, http, formatService, localstorage, general, FxInput } from '../../fx-core';
import Typography from '@material-ui/core/Typography';
import nostalls from '../../../assets/nostalls.svg';
import StallCard from '../../StallHome/StallCard/StallCard';

const ListingStalls = (props: any) => {
    const { navigate, back } = useNavigationService();
    const navToCustomerDashboard = (item: any) => {
        back();
    }
    let defaultFilter: any = {
        stallName: ""
    };

    const [filters, setFilters] = useState(defaultFilter);
    const [serviceId, setServiceId] = useState(0);
    const [listingStalls, setListingStalls] = useState([]);
    const [serviceName, setServiceName] = useState("");

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            stallName: filters.stallName
        };
        if (item.name == "stallName") {
            filterObj.stallName = item.value;
        }
        getListingStalls(filterObj, serviceId);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getServiceBySlug = () => {
        const slug = props.match.params.serviceSlug;
        let apiOptions: any = {
            url: `portal/service/${slug}`
        };
        http.get(apiOptions).then(res => {
            getServiceBySlugCallBack(res)
        });
    };

    const getServiceBySlugCallBack = (res) => {
        let result = res.data;
        if (result && result.id) {
            const id = result.id;
            setServiceName(result.serviceName);
            setServiceId(id);
            getListingStalls(filters, id);
        }
        else {
            getListingStalls(filters, serviceId);
        }
    }

    const getListingStalls = (data: any, serviceId) => {
        let inputData = {
            serviceId: serviceId,
            communityId: localstorage.getItem("communityId"),
            currentDate: formatService.getCurrentDateTime(),
            stallName: data.stallName,
        };
        let apiOptions: any = {
            url: `portal/service/stalls`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getListingStallsCallBack(res);
        });
    }

    const getListingStallsCallBack = (res) => {
        let result = res.data.items;
        for (let item of result) {
            item.imageUrl = general.getImageUrl(item.imagePath);
        }
        setListingStalls(result);
    };

    const navToStallListing = (slug: any) => {
        let navData = {
            targetRoute: '/stalls/' + slug,
            targetTitle: "Listing Stalls",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    };

    useEffect(() => {
        getServiceBySlug();
    }, []);


    return (
        <div className="pt-5 pb-5 listinghome-ui">
            <div className="title-font custom-head pt-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
                        <b>Dashboard</b>
                    </span>
                    <Typography color="textPrimary" className="breadcrumbs-style">{serviceName}</Typography>
                </Breadcrumbs>
            </div>

            {/* Desktop View */}
            <div className="row pt-2 deskview">
                <div className="col-12 px-3 pl-5 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="stallName" variant="outlined" label="Search Stalls" fullWidth size="small"
                            value={filters.stallName}
                            onValueChange={handleInputChange}
                        />
                    </div>
                    <div className="row">
                        {
                            listingStalls.length > 0 && listingStalls.map((item, i) => (
                                <div className='col-3'>
                                    <StallCard item={item} navToStall={navToStallListing} />
                                </div>
                            ))
                        }
                        {
                            listingStalls.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No stalls available.</b></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ListingStalls;