import React, { useEffect, useState } from 'react';
import './SpecialEvents.scss';
import { http, general, localstorage } from '../fx-core';
import { Button, Dialog, Link } from '@material-ui/core';
import dummyImage from "../../assets/images/dummy.png";
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash'

const SpecialEvents = (props) => {
  const dispatch = useDispatch();
  const userId = localstorage.getItem('userId');
  const defaultCommId = parseInt(localstorage.getItem("communityId"));
  const displayCount = 8;

  const [allCommunityList, setAllCommunityList] = useState([]);
  const [allSplCommunityList, setAllSplCommunityList] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [splCommunityList, setSplCommunityList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedItem, setSelectedItem] = React.useState({ id: 0 });
  const [baseCommunity, setBaseCommunity] = useState({ id: 0, name: "" });
  const [isLoggedin, setIsLoggedin] = useState(false);

  const getSpecialEvents = () => {

    let apiOptions: any = {
      url: `community/specialcommunity/${defaultCommId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        item.profileImageUrl = general.getImageUrl(item.profileImagePath);
      }
      setAllSplCommunityList(result);
      showLessItems('spl', result);
    })
  }

  const confirmSwitchCommunity = (event, item: any) => {
    setSelectedItem(item);
    setConfirmOpen(true);
    if (userId) {
      setIsLoggedin(true);
    }
    setMessage("Are you sure to switch to " + item.name + " community?");
    event.stopPropagation();
  }

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if (isLoggedin) {
      switchCommunity();
    }
    else {
      switchCommunityWithoutLogin();
    }
    setIsLoggedin(false);
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  }

  const switchCommunity = () => {
    let inputData = {
      communityId: selectedItem.id
    };
    let apiOptions: any = {
      url: `community/switch`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result && result.linkUrl) {
        window.open(result.linkUrl, '_self');
      }
      logoutUser();
    })
  };

  const logoutUser = () => {
    dispatch({ type: "LOGOUT", payload: { isLogin: false } });
    dispatch({ type: "CLEAR_CART", payload: {} });
    dispatch({ type: "CLEAR_LISTING_CART", payload: {} });
  }

  const getUserBaseCommunity = () => {
    let apiOptions: any = {
      url: `community/user/base`,
      data: {}
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setBaseCommunity(result);
    })
  }

  const switchCommunityWithoutLogin = () => {
    let inputData = {
      communityId: selectedItem.id
    };
    let apiOptions: any = {
      url: `community/switch/withoutlogin`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result && result.linkUrl) {
        window.open(result.linkUrl, '_self');
      }
    })
  }

  const getVerifiedCommunities = () => {
    let apiOptions: any = {
      url: `community/normal/verified`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        item.profileImageUrl = general.getImageUrl(item.profileImagePath);
      }
      setAllCommunityList(result);
      showLessItems('community', result);
    })
  }

  const showMoreItems = (ctx) => {
    if (ctx == "spl") {
      setSplCommunityList(allSplCommunityList);
    }
    else {
      setCommunityList(allCommunityList);
    }
  }

  const showLessItems = (ctx, allItems) => {
    if (ctx == "spl") {
      const reducedItems: any = _.take(allItems, displayCount);
      setSplCommunityList(reducedItems);
    }
    else {
      const reducedItems: any = _.take(allItems, displayCount);
      setCommunityList(reducedItems);
    }
  }

  useEffect(() => {
    getVerifiedCommunities();
    getSpecialEvents();
    if (userId) {
      getUserBaseCommunity();
    }
  }, []);

  return (
    <div className='specialevents-ui pt-5'>
      <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
        <span className="col-8 pt-3 py-1 pl-4">
          <span>Special Events</span>
        </span>
      </div>

      <div className='p-2 mx-3'>
        {
          baseCommunity.id > 0 && baseCommunity.id != defaultCommId &&
          <div className="mt-3 font-13 text-center">
            <Link href="#" onClick={(e) => confirmSwitchCommunity(e, baseCommunity)}>
              <b>Switch back to your base community {baseCommunity.name}</b>
            </Link>
          </div>
        }
        <div className='pt-3 text-center'>
          <b>Communities</b>
        </div>

        <div className='row'>
          <div className='col-2'></div>
          <div className='pt-3 col-8 row'>
            {
              communityList.map((row: any, i) => (
                <div className='col-4 pt-3 pointer' key={i}>
                  <div className='row' onClick={(e) => confirmSwitchCommunity(e, row)}>
                    <div>
                      {!row.profileImageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                      {row.profileImageUrl && <img src={row.profileImageUrl} alt="Stall" width="50" height="50"></img>}
                    </div>
                    <div className='ml-2 mt-2'>
                      {row.name}
                    </div>
                  </div>
                </div>
              ))
            }
            {
              allCommunityList.length > displayCount && communityList.length == displayCount &&
              <div className='col-4 pt-4 pl-0'>
                <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => showMoreItems('community')}>Show More</Button>
              </div>
            }
            {
              allCommunityList.length > displayCount && communityList.length > displayCount &&
              <div className='col-4 pt-4 pl-0'>
                <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => showLessItems('community', allCommunityList)}>Show Less</Button>
              </div>
            }
          </div>
          <div className='col-2'></div>
        </div>


        <div className='pt-5 row justify-content-center col-12 text-center'>
          <b>Special Communities</b>
        </div>
        <div className='row'>
          <div className='col-2'></div>
          <div className='pt-3 col-8 row'>
            {
              splCommunityList.map((row: any, i) => (
                <div className='col-4 pt-3 pointer' key={i}>
                  <div className='row' onClick={(e) => confirmSwitchCommunity(e, row)}>
                    <div>
                      {!row.profileImageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                      {row.profileImageUrl && <img src={row.profileImageUrl} alt="Stall" width="50" height="50"></img>}
                    </div>
                    <div className='ml-2 mt-2'>
                      {row.name}
                    </div>
                  </div>
                </div>
              ))
            }
            {splCommunityList.length == 0 &&
              <div className='row col-12 pb-4 font-20 color-aaa justify-content-center'>
                No Special Communities listed
              </div>
            }
            {
              allSplCommunityList.length > displayCount && splCommunityList.length == displayCount &&
              <div className='col-4 pt-4 pl-0'>
                <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => showMoreItems('spl')}>Show More</Button>
              </div>
            }
            {
              allSplCommunityList.length > displayCount && splCommunityList.length > displayCount &&
              <div className='col-4 pt-4 pl-0'>
                <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => showLessItems('spl', allSplCommunityList)}>Show Less</Button>
              </div>
            }
          </div>
          <div className='col-2'></div>
        </div>
      </div>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default SpecialEvents;