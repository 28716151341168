import React, { useEffect, useState } from 'react';
import './CommunityCoupons.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { http, FxCurrency, FxDateDisplay, FxSelect, localstorage } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';

const CommunityCoupons = (props) => {

    let defaultFilter: any = {
        communityId: props.communityId
    };

    const [coupons, setCoupons] = useState([]);
    const [filters, setFilters] = useState(defaultFilter);
    const [communityList, setCommunityList] = useState([]);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            communityId: filters.communityId
        }
        if (item.name == 'communityId') {
            filterObj.communityId = item.value;
        }
        getCommunityCoupons(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getMyRegionCommunities = () => {
        let inputData = {
            lookups: {
                MyRegionCommunities: { default: false }
            },
            filters: {
                userId: localstorage.getItem("userId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            getMyRegionCommunitiesCallBack(response);
        })
    };

    const getMyRegionCommunitiesCallBack = (res) => {
        let result = res.data;
        setCommunityList(result.MyRegionCommunities);
    };

    const getMyZoneCommunities = () => {
        let inputData = {
            lookups: {
                MyZoneCommunities: { default: false }
            },
            filters: {
                userId: localstorage.getItem("userId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            getMyZoneCommunitiesCallBack(response);
        })
    }

    const getMyZoneCommunitiesCallBack = (res) => {
        let result = res.data;
        setCommunityList(result.MyZoneCommunities);
    };

    const getMyAdminCommunities = () => {
        let apiOptions: any = {
            url: 'community/role/list',
            data: {}
        };
        http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
    };

    const getMyAdminCommunitiesCallBack = (res) => {
        let result = res.data;
        let commuArr: any = [];
        for (let item of result) {
            let itemObj: any = {
                id: item.id,
                text: item.name
            };
            commuArr.push(itemObj);
        }
        setCommunityList(commuArr);
    };

    const initCalls = () => {
        if (props.parent == 'REGIONALMANAGER') {
            getMyRegionCommunities();
        }
        else if (props.parent == 'ZONALMANAGER') {
            getMyZoneCommunities();
        }
        else if (props.parent == 'COMMADMIN') {
            getMyAdminCommunities();
        }
    }

    const getCommunityCoupons = (data) => {
        let inputData = {
            ds: {
                resultset: [
                    { key: "coupons", type: "list" }
                ]
            },
            data: {
                communityId: data.communityId
            }
        };
        let apiOptions: any = {
            url: `general/dbo/GetCommunityCoupons`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getCommunityCouponsCallBack(res);
        });
    };

    const getCommunityCouponsCallBack = (res) => {
        let result = res.data;
        setCoupons(result.coupons);
    }

    useEffect(() => {
        initCalls();
        getCommunityCoupons(filters);
    }, []);

    return (
        <div className='communitycoupons-ui'>
            <div className='content-container'>
                <div className="content">
                    <div className='row my-4 mx-2'>
                        <div className='col-3'>
                            <FxSelect name="communityId" variant="outlined"
                                label="Community"
                                options={communityList}
                                selectedValue={filters.communityId}
                                valueField="id"
                                displayField="text"
                                size="small"
                                style={{ width: 260 }}
                                onValueChange={handleInputChange} />
                        </div>
                    </div>
                    <div className='row my-4 mx-4 pt-2'>
                        <TableContainer component={Paper}>
                            <Table className="table-striped" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Community</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Valid</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        coupons.length == 0 &&
                                        <TableCell colSpan={5}>
                                            <NoRecords />
                                        </TableCell>
                                    }
                                    {
                                        coupons.length > 0 && coupons.map((row: any, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <span>{row.communityName}</span>
                                                </TableCell>                                                
                                                <TableCell>
                                                    <span>{row.couponCode}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{row.couponValueType}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span><FxCurrency value={row.couponValue} /></span>
                                                </TableCell>
                                                <TableCell>
                                                    <span><FxDateDisplay value={row.validFrom} /></span> -
                                                    <span className='ml-1'><FxDateDisplay value={row.validTo} /></span>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CommunityCoupons;