import React, { useEffect, useState } from 'react';
import './ItemFiltersMobile.scss';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { http, localstorage } from '../../fx-core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const customClass = props.customClass || "p-3";
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                //   <Box p={2}>
                <div className={customClass}>
                    {children}
                </div>
                //   </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const ItemFiltersMobile = (props: any) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let defaultItem = {
        categoryIds: [],
        selectedCategory: false,
        tagIds: [],
        selectedTag: false
    };

    const [item, setItem] = useState(defaultItem);
    const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);

    const initLookUp = () => {
       /* Categories and Tags not relevent to Listing and Booking
        Giri confirmed in call not to display the list will address later so commented the calls        
        getCategories();
        getTags();*/
    }

    const handleCheckbox = (event) => {
        if (event.target.name == "selectedCategory") {
            let selectedValue = parseInt(event.target.value);
            if (event.target.checked) {
                item.categoryIds.push(selectedValue);
            }
            else {
                let itemIdx = item.categoryIds.indexOf(selectedValue);
                item.categoryIds.splice(itemIdx, 1);
            }
        }
        if (event.target.name == "selectedTag") {
            let selectedTag = parseInt(event.target.value);
            if (event.target.checked) {
                item.tagIds.push(selectedTag);
            }
            else {
                let itemIdx = item.tagIds.indexOf(selectedTag);
                item.tagIds.splice(itemIdx, 1);
            }
        }
        handleItemChange({ [event.target.name]: event.target.checked });
        props.callbackItemFilter({
            categoryIds: event.target.name == "selectedCategory" ? item.categoryIds : 0,
            tagIds: event.target.name == "selectedTag" ? item.tagIds : 0
        });
    }

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getCategories = () => {
        let inputData = {
            stallId: props.stallId
        }
        let apiOptions: any = {
            url: `portal/category/active/stall/serviceIds`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.categories;
            setCategories(result);
        });
    }

    const getTags = () => {
        let apiOptions: any = {
            url: `portal/itemtag/stall/${props.stallId}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            let result = response.data.itemTags;
            setTags(result);
        });
    }

    useEffect(() => {
        initLookUp();
    }, []);

    return (
        <div className={classes.root}>
            {(props.code == 'ItemWise') &&
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" orientation="vertical" variant="scrollable" className="pt-4" >
                    <Tab label="Categories" {...a11yProps(0)} style={{ outline: "none", fontWeight: "bold" }} />
                    <Tab label="Tags" {...a11yProps(0)} style={{ outline: "none", fontWeight: "bold" }} />
                </Tabs>
            }
            <TabPanel value={value} index={0} className="pt-1">
                <div>
                    {
                        categories.length > 0 && categories.map((item, i) => (
                            <div className="font-14">
                                <Checkbox name="selectedCategory" color="primary" value={item.id} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon
                                    fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                                {item.categoryName}
                            </div>
                        ))
                    }
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="pt-1">
                <div>
                    {
                        tags.length > 0 && tags.map((item, i) => (
                            <div className="font-14">
                                <Checkbox name="selectedTag" color="primary" value={item.id} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                                {item.text}
                            </div>
                        ))
                    }
                </div>
            </TabPanel>

        </div>
    );
}
export default ItemFiltersMobile;