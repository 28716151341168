import React, { useEffect, useState } from 'react';
import './ServiceList.scss';
import { FxInput, http, AlertService, general } from '../../fx-core';
import { Button, Fab, Dialog, Drawer, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { NoRecords } from '../../NoRecords/NoRecords';
import ServiceForm from '../ServiceForm/ServiceForm';
import dummyImage from "../../../assets/images/dummy.png";

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ServiceList = (props) => {
  const classes = styles();
  let defaultFilter = {
    serviceName: ""
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [serviceList, setServiceList] = useState([]);
  const [selServiceId, setSelServiceId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [openServiceForm, setOpenServiceForm] = useState(false);

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let filterObj = {
      serviceName: filters.serviceName
    }
    if (item.name == 'serviceName') {
      filterObj.serviceName = item.value;
    }
    getServiceList(filterObj);
  };

  const handleFilterChange = (data) => {
    setFilters(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getServiceList = (data) => {
    let inputData = {
      serviceName: data.serviceName
    };
    let apiOptions: any = {
      url: `service/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getServiceListCallBack(res);
    });
  };

  const getServiceListCallBack = (res) => {
    let result = res.data;
    for (let item of result) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setServiceList(result);
  }

  const confirmDelete = (item: any) => {
    setConfirmOpen(true);
    setSelServiceId(item.id);
    setMessage("Are you sure to delete " + item.serviceName + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setSelServiceId(0);
    setMessage("");
  };

  const onConfirmCallback = () => {
    const id = selServiceId;
    deleteService(id);
    handleDialogClose();
  }

  const deleteService = (id: any) => {
    let apiOptions: any = {
      url: `service/${id}`
    };
    http.delete(apiOptions).then(res => {
      deleteServiceCallBack(res);
    })
  };

  const deleteServiceCallBack = (res) => {
    AlertService.showSuccessMsg();
    getServiceList(filters);
  }

  const addOrEditService = (id) => {
    setOpenServiceForm(true);
    setSelServiceId(id);
  };

  const closeServiceForm = () => {
    setOpenServiceForm(false);
    setSelServiceId(0);
    getServiceList(filters);
  }

  useEffect(() => {
    getServiceList(filters);
  }, []);

  return (
    <div className='servicelist-ui pt-5'>
      <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
        <span className="col-8 pt-3 py-1 pl-4">
          <span>Services</span>
        </span>
      </div>

      <div className="row col-12 my-3 ml-1 mr-5 justify-content-between">
        <div className="col-3">
          <FxInput name="serviceName" variant="outlined" label="Search Service"
            size="small"
            value={filters.serviceName}
            onValueChange={handleInputChange}
          />
        </div>
        <div className="col-3">
        </div>
        <div className="col-3">
        </div>
        <div className="col-3 pt-1 text-right">
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => addOrEditService(0)}>
            <i className="fas fa-plus pr-2"></i>
            Add
          </Fab>
        </div>
      </div>

      <div className='p-2 mx-3'>
        <div className='mb-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Display Order</TableCell>
                  <TableCell>Default</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceList.length == 0 &&
                  <TableCell colSpan={7}>
                    <NoRecords />
                  </TableCell>
                }
                {serviceList.length > 0 && serviceList.map((row: any, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {!row.imageUrl && <img src={dummyImage} alt="Item" width="50" height="50"></img>}
                      {row.imageUrl && <img src={row.imageUrl} alt="Item" width="50" height="50"></img>}
                    </TableCell>
                    <TableCell>{row.serviceCode}</TableCell>
                    <TableCell>{row.serviceName}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.displayOrder}</TableCell>
                    <TableCell>{row.defaultBehaviorCode}</TableCell>
                    <TableCell>
                      <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => addOrEditService(row.id)}>Edit</Button>
                      <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmDelete(row)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openServiceForm} onClose={closeServiceForm}>
        <ServiceForm serviceId={selServiceId} closeServiceForm={closeServiceForm} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default ServiceList;