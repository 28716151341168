import React, { useEffect, useState } from 'react';
import './StallItemSettings.scss';
import { http, localstorage, FxSelect, AlertService } from '../../fx-core';
import _ from "lodash";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { Button } from '@material-ui/core';

const StallItemSettings = (props) => {
  let defaultContext = {
    stallId: 0
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [stallId, setStallId] = useState(props.stallId);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [stalls, setStalls] = useState([]);
  const [stallItems, setStallItems] = useState([]);

  const handleCurrentContext = (item) => {
    handleCurrentContextChange({ [item.name]: item.value });
    getStallItems(item.value);
  };

  const handleCurrentContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        ProviderStalls: { default: false }
      },
      filters: { communityId: communityId, userId: userId }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setStalls(response.data.ProviderStalls);
      handleCurrentContextChange({ "stallId": stallId });
      if (stallId > 0) {
        getStallItems(stallId);
      }
    })
  }

  const getStallItems = (id) => {
    let inputData = {
      communityId: communityId,
      ownerId: userId,
      id: id
    }
    let apiOptions: any = {
      url: `stall/query/stallitems`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      let result = res.data;
      result = _.orderBy(result, ['displayOrder'], ['asc']);
      setStallItems(result);
    })
  };

  const handleRLDDChange = (items) => {
    setStallItems(items);
  }

  const saveStallItemOrder = () => {
    let displayOrderVal = 0;
    for (let itemObj of stallItems) {
      displayOrderVal = displayOrderVal + 1;
      itemObj.displayOrder = displayOrderVal;
    }
    let inputData = {
      stallItems: stallItems
    };
    let apiOptions: any = {
      url: `stallitem/displayorder/save`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      AlertService.showSuccessMsg();
      props.handleStallItemSettingsClose();
    });
  }

  const handleClose = () => {
    props.handleStallItemSettingsClose();
  }

  useEffect(() => {
    initLookup();
  }, []);

  const itemRenderer = (row: any, i: number): JSX.Element => {
    return (
      <div className="row col-12 m-0 border-bottom py-1 pointer">
        <span className="col-1 pl-4">
          <span> <i className="fas fa-grip-vertical"></i></span>
        </span>
        <span className="col-11">
          {row.itemName}
        </span>
      </div>
    );
  };

  return (
    <div className='stallitemsettings-ui'>
      <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
        <span className="col-11">
          <span>Stall Item Settings</span>
        </span>
        <span className="col-1 text-right pointer">
          <i className="fas fa-times-circle font-16" onClick={handleClose}></i>
        </span>
      </div>
      <div className='mx-1 px-3 pt-4 pb-1 row justify-content-between align-items-center drawer-combo'>
        <div>
          <FxSelect name="stallId" variant="outlined" label="Select Stall"
            options={stalls}
            selectedValue={currentContext.stallId}
            valueField="id"
            displayField="text"
            size="small"
            style={{ width: 250 }}
            onValueChange={handleCurrentContext} />
        </div>
        <div>
          <span><i className='fas fa-grip-vertical'></i></span>
          <span className='pl-1 font-13'> Drag and Drop items to change dispaly order in customer order flow</span>
        </div>
      </div>
      
          <div className='mx-3 mt-3 stall-item-content'>           
              <RLDD
                cssClasses="list-container"
                items={stallItems}
                itemRenderer={itemRenderer}
                onChange={handleRLDDChange}
              />

              <div className="text-center">
                {stallItems && stallItems.length == 0 &&
                  <span className='font-20'><b>No items</b></span>
                }
              </div>          
          </div>
      
      <div className="px-3 py-2 drawer-footer">
        <span>
          <Button variant="contained" size="medium" color="primary" fullWidth onClick={saveStallItemOrder}>Save</Button>
        </span>
      </div>
    </div>
  );
}
export default StallItemSettings;