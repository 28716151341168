import React, { useEffect, useState } from 'react';
import './StallCouponList.scss';
import { http, AlertService, FxCurrency, FxDateDisplay } from '../../fx-core';
import { Dialog, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab } from '@material-ui/core';
import _ from "lodash";
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const StallCouponList = (props) => {

  const [stallCoupons, setStallCoupons] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedId, setSelectedId] = useState(0);
  const [delAction, setDelAction] = useState(false);

  const getStallBasedCoupons = () => {
    let inputData = {
      stallId: props.stallId
    }
    let apiOptions: any = {
      url: `coupon/stall/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setStallCoupons(result);
    });
  };

  const addOrEditCouponForm = (id) => {
    props.handleAddOrEditCoupon(id);
  };

  const confirmDelete = (item) => {
    setConfirmOpen(true);
    setDelAction(true);
    setMessage("Are you sure to delete " + item.couponCode + "?");
    setSelectedId(item.id);
  };

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setDelAction(false);
    setSelectedId(0);
    setMessage("");
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if(delAction) {
      deleteCoupon();
    }
    else {
      notifyUsers();
    }
  }

  const deleteCoupon = () => {
    let apiOptions: any = {
      url: `coupon/${selectedId}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showMsg({ message: "Deleted successfully" });
      setSelectedId(0);
      setDelAction(false);
      setMessage("");
      getStallBasedCoupons();
    })
  };

  const confirmNotifyUsers = (id) => {
    setConfirmOpen(true);
    setDelAction(false);
    setMessage("Do you want to notify all users in this community?");
    setSelectedId(id);
  };

  const notifyUsers = () => {
    let inputData = {
      id: selectedId
    }
    let apiOptions: any = {
      url: `coupon/stall/notification`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showMsg({ message: "Push notification send successfully" });
      setSelectedId(0);
      setMessage("");
    });
  };  

  useEffect(() => {
    getStallBasedCoupons();
  }, []);

  return (
    <div className='stallcouponlist-ui'>
      <div className='drawer-combo pt-3'>
        <span className='d-flex justify-content-end mr-4 pr-2 pb-3'>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => addOrEditCouponForm(0)}
          >
            <i className="fas fa-plus pr-2"></i>
            Add
          </Fab>
        </span>
      </div>
      <div className='px-3 py-3 pt-4'>
        <div className='my-4 pb-5 pt-5 delivery-slot-scroll'>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Valid</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {stallCoupons.map((row, i) => (
                <TableBody key={i}>
                  <TableRow>
                    <TableCell>
                      <span>{row.couponCode}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.couponValueType}</span>
                    </TableCell>
                    <TableCell>
                      {
                        row.couponValueTypeId == 1 &&
                        <span>{row.couponValue} %</span>
                      }
                      {
                        row.couponValueTypeId == 2 &&
                        <span><FxCurrency value={row.couponValue} /></span>
                      }
                    </TableCell>
                    <TableCell className='pr-0'>
                      <span><FxDateDisplay value={row.validFrom} /></span> -
                      <span className='ml-1'><FxDateDisplay value={row.validTo} /></span>
                    </TableCell>
                    <TableCell className='pr-1'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <span className='pointer'><i className="fas fa-pencil-alt font-13" onClick={() => addOrEditCouponForm(row.id)}></i></span>
                        <span className='ml-3 pointer'><i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row)}></i></span>
                        <Button variant='outlined' size='small' className='button-style ml-3' onClick={() => confirmNotifyUsers(row.id)}>Notify Users</Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default StallCouponList;