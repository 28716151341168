import React from 'react';
import './DeliveryAddress.scss';

export const DeliveryAddress = (props) => {
    
    return (
        <div>
            <div className='row ml-0 mr-5'>
                <div className="font-14 bold-text pb-2">
                    Delivery Address
                </div>
                <div className="color-primary font-13 pl-3 pointer bold-text" onClick={props.openAddressDialog}>
                    <i className="fas fa-map-marker-alt pr-1"></i>Select your address
                </div>
            </div>
            <address>
                <span className="m-0 font-14 p-font pt-1">
                    <div>
                        {
                            props.address && props.address.fullName &&
                            <p className='ml-2 mt-2 font-13'>
                                {props.address.fullName},<br />
                                {props.address.mobile}<br />
                                {props.address.fullAddress}
                                <br />
                            </p>
                        }
                    </div>
                    <div className="color-primary font-13 pl-2 pointer" onClick={props.openAddressForm}>
                        <i className="fas fa-plus pr-1"></i> Add New
                    </div>
                </span>
            </address>
        </div>
    );
};