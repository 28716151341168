import React, { useState, useEffect } from 'react';
import './OrderConfirmationContainer.scss';
import { useSelector } from 'react-redux';
import OrderConfirmation from '../OrderConfirmation/OrderConfirmation';
import ListingOrderConfirmation from '../ListingOrderConfirmation/ListingOrderConfirmation';
import { localstorage } from '../fx-core';

const OrderConfirmationContainer = (props) => {
    const cart = useSelector<any, any>(s => s.cart);
    const listingCart = useSelector<any, any>(s => s.listingCart);

    const uid = localstorage.getItem('userId');
    const [userId, setUserId] = useState(uid ? parseInt(uid) : 0);
    const [currentStep, setCurrentStep] = useState(0);

    const handleCurrentStep = () => {
        if (cart.cartCount > 0) {
            setCurrentStep(1);
        }
        else if (listingCart.cartCount > 0) {
            setCurrentStep(2);
        }
    };

    useEffect(() => {
        handleCurrentStep();
    }, []);

    return (
        <div className='content-container'>
            {
                currentStep == 1 &&
                <OrderConfirmation userId={userId} />
            }
            {
                currentStep == 2 &&
                <ListingOrderConfirmation userId={userId} />
            }
        </div>
    );
};

export default OrderConfirmationContainer;