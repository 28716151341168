import React, { useEffect, useState } from 'react';
import './ServiceRequestList.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog, Drawer, makeStyles } from '@material-ui/core';
import { http, FxDateDisplay, FxSelect, formatService, AlertService } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import ServiceRequestDetail from './ServiceRequestDetail/ServiceRequestDetail';
import SaleServiceRequestDetail from './SaleServiceRequestDetail/SaleServiceRequestDetail';
import ServiceRequestConfirmDialog from '../ServiceRequestConfirmDialog/ServiceRequestConfirmDialog';

const styles = makeStyles((theme) => ({
  postsDrawer: {
    paddingTop: "70px",
    [theme.breakpoints.down(599)]: {
      width: '60%'
    },
    [theme.breakpoints.up(600)]: {
      width: '40%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '20%'
    }
  },
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ServiceRequestList = () => {
  const classes = styles();

  let defaultContext: any = {
    communityId: 0,
    serRequestStatusId: -1,
    serviceId: -1
  };

  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [serRequestStatus, setSerRequestStatus] = useState([]);
  const [services, setServices] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const defaultSelectedReq: any = {};
  const [selectedReq, setSelectedReq] = useState(defaultSelectedReq);
  const [action, setAction] = useState("");
  const [detailOpen, setDetailOpen] = useState(false);
  const [saleDetailOpen, setSaleDetailOpen] = useState(false);
  const [splEvtConfirmOpen, setSplEvtConfirmOpen] = useState(false);


  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    if (item.name == "communityId") {
      getServiceRequests({ communityId: item.value, serRequestStatusId: currentContext.serRequestStatusId, serviceId: currentContext.serviceId });
    }
    else if (item.name == "serRequestStatusId") {
      getServiceRequests({ serRequestStatusId: item.value, communityId: currentContext.communityId, serviceId: currentContext.serviceId });
    }
    else if (item.name == "serviceId") {
      getServiceRequests({ serviceId: item.value, communityId: currentContext.communityId, serRequestStatusId: currentContext.serRequestStatusId });
    }
  };

  const handleItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getServiceRequests = (data: any) => {
    let inputData = {
      communityId: data.communityId,
      serviceRequestStatusId: data.serRequestStatusId,
      serviceId: data.serviceId,
      currentDate: formatService.getCurrentDateForServer()
    };
    let apiOptions: any = {
      url: `servicerequest/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      let result = res.data;
      setServiceRequests(result);
    })
  };

  const getUserRoleCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getUserRoleCommunitiesCallBack(res) });
  };

  const getUserRoleCommunitiesCallBack = (res) => {
    setCommunityList(res.data);
    if (res.data.length > 0) {
      const commuId = res.data[0].id;
      handleItemChange({ communityId: commuId });
      getServiceRequests({ paymentDate: currentContext.paymentDate, paymentStatus: currentContext.paymentStatus, communityId: commuId });
    }
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        RequestServices: { default: true },
        ServiceRequestStatus: { default: true }
      },
      filters: {
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initLookupCallBack(response);
    })
  }

  const initLookupCallBack = (res) => {
    let result = res.data;
    setServices(result.RequestServices);
    setSerRequestStatus(result.ServiceRequestStatus);
  };

  const confirmApprove = (item) => {
    let msg = "Do you want to approve #" + item.serviceRequestIdentifier + "?";
    setMessage(msg);
    setSelectedReq(item);
    if (item.communityTypeId == 2) { //2-Special
      setSplEvtConfirmOpen(true);
    }
    else {
      setConfirmOpen(true);
    }
    setAction("approve");
  }

  const confirmReject = (item) => {
    let msg = "Do you want to reject #" + item.serviceRequestIdentifier + "?";
    setMessage(msg);
    setSelectedReq(item);
    setConfirmOpen(true);
    setAction("reject");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setMessage("");
    setAction("")
    setSelectedReq({});
  };

  const onConfirmCallback = (ctx, comments) => {
    let item: any = selectedReq;
    item.comments = comments;
    if (action == "approve") {
      item.serviceRequestStatusId = 2;
    }
    if (action == "reject") {
      item.serviceRequestStatusId = 3;
    }
    updateServiceReqStatus(item);
    handleDialogClose();
  };

  const handleSplEvtDialogClose = () => {
    setSplEvtConfirmOpen(false);
    setMessage("");
    setAction("")
  };

  const onSplEvtConfirmCallback = (itemObj) => {
    let item: any = selectedReq;
    item.amount = itemObj.amount;
    item.comments = itemObj.comments;
    item.serviceRequestStatusId = 2;
    updateServiceReqStatus(item);
    handleSplEvtDialogClose();
  };

  const updateServiceReqStatus = (item) => {
    let apiOptions = {
      url: 'servicerequest/updatestatus',
      data: item
    };
    http.put(apiOptions).then(res => {
      updateServiceReqStatusCallBack(res);
    });
  }

  const updateServiceReqStatusCallBack = (res) => {
    AlertService.showMsg({ message: "Service request status updated successfully" });
    getServiceRequests(currentContext);
  }

  useEffect(() => {
    initLookup();
    getUserRoleCommunities();
  }, []);

  const openDetail = () => {
    setDetailOpen(true);
  }
  const closeDetail = () => {
    setDetailOpen(false);
    getServiceRequests(currentContext);
  }

  const openSaleDetail = () => {
    setSaleDetailOpen(true);
  }
  const closeSaleDetail = () => {
    setSaleDetailOpen(false);
    getServiceRequests(currentContext);
  }

  const viewDetail = (item) => {
    setSelectedReq(item);
    if (item.saleId > 0) {
      openSaleDetail();
    } else {
      openDetail();
    }
  }

  return (
    <div className='pending-payment-list-ui pt-5'>
      <div className="title-font custom-head pl-4 pt-5">
        <Typography color="primary" className="breadcrumbs-style">Service Request List</Typography>
      </div>
      <div className='row my-4 mx-2'>
        <div className="col-3 pt-3">
          <FxSelect name="communityId" variant="outlined" label="Communities"
            options={communityList}
            selectedValue={currentContext.communityId}
            valueField="id"
            displayField="name"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>
        <div className="col-3 pt-3">
          <FxSelect name="serRequestStatusId" variant="outlined" label="Status"
            options={serRequestStatus}
            selectedValue={currentContext.serRequestStatusId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>
        <div className="col-3 pt-3">
          <FxSelect name="serviceId" variant="outlined" label="Services"
            options={services}
            selectedValue={currentContext.serviceId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>
      </div>

      <div className='my-4 mx-4 pt-2'>
        <div className='my-1 d-flex justify-content-end'>
          <span className='pr-1 color-grey'><i className="fas fa-info-circle"></i></span>
          <span className='color-grey font-13'>Please verify pending payments before approval.</span>
        </div>
        <TableContainer component={Paper}>
          <Table className="table-striped" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Request Identifier</TableCell>
                <TableCell>Request Type</TableCell>
                <TableCell>Request Date</TableCell>
                <TableCell>Requested By</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Stall</TableCell>
                <TableCell>Service</TableCell>
                <TableCell align='center'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceRequests.length == 0 &&
                <TableCell colSpan={7}>
                  <NoRecords />
                </TableCell>
              }
              {serviceRequests.length > 0 && serviceRequests.map((row: any, i) => (
                <TableRow key={i}>
                  <TableCell>
                    # {row.serviceRequestIdentifier}
                  </TableCell>
                  <TableCell>
                    {row.serviceRequestType}
                  </TableCell>
                  <TableCell>
                    {<FxDateDisplay value={row.requestDate} />}
                  </TableCell>
                  <TableCell>
                    {row.requestedByName}
                  </TableCell>
                  <TableCell>
                    {row.serviceRequestStatus}
                  </TableCell>
                  <TableCell>
                    {row.stallName}
                  </TableCell>
                  <TableCell>
                    {row.serviceName}
                  </TableCell>
                  <TableCell>
                    {row.serviceRequestStatusId == 1 &&
                      <div>
                        <Button variant='outlined' size='small' className='button-style'
                          onClick={() => confirmApprove(row)}>Approve</Button>

                        <Button variant='outlined' size='small' className='button-style ml-2'
                          onClick={() => confirmReject(row)}>Reject</Button>
                      </div>
                    }
                    <Button variant='outlined' size='small' className='button-style mt-1'
                      onClick={() => viewDetail(row)}>View</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} action={"verify"} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>

      <Drawer anchor={"right"} open={detailOpen} onClose={closeDetail} classes={{ paper: classes.drawerWidth }}>
        <ServiceRequestDetail serviceRequestId={selectedReq.id} handleClose={closeDetail} />
      </Drawer>

      <Drawer anchor={"right"} open={saleDetailOpen} onClose={closeSaleDetail} classes={{ paper: classes.drawerWidth }}>
        <SaleServiceRequestDetail serviceRequestId={selectedReq.id} handleClose={closeSaleDetail} />
      </Drawer>
      <Dialog
        open={splEvtConfirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleSplEvtDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ServiceRequestConfirmDialog message={message} handleDialogClose={handleSplEvtDialogClose} onConfirmCallback={onSplEvtConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default ServiceRequestList;