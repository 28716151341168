import React, { useState, useEffect } from 'react';
import './GuestComponent.scss';
import Button from '@material-ui/core/Button';
import { AlertService, FxInput } from '../fx-core';

const GuestComponent = (props: any) => {
    const defaultItem = {
        name: ""
    };
    const [item, setItem] = useState(defaultItem);
    const [error, setError] = useState(null);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const continueAsGuest = () => {
        if (!item.name) {
            setError("Enter your name is required");
            return;
        }
        props.handleGuestCallback(item.name);
    }

    useEffect(() => {

    }, []);

    return (
        <div className="guestcomponent-ui">
            <div>
                <div className='text-center m-1'>
                    <FxInput name="name" variant="outlined" label="Enter your name"
                        size="small" value={item.name} onValueChange={handleInputChange} fullWidth
                    />
                </div>
                {error && !item.name &&
                    <div className="error"> {error} </div>
                }

                <div className='text-center py-4 m-1'>
                    <Button variant="contained" size="medium" color="primary" fullWidth
                        onClick={continueAsGuest}>
                        Continue as Guest
                    </Button>
                </div>
            </div>
        </div>
    );
}
export default GuestComponent;