import React, { useEffect, useState } from 'react';
import './ProviderAccountForm.scss';
import { http, FxSelect, localstorage, formatService, AlertService, FxInput } from '../../fx-core';
import { Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as _ from 'lodash';

const ProviderAccountForm = (props) => {

    const defaultItem = {
        paymentModeId: -1,
        amount: 0,
        couponCode: ""
    };
    const defaultAmountDetails = [
        { code: "ten", text: 10, symbol: "INR " },
        { code: "twenty", text: 20, symbol: "INR " },
        { code: "fifty", text: 50, symbol: "INR " },
        { code: "hundred", text: 100, symbol: "INR " }
    ];
    const [paymentMode, setPaymentMode] = useState([]);
    const [item, setItem] = useState(defaultItem);
    const [amountDetails, setAmountDetails] = useState(defaultAmountDetails);
    const [selAmtCode, setSelAmtCode] = useState("");

    const initLookup = () => {
        let inputData = {
            lookups: {
                PaymentMode: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setPaymentMode(response.data.PaymentMode);
        })
    };

    const handleClose = (res: any = {}) => {
        props.closeProviderAccountForm(res);
    };

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    }

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const onAmountSelect = (itemObj) => {
        let item = { amount: itemObj.text };
        setSelAmtCode(itemObj.code);
        handleItemChange(item);
    };

    const saveAccountCredit = () => {
        if (item.paymentModeId == -1) {
            AlertService.showErrorMsg("Please select payment mode.");
            return;
        }
        if (item.amount == 0) {
            AlertService.showErrorMsg("Please select amount.");
            return;
        }
        const account = {
            userId: localstorage.getItem("userId"),
            communityId: localstorage.getItem("communityId")
        };
        let selPayMode: any = _.find(paymentMode, { id: item.paymentModeId });
        const accountDetail = {
            userId: localstorage.getItem("userId"),
            transactionDate: formatService.getCurrentDateForServer(),
            paymentModeId: item.paymentModeId,
            paymentMode: selPayMode.code,
            amount: item.amount,
            couponCode: item.couponCode
        };
        let inputData = {
            account,
            accountDetail
        };
        let apiOptions: any = {
            url: `account/provider/save`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveAccountCreditCallBack(res);
        });
    };

    const saveAccountCreditCallBack = (res) => {
        const accountDetailId = res.data;
        let selPayMode: any = _.find(paymentMode, { id: item.paymentModeId });
        handleClose({ id: accountDetailId, paymentMode: selPayMode.code });
    };

    useEffect(() => {
        initLookup();
    }, [])

    return (
        <div>
            <div className="row justify-content-between title-font py-1 mx-auto px-3">
                <div className="py-1">
                    <span>Account Form</span>
                </div>
                <div className="float-right pointer" onClick={handleClose}>
                    <HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="m-4">
                <div className='m-1 row'>
                    {
                        amountDetails.map((amt, i) => (
                            <div key={i} className="m-1">
                                <Button variant="contained" size="medium" color={selAmtCode == amt.code ? "primary" : "secondary"} onClick={() => onAmountSelect(amt)}>
                                    <span className='amount'>{amt.symbol}{amt.text}</span>
                                </Button>
                            </div>
                        ))
                    }
                </div>
                <div className="m-1 pt-3">
                    <FxSelect name="paymentModeId" variant="outlined" label="Payment Mode"
                        options={paymentMode}
                        selectedValue={item.paymentModeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        fullWidth
                        onValueChange={handleInputChange} />
                </div>
                {
                    item.paymentModeId == 4 &&
                    <div className='m-1 pt-3'>
                        <FxInput name="couponCode" variant="outlined" label="Coupon" fullWidth size="small"
                            value={item.couponCode} onValueChange={handleInputChange} />
                    </div>
                }
                {
                    item.paymentModeId == 2 &&
                    <div className='m-1 pt-3'>
                        <div>A/C No : 50200095946891</div>
                        <div>Name : AOTEAROA SOLUTIONS PVT LTD</div>
                        <div>IFSC: HDFC0002231</div>
                        <div>Branch: SARAVANAMPATTI BRANCH</div>
                        <div>Account Type: CURRENT</div>
                        <div className='mt-2 text-danger'>
                            Kindly transfer the amount and then submit. Request to be approved shortly.
                        </div>
                    </div>
                }
                <div className="m-1 pt-3 text-center">
                    <Button variant="contained" size="medium" color="primary" onClick={saveAccountCredit}>
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProviderAccountForm;