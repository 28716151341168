import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FxInput, FxSelect, http, AlertService, localstorage } from '../../../fx-core';
import './NewCategoryItemForm.scss';
import _ from "lodash";

const NewCategoryItemForm = (props) => {

  const defaultItem = {
    serviceId: props.serviceId,
    communityId: parseInt(localstorage.getItem("communityId")),
    categoryId: 0,
    categoryName: "",
    itemId: 0,
    itemName: "",
    otherCategory: ""
  };
  const [item, setItem] = useState(defaultItem);
  const [categories, setCategories] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getCategories = () => {
    let inputData = {
      communityId: item.communityId,
      serviceId: props.serviceId
    }
    let apiOptions: any = {
      url: `category/active`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getCategoriesCallBack(res.data);
    });
  };

  const getCategoriesCallBack = (res) => {
    let categoryList : any = [];
    let otherCategory =
    {
      communityId: item.communityId,
      serviceId: item.serviceId,
      id: -1,
      categoryName: "New Category"
    };
    if (res.categories && res.categories.length > 0) {
      res.categories.push(otherCategory);
      categoryList = res.categories;
    }
    else {
      categoryList.push(otherCategory);
    }
    setCategories(categoryList);
    handleItemChange({ ["categoryId"]: categoryList[0].id });
    handleItemChange({ ["categoryName"]: categoryList[0].categoryName });
  }

  const saveForm = () => {
    if (item.categoryId == -1 && !item.otherCategory) {
      AlertService.showMsg({ message: "Please select Other Category" });
      return;
    }
    if (!item.itemName) {
      AlertService.showMsg({ message: "Please enter Item" });
      return;
    }
    if (item.categoryId != -1) {
      handleItemChange({ ["otherCategory"]: "" });
    }
    let categoryItemDetail = computeSelectedItems();
    props.onSaveFormCallback({
      otherCategoryItems: categoryItemDetail
    });
  };

  const computeSelectedItems = () => {
    let selectedItems : any = [];
    let categoryName = item.otherCategory;
    if(item.categoryId > 0) {
      let selectedCategory : any = _.find(categories, {id : item.categoryId});
      categoryName = selectedCategory.categoryName;
    }
    let itemInfo = {
      categoryId: item.categoryId,
      categoryName: categoryName,
      itemId: -1,
      itemName: item.itemName
    }
    selectedItems.push(itemInfo);
    return selectedItems;
  }

  const handleClose = () => {
    props.handleFormClose();
  }

  useEffect(() => {
    getCategories();
  }, []);


  return (
    <div className='newcatgitem-form-ui'>
      <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
        <span className="col-11">
          <span>New Form</span>
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>

      <div className="m-4 pt-4 pb-5">
        <div className="m-1 pt-3">
          <FxSelect name="categoryId" variant="outlined" label="Category"
            options={categories}
            selectedValue={item.categoryId}
            valueField="id"
            displayField="categoryName"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>

        <div className="m-1 pt-3">
          {
            item.categoryId == -1 &&
            <span>
              <FxInput name="otherCategory" variant="outlined" label="New Category" fullWidth
                value={item.otherCategory} onValueChange={handleInputChange} />
            </span>
          }
        </div>

        <div className="m-1 pt-3">
          <FxInput name="itemName" variant="outlined" label="Item" fullWidth
            value={item.itemName} onValueChange={handleInputChange} />
        </div>
      </div>
      <div className="px-3 py-2 drawer-footer">
        <Button variant="contained" size="medium" color="primary" fullWidth onClick={saveForm}>
          Save
        </Button>
      </div>
    </div>
  );
}
export default NewCategoryItemForm;
