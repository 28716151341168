import React, { useState, useEffect } from 'react';
import './MessageThread.scss';
import { Button, Link } from '@material-ui/core';
import { FxTextarea, http, AlertService, FxDateTimeDisplayLocal, general, formatService } from '../fx-core';
import _ from "lodash";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

const MessageThread = (props: any) => {

  const chat = useSelector<any, any>(s => s.chat);
  const defaultItem = {
    msgBody: "",
    msgByname: "",
    profileImageUrl: "",
    threadId: props.currentContext.threadId ? props.currentContext.threadId : null,
    userId: props.currentContext.userId,
    entityId: 0,
    msgEntityTypeId: 0,
    stallId: props.currentContext.stallId > 0 ? props.currentContext.stallId : 0,
    orderId: props.currentContext.orderId > 0 ? props.currentContext.orderId : 0,
    postId: props.currentContext.postId > 0 ? props.currentContext.postId : 0,
    saleOrderId: props.currentContext.saleOrderId > 0 ? props.currentContext.saleOrderId : 0,
    isMyOrders: props.currentContext.isMyOrders,
    msgEntityDisplay: null,
    followedUserId: props.currentContext.followedUserId > 0 ? props.currentContext.followedUserId : 0
  };

  const [item, setItem] = useState(defaultItem);
  const [msgEntityDisplayFields, setMsgEntityDisplayFields] = useState({ entityTypeId: 0, stallName: "", orderIdentifier: "", postTitle: "", saleOrderIdentifier: "" });
  const [messageList, setMessageList] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const setEntityInfoValues = () => {
    if (item.stallId > 0) {
      getStallById(item.stallId);
    }
    else if (item.orderId > 0) {
      getOrderById(item.orderId);
    }
    else if (item.postId > 0) {
      getLocalBusinessById(item.postId);
    }
    else if (item.saleOrderId > 0) {
      getSaleOrderById(item.saleOrderId);
    }
    else if (item.followedUserId > 0) {
      getUserById(item.followedUserId);
    }
  }

  const getStallById = (stallId) => {
    let apiOptions: any = {
      url: `stall/${stallId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      let itemObj = {
        entityId: props.currentContext.stallId,
        msgEntityTypeId: 1,
        msgEntityDisplay: result.name,
        msgByname: result.providerName
      }
      handleItemChange(itemObj);
    })
  };

  const getOrderById = (orderId) => {
    let apiOptions: any = {
      url: `order/${orderId}/stallInfo`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      let name = "";
      if (item.isMyOrders) {
        name = result.stallOwnerName;
      }
      else if (!item.isMyOrders) {
        name = result.consumerName;
      }
      let itemObj = {
        entityId: props.currentContext.orderId,
        msgEntityTypeId: 2,
        msgEntityDisplay: result.stallName + " _ Order #" + result.orderIdentifier,
        msgByname: name
      }
      handleItemChange(itemObj);
    })
  };

  const getLocalBusinessById = (postId) => {
    let apiOptions: any = {
      url: `localbusinessdirectory/${postId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      let itemObj = {
        entityId: props.currentContext.postId,
        msgEntityTypeId: 3,
        msgEntityDisplay: result.title,
        msgByname: result.userFullName
      };
      handleItemChange(itemObj);
    });
  };

  const getSaleOrderById = (id) => {
    let apiOptions: any = {
      url: `saleorder/${id}/stallInfo`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      let name = "";
      if (item.isMyOrders) {
        name = result.stallOwnerName;
      }
      else if (!item.isMyOrders) {
        name = result.consumerName;
      }
      let itemObj = {
        entityId: props.currentContext.saleOrderId,
        msgEntityTypeId: 4,
        msgEntityDisplay: result.stallName + " _ Order #" + result.saleOrderIdentifier,
        msgByname: name
      }
      handleItemChange(itemObj);
    })
  };

  const getUserById = (id) => {
    let apiOptions: any = {
      url: `user/${id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.name = result.lastName ? result.firstName + " " + result.lastName : result.firstName;
      let itemObj = {
        entityId: props.currentContext.followedUserId,
        msgEntityTypeId: 5,
        msgByname: result.name
      };
      handleItemChange(itemObj);
    });
  };


  const markExistingMsgAsRead = () => {
    let inputData = {
      threadId: item.threadId,
      userId: item.userId
    };
    let apiOptions: any = {
      url: `message/updateMsgStatus`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      getMessages(item.threadId);
    })
  };

  const getMessages = (threadId) => {
    let inputData = {
      threadId: threadId,
      userId: item.userId
    };
    let apiOptions: any = {
      url: `message/thread/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setMsgEntityDisplayFields(prevState => {
        return { ...prevState, ...result.msgEntityDisplayFields }
      });
      getMessagesCallback(result.messages);
    })
  };

  const getMessagesCallback = (result) => {
    let msg = _.find(result, { targetUserId: item.userId });
    if (msg) {
      let msgObj = {
        msgByname: msg.msgByName,
        profileImageUrl: general.getImageUrl(msg.profileImagePath)
      }
      handleItemChange(msgObj);
    }
    else {
      msg = _.find(result, { msgById: item.userId });
      if (msg) {
        let msgObj = {
          msgByname: msg.targetUserName,
          profileImageUrl: general.getImageUrl(msg.profileImagePath)
        }
        handleItemChange(msgObj);
      }
    }
    const currentDate = formatService.getCurrentDateForServer();
    const yesParsedDate = formatService.parseDate("previousDay");
    const yesterdayDate = formatService.getDateStringForServer(yesParsedDate);
    let previsouDate = "";
    for (let item of result) {
      let msgDate = formatService.getLocalDateFromUtc(item.msgDateTime);
      item.msgDisplayDate = formatService.getDateDisplayForChat(msgDate);
      item.showDate = false;
      item.showYesterday = false;
      item.showToday = false;
      if (msgDate != previsouDate) {
        previsouDate = msgDate;
        if (msgDate == currentDate) {
          item.showToday = true;
        }
        else if (msgDate == yesterdayDate) {
          item.showYesterday = true;
        }
        else {
          item.showDate = true;
        }
      }
    }
    setMessageList(result);
  }

  const sendMessage = () => {
    if (!item.msgBody.trim()) return;

    let inputData = {
      threadId: item.threadId ? item.threadId : null,
      msgBody: item.msgBody,
      userId: item.userId,
      entityId: item.entityId,
      msgEntityTypeId: item.msgEntityTypeId
    };
    let apiOptions: any = {
      url: `message/send`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      sendMessageCallBack(result);
    });
  };

  const sendMessageCallBack = (result) => {
    if (result && result.threadId) {
      handleItemChange({ "threadId": result.threadId });
    }
    handleItemChange({ "msgBody": "" });
    getMessages(result.threadId);
  };

  const navToAllChats = () => {
    props.handleMessageThreadClose();
  }

  useEffect(() => {
    if (item.threadId) {
      markExistingMsgAsRead();
    }
    else {
      setEntityInfoValues();
    }
  }, [chat]);

  return (
    <div className="messagethread-ui">
      <div className="title-font custom-head pl-4">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToAllChats}>
            <b>Messages</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">
            <span className='col-9 text-center pl-0'>Thread</span>
          </Typography>
        </Breadcrumbs>
        <div className='row pl-3 py-1 font-12 color-grey'>
          <span className='pr-4'>
            <i className="fas fa-user pr-1"></i>
            {item.msgByname}</span>
          {msgEntityDisplayFields.stallName &&
            <span className='pr-4'>
              <i className="fas fa-store pr-1"></i>
              {msgEntityDisplayFields.stallName}</span>
          }
          <span>
            {msgEntityDisplayFields.entityTypeId == 2 &&
              <span>Order#{msgEntityDisplayFields.orderIdentifier}</span>
            }
            {msgEntityDisplayFields.entityTypeId == 3 &&
              <span>
                {msgEntityDisplayFields.postTitle}</span>
            }
            {msgEntityDisplayFields.entityTypeId == 4 &&
              <span>Order#{msgEntityDisplayFields.saleOrderIdentifier}</span>
            }
          </span>
        </div>
      </div>

      <div className='row py-4 thread-content'>
        {
          messageList.map((msg) => (
            <div className='col-12 pt-2'>
              {
                msg.showToday &&
                <div className='text-center font-11 color-aaa'>Today</div>
              }
              {
                msg.showYesterday &&
                <div className='text-center font-11 color-aaa'>Yesterday</div>
              }
              {
                msg.showDate &&
                <div className='text-center font-11 color-aaa'>{msg.msgDisplayDate}</div>
              }
              {item.userId != msg.msgById &&
                <div className='message received'>
                  <div className='font-13'> {msg.msgBody}</div>
                  <div className='float-right font-10 color-aaa'>
                    <FxDateTimeDisplayLocal value={msg.msgDateTime} displayType="time" />
                  </div>
                </div>

              }

              {item.userId == msg.msgById &&
                <div className='message sent'>
                  <div className='font-13'> {msg.msgBody}</div>
                  <div>
                    <span className='float-right font-10 color-aaa'>
                      <FxDateTimeDisplayLocal value={msg.msgDateTime} displayType="time" />
                      {msg.msgStatus === 'Sent' &&
                        <i className="fas fa-check-double pl-2"></i>
                      }
                      {msg.msgStatus === 'Read' &&
                        <i className="fas fa-check-double readmsg-color pl-2"></i>
                      }
                    </span>
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>
      <div className='drawer-footer'>
        <div className='row p-4'>
          <span className='col-10 pr-0'>
            <FxTextarea name="msgBody" className="input-wrap" variant="outlined" size="small" fullWidth maxRows={3}
              value={item.msgBody} onValueChange={handleInputChange} />
          </span>
          <span className='col-2'>
            <Button variant="contained" size="medium" color="primary" onClick={sendMessage}>
              <i className="fa fa-paper-plane font-15"></i>
              <span className='pl-2 font-15'>Send</span>
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}
export default MessageThread;