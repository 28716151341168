import React, { useEffect, useState } from 'react';
import './MyBookingOrders.scss';
import { FxDateDisplay, FxCurrency, http, AlertService, formatService, FxPopper } from '../../fx-core';
import Link from '@material-ui/core/Link';
import { Dialog, Drawer } from '@material-ui/core';
import MyBookingOrderDetail from '../MyBookingOrderDetail/MyBookingOrderDetail';
import { makeStyles, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import MessageContainer from '../../MessageContainer/MessageContainer';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const MyBookingOrders = (props: any) => {
  const classes = styles();
  const Razorpay = useRazorpay();
  
  const RequestStatus = [
    { id: -1, text: "All", code: "-1", isDefault: false, attributes: null },
    { id: 1, text: "Booked", code: "Booked", isDefault: false, attributes: null },
    { id: 2, text: "Approved", code: "Approved", isDefault: false, attributes: null },
    { id: 4, text: "Cancelled", code: "Cancelled", isDefault: false, attributes: null }
  ];

  let defaultFilter: any = {
    requestStatusId: -1
  };
  const initialList = [];
  const [filter, setFilter] = useState(defaultFilter);
  const [stallBookings, setStallBookings] = useState(initialList);
  const [selReqStatusId, setSelReqStatusId] = useState(-1);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedReq, setSelectedReq] = useState({});

  const [open, setOpen] = useState(false);
  const [bookingReqId, setBookingReqId] = useState(0);
  const [currentContext, setCurrentContext] = useState({ totalAmount: 0, stripeText: "Card" });
  const [openMessage, setOpenMessage] = useState(false);

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let options = { filter: { [item.name]: item.value } };
    getStallBookings(options);
  }

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };


  const getStallBookings = (options: any = {}) => {
    let requestStatusId = 1;
    if (options && options.filter) {
      requestStatusId = options.filter.requestStatusId ? options.filter.requestStatusId : -1;
    }
    let inputData = { requestStatusId: requestStatusId, userId: props.userId };

    let apiOptions: any = {
      url: 'stallbookingrequest/user',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getStallBookingsCallBack(res);
    })
  }

  const getStallBookingsCallBack = (res) => {
    let result = res.data;
    for (let item of result) {
      item.bookingTime = item.bookingTime ? formatService.getConvertedTimeForDisplay(item.bookingTime) : "";
      item.showMore = false;
      if (item.requestNotes && item.requestNotes.length > 20) {
        item.showMore = true;
      }
    }
    setStallBookings(result);
  }

  const viewBookingDetail = (item: any) => {
    setOpen(true);
    setBookingReqId(item.id);
  }

  const handleClose = () => {
    setOpen(false);
    setBookingReqId(0);
  }

  const initiatePayment = (id) => {
    let apiOptions: any = {
      url: `stallbookingrequest/GetPaymentLink/${id}`
    };
    http.get(apiOptions).then(response => {
      openPaymentLink(response);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item = resp.data.result;
    let externalOrderId = item.externalOrderId;
    let description = item.description;
    let customerName = item.name;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        init();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `stallbookingrequest/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const handleStatusChange = (selId) => {
    setSelReqStatusId(selId);
    let item = { name: "requestStatusId", value: selId };
    handleInputChange(item);
  };

  const navToMessage = (id) => {
    setBookingReqId(id);
    setOpenMessage(true);
  }

  const handleMessageClose = () => {
    setOpenMessage(false);
    setBookingReqId(0);
  }

  const confirmCancel = (item) => {
    let msg = "Do you want to cancel ?";
    setMessage(msg);
    setSelectedReq(item);
    setConfirmOpen(true);
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setMessage("");
    setSelectedReq({});
  };

  const onConfirmCallback = (ctx, comments) => {
    let item: any = selectedReq;
    item.comments = comments;
    cancelBookingReq(item);
    handleDialogClose();
  };

  const cancelBookingReq = (item) => {
    let apiOptions = {
      url: 'stallbookingrequest/cancel',
      data: item
    };
    http.post(apiOptions).then(res => {
      cancelBookingReqCallBack(res);
    });
  };

  const cancelBookingReqCallBack = (res) => {
    AlertService.showSuccessMsg();
    let options = { filter: filter };
    getStallBookings(options);
  }

  const init = () => {
    let options = { filter: filter };
    getStallBookings(options);
  }

  useEffect(() => {
    init();
  }, []);


  const body = (
    <div className="mybookings-ui">
      <div className="content-container">
        <div className="content">

          {/* Desktop View */}
          <div className='deskview'>
            <div className='ml-0 mt-4'>
              {RequestStatus.map((item, i) => (
                <span className='mr-3'>
                  {
                    selReqStatusId === item.id &&
                    <Button variant='outlined' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                  {
                    selReqStatusId !== item.id &&
                    <Button variant='contained' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                </span>
              ))
              }
            </div>

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Stall</TableCell>
                      <TableCell>Booking Name</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell className='text-right'>Seat Count</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stallBookings.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.bookingDate} /></TableCell>
                        <TableCell>                                                    <span>
                          <i className="far fa-clock color-aaa px-1"></i>
                          {row.bookingTime}
                        </span>
                        </TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>{row.bookingName}</TableCell>
                        <TableCell>
                          <div className='row'>
                            <div className='notes'>{row.requestNotes}</div>
                            {
                              row.showMore &&
                              <div className='pl-3'>
                                <FxPopper action="More"
                                  popupId={`booking-${row.id}`}>
                                  <div className='p-3 card-style'>
                                    <div className='flex'>
                                      <span className='pl-1'>{row.requestNotes}</span>
                                    </div>
                                  </div>
                                </FxPopper>
                              </div>
                            }
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>
                          {row.seatCount}
                        </TableCell>
                        <TableCell className='text-right'>
                          <FxCurrency value={row.amount} />
                        </TableCell>
                        <TableCell>
                          {
                            row.bookingPayments.length == 0 &&
                            <span>
                              N/A
                            </span>
                          }
                          {row.bookingPayments && row.bookingPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'razorpay' &&
                                <span>
                                  {(itemObj.paymentStatus == 'Pending' || itemObj.paymentStatus == 'Initiated') &&
                                    <span>
                                      {currentContext.stripeText}:
                                      <Link className="btn btn-address font-14" onClick={() => initiatePayment(row.id)}
                                        title="pay">Pay</Link>
                                    </span>
                                  }
                                  {itemObj.paymentStatus != 'Pending' && itemObj.paymentStatus != 'Initiated' &&
                                    <span>
                                      {currentContext.stripeText} / {itemObj.paymentStatus}
                                    </span>
                                  }
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'razorpay' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          {row.bookingRequestStatus}
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => viewBookingDetail(row)}>View</Button>
                          {/* <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button> */}
                          {
                            row.bookingRequestStatusId == 1 &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => confirmCancel(row)}>Cancel</Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <MyBookingOrderDetail id={bookingReqId} handleClose={handleClose} />
        </Dialog>
        <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
          <MessageContainer saleOrderId={bookingReqId} isMyOrders={true} handleMessageClose={handleMessageClose} />
        </Drawer>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={"verify"} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default MyBookingOrders;