import React, { useEffect, useState } from 'react';
import './FollowedUsers.scss';
import { http, general, localstorage, useNavigationService } from '../../fx-core';
import dummyImage from "../../../assets/images/dummy.png";
import { Button, Drawer, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@material-ui/core';
import MessageContainer from '../../MessageContainer/MessageContainer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles((theme) => ({
    drawerWidth: {
        [theme.breakpoints.down(599)]: {
            width: '90%'
        },
        [theme.breakpoints.up(600)]: {
            width: '70%'
        },
        [theme.breakpoints.up(1025)]: {
            width: '40%'
        }
    }
}));
const FollowedUsers = (props: any) => {
    const classes = styles();
    const { navigate } = useNavigationService();
    let defaultContext = {
        stallName: ""
    };
    const [currentContext, setCurrentContext] = useState(defaultContext);
    const [stallId, setStallId] = useState(props.match.params.id ? parseInt(props.match.params.id) : 0);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
    const [openMessage, setOpenMessage] = React.useState(false);
    const [followedUserId, setFollowedUserId] = useState(0);
    const toggleDrawerMessage = () => {
        setOpenMessage(!openMessage);
    };

    const getStallById = () => {
        let inputData = {
            id: stallId
        }
        let apiOptions: any = {
            url: `stall/${inputData.id}`,
            data: null
        };
        http.get(apiOptions).then(res => {
            const result = res.data;
            const data = { stallName: result.name };
            setCurrentContext(prevState => {
                return { ...prevState, ...data }
            });
            getStallFollowers();
        })
    };

    const getStallFollowers = () => {
        let inputData = {
            id: stallId
        }
        let apiOptions: any = {
            url: `follow/stall/${inputData.id}`,
            data: null
        };
        http.get(apiOptions).then(res => {
            const result = res.data;
            for (let item of result) {
                item.profileImageUrl = general.getImageUrl(item.profileImagePath);
            }
            setUserList(result);
        })
    };

    const navToMessage = (selectedUserId) => {
        setFollowedUserId(selectedUserId);
        setOpenMessage(true);
    }

    const handleMessageClose = () => {
        setOpenMessage(false);
    }

    const navToDashboard = () => {
        let navData = {
            targetRoute: '/provider/providerdashboard',
            targetTitle: "Provider Dashboard",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    }

    useEffect(() => {
        getStallById();
    }, []);

    const body = (
        <div className="followedusers-ui pt-5">
            <div className="title-font custom-head pt-4 mt-3 pl-4">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToDashboard}>
                        <b>Provider Dashboard</b>
                    </Link>
                    <Typography color="primary" className="breadcrumbs-style">True Fans</Typography>
                </Breadcrumbs>
            </div>

            <div className='m-2'>
                <div className='row pt-3 pb-2 pl-3'>
                    <span className="pl-3">{currentContext.stallName} Fans</span>
                </div>

                <div className='px-3 py-3'>
                    <TableContainer component={Paper}>
                        <Table size="small" className='table-striped'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell width="80%">Name</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map((item, i) => (
                                    <TableRow>
                                        <TableCell>
                                            {!item.profileImageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                                            {item.profileImageUrl && <img src={item.profileImageUrl} alt="Stall" width="50" height="50"></img>}
                                        </TableCell>
                                        <TableCell>
                                            <span>{item.firstName}</span>
                                            {item.lastName &&
                                                <span>{item.lastName}</span>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(item.id)}>Direct</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
                <MessageContainer followedUserId={followedUserId} handleMessageClose={handleMessageClose} />
            </Drawer>
        </div>
    );

    return (
        <div>{body}</div>
    );

}

export default FollowedUsers;