import React, { useEffect, useState } from 'react';
import './ManageItems.scss';
import StallItemForm from '../StallItemForm/StallItemForm';
import StallItemBatchForm from '../StallItemBatchForm/StallItemBatchForm';
import StallItemSettings from '../StallItemSettings/StallItemSettings';
import { http, localstorage, FxSelect, useNavigationService, session } from '../../fx-core';
import { Drawer, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Fab } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import _ from 'lodash';
import { NoRecords } from '../../NoRecords/NoRecords';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ManageItems = (props) => {
  const { navigate } = useNavigationService();
  const classes = styles();
  let defaultContext = {
    stallId: 0
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [stalls, setStalls] = useState([]);
  const [stallItems, setStallItems] = useState([]);

  const [stallItemId, setStallItemId] = useState(0);
  const [stallItemFormOpen, setStallItemFormOpen] = useState(false);
  const [stallItemBatchFormOpen, setStallItemBatchFormOpen] = useState(false);
  const [stallItemSettingsOpen, setStallItemSettingsOpen] = useState(false);
  const [stallServiceMapList, setStallServiceMapList] = useState([]);

  const handleCurrentContext = (item) => {
    handleCurrentContextChange({ [item.name]: item.value });
    getStallItems(item.value);
  };

  const handleCurrentContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        ProviderStalls: { default: false }
      },
      filters: { communityId: communityId, userId: userId }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      const result = response.data.ProviderStalls;
      setStalls(response.data.ProviderStalls);
      let defaultStallId = result.length > 0 ? result[0].id : 0;
      handleCurrentContextChange({ "stallId": defaultStallId });

      if (defaultStallId > 0) {
        getStallItems(defaultStallId);
      }
    })
  }

  const getStallItems = (id) => {
    let inputData = {
      id: id
    }
    let apiOptions: any = {
      url: `stallitem/stall/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setStallItems(result);
    })
  };

  const openStallItemForm = (id) => {
    setStallItemId(id);
    setStallItemFormOpen(true);
  }

  const handleStallItemFormClose = () => {
    setStallItemFormOpen(false);
    getStallItems(currentContext.stallId);
  }

  const openStallItemBatchList = (id) => {
    let navData = {
      targetRoute: '/provider/stallitembatchlist/' + id,
      targetTitle: "Stall Item Batch List",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openStallItemBatchForm = (id) => {
    setStallItemId(id);
    setStallItemBatchFormOpen(true);
  }

  const handleStallItemBatchFormClose = () => {
    setStallItemBatchFormOpen(false);
    getStallItems(currentContext.stallId);
  }

  const openStallItemSettings = (id) => {
    setStallItemId(id);
    setStallItemSettingsOpen(true);
  }

  const handleStallItemSettingsClose = () => {
    setStallItemSettingsOpen(false);
    getStallItems(currentContext.stallId);
  }

  const openStallItemCustomize = (id) => {
    let navData = {
      targetRoute: '/provider/stallitemcustomize/' + id,
      targetTitle: "Stall Item Customize",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const getStallServiceMapList = () => {
    let inputData = {
      filters: { communityId: communityId, userId: userId, serviceStallsOnly: true }
    };
    let apiOptions = {
      url: 'stall/providerstalls/withservicemap',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data.providerStalls;
      setStallServiceMapList(result);
    })
  };

  const navigateToServices = () => {
    if (stallServiceMapList.length > 0) {
      let item: any = _.find(stallServiceMapList, { id: currentContext.stallId });
      let serviceMap = item.stallServiceMapList;
      if (serviceMap && serviceMap.length == 1) {
        session.setItem('otherCategoryItems', JSON.stringify([]));
        let navData = {
          targetRoute: '/provider/createservicerequest/' + serviceMap[0].serviceId,
          targetTitle: "Service Home",
          targetData: { stallId: serviceMap[0].stallId, stallName: item.stallName, defaultBehaviorCode: 'ItemWise', navToBack: true },
          sourceState: {},
          eventSource: '',
        };
        navigate(navData);
      }
      else {
        let navData = {
          targetRoute: '/provider/providerservices/',
          targetTitle: "Create Stall",
          targetData: {},
          sourceState: {},
          eventSource: '',
        };
        navigate(navData);
      }      
    }
  };

  useEffect(() => {
    initLookup();
    getStallServiceMapList();
  }, []);

  return (
    <div className='manageitems-ui pt-5'>
      <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
        <span className="col-11 pt-3 py-1 pl-4">
          <span>Manage Items</span>
        </span>
        {/* <span className="col-1 text-right pointer pt-3 py-1 pr-4" onClick={openStallItemSettings}>
          <i className="fas fa-cog list-settings"></i>
        </span> */}
      </div>

      <div className='mx-3 my-3'>
        <div className='row justify-content-between px-4 align-items-center'>
          <div className='pl-1'>
            <FxSelect name="stallId" variant="outlined" label="Select Stall"
              options={stalls}
              selectedValue={currentContext.stallId}
              valueField="id"
              displayField="text"
              size="small"
              style={{ width: 250 }}
              onValueChange={handleCurrentContext} />
          </div>
          <div className='pr-4 row'>
            <div className="pr-4">
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={navigateToServices}
              >
                <i className="fas fa-plus pr-2"></i>
                Add
              </Fab>
            </div>
            <div className='mt-2'>
              <i className="fas fa-cog list-settings font-24 color-primary pointer" onClick={openStallItemSettings}></i>
            </div>
          </div>
        </div>


        <div className='col-12 my-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell width="10%">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stallItems.length == 0 &&
                  <TableCell colSpan={4}>
                    <NoRecords />
                  </TableCell>
                }
                {stallItems.length > 0 && stallItems.map((row: any, i) => (
                  <TableRow>
                    <TableCell>
                      <span>
                        {row.stallItem.itemName}
                        <span>
                          {!row.stallItem.isActive &&
                            <span className='pl-4'>
                              <Badge badgeContent="Inactive" color="primary">
                              </Badge></span>
                          }
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className='itemdesc'>
                      {row.stallItem.description}
                    </TableCell>
                    <TableCell>
                      {row.stallItemTags.map((item) => (
                        <span className="tagname mr-1">{item.itemTagName}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell>
                      <div className='d-flex justify-content-between'>
                        <Tooltip title="Edit" placement='top' arrow>
                          <i className="fas fa-edit pointer" onClick={() => openStallItemForm(row.stallItem.id)}></i>
                        </Tooltip>
                        <Tooltip title="Add" placement='top' arrow>
                          <i className="fas fa-plus pointer" onClick={() => openStallItemBatchForm(row.stallItem.id)}></i>
                        </Tooltip>
                        <Tooltip title="List" placement='top' arrow>
                          <i className="fas fa-list pointer" onClick={() => openStallItemBatchList(row.stallItem.id)}></i>
                        </Tooltip>
                        <Tooltip title="Customize" placement='top' arrow>
                          <i className="fas fa-tools pointer" onClick={() => openStallItemCustomize(row.stallItem.id)}></i>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="col-12 my-3 text-center">
          {stallItems && stallItems.length == 0 &&
            <span className='font-20'><b>No items</b></span>
          }
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallItemFormOpen} onClose={handleStallItemFormClose}>
        <StallItemForm stallItemId={stallItemId} handleStallItemFormClose={handleStallItemFormClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallItemBatchFormOpen} onClose={handleStallItemBatchFormClose}>
        <StallItemBatchForm stallItemId={stallItemId} handleStallItemBatchFormClose={handleStallItemBatchFormClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallItemSettingsOpen} onClose={handleStallItemSettingsClose}>
        <StallItemSettings stallId={currentContext.stallId} handleStallItemSettingsClose={handleStallItemSettingsClose} />
      </Drawer>
    </div>
  );
}
export default ManageItems;