import React, { useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MyOrders from "../MyOrders/MyOrders";
import MyListingOrders from '../MyListingOrders/MyListingOrders';
import MyBookingOrders from "../MyBookingOrders/MyBookingOrders";

const MyOrderContainer = (props) => {
    const [value, setValue] = useState('itemSale');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="my-order-container">
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                <Tab label="Item Sales" value={"itemSale"} />
                <Tab label="Listings" value={"listing"} />
                <Tab label="Bookings" value={"bookings"} />
            </Tabs>
            {
                value === 'itemSale' &&
                <MyOrders userId={props.userId} />
            }
            {
                value === 'listing' &&
                <MyListingOrders userId={props.userId} />
            }
            {
                value === 'bookings' &&
                <MyBookingOrders userId={props.userId} />
            }            
        </div>
    );
};

export default MyOrderContainer;