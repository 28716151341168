import React, { useState, useEffect } from "react";
import './CommunityAdminDashboard.scss';
import { http, useNavigationService, FxSelect, formatService, FxCurrency } from '../../fx-core';
import * as _ from 'lodash';
import { Typography } from '@material-ui/core';

const CommunityAdminDashboard = (props) => {
  const commuAdminDashboardList: any = [
    {
      "controlCode": "communityadminpayments",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Payments",
      "sRef": "/community/payments",
      "iconRef": "fas fa-file-invoice-dollar",
      "iconRefNo": 1,
      "translateRef": "",
      "displayOrder": 1,
      "valuePrefix": "currency"
    },
    {
      "controlCode": "communityadminincome",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Monthly Income",
      "sRef": "/community/income",
      "iconRef": "fas fa-receipt",
      "iconRefNo": 2,
      "translateRef": "",
      "displayOrder": 2,
      "valuePrefix": "currency"
    },
    {
      "controlCode": "communitystalls",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Stalls",
      "sRef": "/community/stalllist",
      "iconRef": "fas fa-store",
      "iconRefNo": 3,
      "translateRef": "",
      "displayOrder": 3,
      "valuePrefix": ""
    },
    {
      "controlCode": "communityusers",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Users",
      "sRef": "/community/userlist",
      "iconRef": "fas fa-users",
      "iconRefNo": 4,
      "translateRef": "",
      "displayOrder": 4,
      "valuePrefix": ""
    },
    {
      "controlCode": "communityorders",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Orders",
      "sRef": "/community/orders",
      "iconRef": "fas fa-list",
      "iconRefNo": 5,
      "translateRef": "",
      "displayOrder": 5,
      "valuePrefix": ""
    },
    {
      "controlCode": "communityordersamount",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Order Amount",
      "sRef": "/community/orders",
      "iconRef": "fas fa-money-bill-alt",
      "iconRefNo": 1,
      "translateRef": "",
      "displayOrder": 6,
      "valuePrefix": "currency"
    },
    {
      "controlCode": "communityproviders",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Providers",
      "sRef": "/community/providerlist",
      "iconRef": "fas fa-users",
      "iconRefNo": 2,
      "translateRef": "",
      "displayOrder": 7,
      "valuePrefix": ""
    },
    {
      "controlCode": "communityservices",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Services",
      "sRef": "/community/services",
      "iconRef": "fas fa-th",
      "iconRefNo": 3,
      "translateRef": "fas fa-list",
      "displayOrder": 8
    },
    {
      "controlCode": "communityitems",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Items",
      "sRef": "/community/items",
      "iconRef": "fas fa-list",
      "iconRefNo": 4,
      "translateRef": "",
      "displayOrder": 9,
      "valuePrefix": ""
    },
    {
      "controlCode": "communitypost",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Posts",
      "sRef": "/communityposts",
      "iconRef": "fas fa-mail-bulk",
      "iconRefNo": 5,
      "translateRef": "",
      "displayOrder": 10,
      "valuePrefix": ""
    },
    {
      "controlCode": "communitycoupons",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Coupons",
      "sRef": "/community/couponlist",
      "iconRef": "fas fa-tag",
      "iconRefNo": 1,
      "translateRef": "",
      "displayOrder": 11,
      "valuePrefix": ""
    }
  ];
  const splCommuAdminDashboardList: any = [
    {
      "controlCode": "splcommunityadminpayments",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Payments",
      "sRef": "/community/specialpayments",
      "iconRef": "fas fa-file-invoice-dollar",
      "iconRefNo": 2,
      "translateRef": "",
      "displayOrder": 2,
      "valuePrefix": "currency"
    },
    {
      "controlCode": "splcommunitystalls",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Stalls",
      "sRef": "/community/stalllist",
      "iconRef": "fas fa-store",
      "iconRefNo": 3,
      "translateRef": "",
      "displayOrder": 3,
      "valuePrefix": ""
    },
    {
      "controlCode": "splcommunityorders",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Orders",
      "sRef": "/community/orders",
      "iconRef": "fas fa-list",
      "iconRefNo": 5,
      "translateRef": "",
      "displayOrder": 5,
      "valuePrefix": ""
    },
    {
      "controlCode": "splcommunityordersamount",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Order Amount",
      "sRef": "/community/orders",
      "iconRef": "fas fa-money-bill-alt",
      "iconRefNo": 1,
      "translateRef": "",
      "displayOrder": 6,
      "valuePrefix": "currency"
    },
    {
      "controlCode": "splcommunityproviders",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Providers",
      "sRef": "/community/providerlist",
      "iconRef": "fas fa-users",
      "iconRefNo": 2,
      "translateRef": "",
      "displayOrder": 7,
      "valuePrefix": ""
    },
    {
      "controlCode": "splcommunitypost",
      "parentControlCode": null,
      "controlType": "MAIN",
      "controlPosition": "INNER",
      "display": "Posts",
      "sRef": "/communityposts",
      "iconRef": "fas fa-mail-bulk",
      "iconRefNo": 5,
      "translateRef": "",
      "displayOrder": 10,
      "valuePrefix": ""
    }
  ];
  const { navigate, pageState } = useNavigationService();
  let defaultFilter = {
    communityId: 0
  };
  const [filter, setFilter] = useState(defaultFilter);
  const [communityList, setCommunityList] = useState([]);
  const [result, setResult] = useState({});
  const [communityTypeId, setCommunityTypeId] = useState(1);

  const handleInputChange = (item) => {
    if(item.name == "communityId") {
      handleComunityChange(item.value, communityList);
    }
    else {
      handleFilterChange({ [item.name]: item.value });      
    }
  };

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getUserRoleCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getUserRoleCommunitiesCallBack(res) });
  };

  const getUserRoleCommunitiesCallBack = (res) => {
    let commuId = 0;
    let communityList = res.data;
    setCommunityList(communityList);
    if (pageState && pageState.selCommunity) {
      commuId = pageState.selCommunity.id;
      handleComunityChange(commuId, communityList);
    }
    else if (communityList.length > 0) {
      commuId = communityList[0].id;
      handleComunityChange(commuId, communityList);
    }
  };

  const handleComunityChange = (id, communities) => {
    let commuTypeId = 1
    let commuObj: any = communities.find((c: any) => c.id == id);
    if(commuObj) {
      commuTypeId = commuObj.communityTypeId;
    }
    let filterObj = {
      communityId: id
    };
    handleFilterChange(filterObj);
    setCommunityTypeId(commuTypeId);
    if (commuTypeId == 1) { //1-Normal
      getAdminDashboardDetails(filterObj);
    }
    else if (commuTypeId == 2) { //2-Special
      getSplAdminDashboardDetails(filterObj);
    }
  };

  const navToDetail = (e, item: any) => {
    e.stopPropagation();
    if (item.sRef) {
      const selCommunity: any = _.find(communityList, { id: filter.communityId });
      let navData = {
        targetRoute: item.sRef,
        targetTitle: item.display,
        targetData: { communityId: selCommunity.id, communityName: selCommunity.name, parent: 'COMMADMIN' },
        sourceState: { selCommunity },
        eventSource: '',
      };
      navigate(navData);
    }
  };

  const navToReviews = () => {
    const selCommunity: any = _.find(communityList, { id: filter.communityId });
    let navData = {
      targetRoute: "/community/reviewlist",
      targetTitle: 'Reviews',
      targetData: { communityId: selCommunity.id, communityName: selCommunity.name },
      sourceState: { selCommunity },
      eventSource: '',
    };
    navigate(navData);
  }

  const navToTrueFansBoard = () => {
    const selCommunity: any = _.find(communityList, { id: filter.communityId });
    let navData = {
      targetRoute: "/community/truefansboard",
      targetTitle: 'True Fans LeaderBoard',
      targetData: { communityId: selCommunity.id, communityName: selCommunity.name },
      sourceState: { selCommunity },
      eventSource: '',
    };
    navigate(navData);
  }

  const getAdminDashboardDetails = (data) => {
    const monthStartDate = formatService.getMonthStartDate();
    const yearStartDate = formatService.getYearStartDate();
    const toDate = formatService.getCurrentDateForServer();
    let inputData = {
      ds: {
        resultset: [
          { key: "communityadminpayments", type: "single" },
          { key: "communityadminincome", type: "single" },
          { key: "communitystalls", type: "single" },
          { key: "communityusers", type: "single" },
          { key: "communityorders", type: "single" },
          { key: "communityordersamount", type: "single" },
          { key: "communityproviders", type: "single" },
          { key: "communityitems", type: "single" },
          { key: "communitypost", type: "single" },
          { key: "communitycoupons", type: "single" },
          { key: "communityservices", type: "single"}
        ]
      },
      data: {
        communityId: data.communityId,
        fromDate: formatService.getDateStringForServer(yearStartDate),
        toDate: toDate,
        monthFromDate: formatService.getDateStringForServer(monthStartDate),
        roleCode: "COMMADMIN"
      }
    };
    let apiOptions: any = {
      url: `general/dbo/GetAdminDashboardDetails`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAdminDashboardDetailsCallBack(res);
    });
  };

  const getAdminDashboardDetailsCallBack = (res) => {
    let result = res.data;
    setResult(result);
  }

  const getSplAdminDashboardDetails = (data) => {
    const monthStartDate = formatService.getMonthStartDate();
    const yearStartDate = formatService.getYearStartDate();
    const toDate = formatService.getCurrentDateForServer();
    let inputData = {
      ds: {
        resultset: [
          { key: "splcommunityadminpayments", type: "single" },
          { key: "splcommunitystalls", type: "single" },
          { key: "splcommunityorders", type: "single" },
          { key: "splcommunityordersamount", type: "single" },
          { key: "splcommunityproviders", type: "single" },
          { key: "splcommunitypost", type: "single" }
        ]
      },
      data: {
        communityId: data.communityId,
        fromDate: formatService.getDateStringForServer(yearStartDate),
        toDate: toDate,
        monthFromDate: formatService.getDateStringForServer(monthStartDate),
        roleCode: "SPECIALCOMMADMIN"
      }
    };
    let apiOptions: any = {
      url: `general/dbo/GetSpecialAdminDashboardDetails`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getSplAdminDashboardDetailsCallBack(res);
    });
  };

  const getSplAdminDashboardDetailsCallBack = (res) => {
    let result = res.data;
    setResult(result);
  }

  useEffect(() => {
    getUserRoleCommunities();
  }, []);

  return (
    <div className="communityadmindashboard-ui pt-5">
      <div className="title-font custom-head pl-4 pt-5">
        <Typography color="primary" className="breadcrumbs-style">Admin Dashboard</Typography>
      </div>
      <div className="content-container">
        <div className="content">
          {/* <div className="row col-12">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="col-4" style={{ marginTop: "50px" }}>
                <FxSelect name="community" variant="outlined"
                  label="Community"
                  options={communityList}
                  selectedValue={selCommunityId}
                  valueField="id"
                  displayField="name"
                  size="small"
                  onValueChange={handleCommunityChange} />
              </div>
              <div className="row p-4 ourservicerow">
                <div className='col-3 servicecard pointer' onClick={navToCommunityPost}>
                  <div className="communitypost wd40">
                    <i className="fas fa-mail-bulk"></i>
                  </div>
                  <div className="cardlbl pl-3">Community Posts</div>
                </div>
                <div className='col-3 servicecard pointer' onClick={navToCoupons}>
                  <div className="coupons wd40">
                    <i className="fas fa-tag"></i>
                  </div>
                  <div className="cardlbl pl-3">Coupons</div>
                </div>
              </div>
              <div className="row p-4 ourservicerow">
                <div className='col-3 servicecard pointer' onClick={navToPendingPayments}>
                  <div className="pendingpayments wd40">
                    <i className="fas fa-money-bill-alt"></i>
                  </div>
                  <div className="cardlbl pl-3">Pending Payments</div>
                </div>
                <div className='col-3 servicecard pointer' onClick={navToProviders}>
                  <div className="providers wd40">
                    <i className="fas fa-users"></i>
                  </div>
                  <div className="cardlbl pl-3">Providers</div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div> */}
          <div className="col-4" style={{ marginTop: "20px" }}>
            <FxSelect name="communityId" variant="outlined"
              label="Community"
              options={communityList}
              selectedValue={filter.communityId}
              valueField="id"
              displayField="name"
              size="small"
              onValueChange={handleInputChange} />
          </div>
          <div className="row col-7 align-items-center mt-5 mb-3">
            <div className="col-7 d-flex align-items-center txt-font-weight pointer" onClick={navToTrueFansBoard}>
              <span>True Fans</span>
              <span className="pl-2">Leader board</span>
              <span className="pl-2"><i className="fas fa-play play-icon font-20 pt-1 pointer"></i></span>
            </div>
            <div className="col-5 d-flex align-items-center txt-font-weight pointer" onClick={navToReviews}>
              <span>Reviews</span>
              <span className="pl-2"><i className="fas fa-play play-icon font-20 pt-1 pointer"></i></span>
            </div>
          </div>
          {
            communityTypeId == 1 &&
            <div className="row col-7 align-items-center">
              {
                commuAdminDashboardList.map((item, i) => (
                  <div key={i} className='col-4' onClick={(e) => navToDetail(e, item)}>
                    <div className="servicecard-new pointer pl-3">
                      {item.iconRefNo == 1 && <span className="px-2 py-1 mr-2 number1">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 2 && <span className="px-2 py-1 mr-2 number2">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 3 && <span className="px-2 py-1 mr-2 number3">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 4 && <span className="px-2 py-1 mr-2 number4">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 5 && <span className="px-2 py-1 mr-2 number5">
                        <i className={item.iconRef}></i>
                      </span>}
                      {
                        item.valuePrefix == 'currency' &&
                        <span className="cardlbl-new">
                          {item.display}
                          {
                            result[item.controlCode] &&
                            <div className="pl-5">
                              <FxCurrency value={result[item.controlCode][item.controlCode]} />
                            </div>
                          }
                        </span>
                      }
                      {
                        !item.valuePrefix &&
                        <span className="cardlbl-new">
                          {item.display}
                          {
                            result[item.controlCode] &&
                            <div className="pl-5">
                              {result[item.controlCode][item.controlCode]}
                            </div>
                          }
                        </span>
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          }
          {
            communityTypeId == 2 &&
            <div className="row col-7 align-items-center">
              {
                splCommuAdminDashboardList.map((item, i) => (
                  <div key={i} className='col-4' onClick={(e) => navToDetail(e, item)}>
                    <div className="servicecard-new pointer pl-3">
                      {item.iconRefNo == 1 && <span className="px-2 py-1 mr-2 number1">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 2 && <span className="px-2 py-1 mr-2 number2">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 3 && <span className="px-2 py-1 mr-2 number3">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 4 && <span className="px-2 py-1 mr-2 number4">
                        <i className={item.iconRef}></i>
                      </span>}
                      {item.iconRefNo == 5 && <span className="px-2 py-1 mr-2 number5">
                        <i className={item.iconRef}></i>
                      </span>}
                      {
                        item.valuePrefix == 'currency' &&
                        <span className="cardlbl-new">
                          {item.display}
                          {
                            result[item.controlCode] &&
                            <div className="pl-5">
                              <FxCurrency value={result[item.controlCode][item.controlCode]} />
                            </div>
                          }
                        </span>
                      }
                      {
                        !item.valuePrefix &&
                        <span className="cardlbl-new">
                          {item.display}
                          {
                            result[item.controlCode] &&
                            <div className="pl-5">
                              {result[item.controlCode][item.controlCode]}
                            </div>
                          }
                        </span>
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CommunityAdminDashboard;