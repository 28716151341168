import React, { useEffect, useState } from 'react';
import './StallItemGroupForm.scss';
import { FxInput, http, AlertService } from '../../fx-core';
import { Button } from '@material-ui/core';
import _ from "lodash";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const StallItemGroupForm = (props) => {

  let defaultItem = {
    minCount: 0,
    maxCount: 0,
    id: props.stallItemCustomizeHeaderId > 0 ? props.stallItemCustomizeHeaderId : 0,
    groupTypeId: 0,
    stallItemId: props.stallItemId,
    groupName: ""
  };
  let defaultGroupTypes = [
    { text: 'Single', name: 'groupType', value: 1, checked: false },
    { text: 'Multi', name: 'groupType', value: 2, checked: false }
  ];
  const [item, setItem] = useState(defaultItem);
  const [error, setError] = useState(null);
  const [allGroupTypes, setAllGroupTypes] = useState(defaultGroupTypes);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getGroupHeaderById = () => {
    if (item.id > 0) {
      let inputData = {
        id: item.id
      }
      let apiOptions: any = {
        url: `stallitemcustomize/${inputData.id}`
      };
      http.get(apiOptions).then(res => {
        const result = res.data;
        getGroupHeaderByIdCallback(result);
      })
    }
  };

  const getGroupHeaderByIdCallback = (res) => {
    let lookupItem = _.find(allGroupTypes, { value: res.groupTypeId });
    if (lookupItem) {
      lookupItem.checked = true;
    }
    setItem(res);
  }

  const selectedGroupTypeChange = (grpTypeId) => {
    const data = { groupTypeId: grpTypeId };
    setItem(prevState => {
      return { ...prevState, ...data }
    });
    let tmpPayModes = allGroupTypes.map((item) => {
      if (item.value === grpTypeId) {
        item.checked = true;
      }
      else {
        item.checked = false;
      }
      return item;
    });
    setAllGroupTypes(tmpPayModes);
    if (grpTypeId > 0) {
      handleItemChange({ "minCount": 0 });
      handleItemChange({ "maxCount": 0 });
    }
  }

  const saveGroupHeader = () => {
    if (!item.groupName) {
      setError("Group name is required");
      return;
    }
    item.stallItemId = props.stallItemId;
    item.minCount = item.minCount ? item.minCount : 0;
    item.maxCount = item.maxCount ? item.maxCount : 0;
    let apiOptions: any = {
      url: `stallitemcustomize/save`,
      data: item
    };
    http.post(apiOptions).then(res => {
      AlertService.showSuccessMsg();
      props.handleGroupFormClose();
    })
  };

  const handleClose = () => {
    props.handleGroupFormClose();
  }

  useEffect(() => {
    getGroupHeaderById();
  }, []);

  return (
    <div className='stallitemgroupform-ui'>
      <div className='row title-font py-1 mx-auto'>
        <span className="col-11">
          Stall Item Customize Form
        </span>
        <span className="col-1">
          <span className='text-center'>
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
          </span>
        </span>
      </div>

      <div className='pt-3'>
        <div className='col-12 p-3'>
          <FxInput name="groupName" variant="outlined" fullWidth size="small" label="Name"
            value={item.groupName} onValueChange={handleInputChange} />
          {error && (
            <div className="error">{error}</div>
          )}
        </div>

        <div className='col-12 p-3'>
          <RadioGroup row aria-label="position" name="position" defaultValue="eight">
            {
              allGroupTypes.length > 0 && allGroupTypes.map((itemObj, i) => (
                <div className="text-center" key={i}>
                  <span className="col-6">
                    <FormControlLabel
                      name={itemObj.name}
                      value={itemObj.value}
                      control={<Radio color="primary" size="small" />}
                      label={itemObj.text}
                      checked={itemObj.checked}
                      onChange={() => selectedGroupTypeChange(itemObj.value)}
                    />
                  </span>
                </div>
              ))
            }

          </RadioGroup>
        </div>

        <div className='col-12 p-3'>
          {item.groupTypeId == 1 &&
            <FxInput name="minCount" variant="outlined" fullWidth size="small" label="Min Count"
              value={item.minCount} disabled />
          }
          {item.groupTypeId != 1 &&
            <FxInput type="number" name="minCount" variant="outlined" fullWidth size="small" label="Min Count"
              value={item.minCount} onValueChange={handleInputChange} />
          }
        </div>

        <div className='col-12 p-3'>
          {item.groupTypeId == 1 &&
            <FxInput name="maxCount" variant="outlined" fullWidth size="small" label="Max Count"
              value={item.maxCount} disabled />
          }
          {item.groupTypeId != 1 &&
            <FxInput type="number" name="maxCount" variant="outlined" fullWidth size="small" label="Max Count"
              value={item.maxCount} onValueChange={handleInputChange} />
          }
        </div>

        <div className="text-right p-3">
          <span>
            <Button variant="contained" size="small" onClick={handleClose}>Cancel</Button>
          </span>
          <span className="pl-3">
            <Button variant="contained" size="small" color="primary" onClick={saveGroupHeader}>Save</Button>
          </span>
        </div>
      </div>
    </div>
  );
}
export default StallItemGroupForm;