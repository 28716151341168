import React, { useEffect, useState } from 'react';
import { FxInput, FxSelect } from '../../fx-core';
import './FxMobile.styles.scss';

export const FxMobile = (props: any) => {

  let defaultForm = {
    countryCode: "+91",
    mobile: ""
  };
  const [item, setItem] = useState(defaultForm);

  const handleInputChange = (item: any) => {
    handleItemChange({ [item.name]: item.value });
    if (item.name == "mobile") {
      getMobileValue(item.value);
    }
    else if (item.name == "countryCode") {
      props.onValueChange({ "name": "countryCode", "value": item.value });
    }
  }

  const onChange = (event: any) => {
    if (event.value.length <= 10) { // allow only 10 characters
      const data = { name: event.name, value: event.value }
      handleInputChange(data);
    }
  }

  const handleItemChange = (data: any) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getMobileValue = (val: any) => {
    var mobileValue = "";
    if (item.countryCode && val) {
      mobileValue = item.countryCode + val;
    }
    props.onValueChange({ "name": "mobile", "value": val });
  }

  const splitAndComputeMobileNo = () => {
    if (props.value) {
      let mobileValue = props.value;
      let mobileNumLength = mobileValue.length;
      let countryCd = mobileValue.slice(0, 3);
      if (countryCd == "+91") {
        handleItemChange({ "countryCode": "+91" });
        handleItemChange({ "mobile": mobileValue.slice(3, mobileNumLength) });
        props.onValueChange({ "name": "mobile", "value": mobileValue.slice(3, mobileNumLength) });
        props.onValueChange({ "name": "countryCode", "value": "+91" });
        return;
      }
      if (mobileValue && !item.mobile) {
        handleItemChange({ "mobile": mobileValue });
        props.onValueChange({ "name": "mobile", "value": mobileValue });
      }
    }
  }

  useEffect(() => {
    splitAndComputeMobileNo();
  }, [props.value]);

  return (
    <>
      <div className="fx-mobile row col-12 p-0 pt-2 m-0">
        <div className="col-4 pl-0">
          <FxSelect name="countryCode" variant="outlined" label="Code"
            options={props.countryCode}
            selectedValue={item.countryCode}
            valueField="code"
            displayField="text"
            onValueChange={handleInputChange} />
        </div>
        <div className="col-8 pr-0">
          <FxInput name="mobile" variant="outlined" label="Mobile" size="small" maxLength="10" type="number"
            value={item.mobile} onValueChange={onChange} style={props.style} />
        </div>
      </div>
    </>
  );
};