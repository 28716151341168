import React from 'react';
import './StallCard.scss';
import Card from '@material-ui/core/Card';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import dummyImage from "./../../../assets/images/dummy.png";
import Chip from '@material-ui/core/Chip';
import verifyTick from "./../../../assets/img/verifytick.png";

const StallCard = (props: any) => {
  return (
    <div className="item-ui" onClick={() => props.navToStall(props.item.slug)}>

      {/* Desktop View */}
      <div className="deskview pt-5">
        <Card style={{ width: "260px", height: "250px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
          <div className="row justify-content-center">
            {/* <img src={props.item.imageUrl} alt="Item" width="200" height="150" className="itemimage" /> */}
            {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
            {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
          </div>
          <div>
            <div className="font-22 itemname-style text-center">
              {props.item.name}
              {
                props.item.isVerified &&
                <span className='ml-1'>
                  <img src={verifyTick} width="20" height="20" />
                </span>
              }
            </div>
            <div className="font-12 text-success shopstatus-style text-center">
              {props.item.stallOpenStatus}
            </div>
          </div>

          <div className="row pr-3 pl-4">
            {
              props.item.categories && props.item.categories.length > 0 && props.item.categories.map((category, i) => (
                <div className="pr-2 pb-2">
                  <div className="tagname-style font-12 px-1 py-0">
                    {category}
                  </div>
                </div>
              ))
            }
          </div>
          {/* <div className="font-13 row pl-4">
            {props.item.address}
          </div>
          <div className="row align-items-center pl-4 font-13 pt-1">
            <span><i className="fas fa-phone-alt"></i></span>
            {props.item.providerMobile &&
              <span className="pl-1 font-12">{props.item.providerMobile}</span>
            }
            {!props.item.providerMobile &&
              <span className="pl-1">-----</span>
            }
          </div>
          <div className="row align-items-center pl-4 font-13 pt-1">
            <span><i className="fas fa-envelope"></i></span>
            {props.item.providerEmail &&
              <span className="pl-1 font-12">{props.item.providerEmail}</span>
            }
            {!props.item.providerEmail &&
              <span className="pl-1">-----</span>
            }
          </div>
          <div className="row align-items-center pl-4 font-13 pt-1">
            <span><i className="fas fa-globe"></i></span>
            {props.item.website &&
              <span className='pl-1 font-12'>{props.item.website}</span>
            }
            {!props.item.website &&
              <span className='pl-1'>-----</span>
            }
          </div> */}


          {/* <div className="row pl-4">
                    {
                        props.item.deliveries && props.item.deliveries.length > 0 && props.item.deliveries.map((type, i) => (
                            <div className="pr-2">
                                <div className="deliveryname-style font-12 px-1 py-0">
                                    {type}
                                </div>
                            </div>
                        ))
                    }
                </div> */}
          {/* <div className="d-flex pt-1">
            {
              <div className="pr-2 pl-1">
                <div className="deliveryname-style font-11 px-1 py-0">
                  {props.item.isDeliveryEnabled &&
                    <span>
                      Door Delivery
                    </span>
                  }
                  {props.item.isDeliveryEnabled && props.item.isPickupEnabled && <span className='pr-2'>,</span>}
                  {props.item.isPickupEnabled &&
                    <span>
                      Take Away
                    </span>
                  }
                </div>
              </div>
            }
          </div> */}

        </Card>
      </div>


      {/* Tablet View */}
      <div className="tabview pt-2">
        <Card style={{ height: "130px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
          <div className="row pl-1">
            <div className="col-3">
              {/* <img src={props.item.imageUrl} alt="Item" width="200" height="150" className="itemimage" /> */}
              {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
              {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
            </div>
            <div className="pl-5 col-9">
              <div className="font-18 itemname-style">
                {props.item.name}
                {
                  props.item.isVerified &&
                  <span className='ml-1'>
                    <img src={verifyTick} width="20" height="20" />
                  </span>
                }
              </div>
              {/* <div className="font-12 pb-2 text-success shopstatus-style">
                                {props.item.stallOpenStatus}
                            </div> */}
              {/* <div className="row pl-1 pb-2">
                {
                  <div className="pr-2">
                    <div className="deliveryname-style font-12 px-1 py-0">
                      {props.item.isDeliveryEnabled &&
                        <span>
                          Door Delivery
                        </span>
                      }
                      {props.item.isDeliveryEnabled && props.item.isPickupEnabled && <span>,</span>}
                      {props.item.isPickupEnabled &&
                        <span className="pl-2">
                          Take Away
                        </span>
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="row pl-3">
                {
                  props.item.categories && props.item.categories.length > 0 && props.item.categories.map((category, i) => (
                    <div className="pr-2 pb-2">
                      <div className="tagname-style font-12 px-1 py-0">
                        {category}
                      </div>
                    </div>
                  ))
                }
              </div> */}
            </div>
          </div>


          {/* <div className="font-13 row pl-4 pr-2">
            {props.item.address}
          </div> */}
          {/* <div className="row pl-4 font-13 pt-1">
                        <span> <PhoneIcon fontSize="inherit" /></span>
                        <span className="pl-1 font-12">{props.item.providerMobile}</span>
                    </div>
                    <div className="row pl-4 font-13 pt-1">
                        <span> <EmailIcon fontSize="inherit" /></span>
                        <span className="pl-1 font-12">{props.item.providerEmail}</span>
                    </div>

                    {props.item.website &&
                        <div className="row pl-4 font-13 pt-1">
                            <span><LanguageIcon fontSize='inherit' /></span>
                            <span className='pl-1 font-12'>{props.item.website}</span>
                        </div>
                    } */}
          {/* <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
            <div className="row pl-3">
              <span> <PhoneIcon fontSize="inherit" /></span>
              <span className="pl-1">{props.item.providerMobile}</span>
            </div>
            <div className="row px-0">
              <span> <EmailIcon fontSize="inherit" /></span>
              <span className="pl-1">{props.item.providerEmail}</span>
            </div>
          </div>
          {props.item.website &&
            <div className="row pl-3">
              <div className="pr-2 font-12 px-1 py-0">
                <span><LanguageIcon fontSize='inherit' /></span>
                <span className="pl-1">{props.item.website}</span>
              </div>
            </div>
          } */}
          {/* <div className="row pl-4">
                        {
                             props.item.deliveries && props.item.deliveries.length > 0 && props.item.deliveries.map((type, i) => (
                            <div className="pr-2">
                                <div className="deliveryname-style font-12 px-1 py-0">
                                    {type}
                                </div>
                            </div>
                            ))
                        }
                    </div> */}

        </Card>
      </div>


      {/* Mobile View */}
      <div className="mobileview pt-2">
        <Card style={{ height: "130px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
          <div className="row pl-1">
            <div className="col-3">
              {/* <img src={props.item.imageUrl} alt="Item" width="200" height="150" className="itemimage" /> */}
              {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
              {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
            </div>
            <div className="pl-4 col-9">
              <div className="font-18 itemname-style">
                {props.item.name}
                {
                  props.item.isVerified &&
                  <span className='ml-1'>
                    <img src={verifyTick} width="20" height="20" />
                  </span>
                }
              </div>
              {/* <div className="font-12 pb-2 text-success shopstatus-style">
                                {props.item.stallOpenStatus}
                            </div> */}
              {/* <div className="row pl-1 pb-2">
                {
                  <div className="pr-2">
                    <div className="deliveryname-style font-12 px-1 py-0">
                      {props.item.isDeliveryEnabled &&
                        <span>
                          Door Delivery
                        </span>
                      }
                      {props.item.isDeliveryEnabled && props.item.isPickupEnabled && <span>,</span>}
                      {props.item.isPickupEnabled &&
                        <span className="pl-2">
                          Take Away
                        </span>
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="row pl-3">
                {
                  props.item.categories && props.item.categories.length > 0 && props.item.categories.map((category, i) => (
                    <div className="pr-2 pb-2">
                      <div className="tagname-style font-12 px-1 py-0">
                        {category}
                      </div>
                    </div>
                  ))
                }
              </div> */}
            </div>
          </div>


          {/* <div className="font-13 row pl-4">
            {props.item.address}
          </div>
          <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
            <div className="row pl-3">
              <span> <PhoneIcon fontSize="inherit" /></span>
              <span className="pl-1">{props.item.providerMobile}</span>
            </div>
            <div className="row px-0">
              <span> <EmailIcon fontSize="inherit" /></span>
              <span className="pl-1">{props.item.providerEmail}</span>
            </div>
          </div>
          {props.item.website &&
            <div className="row pl-3">
              <div className="pr-2 font-12 px-1 py-0">
                <span><LanguageIcon fontSize='inherit' /></span>
                <span className="pl-1">{props.item.website}</span>
              </div>
            </div>
          } */}
          {/* <div className="row pl-4">
                        {
                             props.item.deliveries && props.item.deliveries.length > 0 && props.item.deliveries.map((type, i) => (
                            <div className="pr-2">
                                <div className="deliveryname-style font-12 px-1 py-0">
                                    {type}
                                </div>
                            </div>
                            ))
                        }
                    </div> */}

        </Card>
      </div>
    </div>
  );
}
export default StallCard;