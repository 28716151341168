import React, { useState } from 'react';
import './StallCouponContainer.scss';
import StallCouponList from '../StallCouponList/StallCouponList';
import StallCouponForm from '../StallCouponForm/StallCouponForm';

const StallCouponContainer = (props) => {

    const [currentStep, setCurrentStep] = React.useState(1);
    const [couponId, setCouponId] = useState(0);

    const handleClose = () => {
        props.handleStallCouponContainerClose();
    };

    const handleAddOrEditCoupon = (id) => {
        setCouponId(id);
        setCurrentStep(2);
    };

    const handleBackToCouponList = () => {
        setCouponId(0);
        setCurrentStep(1);
    }

    return (
        <div className='stallcouponcontainer-ui'>
            <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
                <span className="col-11">
                    <span>{props.stallName} Coupons</span>
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>
            <div>
                {
                    currentStep == 1 &&
                    <StallCouponList stallId={props.stallId} handleAddOrEditCoupon={handleAddOrEditCoupon} />
                }
                {
                    currentStep == 2 &&
                    <StallCouponForm id={couponId} stallId={props.stallId} handleBackToCouponList={handleBackToCouponList} />
                }
            </div>
        </div>
    )
};

export default StallCouponContainer;