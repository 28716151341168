import React from 'react';
import './ProviderServiceCard.scss';
import Card from '@material-ui/core/Card';
//import dummyImage from "./../../../assets/images/dummy.png";
import dummyImage from "./../../../assets/img/defaultcategory.png";
import catBgImage from "./../../../assets//img/categorybackground.png";
import deskcardbackground from "../../../assets/deskcardbackground.svg";

const ProviderServiceCard = (props: any) => {
  return (
    <div className="providerservicecard-ui">

      {/* Desktop View */}
      <div className="deskview pt-5" onClick={() => props.navToProviderSetup(props.item)}>
        <Card className="pointer pt-3">
          {/* <div className='text-center pt-2'>
            {!props.item.image && <img src={dummyImage} alt="Service Image" className="dummyimg-style"></img>}
            {props.item.image && <img src={props.item.image} alt="Service Image" width="50" height="50"></img>}
          </div> */}
          <div className='text-center' style={{
                                backgroundImage: "url(" + catBgImage + ")", backgroundRepeat: "no-repeat", color: "#6D4B40",
                                backgroundSize: "47px auto", position: "relative", height: "50px", backgroundPosition: "center"
                            }}>
                                {!props.item.image && <img src={dummyImage} alt="img" className="dummyimg-style" ></img>}
                                {props.item.image && <img src={props.item.image} alt="img" className="img-style"></img>}
                            </div>          
          <div>
            <div className="font-18 text-center pt-3 pb-4">{props.item.serviceName}</div>
            {/* <div className="font-16 pt-2 pl-3 pr-3 text-center" style={{ height: "100px" }}>
              {props.item.description}
            </div> */}
          </div>
        </Card>
      </div>
    </div>
  );
}
export default ProviderServiceCard;