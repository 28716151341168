import React, { useEffect, useState } from 'react';
import './CommunityServices.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog, Breadcrumbs, Typography, Link } from '@material-ui/core';
import { http, FxSelect, useNavigationService } from '../../fx-core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const CommunityServices = (props) => {
    const { routeData, back } = useNavigationService();
    let defaultFilter: any = {
        communityId: routeData.communityId
    };

    let defaultService: any = {
        id: 0
    };

    const [services, setServices] = useState([]);
    const [filters, setFilters] = useState(defaultFilter);
    const [communityList, setCommunityList] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [action, setAction] = useState("");
    const [selService, setSelService] = useState(defaultService);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            communityId: filters.communityId
        }
        if (item.name == 'communityId') {
            filterObj.communityId = item.value;
        }
        getServicesWithMap(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getMyAdminCommunities = () => {
        let apiOptions: any = {
            url: 'community/role/list',
            data: {}
        };
        http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
    };

    const getMyAdminCommunitiesCallBack = (res) => {
        let result = res.data;
        let commuArr: any = [];
        for (let item of result) {
            let itemObj: any = {
                id: item.id,
                text: item.name
            };
            commuArr.push(itemObj);
        }
        setCommunityList(commuArr);
    };

    const getServicesWithMap = (data) => {
        let apiOptions: any = {
            url: `portal/community/services/${data.communityId}`
        };
        http.get(apiOptions).then(res => {
            getServicesWithMapCallBack(res);
        });
    };

    const getServicesWithMapCallBack = (res) => {
        let result = res.data;
        setServices(result);
    }

    const confirmAction = (item, action) => {
        setConfirmOpen(true);
        setMessage("Are you sure to " + action + " " + item.serviceName + "?");
        setSelService(item)
        setAction(action);
    }

    const handleDialogClose = () => {
        setConfirmOpen(false);
        setSelService(defaultService);
        setMessage("");
        setAction("");
    };

    const onConfirmCallback = () => {
        const selSer = selService;
        if (action == "enable") {
            enableService(selSer)
        }
        else if (action == "disable") {
            disableService(selSer)
        }
        handleDialogClose()
    };

    const enableService = (item) => {
        let inputData = {
            communityId: filters.communityId,
            serviceId: item.id
        };
        let apiOptions: any = {
            url: `service/community/enable`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveCallback(res);
        });
    }

    const disableService = (item) => {
        let inputData = {
            communityId: filters.communityId,
            serviceId: item.id
        };
        let apiOptions: any = {
            url: `service/community/disable`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveCallback(res);
        });
    }

    const saveCallback = (res) => {
        getServicesWithMap(filters);
    }

    const navToBack = () => {
        back();
    };

    useEffect(() => {
        getMyAdminCommunities();
        getServicesWithMap(filters);
    }, []);

    return (
        <div className='communityservices-ui pt-4 mt-3'>
            <div className="title-font custom-head pl-4 pt-5">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                        <b>{routeData.communityName}</b>
                    </Link>
                    <Typography color="primary" className="breadcrumbs-style">Services</Typography>
                </Breadcrumbs>
            </div>
            <div className='row my-4 mx-2'>
                <div className='col-3'>
                    <FxSelect name="communityId" variant="outlined"
                        label="Community"
                        options={communityList}
                        selectedValue={filters.communityId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        style={{ width: 260 }}
                        onValueChange={handleInputChange} />
                </div>
            </div>
            <div className='row my-4 mx-4 pt-2'>
                <TableContainer component={Paper}>
                    <Table className="table-striped" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Default</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                services.length > 0 && services.map((row: any, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <span>{row.serviceCode}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span>{row.serviceName}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span>{row.defaultBehaviorCode}</span>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                !row.isCommunityMapped &&
                                                <Button variant='outlined' size='small' className='button-style' onClick={() => confirmAction(row, 'enable')}>Enable</Button>
                                            }
                                            {
                                                row.isCommunityMapped &&
                                                <Button variant='outlined' size='small' className='button-style' onClick={() => confirmAction(row, 'disable')}>Disable</Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog
                open={confirmOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
            </Dialog>
        </div>
    );
}
export default CommunityServices;