import React, { useEffect, useState } from 'react';
import './LiveStreamList.scss';
import { http, FxDateTimeDisplay, FxDateDisplay, AlertService, useNavigationService, FxSelect, localstorage } from '../../fx-core';
import { Button, Dialog, Drawer, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, Fab, TableRow, Link } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import LiveStreamForm from '../LiveStreamForm/LiveStreamForm';
import ShareOptions from '../../LocalDrCard/ShareOptions/ShareOptions';
import { NoRecords } from '../../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const LiveStreamList = (props) => {
  const classes = styles();
  const Razorpay = useRazorpay();
  
  const { navigate, routeData, back } = useNavigationService();
  let defaultContext = {
    stallId: 0,
    liveStreamStatusId: 0
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [liveStreamFormOpen, setLiveStreamFormOpen] = useState(false);
  const [liveStreamList, setLiveStreamList] = useState([]);
  const [liveStreamId, setLiveStreamId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");
  const [stalls, setStalls] = useState([]);
  const [liveStreamStatus, setLiveStreamStatus] = useState([]);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [shareOpen, setShareOpen] = useState(false);

  const handleCurrentContext = (item) => {
    handleCurrentContextChange({ [item.name]: item.value });
    let options = { filter: { [item.name]: item.value } };
    getLiveStreamList(options);
  };

  const handleCurrentContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        ProviderStalls: { default: true },
        LiveStreamStatus: { default: true }
      },
      filters: {
        userId: userId,
        communityId: communityId
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initLookupCallBack(response);
    })
  }

  const initLookupCallBack = (res) => {
    let result = res.data;
    setStalls(result.ProviderStalls);
    setLiveStreamStatus(result.LiveStreamStatus);
    let contextObj = {
      stallId: routeData.stallId > 0 ? routeData.stallId : -1,
      liveStreamStatusId: -1
    };
    handleCurrentContextChange(contextObj);
    let options = { filter: contextObj };
    getLiveStreamList(options);
  };

  const getLiveStreamList = (options: any = {}) => {
    let selectedStatusId = currentContext.liveStreamStatusId;
    let selectedStallId = currentContext.stallId;
    if (options && options.filter) {
      selectedStatusId = options.filter.liveStreamStatusId ? options.filter.liveStreamStatusId : currentContext.liveStreamStatusId;
      selectedStallId = options.filter.stallId ? options.filter.stallId : currentContext.stallId;
    }
    let inputData = {
      stallId: selectedStallId,
      liveStreamStatusId: selectedStatusId
    }
    let apiOptions: any = {
      url: `livestream/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setLiveStreamList(result);
    })
  };

  const openLiveStreamForm = (id) => {
    setLiveStreamId(id);
    setLiveStreamFormOpen(true);
  }

  const handleLiveStreamFormClose = () => {
    setLiveStreamFormOpen(false);
    setLiveStreamId(0);
    getLiveStreamList();
  }

  const confirmToStartLiveStream = (item) => {
    setLiveStreamId(item.id);
    setConfirmOpen(true);
    setMessage("Are you sure to start live streaming " + item.liveStreamName + "?");
    setAction("start");
  }

  const handleConfirmDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if (action == "start") {
      startLiveStream();
    }
    else if (action == "delete") {
      deleteLiveStream();
    }
  }

  const startLiveStream = () => {
    let apiOptions: any = {
      url: `livestream/${liveStreamId}/startlivestream`
    };
    http.get(apiOptions).then(response => {
      if (response && response.data) {
        startLiveStreamCallback(response.data);
      }
    })
  };

  const startLiveStreamCallback = (data: any) => {
    joinLiveStream(data.lh);
  }

  const prepareToJoin = (id) => {
    let apiOptions: any = {
      url: `livestream/${id}/prepareToJoin`,
    };
    http.put(apiOptions).then((res: any) => {
      joinLiveStream(res.data.lh);
    })
  };

  const joinLiveStream = (lh) => {
    let navData = {
      targetRoute: `/provider/livestreamdetail/${lh}`,
      targetTitle: "Live Stream",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const confirmDelete = (item: any) => {
    setLiveStreamId(item.id);
    setConfirmOpen(true);
    setMessage("Do you want to delete this livestream " + item.liveStreamName + "?");
    setAction("delete");
  }

  const deleteLiveStream = () => {
    let inputData = {
      id: liveStreamId,
    };
    let apiOptions: any = {
      url: `livestream/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getLiveStreamList();
    })
  };

  const openShareDialog = (id: any) => {
    setLiveStreamId(id);
    setShareOpen(true);
  }

  const closeShareDialog = () => {
    setLiveStreamId(0);
    setShareOpen(false);
  };

  const downloadRecording = (lvId) => {
    let apiOptions: any = {
      url: `livestream/${lvId}/downloadrecording`
    };
    http.get(apiOptions).then(response => {
      const result = response.data;
      downloadRecordingCallback(result);
    })
  }

  const downloadRecordingCallback = (result) => {
    if (result && result.downloadUrl) {
      window.open(result.downloadUrl, '_blank');
    }
  };

  const initiatePayment = (item) => {
    let inputData = {};
    if (item.allowRecording) {
      inputData = {
        entityTypeId: 5,
        entityId: item.id
      };
    }
    else {
      inputData = {
        entityTypeId: 4,
        entityId: item.id
      };
    }
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item = resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        getLiveStreamList();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const navToDashboard = () => {
    back();
  }  

  useEffect(() => {
    initLookup();
  }, []);

  return (
    <div className='livestreamlist-ui pt-5'>
      {
        routeData.stallId > 0 &&
        <div className="title-font custom-head pl-4 pt-5">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToDashboard}>
              <b>Provider Dashboard</b>
            </Link>
            <Typography color="primary" className="breadcrumbs-style">Live Shopping</Typography>
          </Breadcrumbs>
        </div>
      }
      {
        !routeData.stallId &&
        <div className="title-font custom-head pl-4 pt-5">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="primary" className="breadcrumbs-style">Live Shopping</Typography>
          </Breadcrumbs>
        </div>
      }
      <div className='pt-5'>
        <div className='row justify-content-between px-4'>
          <div className='col-3 pl-1'>
            <FxSelect name="stallId" variant="outlined" label="Select Stall"
              options={stalls}
              selectedValue={currentContext.stallId}
              valueField="id"
              displayField="text"
              size="small"
              style={{ width: 250 }}
              onValueChange={handleCurrentContext} />
          </div>
          <div className='col-3 pl-1'>
            <FxSelect name="liveStreamStatusId" variant="outlined" label="Select Status"
              options={liveStreamStatus}
              selectedValue={currentContext.liveStreamStatusId}
              valueField="id"
              displayField="text"
              size="small"
              style={{ width: 250 }}
              onValueChange={handleCurrentContext} />
          </div>
          <div className="col-4 text-right">
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={openLiveStreamForm}>
              <i className="fas fa-plus pr-2"></i>
              Add
            </Fab>
          </div>
        </div>
      </div>

      <div className='pb-2 mx-3'>
        <div className='my-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell>Stall Name</TableCell>
                  <TableCell>Live Shopping Date</TableCell>
                  <TableCell align='right'>Duration (mins)</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {liveStreamList.length == 0 &&
                  <TableCell colSpan={8}>
                    <NoRecords />
                  </TableCell>
                }
                {liveStreamList.length > 0 && liveStreamList.map((row: any, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.liveStreamName}</TableCell>
                    <TableCell className='text-center'>
                      <div className='pt-2 font-13'>{row.liveStreamStatusId == 1 &&
                        <span>Created</span>
                      }
                        {row.liveStreamStatusId == 2 &&
                          <span>Running</span>
                        }
                        {row.liveStreamStatusId == 3 &&
                          <span>Closed</span>
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      {row.stallName}
                    </TableCell>
                    <TableCell>
                      <FxDateDisplay value={row.liveStreamDate} />
                    </TableCell>
                    <TableCell align='right'>
                      {row.duration > 0 &&
                        <span>{row.duration}</span>
                      }
                    </TableCell>
                    <TableCell>
                      <i className="far fa-clock color-aaa px-1"></i>
                      <FxDateTimeDisplay value={row.startTime} displayType="time" />
                      <span className='px-1'>-</span>
                      <span>
                        <FxDateTimeDisplay value={row.endTime} displayType="time" />
                      </span>
                    </TableCell>
                    <TableCell>
                      {row.paymentMode &&
                        <span>{row.paymentMode}
                          <span> - {row.paymentStatus}</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>
                      {row.liveStreamStatusId == 1 &&
                        <div>
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openLiveStreamForm(row.id)}>Edit</Button>
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmDelete(row)}>Delete</Button>
                          <Button variant='outlined' size='small' className='button-style mr-2' disabled={!row.isPaid} onClick={() => confirmToStartLiveStream(row)}>Start</Button>
                          <Button variant='outlined' size='small' className='button-style' disabled={!row.isPaid} onClick={() => openShareDialog(row.id)}>Share</Button>
                        </div>
                      }
                      {row.liveStreamStatusId == 2 &&
                        <div>
                          <Button variant='outlined' size='small' className='button-style mr-2' disabled={!row.isPaid} onClick={() => prepareToJoin(row.id)}>Join</Button>
                          <Button variant='outlined' size='small' className='button-style' disabled={!row.isPaid} onClick={() => openShareDialog(row.id)}>Share</Button>
                        </div>
                      }
                      {row.liveStreamStatusId == 3 && row.compositionId &&
                        <div>
                          <Button variant='outlined' size='small' className='button-style mr-2' disabled={!row.isPaid} onClick={() => downloadRecording(row.id)}>Download</Button>
                        </div>
                      }
                      {(row.paymentMode == 'razorpay' && row.paymentStatus != 'Paid') &&
                        <div>
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => initiatePayment(row)}>Pay</Button>
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={liveStreamFormOpen} onClose={handleLiveStreamFormClose}>
        <LiveStreamForm liveStreamId={liveStreamId} handleLiveStreamFormClose={handleLiveStreamFormClose} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleConfirmDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
      <Dialog
        open={shareOpen}
        maxWidth="sm"
        fullWidth
        onClose={closeShareDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ShareOptions closeShareDialog={closeShareDialog} liveStreamId={liveStreamId} />
      </Dialog>
    </div>
  );
}
export default LiveStreamList;
