import React, { useEffect, useState } from 'react';
import '../Home.scss';
import './Header.scss';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import { http, localstorage, useNavigationService, AlertService } from '../../fx-core';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grow, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, MenuList, Popper, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import whitelogo from '../../../assets/whitelogo.svg';
import logo from '../../../assets/logo.png';
import Drawer from '@material-ui/core/Drawer';
import LoginContainer from './../../LoginContainer/LoginContainer';
import MyNotications from '../../MyNotifications/MyNotifications';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import * as _ from 'lodash';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import * as signalR from "@microsoft/signalr";
import { AppSettings } from '../../utils/AppSettings';
import MessageContainer from '../../MessageContainer/MessageContainer';
import LiveStreams from '../../LiveStreams/LiveStreams';
import SpecialCommunities from '../../SpecialCommunities/SpecialCommunities';

const styles = makeStyles((theme) => ({
  postsDrawer: {
    paddingTop: "10px",
    zIndex: theme.zIndex.appBar - 1,
    [theme.breakpoints.down(599)]: {
      width: '60%'
    },
    [theme.breakpoints.up(600)]: {
      width: '40%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '20%'
    }
  },
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const Header = (props: any) => {
  const globalDispatch = useDispatch();
  const classes = styles();
  const history = useHistory();
  const community = useSelector<any, any>(s => s.community);
  const login = useSelector<any, any>(s => s.login);
  const cart = useSelector<any, any>(s => s.cart);
  const listingCart = useSelector<any, any>(s => s.listingCart);

  const [communityName, setCommunityName] = useState("");
  const { navigate, navigateRoot } = useNavigationService();
  const [openLogin, setOpenLogin] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedClick, setSelectedClick] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const [connectionId, setConnectionId] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [notifyChat, setNotifyChat] = useState(false);
  const [openLiveStream, setOpenLiveStream] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isValidCommunity, setIsValidCommunity] = useState(false);

  const notificationUrl = `${AppSettings.APP_API_BASE_URL}/newordernotification`;

  // const hubConnection = new signalR.HubConnectionBuilder()
  //   .withUrl(notificationUrl)
  //   .configureLogging(signalR.LogLevel.Information)
  //   .build();

  const toggleHeaderMenu = () => {
    setHeaderMenuOpen(!headerMenuOpen);
  };

  const closeHeaderMenu = () => {
    setHeaderMenuOpen(false);
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  const toggleDrawerLogin = () => {
    setOpenLogin(!openLogin);
  };

  const toggleDrawerNotification = () => {
    setOpenNotification(!openNotification);
  };

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const toggleDrawerLive = () => {
    setOpenLiveStream(!openLiveStream);
  };

  const navToDashboard = () => {
    if (community.id > 0) {
      let navData = {
        targetRoute: ``,
        targetTitle: 'Dashboard',
        sourceState: {},
        eventSource: 'customerdashboard',
      };
      navigate(navData);
    }
  }

  const navToLogin = () => {
    setOpenLogin(true);
    setOpen(false);
  }

  const handleLoginClose = () => {
    setOpenLogin(false);
  }

  const onLoginCallBack = () => {
    handleLoginClose();
  }

  const navToMyAccount = () => {
    let navData = {
      targetRoute: '/myaccount',
      targetTitle: "My Account",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
    setOpen(false);
  }

  const navToLocalDrCard = () => {
    let navData = {
      targetRoute: '/localdirectory',
      targetTitle: "Local Business Directory",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
    setOpen(false);
  }

  const handleLogin = () => {
    console.log(login);
    let userId: any = localstorage.getItem('userId');
    let userRoles: any = localstorage.getItem('userRoles');
    userRoles = JSON.parse(userRoles);
    if (userId && login.isLogin) {
      //openConnection(userId);
      setUserId(userId);
      setIsLoggedIn(true);
      if (userRoles.length == 1) {
        var roleIndex: any = _.findIndex(userRoles, (r) => { return r === "CONSUMER" });
        if (roleIndex !== -1 && !community.isStallCreation) {
          setShowMenu(false);
        }
      }
      const superadminIndex = _.findIndex(userRoles, (r) => { return r === "SUPERADMIN" });
      if (superadminIndex !== -1) {
        setIsSuperAdmin(true);
      }
    }
    else {
      setUserId(0);
      setIsLoggedIn(false);
      setShowMenu(true);
      //closeConnection();
      setHeaderMenuOpen(false);
      setIsSuperAdmin(false);
    }
  }

  const navTocartConfirm = () => {
    setSelectedClick("cart");
    if (userId) {
      navToConfirmationPage();
    } else {
      navToLogin();
      return;
    }
  }

  const navToConfirmationPage = () => {
    let navData = {
      targetRoute: '/orderconfirmation',
      targetTitle: "Order Confirmation",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
    setOpen(false);
  }


  const navToProviderServices = () => {
    let navData = {
      targetRoute: '/provider/providerservices',
      targetTitle: "Provider Services",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
    toggleHeaderMenu();
  }

  const navToNotifications = () => {
    setNotify(false);
    setOpenNotification(true);
    setOpen(false);
  }

  const handleNotificationClose = () => {
    setOpenNotification(false);
  }

  // const openConnection = (loggedInUserId) => {
  //   hubConnection.start().then(data => {
  //     console.log("connection started");
  //     if (hubConnection.connectionId) {
  //       setConnectionId(hubConnection.connectionId);
  //       saveUserHubMap(loggedInUserId, hubConnection.connectionId);

  //       hubConnection.on("NEW_ORDER", data => {
  //         console.log("New order info", data);
  //         if (data) {
  //           setNotify(true);
  //           AlertService.showMsg({ message: data.msg });
  //           return;
  //         }
  //       });

  //       hubConnection.on("NEW_MESSAGE", data => {
  //         console.log("New message info", data);
  //         if (data) {
  //           setNotifyChat(true);
  //           globalDispatch({ type: "LATEST_CHAT", payload: data.msgData });
  //           AlertService.showMsg({ message: data.msg });
  //           return;
  //         }
  //       });

  //       hubConnection.on("PAYMENT_SUCCESS", data => {
  //         console.log("New message info", data);
  //         if (data) {
  //           AlertService.showMsg({ message: data.msg });
  //           return;
  //         }
  //       });
  //     }
  //   });
  // }

  const saveUserHubMap = (userId, connectionId) => {
    let inputData = {
      userId: userId,
      hubId: connectionId
    }
    let apiOptions: any = {
      url: `userhubmap/add`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      console.log("connection inserted");
    })
  };

  // const closeConnection = () => {
  //   hubConnection.stop().then(data => {
  //     console.log("connection stopped");
  //     deleteUserHubMap();
  //   });
  // }

  const deleteUserHubMap = () => {
    if (connectionId && connectionId.length > 0) {
      let inputData = {
        hubId: connectionId
      }
      let apiOptions: any = {
        url: `userhubmap/${inputData.hubId}`
      };
      http.delete(apiOptions).then(res => {
        console.log("connection deleted");
      })
    }
  };

  const navToMessage = () => {
    setNotifyChat(false);
    setOpenMessage(true);
  }

  const handleMessageClose = () => {
    setOpenMessage(false);
  }

  const navToLiveStreams = () => {
    if (login.isLogin && userId) {
      setOpenLiveStream(true);
    }
    else {
      navToLogin();
      return;
    }
  }

  const handleLiveStreamClose = () => {
    setOpenLiveStream(false);
  }

  const [communityOpen, setCommunityOpen] = useState(false);
  const handleDialogClose = () => {
    setCommunityOpen(false);
  };
  const showCommunityList = (id: any) => {
    if (isLoggedIn) {
      setCommunityOpen(true);
    }
  }

  const navToSpecialEvents = () => {
    let navData = {
      targetRoute: '/specialevents',
      targetTitle: "Special Events",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
    setOpen(false);
  }

  const navToHome = () => {
    globalDispatch({ type: "clear-community-details", payload: { id: 0 } });
    let navData = {
      targetRoute: '/',
      targetTitle: "Community Selection",
      targetData: {},
      sourceState: {},
      eventSource: '',
      skipAppendCode: true
    };
    navigateRoot(navData);
    setOpen(false);
  }

  const navToLogout = () => {
    globalDispatch({ type: "LOGOUT", payload: { isLogin: false } });
    globalDispatch({ type: "CLEAR_CART", payload: {} });
    globalDispatch({ type: "CLEAR_LISTING_CART", payload: {} });

    let navData = {
      targetRoute: ``,
      targetTitle: 'Dashboard',
      sourceState: {},
      eventSource: 'customerdashboard',
    };
    navigate(navData);
    setOpen(false);
  }

  useEffect(() => {
    if (community.id > 0) {
      setCommunityName(community.name);
      setIsValidCommunity(true);
    }
    else {
      setCommunityName("India");
      setIsValidCommunity(false);
    }
  }, [community]);

  useEffect(() => {
    handleLogin();
  }, [login]);

  return (
    <div>
      <Box height="100%" width="100%" className="head-color pt-4 pr-0">
        <Container fixed disableGutters>
          <AppBar>
            <Toolbar>
              <div className="d-flex col-12 justify-content-between head-ui font-weight-bold py-3 px-0">
                <div className='col-sm-3 col-lg-4'>
                  <div className="font-20 logodesign pointer" onClick={navToDashboard}>
                    <span className="d-flex align-items-center justify-content-center">
                      <img src={logo} alt="logo" width="35" height="35"></img>
                    </span>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 px-0 d-flex justify-content-center font-weight-bold header-style my-auto">
                  <span className='pointer' onClick={showCommunityList}>{communityName}</span>
                  {!isSuperAdmin && isValidCommunity && isLoggedIn &&
                    <span className="pl-2 pointer" onClick={showCommunityList}>
                      <i className="fas fa-chevron-down pointer fa-sm"></i></span>
                  }
                </div>
                <div className="col-sm-3 col-lg-4 row justify-content-end my-auto">

                  <div className='menuicons'>
                    {
                      !isLoggedIn && isValidCommunity &&
                      <span onClick={navToHome} className="float-right px-2 pointer login-btn d-flex align-items-center">
                        <span><i className="fas fa-exchange-alt pr-1"></i></span>
                        <span>Switch</span>
                      </span>
                    }
                    {isValidCommunity && isLoggedIn &&
                      <span onClick={navToLiveStreams} className="float-right ml-3 px-2 pointer login-btn d-flex align-items-center">
                        <span><i className="fas fa-video pr-1 pt-1"></i></span>
                        <span>Live</span>
                      </span>
                    }
                    {cart.cartCount > 0 &&
                      <span className="my-auto pointer pl-3" onClick={navTocartConfirm}>
                        <Badge badgeContent={cart.cartCount} color="error">
                          <i className="fas fa-shopping-cart header-icon-style"></i>
                        </Badge>
                      </span>
                    }
                    {listingCart.cartCount > 0 &&
                      <span className="my-auto pointer pl-3" onClick={navTocartConfirm}>
                        <Badge badgeContent={listingCart.cartCount} color="error">
                          <i className="fas fa-shopping-cart header-icon-style"></i>
                        </Badge>
                      </span>
                    }
                    {isValidCommunity &&
                      <span onClick={navToLocalDrCard}>
                        <i className="far fa-address-book header-icon-style pl-3"></i>
                      </span>
                    }

                    {!isLoggedIn && isValidCommunity &&
                      <span onClick={navToLogin} className="float-right ml-3 px-2 pointer login-btn d-flex align-items-center">
                        <i className="fas fa-sign-in-alt pr-1 font-17"></i>
                        <span>Login</span>
                      </span>
                    }
                    {isLoggedIn &&
                      <span onClick={navToMyAccount} className="float-right pl-3">
                        <i className="fas fa-user header-icon-style"></i>
                      </span>
                    }
                    {isLoggedIn &&
                      <span onClick={navToMessage} className="float-right pl-3">
                        {!notifyChat &&
                          <i className="fas fa-paper-plane header-icon-style"></i>

                        }
                        {notifyChat &&
                          <Badge badgeContent="" color="error">
                            <i className="fas fa-paper-plane send-icon-style"></i>

                          </Badge>
                        }
                      </span>
                    }
                    {isLoggedIn &&
                      <span onClick={navToNotifications} className="float-right pl-3">
                        {!notify &&
                          <i className="fas fa-bell header-icon-style"></i>
                        }
                        {notify &&
                          <Badge badgeContent="" color="error">
                            <i className="fas fa-bell header-icon-style"></i>
                          </Badge>
                        }
                      </span>
                    }
                    {
                      isLoggedIn && showMenu &&
                      <span onClick={toggleHeaderMenu} className="float-right pl-3 pointer">
                        <MenuRoundedIcon />
                      </span>
                    }
                    {isLoggedIn && isValidCommunity &&
                      <span onClick={navToLogout} className="float-right ml-3 px-2 pointer login-btn d-flex align-items-center">
                        <i className="fas fa-sign-out-alt pr-1 font-17"></i>
                        <span>Logout</span>
                      </span>
                    }
                  </div>
                  {isValidCommunity &&
                    <div className='menulist'>
                      <div className='d-flex'>
                        {isLoggedIn &&
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={toggleHeaderMenu}
                          >
                            <i className="fas fa-bars menu-color"></i>
                          </IconButton>
                        }
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={toggleMenu}
                        >
                          <i className="fas fa-ellipsis-h menu-color"></i>
                        </IconButton>
                      </div>

                      <Drawer anchor='right' classes={{ paper: classes.drawerWidth }} open={open} onClose={toggleMenu}>
                        <List>
                          {!isLoggedIn &&
                            <ListItem onClick={navToHome}>
                              <ListItemIcon><i className="fas fa-exchange-alt header-icon-style"></i></ListItemIcon>
                              <ListItemText>Switch</ListItemText>
                            </ListItem>
                          }
                          {isLoggedIn &&
                            <ListItem onClick={navToLiveStreams}>
                              <ListItemIcon> <i className="fas fa-video header-icon-style"></i></ListItemIcon>
                              <ListItemText>Live</ListItemText>
                            </ListItem>
                          }
                          {listingCart.cartCount > 0 &&
                            <ListItem onClick={navTocartConfirm}>
                              <ListItemIcon><i className="fas fa-shopping-cart header-icon-style"></i></ListItemIcon>
                              <ListItemText>Cart</ListItemText>
                            </ListItem>
                          }
                          {cart.cartCount > 0 &&
                            <ListItem onClick={navTocartConfirm}>
                              <ListItemIcon><i className="fas fa-shopping-cart header-icon-style"></i></ListItemIcon>
                              <ListItemText>Cart</ListItemText>
                            </ListItem>
                          }                                          
                          <ListItem onClick={navToLocalDrCard}>
                            <ListItemIcon> <i className="fas fa-address-book header-icon-style"></i></ListItemIcon>
                            <ListItemText>Local Business</ListItemText>
                          </ListItem>
                          {!isLoggedIn &&
                            <ListItem onClick={navToLogin}>
                              <ListItemIcon><i className="fas fa-sign-in-alt header-icon-style"></i></ListItemIcon>
                              <ListItemText>Login</ListItemText>
                            </ListItem>
                          }
                          {isLoggedIn &&
                            <ListItem onClick={navToMyAccount}>
                              <ListItemIcon><i className="fas fa-user header-icon-style"></i></ListItemIcon>
                              <ListItemText>My Account</ListItemText>
                            </ListItem>
                          }
                          {isLoggedIn &&
                            <ListItem onClick={navToMessage}>
                              <ListItemIcon><i className="fas fa-paper-plane send-icon-style"></i></ListItemIcon>
                              <ListItemText>Messages</ListItemText>
                            </ListItem>
                          }                                                    
                          {isLoggedIn &&
                            <ListItem onClick={navToNotifications}>
                              <ListItemIcon><i className="fas fa-bell header-icon-style"></i></ListItemIcon>
                              <ListItemText>Notifications</ListItemText>
                            </ListItem>
                          }
                          {isLoggedIn &&
                            <ListItem onClick={navToLogout}>
                              <ListItemIcon><i className="fas fa-sign-out-alt header-icon-style"></i></ListItemIcon>
                              <ListItemText>Logout</ListItemText>
                            </ListItem>
                          }                          
                        </List>
                      </Drawer>
                    </div>
                  }
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Container>
      </Box>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openLogin} onClose={toggleDrawerLogin}>
        <LoginContainer handleLoginClose={handleLoginClose} onLoginCallBack={onLoginCallBack} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openNotification} onClose={toggleDrawerNotification}>
        <MyNotications userId={userId} handleNotificationClose={handleNotificationClose} />
      </Drawer>
      <Drawer anchor='right' classes={{ paper: classes.postsDrawer }} className="drawer-right" open={headerMenuOpen} onClose={toggleHeaderMenu}>
        <HeaderMenu closeHeaderMenu={closeHeaderMenu} toggleMenu={headerMenuOpen} navToProviderServices={navToProviderServices} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
        <MessageContainer handleMessageClose={handleMessageClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openLiveStream} onClose={toggleDrawerLive}>
        <LiveStreams handleLiveStreamClose={handleLiveStreamClose} />
      </Drawer>
      <Dialog
        open={communityOpen}
        maxWidth="md"
        fullWidth
        onClose={handleDialogClose}>
        <SpecialCommunities handleDialogClose={handleDialogClose} />
      </Dialog>
    </div>

  );
}
export default Header;
