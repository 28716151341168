import React, { useEffect, useState } from 'react';
import './StallItemBatchList.scss';
import StallItemBatchForm from '../StallItemBatchForm/StallItemBatchForm';
import { http, AlertService, useNavigationService } from '../../fx-core';
import { Button, Dialog, Drawer, Fab, Link, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import AddIcon from '@material-ui/icons/Add';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const StallItemBatchList = (props) => {
  const { navigate } = useNavigationService();
  const classes = styles();
  let defaultItem = {
    itemName: "",
    stallName: ""
  };
  const [stallItem, setStallItem] = useState(defaultItem);
  const [stallItemId, setStallItemId] = useState(props.match.params.id ? parseInt(props.match.params.id) : 0);
  const [stallItemBatchFormOpen, setStallItemBatchFormOpen] = useState(false);
  const [stallItemBatchList, setStallItemBatchList] = useState([]);
  const [stallItemBatchId, setStallItemBatchId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const getStallItemBatchList = () => {
    let inputData = {
      stallItemId: stallItemId
    };
    let apiOptions: any = {
      url: `stallitembatch/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setStallItem(result.stallItem);
      setStallItemBatchList(result.stallItemBatch);
    })
  };

  const openStallItemBatchForm = (id) => {
    setStallItemBatchId(id);
    setStallItemBatchFormOpen(true);
  }

  const handleStallItemBatchFormClose = () => {
    setStallItemBatchFormOpen(false);
    getStallItemBatchList();
  }

  const confirmDelete = (item: any) => {
    setStallItemBatchId(item.id);
    setConfirmOpen(true);
    setMessage("Are you sure to delete stallitem batch #" + item.id + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    deleteStallItemBatch();
  }

  const deleteStallItemBatch = () => {
    let inputData = {
      id: stallItemBatchId,
    };
    let apiOptions: any = {
      url: `stallitembatch/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getStallItemBatchList();
    })
  };

  const navToStallItemBatchList = () => {
    let navData = {
      targetRoute: '/provider/manageitems',
      targetTitle: "Manage Items",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const updateIsActive = (row) => {
    let apiOptions: any = {
        url: `stallitembatch/updateisactive/${row.id}`,
        data: {}
    };
    http.put(apiOptions).then(res => {
        updateIsActiveCallBack(res);
    });
};

const updateIsActiveCallBack = (res) => {
    AlertService.showSuccessMsg();
    getStallItemBatchList();
};  

  useEffect(() => {
    getStallItemBatchList();
  }, []);

  return (
    <div className='stallbatchlist-ui pt-5'>
      <div className="title-font custom-head pt-4 mt-3 pl-4">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToStallItemBatchList}>
            <b>Manage Items</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Stall Item Batch List</Typography>
        </Breadcrumbs>
      </div>

      <div className='mx-3 pl-2 my-3'>
        <div className='p-2 pt-2'>
          <span><b>Item Name:</b> {stallItem.itemName}</span>
        </div>
        <div className='p-2'>
          <span><b>Stall Name:</b> {stallItem.stallName}</span>
          <span className="float-right pointer">
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={() => openStallItemBatchForm(0)}
            >
              <i className="fas fa-plus pr-2"></i>
              Add
            </Fab>
          </span>
        </div>
        <div className='p-2 mt-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stallItemBatchList.map((row: any, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      #{row.id}
                    </TableCell>
                    <TableCell>
                      {row.totalQuantity}
                    </TableCell>
                    <TableCell>
                      {row.unit}
                    </TableCell>
                    <TableCell>
                      <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openStallItemBatchForm(row.id)}>Edit</Button>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => confirmDelete(row)}>Delete</Button>
                      <span className="ml-1">
                        <Button variant="outlined" size="small" className='button-style mr-2'
                          onClick={() => updateIsActive(row)}> {row.isActive ? "Disable" : "Enable"}
                        </Button>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallItemBatchFormOpen} onClose={handleStallItemBatchFormClose}>
        <StallItemBatchForm stallItemId={stallItemId} stallItemBatchId={stallItemBatchId} handleStallItemBatchFormClose={handleStallItemBatchFormClose} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default StallItemBatchList;