import React, { useEffect, useState } from 'react';
import './ProviderServiceRequestDetail.scss';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, TableFooter, Link } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { http, FxDateDisplay, useNavigationService, formatService } from '../../fx-core';
import _ from "lodash";

const ProviderServiceRequestDetail = (props: any) => {
  let defaultServiceRequest = {
    serviceRequestIdentifier: null,
    requestDate: null,
    communityName: null,
    serviceCode: null,
    serviceName: null,
    comments: null,
    description: null,
    serviceRequestStatusId: 1,
    saleId: 0
  };

  let defaultSplEventItem = {
    communityName: "",
    serviceName: "",
    stallName: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: ""
  };
  const { navigate } = useNavigationService();
  const [serviceRequest, setServiceRequest] = useState(defaultServiceRequest);
  const [serviceReqDetails, setServiceReqDetails] = useState([]);
  const [showSpecialEvent, setShowSpecialEvent] = useState(false);
  const [specialEventItem, setSpecialEventItem] = useState(defaultSplEventItem);
  const [questions, setQuestions] = useState([]);
  const [queOptions, setQueOptions] = useState([]);
  const [answerMap, setAnswerMap] = useState({});

  const getServiceRequestInfoById = () => {
    let inputData = {
      id: props.requestId
    };

    let apiOptions: any = {
      url: `servicerequest/detail/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      getServiceRequestInfoCallBack(res)
    });
  };

  const getServiceRequestInfoCallBack = (res) => {
    let result = res.data;
    let serviceRequest = result.serviceRequest;
    setServiceRequest(serviceRequest);
    setServiceReqDetails(result.serviceRequestDetails);
    if (serviceRequest.communityTypeId == 2) {
      setShowSpecialEvent(true);
      getStallCommunityMapByServiceReqId();
    }
  };

  const navigateToManageItems = () => {
    let navData = {
      targetRoute: '/provider/manageitems/',
      targetTitle: "Manage Items",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const getStallCommunityMapByServiceReqId = () => {
    let apiOptions: any = {
      url: `stallcommunitymap/servicerequest/${props.requestId}`
    };
    http.get(apiOptions).then(res => {
      getStallCommunityMapByServiceReqIdCallBack(res);
    });
  };

  const getStallCommunityMapByServiceReqIdCallBack = (res) => {
    let result = res.data;
    result.startTime = result.startTime != null ? formatService.getConvertedTimeForDisplay(result.startTime) : null;
    result.endTime = result.endTime != null ? formatService.getConvertedTimeForDisplay(result.endTime) : null;
    setSpecialEventItem(result);
    getCommunityQuestionOptions(result.communityId);
  };

  const getCommunityQuestionOptions = (communityId) => {
    let apiOptions: any = {
      url: `question/community/${communityId}`
    };
    http.get(apiOptions).then(res => {
      getCommunityQuestionOptionsCallBack(res);
    });
  }

  const getCommunityQuestionOptionsCallBack = (res) => {
    let result: any = res.data;
    let ansMap: any = {};
    for (let question of result.questions) {
      ansMap[question.id.toString()] = "";
      question.identifier = question.id.toString();
    }
    setAnswerMap(ansMap);
    setQuestions(result.questions);
    setQueOptions(result.questionOptions);
    getServiceReqQueAns(props.requestId);
  }

  const getServiceReqQueAns = (id) => {
    let inputData = {
      id: id
    };
    let apiOptions: any = {
      url: `servicerequest/question/answers`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getServiceReqQueAnsCallBack(res);
    });
  };

  const getServiceReqQueAnsCallBack = (res) => {
    let result = res.data;
    let ansMap: any = {};
    for (let r of result) {
      ansMap[r.questionId.toString()] = r.answerText;
    }
    setAnswerMap(ansMap);
  };

  useEffect(() => {
    getServiceRequestInfoById();
  }, []);

  const body = (
    <div className='provider-service-req-detail'>
      <div className="row col-12 title-font py-1 mx-auto">
        <div className="col-11 pl-0">
          <span>Request Detail</span>
        </div>
        <div className="col-1 text-right">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </div>
      </div>

      {/* Desktop View */}
      <div className='deskview'>
        <div className='row mr-3 my-3 ml-0'>
          <div className='col-12'>
            <div className='row pl-4 pb-2 font-14'>
              <div>
                <span className='font-weight-bold'>#{serviceRequest.serviceRequestIdentifier}</span>
                <span className='px-2'>/</span>
                <FxDateDisplay value={serviceRequest.requestDate} />
              </div>
              <div className='mb-2'>
                <span className='px-2'>/</span>
                <span className='font-weight-bold'>{serviceRequest.communityName}</span> - {serviceRequest.serviceName}
              </div>
            </div>
            {
              serviceReqDetails.length > 0 &&
              <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                <Table className="table-striped" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Item</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serviceReqDetails.map((row: any, i) => (
                      <TableRow key={i}>
                        <TableCell className="font-12">
                          {row.categoryName}
                        </TableCell>
                        <TableCell className="table-font">
                          <span>{row.itemName}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    {
                      (serviceRequest.saleId === 0) &&
                      <Link href='#' className='mt-2 pl-3' onClick={navigateToManageItems}>Click here to stall items</Link>
                    }
                  </TableFooter>
                </Table>
              </TableContainer>
            }
            {
              serviceReqDetails.length == 0 && serviceRequest.serviceName !== "Child Care" &&
              <div className='pl-2 font-14'>
                {
                  serviceRequest.serviceRequestStatusId == 3 &&
                  <span>Rejected Comments : {serviceRequest.comments}</span>
                }
                {
                  serviceRequest.serviceRequestStatusId == 4 &&
                  <span>Cancelled Comments : {serviceRequest.comments}</span>
                }
              </div>
            }
            {
              serviceReqDetails.length == 0 && serviceRequest.serviceName === "Child Care" &&
              <div className='pl-2 font-14'>
                Description : {serviceRequest.description}
              </div>
            }
          </div>
        </div>
        {
          showSpecialEvent &&
          <div className="mt-2">
            <div className='row pl-5'>
              <span className='col-4 text-left label'>
                Stall
              </span>
              <span className='col-8 text-left'>
                {specialEventItem.stallName}
              </span>
            </div>
            <div className='row pt-3 pl-5'>
              <span className='col-4 text-left label'>
                Community
              </span>
              <span className='col-8 text-left'>
                {specialEventItem.communityName}
              </span>
            </div>
            <div className='row pt-3 pl-5'>
              <span className='col-4 text-left label'>
                Service
              </span>
              <span className='col-8 text-left'>
                {specialEventItem.serviceName}
              </span>
            </div>
            <div className='row pt-3 pl-5'>
              <span className='col-4 text-left label'>
                Start Date Time
              </span>
              <span className='col-8 text-left'>
                <FxDateDisplay value={specialEventItem.startDate} />
                <span className='pl-2'>
                  <i className="far fa-clock color-aaa px-1"></i>
                  {specialEventItem.startTime}
                </span>
              </span>
            </div>
            <div className='row pt-3 pl-5'>
              <span className='col-4 text-left label'>
                End Date Time
              </span>
              <span className='col-8 text-left'>
                <FxDateDisplay value={specialEventItem.endDate} />
                <span className='pl-2'>
                  <i className="far fa-clock color-aaa px-1"></i>
                  {specialEventItem.endTime}
                </span>
              </span>
            </div>

            <div className='px-5 pt-3 row'>
              <span className='divider'></span>
            </div>

            <div className='pl-5 pt-3 row'>
              <div className='text-left col-4 label'>
                Questions
              </div>
              <div className='col-8 text-left'>
                {
                  questions.length > 0 && questions.map((que: any, i) => (
                    <div key={i}>
                      {
                        que.questionTypeId == 1 &&
                        <div className='text-left pb-3'>
                          <div className='question'>
                            {que.description}
                          </div>
                          <div className='pl-4 font-weight-bold font-15'>
                            {answerMap[que.identifier]}
                          </div>
                        </div>
                      }
                      {
                        que.questionTypeId == 2 &&
                        <div className='text-left pb-3'>
                          <div className='question'>
                            {que.description}
                          </div>
                          <div className='pl-4 font-weight-bold font-15'>
                            <FxDateDisplay value={answerMap[que.identifier]} />
                          </div>
                        </div>
                      }
                      {
                        que.questionTypeId == 3 &&
                        <div className='text-left pb-3'>
                          <div className='question'>
                            {que.description}
                          </div>
                          <div className='pl-4 font-weight-bold font-15'>
                            {answerMap[que.identifier]}
                          </div>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ProviderServiceRequestDetail;