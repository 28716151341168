export const Constants = {
  ServerDateFormat: "YYYY-MM-DD",
  DateFormat: "DD/MM/YYYY",
  TimeFormat12Hour: "hh:mm a",
  ServerTimeFormat: "HH:mm",
  DateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  TimeFormat: "HH:mm:ss",
  DisplayDateTimeFormat: "DD/MMM/YYYY hh:mm a",
  TimeDisplayFormat: "hh:mm A",
  TimeFormatForInput: "YYYY-MM-DDTHH:mm",
  TimeFormatForServer: "HHmm",
  DisplayDateForChat: "DD-MMM-YYYY",
  DayFromat: "dd"
}
