import React, { useEffect, useState } from 'react';
import './MyAccountContainer.scss';
import { useNavigationService, general, http, AlertService } from '../../fx-core';
import dummyImg from "../../../assets/images/dummy.png";
import { useHistory } from "react-router-dom";
import IconPerson from '@material-ui/icons/Person';
import IconExitToApp from '@material-ui/icons/ExitToApp';
import IconList from '@material-ui/icons/List';
import ImportContactsGroup from '@material-ui/icons/ImportContacts';
import { useDispatch } from 'react-redux';
import CustomerProfile from '../CustomerProfile/CustomerProfile';
import MyOrderContainer from '../MyOrderContainer/MyOrderContainer';
import ManageAddress from '../ManageAddress/ManageAddress';
import { List, ListItem, ListItemIcon, ListItemText, Card, CardContent, Tabs, Tab } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import MyPosts from '../MyPosts/MyPosts';
import IconListAlt from '@material-ui/icons/ListAltRounded';
import { localstorage } from '../../fx-core';
import InviteFriendForm from '../InviteFriendForm/InviteFriendForm';
import IconGroup from '@material-ui/icons/Group';
import CommentIcon from '@material-ui/icons/Comment';
import MyReviewList from '../MyReviewList/MyReviewList';

const MyAccountContainer = (props: any) => {
  const drawerWidth = 240;

  const useStyles = makeStyles(theme =>
    createStyles({
      menuheight: {
        height: 690,
      },
    }),
  )

  const classes = useStyles();

  let userObj = {
    id: 0,
    firstName: null,
    lastName: null,
    mobile: null,
    email: null,
    imageUrl: null
  };
  const uid = localstorage.getItem('userId');
  const history = useHistory();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState(userObj);
  const [currentScreen, setCurrentScreen] = React.useState("");
  const community = useSelector<any, any>(s => s.community);
  const { navigate, navigateEntry, clear } = useNavigationService();
  const [userId, setUserId] = useState(uid ? parseInt(uid) : 0);
  const [value, setValue] = React.useState('profile');
  const [from, setFrom] = React.useState(props.match.params.from ? props.match.params.from : null);
  const [profileMsg, setProfileMsg] = React.useState(props.match.params.msg ? props.match.params.msg : null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const menus: any = [
    { title: 'Account Info', screen: 'profile', icon: <IconPerson /> },
    { title: 'Orders', screen: 'orders', icon: <IconList /> },
    { title: 'Addresses', screen: 'addresses', icon: <ImportContactsGroup /> },
    { title: 'Posts', screen: 'myposts', icon: <IconListAlt /> },
    { title: 'Invite Friends', screen: 'inviteafriend', icon: <IconGroup /> },
    { title: 'Rate the App', screen: 'ratetheapp', icon: <CommentIcon /> },
    { title: 'Logout', secreen: 'logout', icon: <IconExitToApp /> },
  ];

  const openMenu = (menu) => {
    setCurrentScreen(menu.screen);
    if (menu.title == "Logout") {
      dispatch({ type: "LOGOUT", payload: { isLogin: false } });
      dispatch({ type: "CLEAR_CART", payload: {} });
      dispatch({ type: "CLEAR_LISTING_CART", payload: {} });
      logout();
    }
  }

  const logout = () => {
    let navData = {
      targetRoute: ``,
      targetTitle: 'Dashboard',
      sourceState: {},
      eventSource: 'customerdashboard',
    };
    navigate(navData);
  }

  const getUserProfile = () => {
    let apiOptions: any = {
      url: `user/profile/${userId}`
    };
    http.get(apiOptions).then(res => {
      res.data.imageUrl = general.getImageUrl(res.data.profileImagePath);
      setUserInfo(res.data);
      if (from && from == 'isInvite') {
        setValue("inviteafriend");
        setCurrentScreen("inviteafriend");
      }
      else{
        setCurrentScreen("profile");
      }
    })
  };

  const refreshAccountUserProfile = () => {
    let apiOptions: any = {
      url: `user/profile/${userId}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      result.imageUrl = general.getImageUrl(result.profileImagePath);
      setUserInfo(result);
    });
  };

  useEffect(() => {
    getUserProfile();
    // if (from && from == 'isProfile' && profileMsg) {
    //   AlertService.showMsg({ message: profileMsg });
    // }
  }, []);


  const body = (
    <div className="account-container mt-5">

      {/* Desktop View */}
      <div className='deskview'>
        <div className='row pt-4'>
          <div className='col-2 pt-2 section-clr'>
            {/* LeftMenu section */}
            <List className={classes.menuheight} disablePadding component="nav">
              {menus.map((menu: any) => (
                <ListItem button selected={true} onClick={() => openMenu(menu)} className={currentScreen == menu.screen ? "selectedMenu": ""}>
                  <ListItemIcon>
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText primary={menu.title} />
                </ListItem>
              ))
              }
            </List>
          </div>
          <div className='col-8 pt-5'>
            {/* Content section */}
            {currentScreen == 'profile' &&
              <span>
                <CustomerProfile userId={userId} refreshAccountUserProfile={refreshAccountUserProfile} />
              </span>
            }
            {currentScreen == 'orders' &&
              <span>
                <MyOrderContainer userId={userId} />
              </span>
            }
            {currentScreen == 'addresses' &&
              <span>
                <ManageAddress userId={userId} />
              </span>
            }
            {currentScreen == 'myposts' &&
              <span>
                <MyPosts userId={userId} />
              </span>
            }
            {currentScreen == 'inviteafriend' &&
              <span>
                <InviteFriendForm userId={userId} />
              </span>
            }
            {currentScreen == 'ratetheapp' &&
              <span>
                <MyReviewList userId={userId} />
              </span>
            }
          </div>
          <div className='col-2 pt-5 section-clr'>
            {/* Profile section */}
            <div className='text-center'>
              {!userInfo.imageUrl && <img className="itemimg" src={dummyImg} alt="dummy"></img>}
              {userInfo.imageUrl && <img className="itemimg" src={userInfo.imageUrl} alt="product"></img>}
            </div>
            <div className="mt-5 text-center">
              <div>
                <h4>{userInfo.firstName}
                  {userInfo.lastName &&
                    <span className="ml-1">{userInfo.lastName}</span>
                  }
                  <div>
                    <span className="font-14">{userInfo.mobile}</span></div></h4>
              </div>
              <div className="font-14">{userInfo.email}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Tablet View */}
      <div className='tabview'>
        <div className='row pt-5 section-clr px-4 pb-3'>
          {/* LeftMenu section */}
          <div className='col-4'>
            <List disablePadding component="nav">
              {menus.map((menu: any) => (
                <ListItem button selected={true} onClick={() => openMenu(menu)} className={currentScreen == menu.screen ? "selectedMenu": ""}>
                  <ListItemIcon>
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText primary={menu.title} />
                </ListItem>
              ))
              }
            </List>
          </div>
          {/* Profile section */}
          <div className='col-8 row justify-content-end pr-0'>
            <div className='pr-5 my-auto text-right'>
              <div>
                <h4>{userInfo.firstName}
                  {userInfo.lastName &&
                    <span className="ml-1">{userInfo.lastName}</span>
                  }
                  <div>
                    <span className="font-14">{userInfo.mobile}</span></div></h4>
              </div>
              <div className="font-14">{userInfo.email}</div>
            </div>
            <div>
              {!userInfo.imageUrl && <img className="itemimg" src={dummyImg} alt="dummy"></img>}
              {userInfo.imageUrl && <img className="itemimg" src={userInfo.imageUrl} alt="product"></img>}
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className='mx-3 pt-4'>
          {currentScreen == 'profile' &&
            <span>
              <CustomerProfile userId={userId} refreshAccountUserProfile={refreshAccountUserProfile} />
            </span>
          }
          {currentScreen == 'orders' &&
            <span>
              <MyOrderContainer userId={userId} />
            </span>
          }
          {currentScreen == 'addresses' &&
            <span>
              <ManageAddress userId={userId} />
            </span>
          }
          {currentScreen == 'myposts' &&
            <span>
              <MyPosts userId={userId} />
            </span>
          }
          {currentScreen == 'inviteafriend' &&
            <span>
              <InviteFriendForm userId={userId} />
            </span>
          }
          {currentScreen == 'ratetheapp' &&
            <span>
              <MyReviewList userId={userId} />
            </span>
          }
        </div>
      </div>

      {/* Mobile View */}
      <div className='mobileview'>
        {/* Profile section */}
        <div className='row pt-5 section-clr px-4 pb-3'>
          <div>
            {!userInfo.imageUrl && <img className="itemimg" src={dummyImg} alt="dummy"></img>}
            {userInfo.imageUrl && <img className="itemimg" src={userInfo.imageUrl} alt="product"></img>}
          </div>
          <div className='pl-5 my-auto'>
            <div>
              <h6>{userInfo.firstName}
                {userInfo.lastName &&
                  <span className="ml-1">{userInfo.lastName}</span>
                }
                <div>
                  <span className="font-13">{userInfo.mobile}</span></div></h6>
            </div>
            <div className="font-13">{userInfo.email}</div>
          </div>
        </div>
        <div>

          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            {menus.map((menu: any) => (
              <Tab label={menu.title} value={menu.screen} onClick={() => openMenu(menu)} />
            ))}
          </Tabs>

          {/* Content section */}
          <div className='mx-3 pt-4'>
            {value == 'profile' &&
              <span>
                <CustomerProfile userId={userId} refreshAccountUserProfile={refreshAccountUserProfile} />
              </span>
            }
            {value == 'orders' &&
              <span>
                <MyOrderContainer userId={userId} />
              </span>
            }
            {value == 'addresses' &&
              <span>
                <ManageAddress userId={userId} />
              </span>
            }
            {value == 'myposts' &&
              <span>
                <MyPosts userId={userId} />
              </span>
            }
            {value == 'inviteafriend' &&
              <span>
                <InviteFriendForm userId={userId} />
              </span>
            }
            {currentScreen == 'ratetheapp' &&
              <span>
                <MyReviewList userId={userId} />
              </span>
            }            
          </div>
        </div>
      </div>

    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default MyAccountContainer;