import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './PhotoCapture.scss';
import { FxUploader, http } from '../../fx-core';

const PhotoCapture = (props: any) => {

  const [acceptType, setAcceptType] = React.useState('image/jpeg,image/jpg, image/png,image/svg+xml');

  const onUploadImage = (files: any) => {
    console.log('Photo capture files=', files);
    let id = props.uploadConfig.id;
    if (files && files.length > 0) {
      const formData = new FormData()
      formData.append('file', files[0]);
      if (id > 0) {
        formData.append('id', id);
      }
      let apiOptions = { url: props.uploadConfig.fileUploadConfig.url, data: formData };
      http.post(apiOptions).then((response: any) => {
        console.log('Upload image success');
        props.onUploadCallBack(response.data);
      }).catch(error => {
        console.log('Upload error=', error);
      });
    }
  }

  const handleClose = () => {
    props.handlePhotoCaptureClose();
  }


  const body = (
    <div className="photocapture-style">
      <div className='row title-font py-1 mx-auto'>
        <span className="col-10">
          Photo Capture
        </span>
        <span className="col-2 text-right pr-3">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>
      <div className="row m-2">

        <div className="col-12 m-1">
          Select Image
        </div>
        <div className="col-12">
          <FxUploader onUpload={onUploadImage} acceptType={acceptType} />
        </div>
      </div>
    </div>
  );


  return (
    <div>
      {body}
    </div>
  );

}
export default PhotoCapture;
