import React, { useEffect, useState } from 'react';
import './StallList.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Button, Drawer, makeStyles } from '@material-ui/core';
import { http, FxInput, useNavigationService, FxSelect, localstorage } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import StallForm from '../StallForm/StallForm';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const StallList = () => {
  const { routeData, back } = useNavigationService();
  const classes = styles();

  let defaultContext: any = {
    name: "",
    communityId: routeData.communityId
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [stalls, setStalls] = useState([]);
  const [stallFormOpen, setStallFormOpen] = useState(false);
  const [stallId, setStallId] = useState(0);
  const [communityList, setCommunityList] = useState([]);
  const [isView, setIsView] = useState(false);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    let filterObj = {
      name: currentContext.name,
      communityId: currentContext.communityId
    }
    if (item.name == "name") {
      filterObj.name = item.value;
    }
    else if (item.name == 'communityId') {
      filterObj.communityId = item.value;
    }
    getStalls(filterObj);
  };

  const handleItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getStalls = (data: any) => {
    let inputData = {
      communityId: data.communityId,
      name: data.name,
    };
    let apiOptions: any = {
      url: `stall/query/dashboard`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      let result = res.data;
      setStalls(result);
    })
  };

  const showStall = (item, context) => {
    setStallId(item.id);
    setStallFormOpen(true);
    setIsView(context);
  }

  const handleStallFormClose = () => {
    setStallFormOpen(false);
    setStallId(0);
    setIsView(false);
    getStalls(currentContext);
  }

  const getMyRegionCommunities = () => {
    let inputData = {
      lookups: {
        MyRegionCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyRegionCommunitiesCallBack(response);
    })
  };

  const getMyRegionCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyRegionCommunities);
  };

  const getMyZoneCommunities = () => {
    let inputData = {
      lookups: {
        MyZoneCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyZoneCommunitiesCallBack(response);
    })
  }

  const getMyZoneCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyZoneCommunities);
  };

  const navToBack = () => {
    back();
  };

  const getMyAdminCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
  };

  const getMyAdminCommunitiesCallBack = (res) => {
    let result = res.data;
    let commuArr: any = [];
    for (let item of result) {
      let itemObj: any = {
        id: item.id,
        text: item.name
      };
      commuArr.push(itemObj);
    }
    setCommunityList(commuArr);
  };

  const initCalls = () => {
    if (routeData.parent == 'REGIONALMANAGER') {
      getMyRegionCommunities();
    }
    else if (routeData.parent == 'ZONALMANAGER') {
      getMyZoneCommunities();
    }
    else if (routeData.parent == 'COMMADMIN') {
      getMyAdminCommunities();
    }
  }

  useEffect(() => {
    initCalls();
    getStalls(currentContext);
  }, []);

  return (
    <div className='stall-list-ui pt-4 mt-3'>
      <div className="title-font custom-head pl-4 pt-5">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
            <b>{routeData.communityName}</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Stalls</Typography>
        </Breadcrumbs>
      </div>
      <div className='row my-4 mx-2'>
        <div className="col-3">
          <FxInput
            name="name"
            value={currentContext.name}
            variant="outlined"
            label="Search"
            fullWidth
            size="small"
            onValueChange={handleInputChange}
          />
        </div>
        <div className='col-3'>
          <FxSelect name="communityId" variant="outlined"
            label="Community"
            options={communityList}
            selectedValue={currentContext.communityId}
            valueField="id"
            displayField="text"
            size="small"
            onValueChange={handleInputChange} />
        </div>
      </div>

      <div className='row my-4 mx-4 pt-2'>
        <TableContainer component={Paper}>
          <Table className="table-striped" size="small">
            <TableHead>
              <TableRow>
                <TableCell width="10%">Name</TableCell>
                <TableCell width="10%">Stall Status</TableCell>
                <TableCell width="10%">Community</TableCell>
                <TableCell width="60%">Description</TableCell>
                <TableCell width="10%" align='center'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stalls.length == 0 &&
                <TableCell colSpan={4}>
                  <NoRecords />
                </TableCell>
              }
              {
                stalls.length > 0 && stalls.map((row: any, i) => (
                  <TableRow>
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.stallOpenStatus}
                    </TableCell>
                    <TableCell>
                      {row.communityName}
                    </TableCell>
                    <TableCell>
                      {row.description}
                    </TableCell>
                    <TableCell align='center'>
                      {
                        row.communityTypeId == 1 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => showStall(row, false)}>Edit</Button>
                        </span>
                      }
                      {
                        row.communityTypeId == 2 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => showStall(row, true)}>View</Button>
                        </span>
                      }                      
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallFormOpen} onClose={handleStallFormClose}>
        <StallForm stallId={stallId} isView={isView} handleStallFormClose={handleStallFormClose} />
      </Drawer>
    </div>
  );
}
export default StallList;