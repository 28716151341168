import React, { useState, useEffect } from 'react';
import './OrderConfirmation.scss';
import { ReviewCart } from "./ReviewCart/ReviewCart";
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { FxCurrency, AlertService, FxDate, http, general, formatService, useNavigationService, localstorage } from '../fx-core';
import Dialog from '@material-ui/core/Dialog';
import { UserAddress } from "./UserAddress/UserAddress";
import { AddressForm } from "./../MyAccount/AddressForm/AddressForm";
import { DeliveryMode } from './DeliveryMode/DeliveryMode';
import { PaymentMethod } from './PaymentMethod/PaymentMethod';
import { Coupons } from "./Coupons/Copuons";
import { DeliverySlots } from "./DeliverySlots/DeliverySlots";
import { PickupAddress } from './PickupAddress/PickupAddress';
import { DeliveryAddress } from './DeliveryAddress/DeliveryAddress';
import { DeliveryCharge } from './DeliveryCharge/DeliveryCharge';

const OrderConfirmation = (props) => {

  const cartInfo = useSelector<any, any>(store => store.cart);
  const cart = cartInfo;
  const [userId, setUserId] = useState(props.userId ? parseInt(props.userId) : 0);
  const [stallId, setStallId] = useState(cart.stallId);
  const [communityId, setCommunityId] = useState(localstorage.getItem('communityId'));

  const allDeliveryModes = [
    { text: 'Delivery', name: 'deliverymode', value: 'Delivery', checked: true },
    { text: 'Pickup', name: 'deliverymode', value: 'Pickup', checked: false }
  ];
  const allPaymentOptions = [
    { text: 'Cash', name: 'paymentoption', value: 'cod', checked: false },
    { text: 'Razorpay (UPI, Credit/Debit Card, NetBanking)', name: 'paymentoption', value: 'razorpay', checked: true }
  ];

  let defaultContex = {
    deliveryMode: 'Delivery',
    deliveryCharge: 0,
    deliveryChargeMsg: null,
    addressId: 0,
    deliverySlotId: 0,
    selectedDeliveryDate: formatService.getCurrentDateForServer(),
    minDate: formatService.getCurrentDateForServer(),
    selectedUserCouponId: 0,
    paymentMode: 'razorpay',
    selectedCouponValue: 0,
    doorDeliveryMsg: "You will be notified once the order is shipped!",
    pickupDeliveryMsg: "You will be notified once the order is ready!",
    selectedCouponId: 0,
    selectedCouponValueTypeId: 2,//2-Value
    minimumOrderAmount: null
  };

  let defaultAddress = {
    fullName: null,
    mobile: '',
    fullAddress: '',
    id: 0
  };

  let defaultStall = {
    address: '',
    id: 0,
    name: '',
    slug: ''
  };
  const { navigate } = useNavigationService();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [deliveryModes, setDeliveryModes] = useState([]);
  const [currentContext, setCurrentContext] = useState(defaultContex);
  const [address, setAddress] = useState(defaultAddress);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [stall, setStall] = useState(defaultStall);

  const onInit = () => {
    getStallById();
    getDefaultAddress();
    getAvailableStallTiming();
    getAvailableCoupons();
  };

  const getStallById = () => {
    let apiOptions: any = {
      url: `stall/${stallId}`
    };
    http.get(apiOptions).then(res => {
      getStallCallBack(res.data);
    })
  };

  const getStallCallBack = (res) => {
    setStall(res);
    let deliveryModes: any = [];
    if (res.isDeliveryEnabled == 1 && res.isPickupEnabled == 0) {
      for (let item of allDeliveryModes) {
        if (item.value == "Delivery") {
          item.checked = true;
          deliveryModes.push(item);
        }
      }
    }
    else if (res.isDeliveryEnabled == 0 && res.isPickupEnabled == 1) {
      for (let item of allDeliveryModes) {
        if (item.value == "Pickup") {
          item.checked = true;
          deliveryModes.push(item);
        }
      }
      const data = { deliveryMode: "Pickup" };
      setCurrentContext(prevState => {
        return { ...prevState, ...data }
      });
    }
    else {
      deliveryModes = allDeliveryModes;
    }

    setDeliveryModes(deliveryModes);
    getPaymentOptions();
  };

  const getPaymentOptions = () => {
    let apiOptions: any = {
      url: `paymentoption/active/stall/${stallId}`
    };
    http.get(apiOptions).then(res => {
      getPaymentsCallBack(res);
    })
  };

  const getPaymentsCallBack = (res) => {
    let paymentOptionList = res.data;
    let paymentModes: any = [];
    for (let item of paymentOptionList) {
      let lookupItem = _.find(allPaymentOptions, { value: item.paymentOptionCode });
      if (lookupItem) {
        paymentModes.push(lookupItem);
      }
    }
    setPaymentOptions(paymentModes);
  };

  const onDeliveryModeChange = (selected) => {
    const data = { deliveryMode: selected.value, deliverySlotId: 0 };
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
    let tmpDelModes: any = deliveryModes.map((item: any) => {
      if (item.value === selected.value) {
        item.checked = true;
      }
      else {
        item.checked = false;
      }
      return item;
    });
    setDeliveryModes(tmpDelModes);
  };

  const onPaymentModeChange = (selected) => {
    const data = { paymentMode: selected.value };
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
    let tmpPayModes: any = paymentOptions.map((item: any) => {
      if (item.value === selected.value) {
        item.checked = true;
      }
      else {
        item.checked = false;
      }
      return item;
    });
    setPaymentOptions(tmpPayModes);
  };

  const getDefaultAddress = () => {
    let apiOptions: any = {
      url: `address/default/query`,
    };
    http.post(apiOptions).then(res => {
      getDefaultAddressCallBack(res);
    })
  };

  const getDefaultAddressCallBack = (res) => {
    const result = res.data;
    setAddress(result);
    let addObj = { addressId: 0 };
    if (result.id > 0) {
      addObj.addressId = result.id;
    }
    setCurrentContext(prevState => {
      return { ...prevState, ...addObj }
    });
    if (result.id > 0 && result.locationId) {
      getDeliveryCharge(result.locationId)
    }
  };

  const getAddressById = () => {
    let apiOptions: any = {
      url: `address/${currentContext.addressId}`,
    };
    http.get(apiOptions).then(res => {
      setAddress(res.data);
    })
  };

  const getDeliveryCharge = (locationId) => {
    let inputData = {
      locationId: locationId,
      stallId: stallId
    }
    let apiOptions: any = {
      url: `stalldeliverylocation/deliverycharge`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getDeliveryChargeCallBack(res);
    })
  };

  const getDeliveryChargeCallBack = (res) => {
    const result = res.data;
    let resultData = {
      deliveryCharge: result.deliveryCharge,
      minimumOrderAmount: result.minimumOrderAmount
    }
    setCurrentContext(prevState => {
      return { ...prevState, ...resultData };
    });
  };

  const getAvailableSlots = () => {
    let inputData = {
      stallId: stallId
    };
    let apiOptions: any = {
      url: `stalldeliveryslot/available`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAvailableSlotsCallBack(res);
    })
  };

  const getAvailableSlotsCallBack = (res) => {
    const result = res.data;
    if (result.deliverySlots.length > 0) {
      let filterDelSlots = filterCurrentDaySlots(result.deliverySlots);
      for (let item of filterDelSlots) {
        item.startTime = item.startTime != null ? general.getFormattedTime(item.startTime) : null;
        item.endTime = item.endTime != null ? general.getFormattedTime(item.endTime) : null;
      }
      setAvailableSlots(filterDelSlots);
    }
    else {
      setAvailableSlots([]);
    }
    setCurrentContext(prevState => {
      return { ...prevState, ...{ deliverySlotId: 0 } }
    });
  };

  const filterCurrentDaySlots = (slotList) => {
    let currentDate = formatService.getCurrentDateForServer();
    let currentTime = formatService.getCurrentTimeForServer();
    let selDelDate = formatService.getDateStringForServer(currentContext.selectedDeliveryDate);
    if (currentDate == selDelDate) {
      slotList = slotList.filter(i => i.startTime > currentTime);
    }
    return slotList;
  }

  const onSelectSlot = (item) => {
    let deliverySlotId = 0;
    if (currentContext.deliverySlotId != item.id) {
      deliverySlotId = item.id;
    }
    setCurrentContext(prevState => {
      return { ...prevState, ...{ deliverySlotId: deliverySlotId } }
    });
  };

  const getAvailableCoupons = () => {
    let inputData = {
      entityTypeId: 3, // 3-Stall
      entityId: stallId
    };
    let apiOptions: any = {
      url: `coupon/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAvailableCouponsCallBack(res);
    })
  };

  const getAvailableCouponsCallBack = (res) => {
    setCouponList(res.data);
  };

  const selectedCoupon = (item) => {
    let couponObj: any = {};
    if (currentContext.selectedUserCouponId == item.id || currentContext.selectedCouponId == item.id) {
      couponObj.selectedUserCouponId = 0;
      couponObj.selectedCouponValue = 0;
      couponObj.selectedCouponId = 0;
      couponObj.selectedCouponValueTypeId = 2;
    }
    else {
      couponObj.selectedUserCouponId = item.userCouponId;
      couponObj.selectedCouponValue = item.couponValue;
      couponObj.selectedCouponId = item.couponId;
      couponObj.selectedCouponValueTypeId = item.couponValueTypeId;
    }
    setCurrentContext(prevState => {
      return { ...prevState, ...couponObj }
    });
  };

  const getAvailableStallTiming = () => {
    let inputData = {
      stallId: stallId,
      communityId: communityId,
      currentDay: formatService.getDayByDate(formatService.getCurrentDateForServer())
    }
    let apiOptions: any = {
      url: `stalltiming/available`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAvailableStallTimingCallBack(res);
    })
  };

  const getAvailableStallTimingCallBack = (res) => {
    const result = res.data;
    let currentAvailableTimings = result.currentAvailableTimings;
    let nextAvailableTimings = result.nextAvailableTimings;
    if (currentAvailableTimings && currentAvailableTimings.length > 0) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ selectedDeliveryDate: formatService.getCurrentDateForServer() } }
      });
    }
    else if (nextAvailableTimings && nextAvailableTimings.length > 0) {
      let hours = 24;
      for (var i = 0; i < 6; i++) {
        let nextDate = formatService.getDateByAddedHrs(hours);
        let nextDay = formatService.getDayByDate(nextDate);
        let lookupItem = _.find(nextAvailableTimings, { day: nextDay.toLowerCase() });
        if (lookupItem) {
          setCurrentContext(prevState => {
            return { ...prevState, ...{ selectedDeliveryDate: nextDate } }
          });
          break;
        }
        else {
          hours = hours + 24;
        }
      }
    }
  };


  const confirmOrder = () => {
    if (currentContext.deliveryMode === "Delivery") {
      if (currentContext.addressId == 0) {
        AlertService.showErrorMsg("Please add or select address for Door delivery");
        return;
      }
      if (currentContext.deliverySlotId === 0) {
        AlertService.showErrorMsg("Please select delivery slot");
        return;
      }
      if (availableSlots.length > 0 && !currentContext.deliverySlotId) {
        AlertService.showErrorMsg("Please select delivery slot");
        return;
      }
    }
    else if (currentContext.deliveryMode === 'Pickup') {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ addressId: 0 } }
      });
    }

    if (cart.items.length === 0) {
      AlertService.showErrorMsg("Please select products to order");
      return;
    }
    if (cart.totalAmount === 0) {
      AlertService.showErrorMsg("Order total should not be zero");
      return;
    }

    if ((!currentContext.selectedUserCouponId || !currentContext.selectedCouponId) && !currentContext.paymentMode) {
      AlertService.showErrorMsg("Please select any payment mode");
      return;
    }

    if ((currentContext.selectedUserCouponId > 0 || currentContext.selectedCouponId > 0) &&
      currentContext.selectedCouponValue < cart.totalAmount) {
      if (!currentContext.paymentMode) {
        AlertService.showErrorMsg("Please select payment mode for remaining payment");
        return;
      }
    }
    prepareDataAndPlaceOrder();
  };

  const prepareDataAndPlaceOrder = () => {
    const inputData = getOrderInputData();
    placeOrder(inputData);
  };

  const getOrderInputData = () => {
    const orderLines: any = [];
    let modifierAmt = 0;
    let orderTypeId = 0;
    if (currentContext.deliveryMode == 'Delivery') {
      orderTypeId = 1;
    }
    else if (currentContext.deliveryMode == 'Pickup') {
      orderTypeId = 2;
    }
    if (currentContext.selectedCouponValueTypeId == 2 && (currentContext.selectedUserCouponId > 0 || currentContext.selectedCouponId > 0)) {
      cart.totalAmount = cart.totalAmount - currentContext.selectedCouponValue;
    }
    for (let item of cart.items) {
      modifierAmt = 0;
      if (item.customizedDetails && item.customizedDetails.length > 0) {
        let totalModifierAmt = _.sumBy(item.customizedDetails, 'stallItemCustomizeDetailPrice');
        modifierAmt = item.quantity * totalModifierAmt;
      }
      if (item.quantity > 0) {
        let newItem = {
          ItemId: item.itemId,
          ItemName: item.itemName,
          Price: item.price,
          ItemQuantity: item.quantity,
          LineTotalPrice: (item.quantity * item.price) + modifierAmt,
          StallItemBatchId: item.id,
          StallItemId: item.stallItemId,
          LineModifierAmount: modifierAmt,
          OrderLineModifiers: item.customizedDetails ? item.customizedDetails : []
        };
        orderLines.push(newItem);
      }
    }

    const order = {
      id: 0,
      stallId: stallId,
      orderAmount: cart.totalAmount,
      orderTypeId: orderTypeId,
      paymentMode: currentContext.paymentMode,
      addressId: currentContext.addressId,
      userCouponId: currentContext.selectedUserCouponId,
      deliverySlotId: currentContext.deliverySlotId,
      orderDate: currentContext.selectedDeliveryDate,
      deliveryCharge: currentContext.deliveryCharge,
      selectedDay: formatService.getDayByDate(currentContext.selectedDeliveryDate),
      communityId: communityId
    };
    let inputData = {
      Order: order,
      OrderLines: orderLines,
      CouponId: currentContext.selectedCouponId
    };
    return inputData;
  };

  const placeOrder = (inputData) => {
    let apiOptions: any = {
      url: `order/place`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      createOrderCallBack(res);
    })
  };

  const createOrderCallBack = (res) => {
    if (res.status === 200) {
      navToOrderSummary(res.data.orderId);
    }
  };

  const navToOrderSummary = (orderId) => {
    let navData = {
      targetRoute: '/ordersummary/' + orderId,
      targetTitle: "Order Summary",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openAddressDialog = () => {
    setOpen(true);
  };

  const closeAddressDialog = () => {
    setOpen(false);
  };

  const onSelectAddress = (item) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...{ addressId: item.id } };
    });
    setAddress(item);
    if (item.locationId) {
      getDeliveryCharge(item.locationId)
    }
    closeAddressDialog();
  };

  const onDeliveryDateSelect = (item) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...{ selectedDeliveryDate: item.value } }
    });
  };

  const openAddressForm = () => {
    setAddOpen(true);
  };

  const closeAddressForm = () => {
    setAddOpen(false);
  };

  const onSaveCallback = () => {
    setAddOpen(false);
    getDefaultAddress();
  }

  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
  }, [cartInfo]);

  useEffect(() => {
    getAvailableSlots();
  }, [currentContext.selectedDeliveryDate]);

  return (
    <div className="orderconfirmation-ui pt-5">
      <div className="content-container">

        {/* Desktop View */}
        <div className='deskview'>
          <div className="row col-12 p-4">
            <div className="row col-12 pb-4 pt-3">
              <h5>Order Confirmation</h5>
            </div>
            <div className='col-7'>
              <div className='row'>
                <div className='col-6 deliveryborder'>
                  <DeliveryMode
                    deliveryModes={deliveryModes}
                    deliveryMode={currentContext.deliveryMode}
                    onDeliveryModeChange={onDeliveryModeChange}
                  />
                </div>
                <div className='col-6 paymentborder pb-4'>
                  <PaymentMethod
                    paymentOptions={paymentOptions}
                    paymentMode={currentContext.paymentMode}
                    onPaymentModeChange={onPaymentModeChange}
                  />
                  <div>
                    <Coupons
                      couponList={couponList}
                      selectedCoupon={selectedCoupon}
                      selectedUserCouponId={currentContext.selectedUserCouponId}
                      selectedCouponId={currentContext.selectedCouponId}
                    />
                  </div>
                </div>
              </div>
              <div className='row pb-5'>
                <div className='col-6  deliveryaddressborder'>
                  <DeliverySlots
                    selectedDeliveryDate={currentContext.selectedDeliveryDate}
                    onDeliveryDateSelect={onDeliveryDateSelect}
                    availableSlots={availableSlots}
                    onSelectSlot={onSelectSlot}
                    deliverySlotId={currentContext.deliverySlotId}
                    isSave={false}
                    minDate={currentContext.minDate}
                  />
                </div>

                <div className='col-6'>
                  {
                    currentContext.deliveryMode === "Pickup" &&
                    <div className="pt-4">
                      <PickupAddress
                        stall={stall}
                        pickupDeliveryMsg={currentContext.pickupDeliveryMsg}
                      />
                    </div>
                  }
                  {
                    currentContext.deliveryMode === "Delivery" &&
                    <div className="pt-4">
                      <DeliveryAddress
                        openAddressDialog={openAddressDialog}
                        address={address}
                        openAddressForm={openAddressForm}
                      />
                      <div className="ml-1 mt-2 row">
                        <DeliveryCharge
                          deliveryCharge={currentContext.deliveryCharge}
                          deliveryChargeMsg={currentContext.deliveryChargeMsg}
                          doorDeliveryMsg={currentContext.doorDeliveryMsg}
                          minimumOrderAmount={currentContext.minimumOrderAmount}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='col-5'>
              <ReviewCart userId={userId} stallId={stallId} confirmOrder={confirmOrder} stallName={stall.name} stallSlug={stall.slug} />
            </div>
          </div>
        </div>


        {/* Tablet View */}
        <div className='tabview'>
          <div className="row col-12 p-4">
            <div className="row col-12 pb-4 pt-3">
              <h5>Order Confirmation</h5>
            </div>
            <div className='col-12 pl-4'>
              <div className='row'>
                <div className='col-6 deliveryborder'>
                  <DeliveryMode
                    deliveryModes={deliveryModes}
                    deliveryMode={currentContext.deliveryMode}
                    onDeliveryModeChange={onDeliveryModeChange}
                  />
                </div>
                <div className='col-6 paymentborder pb-4'>
                  <PaymentMethod
                    paymentOptions={paymentOptions}
                    paymentMode={currentContext.paymentMode}
                    onPaymentModeChange={onPaymentModeChange}
                  />
                  <div>
                    <Coupons
                      couponList={couponList}
                      selectedCoupon={selectedCoupon}
                      selectedUserCouponId={currentContext.selectedUserCouponId}
                      selectedCouponId={currentContext.selectedCouponId}
                    />
                  </div>
                </div>
              </div>
              <div className='row pb-5'>
                <div className='col-6  deliveryaddressborder'>
                  <DeliverySlots
                    selectedDeliveryDate={currentContext.selectedDeliveryDate}
                    onDeliveryDateSelect={onDeliveryDateSelect}
                    availableSlots={availableSlots}
                    onSelectSlot={onSelectSlot}
                    deliverySlotId={currentContext.deliverySlotId}
                    isSave={false}
                    minDate={currentContext.minDate}
                  />
                </div>

                <div className='col-6'>
                  {
                    currentContext.deliveryMode === "Pickup" &&
                    <div className="pt-4">
                      <PickupAddress
                        stall={stall}
                        pickupDeliveryMsg={currentContext.pickupDeliveryMsg}
                      />
                    </div>
                  }
                  {
                    currentContext.deliveryMode === "Delivery" &&
                    <div className="pt-4">
                      <DeliveryAddress
                        openAddressDialog={openAddressDialog}
                        address={address}
                        openAddressForm={openAddressForm}
                      />
                      <div className="ml-1 mt-2 row">
                        <DeliveryCharge
                          deliveryCharge={currentContext.deliveryCharge}
                          deliveryChargeMsg={currentContext.deliveryChargeMsg}
                          doorDeliveryMsg={currentContext.doorDeliveryMsg}
                          minimumOrderAmount={currentContext.minimumOrderAmount}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='col-12'>
              <ReviewCart userId={userId} stallId={stallId} confirmOrder={confirmOrder} stallName={stall.name} stallSlug={stall.slug} />
            </div>
          </div>
        </div>


        {/* Mobile View */}
        <div className='mobileview'>
          <div className="p-3">
            <div className="row col-12 pb-4 pt-3">
              <h5>Order Confirmation</h5>
            </div>
            <div className='pb-4'>
              <DeliveryMode
                deliveryModes={deliveryModes}
                deliveryMode={currentContext.deliveryMode}
                onDeliveryModeChange={onDeliveryModeChange}
              />
            </div>
            <div>
              <PaymentMethod
                paymentOptions={paymentOptions}
                paymentMode={currentContext.paymentMode}
                onPaymentModeChange={onPaymentModeChange}
              />
              <div>
                <Coupons
                  couponList={couponList}
                  selectedCoupon={selectedCoupon}
                  selectedUserCouponId={currentContext.selectedUserCouponId}
                  selectedCouponId={currentContext.selectedCouponId}
                />
              </div>
            </div>


            <div className='pb-4'>
              <DeliverySlots
                selectedDeliveryDate={currentContext.selectedDeliveryDate}
                onDeliveryDateSelect={onDeliveryDateSelect}
                availableSlots={availableSlots}
                onSelectSlot={onSelectSlot}
                deliverySlotId={currentContext.deliverySlotId}
                isSave={false}
                minDate={currentContext.minDate}
              />
            </div>

            <div>
              {
                currentContext.deliveryMode === "Pickup" &&
                <div className="pt-4">
                  <PickupAddress
                    stall={stall}
                    pickupDeliveryMsg={currentContext.pickupDeliveryMsg}
                  />
                </div>
              }
              {
                currentContext.deliveryMode === "Delivery" &&
                <div className="pt-4">
                  <DeliveryAddress
                    openAddressDialog={openAddressDialog}
                    address={address}
                    openAddressForm={openAddressForm}
                  />
                  <div className="ml-1 mt-2 row">
                    <DeliveryCharge
                      deliveryCharge={currentContext.deliveryCharge}
                      deliveryChargeMsg={currentContext.deliveryChargeMsg}
                      doorDeliveryMsg={currentContext.doorDeliveryMsg}
                      minimumOrderAmount={currentContext.minimumOrderAmount}
                    />
                  </div>
                </div>
              }
            </div>


            <div className='col-12 px-0 pt-3'>
              <ReviewCart userId={userId} stallId={stallId} confirmOrder={confirmOrder} stallName={stall.name} stallSlug={stall.slug} />
            </div>
          </div>
        </div>


        <div>
          <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            onClose={closeAddressDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <UserAddress userId={userId} onSelectAddress={onSelectAddress} closeAddressDialog={closeAddressDialog} />
          </Dialog>
          <Dialog
            open={addOpen}
            maxWidth="sm"
            onClose={closeAddressForm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <AddressForm userId={userId} addressId={0} closeAddressForm={closeAddressForm}
              onSaveCallback={onSaveCallback} />
          </Dialog>
        </div>
      </div>
    </div>
  )
};

export default OrderConfirmation;