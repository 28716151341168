import React, { useEffect, useState } from 'react';
import './CustomerDashboard.scss';
import ServiceCard from './ServiceCard/ServiceCard';
import FeaturedStall from './FeaturedStall/FeaturedStall';
import ListItem from '@material-ui/core/ListItem';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import localstall from '../../assets/img/localstall.svg';
import localstallimage from '../../assets/img/localstallimage.png';
import readytoeat from '../../assets/img/readytoeat.svg';
import readytoeatimage from '../../assets/img/readytoeatimage.png';
import foodbag from '../../assets/img/foodbag.svg';
import foodbagimage from '../../assets/img/foodbagimage.png';
import freshproduce from '../../assets/img/freshproduce.svg';
import freshproduceimage from '../../assets/img/freshproduceimage.png';
import garagesale from '../../assets/img/garagesale.svg';
import garageimage from '../../assets/img/garageimage.png';
import childcare from '../../assets/img/childcare.svg';
import childcareimage from '../../assets/img/childcareimage.png';
import garden from '../../assets/img/garden.svg';
import gardenimage from '../../assets/img/gardenimage.png';
import pet from '../../assets/img/pet.svg';
import petimage from '../../assets/img/petimage.png';
import professional from '../../assets/img/professional.svg';
import professionalimage from '../../assets/img/professionalimage.png';
import creativecraft from '../../assets/img/creativecraft.svg';
import creativecraftimage from '../../assets/img/creativecraftimage.png';
import banner from '../../assets/img/banner3.png';
// import banner from '../../assets/img/banner2.png';
// import banner from '../../assets/img/banner1.png';
// import banner from '../../assets/img/banner.jpg';
import { http, formatService, useNavigationService, localstorage, session, general } from '../fx-core';
import { useDispatch } from 'react-redux';
import _ from "lodash";
import Drawer from '@material-ui/core/Drawer';
import ReviewList from '../ReviewList/ReviewList';
import Dialog from '@material-ui/core/Dialog';
import DownloadApp from '../DownloadApp/DownloadApp';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import TaskTracker from '../TaskTracker/TaskTracker';
import { useSelector } from 'react-redux';
import LoginContainer from '../LoginContainer/LoginContainer';
import FeaturedProducts from './FeaturedProducts/FeaturedProducts';
import { Helmet } from "react-helmet";
import { FeaturedItemList } from './FeaturedItemList/FeaturedItemList';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const CustomerDashboard = (props) => {
  const classes = styles();

  const { navigate, navigateEntry, clear } = useNavigationService();
  const [serviceCard, setServiceCard] = useState([]);
  const [featuredStalls, setFeaturedStalls] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [slidesToScroll, setSlidesToScroll] = useState(1);
  const [stallCount, setStallCount] = React.useState(0);
  const [userCount, setUserCount] = React.useState(0);
  const [reviewCount, setReviewCount] = React.useState(0);
  const [ReviewListOpen, setReviewListOpen] = useState(false);
  const [communityId, setCommunityId] = React.useState(0);
  const globalDispatch = useDispatch();
  const login = useSelector<any, any>(s => s.login);
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false)
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  )
  const [openLogin, setOpenLogin] = useState(false);
  const [featuredProds, setFeaturedProds] = useState([]);
  const [metaInfo, setMetaInfo] = useState({ metaTitle: "", metaDescription: "" });
  const [featuredOpen, setFeaturedOpen] = useState(false);

  const openLoginDrawer = () => {
    setOpenLogin(true);
  };

  const handleLoginClose = () => {
    setOpenLogin(false);
  }

  const onLoginCallBack = () => {
    handleLoginClose();
  }

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener('change', e => setMatches(e.matches));
    const referrralCode = session.getItem('referralCode');
    if (referrralCode) {
      openLoginDrawer();
    }
  }, []);

  const dataMap = {
    "Local Food Stall": { "icon": localstall, "image": localstallimage, "description": "We bring together locals to buy and sell within the community", displayOrder: 10 },
    "Ready To Eat": { "icon": readytoeat, "image": readytoeatimage, "description": "Authentic home made cuisines", displayOrder: 1 },
    "Food Bag": { "icon": foodbag, "image": foodbagimage, "description": "Master any cuisine", displayOrder: 2 },
    "Fresh Produce": { "icon": freshproduce, "image": freshproduceimage, "description": "Direct from local gardens to kitchen", displayOrder: 3 },
    "Garage Sale": { "icon": garagesale, "image": garageimage, "description": "Create a local community carnival", displayOrder: 4 },
    "ChildCare": { "icon": childcare, "image": childcareimage, "description": "Your friendly neighbourhood child care", displayOrder: 5 },
    "Garden": { "icon": garden, "image": gardenimage, "description": "Your go to local gardening pro", displayOrder: 6 },
    "Pet": { "icon": pet, "image": petimage, "description": "All things for our four legged friends", displayOrder: 7 },
    "Professional": { "icon": professional, "image": professionalimage, "description": "Find the local experts", displayOrder: 8 },
    "CreativeCraft": { "icon": creativecraft, "image": creativecraftimage, "description": "Things made with hand, head & lots of heart", displayOrder: 9 },
  };

  const renderSlides = () =>
    featuredStalls.length > 0 && featuredStalls.map((item, i) => (
      <ListItem button key={i} className="slide-class">
        <div className="d-flex justify-content-center">
          <FeaturedStall item={item} navToStall={navToStall} />
        </div>
      </ListItem>
    ));

  const handleLogin = (code: any) => {
    let inputData = { code: code || "dev" };

    let apiOptions = {
      url: 'portal/validate/community/code',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result: any = response.data;
      let community: any = result.community;
      setCommunityId(community.id);
      setMetaInfo({ metaTitle: community.metaTitle, metaDescription: community.metaDescription });
      localstorage.setItem('imagStorageUrl', result.imageStorageUrl);

      globalDispatch({ type: "set-community-details", payload: community });
      getDashboardDetails(community.id);
      getHeaderDetails(community.id);
      getFeaturedStalls(community.id);
      getFeaturedProducts(community.id);
      if (!login.isLogin) {
        resolveUser();
        acceptStall();
        redirectToStall();
      }
    });
  }

  const getDashboardDetails = (id: any) => {
    let inputData = {
      communityId: id,
      currentDate: formatService.getCurrentDateTime(),
    };
    let apiOptions: any = {
      url: `portal/service/portal/dashboard`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data.Items;
      for (let item of result) {
        /*item.icon = dataMap[item.ServiceCode]?.icon;
        item.image = dataMap[item.ServiceCode]?.image;
        item.Description = dataMap[item.ServiceCode]?.description;
        item.displayOrder = dataMap[item.ServiceCode]?.displayOrder;*/
        item.image = general.getImageUrl(item.ImagePath);
      }
      result = _.orderBy(result, ['DisplayOrder'], ['asc']);
      setServiceCard(result);
    });
  }

  const getFeaturedStalls = (id: any) => {
    let inputData = {
      communityId: id,
      currentDate: formatService.getCurrentDateForServer()
    };
    let apiOptions: any = {
      url: `portal/stall/featuredstallswithitems`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      setFeaturedStalls(result);
      //setSlidesToShow(result.length - 1);
      if(result.length > 1) {
        setSlidesToShow(2);
      }
      else {
        setSlidesToShow(1);
      }
    });
  }

  const getFeaturedProducts = (id: any) => {
    let inputData = {
      communityId: id,
      currentDate: formatService.getCurrentDateForServer()
    };
    let apiOptions: any = {
      url: `portal/community/featuredproducts`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getFeaturedProductsCallBack(res);
    });
  }

  const getFeaturedProductsCallBack = (res) => {
    let result = res.data;
    let itemServiceProds = result.items;
    let listingServiceProds = result.listing;
    let bookingServiceProds = result.booking;
    let products: any = [];
    for (let item of itemServiceProds) {
      let itemObj = {
        stallImageUrl: general.getImageUrl(item.stallImagePath),
        itemImageUrl: general.getImageUrl(item.itemImagePath),
        itemName: item.itemName,
        price: item.price,
        unit: item.unit,
        id: item.id,
        serviceId: item.serviceId,
        slug: item.slug,
        type: 'item'
      };
      products.push(itemObj);
    }
    for (let item of listingServiceProds) {
      let itemObj = {
        stallImageUrl: general.getImageUrl(item.stallImagePath),
        itemImageUrl: general.getImageUrl(item.itemImagePath),
        itemName: item.itemName,
        stallId: item.stallId,
        id: item.id,
        serviceId: item.serviceId,
        slug: item.slug,
        price: item.price,
        type: 'listing'
      };
      products.push(itemObj);
    }
    for (let item of bookingServiceProds) {
      let itemObj = {
        stallImageUrl: general.getImageUrl(item.stallImagePath),
        itemImageUrl: general.getImageUrl(item.itemImagePath),
        itemName: item.itemName,
        price: item.price,
        stallId: item.stallId,
        id: item.id,
        serviceId: item.serviceId,
        slug: item.slug,
        type: 'booking'
      };
      products.push(itemObj);
    }
    setFeaturedProds(products);
  }

  const navToStallWithService = (id: any, serviceId: any) => {
    let parent = 'dashboard';
    let navData = {
      targetRoute: '/stall/' + serviceId + '/' + id + '/' + parent,
      targetTitle: "Stall",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const navToStallDetail = (item: any) => {
    if(item.type == "listing" || item.type == "booking") {
      const featuredType = { type: item.type, id: item.id };
      localstorage.setItem('featuredType', JSON.stringify(featuredType));
    }
    let navData = {
      targetRoute: '/stalls/' + item.slug,
      targetTitle: "Stall",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const viewAllFeatureItems = () => {
    setFeaturedOpen(true)
  };

  const navToStallDetailFromDialog = (item: any) => {
    navToStallDetail(item);
    closeFeaturedDialog();
  };

  const closeFeaturedDialog = () => {
    setFeaturedOpen(false);
  }

  const navToStall = (slug: any) => {
    let navData = {
      targetRoute: '/stalls/' + slug,
      targetTitle: "Stalls",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
    //getStallService(id);
  }

  const getStallService = (id: any) => {
    let inputData = {
      communityId: communityId
    };
    let apiOptions: any = {
      url: `portal/stall/${id}/services`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data.services;
      if (result && result.length > 0) {
        navToStallWithService(id, result[0].id);
      };
    });
  }

  const initNav = (code) => {
    clear();
    let navData = {
      targetRoute: `/${code}`,
      targetTitle: 'Dashboard',
      sourceState: {},
      eventSource: 'customerdashboard',
    };
    navigateEntry(navData);
  }

  const navToStallHome = (item: any) => {
    const id = item.ServiceId;
    const serviceBehaviorCode = item.ServiceBehaviorCode;
    const slug = item.Slug;
    if (serviceBehaviorCode == 'ItemWise') {
      let navData = {
        targetRoute: '/' + slug + '/itemwise/stalls',
        targetTitle: "Itemwise Stalls",
        targetData: {},
        sourceState: {},
        eventSource: '',
      };
      navigate(navData);
    } else if (serviceBehaviorCode == 'Bookings') {
      let navData = {
        targetRoute: '/' + slug + '/bookings/stalls',
        targetTitle: "Booking Stalls",
        targetData: {},
        sourceState: {},
        eventSource: '',
      };
      navigate(navData);
    }
    else if (serviceBehaviorCode == 'Listings') {
      let navData = {
        targetRoute: '/' + slug + '/listings/stalls',
        targetTitle: "Listing Stalls",
        targetData: {},
        sourceState: {},
        eventSource: '',
      };
      navigate(navData);
    }
    else {
      navToComingSoon();
    }
  }
  const navToComingSoon = () => {
    let navData = {
      targetRoute: '/comingsoon',
      targetTitle: "Coming Soon",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const getHeaderDetails = (id: any) => {
    let apiOptions: any = {
      url: `portal/community/headerdetails/${id}`,
      data: {}
    };
    http.get(apiOptions).then(response => {
      setStallCount(response.data.stallCount);
      setUserCount(response.data.userCount);
      setReviewCount(response.data.reviewCount);
    });
  }

  const openReviews = () => {
    setReviewListOpen(true);
  }

  const handleReviewList = () => {
    setReviewListOpen(false);
  }

  const resolveUser = () => {
    const uHex = props.match.params.id;
    if (uHex && uHex.length > 0) {
      let apiOptions: any = {
        url: `security/${uHex}/resolveuser`
      };
      http.get(apiOptions).then(response => {
        signInCallBack(response);
      })
    }
  }

  const acceptStall = () => {
    const pHex = session.getItem('pHex');
    if (pHex && pHex.length > 0) {
      let apiOptions: any = {
        url: `security/stall/${pHex}/accept`
      };
      http.get(apiOptions).then(res => {
        acceptStallCallBack(res);
      })
    }
  };

  const acceptStallCallBack = (res) => {
    session.setItem('pHex', "");
    const result = res.data;
    if (result && result.authToken && result.authToken.sessionId) {
      signInCallBack(res);
    }
    else {
      //TODO for non register users
    }
  };

  const redirectToStall = () => {
    let navToStallObj = session.getItem("navToStall");
    if (navToStallObj) {
      let stallObj: any = session.getItem('stallObj');
      const item = JSON.parse(stallObj);
      navToStall(item.slug);
      session.setItem("navToStall", "");
    }
  }

  const signInCallBack = (response) => {
    let result = response.data;
    let authToken = result.authToken;

    let userDisplayName = authToken.firstName;
    if (authToken.lastName) {
      userDisplayName = `${userDisplayName} ${authToken.lastName}`;
    }

    session.setItem('id_token', "token " + authToken.sessionId);
    localstorage.setItem('ltk', authToken.sessionId);
    localstorage.setItem('userId', authToken.userId);
    localstorage.setItem('roleCode', authToken.roleCode);
    localstorage.setItem('userDisplayName', userDisplayName);
    localstorage.setItem('username', authToken.username);
    localstorage.setItem('userRoles', JSON.stringify(result.roles));
    globalDispatch({ type: "LOGIN_SUCCESS", payload: { isLogin: true } });
  }

  useEffect(() => {
    console.log(props);
    let code = props.match.params.code ? props.match.params.code : "";
    handleLogin(code);
    //initNav(code);
  }, [login]);



  return (
    <div className="pr-0 customerDashboard-ui pt-4">
      <Helmet>
        {
          metaInfo.metaTitle &&
          <title>{metaInfo.metaTitle}</title>
        }
        {
          metaInfo.metaDescription &&
          <meta name="description" content={metaInfo.metaDescription} />
        }
        {
          !metaInfo.metaDescription &&
          <meta name="description" content="Shop local, made easy" />
        }
      </Helmet>
      <div className="px-0">
        <div className="pt-5">
          <img src={banner} alt="banner" className="custom-head"></img>
        </div>
        <div className="title-font py-2 pt-2 text-center">
          <span color="primary" className="custom-link breadcrumbs-style">
            {/* <span className="pr-3">{stallCount}+ Stalls</span> */}
            {/* <span className="pr-3">{userCount}+ Users</span> */}
            <span className="pointer pr-3">
              <span onClick={openReviews}>
                <i className="fas fa-chevron-circle-right"></i>
                <span className='ml-2'>{reviewCount} Reviews</span>
              </span>
            </span>
          </span>

        </div>
      </div>

      {/* Desktop View */}
      <div className="deskview row pl-4">
        <div className="col-9 pt-4 pl-4 mr-0 pb-5">
          {login.isLogin &&
            <div className='pt-3 d-flex justify-content-center'>
              <TaskTracker />
            </div>
          }
          {/* {
            serviceCard.length > 0 && serviceCard.map((item, i) => (
              <div className="d-flex justify-content-center">
                <ServiceCard item={item} navToStallHome={navToStallHome} />
              </div>
            ))
          } */}
          {
            serviceCard.length > 0 &&
            <div className="d-flex justify-content-center">
              <ServiceCard items={serviceCard} navToStallHome={navToStallHome} />
            </div>
          }
          <div className="d-flex justify-content-center">
            <FeaturedProducts products={featuredProds} navToStallDetail={navToStallDetail} viewAllFeatureItems={viewAllFeatureItems} />
          </div>
        </div>
        <div className="col-3 pr-0 pt-3 pb-4" style={{ background: "#f69516" }}>
          <Slider
            dots={false}
            arrows={false}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            autoplay={true}
            vertical={true}
            verticalSwiping={true}
          >
            {renderSlides()}
          </Slider>
        </div>
      </div>

      {/* Tablet View */}
      <div className="tabview pb-5">
        <div>
          {login.isLogin &&
            <div className='pt-3 d-flex justify-content-center'>
              <TaskTracker />
            </div>
          }
        </div>
        <div>
          <Slider
            dots={true}
            arrows={true}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
          >
            {renderSlides()}
          </Slider>
        </div>

        <div>
          {/* {
            serviceCard.length > 0 && serviceCard.map((item, i) => (
              <div className="d-flex justify-content-center">
                <ServiceCard item={item} navToStallHome={navToStallHome} />
              </div>
            ))
          } */}
          {
            serviceCard.length > 0 &&
            <div className="d-flex justify-content-center">
              <ServiceCard items={serviceCard} navToStallHome={navToStallHome} />
            </div>
          }
          <div className="d-flex justify-content-center">
            <FeaturedProducts products={featuredProds} navToStallDetail={navToStallDetail} viewAllFeatureItems={viewAllFeatureItems} />
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="mobileview pb-5">
        <div>
          {login.isLogin &&
            <div className='pt-3 d-flex justify-content-center'>
              <TaskTracker />
            </div>
          }
        </div>
        <div>
          <Slider
            dots={true}
            arrows={true}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
          >
            {renderSlides()}
          </Slider>
        </div>

        <div className="pt-4">
          {/* {
            serviceCard.length > 0 && serviceCard.map((item, i) => (
              <div className="d-flex justify-content-center">
                <ServiceCard item={item} navToStallHome={navToStallHome} />
              </div>
            ))
          } */}
          {
            serviceCard.length > 0 &&
            <div className="d-flex justify-content-center">
              <ServiceCard items={serviceCard} navToStallHome={navToStallHome} />
            </div>
          }
          <div className="d-flex justify-content-center">
            <FeaturedProducts products={featuredProds} navToStallDetail={navToStallDetail} viewAllFeatureItems={viewAllFeatureItems} />
          </div>
        </div>
      </div>
      {/* {matches &&
        <div>
          <Dialog maxWidth='xl' fullWidth={true} open={open} onClose={handleClose} >
            <DownloadApp handleClose={handleClose} />
          </Dialog>
        </div>
      } */}
      <Drawer anchor={"right"} open={ReviewListOpen} onClose={handleReviewList}>
        <ReviewList handleReviewList={handleReviewList} communityId={communityId} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openLogin} onClose={handleLoginClose}>
        <LoginContainer handleLoginClose={handleLoginClose} onLoginCallBack={onLoginCallBack} landingTab="signup" />
      </Drawer>
      <Dialog open={featuredOpen} fullWidth onClose={closeFeaturedDialog}>
        <FeaturedItemList items={featuredProds} closeFeaturedDialog={closeFeaturedDialog} navToStallDetail={navToStallDetailFromDialog} />
      </Dialog>      
    </div>

  );
}
export default CustomerDashboard;