import React, { useEffect, useState } from 'react';
import { FxInput, http, AlertService, formValidator, general, FxSelect } from '../../fx-core';
import './ItemForm.scss';
import * as _ from 'lodash';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import { Button, Checkbox, Dialog } from '@material-ui/core';
import ImageSlides from '../../general/ImageSlides/ImageSlides';

const ItemForm = (props) => {
  const defaultItem = {
    imagePath: "",
    imageUrl: "",
    code: "",
    name: "",
    serviceId: -1,
    isActive: true,
    id: props.itemId ? props.itemId : 0,
    communityId: props.communityId,
    categoryId: -1,
    categories: "",
    isDefault: false
  };

  const [item, setItem] = useState(defaultItem);
  const [services, setServices] = useState([]);
  const [errors, setErrors] = useState({});
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [categories, setCategories] = useState([]);
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});

  const initLookup = () => {
    let inputData = {
      lookups: {
        Services: { default: false }
      },
      filters: {
        behaviorCodeFlag: true
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      initLookupCallBack(res);
    })
  }

  const initLookupCallBack = (res) => {
    let result = res.data;
    //let services = result.Services.filter(s => s.code == "ItemWise");
    setServices(result.Services);
    if (props.itemId > 0) {
      getItemById();
    }
  }

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    if (item.name == "serviceId") {
      getCategoriesByServiceId(item.value);
    }
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const getValidations = () => {
    const validationMap = {
      code: ["required"],
      name: ["required", "maxlen"],
      serviceId: ["select"]
    };
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      if (key == "name") {
        let result = { name: key, value: item[key], validations: validationMap[key], param: 25 }
        items.push(result);
      }
      else {
        let result = { name: key, value: item[key], validations: validationMap[key] }
        items.push(result);
      }
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const getItemById = () => {
    let apiOptions: any = {
      url: `community/item/${item.id}`
    };
    http.get(apiOptions).then(res => {
      getItemByIdCallBack(res);
    });
  }

  const getItemByIdCallBack = (res) => {
    const result = res.data;
    result.imageUrl = general.getImageUrl(result.imagePath);
    result.categoryId = result.categories && result.categories.length > 0 ? parseInt(result.categories) : -1;
    setItem(result);
    getCategoriesByServiceId(result.serviceId);
  }

  const getCategoriesByServiceId = (serviceId) => {
    let inputData = {
      serviceId: serviceId,
      communityId: item.communityId
    };
    let apiOptions = {
      url: 'category/service',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getCategoriesByServiceIdCallBack(res);
    })
  }

  const getCategoriesByServiceIdCallBack = (res) => {
    let result = res.data;
    setCategories(result.categories);
  }

  const saveItem = () => {
    const isValid = bulkValidate();
    if (isValid) {
      item.categories = item.categoryId > -1 ? item.categoryId.toString() : "";
      if (item.id > 0) {
        let apiOptions: any = {
          url: `community/item`,
          data: item
        };
        http.put(apiOptions).then(res => {
          saveItemCallBack(res.data);
        });
      }
      else {
        let apiOptions: any = {
          url: `community/${item.communityId}/item`,
          data: item
        };
        http.post(apiOptions).then(res => {
          saveItemCallBack(res.data);
        });
      }
    }
  };

  const saveItemCallBack = (res) => {
    AlertService.showSuccessMsg();
    if (res > 0) {
      handleClose();
    }
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: item.id,
      cameraConfig: { url: `item/savecameraimage` },
      fileUploadConfig: { url: `item/savefileimage` }
    };
    if (item.communityId > 0) {
      photoCaptureConfig = {
        id: item.id,
        cameraConfig: { url: `item/${item.communityId}/savecameraimage` },
        fileUploadConfig: { url: `item/${item.communityId}/savefileimage` }
      };
    }
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (item.id > 0) {
      getItemById();
    }
    else {
      let imagePath = res.filePath;
      let imageUrl = general.getImageUrl(imagePath);
      handleItemChange({ imagePath: imagePath, imageUrl: imageUrl });
    }
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const handleClose = () => {
    props.handleItemFormClose();
  }

  const openImages = () => {
    let config = {
      api: `community/item/${item.id}/images`,
      title: item.name,
      updateDefaultImageApi: `item/updateDefaultImage`,
      deleteImageApi: `item/deleteImage`,      
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
    setImageConfig({});
    getItemById();
  }

  useEffect(() => {
    initLookup();
  }, []);


  return (
    <div className='itemform-ui'>
      <div className='row title-font py-1 px-2 drawer-header'>
        <span className="col-11">
          Item Form
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>

      <div className='p-3 pt-5'>
        <div className='row p-3'>
          <div className="col-2 text-center">
            <div>
              {
                item.id > 0 &&
                <a className="pointer" onClick={openImages}>
                  {!item.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                  {item.imageUrl && <img src={item.imageUrl} alt="prod" width={100} height={100}></img>}
                </a>
              }
              {
                item.id == 0 &&
                <a className="pointer">
                  {!item.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                  {item.imageUrl && <img src={item.imageUrl} alt="prod" width={100} height={100}></img>}
                </a>
              }
              <div className="text-center camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 p-3'>
          <FxInput name="code" variant="outlined" fullWidth size="small" label="Code"
            value={item.code} onValueChange={handleInputChange} />
          {hasError("code", "required") &&
            <div className="error">Code is required</div>
          }
        </div>
        <div className='col-12 p-3'>
          <FxInput name="name" variant="outlined" fullWidth size="small" label="Name"
            value={item.name} onValueChange={handleInputChange} />
          {hasError("name", "required") &&
            <div className="error">Name is required</div>
          }
           {
              hasError("name", "maxlen") &&
              <div className="error">Name should be less than 25 characters</div>
            }
        </div>

        <div className='col-12 p-3'>
          <FxSelect name="serviceId" variant="outlined" label="Service"
            options={services}
            selectedValue={item.serviceId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
          {
            hasError("serviceId", "select") &&
            <div className="error">Service is required</div>
          }
        </div>

        <div className='col-12 p-3'>
          <FxSelect name="categoryId" variant="outlined" label="Categories"
            options={categories}
            selectedValue={item.categoryId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
          {
            hasError("serviceId", "select") &&
            <div className="error">Service is required</div>
          }
        </div>

        <div className='col-12 px-3'>
          <Checkbox name="isActive" color="primary" checked={item.isActive} value={item.isActive} onChange={handleCheckbox} />
          Active
        </div>

        <div className='col-12 px-3'>
          <Checkbox name="isDefault" color="primary" checked={item.isDefault} value={item.isDefault} onChange={handleCheckbox} />
          Default
        </div>        
      </div>
      <div className='px-4 py-2 drawer-footer'>
        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveItem}><b>Save</b></Button>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
    </div>
  );
}
export default ItemForm;
