import React, { useEffect, useState } from 'react';
import './MessageContainer.scss';
import MessageList from '../MessageList/MessageList';
import MessageThread from '../MessageThread/MessageThread';
import { localstorage, http } from '../fx-core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const MessageContainer = (props: any) => {

  let defaultContext = {
    userId: parseInt(localstorage.getItem("userId")),
    threadId: null,
    entityId: 0,
    msgEntityTypeId: 0,
    msgEntityDisplay: "",
    postId: 0,
    followedUserId: 0,
    isMyOrders: props.isMyOrders ? props.isMyOrders : false
  }
  const [currentStep, setCurrentStep] = React.useState(0);
  const [currentContext, setCurrentContext] = React.useState(defaultContext);

  const handleContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleClose = () => {
    props.handleMessageClose();
  }

  const handleMessageListClose = (threadId: any) => {
    if (threadId) {
      handleContextChange({ threadId: threadId });
      setCurrentStep(2);
    }
  }

  const handleMessageThreadClose = () => {
    setCurrentStep(1);
    handleContextChange({ threadId: null });
  }

  const getInputData = () => {
    let inputData: any = {};
    if (props.stallId > 0) {
      inputData.entityTypeId = 1; //1-Stall
      inputData.id = props.stallId;
    }
    else if (props.orderId > 0) {
      inputData.entityTypeId = 2; //2-Order
      inputData.id = props.orderId;
    }
    else if (props.postId > 0) {
      inputData.entityTypeId = 3; //3-Post
      inputData.id = props.postId;
    }
    else if (props.saleOrderId > 0) {
      inputData.entityTypeId = 4; //4-Sale Order
      inputData.id = props.saleOrderId;
    }
    else if (props.followedUserId > 0) {
      inputData.entityTypeId = 5; //4-User
      inputData.id = props.followedUserId;
    }
    return inputData;
  };

  const getContextObj = () => {
    let contextObj = {
      stallId: props.stallId > 0 ? props.stallId : 0,
      orderId: props.orderId > 0 ? props.orderId : 0,
      postId: props.postId > 0 ? props.postId : 0,
      saleOrderId: props.saleOrderId > 0 ? props.saleOrderId : 0,
      followedUserId: props.followedUserId > 0 ? props.followedUserId : 0
    };
    return contextObj;
  };

  const checkMessageThreadByContext = () => {
    let inputData = getInputData();
    let apiOptions: any = {
      url: `message/order/thread`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      checkMessageThreadByContextCallBack(res)
    });
  };

  const checkMessageThreadByContextCallBack = (res) => {
    const result = res.data;
    let contextObj: any = getContextObj();
    if (result != null && result.threadId && result.threadId.length > 0) {
      contextObj.threadId = result.threadId
    }
    handleContextChange(contextObj);
    setCurrentStep(2);
  };

  const getStallById = () => {
    let apiOptions: any = {
      url: `stall/${props.stallId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      getDataCallback(result.ownerId);
    })
  };

  const getLocalBusinessById = () => {
    let apiOptions: any = {
      url: `localbusinessdirectory/${props.postId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      getDataCallback(result.userId);
    });
  };

  const getDataCallback = (selectedUserId) => {
    if (selectedUserId == currentContext.userId) {
      setCurrentStep(1);
    }
    else {
      checkMessageThreadByContext();
    }
  }

  const checkForLoggedInUser = () => {
    if (props.stallId > 0) {
      getStallById();
    }
    else if (props.postId > 0) {
      getLocalBusinessById();
    }
    else if (props.followedUserId > 0) {
      getDataCallback(props.followedUserId);;
    }
  }

  useEffect(() => {
    if (props.stallId > 0 || props.postId > 0 || props.followedUserId > 0) {
      checkForLoggedInUser();
    }
    if (props.orderId > 0 || props.saleOrderId > 0) {
      checkMessageThreadByContext();
    }
    else {
      setCurrentStep(1);
    }
  }, [props]);


  const body = (
    <div className="message-container-ui">
      <div className="d-flex justify-content-between title-font py-1 px-4 sticky-top">
        <div>
          <span>Messages</span>
        </div>
        <div className="pointer" onClick={handleClose}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>

      <div className="d-flex">
        <div className="col-12 px-0">
          {
            currentStep == 1 &&
            <MessageList userId={currentContext.userId} handleMessageListClose={handleMessageListClose} />
          }
          {
            currentStep == 2 &&
            <MessageThread currentContext={currentContext} handleMessageThreadClose={handleMessageThreadClose} />
          }
        </div>
      </div>
    </div>

  );

  return (
    <div>
      {body}
    </div>
  );

}

export default MessageContainer;