import React, { useEffect, useState } from 'react';
import './LoginContainer.scss';
import SignIn from '../SignIn/SignIn';
import RegisterComponent from '../RegisterComponent/RegisterComponent';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import logo1 from "./logo1.png";

const LoginContainer = (props: any) => {

  let defaultValue = props.landingTab || "signin";
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSignInCallBack = () => {
    props.onLoginCallBack();
  }

  const handleClose = () => {
    props.handleClose();
  }

  const handleRegisterCallBack = () => {
    setValue("signin");
  }

  const showForgotPwd = () => {
    setValue("fgtpwd");
  }

  const forgotPwdCallBack = () => {
    setValue("signin");
  }

  useEffect(() => {
  }, []);


  const body = (
    <div className="login-ui col-12 pb-5">
      <div>
      <div className="row col-12 my-2 pr-0 justify-content-between">       
        <span className="logo1 col-10 text-center">
        </span>    
      </div>       
      
      {value == 'signin' &&
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="Sign In" value="signin" />
          <Tab label="Sign Up" value="signup" />
        </Tabs>
      }
      {value == 'signup' &&
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="Sign In" value="signin" />
          <Tab label="Register" value="signup" />
        </Tabs>
      }

      {value == 'fgtpwd' &&
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="Sign In" value="signin" />
          <Tab label="Forgot Password" value="fgtpwd" />
        </Tabs>
      }
     
      <div className="row">
        <div className="col-12">
          {
            value == 'signin' &&
            <div>
              <SignIn handleSignInCallBack={handleSignInCallBack} showForgotPwd={showForgotPwd} />
            </div>
          }
          <div className="col-12">
            {
              value == 'signup' &&
              <RegisterComponent handleRegisterCallBack={handleRegisterCallBack} />
            }
            {
              value == 'fgtpwd' &&
              <ForgotPassword forgotPwdCallBack={forgotPwdCallBack} />
            }
          </div>
        </div>
      </div>
      </div>
    </div>
 
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default LoginContainer;