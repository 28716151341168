import React, { useEffect, useState } from 'react';
import './ProviderList.scss';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Drawer, makeStyles } from '@material-ui/core';
import { http, useNavigationService, AlertService, FxCurrency, FxInput, FxSelect, localstorage } from '../../fx-core';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { NoRecords } from '../../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import StallForm from '../StallForm/StallForm';
import MessageContainer from '../../MessageContainer/MessageContainer';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ProviderList = () => {
  const { routeData, back } = useNavigationService();
  const classes = styles();

  let defaultContext: any = {
    searchText: "",
    communityId: routeData.communityId
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [providerList, setProviderList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [action, setAction] = useState("");
  const [stallFormOpen, setStallFormOpen] = useState(false);
  const [stallId, setStallId] = useState(0);
  const [communityList, setCommunityList] = useState([]);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [selProviderId, setSelProviderId] = useState(0);
  const [isView, setIsView] = useState(false);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    let filterObj = {
      searchText: currentContext.searchText,
      communityId: currentContext.communityId
    }
    if (item.name == "searchText") {
      filterObj.searchText = item.value;
    }
    else if (item.name == 'communityId') {
      filterObj.communityId = item.value;
    }
    getProviderList(filterObj);
  };

  const handleItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getProviderList = (data: any) => {
    let inputData = {
      name: data.searchText ? data.searchText : "",
      communityId: data.communityId
    };
    let apiOptions: any = {
      url: `community/providers/dashboard`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      setProviderList(res.data);
    })
  };

  const confirmEnable = (id: any) => {
    setSelectedUserId(id);
    setConfirmOpen(true);
    setMessage("Are you sure to enable livestream?");
    setAction("enable");
  }

  const confirmDisable = (id: any) => {
    setSelectedUserId(id);
    setConfirmOpen(true);
    setMessage("Are you sure to disable livestream?");
    setAction("disable");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if (action == "deeplink") {
      mailDeepLink();
    }
    else {
      enableOrDisableLiveStream();
    }
  }

  const enableOrDisableLiveStream = () => {
    var canHostEvent = action == 'enable' ? 1 : 0;
    let inputData = {
      id: selectedUserId,
      canHostEvent: canHostEvent
    };
    let apiOptions: any = {
      url: `user/enableordisablelivestream`,
      data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getProviderList(defaultContext);
    })
  };

  const confirmLink = (item: any) => {
    setSelectedUserId(item.id);
    setConfirmOpen(true);
    let msg = "About to send personalized stall link to " + item.email;
    setMessage(msg);
    setAction("deeplink");
  }

  const mailDeepLink = () => {
    let apiOptions: any = {
      url: `stall/provider/${selectedUserId}/slug/maildeeplinkqrcode`
    };
    http.get(apiOptions).then(res => {
      mailDeepLinkCallBack(res);
    })
  }

  const mailDeepLinkCallBack = (res) => {
    const msg = "Personalized stall link sent!";
    AlertService.showMsg({ message: msg });
  }

  const showStall = (item, communityTypeId) => {
    setStallId(item.id);
    setStallFormOpen(true);
    setIsView(communityTypeId == 2);
  }

  const handleStallFormClose = () => {
    setStallFormOpen(false);
    setStallId(0);
    setIsView(false);
  }

  const navToBack = () => {
    back();
  };

  const getMyRegionCommunities = () => {
    let inputData = {
      lookups: {
        MyRegionCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyRegionCommunitiesCallBack(response);
    })
  };

  const getMyRegionCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyRegionCommunities);
  };

  const getMyZoneCommunities = () => {
    let inputData = {
      lookups: {
        MyZoneCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyZoneCommunitiesCallBack(response);
    })
  }

  const getMyZoneCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyZoneCommunities);
  };

  const getMyAdminCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
  };

  const getMyAdminCommunitiesCallBack = (res) => {
    let result = res.data;
    let commuArr: any = [];
    for (let item of result) {
      let itemObj: any = {
        id: item.id,
        text: item.name
      };
      commuArr.push(itemObj);
    }
    setCommunityList(commuArr);
  };

  const initCalls = () => {
    if (routeData.parent == 'REGIONALMANAGER') {
      getMyRegionCommunities();
    }
    else if (routeData.parent == 'ZONALMANAGER') {
      getMyZoneCommunities();
    }
    else if (routeData.parent == 'COMMADMIN') {
      getMyAdminCommunities();
    }
  }

  const navToMessage = (providerId) => {
    setSelProviderId(providerId);
    setOpenMessage(true);
  }

  const handleMessageClose = () => {
    setSelProviderId(0);
    setOpenMessage(false);
  }

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  useEffect(() => {
    initCalls();
    getProviderList(defaultContext);
  }, []);

  return (
    <div className='provider-list-ui pt-4 mt-3'>
      <div className="title-font custom-head pl-4 pt-5">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
            <b>{routeData.communityName}</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Providers</Typography>
        </Breadcrumbs>
      </div>
      <div className='row my-4 mx-2'>
        <div className="col-3">
          <FxInput name="searchText" value={currentContext.searchText} variant="outlined" label="Search Provider"
            onValueChange={handleInputChange} fullWidth size="small"
          />
        </div>
        <div className='col-3'>
          <FxSelect name="communityId" variant="outlined"
            label="Community"
            options={communityList}
            selectedValue={currentContext.communityId}
            valueField="id"
            displayField="text"
            size="small"
            onValueChange={handleInputChange} />
        </div>
      </div>

      <div className='row my-4 mx-4 pt-2'>
        <TableContainer component={Paper}>
          <Table className="table-striped" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Community</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Stalls</TableCell>
                <TableCell align="right">Account Balance</TableCell>
                <TableCell align='center'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerList.length == 0 &&
                <TableCell colSpan={7}>
                  <NoRecords />
                </TableCell>
              }
              {providerList.length > 0 && providerList.map((row: any, i) => (
                <TableRow>
                  <TableCell>
                    {row.user.firstName}
                    {
                      row.user.lastName &&
                      <span className='ml-1'>{row.user.lastName}</span>
                    }
                  </TableCell>
                  <TableCell>
                    {row.user.communityName}
                  </TableCell>
                  <TableCell>
                    <span>
                      {row.user.mobile}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {row.user.email}
                    </span>
                  </TableCell>
                  <TableCell>
                    <div>
                      {row.stalls.map((item, i) => (
                        <div className='table-link'>
                          <Link className='pointer' underline="hover" onClick={() => showStall(item, row.communityTypeId)}>
                            {item.name}
                          </Link>
                        </div>
                      ))
                      }
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {row.account &&
                      <span className='pr-1'>
                        <FxCurrency value={row.account.currentBalance} />
                      </span>
                    }
                  </TableCell>
                  {
                    row.communityTypeId == 1 &&
                    <TableCell align='center'>
                      {row.user.canHostEvent == 0 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmEnable(row.user.id)}>Enable LiveStream</Button>
                        </span>
                      }
                      {row.user.canHostEvent == 1 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmDisable(row.user.id)}>Disable LiveStream</Button>
                        </span>
                      }
                      <span className='ml-2'>
                        <Button variant='outlined' size='small' className='button-style'
                          onClick={() => confirmLink(row.user)}>Personalized Link</Button>
                      </span>
                      <span className='ml-2'>
                        <Button variant='outlined' size='small' className='button-style' onClick={() => navToMessage(row.user.id)}>Direct</Button>
                      </span>
                    </TableCell>
                  }
                  {
                    row.communityTypeId == 2 &&
                    <TableCell></TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={stallFormOpen} onClose={handleStallFormClose}>
        <StallForm stallId={stallId} isView={isView} handleStallFormClose={handleStallFormClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
        <MessageContainer followedUserId={selProviderId} handleMessageClose={handleMessageClose} />
      </Drawer>
    </div>
  );
}
export default ProviderList;