import React, { useState, useEffect } from 'react';
import './ServiceRequestConfirm.scss';
import { formatService, http, general, AlertService, session } from '../../../fx-core';
import dummyImage from "../../../../assets/images/dummy.png";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Badge from '@material-ui/core/Badge';
import { Button, Link } from '@material-ui/core';

const ServiceRequestConfirm = (props: any) => {

  let defaultContext = {
    stallId: props.requestDetails.stallId,
    selectedStallName: "",
    serviceId: props.requestDetails.serviceId,
    communityId: props.requestDetails.communityId,
    serviceRequestType: props.requestDetails.serviceRequestType,
    accept: false,
    paymentStatus: "Pending"
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [requestDetails, setRequestDetails] = useState(props.requestDetails.selectedItems);
  const [isCreateStallDetail, setIsCreateStallDetail] = useState(false);

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  };

  const handleItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getStallById = () => {
    let apiOptions: any = {
      url: `portal/stall/${currentContext.stallId}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      getStallByIdCallBack(result);
    });
  }

  const getStallByIdCallBack = (res) => {
    handleItemChange({ "selectedStallName": res.name });
    for (let item of requestDetails) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
  }

  const collectserviceRequestDetails = () => {
    let serRequestDetails = [];
    for (let item of requestDetails) {
      let newItem = {
        itemId: item.id,
        itemName: item.name,
        categoryId: item.categoryId,
        categoryName: item.categoryName
      };
      serRequestDetails.push(newItem);
    }
    return serRequestDetails;
  }

  //Save Service Request
  const submitServiceRequest = () => {
    if (!currentContext.accept) {
      AlertService.showMsg({ message: "Please agree provider agreement" });
      return;
    }
    let serviceRequest: any = {
      serviceId: currentContext.serviceId,
      communityId: currentContext.communityId,
      serviceRequestType: currentContext.serviceRequestType,
      stallId: currentContext.stallId,
      currentDate: formatService.getCurrentDateForServer()
    };
    let serviceRequestDetails = collectserviceRequestDetails();
    let inputData = {
      ServiceRequest: serviceRequest,
      ServiceRequestDetails: serviceRequestDetails
    };
    let apiOptions: any = {
      url: `servicerequest/save`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      submitServiceRequestCallback(result);
    });
  }

  const submitServiceRequestCallback = (res) => {
    AlertService.showSuccessMsg("Your service request is confirmed");
    // if (res.isAutoApproved) {
    //     AlertService.showSuccessMsg("Thank you. All the items has been approved for sales.");
    // }
    session.setItem('otherCategoryItems', JSON.stringify([]));
    navigateToSummary(res.serviceReqId, res.subscriptionStatus);
  }

  const navigateToSummary = (reqId, status) => {
    props.onRequestConfirmCallback({ reqId: reqId, status: status });
  }

  const navigateToBack = () => {
    props.onRequestConfirmCallback();
  }

  const openProviderAgreement = () => {
    let url = general.getProviderAgreementUrl();
    if (url) {
      window.open(url, '_blank');
    }
  }

  const getProviderSubscriptionById = (id) => {
    let apiOptions: any = {
      url: `providersubscription/${id}`
    };
    http.get(apiOptions).then(res => {
      getProviderSubscriptionByIdCallBack(res);
    });
  }

  const getProviderSubscriptionByIdCallBack = (res) => {
    let result = res.data;
    handleItemChange({ paymentStatus: result.paymentStatus });
  }

  useEffect(() => {
    getStallById();
    if (props.requestDetails.isCreateStall && props.requestDetails.subscriptionId > 0) {
      setIsCreateStallDetail(true);
      getProviderSubscriptionById(props.requestDetails.subscriptionId);
    }
  }, []);

  return (
    <div className="pt-5 servicereq-confirm-ui">
      <div className="mx-4 mb-4">
        {
          !isCreateStallDetail &&
          <div className="col-12 ml-2"><b>{currentContext.selectedStallName}</b> </div>
        }
        {
          isCreateStallDetail &&
          <div>
            <b>Create Stall</b>
            <div className='pt-2'>
              {currentContext.selectedStallName} Created
            </div>
            <div className='pt-4'>
              <b>Subscription</b>
            </div>
            <div className='pt-3'>
              Status : {currentContext.paymentStatus}
            </div>
          </div>
        }
        <div>
          {
            requestDetails.length > 0 && requestDetails.map((itemObj, i) => (
              <div className="pt-2 row ml-1 align-items-center">
                <span className="col-3">
                  {!itemObj.imageUrl && <img src={dummyImage} alt="Stall" className="stallimage"></img>}
                  {itemObj.imageUrl && <img src={itemObj.imageUrl} alt="Stall" className="stallimage"></img>}
                </span>
                <span className="col-9 row">
                  <span className='font-14'>{itemObj.name}</span>
                  <span className='pl-2'>
                    <span className='badge-color px-2 pb-1 font-12'>{itemObj.categoryName}</span>
                    {/* <Badge badgeContent={itemObj.categoryName} color="primary">
                    </Badge> */}
                  </span>
                </span>
              </div>
            ))
          }
        </div>
        <div className="row pl-3">
          <div className="pt-3">
            <div className='my-1 d-flex pl-4 ml-4'>
              <span className='pr-1 color-grey'><i className="fas fa-info-circle"></i></span>
              <span className='color-grey pr-1'>Please read all details from</span>
              <span> <Link className="pointer" onClick={openProviderAgreement}> Provider Agreement </Link></span>
            </div>
            <div className='text-left pl-4 ml-3'>
              <Checkbox name="accept" color="primary" value={currentContext.accept} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
              I agree
            </div>
          </div>
          <div className="col-12 pt-4 m-4">
            <span className='pr-2'>
              <Button variant="contained" size="medium" onClick={navigateToBack}> Back </Button>
            </span>
            <Button variant="contained" size="medium" color="primary" onClick={submitServiceRequest}>  Submit  </Button>
          </div>
        </div>
      </div>
    </div>

  );
}
export default ServiceRequestConfirm;