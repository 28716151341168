import React from 'react';
import NumberFormat from 'react-number-format';

export const FxCurrency = (props: any) => {

  let currencyDisplay = 0;
  let decimalDisplay = 0;
  if (props.value) {
    let value = props.value.toFixed(2);
    var splits = value.split(".");
    currencyDisplay = splits[0];
    decimalDisplay = splits[1];
  } else {
    currencyDisplay = 0;
    decimalDisplay = 0
  }

  return (
    <span>
      {decimalDisplay == 0 &&
        <>
          <i className="fas fa-rupee-sign"></i>{currencyDisplay}
        </>
      }

      {decimalDisplay > 0 &&
        <>
          <i className="fas fa-rupee-sign"></i>{currencyDisplay}.{decimalDisplay}
        </>
      }

    </span>
  );

};