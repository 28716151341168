import { Button, Dialog, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CommunityList from './CommunityList/CommunityList';
import './CommunitySelection.scss';
//import communityselection from '../../assets/img/communityselection.png';
import { FxSelect, general, http, session, localstorage } from '../fx-core';
import * as _ from 'lodash';
import { isAndroid, isIOS } from 'react-device-detect';
import * as QueryString from "query-string";
import { useLocation } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import dummyImage from "../../assets/images/dummy.png";
import { Helmet } from "react-helmet";

const CommunitySelection = (props) => {
  const location = useLocation();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1260 },
      items: 4,
      slidesToSlide: 1
    },
    laptop: {
      breakpoint: { max: 1261, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const defaultContext = {
    tier2CommunityName: "",
    tier2CommunityId: -1
  };
  let defaultImageUrl = "";
  const [communityOpen, setCommunityOpen] = useState(false);
  const [tier2Communities, setTier2Communities] = useState([]);
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [featuredCommunities, setFeaturedCommunities] = useState([]);
  const defaultDevice = "";
  const [device, setDevice] = useState(defaultDevice);
  const params = QueryString.parse(location.search);
  console.log('query params=', params);

  const handleDialogClose = () => {
    setCommunityOpen(false);
  };

  const getTier2Communities = () => {

    let apiOptions: any = {
      url: `community/tiertwo/list`,
      data: {}
    };
    http.post(apiOptions).then(res => {
      getTier2CommunitiesCallBack(res);
    });
  };

  const getTier2CommunitiesCallBack = (res) => {
    setTier2Communities(res.data);
    if (res.data.length > 0) {
      const id = res.data[0].id;
      const name = res.data[0].name;
      let item = { tier2CommunityId: id, tier2CommunityName: name };
      handleContextChange(item);
    }
  };

  const handleCommunityChange = (item) => {
    const id = item.value;
    const name = getCommunityName(id);
    let context = { tier2CommunityId: id, tier2CommunityName: name };
    handleContextChange(context);
  };

  const showCommunityList = () => {
    setCommunityOpen(true);
  };

  const handleContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getCommunityName = (id) => {
    var item: any = _.find(tier2Communities, { id: id });
    return item.name;
  };

  const handleQueryParams = () => {
    if (params.c) {
      session.setItem('referralCode', params.c);
    }
    else if (params.p) {
      const val = params.p;
      session.setItem('pHex', val);
      resolveStallCommunity(val);
    }
    else if (params.s) {
      resolveStallCommunityService(params.s);
    }
  }

  const resolveStallCommunity = (pHex: any) => {
    if (pHex && pHex.length > 0) {
      let apiOptions: any = {
        url: `community/stall/${pHex}/resolve`
      };
      http.get(apiOptions).then(res => {
        resolveStallCommunityCallBack(res);
      })
    }
  };

  const resolveStallCommunityCallBack = (res) => {
    const result = res.data;
    if (result && result.linkUrl) {
      window.open(result.linkUrl, '_self');
    }
  };

  const resolveStallCommunityService = (slug: any) => {
    if (slug && slug.length > 0) {
      let apiOptions: any = {
        url: `community/stalls/${slug}/resolve`
      };
      http.get(apiOptions).then(res => {
        resolveStallCallBack(res);
      })
    }
  };

  const resolveStallCallBack = (res) => {
    const result = res.data;
    const stallObj = result.stall;
    session.setItem("navToStall", true);
    session.setItem("stallObj", JSON.stringify(stallObj));
    if (result && result.linkUrl) {
      window.open(result.linkUrl, '_self');
    }
  };

  const getFeaturedCommunities = () => {
    let apiOptions: any = {
      url: `community/featured`,
      data: {}
    };
    http.post(apiOptions).then(res => {
      getFeaturedCommunitiesCallBack(res);
    });
  };

  const getFeaturedCommunitiesCallBack = (res) => {
    let result = res.data;
    for (let item of result) {
      item.communityImageUrl = item.profileImagePath ? defaultImageUrl + item.profileImagePath : "";
    }
    setFeaturedCommunities(result);
  };

  const switchCommunityWithoutLogin = (communityId) => {
    let inputData = {
      communityId: communityId
    };
    let apiOptions: any = {
      url: `community/switch/withoutlogin`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result && result.linkUrl) {
        window.open(result.linkUrl, '_self');
      }
    })
  };

  const getImageUrlOnInit = () => {
    let defaultUrl = localstorage.getItem("imagStorageUrl");
    if (!defaultUrl) {
      let apiOptions: any = {
        url: `portal/image/storage/url`
      };
      http.get(apiOptions).then(res => {
        const result = res.data;
        localstorage.setItem('imagStorageUrl', result.config.imageStorageUrl);
        defaultImageUrl = result.config.imageStorageUrl;
      });
    }
    else {
      defaultImageUrl = defaultUrl != null ? defaultUrl : "";
    }
  }

  useEffect(() => {
    getImageUrlOnInit();
    handleQueryParams();
    // if (isAndroid) {
    //   window.open("https://hs.commkit.co.nz/android", "_self");
    // }
    // else if (isIOS) {
    //   window.open("https://hs.commkit.co.nz/ios", "_self");
    // }
    // else {
    //   setDevice("desktop");
    //   getTier2Communities();
    // }
    getTier2Communities();
    getFeaturedCommunities();
  }, []);

  return (
    // <>
    //   {device != "desktop" && <div className='text-center font-22 color-grey' style={{ marginTop: "30vh" }}>Loading...</div>}
    //   <>
    //     {device == "desktop" &&
    <div className='community-selection mt-5 mb-3'>
      <Helmet>
        <title>Localites Web App | shop local small business online</title>
        <meta name="description" content="Local community platform for shopping local - supporting entrepreuners, sidehustlers, hobbyists and home based small business, build a profitable business." />
      </Helmet>
      {/* <div className='pb-4 pt-5 mb-3 w-100 selection-header d-flex justify-content-center align-items-center'>
              <span>Localites in your local community ! Pick your community</span>
              <span className='ml-3 community-select'>
                <FxSelect name="tier2CommunityId" variant="outlined"
                  options={tier2Communities}
                  selectedValue={currentContext.tier2CommunityId}
                  valueField="id"
                  displayField="name"
                  size="small"
                  onValueChange={handleCommunityChange} />
              </span>
              <span className='ml-3'>
                <Button variant='contained' size="small" onClick={showCommunityList}>GO</Button>
              </span>
            </div> */}
      {/* <div style={{ backgroundImage: `url(${communityselection})`, backgroundRepeat: "no-repeat", width: "auto", height: "100vh", backgroundSize: "cover" }}> */}
      <div className='row justify-content-center mt-5'>
        <div className='text-center'>
          <div className='hero-text'>Localites Web App</div>
          {/* <div className='hero-text pl-1 text-spacing'>made easy.</div> */}
          <div className='landing-sub-text pt-4'>India's Only Authentic Hyperlocal Online Marketplace</div>
          {/* <div className='pt-5 mt-5'>
                    <div className='d-flex col-9 input-box p-3'>
                      <span className='col-8 px-0'>
                        <TextField placeholder='Enter your email' variant="filled" fullWidth size="small" />
                      </span>
                      <span className='col-4'>
                        <Button variant='contained' size="large">Subscribe</Button>
                      </span>
                    </div>
                  </div> */}
        </div>
      </div>


      <div className='pb-4 pt-5 mb-3 w-100 text-center'>
        {/* <span>Localites in your local community !</span> */}
        <div className='d-flex justify-content-center align-items-center pt-3'>
          <span className='community-select'>
            <div className='text-left color-aaa font-14'>Select your state</div>
            <FxSelect name="tier2CommunityId" variant="outlined"
              options={tier2Communities}
              selectedValue={currentContext.tier2CommunityId}
              valueField="id"
              displayField="name"
              size="small"
              onValueChange={handleCommunityChange} />
          </span>
          <span className='ml-3'>
            <div className='pt-3'></div>
            <Button variant='contained' size="medium" color='primary' onClick={showCommunityList}>GO</Button>
          </span>
        </div>
      </div>

      <div className="row col-12 py-3">
        <div className='col-md-1'></div>
        <div className='col-12 col-md-10 featuredcommunities-ui'>
          <div className='row justify-content-between pb-3 pt-5 py-3 px-5'>
            <div className='font-22 font-weight-bold color-secondary col-12 col-md-6'>Featured Communities</div>
            <div className='col-12 col-md-6 text-right'>
              <Button variant='contained' size="medium" color='primary' onClick={showCommunityList}>
                <span className='font-13'>View All</span>
              </Button>
            </div>
          </div>
          <Carousel responsive={responsive}
            autoPlay={false}
            showDots={false}
            arrows={true}
            infinite={false}
            swipeable={true}
            draggable={true}
          >
            {
              featuredCommunities.length > 0 && featuredCommunities.map((item: any, i) => (
                <div className='d-flex justify-content-center' key={i}>
                  <div className='community-card-wrapper'>
                    <div key={i} className='feature-community-card pointer' onClick={() => switchCommunityWithoutLogin(item.id)}>
                      {!item.communityImageUrl && <img src={dummyImage} alt="img" className="itemImg" ></img>}
                      {item.communityImageUrl && <img src={item.communityImageUrl} alt="img" className="itemImg"></img>}
                      <div className="category-ui pt-2 font-15 text-center">
                        {item.name}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </Carousel>
        </div>
        <div className='col-md-1'></div>
      </div>

      {/* </div> */}
      <Dialog
        open={communityOpen}
        maxWidth="md"
        fullWidth
        onClose={handleDialogClose}>
        <CommunityList handleDialogClose={handleDialogClose}
          tier2CommunityId={currentContext.tier2CommunityId}
          tier2CommunityName={currentContext.tier2CommunityName}
        />
      </Dialog>
    </div>
    //     }
    //   </>
    // </>
  );
}
export default CommunitySelection;