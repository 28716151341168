import React, { useEffect, useState } from 'react';
import { FxInput, FxTextarea, http, FxDate, FxTime, AlertService, localstorage, formValidator, general, formatService } from '../../fx-core';
import './GardenPetProfArtForm.scss';
import * as _ from 'lodash';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import { Button, Dialog, Link, Checkbox } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel'

const GardenPetProfArtForm = (props) => {
  const currentDate = formatService.getCurrentDateForServer();
  const defaultItem = {
    imagePathList: [],
    title: "",
    description: "",
    startDate: currentDate,
    startTime: "",
    startDateTime: "",
    endDate: "",
    endTime: "",
    endDateTime: "",
    id: props.saleId > 0 ? props.saleId : 0,
    serviceId: props.serviceId ? props.serviceId : 0,
    ownerId: parseInt(localstorage.getItem("userId")),
    communityId: parseInt(localstorage.getItem("communityId")),
    stallId: props.stallId ? props.stallId : 0,
    showLink: false,
    linkLabel: "",
    linkText: "",
    price: 0
  };
  const [item, setItem] = useState(defaultItem);
  const [images, setImages] = useState([]);
  const [notSavedImages, setNotSavedImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [stallName, setStallName] = useState(null);
  const [imagecount, setImagecount] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isEditFlow, setIsEditFlow] = useState(props.isEdit ? props.isEdit : false);
  const [listingInfo, setListedInfo] = useState(props.listingInfo ? props.listingInfo : { imagePathList: [] });

  const initMethods = () => {
    getStallById();
    if (item.id > 0) {
      getSaleById();
    }
    else if (listingInfo && listingInfo.title) {
      setItem(listingInfo);
      refreshItemImages(listingInfo.imagePathList.length > 0 ? listingInfo.imagePathList : []);
    }
  }

  const getStallById = () => {
    let apiOptions: any = {
      url: `stall/${item.stallId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setStallName(result.name);
    })
  }

  const getSaleById = () => {
    let inputData = {
      id: item.id
    };
    let apiOptions: any = {
      url: `sale/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      const result = response.data;
      result.startTime = result.startTime != null ? formatService.getConvertedTimeForDisplay(result.startTime) : null;
      result.endTime = result.endTime != null ? formatService.getConvertedTimeForDisplay(result.endTime) : null;
      setItem(result);
      getImages(result.id);
    });
  }

  const handleInputChange = (item) => {
    if (item.name == "stallName") {
      return;
    }
    else {
      handleItemChange({ [item.name]: item.value });
      item.validations = getInputValidations(item.name);
      const validationResult = formValidator.validate(item);
      const validationData = { [item.name]: validationResult };
      updateErrors(validationData);
    }
  }

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }

  const handleImageChange = (selectedIdx) => {
    setSelectedIndex(selectedIdx);
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts

  const getValidations = () => {
    let validationMap: any = {};
    validationMap.title = ["required"];
    validationMap.price = ["number"];
    if (item.showLink) {
      validationMap.linkLabel = ["required", "maxlen"];
      validationMap.linkText = ["required"];
    }
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      if (key == "linkLabel") {
        let result = { name: key, value: item[key], validations: validationMap[key], param: 10 }
        items.push(result);
      }
      else {
        let result = { name: key, value: item[key], validations: validationMap[key] }
        items.push(result);
      }
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const saveSaleForm = () => {
    const isValid = bulkValidate();
    if (isValid) {
      // if (item.startDate > item.endDate) {
      //   AlertService.showErrorMsg("End date should be greater than start date");
      //   return;
      // }
      item.startDateTime = formatService.getConvertedDate(item.startDate);
      const opt = { "addValue": 30, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" };
      item.endDate = formatService.getComputedDateFromInput(opt, item.startDate);
      item.endDateTime = item.endDate;
      if (item.id > 0) {
        //update sale form
        updateSaleForm();
      }
      else {
        //add sale form
        addSaleForm();
      }
    }
  };

  const addSaleForm = () => {
    let apiOptions: any = {
      url: `sale/add`,
      data: item
    };
    http.post(apiOptions).then(res => {
      saveSaleFormCallBack(res.data);
    })
  }

  const updateSaleForm = () => {
    let apiOptions: any = {
      url: `sale/update`,
      data: item
    };
    http.put(apiOptions).then(res => {
      saveSaleFormCallBack(res.data);
    })
  }

  const saveSaleFormCallBack = (res) => {
    AlertService.showSuccessMsg();
    props.handleClose();
  }

  const openPhotoCapture = () => {
    if ((item.id == 0 && item.imagePathList.length > 2) ||
      (item.id > 0 && imagecount > 2)) {
      AlertService.showErrorMsg("Maximum 3 photos allowed!");
      return;
    }
    else {
      let photoCaptureConfig = {
        id: item.id,
        cameraConfig: { url: `sale/savecameraimage` },
        fileUploadConfig: { url: `sale/savefileimage` },
        isCloseOnSuccess: true
      };
      if (item.communityId > 0) {
        photoCaptureConfig = {
          id: item.id,
          cameraConfig: { url: `sale/${item.communityId}/savecameraimage` },
          fileUploadConfig: { url: `sale/${item.communityId}/savefileimage` },
          isCloseOnSuccess: true
        };
      }
      setUploadConfig(photoCaptureConfig);
      setPhotoCaptureOpen(true);
    }
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (item.id > 0) {
      getSaleById();
    }
    else {
      let imageList: any = item.imagePathList || [];
      let imagePath = res && res.filePath ? res.filePath : "";
      imageList.push(imagePath);
      handleItemChange({ imagePathList: imageList });
      refreshItemImages(imageList);
    }
  }

  const refreshItemImages = (imageList) => {
    let result: any = [];
    for (let itemObj of imageList) {
      let imageUrl = general.getImageUrl(itemObj);
      result.push({ imageUrl: imageUrl });
    }
    setNotSavedImages(result);
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const updateDefaultImage = () => {
    let itemObj = images[selectedIndex];
    let inputData = {
      id: itemObj.id
    };
    let apiOptions: any = {
      url: `sale/updateDefaultImage`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg("Default image updated successfully");
      getImages(item.id);
    });
  }

  const deleteImage = () => {
    let itemObj = images[selectedIndex];
    let inputData = {
      id: itemObj.id
    };
    let apiOptions: any = {
      url: `sale/deleteImage`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg("Image deleted successfully");
      getImages(item.id);
    });

  }
  const deleteNotSavedImage = () => {
    let itemObj = notSavedImages[selectedIndex];
    deleteSelectedImage(itemObj);
  }

  const deleteSelectedImage = (itemObj: any) => {
    let itemIdx = _.findIndex(item.imagePathList, { imageUrl: item });
    item.imagePathList.splice(itemIdx, 1);
    refreshItemImages(item.imagePathList);
    AlertService.showSuccessMsg("Image deleted successfully");
  }

  const getImages = (id) => {
    let inputData = {
      id: id
    };
    let apiOptions: any = {
      url: `sale/${inputData.id}/images`
    };
    http.post(apiOptions).then(response => {
      getImagesCallback(response.data);
    });
  }

  const getImagesCallback = (res) => {
    for (let item of res) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setImages(res);
    setImagecount(res.length);
    setSelectedIndex(0);
  }

  const navigateToNext = () => {
    const isValid = bulkValidate();
    if (isValid) {
      let listingDetail: any = [];
      item.startDateTime = formatService.getConvertedDate(item.startDate);
      const opt = { "addValue": 30, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" };
      item.endDate = formatService.getComputedDateFromInput(opt, item.startDate);
      item.endDateTime = item.endDate;
      let listingInfo = {
        startDate: formatService.getConvertedDate(item.startDate),
        startDateTime: item.startDateTime,
        endDate: item.endDate,
        endDateTime: item.endDateTime,
        title: item.title,
        description: item.description,
        imagePathList: item.imagePathList,
        showLink: item.showLink,
        linkLabel: item.linkLabel,
        linkText: item.linkText,
        price: item.price
      };
      listingDetail.push({ "listingItem": listingInfo });
      props.onListingFormCallback(listingDetail);
    }
  }

  const navigateBack = () => {
    let listingDetail: any = [];
    listingDetail.push({ "listingItem": null });
    props.onListingFormCallback(listingDetail);
  }

  useEffect(() => {
    initMethods();
  }, []);


  return (
    <div className='gardenpetprofartform-ui px-3 pb-5'>
      <div className='px-2'>
        <div className='row justify-content-center font-18 text-bold'>Enter Listing Details</div>
        <div className='row justify-content-center'>
          <div className="col-xs-3 col-md-12 col-lg-12 text-center">
            {(notSavedImages && notSavedImages.length > 0) &&
              <Carousel onChange={handleImageChange}>
                {
                  notSavedImages && notSavedImages.map((itemObj: any) => (
                    <div className="text-center">
                      <img src={itemObj.imageUrl} alt="image"></img>
                    </div>
                  ))}
              </Carousel>
            }
            {(images && images.length > 0) &&
              <Carousel onChange={handleImageChange}>
                {
                  images && images.map((itemObj: any) => (
                    <div className="text-center">
                      <img src={itemObj.imageUrl} alt="image"></img>
                    </div>
                  ))}
              </Carousel>
            }
            {images.length == 0 && notSavedImages.length == 0 &&
              <span>
                <img src={dummyImage} alt="dummy" width={100} height={100}></img>
                <div className="text-center camera-align" onClick={openPhotoCapture}>
                  <CameraAltIcon className='pointer' />
                </div>
              </span>
            }
            {images && images.length > 0 &&
              <span className='row pb-3 pt-2'>
                <span className='col-5 text-right'>
                  <Link component="button" onClick={updateDefaultImage}>Set as default</Link>
                </span>
                <span className='col-2'>
                  <span className="text-center camera-align px-3" onClick={openPhotoCapture}>
                    <CameraAltIcon className='pointer' />
                  </span>
                </span>
                <span className='col-5 text-left'>
                  <i className="fas fa-trash-alt pointer font-18" onClick={deleteImage}></i>
                </span>
              </span>
            }
            {notSavedImages && notSavedImages.length > 0 &&
              <span className='row'>
                <span className='col-5'></span>
                <span className='col-2'>
                  <span className="text-center camera-align" onClick={openPhotoCapture}>
                    <CameraAltIcon className='pointer' />
                  </span>
                </span>
                <span className='col-5 text-left'>
                  <i className="fas fa-trash-alt pointer font-18" onClick={deleteNotSavedImage}></i>
                </span>
              </span>
            }
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-xs-12 col-md-8 p-3'>
            <FxInput name="stallName" variant="outlined" fullWidth size="small" label="Stall Name"
              value={stallName || ""} readOnly onValueChange={handleInputChange} />
          </div>
          <div className='col-xs-12 col-md-8 p-3'>
            <FxInput name="title" variant="outlined" fullWidth size="small" label="Title"
              value={item.title} onValueChange={handleInputChange} />
            {hasError("title", "required") &&
              <div className="error">Title is required</div>
            }
          </div>

          <div className='col-xs-12 col-md-8 p-3'>
            <FxTextarea name="description" variant="outlined" size="small" fullWidth maxRows={4} label="Description"
              value={item.description} onValueChange={handleInputChange} />
          </div>

          <div className='col-xs-12 col-md-8 p-3'>
            <FxInput name="price" variant="outlined" fullWidth size="small" label="Price" type="number"
              value={item.price} onValueChange={handleInputChange} />
            {hasError("price", "number") &&
              <div className="error">Price is required</div>
            }
          </div>

          {/* <div className='col-xs-12 col-md-8 px-3 py-2'>
            <FxDate name="startDate" variant="outlined" size="small" fullWidth label="Start Date"
              value={item.startDate} onValueChange={handleInputChange} />
            {
              hasError("startDate", "required") &&
              <div className="error">Start Date is required</div>
            }
          </div> */}

          <div className='col-xs-12 col-md-8 p-3'>
            <Checkbox name="showLink" color="primary" checked={item.showLink} value={item.showLink} onChange={handleCheckbox} />
            <span>Add Button</span>
          </div>
          {
            item.showLink &&
            <div className='col-xs-12 col-md-8 p-3'>
              <FxInput name="linkLabel" variant="outlined" fullWidth size="small" label="Label"
                value={item.linkLabel} onValueChange={handleInputChange} />
              {hasError("linkLabel", "required") &&
                <div className="error">Link label is required</div>
              }
              {hasError("linkLabel", "maxlen") &&
                <div className="error">Max char for link label is 10</div>
              }
            </div>
          }
          {
            item.showLink &&
            <div className='col-xs-12 col-md-8 p-3'>
              <FxInput name="linkText" variant="outlined" fullWidth size="small" label="Link"
                value={item.linkText} onValueChange={handleInputChange} />
              {hasError("linkText", "required") &&
                <div className="error">Link is required</div>
              }
            </div>
          }
        </div>
        <div className='row justify-content-center'>
          {isEditFlow ?
            <div className='drawer-footer py-2 col-4'>
              <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveSaleForm}><b>Save</b></Button>
            </div>
            :
            <div className='px-3 py-2'>
              <Button variant="contained" className='btn-style' onClick={navigateBack}><b>Back</b></Button>
              <Button color="primary" variant="contained" className='btn-style ml-2' onClick={navigateToNext}><b>Next</b></Button>
            </div>
          }
        </div>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
    </div>
  );
}
export default GardenPetProfArtForm;
