import React, { useEffect, useState } from 'react';
import './ProviderDashboard.scss';
import { http, formatService, localstorage } from '../../fx-core';
import ItemSaleDashboard from '../ItemSaleDashboard/ItemSaleDashboard';
import Followers from '../Followers/Followers';
import { NoRecords } from '../../NoRecords/NoRecords';

const ProviderDashboard = (props) => {

  let defaultContext = {
    stallId: props.match.params.id ? parseInt(props.match.params.id) : 0
  };
  const initialList = [];
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [stallList, setStallList] = useState(initialList);

  const handleCurrentContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getStallWithStatus = () => {
    let inputData = {
      lookups: {
        ProviderStalls: { default: false }
      },
      filters: {
        userId: localstorage.getItem('userId'),
        communityId: localstorage.getItem("communityId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data.ProviderStalls;
      setStallList(result);
      if (result.length > 0 && currentContext.stallId == 0) {
        selectedStall({ id: result[0].id });
      }
      else {
        selectedStall({ id: currentContext.stallId });
      }
    })
  };

  const selectedStall = (item) => {
    handleCurrentContextChange({ stallId: item.id });
  }

  useEffect(() => {
    getStallWithStatus();
  }, []);

  const body = (
    <div className="providerdashboard-ui pt-5">
      <div className='row title-font align-items-center pt-4'>
        <span className='pl-5 pt-3 py-1'>Provider Dashboard</span>
      </div>
      <div className="content-container">
        <div className="content">
          <div className="row col-12 mt-2 pt-5 justify-content-center">
            {
              stallList.length > 0 && stallList.map((item: any, i) => (
                <div className={item.id == currentContext.stallId ? 'menubtn px-2 pointer' : 'menubtn-selected px-2 pointer'}
                  onClick={() => selectedStall(item)}>
                  {item.text}
                </div>
              ))
            }
            {
              stallList.length == 0 &&
              <NoRecords />
            }
          </div>
          <div className='row col-12 pt-3 justify-content-center'>
            {
              currentContext.stallId > 0 &&
              <Followers stallId={currentContext.stallId} />
            }
          </div>

          <div className='mt-3'>
            {
              currentContext.stallId > 0 &&
              <div className='d-flex justify-content-center'>
                <ItemSaleDashboard stallId={currentContext.stallId} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default ProviderDashboard;