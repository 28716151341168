import React, { useEffect, useState } from 'react';
import './CommunityPosts.scss';
import LocalDrForm from '../LocalDrCard/LocalDrForm/LocalDrForm';
import { Drawer, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, makeStyles, Link } from '@material-ui/core';
import { http, localstorage, AlertService, useNavigationService } from '../fx-core';
import Dialog from '@material-ui/core/Dialog';
import { CommentDialog } from '../CommentDialog/CommentDialog';
import { NoRecords } from '../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const CommunityPosts = (props) => {
  const { routeData, back } = useNavigationService();

  const classes = styles();
  const [FormOpen, setFormOpen] = useState(false);
  const [localBusinessList, setLocalBusinessList] = useState([]);
  const [localBusinessDirectoryId, setLocalBusinessDirectoryId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmApproveOpen, setConfirmApproveOpen] = useState(false);
  const [confirmRejectOpen, setConfirmRejectOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [postedUserId, setPostedUserId] = useState(0);

  const getLocalBusinessList = () => {
    let inputData = {
      communityId: routeData.communityId,
      userId: 0
    };
    let apiOptions: any = {
      url: `localbusinessdirectory/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      setLocalBusinessList(res.data);
    })
  };

  const viewLocalBusinessForm = (id, userId) => {
    setLocalBusinessDirectoryId(id);
    setFormOpen(true);
    setPostedUserId(userId);
  }
  const handleForm = () => {
    setFormOpen(false);
    setLocalBusinessDirectoryId(0);
    setPostedUserId(0);
  }

  const confirmApprove = (id: any) => {
    setLocalBusinessDirectoryId(id);
    setConfirmApproveOpen(true);
    setMessage("Are you sure to approve this post?");
  }

  const closeApproveDialog = () => {
    setLocalBusinessDirectoryId(0);
    setConfirmApproveOpen(false);
  };

  const updateApproveStatus = (comment) => {
    let inputData: any = {
      id: localBusinessDirectoryId,
      postStatusId: 2, //Approve
      comments: comment
    };

    let apiOptions: any = {
      url: `localbusinessdirectory/updatepoststatus`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      updateApproveStatusCallBack(res);
    });
  };

  const updateApproveStatusCallBack = (res) => {
    AlertService.showSuccessMsg();
    closeApproveDialog();
    getLocalBusinessList();
  };

  const confirmReject = (id) => {
    setLocalBusinessDirectoryId(id);
    setConfirmRejectOpen(true);
    setMessage("Are you sure to reject this post?");
  };

  const closeRejectDialog = () => {
    setLocalBusinessDirectoryId(0);
    setConfirmRejectOpen(false);
  }

  const updateRejectStatus = (comment) => {
    let inputData: any = {
      id: localBusinessDirectoryId,
      postStatusId: 3, //Reject
      comments: comment
    };

    let apiOptions: any = {
      url: `localbusinessdirectory/updatepoststatus`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      updateRejectStatusCallBack(res);
    });
  };

  const updateRejectStatusCallBack = (res) => {
    AlertService.showSuccessMsg();
    closeRejectDialog();
    getLocalBusinessList();
  };

  const navToBack = () => {
    back();
  }

  useEffect(() => {
    getLocalBusinessList();
  }, []);

  return (
    <div className='community-posts-ui pt-4 mt-3'>
        <div className="title-font custom-head pl-4 pt-5">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
              <b>{routeData.communityName}</b>
            </Link>
            <Typography color="primary" className="breadcrumbs-style">Post</Typography>
          </Breadcrumbs>
        </div>
      {/* Desktop View */}
      <div className='deskview'>
        <div className='row my-4 mx-4 pt-5'>
          <TableContainer component={Paper}>
            <Table className="table-striped" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="15%">Title</TableCell>
                  <TableCell width="20%">Short Description</TableCell>
                  <TableCell width="42%">Long Description</TableCell>
                  <TableCell width="18%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localBusinessList.length == 0 &&
                  <TableCell colSpan={4}>
                    <NoRecords />
                  </TableCell>
                }
                {localBusinessList.length > 0 && localBusinessList.map((row: any, i) => (
                  <TableRow>
                    <TableCell>
                      {row.title}
                      {row.isListingInCommkit &&
                        <span className='commkit-wrapper ml-2'>
                          <span className="commkit-style px-1">Localites</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>
                      <span>
                        {row.shortDescription}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {row.longDescription}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='pr-1'>
                        <Button variant='outlined' size='small' className='button-style' onClick={() => viewLocalBusinessForm(row.id, row.userId)}>View</Button>
                      </span>
                      {row.postStatusId == 1 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmApprove(row.id)}>Approve</Button>
                          <span className="pl-1">
                            <Button variant='outlined' size='small' className='button-style'
                              onClick={() => confirmReject(row.id)}>Reject</Button>
                          </span>
                        </span>
                      }
                      {row.postStatusId == 2 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmReject(row.id)}>Reject</Button>
                        </span>
                      }
                      {row.postStatusId == 3 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmApprove(row.id)}>Approve</Button>
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Tablet View */}
      <div className='tabview'>
        <div className='row my-4 mx-4 pt-5'>
          <TableContainer component={Paper}>
            <Table className="table-striped" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="15%">Title</TableCell>
                  <TableCell width="20%">Short Description</TableCell>
                  <TableCell width="42%">Long Description</TableCell>
                  <TableCell width="18%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localBusinessList.length == 0 &&
                  <TableCell colSpan={4}>
                    <NoRecords />
                  </TableCell>
                }
                {localBusinessList.length > 0 && localBusinessList.map((row, i) => (
                  <TableRow>
                    <TableCell>
                      {row.title}
                      {row.isListingInCommkit &&
                        <span className='commkit-wrapper ml-2'>
                          <span className="commkit-style pl-2">Localites</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>
                      <span>
                        {row.shortDescription}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {row.longDescription}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='pr-1'>
                        <Button variant='outlined' size='small' className='button-style' onClick={() => viewLocalBusinessForm(row.id, row.userId)}>View</Button>
                      </span>
                      {row.postStatusId == 1 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmApprove(row.id)}>Approve</Button>
                          <span className="pl-1">
                            <Button variant='outlined' size='small' className='button-style'
                              onClick={() => confirmReject(row.id)}>Reject</Button>
                          </span>
                        </span>
                      }
                      {row.postStatusId == 2 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmReject(row.id)}>Reject</Button>
                        </span>
                      }
                      {row.postStatusId == 3 &&
                        <span>
                          <Button variant='outlined' size='small' className='button-style'
                            onClick={() => confirmApprove(row.id)}>Approve</Button>
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Mobile View */}
      <div className='mobileview my-5 mx-2'>
        {localBusinessList.map((row, i) => (
          <div>
            <Card className="mt-3 py-2 px-3">
              <div>
                <span className='font-14 font-weight-bold'>{row.title}</span>
                {row.isListingInCommkit &&
                  <span className='commkit-wrapper ml-2'>
                    <span className="commkit-style pl-2">Localites</span>
                  </span>
                }
              </div>
              <div className='font-13 short-desc py-2'>{row.shortDescription}</div>
              <div className='font-13 long-desc'>{row.longDescription}</div>
              <div className='text-right'>
                <span className='mr-3'>
                  <Button variant='outlined' size='small' className='button-style' onClick={() => viewLocalBusinessForm(row.id, row.userId)}>View</Button>
                </span>
                {row.postStatusId == 1 &&
                  <span>
                    <Button variant='outlined' size='small' className='button-style'
                      onClick={() => confirmApprove(row.id)}>Approve</Button>
                    <span className="pl-1">
                      <Button variant='outlined' size='small' className='button-style'
                        onClick={() => confirmReject(row.id)}>Reject</Button>
                    </span>
                  </span>
                }
                {row.postStatusId == 2 &&
                  <span>
                    <Button variant='outlined' size='small' className='button-style'
                      onClick={() => confirmReject(row.id)}>Reject</Button>
                  </span>
                }
                {row.postStatusId == 3 &&
                  <span>
                    <Button variant='outlined' size='small' className='button-style'
                      onClick={() => confirmApprove(row.id)}>Approve</Button>
                  </span>
                }
              </div>
            </Card>
          </div>
        ))}
      </div>

      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={FormOpen} onClose={handleForm}>
        <LocalDrForm handleForm={handleForm} localBusinessDirectoryId={localBusinessDirectoryId} ownerId={postedUserId} />
      </Drawer>
      <Dialog
        open={confirmApproveOpen}
        onClose={closeApproveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <CommentDialog handleComment={updateApproveStatus} closeCommentDialog={closeApproveDialog} message={message} />
      </Dialog>
      <Dialog
        open={confirmRejectOpen}
        onClose={closeRejectDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <CommentDialog handleComment={updateRejectStatus} closeCommentDialog={closeRejectDialog} message={message} />
      </Dialog>
    </div>
  );
}
export default CommunityPosts;