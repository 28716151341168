import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './PendingSaleOrders.scss';
import { FxDateDisplay, FxCurrency, http, formatService, AlertService } from '../../fx-core';
import * as _ from 'lodash';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Drawer } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import SaleOrderDetail from '../SaleOrderDetail/SaleOrderDetail';
import MessageContainer from '../../MessageContainer/MessageContainer';
import { NoRecords } from '../../NoRecords/NoRecords';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '1400px',
  },
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const PendingSaleOrders = (props: any) => {
  const classes = useStyles();
  const initialList = [];
  const selOrderDefaultObj = {
    id: 0,
    orderStatusId: 0,
    orderTypeId: 0
  };
  const [saleOrderList, setSaleOrderList] = useState(initialList);
  const [open, setOpen] = useState(false);
  const [saleOrderId, setSaleOrderId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(selOrderDefaultObj);
  const [isCancelAction, setIsCancelAction] = useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const getPendingSaleOrders = () => {
    let inputData = {
      id: props.stallId
    }
    let apiOptions: any = {
      url: `saleorder/pendingorders/stall/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      getPendingSaleOrdersCallback(result);
    })
  };

  const getPendingSaleOrdersCallback = (res) => {
    res = _.slice(res, [0], [5]);
    setSaleOrderList(res);
  }

  const viewSaleOrderDetail = (item: any) => {
    setOpen(true);
    setSaleOrderId(item.id);
  };

  const handleClose = () => {
    setOpen(false);
    setSaleOrderId(0);
    getPendingSaleOrders();
    props.loadDashboard();
  };

  const defaulConfirmDialogStateValues = () => {
    setSelectedOrder(selOrderDefaultObj);
    setMessage(null);
    setConfirmOpen(false);
    if (isCancelAction) {
      setIsCancelAction(false);
    }
    props.loadDashboard();
  };

  const openConfirmDialogByStatus = (item) => {
    let msg = "";
    if (item.orderStatusId === 1) {
      msg = "Do you want to accept the order #" + item.saleOrderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 1) {
      msg = "Do you want to ship the order #" + item.saleOrderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 2) {
      msg = "Is the order #" + item.saleOrderIdentifier + " ready?";
    }
    else if (item.orderStatusId === 4 || item.orderStatusId === 5) {
      msg = "Do you want to deliver the order #" + item.saleOrderIdentifier + "?";
    }
    setSelectedOrder(item);
    setMessage(msg);
    setConfirmOpen(true);
  };

  const handleDialogClose = () => {
    defaulConfirmDialogStateValues()
  };

  const onConfirmCallback = (action, comments) => {
    if (selectedOrder.orderStatusId === 1) {
      if (action === 'cancel') {
        cancelOrder(comments);
      } else {
        markAsAccepted();
      }
    }
    else if (selectedOrder.orderStatusId === 2) {
      markAsReadyOrShipped();
    }
    else if (selectedOrder.orderStatusId === 4 || selectedOrder.orderStatusId === 5) {
      markOrderDelivered();
    }
  };

  const markAsAccepted = () => {
    let apiOptions = {
      url: `saleorder/${selectedOrder.id}/markAsAccepted`
    };
    http.put(apiOptions).then(res => {
      markAsAcceptedCallBack(res);
    });
  };

  const markAsAcceptedCallBack = (res) => {
    AlertService.showMsg({ message: "Order is accepted" });
    defaulConfirmDialogStateValues();
    getPendingSaleOrders();
  };

  const markAsReadyOrShipped = () => {
    let apiOptions = {
      url: `saleorder/${selectedOrder.id}/MarkAsReadyOrShipped`
    };
    http.put(apiOptions).then(res => {
      markAsReadyOrShippedCallBack(res);
    });
  };

  const markAsReadyOrShippedCallBack = (res) => {
    let successMsg = "";
    if (selectedOrder.orderTypeId === 1) {
      successMsg = "Order shipped";
    }
    else if (selectedOrder.orderTypeId === 2) {
      successMsg = "Order ready";
    }
    AlertService.showMsg({ message: successMsg });
    defaulConfirmDialogStateValues();
    getPendingSaleOrders();
  };

  const markOrderDelivered = () => {
    let inputData = {
      id: selectedOrder.id,
      currentDate: formatService.getCurrentDateForServer()
    }
    let apiOptions = {
      url: `saleorder/MarkOrderDelivered`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      markOrderDeliveredCallBack(res);
    });
  };

  const markOrderDeliveredCallBack = (res) => {
    AlertService.showMsg({ message: "Order delivered" });
    defaulConfirmDialogStateValues();
    getPendingSaleOrders();
  };

  const openConfirmDialogForCancel = (item) => {
    setSelectedOrder(item);
    setIsCancelAction(true);
    setConfirmOpen(true);
  };

  const cancelOrder = (comments) => {
    let inputData = {
      id: selectedOrder.id,
      cancelledRemarks: comments
    }
    let apiOptions = {
      url: `saleorder/MarkOrderCancelled`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      cancelOrderCallBack(res);
    });
  };

  const cancelOrderCallBack = (res) => {
    defaulConfirmDialogStateValues();
    getPendingSaleOrders();
  };

  const navToMessage = (orderId) => {
    setSaleOrderId(orderId);
    setOpenMessage(true);
  }

  const handleMessageClose = () => {
    setOpenMessage(false);
    setSaleOrderId(0);
  }

  useEffect(() => {
    getPendingSaleOrders();
  }, [props.stallId])


  const body = (
    <div className="pendingsaleorders-ui">
      <div className="content-container">
        <div className="content">
          {/* Desktop View */}
          <div className='deskview'>
            <div className='py-2'>
              <b>Pending Listing Orders</b>
            </div>
            <div className='my-2'>
              <TableContainer component={Paper} className={classes.root}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell className="pl-0">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saleOrderList.length == 0 &&
                      <TableCell colSpan={9}>
                        <NoRecords />
                      </TableCell>
                    }
                    {saleOrderList.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell>
                          <div>
                            <span>{row.consumerName}</span>
                            <span className='pl-2'>{row.consumerPhone}</span>
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>#{row.saleOrderIdentifier}</TableCell>
                        <TableCell>
                          {row.orderStatus}
                          {
                            row.orderStatus === 'Cancelled' &&
                            <span className='pl-2'>({row.cancelledRemarks})</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.saleOrderPayments && row.saleOrderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span className='payment-style'>
                                  {'Card :' + itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell className="pl-0">
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => viewSaleOrderDetail(row)}>View Order</Button>
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Accept</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 1) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ship</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 2) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ready</Button>
                          }
                          {
                            (row.orderStatusId === 4 || row.orderStatusId === 5) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Deliver</Button>
                          }
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialogForCancel(row)}>Cancel</Button>
                          }
                          <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <SaleOrderDetail saleOrderId={saleOrderId} handleClose={handleClose} />
        </Dialog>
      </div>
      <div>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={isCancelAction ? "cancel" : ""} />
        </Dialog>
        <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
          <MessageContainer saleOrderId={saleOrderId} handleMessageClose={handleMessageClose} />
        </Drawer>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default PendingSaleOrders;