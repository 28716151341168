import React, { useEffect, useState } from 'react';
import './MyListingOrderDetail.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { TableFooter } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FxCurrency, http, general } from '../../fx-core';
import _ from "lodash";

const MyListingOrderDetail = (props: any) => {
  const initialList = [];
  let defaultSaleOrder = {
    saleOrderIdentifier: null,
    orderDate: null,
    orderTypeId: 0,
    stallName: null,
    orderTotal: 0,
    stallAddress: null
  }
  const [saleOrder, setSaleOrder] = useState(defaultSaleOrder);
  const [saleOrderLines, setSaleOrderLines] = useState(initialList);
  const [saleOrderPayments, setSaleOrderPayments] = useState(initialList);

  const getSaleOrderDetail = () => {
    let inputData = {
      id: props.saleOrderId
    };

    let apiOptions: any = {
      url: `saleorder/detail/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      setSaleOrder(result.saleOrder);
      setSaleOrderPayments(result.saleOrderPayments);

      let orderLinesData = result.saleOrderLines;
      for (let item of orderLinesData) {
        item.imageUrl = general.getImageUrl(item.imagePath);
      }
      setSaleOrderLines(orderLinesData);
    })
  }

  const handleClose = () => {
    props.handleClose();
  }


  useEffect(() => {
    getSaleOrderDetail();
  }, []);

  const body = (
    <div className='my-listing-order-detail'>
      <div className="row col-12 title-font py-1 mx-auto">
        <div className="col-11 pl-0">
          <span>Order Detail</span>
        </div>
        <div className="col-1 text-right pointer px-0" onClick={handleClose}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>


      {/* Desktop View */}
      <div className='deskview'>
        <div className='row mr-3 my-3 ml-0'>
          <div className='col-7'>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >Items</TableCell>
                    <TableCell width="4%" >Qty</TableCell>
                    <TableCell width="1%" ></TableCell>
                    <TableCell width="23%" align="right" >Price</TableCell>
                    <TableCell width="15%" align="right" >Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleOrderLines.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell className="font-12">
                        {row.itemName}
                      </TableCell>
                      <TableCell className="table-font">
                        <span>{row.itemQuantity}</span>
                      </TableCell>
                      <TableCell>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          {row.itemQuantity} * <FxCurrency value={row.price} />
                        </span>
                      </TableCell>
                      <TableCell align="right" style={{ width: 220 }}>
                        <span className="table-font">
                          <FxCurrency value={row.lineTotalPrice} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <span className="table-font">Total</span>
                    </TableCell>
                    <TableCell align="right"><span className="table-font">
                      <FxCurrency value={saleOrder.orderTotal} />
                    </span></TableCell>
                  </TableRow>

                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <div className='col-5 summary-detail font-14'>
            <div className='row'>
              <div className='col-4'>Order #</div>
              <div className='col-8'>: {saleOrder.saleOrderIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Ordered Date</div>
              <div className='col-8'>: {general.getFormattedDate(saleOrder.orderDate)}</div>
            </div>
            {saleOrderPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Order total</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Coupon Value</span>
                    }
                  </div>
                  <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>Payment Mode</div>
                  <div className='col-8'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'stripe' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span className="pr-1">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Payment Status</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Discount Status</span>
                    }
                  </div>
                  <div className='col-8'>
                    : <span>{item.paymentStatus}
                      {item.paymentMode == 'stripe' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                        <span>
                          Pay
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      {/* Tablet View */}
      <div className='tabview'>
        <div className='row mr-3 my-3 ml-0'>
          <div className='col-7'>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >Items</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell align="right" >Price</TableCell>
                    <TableCell align="right" >Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleOrderLines.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell className="table-font">
                        {row.itemName}
                      </TableCell>
                      <TableCell className="table-font">
                        <span>{row.itemQuantity}</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          {row.itemQuantity} * <FxCurrency value={row.price} />
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={row.lineTotalPrice} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <span className="table-font">Total</span>
                    </TableCell>
                    <TableCell align="right"><span className="table-font">
                      <FxCurrency value={saleOrder.orderTotal} />
                    </span></TableCell>
                  </TableRow>

                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <div className='col-5 summary-detail font-14'>
            <div className='row'>
              <div className='col-4'>Order #</div>
              <div className='col-8'>: {saleOrder.saleOrderIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Ordered Date</div>
              <div className='col-8'>: {general.getFormattedDate(saleOrder.orderDate)}</div>
            </div>
            {saleOrderPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Order total</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Coupon Value</span>
                    }
                  </div>
                  <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>Payment Mode</div>
                  <div className='col-8'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'stripe' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span className="pr-1">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Payment Status</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Discount Status</span>
                    }
                  </div>
                  <div className='col-8'>
                    : <span>{item.paymentStatus}
                      {item.paymentMode == 'stripe' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                        <span>
                          Pay
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      {/* Mobile View */}
      <div className='mobileview'>
        <div className='mx-2 my-3 ml-0'>
          <div className='summary-detail font-13 mb-3'>
            <div className='row'>
              <div className='col-5'>Order #</div>
              <div className='col-7'>: {saleOrder.saleOrderIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-5'>Ordered Date</div>
              <div className='col-7'>: {general.getFormattedDate(saleOrder.orderDate)}</div>
            </div>
            {saleOrderPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-5'>
                    {item.paymentMode != 'coupon' &&
                      <span>Order total</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Coupon Value</span>
                    }
                  </div>
                  <div className='col-7'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-5'>Payment Mode</div>
                  <div className='col-7'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'stripe' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span className="pr-1">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-5'>
                    {item.paymentMode != 'coupon' &&
                      <span>Payment Status</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Discount Status</span>
                    }
                  </div>
                  <div className='col-7'>
                    : <span>{item.paymentStatus}
                      {item.paymentMode == 'stripe' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                        <span>
                          Pay
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >Items</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleOrderLines.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell className="table-font pr-0">
                        {row.itemName}
                      </TableCell>
                      <TableCell className="table-font">
                        <span>{row.itemQuantity}</span>
                      </TableCell>
                      <TableCell align="right" className='px-0'>
                        <span className="table-font">
                          {row.itemQuantity} * <FxCurrency value={row.price} />
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={row.lineTotalPrice} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <span className="table-font">Total</span>
                    </TableCell>
                    <TableCell align="right"><span className="table-font">
                      <FxCurrency value={saleOrder.orderTotal} />
                    </span></TableCell>
                  </TableRow>

                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default MyListingOrderDetail;