import React, { useEffect, useState } from 'react';
import './ListingSaleBehavior.scss';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { http, general, useNavigationService, FxCurrency, FxPopup } from '../../fx-core';
import { useDispatch, useSelector } from 'react-redux';
import secondset from "../../../assets/secondset.svg";
import dummyImage from "../../../assets/images/dummy.png";
import * as _ from 'lodash';
import info from "../../../assets/info.png";

const ListingSaleBehavior = (props) => {
  const dispatch = useDispatch();
  const listingCartData = useSelector<any, any>(s => s.listingCart);
  const cartData = useSelector<any, any>(s => s.cart);

  let defaultHeader: any = {
    title: "",
    startDateTime: "",
    endDateTime: "",
    description: ""
  };
  const [saleHeader, setSaleHeader] = useState(defaultHeader);
  const [saleItemList, setSaleItemList] = useState([]);

  const getSaleById = () => {
    let apiOptions: any = {
      url: `portal/sale/${props.saleId}/stall`,
      data: {}
    };
    http.get(apiOptions).then(response => {
      getSaleCallBack(response.data);
    });
  }

  const getSaleCallBack = (item: any) => {
    item.imageUrl = general.getImageUrl(item.imagePath);
    setSaleHeader(item);
  }

  const getSaleItemListBySaleId = () => {
    let apiOptions: any = {
      url: `portal/saleitem/available/${props.saleId}`,
      data: {}
    };
    http.get(apiOptions).then(response => getSaleItemListBySaleIdCallBack(response));
  };

  const getSaleItemListBySaleIdCallBack = (res) => {
    let result = res.data;
    let hasCartData = (listingCartData && listingCartData.items && listingCartData.items.length > 0);
    for (let itemObj of result) {
      for (let item of itemObj.saleItems) {
        item.imageUrl = general.getImageUrl(item.imagePath);
        item.quantity = 0;
        if (hasCartData) {
          let cartItem = _.find(listingCartData.items, { id: item.id });
          if (cartItem) {
            item.quantity = cartItem.quantity;
          }
        }
      }
    }
    setSaleItemList(result);
  };

  const reduceQtyFromCart = (saleItem) => {
    dispatch({ type: "REDUCE_LISTING_CART", payload: { stallId: saleHeader.stallId, item: saleItem } });
  };

  const addQtyToCart = (saleItem) => {
    if (cartData.cartCount > 0) {
      dispatch({ type: "CLEAR_CART", payload: {} }); //Clear the service order items
    }
    dispatch({ type: "ADD_LISTING_CART", payload: { stallId: saleHeader.stallId, item: saleItem } });
  };

  const updateQuntity = () => {
    let cartItemsArr: any = saleItemList.map((i: any) => {
      let saleItems = i.saleItems.map((item) => {
        let cartItem = _.find(listingCartData.items, { id: item.id });
        if (cartItem) {
          item.quantity = cartItem.quantity;
        }
        else {
          item.quantity = 0;
        }
        return item;
      });
      i.saleItems = saleItems;
      return i;
    });
    setSaleItemList(cartItemsArr);
  };

  const showListingDetails = () => {
    props.showListingDetails();
  };

  useEffect(() => {
    getSaleById();
    getSaleItemListBySaleId();
  }, []);

  useEffect(() => {
    updateQuntity();
  }, [listingCartData]);

  return (
    <div className='listingsale-ui'>
      <div className="title-font pt-2 mt-1">
        <Breadcrumbs aria-label="breadcrumb">
          <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={showListingDetails}>
            <b>All Listings</b>
          </span>
          <Typography color="textPrimary" className="breadcrumbs-style">{saleHeader.title}</Typography>
        </Breadcrumbs>
      </div>
      {/* Desktop View */}
      <div className="row deskview">
        <div className="col-12 pt-4 pl-0">
          {/* <div className='d-flex pl-5 ml-2 py-2'>
            <div>
              {!saleHeader.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
              {saleHeader.imageUrl && <img src={saleHeader.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
            </div>
            <div className="pl-4 ml-2 pb-2 col-10">
              <div className="font-12 text-success shopstatus-style pt-2">
                {saleHeader.description}
              </div>
            </div>
          </div>
          <hr /> */}
          <div className='row px-3 pl-5 pb-5'>
            {
              saleItemList.length < 1 &&
              <div className="py-5 mx-auto">
                <img src={secondset} alt="first set"></img>
                <div className="pt-4 font-17"><b> Sorry, No Listings Available.</b></div>
              </div>
            }
            {
              saleItemList.length > 0 && saleItemList.map((itemObj: any, i) => (
                <div className="col-4 pt-4" key={i}>
                  <div className='text-center'>
                    <span className="font-22"><u>{itemObj.groupName}</u></span>
                  </div>
                  {
                    itemObj.saleItems.map((item, j) => (
                      <div key={j}>
                        <div className="row">
                          <div className="col-6 text-center py-2">
                            {!item.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
                            {item.imageUrl && <img src={item.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
                          </div>
                          <div className="col-6 text-right pt-3">
                            <div className="row justify-content-end mr-3">
                              <div className="font-24"><b><FxCurrency value={item.price} /></b></div>
                            </div>
                            <div className="row justify-content-end mr-3 font-24 pt-3">
                              <div className="d-flex align-items-center justify-content-between b" style={{ width: 80, height: 29 }}>
                                <span className="count-minus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-minus-square"></i>
                                </span>
                                <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer"
                                  style={{ flex: 1 }}>{item.quantity}</span>
                                <span className="count-plus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(item)} style={{ flex: 1 }}>
                                  <i className="fas fa-plus-square"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <span className='font-20'>{item.itemName}</span>
                          {
                            item.description &&
                            <span className='info-placement pl-2 zoom-in-out-box'>
                              <FxPopup image={info}
                                popupId={`listing-${item.id}`} maxWidth="370px" fontSize="12px">
                                <div className='p-3 card-style'>
                                  <span>{item.description}</span>
                                </div>
                              </FxPopup>
                            </span>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingSaleBehavior;