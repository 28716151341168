import React, { useEffect, useState } from 'react';
import './ProviderServiceRequests.scss';
import { FxInput, FxDateDisplay, http, useNavigationService, AlertService } from '../../fx-core';
import _ from 'lodash';
import { Button, Card, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Drawer, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ProviderServiceRequestDetail from '../ProviderServiceRequestDetail/ProviderServiceRequestDetail';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { NoRecords } from '../../NoRecords/NoRecords';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ProviderServiceRequests = (props: any) => {
  const classes = styles();

  let defaultFilter: any = {
    serviceName: ""
  };
  const { navigate } = useNavigationService();
  const initialList = [];
  const [requestList, setRequestList] = useState(initialList);
  const [filter, setFilter] = useState(defaultFilter);
  const [open, setOpen] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState({});

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
  };

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getServiceRequest = (options: any = {}) => {
    let inputData = {
      serviceName: filter.serviceName
    };
    let apiOptions: any = {
      url: 'servicerequest/user/query',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getServiceRequestCallBack(res);
    })
  };

  const getServiceRequestCallBack = (res) => {
    setRequestList(res.data);
  };

  const navigateToServices = () => {
    let navData = {
      targetRoute: '/provider/providerservices/',
      targetTitle: "Create Stall",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  };

  const viewRequestDetail = (item) => {
    setOpen(true);
    setRequestId(item.id);
  };

  const handleClose = () => {
    setOpen(false);
    setRequestId(0);
  };

  const openConfirmDialog = (item) => {
    let msg = "Do you want to cancel the service request #" + item.serviceRequestIdentifier;
    setSelectedRequest(item);
    setMessage(msg);
    setConfirmOpen(true);
  };

  const handleDialogClose = () => {
    defaulConfirmDialogStateValues()
  };

  const onConfirmCallback = (action, comments) => {
    let item = { comments: comments };
    let inputData = { ...selectedRequest, ...item };
    cancelRequest(inputData);
  };

  const defaulConfirmDialogStateValues = () => {
    setSelectedRequest({});
    setMessage(null);
    setConfirmOpen(false);
  };

  const cancelRequest = (inputData) => {
    let apiOptions = {
      url: `servicerequest/cancelled`,
      data: inputData
    };
    http.put(apiOptions).then(res => {
      cancelRequestCallBack(res);
    });
  };

  const cancelRequestCallBack = (res) => {
    AlertService.showMsg({ message: "Service request cancelled" });
    defaulConfirmDialogStateValues();
    getServiceRequest();
  };

  useEffect(() => {
    getServiceRequest();
  }, []);

  useEffect(() => {
    if (filter.serviceName.length > 0) {
      getServiceRequest();
    }
  }, [filter]);


  const body = (
    <div className="servicereqlist-ui pt-5">
      <div className="content-container">
        <div className="content">
          {/* Desktop View */}
          <div className='deskview'>
            <div className='row title-font align-items-center pt-1'>
              <span className='pl-5 pt-3 py-1'>Service Requests</span>
            </div>
            <div className="row col-12 mt-4 pl-5">
              <div className="col-6 px-0">
                <FxInput
                  name="serviceName"
                  variant="outlined"
                  label="Search"
                  size="small"
                  value={filter.serviceName}
                  onValueChange={handleInputChange}
                />
              </div>
              <div className="col-6 pr-0 text-right">
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  onClick={navigateToServices}
                >
                  <i className="fas fa-plus pr-2"></i>
                  Add
                </Fab>
              </div>
            </div>
            <div className='my-4 mx-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Request #</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Requested By</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Service Name</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestList.length == 0 &&
                      <TableCell colSpan={7}>
                        <NoRecords />
                      </TableCell>
                    }
                    {requestList.length > 0 && requestList.map((row: any, i) => (
                      <TableRow>
                        <TableCell>#{row.serviceRequestIdentifier}</TableCell>
                        <TableCell>{row.serviceRequestType}</TableCell>
                        <TableCell><FxDateDisplay value={row.requestDate} /></TableCell>
                        <TableCell>{row.requestedByName}</TableCell>
                        <TableCell>{row.serviceRequestStatus}</TableCell>
                        <TableCell>{row.communityName} - {row.serviceName}</TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewRequestDetail(row)}>View Request</Button>
                          {
                            row.serviceRequestStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialog(row)}>Cancel</Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Tablet View */}
          <div className='tabview'>
            <div className='ml-0 mt-4'>
            </div>

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Request #</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Requested By</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Service Name</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestList.map((row, i) => (
                      <TableRow>
                        <TableCell>#{row.serviceRequestIdentifier}</TableCell>
                        <TableCell>{row.serviceRequestType}</TableCell>
                        <TableCell><FxDateDisplay value={row.requestDate} /></TableCell>
                        <TableCell>{row.requestedByName}</TableCell>
                        <TableCell>{row.serviceRequestStatus}</TableCell>
                        <TableCell>{row.communityName} - {row.serviceName}</TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewRequestDetail(row)}>View Request</Button>
                          {
                            row.serviceRequestStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialog(row)}>Cancel</Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Mobile View */}
          <div className='mobileview mb-4'>
            <div className='ml-0 mt-4 mb-4'>
            </div>
            {requestList.map((row, i) => (
              <div>
                <Card className="mt-3 py-2 px-3">
                  <div className='row justify-content-between px-2'>
                    <div>
                      <div className='font-13 bold-text'>#{row.serviceRequestIdentifier}</div>
                      <div className='ml2'>{row.serviceRequestType}</div>
                    </div>
                    <div className='text-right'>
                      <div className='font-11'><FxDateDisplay value={row.requestDate} /></div>
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-2'>
                    <div className='font-13'>{row.requestedByName}</div>
                    <div className='font-13 text-right'>
                      {row.serviceRequestStatus}
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-1'>
                    <div className='font-13'>
                      {row.communityName} - {row.serviceName}
                    </div>
                    <div className='text-right'>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => viewRequestDetail(row)}>View Request</Button>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>

        </div>
      </div>
      {/* <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ProviderServiceRequestDetail requestId={requestId} handleClose={handleClose} />
        </Dialog>
      </div> */}
      <Drawer anchor={"right"} open={open} onClose={handleClose} classes={{ paper: classes.drawerWidth }}>
        <ProviderServiceRequestDetail requestId={requestId} handleClose={handleClose} />
      </Drawer>
      <div>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={"cancel"} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default ProviderServiceRequests;