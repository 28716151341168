import React, { useEffect, useState } from 'react';
import './ProviderLocalDrList.scss';
import LocalDrForm from '../../LocalDrCard/LocalDrForm/LocalDrForm';
import { Drawer, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Link, Fab } from '@material-ui/core';
import { http, localstorage, AlertService, useNavigationService } from '../../fx-core';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ProviderLocalDrList = (props) => {
  const classes = styles();
  const { navigate } = useNavigationService();
  const stallId = props.match.params.id ? props.match.params.id : 0;
  const [FormOpen, setFormOpen] = useState(false);
  const [localBusinessList, setLocalBusinessList] = useState([]);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [localBusinessDirectoryId, setLocalBusinessDirectoryId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [message, setMessage] = useState("");
  const [postedUserId, setPostedUserId] = useState(0);

  const editLocalBusinessForm = (id, userId) => {
    setLocalBusinessDirectoryId(id);
    setPostedUserId(userId);
    setFormOpen(true);
  }

  const handleForm = () => {
    setFormOpen(false);
    setLocalBusinessDirectoryId(0);
    setPostedUserId(0);
    getLocalBusinessList();
  }

  const getLocalBusinessList = () => {
    let inputData = {
      communityId: communityId,
      userId: userId
    };
    let apiOptions: any = {
      url: `localbusinessdirectory/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      setLocalBusinessList(res.data);
    })
  };

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setConfirmOpen(true);
    setMessage("Do you want to delete this post?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    deleteLocalBusinessPost(selectedItem);
  }

  const deleteLocalBusinessPost = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `localbusinessdirectory/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getLocalBusinessList();
    })
  };

  const Razorpay = useRazorpay();

  const initiatePayment = (localDrId) => {
    let inputData = {
      entityTypeId: 2,
      entityId: localDrId
    };
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item =  resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {        
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        getLocalBusinessList();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
      //TODO: Api call
      //session_id - must
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const openForm = () => {
    editLocalBusinessForm(0, userId);
  };

  const navToDashboard = () => {
    let navData = {
      targetRoute: '/provider/provdashboard/' + stallId,
      targetTitle: "Provider Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    getLocalBusinessList();
  }, []);

  return (
    <div className='provider-local-drlist-ui pt-5'>
      <div className="title-font pt-5 pl-4">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToDashboard}>
            <b>Provider Dashboard</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Local Business Directory</Typography>
        </Breadcrumbs>
      </div>

      <div className='pt-3'>
        <div className='row justify-content-between px-4'>
          <div className="col-12 text-right">
            <Fab
              variant="extended"
              color="default"
              onClick={openForm}>
              <i className="fas fa-plus pr-2"></i>
              Post
            </Fab>
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className='deskview'>
        <div className='my-4 mx-3'>
          <TableContainer component={Paper}>
            <Table className="table-striped" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Short Description</TableCell>
                  <TableCell>Long Description</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell width="18%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localBusinessList.map((row: any, i) => (
                  <TableRow>
                    <TableCell>
                      {row.title}
                      {row.isListingInCommkit &&
                        <span className='commkit-wrapper ml-2'>
                          <span className="commkit-style pl-2">Localites</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>{row.shortDescription}</TableCell>
                    <TableCell>
                      {row.longDescription}
                    </TableCell>
                    <TableCell>
                      {row.postStatusId == 1 &&
                        <span>Posted</span>
                      }
                      {row.postStatusId == 2 &&
                        <span>Approved</span>
                      }
                      {row.postStatusId == 3 &&
                        <span>Rejected</span>
                      }
                    </TableCell>
                    <TableCell>
                      {row.paymentMode &&
                        <span>{row.paymentMode}
                          <span> - {row.paymentStatus}</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => editLocalBusinessForm(row.id, row.userId)}>Edit</Button>
                      <Button variant='outlined' size='small' className='button-style ml-1' onClick={() => confirmDelete(row)}>Delete</Button>
                      {row.paymentMode == 'razorpay' && row.paymentStatus != 'Paid' &&
                        <Button variant='outlined' size='small' className='button-style ml-1' onClick={() => initiatePayment(row.id)}>Pay</Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={FormOpen} onClose={handleForm}>
        <LocalDrForm handleForm={handleForm} localBusinessDirectoryId={localBusinessDirectoryId} ownerId={postedUserId} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default ProviderLocalDrList;