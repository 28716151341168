import React, { useEffect, useState } from 'react';
import { FxInput, FxDate, http, AlertService, formValidator, formatService } from '../../fx-core';
import './StallItemBatchForm.scss';
import { Button, Checkbox } from '@material-ui/core';

const StallItemBatchForm = (props) => {
  const defaultItem = {
    id: props.stallItemBatchId ? props.stallItemBatchId : 0,
    stallItemId: props.stallItemId,
    totalQuantity: 0,
    price: 0,
    //expiryDate: "",
    expiryTime: 0,
    //expiresOn: "",
    unit: "",
    itemName: "",
    itemId: 0,
    stallId: 0,
    serviceId: 0,
    currentDate: "",
    isActive: true
  };

  const [item, setItem] = useState(defaultItem);
  const [expError, setExpError] = useState("");
  const [reqError, setReqError] = useState("");
  const [dateError, setDateError] = useState("");

  const handleInputChange = (item) => {
    if (item.name == "totalQuantity" || item.name == "price" || item.name == "expiryTime") {
      item.value = parseInt(item.value);
    }
    handleItemChange({ [item.name]: item.value });
  }

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }  

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getStallItemById = () => {
    let inputData = {
      id: props.stallItemId
    };
    let apiOptions: any = {
      url: `stallitem/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      getStallItemCallback(response.data);
    });
  }

  const getStallItemCallback = (res) => {
    let itemObj = {
      itemName: res.itemName,
      itemId: res.itemId,
      stallId: res.stallId,
      serviceId: res.serviceId
    };
    handleItemChange(itemObj);
    if (props.stallItemBatchId > 0) {
      getStallItemBatchById();
    }
  }

  const getStallItemBatchById = () => {
    let apiOptions: any = {
      url: `stallitembatch/${item.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.expiryTime = result.expiryTime > 0 ? result.expiryTime + "" : '';
      setItem(result);
    })
  }


  const saveStallItemBatch = () => {
    if (!item.totalQuantity || !item.price) {
      setReqError("This field is required");
      return;
    }
    // if (!item.expiryTime && !item.expiryDate) {
    //   setExpError("Expiry Date or Time is required");
    //   return;
    // }
    // if (item.expiryTime && item.expiryDate) {
    //   setExpError("Please enter Expiry Date or Expiry Time");
    //   return;
    // }
    // if (!item.expiryTime && item.expiryDate && formatService.futureDateValidator(item.expiryDate)) {
    //   setDateError("Expiry Date should be future date");
    //   return;
    // }
    item.totalQuantity = item.totalQuantity > 0 ? parseInt(item.totalQuantity) : 0;
    item.price = item.price > 0 ? parseFloat(item.price) : 0;
    item.expiryTime = item.expiryTime ? parseInt(item.expiryTime) : 0;
    item.currentDate = formatService.getCurrentDateForServer();
    /*if (item.expiryTime) {
      item.expiresOn = formatService.getDateByAddedHrs(item.expiryTime);
    }
    else if (item.expiryDate) {
      item.expiresOn = formatService.getDateStringForServer(item.expiryDate);
    }*/
    let apiOptions: any = {
      url: `stallitembatch/save`,
      data: item
    };
    http.post(apiOptions).then(res => {
      saveStallCallBack(res.data);
    })
  };

  const saveStallCallBack = (res) => {
    AlertService.showSuccessMsg();
    props.handleStallItemBatchFormClose();
  }

  const handleClose = () => {
    props.handleStallItemBatchFormClose();
  }

  useEffect(() => {
    getStallItemById();
  }, []);


  return (
    <div className='stallbatchform-ui'>
      <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
        <span className="col-11">
          <span>Stall Item Batch Form</span>
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>     

      <div className='row p-3 pt-5'>
        <div className='col-12 p-3'>
          <FxInput name="unit" variant="outlined" fullWidth size="small" label="Unit"
            value={item.unit} onValueChange={handleInputChange} />
        </div>

        <div className='col-12 p-3'>
          <FxInput type="number" name="totalQuantity" variant="outlined" fullWidth size="small" label="Total Quantity"
            value={item.totalQuantity || ""} onValueChange={handleInputChange} />
          {reqError && !item.totalQuantity && (
            <div className="error">{reqError}</div>
          )}
        </div>

        <div className='col-12 p-3'>
          <FxInput type="number" name="price" variant="outlined" fullWidth size="small" label="Price"
            value={item.price || ""} onValueChange={handleInputChange} />
          {reqError && !item.price && (
            <div className="error">{reqError}</div>
          )}
        </div>

        <div className='col-12 p-3'>
          <Checkbox name="isActive" color="primary" checked={item.isActive} value={item.isActive} onChange={handleCheckbox} />
          Enable
        </div>        

        {/* <div className='col-12 p-3'>
          <FxInput type="number" name="expiryTime" variant="outlined" fullWidth size="small" label="Expiry Time"
            value={item.expiryTime || ""} onValueChange={handleInputChange} />
        </div>

        <div className='col-12 p-3 text-center font-weight-bold'>or</div>

        <div className='col-12 p-3 pb-5'>
          <FxDate name="expiryDate" variant="outlined" size="small" fullWidth label="Expiry Date"
            value={item.expiryDate} onValueChange={handleInputChange} />
          {dateError && (
            <div className="error">{dateError}</div>
          )}
          {
            expError &&
            <div className="error">{expError}</div>
          }
        </div> */}

        <div className='px-3 py-2 drawer-footer'>
          <span>
            <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveStallItemBatch}><b>Save</b></Button>
          </span>
        </div>
      </div>
    </div>
  );
}
export default StallItemBatchForm;
