import React from 'react';
import moment from 'moment';
import { Constants } from '../helpers/constants';

export const FxHumanDateDisplay = (props:any) => {

    const getHumanDateDisplay = () => {
        let getDate = props.value;
        let displayType = props.displayType;
        if(getDate) {
            if(displayType == 'date')
            {
                const temp = moment(getDate, Constants.ServerDateFormat).add(24, 'hours');
                let dateDisplay = temp.fromNow();
                return dateDisplay;
            }
            if(displayType == 'datetime')
            {
                let temp = moment.utc(getDate).local().format(Constants.DateTimeFormat);
                let dateTimeDisplay = moment(temp, Constants.ServerDateFormat).fromNow();
                return dateTimeDisplay;
            }
        }
    }

    return(
        <span>
          {getHumanDateDisplay()}
        </span>       
    );
};