import React, { useEffect, useState } from 'react';
import './LiveStreamOrder.scss';
import { http, formatService, FxDateTimeDisplay, FxDateDisplay, general, localstorage, AlertService, FxCurrency } from '../fx-core';
import * as _ from 'lodash';
import { Dialog, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Coupons } from '../OrderConfirmation/Coupons/Copuons';
import { DeliveryAddress } from '../OrderConfirmation/DeliveryAddress/DeliveryAddress';
import { DeliveryCharge } from '../OrderConfirmation/DeliveryCharge/DeliveryCharge';
import { DeliveryMode } from '../OrderConfirmation/DeliveryMode/DeliveryMode';
import { DeliverySlots } from '../OrderConfirmation/DeliverySlots/DeliverySlots';
import { PaymentMethod } from '../OrderConfirmation/PaymentMethod/PaymentMethod';
import { PickupAddress } from '../OrderConfirmation/PickupAddress/PickupAddress';
import { UserAddress } from '../OrderConfirmation/UserAddress/UserAddress';
import { AddressForm } from '../MyAccount/AddressForm/AddressForm';
import StallItem from '../Stall/StallItem/StallItem';
import OrderSummary from '../OrderSummary/OrderSummary';

const LiveStreamOrder = (props) => {
    const dispatch = useDispatch();
    const cartData = useSelector<any, any>(s => s.cart);
    const userId = localstorage.getItem('userId');
    const allDeliveryModes = [
        { text: 'Delivery', name: 'deliverymode', value: 'Delivery', checked: true },
        { text: 'Pickup', name: 'deliverymode', value: 'Pickup', checked: false }
    ];
    const allPaymentOptions = [
        { text: 'Cash', name: 'paymentoption', value: 'cod', checked: false },
        { text: 'Razorpay (UPI, Credit/Debit Card, NetBanking)', name: 'paymentoption', value: 'razorpay', checked: true }
    ];
    let defaultContex = {
        deliveryMode: 'Delivery',
        deliveryCharge: 0,
        deliveryChargeMsg: null,
        addressId: 0,
        deliverySlotId: 0,
        selectedDeliveryDate: formatService.getCurrentDateForServer(),
        minDate: formatService.getCurrentDateForServer(),
        selectedUserCouponId: 0,
        paymentMode: 'razorpay',
        selectedCouponValue: 0,
        doorDeliveryMsg: "You will be notified once the order is shipped!",
        pickupDeliveryMsg: "You will be notified once the order is ready!",
        selectedCouponId: 0,
        selectedCouponValueTypeId: 2,//2-Value
        minimumOrderAmount: null
    };

    let defaultAddress = {
        fullName: null,
        mobile: '',
        fullAddress: '',
        id: 0
    };

    let defaultStall = {
        address: '',
        id: 0,
        name: ''
    };

    let defaultCurrentBehavior = "ItemWise"

    const [paymentOptions, setPaymentOptions] = useState([]);
    const [deliveryModes, setDeliveryModes] = useState([]);
    const [currentContext, setCurrentContext] = useState(defaultContex);
    const [address, setAddress] = useState(defaultAddress);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [couponList, setCouponList] = useState([]);
    const [open, setOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [stall, setStall] = useState(defaultStall);
    const [itemDetails, setItemDetails] = useState([]);
    const [currentBehavior, setCurrentBehavior] = useState(defaultCurrentBehavior);
    const [showItemSummary, setShowItemSummary] = useState(false);
    const [id, setId] = useState(0);

    const onInit = () => {
        getStallById();
        getDefaultAddress();
        getAvailableStallTiming();
        getAvailableCoupons();
        getStallItemsByStallId();
    };

    const getStallById = () => {
        let apiOptions: any = {
            url: `stall/${props.stallId}`
        };
        http.get(apiOptions).then(res => {
            getStallCallBack(res.data);
        })
    };

    const getStallCallBack = (res) => {
        setStall(res);
        let deliveryModes: any = [];
        for (let item of allDeliveryModes) {
            if (item.value == "Delivery" && res.isDeliveryEnabled == 1) {
                item.checked = true;
                deliveryModes.push(item);
            }
            if (item.value == "Pickup" && res.isPickupEnabled == 1) {
                item.checked = false; // Added false for default selection
                deliveryModes.push(item);
            }
        }
        setDeliveryModes(deliveryModes);
        getPaymentOptions();
    };

    const getPaymentOptions = () => {
        let apiOptions: any = {
            url: `paymentoption/active/stall/${props.stallId}`
        };
        http.get(apiOptions).then(res => {
            getPaymentsCallBack(res);
        })
    };

    const getPaymentsCallBack = (res) => {
        let paymentOptionList = res.data;
        let paymentModes: any = [];
        for (let item of paymentOptionList) {
            let lookupItem = _.find(allPaymentOptions, { value: item.paymentOptionCode });
            if (lookupItem) {
                paymentModes.push(lookupItem);
            }
        }
        setPaymentOptions(paymentModes);
    };

    const onDeliveryModeChange = (selected) => {
        const data = { deliveryMode: selected.value, deliverySlotId: 0 };
        setCurrentContext(prevState => {
            return { ...prevState, ...data }
        });
        let tmpDelModes: any = deliveryModes.map((item: any) => {
            if (item.value === selected.value) {
                item.checked = true;
            }
            else {
                item.checked = false;
            }
            return item;
        });
        setDeliveryModes(tmpDelModes);
    };

    const onPaymentModeChange = (selected) => {
        const data = { paymentMode: selected.value };
        setCurrentContext(prevState => {
            return { ...prevState, ...data }
        });
        let tmpPayModes: any = paymentOptions.map((item: any) => {
            if (item.value === selected.value) {
                item.checked = true;
            }
            else {
                item.checked = false;
            }
            return item;
        });
        setPaymentOptions(tmpPayModes);
    };

    const getDefaultAddress = () => {
        let apiOptions: any = {
            url: `address/default/query`,
        };
        http.post(apiOptions).then(res => {
            getDefaultAddressCallBack(res);
        })
    };

    const getDefaultAddressCallBack = (res) => {
        const result = res.data;
        setAddress(result);
        let addObj = { addressId: 0 };
        if (result.id > 0) {
            addObj.addressId = result.id;
        }
        setCurrentContext(prevState => {
            return { ...prevState, ...addObj }
        });
        if (result.id > 0 && result.locationId) {
            getDeliveryCharge(result.locationId)
        }
    };

    const getDeliveryCharge = (locationId) => {
        let inputData = {
            locationId: locationId,
            stallId: props.stallId
        }
        let apiOptions: any = {
            url: `stalldeliverylocation/deliverycharge`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getDeliveryChargeCallBack(res);
        })
    };

    const getDeliveryChargeCallBack = (res) => {
        const result = res.data;
        let resultData = {
            deliveryCharge: result.deliveryCharge,
            minimumOrderAmount: result.minimumOrderAmount
        }
        setCurrentContext(prevState => {
            return { ...prevState, ...resultData };
        });
    };

    const getAvailableSlots = () => {
        let inputData = {
            stallId: props.stallId
        };
        let apiOptions: any = {
            url: `stalldeliveryslot/available`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getAvailableSlotsCallBack(res);
        })
    };

    const getAvailableSlotsCallBack = (res) => {
        const result = res.data;
        if (result.deliverySlots.length > 0) {
            let filterDelSlots = filterCurrentDaySlots(result.deliverySlots);
            for (let item of filterDelSlots) {
                item.startTime = item.startTime != null ? general.getFormattedTime(item.startTime) : null;
                item.endTime = item.endTime != null ? general.getFormattedTime(item.endTime) : null;
            }
            setAvailableSlots(filterDelSlots);
        }
        else {
            setAvailableSlots([]);
        }
        setCurrentContext(prevState => {
            return { ...prevState, ...{ deliverySlotId: 0 } }
        });
    };

    const filterCurrentDaySlots = (slotList) => {
        let currentDate = formatService.getCurrentDateForServer();
        let currentTime = formatService.getCurrentTimeForServer();
        let selDelDate = formatService.getDateStringForServer(currentContext.selectedDeliveryDate);
        if (currentDate == selDelDate) {
            slotList = slotList.filter(i => i.startTime > currentTime);
        }
        return slotList;
    }

    const onSelectSlot = (item) => {
        let deliverySlotId = 0;
        if (currentContext.deliverySlotId != item.id) {
            deliverySlotId = item.id;
        }
        setCurrentContext(prevState => {
            return { ...prevState, ...{ deliverySlotId: deliverySlotId } }
        });
    };

    const getAvailableCoupons = () => {
        let inputData = {
            entityTypeId: 3, // 3-Stall
            entityId: props.stallId
        };
        let apiOptions: any = {
            url: `coupon/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getAvailableCouponsCallBack(res);
        })
    };

    const getAvailableCouponsCallBack = (res) => {
        setCouponList(res.data);
    };

    const getStallItemsByStallId = () => {
        let filters = {
            currentDate: formatService.getCurrentDateForServer(),
            stallId: props.stallId,
            stallItemId: props.stallItemId,
            categoryIds: "",
            tagIds: "",
            itemName: ""
        };
        let inputData = { filters }
        let apiOptions: any = {
            url: `portal/stallitembatch/itemsbystall/itemsales`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getStallItemsByStallIdCallBack(res);
        });
    }

    const getStallItemsByStallIdCallBack = (res) => {
        let result = res.data;
        let hasCartData = (cartData && cartData.items && cartData.items.length > 0);
        for (let item of result) {
            item.stalltemBatch.imageUrl = general.getImageUrl(item.stalltemBatch.imagePath);
            item.stalltemBatch.quantity = 0;
            if (hasCartData) {
                let cartItem = _.find(cartData.items, { id: item.stalltemBatch.id });
                if (cartItem) {
                    item.stalltemBatch.quantity = cartItem.quantity;
                }
            }
        }
        setItemDetails(result);
    }

    const handleStallItemCustomized = (customizedDetails, id) => {
        if (customizedDetails.length > 0) {
            let stallItemBatchInstance = _.result(_.find(itemDetails, function (i: any) {
                return i.stalltemBatch.id === id;
            }), 'stalltemBatch');
            let stallItemBatchObj = { ...stallItemBatchInstance };
            stallItemBatchObj.quantity = 1;
            stallItemBatchObj.customizedDetails = customizedDetails;
            //to display by grouped Group Name
            let displayDetails = _.map((_.groupBy(customizedDetails, function (i) {
                return i.groupName
            })), function (items, key) { return { key: key, displayModifiers: items }; });
            stallItemBatchObj.groupedCustomDetails = displayDetails;
            stallItemBatchObj.uid = stallItemBatchObj.id + "_" + uuidv4();
            let totalModifierAmt = _.sumBy(customizedDetails, 'stallItemCustomizeDetailPrice');
            stallItemBatchObj.modifierAmt = stallItemBatchObj.quantity * totalModifierAmt;
            // if (listingCartData.cartCount > 0) {
            //     dispatch({ type: "CLEAR_LISTING_CART", payload: {} }); //Clear the listing order items
            // }
            dispatch({ type: "ADD_CART", payload: { stallId: props.stallId, item: stallItemBatchObj } });
        }
    }

    const updateQuntity = () => {
        let cartItemsArr: any = itemDetails.map((item: any) => {
            let cartItem = _.find(cartData.items, { id: item.stalltemBatch.id });
            if (cartItem) {
                item.stalltemBatch.quantity = cartItem.quantity;
            }
            else {
                item.stalltemBatch.quantity = 0;
            }
            return item;
        });
        setItemDetails(cartItemsArr);
    };

    const selectedCoupon = (item) => {
        let couponObj: any = {};
        if (currentContext.selectedUserCouponId == item.id || currentContext.selectedCouponId == item.id) {
            couponObj.selectedUserCouponId = 0;
            couponObj.selectedCouponValue = 0;
            couponObj.selectedCouponId = 0;
            couponObj.selectedCouponValueTypeId = 2;
        }
        else {
            couponObj.selectedUserCouponId = item.userCouponId;
            couponObj.selectedCouponValue = item.couponValue;
            couponObj.selectedCouponId = item.couponId;
            couponObj.selectedCouponValueTypeId = item.couponValueTypeId;
        }
        setCurrentContext(prevState => {
            return { ...prevState, ...couponObj }
        });
    };

    const getAvailableStallTiming = () => {
        let inputData = {
            stallId: props.stallId,
            communityId: localstorage.getItem('communityId'),
            currentDay: formatService.getDayByDate(formatService.getCurrentDateForServer())
        }
        let apiOptions: any = {
            url: `stalltiming/available`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getAvailableStallTimingCallBack(res);
        })
    };

    const getAvailableStallTimingCallBack = (res) => {
        const result = res.data;
        let currentAvailableTimings = result.currentAvailableTimings;
        let nextAvailableTimings = result.nextAvailableTimings;
        if (currentAvailableTimings && currentAvailableTimings.length > 0) {
            setCurrentContext(prevState => {
                return { ...prevState, ...{ selectedDeliveryDate: formatService.getCurrentDateForServer() } }
            });
        }
        else if (nextAvailableTimings && nextAvailableTimings.length > 0) {
            let hours = 24;
            for (var i = 0; i < 6; i++) {
                let nextDate = formatService.getDateByAddedHrs(hours);
                let nextDay = formatService.getDayByDate(nextDate);
                let lookupItem = _.find(nextAvailableTimings, { day: nextDay.toLowerCase() });
                if (lookupItem) {
                    setCurrentContext(prevState => {
                        return { ...prevState, ...{ selectedDeliveryDate: nextDate } }
                    });
                    break;
                }
                else {
                    hours = hours + 24;
                }
            }
        }
    };

    const openAddressDialog = () => {
        setOpen(true);
    };

    const closeAddressDialog = () => {
        setOpen(false);
    };

    const onSelectAddress = (item) => {
        setCurrentContext(prevState => {
            return { ...prevState, ...{ addressId: item.id } };
        });
        setAddress(item);
        if (item.locationId) {
            getDeliveryCharge(item.locationId)
        }
        closeAddressDialog();
    };

    const onDeliveryDateSelect = (item) => {
        setCurrentContext(prevState => {
            return { ...prevState, ...{ selectedDeliveryDate: item.value } }
        });
    };

    const openAddressForm = () => {
        setAddOpen(true);
    };

    const closeAddressForm = () => {
        setAddOpen(false);
    };

    const onSaveCallback = () => {
        setAddOpen(false);
        getDefaultAddress();
    }

    const confirmOrder = () => {
        if (currentContext.deliveryMode === "Delivery") {
            if (currentContext.addressId == 0) {
                AlertService.showErrorMsg("Please add or select address for Door delivery");
                return;
            }
            if (currentContext.deliverySlotId === 0) {
                AlertService.showErrorMsg("Please select delivery slot");
                return;
            }
            if (availableSlots.length > 0 && !currentContext.deliverySlotId) {
                AlertService.showErrorMsg("Please select delivery slot");
                return;
            }
        }
        else if (currentContext.deliveryMode === 'Pickup') {
            setCurrentContext(prevState => {
                return { ...prevState, ...{ addressId: 0 } }
            });
        }

        if (cartData.items.length === 0) {
            AlertService.showErrorMsg("Please add quantity to order");
            return;
        }
        if (cartData.totalAmount === 0) {
            AlertService.showErrorMsg("Order total should not be zero");
            return;
        }
        prepareDataAndPlaceOrder();
    };

    const prepareDataAndPlaceOrder = () => {
        const inputData = getOrderInputData();
        placeOrder(inputData);
    };

    const getOrderInputData = () => {
        const orderLines: any = [];
        let modifierAmt = 0;
        let orderTypeId = 0;
        if (currentContext.deliveryMode == 'Delivery') {
            orderTypeId = 1;
        }
        else if (currentContext.deliveryMode == 'Pickup') {
            orderTypeId = 2;
        }
        if (currentContext.selectedCouponValueTypeId == 2 && (currentContext.selectedUserCouponId > 0 || currentContext.selectedCouponId > 0)) {
            cartData.totalAmount = cartData.totalAmount - currentContext.selectedCouponValue;
        }
        for (let item of cartData.items) {
            modifierAmt = 0;
            if (item.customizedDetails && item.customizedDetails.length > 0) {
                let totalModifierAmt = _.sumBy(item.customizedDetails, 'stallItemCustomizeDetailPrice');
                modifierAmt = item.quantity * totalModifierAmt;
            }
            if (item.quantity > 0) {
                let newItem = {
                    ItemId: item.itemId,
                    ItemName: item.itemName,
                    Price: item.price,
                    ItemQuantity: item.quantity,
                    LineTotalPrice: (item.quantity * item.price) + modifierAmt,
                    StallItemBatchId: item.id,
                    StallItemId: item.stallItemId,
                    LineModifierAmount: modifierAmt,
                    OrderLineModifiers: item.customizedDetails ? item.customizedDetails : []
                };
                orderLines.push(newItem);
            }
        }
        const order = {
            id: 0,
            stallId: props.stallId,
            orderAmount: cartData.totalAmount,
            orderTypeId: orderTypeId,
            paymentMode: currentContext.paymentMode,
            addressId: currentContext.addressId,
            userCouponId: currentContext.selectedUserCouponId,
            deliverySlotId: currentContext.deliverySlotId,
            orderDate: currentContext.selectedDeliveryDate,
            deliveryCharge: currentContext.deliveryCharge,
            selectedDay: formatService.getDayByDate(currentContext.selectedDeliveryDate),
            communityId: localstorage.getItem('communityId')
        };
        let inputData = {
            Order: order,
            OrderLines: orderLines,
            CouponId: currentContext.selectedCouponId
        };
        return inputData;
    };

    const placeOrder = (inputData) => {
        let apiOptions: any = {
            url: `order/place`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            placeOrderCallBack(res);
        })
    };

    const placeOrderCallBack = (res) => {
        setId(res.data.orderId);
        AlertService.showSuccessMsg();
        setShowItemSummary(true);
    };

    const handleClose = () => {
        props.handleClose();
    };

    useEffect(() => {
        onInit();
    }, []);

    useEffect(() => {
        getAvailableSlots();
    }, [currentContext.selectedDeliveryDate]);

    useEffect(() => {
        updateQuntity();
    }, [cartData])

    return (
        <div className='livestremorder-ui'>
            <div className='row title-font py-1 stream-card-header'>
                <span className="col-11">
                </span>
                <span className="col-1 px-0">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>
            <div className='pl-4'>           
            {
                currentBehavior == "ItemWise" && !showItemSummary &&
                <div className='mt-2'>
                    <div className='deskview'>
                        <div className='p-2'>
                            {
                                itemDetails.length > 0 && itemDetails.map((item, i) => (
                                    <div>
                                        <StallItem item={item} stallId={props.stallId}
                                            handleStallItemCustomized={handleStallItemCustomized} />
                                    </div>
                                ))
                            }
                        </div>
                        <div className='p-2'>
                            <DeliveryMode
                                deliveryModes={deliveryModes}
                                deliveryMode={currentContext.deliveryMode}
                                onDeliveryModeChange={onDeliveryModeChange}
                            />
                        </div>
                        <div className='p-2'>
                            <PaymentMethod
                                paymentOptions={paymentOptions}
                                paymentMode={currentContext.paymentMode}
                                onPaymentModeChange={onPaymentModeChange}
                            />
                            <div>
                                <Coupons
                                    couponList={couponList}
                                    selectedCoupon={selectedCoupon}
                                    selectedUserCouponId={currentContext.selectedUserCouponId}
                                    selectedCouponId={currentContext.selectedCouponId}
                                />
                            </div>
                        </div>
                        <div className='p-2'>
                            <DeliverySlots
                                selectedDeliveryDate={currentContext.selectedDeliveryDate}
                                onDeliveryDateSelect={onDeliveryDateSelect}
                                availableSlots={availableSlots}
                                onSelectSlot={onSelectSlot}
                                deliverySlotId={currentContext.deliverySlotId}
                                isSave={false}
                            />
                        </div>
                        <div className='p-2'>
                            {
                                currentContext.deliveryMode === "Pickup" &&
                                <div className="pt-4">
                                    <PickupAddress
                                        stall={stall}
                                        pickupDeliveryMsg={currentContext.pickupDeliveryMsg}
                                    />
                                </div>
                            }
                            {
                                currentContext.deliveryMode === "Delivery" &&
                                <div className="pt-4">
                                    <DeliveryAddress
                                        openAddressDialog={openAddressDialog}
                                        address={address}
                                        openAddressForm={openAddressForm}
                                    />
                                    <div className="pl-2 mt-2 row">
                                        <DeliveryCharge
                                            deliveryCharge={currentContext.deliveryCharge}
                                            deliveryChargeMsg={currentContext.deliveryChargeMsg}
                                            doorDeliveryMsg={currentContext.doorDeliveryMsg}
                                            minimumOrderAmount={currentContext.minimumOrderAmount}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='row p-2 pb-4'>
                            <div className="col-md-4 col-6">
                                <div className="sub-text">
                                    <h6>Total</h6>
                                </div>
                            </div>
                            <div className="col-md-8 col-6 font-18">
                                <FxCurrency value={cartData.totalAmount} />
                            </div>
                        </div>
                    </div>
                    <div className='mobileview'>
                        <div className="px-2 pt-4">
                            <div className='py-2 mb-3'>
                                {
                                    itemDetails.length > 0 && itemDetails.map((item, i) => (
                                        <div>
                                            <StallItem item={item} stallId={props.stallId}
                                                handleStallItemCustomized={handleStallItemCustomized} />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='pb-4'>
                                <DeliveryMode
                                    deliveryModes={deliveryModes}
                                    deliveryMode={currentContext.deliveryMode}
                                    onDeliveryModeChange={onDeliveryModeChange}
                                />
                            </div>
                            <div>
                                <PaymentMethod
                                    paymentOptions={paymentOptions}
                                    paymentMode={currentContext.paymentMode}
                                    onPaymentModeChange={onPaymentModeChange}
                                />
                                <div>
                                    <Coupons
                                        couponList={couponList}
                                        selectedCoupon={selectedCoupon}
                                        selectedUserCouponId={currentContext.selectedUserCouponId}
                                        selectedCouponId={currentContext.selectedCouponId}
                                    />
                                </div>
                            </div>
                            <div className='pb-4'>
                                <DeliverySlots
                                    selectedDeliveryDate={currentContext.selectedDeliveryDate}
                                    onDeliveryDateSelect={onDeliveryDateSelect}
                                    availableSlots={availableSlots}
                                    onSelectSlot={onSelectSlot}
                                    deliverySlotId={currentContext.deliverySlotId}
                                    isSave={false}
                                />
                            </div>
                            <div>
                                {
                                    currentContext.deliveryMode === "Pickup" &&
                                    <div className="pt-4">
                                        <PickupAddress
                                            stall={stall}
                                            pickupDeliveryMsg={currentContext.pickupDeliveryMsg}
                                        />
                                    </div>
                                }
                                {
                                    currentContext.deliveryMode === "Delivery" &&
                                    <div className="pt-4">
                                        <DeliveryAddress
                                            openAddressDialog={openAddressDialog}
                                            address={address}
                                            openAddressForm={openAddressForm}
                                        />
                                        <div className="ml-1 mt-2 row">
                                            <DeliveryCharge
                                                deliveryCharge={currentContext.deliveryCharge}
                                                deliveryChargeMsg={currentContext.deliveryChargeMsg}
                                                doorDeliveryMsg={currentContext.doorDeliveryMsg}
                                                minimumOrderAmount={currentContext.minimumOrderAmount}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='row p-2 pb-4'>
                                <div className="col-md-4 col-6">
                                    <div className="sub-text">
                                        <h6>Total</h6>
                                    </div>
                                </div>
                                <div className="col-md-8 col-6 font-18">
                                    <FxCurrency value={cartData.totalAmount} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pr-4 py-2 stream-card-footer'>
                        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={confirmOrder}><b>Confirm</b></Button>
                    </div>
                </div>
            }
            {
                currentBehavior == "ItemWise" && showItemSummary &&
                <div className='mt-4'>
                    <OrderSummary orderId={id} />
                    <div className='py-2 stream-card-footer'>
                        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={handleClose}><b>Close</b></Button>
                    </div>
                </div>
            }
            </div>

            <Dialog
                open={open}
                maxWidth="md"
                fullWidth
                onClose={closeAddressDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <UserAddress userId={userId} onSelectAddress={onSelectAddress} closeAddressDialog={closeAddressDialog} />
            </Dialog>
            <Dialog
                open={addOpen}
                maxWidth="sm"
                onClose={closeAddressForm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <AddressForm userId={userId} addressId={0} closeAddressForm={closeAddressForm}
                    onSaveCallback={onSaveCallback} />
            </Dialog>
        </div>
    );
};

export default LiveStreamOrder;