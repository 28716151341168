import React, { useEffect, useState } from 'react';
import './MyPosts.scss';
import LocalDrForm from '../../LocalDrCard/LocalDrForm/LocalDrForm';
import { Card, Drawer, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { http, localstorage, AlertService } from '../../fx-core';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const MyPosts = (props) => {
  const classes = styles();
  const Razorpay = useRazorpay();
  
  const [FormOpen, setFormOpen] = useState(false);
  const [localBusinessList, setLocalBusinessList] = useState([]);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [localBusinessDirectoryId, setLocalBusinessDirectoryId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [message, setMessage] = useState(null);
  const [postedUserId, setPostedUserId] = useState(0);

  const editLocalBusinessForm = (id, userId) => {
    setLocalBusinessDirectoryId(id);
    setPostedUserId(userId);
    setFormOpen(true);
  }
  const handleForm = () => {
    setFormOpen(false);
    setLocalBusinessDirectoryId(0);
    setPostedUserId(0);
    getLocalBusinessList();
  }

  const getLocalBusinessList = () => {
    let inputData = {
      communityId: communityId,
      userId: userId
    };
    let apiOptions: any = {
      url: `localbusinessdirectory/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      setLocalBusinessList(res.data);
    })
  };

  const confirmDelete = (item: any) => {
    setSelectedItem(item);
    setConfirmOpen(true);
    setMessage("Do you want to delete this post?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    deleteLocalBusinessPost(selectedItem);
  }

  const deleteLocalBusinessPost = (item: any) => {
    let inputData = {
      id: item.id,
    };
    let apiOptions: any = {
      url: `localbusinessdirectory/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getLocalBusinessList();
    })
  };

  const initiatePayment = (localDrId) => {
    let inputData = {
      entityTypeId: 2,
      entityId: localDrId
    };
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item =  resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {        
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        getLocalBusinessList();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  useEffect(() => {
    getLocalBusinessList();
  }, []);

  return (
    <div className='my-posts-ui'>
      <div className="row py-1 px-1 mx-auto">
        <div className="col-9 pl-0">
          <h5>Posts</h5>
        </div>
      </div>

      {/* Desktop View */}
      <div className='deskview'>
        <div className='my-4'>
          <TableContainer component={Paper}>
            <Table className="table-striped" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Short Description</TableCell>
                  <TableCell>Long Description</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell width="18%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localBusinessList.length == 0 &&
                  <TableRow>
                    <TableCell colSpan={6}>
                    <span className='d-flex justify-content-center py-4'>Added Local Bussiness Directory can be edited here.</span>
                    </TableCell>
                  </TableRow>
                }
                {localBusinessList.length > 0 && localBusinessList.map((row: any, i) => (
                  <TableRow>
                    <TableCell>
                      {row.title}
                      {row.isListingInCommkit &&
                        <span className='commkit-wrapper'>
                          <span className="commkit-style pl-2">Localites</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>{row.shortDescription}</TableCell>
                    <TableCell>
                      {row.longDescription}
                    </TableCell>
                    <TableCell>
                      {row.postStatusId == 1 &&
                        <span>Posted</span>
                      }
                      {row.postStatusId == 2 &&
                        <span>Approved</span>
                      }
                      {row.postStatusId == 3 &&
                        <span>Rejected</span>
                      }
                    </TableCell>
                    <TableCell>
                      {row.paymentMode &&
                        <span>{row.paymentMode}
                          <span> - {row.paymentStatus}</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => editLocalBusinessForm(row.id, row.userId)}>Edit</Button>
                      <Button variant='outlined' size='small' className='button-style ml-1' onClick={() => confirmDelete(row)}>Delete</Button>
                      {row.paymentMode == 'razorpay' && row.paymentStatus != 'Paid' &&
                        <Button variant='outlined' size='small' className='button-style ml-1' onClick={() => initiatePayment(row.id)}>Pay</Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Tablet View */}
      <div className='tabview'>
        <div className='my-4'>
          <TableContainer component={Paper}>
            <Table className="table-striped" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Short Description</TableCell>
                  <TableCell>Long Description</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell width="25%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localBusinessList.map((row: any, i) => (
                  <TableRow>
                    <TableCell>
                      {row.title}
                      {row.isListingInCommkit &&
                        <span className='commkit-wrapper'>
                          <span className="commkit-style pl-2">Localites</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>{row.shortDescription}</TableCell>
                    <TableCell>
                      {row.longDescription}
                    </TableCell>
                    <TableCell>
                      {row.postStatusId == 1 &&
                        <span>Posted</span>
                      }
                      {row.postStatusId == 2 &&
                        <span>Approved</span>
                      }
                      {row.postStatusId == 3 &&
                        <span>Rejected</span>
                      }
                    </TableCell>
                    <TableCell>
                      {row.paymentMode &&
                        <span>{row.paymentMode}
                          <span> - {row.paymentStatus}</span>
                        </span>
                      }
                    </TableCell>
                    <TableCell>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => editLocalBusinessForm(row.id, row.userId)}>Edit</Button>
                      <Button variant='outlined' size='small' className='button-style ml-1' onClick={() => confirmDelete(row)}>Delete</Button>
                      {row.paymentMode == 'razorpay' && row.paymentStatus != 'Paid' &&
                        <Button variant='outlined' size='small' className='button-style ml-1' onClick={() => initiatePayment(row.id)}>Pay</Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Mobile View */}
      <div className='mobileview mb-5'>
        {localBusinessList.map((row: any, i) => (
          <div>
            <Card className="mt-3 py-2 px-3">
              <div>
                <span className='font-14 font-weight-bold'>{row.title}</span>
                {row.isListingInCommkit &&
                  <span className='commkit-wrapper ml-2'>
                    <span className="commkit-style pl-2">Localites</span>
                  </span>
                }
                <span className='float-right'>
                  {row.postStatusId == 1 &&
                    <span>Posted</span>
                  }
                  {row.postStatusId == 2 &&
                    <span>Approved</span>
                  }
                  {row.postStatusId == 3 &&
                    <span>Rejected</span>
                  }
                </span>
              </div>
              <div>
                <span className='font-13 short-desc py-2'>{row.shortDescription}</span>
                <span className='float-right'>
                  {row.paymentMode &&
                    <span>{row.paymentMode}
                      <span> - {row.paymentStatus}</span>
                    </span>
                  }
                </span></div>
              <div className='font-13 long-desc'>{row.longDescription}</div>
              <div className='text-right'>
                <Button variant='outlined' size='small' className='button-style' onClick={() => editLocalBusinessForm(row.id, row.userId)}>Edit</Button>
                <Button variant='outlined' size='small' className='button-style ml-3' onClick={() => confirmDelete(row)}>Delete</Button>
                {row.paymentMode == 'razorpay' && row.paymentStatus != 'Paid' &&
                  <Button variant='outlined' size='small' className='button-style ml-3' onClick={() => initiatePayment(row.id)}>Pay</Button>
                }
              </div>
            </Card>
          </div>
        ))}
      </div>


      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={FormOpen} onClose={handleForm}>
        <LocalDrForm handleForm={handleForm} localBusinessDirectoryId={localBusinessDirectoryId} ownerId={postedUserId} />
      </Drawer>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default MyPosts;