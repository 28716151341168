import React, { useEffect, useState } from 'react';
import './SpecialEventList.scss';
import { FxSelect, FxDateDisplay, http, general, localstorage, formatService, FxCurrency, AlertService } from '../../fx-core';
import { Fab, Button, Paper, Table, TableBody, makeStyles, TableCell, TableContainer, TableHead, TableRow, Drawer, Dialog } from '@material-ui/core';
import { NoRecords } from '../../NoRecords/NoRecords';
import SpecialEventEnrol from '../SpecialEventEnrol/SpecialEventEnrol';
import SpecialEventPayment from '../SpecialEventPayment/SpecialEventPayment';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
    drawerWidth: {
        [theme.breakpoints.down(599)]: {
            width: '90%'
        },
        [theme.breakpoints.up(600)]: {
            width: '70%'
        },
        [theme.breakpoints.up(1025)]: {
            width: '40%'
        }
    }
}));

const SpecialEventList = (props) => {
    const classes = styles();
    const Razorpay = useRazorpay();
    
    let defaultFilter = {
        stallId: -1
    };
    let defaultEvent = {
        serviceRequestId: 0,
        amount: 0,
        id: 0
    };
    const [filters, setFilters] = useState(defaultFilter);
    const [stalls, setStalls] = useState([]);
    const [specialEventList, setSpecialEventList] = useState([]);
    const [enrolFormOpen, setEnrolFormOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [paymentOpen, setPaymentOpen] = useState(false);
    const [selEvent, setSelEvent] = useState(defaultEvent);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            stallId: filters.stallId
        }
        if (item.name == 'stallId') {
            filterObj.stallId = item.value;
        }
        getSpecialEventList(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                ProviderStalls: { default: true }
            },
            filters: {
                userId: localstorage.getItem('userId'),
                communityId: localstorage.getItem("communityId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            initLookupCallBack(response);
        })
    }

    const initLookupCallBack = (res) => {
        let result = res.data;
        let stalls = result.ProviderStalls;
        setStalls(stalls);
    };

    const getSpecialEventList = (data) => {
        let inputData = {
            stallId: data.stallId
        };
        let apiOptions: any = {
            url: `community/provider/special/events`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getSpecialEventListCallBack(res);
        })
    }

    const getSpecialEventListCallBack = (res) => {
        const result = res.data;
        for (let item of result) {
            item.profileImageUrl = general.getImageUrl(item.profileImagePath);
            item.startTime = item.startTime != null ? formatService.getConvertedTimeForDisplay(item.startTime) : null;
            item.endTime = item.endTime != null ? formatService.getConvertedTimeForDisplay(item.endTime) : null;
            if (item.paymentMode == "accounttransfer") {
                item.paymentModeText = "Bank Transfer";
            }
            else if (item.paymentMode == "razorpay") {
                item.paymentModeText = "Credit/Debit Card";
            }
        }
        setSpecialEventList(result);
    };

    const createOrViewSplEvent = (id) => {
        setSelectedId(id);
        setEnrolFormOpen(true);
    }

    const handleEnrolFormClose = () => {
        setEnrolFormOpen(false);
        setSelectedId(0);
        getSpecialEventList(filters);
    }

    const openEventPayment = (item) => {
        setPaymentOpen(true);
        setSelEvent(item);
    };

    const closeEventPayment = (res: any) => {
        setPaymentOpen(false);
        setSelEvent(defaultEvent);
        if (res && res.id > 0) {
            if (res.paymentMode == "razorpay") {
                initiatePayment(res.id);
            }
            getSpecialEventList(filters);
        }
    };

    const initiatePayment = (id: any) => {
        let inputData = {
            entityTypeId: 6,
            entityId: id
        };
        let apiOptions: any = {
            url: `payment/GetPaymentLink`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            openPaymentLink(res);
        });
    }

    const openPaymentLink = (resp: any) => {
        var item = resp.data.result;
        let checkoutIdentifier = item.checkoutIdentifier;
        let externalOrderId = item.externalOrderId;
        let amount = item.amount;
        let description = item.description;
        let customerName = item.providerName;
        let email = item.email;
        let mobile = item.mobile;
    
        const options: any = {
          amount: amount,
          description: description,
          order_id: externalOrderId,
          handler: async function (response: any) {
            let data: any = {
              Id: item.id,
              ExternalPaymentId: response.razorpay_payment_id,
              ExternalOrderId: externalOrderId
            };
            await updateRazorPaySuccess(data);
            AlertService.showSuccessMsg("Payment Success!");
            getSpecialEventList(filters);
          },
          prefill: {
            name: customerName,
            email: email,
            contact: mobile
          }
        };
    
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response: any) {
          console.log("payment failed, response=", response);
        });
    
        rzp1.open();
      }
    
      const updateRazorPaySuccess = async (inputData) => {
        let apiOptions: any = {
          url: `payment/updatePaymentSuccess`,
          data: inputData
        };
        await http.post(apiOptions);
      }    

    useEffect(() => {
        initLookup();
        getSpecialEventList(filters);
    }, []);

    return (
        <div className='spleventlist-ui pt-5'>
            <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
                <span className="col-8 pt-3 py-1 pl-4">
                    <span>Special Events</span>
                </span>
            </div>

            <div className="row col-12 my-3 ml-1 mr-5 justify-content-between">
                <div className="col-3">
                    <FxSelect name="stallId" variant="outlined" label="Stalls"
                        options={stalls}
                        selectedValue={filters.stallId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>
                <div className="col-3 pt-1 text-right">
                    <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        onClick={() => createOrViewSplEvent(0)}>
                        <i className="fas fa-plus pr-2"></i>
                        Add
                    </Fab>
                </div>
            </div>
            <div className='p-2 mx-3'>
                <div className='mb-4'>
                    <TableContainer component={Paper}>
                        <Table size="small" className='table-striped'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Stall</TableCell>
                                    <TableCell>Community</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>Start Time</TableCell>
                                    <TableCell>End Date</TableCell>
                                    <TableCell>End Time</TableCell>
                                    <TableCell>Event Status</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Payment</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {specialEventList.length == 0 &&
                                    <TableCell colSpan={8}>
                                        <NoRecords />
                                    </TableCell>
                                }
                                {specialEventList.length > 0 && specialEventList.map((row: any, i) => (
                                    <TableRow>
                                        <TableCell>{row.stallName}</TableCell>
                                        <TableCell>{row.communityName}</TableCell>
                                        <TableCell>
                                            <FxDateDisplay value={row.startDate} />
                                        </TableCell>
                                        <TableCell>
                                            <i className="far fa-clock color-aaa px-1"></i>
                                            {row.startTime}
                                        </TableCell>
                                        <TableCell>
                                            <FxDateDisplay value={row.endDate} />
                                        </TableCell>
                                        <TableCell>
                                            <i className="far fa-clock color-aaa px-1"></i>
                                            {row.endTime}
                                        </TableCell>
                                        <TableCell>{row.eventStatus}</TableCell>
                                        <TableCell>
                                            <FxCurrency value={row.amount} />
                                        </TableCell>
                                        <TableCell>
                                            {
                                                row.serviceRequestStatusId == 1 &&
                                                <span>Pending</span>
                                            }
                                            {
                                                row.serviceRequestStatusId == 2 && row.amount > 0 && !row.paymentMode &&
                                                <span>Pending</span>
                                            }
                                            {
                                                row.serviceRequestStatusId == 2 && row.amount > 0 && row.paymentMode &&
                                                <span>
                                                    {row.paymentModeText} - {row.paymentStatus}
                                                </span>
                                            }
                                            {
                                                row.serviceRequestStatusId == 2 && row.amount == 0 &&
                                                <span>N/A</span>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => createOrViewSplEvent(row.serviceRequestId)}>View</Button>
                                            {
                                                row.serviceRequestStatusId == 2 && !row.paymentMode && row.amount > 0 &&
                                                <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openEventPayment(row)}>Pay</Button>
                                            }
                                            {
                                                row.paymentMode && row.paymentStatus && row.paymentMode == "razorpay" && row.paymentStatus != "Paid" &&
                                                <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => initiatePayment(row.serviceRequestId)}>Pay</Button>
                                            }                                            
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={enrolFormOpen} onClose={handleEnrolFormClose}>
                <SpecialEventEnrol id={selectedId} handleClose={handleEnrolFormClose} />
            </Drawer>
            <Dialog
                open={paymentOpen}
                maxWidth="sm"
                fullWidth
                onClose={closeEventPayment}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <SpecialEventPayment id={selEvent.serviceRequestId} communityId={selEvent.id} amount={selEvent.amount} handleClose={closeEventPayment} />
            </Dialog>
        </div>
    );
}
export default SpecialEventList;