import React, { useState, useEffect } from 'react';
import './ReviewForm.scss';
import Button from '@material-ui/core/Button';
import { FxTextarea, http, AlertService, session } from '../fx-core';
import Rating from '@material-ui/lab/Rating';
import { useDispatch } from 'react-redux';

const ReviewForm = (props: any) => {
    const defaultItem = {
        communityName: "",
        rating: 0,
        reviewComment: "",
        userId: 0,
        orderId: 0
    };

    const [item, setItem] = useState(defaultItem);
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState(null);
    const [code, setCode] = useState(props.match.params.code ? props.match.params.code : "");
    const globalDispatch = useDispatch();

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    };

    const handleRatingChange = (value) => {
        handleItemChange({ "rating": value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getCommunityByCode = () => {
        let inputData = {
            code: code
        };
        let apiOptions: any = {
            url: `community/code/${inputData.code}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            handleItemChange({ "communityName": response.data.name });
        });
    }

    const resolveUserIdOrderId = (token) => {
        let apiOptions: any = {
            url: `order/resolve/${token}`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            handleItemChange({ "userId": result.userId });
            handleItemChange({ "orderId": result.orderId });
            handleLogin(code);
        });
    }

    const handleLogin = (code: any) => {
        let inputData = { code: code || "dev" };
        session.setItem('communityCode', code);

        let apiOptions = {
            url: 'security/login/portal',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result: any = response.data;
            let authToken = result.authToken;

            session.setItem('sessionid', authToken.sessionId);
            session.setItem('id_token', "token " + authToken.sessionId);
            session.setItem('communityId', authToken.communityId);
            session.setItem('email', authToken.email);
            session.setItem('userId', authToken.userId);
            session.setItem('roleCode', authToken.roleCode);
            session.setItem('imagStorageUrl', result.config?.imagStorageUrl);

            globalDispatch({ type: "set-community-details", payload: { code: code, id: authToken.communityId } });
            getCommunityByCode();
        });
    }


    const saveReview = () => {
        if (!item.rating) {
            setError("Rating is required");
            return;
        }
        let inputData = {
            userId: item.userId,
            rating: item.rating,
            reviewComment: item.reviewComment,
            entityTypeId: 1
        };
        let apiOptions: any = {
            url: `review/add`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveReviewCallBack(res);
        });
    };

    const saveReviewCallBack = (res) => {
        setCurrentStep(2);
        AlertService.showSuccessMsg();
    };

    useEffect(() => {
        let token = props.match.params.tk ? props.match.params.tk : "";
        if (token) {
            resolveUserIdOrderId(token);
        }
    }, []);

    return (
        <div className="reviewformcomponent-ui pl-5">
            <div className="reviewformContainer">
                {currentStep == 1 &&
                    <div>
                        <div className="mt-4 mb-4">
                            <span>
                                How was your experience using Localites platform?
                            </span>
                        </div>
                        <div>
                            <Rating name="rating" value={item.rating} onChange={(event, val) => handleRatingChange(val)} />
                            {
                                error && !item.rating &&
                                <div className="validation-msg">{error}</div>
                            }
                        </div>
                        <div className="pt-4">
                            <FxTextarea name="reviewComment" variant="outlined" label="Review Comment" size="small"
                                value={item.reviewComment} maxRows={5} onValueChange={handleInputChange}
                                style={{ minWidth: 445 }}
                            />

                        </div>
                        <div className="row">
                            <div className="col-4 pt-4 pb-4">
                                <Button variant="contained" size="small" color="primary" fullWidth style={{ color: '#FFFFFF', fontWeight: 600 }}
                                    onClick={saveReview}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {currentStep == 2 &&
                    <div className="col-12 py-3 text-center font-22 alignmargin">
                        <span><b>Thank you for submitting review.</b></span>
                    </div>
                }
            </div>
        </div>
    );
}
export default ReviewForm;