import React from 'react';
import './ListingServiceCard.scss';
import Card from '@material-ui/core/Card';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import dummyImage from "./../../../assets/images/dummy.png";
import { FxHumanDateDisplay } from '../../fx-core';
import { Button } from '@material-ui/core'
import LanguageIcon from '@material-ui/icons/Language';
import verifyTick from "./../../../assets/img/verifytick.png";

const ListingServiceCard = (props: any) => {
  return (
    <div className="listing-ui" onClick={() => props.navToListingSale(props.item.id, props.item.stallId)}>

      {/* Desktop View */}
      <div className="deskview pt-5">
        <Card style={{ width: "350px", height: "370px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
          <div className="row justify-content-center">
            {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
            {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
          </div>
          <div>
            <div className="font-22 itemname-style text-center">
              {props.item.title}
            </div>
            <div>
              <span className="pl-2"> {props.item.stallName}</span>
              {
                props.item.isVerified &&
                <span className='ml-1'>
                  <img src={verifyTick} width="20" height="20" />
                </span>
              }
              <span className="float-right text-danger font-12 shopstatus-style">
                {props.item.isStart &&
                  <span>Starts
                    <span className="pl-1">
                      <FxHumanDateDisplay value={props.item.startDateTime} displayType="datetime" /></span>
                  </span>
                }
                {/* {!props.item.isStart &&
                                    <span>Ends
                                        <span className="pl-1">
                                            <FxHumanDateDisplay value={props.item.endDateTime} displayType="datetime" /></span>
                                    </span>
                                } */}
              </span>
            </div>
          </div>
          <div className="font-13 row pl-4">
            {props.item.stallAddress}
          </div>
          <div className="row pr-4 mr-1 pl-3 font-13 justify-content-between">
            <div className="row align-items-center pl-4 font-13 pt-1">
            <span><i className="fas fa-phone-alt"></i></span>
              {props.item.providerMobile &&
                <span className="pl-1 font-12">{props.item.providerMobile}</span>
              }
              {!props.item.providerMobile &&
                <span className="pl-1">-----</span>
              }
            </div>
            <div className="row align-items-center pl-4 font-13 pt-1">
              <span><i className="fas fa-envelope"></i></span>
              {props.item.providerEmail &&
                <span className="pl-1 font-12">{props.item.providerEmail}</span>
              }
              {!props.item.providerEmail &&
                <span className="pl-1">-----</span>
              }
            </div>
          </div>
          <div className="row align-items-center pl-4 font-13 pt-1">
            <span><i className="fas fa-globe"></i></span>
            {props.item.website &&
              <span className='pl-1 font-12'>{props.item.website}</span>
            }
            {!props.item.website &&
              <span className='pl-1'>-----</span>
            }
          </div>

          <div className="font-12 text-success shopstatus-style pl-2">
            {props.item.description}
          </div>
          {/* <div className='text-right'>
            <Button variant='outlined' size='small' className='button-style' onClick={() => props.navToListingSale(props.item.id, props.item.stallId)}>Order</Button>
          </div> */}
        </Card>
      </div>

      {/* Tablet View */}
      <div className="tabview pt-5">
        <Card style={{ height: "230px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
          <div className="row pl-1">
            <div className="col-3">
              {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
              {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
            </div>
            <div className="col-9 pl-5 d-flex flex-column justify-content-between">
              <div className="font-18 itemname-style">
                {props.item.title}
              </div>
              <div>
                {props.item.stallName}
                {
                  props.item.isVerified &&
                  <span className='ml-1'>
                    <img src={verifyTick} width="20" height="20" />
                  </span>
                }
              </div>
              <div className="text-danger font-13 shopstatus-style">
                {props.item.isStart &&
                  <span>Starts
                    <span className="pl-1">
                      <FxHumanDateDisplay value={props.item.startDateTime} displayType="datetime" /></span>
                  </span>
                }
                {/* {!props.item.isStart &&
                                    <span>Ends
                                        <span className="pl-1">
                                            <FxHumanDateDisplay value={props.item.endDateTime} displayType="datetime" /></span>
                                    </span>
                                } */}
              </div>
            </div>
          </div>
          <div className="pl-2 pr-3 d-flex flex-column justify-content-between">
            <div className="font-13">
              {props.item.stallAddress}
            </div>
            <div className="row font-13 justify-content-between pl-2 pr-4">
              <div className="row pl-3">
                <span> <PhoneIcon fontSize="inherit" /></span>
                <span className="pl-1">{props.item.providerMobile}</span>
              </div>
              <div className="row px-0">
                <span> <EmailIcon fontSize="inherit" /></span>
                <span className="pl-1">{props.item.providerEmail}</span>
              </div>
            </div>
            {props.item.website &&
              <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
                <div className="row pl-3">
                  <span><LanguageIcon fontSize='inherit' /></span>
                  <span className="pl-1">{props.item.website}</span>
                </div>
              </div>
            }
            <div className="font-12 text-success shopstatus-style pl-2">
              {props.item.description}
            </div>
          </div>
          {/* <div className='text-right'>
            <Button variant='outlined' size='small' className='button-style' onClick={() => props.navToListingSale(props.item.id, props.item.stallId)}>Order</Button>
          </div> */}
        </Card>
      </div>

      {/* Mobile View */}
      <div className="mobileview pt-2">
        <Card style={{ height: "220px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
          <div className="row pl-1">
            <div className="col-3">
              {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
              {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
            </div>
            <div className="col-9 text-left pl-4 d-flex flex-column justify-content-between">
              <div className="font-16 itemname-style">
                {props.item.title}
              </div>
              <div className="font-14">
                {props.item.stallName}
                {
                  props.item.isVerified &&
                  <span className='ml-1'>
                    <img src={verifyTick} width="20" height="20" />
                  </span>
                }
              </div>
              <div className="text-danger font-11 shopstatus-style">
                {props.item.isStart &&
                  <span>Starts
                    <span className="pl-1">
                      <FxHumanDateDisplay value={props.item.startDateTime} displayType="datetime" /></span>
                  </span>
                }
                {/* {!props.item.isStart &&
                                    <span>Ends
                                        <span className="pl-1">
                                            <FxHumanDateDisplay value={props.item.endDateTime} displayType="datetime" /></span>
                                    </span>
                                } */}
              </div>
            </div>
          </div>
          <div className="pl-2 pr-3 d-flex flex-column justify-content-between">
            <div className="font-12 text-left">
              {props.item.stallAddress}
            </div>
            <div className="row font-12 justify-content-between pl-2 pr-4">
              <div className="row pl-3">
                <span> <PhoneIcon fontSize="inherit" /></span>
                <span className="pl-1">{props.item.providerMobile}</span>
              </div>
              <div className="row px-0">
                <span> <EmailIcon fontSize="inherit" /></span>
                <span className="pl-1">{props.item.providerEmail}</span>
              </div>
            </div>
            {props.item.website &&
              <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
                <div className="row pl-3">
                  <span><LanguageIcon fontSize='inherit' /></span>
                  <span className="pl-1">{props.item.website}</span>
                </div>
              </div>
            }
            <div className="font-12 text-success shopstatus-style pl-2">
              {props.item.description}
            </div>
            {/* <div className='text-right'>
              <Button variant='outlined' size='small' className='button-style' onClick={() => props.navToListingSale(props.item.id, props.item.stallId)}>Order</Button>
            </div> */}
          </div>
        </Card>
      </div>
    </div>
  );
}
export default ListingServiceCard;