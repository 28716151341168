import React from 'react';
import './DeliveryMode.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export const DeliveryMode = (props) => {

    return (
        <div>
            <div className='font-14 bold-text pb-2'>Delivery Mode</div>
            <div>
                {
                    props.deliveryModes.length > 0 && props.deliveryModes.map((item, i) => (
                        <span key={i}>
                            <FormControlLabel
                                name={item.name}
                                value={item.value}
                                control={<Radio color="primary" size="small" />}
                                label={item.text}
                                checked={item.checked}
                                onChange={() => props.onDeliveryModeChange(item)}
                            />
                        </span>
                    ))
                }
            </div>
        </div>
    );
};