import React, { useState, useEffect } from 'react';
import './MyReviewList.scss';
import { http, AlertService, FxHumanDateDisplay } from '../../fx-core';
import Rating from '@material-ui/lab/Rating';
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { Button } from '@material-ui/core';
import { MyReviewForm } from '../MyReviewForm/MyReviewForm';

const MyReviewList = (props) => {
    const [reviewList, setReviewList] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [reviewId, setReviewId] = useState(0);

    const getReviewList = () => {
        let inputData = {
            communityId: localStorage.getItem("communityId")
        };
        let apiOptions: any = {
            url: `myreview/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getReviewListCallBack(res);
        })
    };

    const getReviewListCallBack = (res) => {
        const result = res.data;
        setReviewList(result);
    };

    const openReviewForm = (id: any) => {
        setReviewId(id);
        setOpen(true);
    };

    const closeReviewForm = () => {
        setOpen(false);
        setReviewId(0);
    };

    const confirmDelete = (id: any) => {
        setReviewId(id);
        setConfirmOpen(true);
        setMessage("Are you sure to delete ?");
    }

    const handleDialogClose = () => {
        setConfirmOpen(false);
        setMessage("");
        setReviewId(0);
    };

    const onConfirmCallback = () => {
        const id = reviewId;        
        deleteReview(id);
        handleDialogClose();
    }

    const deleteReview = (id: any) => {
        let apiOptions: any = {
            url: `myreview/${id}`
        };
        http.delete(apiOptions).then(response => {
            AlertService.showSuccessMsg();
            getReviewList();
        })
    };

    const onSaveCallback = () => {
        setOpen(false);
        getReviewList();
    }

    useEffect(() => {
        getReviewList();
    }, []);

    return (
        <div className="myreviewlist-ui">
            {/* Desktop View */}
            <div className='deskview'>
                <div className="row py-1 px-1 mx-auto">
                    <div className="col-9 pl-0">
                        <h5>Reviews</h5>
                    </div>
                    <div className="col-3 pr-0 text-right">
                        <Button variant="contained" size="small" color="primary" onClick={() => openReviewForm(0)}>
                            Add Review
                        </Button>
                    </div>
                </div>
                <div className='my-4'>
                    {
                        reviewList.map((item: any) => (
                            <div className="row mx-2 pt-1 col-12 alignbox">
                                <div className="col-9">
                                    {item.reviewComment}
                                </div>

                                <div className="col-3">
                                    <span className="float-right">
                                        <Rating name="read-only" value={item.rating} readOnly />
                                    </span>
                                </div>

                                <div className="col-8">
                                    <FxHumanDateDisplay value={item.ratingDate} displayType="datetime" />
                                </div>
                                <div className="col-4">
                                    {
                                        item.userId == props.userId &&
                                        <div className="row float-right">
                                            <Button variant='outlined' size='small' className='button-style' onClick={() => openReviewForm(item.id)}>Edit</Button>
                                            <Button variant='outlined' size='small' className='button-style ml-3' onClick={() => confirmDelete(item.id)}>Delete</Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>

            <div>
                <Dialog
                    open={open}
                    maxWidth="sm"
                    fullWidth
                    onClose={closeReviewForm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <MyReviewForm userId={props.userId} reviewId={reviewId}
                        closeReviewForm={closeReviewForm} onSaveCallback={onSaveCallback} />
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={confirmOpen}
                    maxWidth="sm"
                    fullWidth
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
                </Dialog>
            </div>
        </div>
    );
}

export default MyReviewList;