import React, { useEffect, useState } from 'react';
import './StallItem.scss';
import Card from '@material-ui/core/Card';
import dummyImage from "./../../../assets/images/dummy.png";
import { AlertService, FxCurrency, FxPopup } from '../../fx-core';
import { Button, makeStyles, Dialog } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import RegisterComponent from '../../RegisterComponent/RegisterComponent';
import { useDispatch, useSelector } from 'react-redux';
import CustomizeForm from '../CustomizeForm/CustomizeForm';
import * as _ from 'lodash';
import info from "../../../assets/info.png";
import ImageSlides from '../../general/ImageSlides/ImageSlides';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '35%'
    }
  }
}));


const StallItem = (props: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const cartInfo = useSelector<any, any>(store => store.cart);
  const cart = cartInfo;
  const listingCartData = useSelector<any, any>(s => s.listingCart);
  const [registerComponentFormOpen, setRegisterComponentFormOpen] = useState(false);
  const [customizeFormOpen, setCustomizeFormOpen] = useState(false);
  const [stallItemBatchId, setStallItemBatchId] = useState(0);
  const [stallItemId, setStallItemId] = useState(0);
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});  

  const handleRegisterComponentForm = () => {
    setRegisterComponentFormOpen(false);
  }
  const openRegisterComponentFormOpen = () => {
    setRegisterComponentFormOpen(true);
  }

  const openStallLinkUrl = (prod) => {
    window.open(props.stallLinkUrl, '_blank');
  }

  const reduceQtyFromCart = (item) => {
    getModifierAmount(item);
    dispatch({ type: "REDUCE_CART", payload: { stallId: props.stallId, item: item } });
  };

  const addQtyToCart = (item) => {
    getModifierAmount(item);
    if (listingCartData.cartCount > 0) {
      dispatch({ type: "CLEAR_LISTING_CART", payload: {} }); //Clear the listing order items
    }
    dispatch({ type: "ADD_CART", payload: { stallId: props.stallId, item: item } });
  };

  const getModifierAmount = (item) => {
    if (item.customizedDetails && item.customizedDetails.length > 0) {
      let totalModifierAmt = _.sumBy(item.customizedDetails, 'stallItemCustomizeDetailPrice');
      item.modifierAmt = totalModifierAmt;
    }
  }

  const openCustomizeForm = (item) => {
    setStallItemBatchId(item.id);
    setStallItemId(item.stallItemId);
    setCustomizeFormOpen(true);
  }
  const closeCustomizeForm = () => {
    setCustomizeFormOpen(false);
  }

  const handleCustomizedDetails = (data, id) => {
    setCustomizeFormOpen(false);
    props.handleStallItemCustomized(data, id);

  }

  const openImages = (item: any) => {
    let config = {
      api: `portal/stallitem/${item.id}/images`,
      title: item.title
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
  }  

  return (
    <div className="stallitem-ui">
      {/* Desktop View */}
      <div className="deskview pt-5">
        <Card style={{ width: "350px", height: "250px" }} className="d-flex flex-column justify-content-between py-3 px-2">
          <div className="row">
            <div className="col-6 text-center py-2 pointer" onClick={() => openImages(props.item.stallitem)}>
              {!props.item.stalltemBatch.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
              {props.item.stalltemBatch.imageUrl && <img src={props.item.stalltemBatch.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
            </div>
            <div className="col-6 text-right pt-3">
              <div className="row justify-content-end mr-3">
                <div className="font-24"><b><FxCurrency value={props.item.stalltemBatch.price} /></b></div>
                {props.item.stalltemBatch.unit && <div className="font-15 pt-2 itemunit-style">/{props.item.stalltemBatch.unit}</div>}
              </div>
              <div className="row justify-content-end mr-3 pt-3">
                {props.item.stalltemBatch && !props.item.stalltemBatch.isCustomized &&
                  // <Button size="small" variant="contained" color="primary" className="outline-none" 
                  // onClick={() => addToCart(props.item.stalltemBatch)}>Buy</Button>
                  <div className="d-flex align-items-center justify-content-between b" style={{ width: 80, height: 29 }}>
                    <span className="count-minus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(props.item.stalltemBatch)} style={{ flex: 1 }}>
                      <i className="fas fa-minus-square"></i>
                    </span>
                    <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer"
                      style={{ flex: 1 }}>{props.item.stalltemBatch.quantity}</span>
                    <span className="count-plus pointer color-primary h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(props.item.stalltemBatch)} style={{ flex: 1 }}>
                      <i className="fas fa-plus-square"></i>
                    </span>
                  </div>
                }
                {props.item.stalltemBatch && props.item.stalltemBatch.isCustomized &&
                  <span>
                    <Button size="small" variant="contained" color="primary" className="outline-none"
                      onClick={() => openCustomizeForm(props.item.stalltemBatch)}>Add</Button>
                  </span>
                }
              </div>
            </div>
          </div>
          <div>
            <span className=' pl-2 font-22 itemname-style'>{props.item.stalltemBatch.itemName}</span>
            {props.item.stalltemBatch.stallItemDescription && <span className='info-placement pl-1 zoom-in-out-box'>
              <FxPopup image={info} maxWidth="370px" fontSize="12px">
                <div className='p-3'>
                  {props.item.stalltemBatch.stallItemDescription}
                </div>
              </FxPopup>
            </span>}
          </div>
          {/* <div className="row pr-3 pl-4 font-13">
            {props.item.stalltemBatch.stallItemDescription}
          </div> */}
          <div className="row pl-4">
            {
              props.item.stallItemTags && props.item.stallItemTags.length > 0 && props.item.stallItemTags.map((tag, i) => (
                <div className="pr-2">
                  <div className="tagname-style font-12 px-1 py-0">
                    {tag.itemTagName}
                  </div>
                </div>
              ))
            }
          </div>
        </Card>
      </div>

      {/* Tablet View */}
      <div className="tabview pt-5">
        <Card style={{ height: "150px" }} className="d-flex flex-column justify-content-between py-3 px-2">
          <div className="row">
            <div className="col-3 text-center py-2">
              <div className='pointer' onClick={() => openImages(props.item.stallitem)}>
                {!props.item.stalltemBatch.imageUrl && <img src={dummyImage} alt="Item" width="120" height="120" className="itemimage"></img>}
                {props.item.stalltemBatch.imageUrl && <img src={props.item.stalltemBatch.imageUrl} alt="Item" width="120" height="120" className="itemimage"></img>}
              </div>
              <div className="col-12 text-center pl-4 mt-n4">
                {props.item.stalltemBatch && !props.item.stalltemBatch.isCustomized &&
                  <div className="d-flex align-items-center justify-content-between b" style={{ width: 80, height: 29, backgroundColor: "#f69516", borderRadius: "4px" }}>
                    <span className="count-minus pointer color-white h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(props.item.stalltemBatch)} style={{ flex: 1 }}>
                      <i className="fas fa-minus-square font-20"></i>
                    </span>
                    <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer color-white"
                      style={{ flex: 1 }}><b>{props.item.stalltemBatch.quantity}</b></span>
                    <span className="count-plus pointer color-white h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(props.item.stalltemBatch)} style={{ flex: 1 }}>
                      <i className="fas fa-plus-square font-20"></i>
                    </span>
                  </div>
                }
                {props.item.stalltemBatch && props.item.stalltemBatch.isCustomized &&
                  <span>
                    <Button size="small" variant="contained" color="primary" className="outline-none"
                      onClick={() => openCustomizeForm(props.item.stalltemBatch)}>Add</Button>
                  </span>
                }
              </div>

            </div>
            <div className="col-9 pt-1 pl-5">
              <div>
                <span className='pl-2 font-22 itemname-style'>{props.item.stalltemBatch.itemName}</span>
                {props.item.stalltemBatch.stallItemDescription && <span className='info-placement pl-1 zoom-in-out-box'>
                  <FxPopup image={info} maxWidth="370px" fontSize="12px">
                    <div className='p-3'>
                      {props.item.stalltemBatch.stallItemDescription}
                    </div>
                  </FxPopup>
                </span>}
              </div>
              <div className="row pl-4 py-1">
                {
                  props.item.stallItemTags && props.item.stallItemTags.length > 0 && props.item.stallItemTags.map((tag, i) => (
                    <div className="pr-2">
                      <div className="tagname-style font-14 px-1 py-0">
                        {tag.itemTagName}
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="pl-2">
                <span className="font-18"><b><FxCurrency value={props.item.stalltemBatch.price} /></b></span>
                {props.item.stalltemBatch.unit && <span className="font-12 itemunit-style">/{props.item.stalltemBatch.unit}</span>}
              </div>
            </div>
          </div>
        </Card>
      </div>

      {/* Mobile View */}
      <div className="mobileview pt-2">
        <Card style={{ height: "150px" }} className="d-flex flex-column justify-content-between py-2 px-2">
          <div className="row">
            <div className="col-4 text-center py-1">
              <div className='pointer' onClick={() => openImages(props.item.stallitem)}>
                {!props.item.stalltemBatch.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                {props.item.stalltemBatch.imageUrl && <img src={props.item.stalltemBatch.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
              </div>
              <div className="col-12 text-center mt-1">
                {props.item.stalltemBatch && !props.item.stalltemBatch.isCustomized &&
                  <div className="d-flex align-items-center justify-content-between" style={{ width: 70, height: 29, backgroundColor: "#f69516", borderRadius: "4px" }}>
                    <span className="count-minus pointer color-white h-100 d-flex align-items-center justify-content-center" onClick={() => reduceQtyFromCart(props.item.stalltemBatch)} style={{ flex: 1 }}>
                      <i className="fas fa-minus-square font-18"></i>
                    </span>
                    <span className="quantity-count bl br d-flex align-items-center justify-content-center h-100 m-0 pointer color-white"
                      style={{ flex: 1 }}><b>{props.item.stalltemBatch.quantity}</b></span>
                    <span className="count-plus pointer color-white h-100 d-flex align-items-center justify-content-center" onClick={() => addQtyToCart(props.item.stalltemBatch)} style={{ flex: 1 }}>
                      <i className="fas fa-plus-square font-18"></i>
                    </span>
                  </div>
                }
                {props.item.stalltemBatch && props.item.stalltemBatch.isCustomized &&
                  <span className='pl-1'>
                    <Button size="small" variant="contained" color="primary" className="outline-none"
                      onClick={() => openCustomizeForm(props.item.stalltemBatch)}>Add</Button>
                  </span>
                }
              </div>
            </div>
            <div className="col-8 pt-2 pl-0">
              <div>
                <span className='pl-2 font-20 itemname-style'>{props.item.stalltemBatch.itemName}</span>
                {props.item.stalltemBatch.stallItemDescription && <span className='info-placement pl-1 zoom-in-out-box'>
                  <FxPopup image={info} maxWidth="370px" fontSize="12px">
                    <div className='p-3'>
                      {props.item.stalltemBatch.stallItemDescription}
                    </div>
                  </FxPopup>
                </span>}
              </div>              
              <div className="row pl-4 py-1">
                {
                  props.item.stallItemTags && props.item.stallItemTags.length > 0 && props.item.stallItemTags.map((tag, i) => (
                    <div className="pr-2">
                      <div className="tagname-style font-12 px-1 py-0">
                        {tag.itemTagName}
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="pl-2">
                <span className="font-16"><b><FxCurrency value={props.item.stalltemBatch.price} /></b></span>
                {props.item.stalltemBatch.unit && <span className="font-12 itemunit-style">/{props.item.stalltemBatch.unit}</span>}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Drawer anchor={"right"} open={registerComponentFormOpen} onClose={handleRegisterComponentForm}>
        <RegisterComponent stallId={props.stallId} handleRegisterComponentForm={handleRegisterComponentForm} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={customizeFormOpen} onClose={closeCustomizeForm}>
        <CustomizeForm stallItemBatchId={stallItemBatchId} stallItemId={stallItemId}
          handleCustomizedDetails={handleCustomizedDetails} closeCustomizeForm={closeCustomizeForm} />
      </Drawer>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>      
    </div>
  );
}
export default StallItem;