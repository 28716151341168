import React, { useEffect, useState } from 'react';
import './ManageUsers.scss';
import { http, FxSelect, AlertService } from '../../fx-core';
import { Button, Dialog } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import _ from "lodash";

const ManageUsers = (props) => {
  let defaultItem =
  {
    name: props.communityName,
    userId: 0,
    roleCode: "",
    communityId: props.communityId
  };
  const [item, setItem] = useState(defaultItem);
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(false);
  const [userRoleMapList, setUserRoleMapList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [message, setMessage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [roleCode, setRoleCode] = useState(null);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initMethods = () => {
    let code: any = null;
    if (props.parent == "superadmin") {
      code = "REGIONALMANAGER";
      initAdminLookup();
    }
    else if (props.parent == "region") {
      code = "ZONALMANAGER";
      initRegionLookup();
    }
    else if (props.parent == "zonal") {
      code = "COMMADMIN";
      initZoneLookup();
    }
    else if(props.parent == "splcommunity") {
      code = "SPECIALCOMMADMIN";
      initSplCommunityAdminLookup();
    }    
    setRoleCode(code);
    getUserRoleMapList(code);
  }

  const initAdminLookup = () => {
    let inputData = {
      lookups: {
        ManagerRoles: { default: false }
      },
      filters: {
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initAdminLookupCallBack(response);
    })
  }

  const initAdminLookupCallBack = (res) => {
    let result = res.data;
    result.ManagerRoles = result.ManagerRoles.filter(i => i.code == "REGIONALMANAGER");
    setRoles(result.ManagerRoles);
    if (result.ManagerRoles.length > 0) {
      defaultRoleByCode(result.ManagerRoles[0].code);
    }
  };

  const initRegionLookup = () => {
    let inputData = {
      lookups: {
        ManagerRoles: { default: false }
      },
      filters: {
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initRegionLookupCallBack(response);
    })
  }

  const initRegionLookupCallBack = (res) => {
    let result = res.data;
    result.ManagerRoles = result.ManagerRoles.filter(i => i.code == "ZONALMANAGER");
    setRoles(result.ManagerRoles);
    if (result.ManagerRoles.length > 0) {
      defaultRoleByCode(result.ManagerRoles[0].code);
    }    
  };

  const initZoneLookup = () => {
    let inputData = {
      lookups: {
        CommunityRoles: { default: false }
      },
      filters: {
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initZoneLookupCallBack(response);
    })
  }

  const initZoneLookupCallBack = (res) => {
    let result = res.data;
    result.CommunityRoles = result.CommunityRoles.filter(i => i.code == "COMMADMIN");
    setRoles(result.CommunityRoles);
    if (result.CommunityRoles.length > 0) {
      defaultRoleByCode(result.CommunityRoles[0].code);
    }    
  };

  const initSplCommunityAdminLookup = () => {
    let inputData = {
      lookups: {
        CommunityRoles: { default: false }
      },
      filters: {
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initSplCommunityAdminLookupCallBack(response);
    })
  }

  const initSplCommunityAdminLookupCallBack = (res) => {
    let result = res.data;
    result.CommunityRoles = result.CommunityRoles.filter(i => i.code == "SPECIALCOMMADMIN");
    setRoles(result.CommunityRoles);
    if (result.CommunityRoles.length > 0) {
      defaultRoleByCode(result.CommunityRoles[0].code);
    }    
  };  

  const getUserRoleMapList = (code) => {
    let inputData = {
      code: code,
      communityId: item.communityId
    }
    let apiOptions: any = {
      url: `user/userrolemap/community`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        item.user.fullName = item.user.lastName ? item.user.firstName + " " + item.user.lastName : item.user.firstName;
      }
      setUserRoleMapList(result);
      getUsersByCommId();
    })
  }

  const defaultRoleByCode = (code) => {
    handleItemChange({ roleCode: code });
  }

  const getUsersByCommId = () => {
    //const communityId = (props.parent == "zonal") ? item.communityId : 0;
    let inputData = {
      communityId: 0
    }
    let apiOptions: any = {
      url: `user/query/community`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        let fullName = item.lastName ? item.firstName + " " + item.lastName : item.firstName;
        item.fullName = fullName ? item.userName + " - " + fullName : item.userName;

      }
      setUserList(result);
    })
  }

  const handleClose = () => {
    props.handleManageUsersClose();
  }

  const saveUserRoleMap = () => {
    setError(false);
    if (!item.userId || !item.roleCode) {
      setError(true);
      return;
    }
    let apiOptions: any = {
      url: `community/userrolemap/add`,
      data: item
    };
    http.post(apiOptions).then(res => {
      saveUserRoleMapCallBack(res.data);
    })
  };

  const saveUserRoleMapCallBack = (res) => {
    AlertService.showSuccessMsg();
    getUserRoleMapList(roleCode);
    const data = {
      userId: 0
    };
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  }

  const confirmDelete = (id: any) => {
    setSelectedUserId(id);
    setConfirmOpen(true);
    setMessage("Do you want to delete this user?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    deleteUserRoleMap();
  }

  const deleteUserRoleMap = () => {
    let inputData = {
      id: selectedUserId
    };
    let apiOptions: any = {
      url: `userrolemap/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getUserRoleMapList(roleCode);
    })
  };

  useEffect(() => {
    initMethods();
  }, []);

  return (
    <div className='manageusers-ui confirmContainer'>
      <div className="row justify-content-between title-font px-3">
        <div className="py-1 pl-3">
          <span>Manage Users</span>
        </div>
        <div className="text-right pointer my-auto">
          <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
        </div>
      </div>

      <div className="row my-2 p-3 justify-content-between">
        <div className="col-12 py-2">
          <span>Community Name : {item.name}</span>
        </div>
        <div className="col-12 py-3">
          <FxSelect name="roleCode" variant="outlined" label="Role"
            options={roles}
            selectedValue={item.roleCode}
            valueField="code"
            displayField="text"
            size="small"
            onValueChange={handleInputChange} />
          {error && !item.roleCode &&
            <div className="validation-msg">Role is required</div>
          }
        </div>
        <div className="col-12 py-3">
          <FxSelect name="userId" variant="outlined" label="User"
            options={userList}
            selectedValue={item.userId}
            valueField="id"
            displayField="fullName"
            size="small"
            onValueChange={handleInputChange} />
          {error && !item.userId &&
            <div className="validation-msg">User is required</div>
          }
        </div>
        <div className="col-12 py-2">
          <span className='float-right'>
            <Button variant="contained" size="small" color="primary" onClick={saveUserRoleMap}>
              Add
            </Button>
          </span>
        </div>
        {userRoleMapList.map((row: any, i) => (
          <div className="col-12 row pt-3 pr-0 pl-3">
            <span className='col-5'>
              {row.user.userName}
              {row.user.fullName &&
                <span> - {row.user.fullName}</span>
              }
            </span>
            <span className='col-5'>
              {row.uRoleMap.roleName}
            </span>
            <span className='col-2 text-right pr-1'>
              <i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row.uRoleMap.id)}></i>
            </span>
          </div>
        ))
        }
      </div>

      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default ManageUsers;