import React, { useState, useEffect } from 'react';
import './AddressForm.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { http, formValidator, AlertService, FxInput, FxSelect, LocationSearchInput, FxMobile, FxTextarea } from '../../fx-core';
import Checkbox from "@material-ui/core/Checkbox";
import Button from '@material-ui/core/Button';

export const AddressForm = (props) => {
  const defaultItem = {
    fullName: "",
    displayName: "",
    mobile: "",
    fullAddress: "",
    country: "",
    pinCode: "",
    addressTypeId: -1,
    locationId: -1,
    isDefault: false,
    id: props.addressId ? props.addressId : 0,
    customerId: 0,
    countryCode: ''
  };

  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const initialList = [];
  const [addressType, setAddressType] = useState(initialList);
  const [locations, setLocations] = useState(initialList);
  const [countryCode, setCountryCode] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        AddressType: { default: true },
        Tier4Locations: { default: true },
        CountryCode: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setAddressType(response.data.AddressType);
      setLocations(response.data.Tier4Locations);
      setCountryCode(response.data.CountryCode);
    })
  }

  //Validation area starts
  const validationMap_Item = {
    fullName: ["required"],
    mobile: ["required", "mobileno"],
    fullAddress: ["required"],
    country: ["required"],
    pinCode: ["required", "pincode"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const addressSelected = (data) => {
    let inputData = { fullAddress: data.address, lat: data.lat, lng: data.lng };
    console.log(data);
    handleItemChange(inputData);
  };

  const onReceiveOtherDetails = (data) => {
    let inputData = { country: data.country, pinCode: data.pinCode };
    handleItemChange(inputData);
  }

  const saveAddress = () => {
    const isValid = bulkValidate();
    if (isValid) {
      if (item.locationId == -1) {
        AlertService.showErrorMsg("Please select location");
        return;
      }
      if (item.mobile) {
        item.countryCode = item.countryCode ? item.countryCode : "+91";
        item.mobile = item.countryCode + item.mobile;
      }
      if (item.id) {
        //update address
        updateAddress(item);
      }
      else {
        //add address   
        addAddress(item);
      }
    }
  };

  const addAddress = (item: any) => {
    let apiOptions: any = {
      url: `address`,
      data: item
    };
    http.post(apiOptions).then(res => {
      saveAddressCallBack(res);
    });
  }

  const updateAddress = (item: any) => {
    let apiOptions: any = {
      url: `address/${item.id}`,
      data: item
    };
    http.put(apiOptions).then(res => {
      saveAddressCallBack(res);
    });
  }

  const saveAddressCallBack = (res) => {
    AlertService.showSuccessMsg();
    props.onSaveCallback();
  }

  const getAddressById = () => {
    let apiOptions: any = {
      url: `address/${props.addressId}`
    };
    http.get(apiOptions).then(res => {
      setItem(res.data);
    });
  };

  const getUserInfo = () => {
    let apiOptions: any = {
      url: `user/profile`
    };
    http.get(apiOptions).then(res => {
      handleItemChange({ fullName: res.data.firstName });
      if (res.data.lastName) {
        handleItemChange({ fullName: res.data.firstName + " " + res.data.lastName });
      }
      handleItemChange({ mobile: res.data.mobile });
    })
  };

  useEffect(() => {
    initLookup();
    if (props.userId > 0 && props.addressId == 0) {
      handleItemChange({ userId: props.userId });
      getUserInfo();
    }
    else if (props.addressId > 0) {
      getAddressById();
    }
  }, []);

  return (
    <div className="customerAddress-ui">
      <div className="row justify-content-between title-font py-1 mx-auto px-3">
        <div className="py-1">
          <span>Address Form</span>
        </div>
        <div className="float-right pointer" onClick={props.closeAddressForm}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>

      <div className="m-4">
        <div className="m-1">
          <FxInput name="fullName" variant="outlined" label="Full name" fullWidth
            value={item.fullName} onValueChange={handleInputChange} />
          {hasError("fullName", "required") && (
            <div className="error">Field is required</div>
          )}
        </div>

        <div className="m-1 pt-3">
          <FxInput name="displayName" variant="outlined" label="Display Name" fullWidth
            value={item.displayName} onValueChange={handleInputChange} />
        </div>

        <div className="m-1 pt-3">
          <FxMobile value={item.mobile} onValueChange={handleInputChange} countryCode={countryCode} />

          {hasError("mobile", "required") && (
            <div className="error">Field is required</div>
          )}
          {
            hasError("mobile", "mobileno") &&
            <div className="error">Please enter valid mobile number</div>
          }
        </div>

        {/* <div className="m-1 pt-3">
          {(item.id > 0 && item.fullAddress) && <LocationSearchInput address={item.fullAddress} name="fullAddress"
            fullWidth locationSelected={addressSelected} onReceiveOtherDetails={onReceiveOtherDetails}
            placeholder="Full Address" />}

          {(item.id == 0 || !item.fullAddress) && <LocationSearchInput address={item.fullAddress} name="fullAddress"
            fullWidth locationSelected={addressSelected} onReceiveOtherDetails={onReceiveOtherDetails}
            placeholder="Full Address" />}

          {hasError("fullAddress", "required") && (
            <div className="error">Field is required</div>
          )}
        </div> */}

        <div className="m-1 pt-3">
          <FxTextarea name="fullAddress" variant="outlined" fullWidth size="small" label="Full Address"
            value={item.fullAddress} onValueChange={handleInputChange} maxRows={4} />

          {hasError("fullAddress", "required") && (
            <div className="error">Field is required</div>
          )}
        </div>
        <div className="m-1 pt-3">
          <FxInput name="country" variant="outlined" label="Country" value={item.country} fullWidth
            onValueChange={handleInputChange} />
          {hasError("country", "required") && (
            <div className="error">Field is required</div>
          )}
        </div>

        <div className="m-1 pt-3">
          <FxInput name="pinCode" type="number" variant="outlined" label="Pincode" value={item.pinCode} fullWidth
            onValueChange={handleInputChange} />
          {hasError("pinCode", "required") && (
            <div className="error">Field is required</div>
          )}
          {hasError("pinCode", "pincode") && (
            <div className="error">Invalid Pincode</div>
          )}
        </div>

        <div className="m-1 pt-3">
          <FxSelect name="addressTypeId" variant="outlined" label="Address Type"
            options={addressType}
            selectedValue={item.addressTypeId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>

        <div className="m-1 pt-3">
          <FxSelect name="locationId" variant="outlined" label="Location"
            options={locations}
            selectedValue={item.locationId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>


        <div className="font-12">
          <Checkbox name="isDefault" color="primary" checked={item.isDefault}
            value={item.isDefault} onChange={handleCheckbox} />
          Set as Default
        </div>

        <div className="m-1 text-center">
          <Button variant="contained" size="medium" color="primary" onClick={saveAddress}>
            Save
          </Button></div>
      </div>
    </div>
  );
};