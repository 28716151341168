import React, { useEffect, useState } from 'react';
import './MyOrders.scss';
import { FxDateDisplay, FxCurrency, http, AlertService } from '../../fx-core';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import MyOrderDetail from '../MyOrderDetail/MyOrderDetail';
import * as _ from 'lodash';
import { Button, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Drawer, makeStyles } from '@material-ui/core';
import MessageContainer from '../../MessageContainer/MessageContainer';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const MyOrders = (props: any) => {
  const classes = styles();
  const OrderStatus = [
    { id: -1, text: "All", code: "-1", isDefault: false, attributes: null },
    { id: 1000, text: "Open", code: "Open", isDefault: false, attributes: null },
    { id: 6, text: "Delivered", code: "Delivered", isDefault: false, attributes: null },
    { id: 7, text: "Cancelled", code: "Cancelled", isDefault: false, attributes: null }
  ];

  let defaultFilter: any = {
    orderStatusId: -1
  };
  const initialList = [];
  const [orderList, setOrderList] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [orderId, setOrderId] = React.useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const [currentContext, setCurrentContext] = useState({ totalAmount: 0, razorpayText: "Card" });
  const [selOrderStatusId, setSelOrderStatusId] = useState(-1);
  const [openMessage, setOpenMessage] = React.useState(false);

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let options = { filter: { [item.name]: item.value } };
    getOrderList(options);
  }

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };


  const getOrderList = (options: any = {}) => {
    let selectedStatusId = 1;
    if (options && options.filter) {
      selectedStatusId = options.filter.orderStatusId ? options.filter.orderStatusId : -1;
    }
    let inputData = { orderStatusId: selectedStatusId, userId: props.userId };

    let apiOptions: any = {
      url: 'order/query',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setOrderList(response.data);
    })
  }

  const viewOrderDetail = (item: any) => {
    setOpen(true);
    setOrderId(item.id);
  }

  const handleClose = () => {
    setOpen(false);
    init();
  }

  const Razorpay = useRazorpay();

  const initiatePayment = (id) => {
    let inputData = { id: id };
    let apiOptions: any = {
      url: 'order/GetPaymentLink',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      openPaymentLink(response);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item =  resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.name;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        init();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
      //TODO: Api call
      //session_id - must
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `order/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const handleStatusChange = (selId) => {
    setSelOrderStatusId(selId);
    let item = { name: "orderStatusId", value: selId };
    handleInputChange(item);
  };

  const navToMessage = (orderId) => {
    setOrderId(orderId);
    setOpenMessage(true);
  }

  const handleMessageClose = () => {
    setOpenMessage(false);
    setOrderId(0);
  }

  const init = () => {
    let options = { filter: filter };
    getOrderList(options);
  }

  useEffect(() => {
    init();
  }, []);


  const body = (
    <div className="myorders-ui">
      <div className="content-container">
        <div className="content">
          {/* <div className="mt-n4 pl-1">
            <h5>Orders</h5>
          </div> */}

          {/* Desktop View */}
          <div className='deskview'>
            <div className='ml-0 mt-4'>
              {OrderStatus.map((item, i) => (
                <span className='mr-3'>
                  {
                    selOrderStatusId === item.id &&
                    <Button variant='outlined' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                  {
                    selOrderStatusId !== item.id &&
                    <Button variant='contained' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                </span>
              ))
              }
            </div>

            {/* <div className="row col-12 mt-n1 px-0">
            <div className="col-12 text-right px-0">
              <FxSelect name="orderStatusId" variant="outlined" label="Order Status"
                options={orderStatus}
                selectedValue={filter.orderStatusId}
                valueField="id"
                displayField="text"
                size="small"
                style={{ width: 280, float: 'right' }}
                onValueChange={handleInputChange} />
            </div>
          </div> */}

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell></TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.placedDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'>#{row.orderIdentifier}</TableCell>
                        <TableCell>{row.orderStatus}</TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'razorpay' &&
                                <span>
                                  {(itemObj.paymentStatus == 'Pending' || itemObj.paymentStatus == 'Initiated') &&
                                    <span>
                                      {currentContext.razorpayText}:
                                      <Link className="btn btn-address font-14" onClick={() => initiatePayment(row.id)}
                                        title="pay">Pay</Link>
                                    </span>
                                  }
                                  {itemObj.paymentStatus != 'Pending' && itemObj.paymentStatus != 'Initiated' &&
                                    <span>
                                      {currentContext.razorpayText} / {itemObj.paymentStatus}
                                    </span>
                                  }
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'razorpay' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                          <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Tablet View */}
          <div className='tabview'>
            <div className='ml-0 mt-4'>
              {OrderStatus.map((item, i) => (
                <span className='mr-3'>
                  {
                    selOrderStatusId === item.id &&
                    <Button variant='outlined' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                  {
                    selOrderStatusId !== item.id &&
                    <Button variant='contained' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                </span>
              ))
              }
            </div>

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell></TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.placedDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'>#{row.orderIdentifier}</TableCell>
                        <TableCell>{row.orderStatus}</TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'razorpay' &&
                                <span>
                                  {itemObj.paymentStatus == 'Pending' || itemObj.paymentStatus == 'Initiated' &&
                                    <span>
                                      {currentContext.razorpayText}:
                                      <Link className="btn btn-address font-14" onClick={() => initiatePayment(itemObj.id)}
                                        title="pay">Pay</Link>
                                    </span>
                                  }
                                  {itemObj.paymentStatus != 'Pending' && itemObj.paymentStatus != 'Initiated' &&
                                    <span>
                                      {currentContext.razorpayText} / {itemObj.paymentStatus}
                                    </span>
                                  }
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'razorpay' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                          <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Mobile View */}
          <div className='mobileview mb-4'>
            <div className='ml-0 mt-4 mb-4'>
              {OrderStatus.map((item, i) => (
                <span className='mr-2'>
                  {
                    selOrderStatusId === item.id &&
                    <Button variant='outlined' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                  {
                    selOrderStatusId !== item.id &&
                    <Button variant='contained' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                </span>
              ))
              }
            </div>
            {orderList.map((row: any, i) => (
              <div>
                <Card className="mt-3 py-2 px-3">
                  <div className='row justify-content-between px-2'>
                    <div>
                      <div className='font-13 bold-text'>#{row.orderIdentifier}</div>
                      <div className='font-11'><FxDateDisplay value={row.placedDate} /></div>
                    </div>
                    <div className='text-right'>
                      <div className='font-13 bold-text'><FxCurrency value={row.orderTotal} /></div>
                      <div className='font-11'>{row.orderStatus}</div>
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-2'>
                    <div className='font-13'>{row.stallName}</div>
                    <div className='font-13 text-right'>
                      {row.orderTypeId == 1 &&
                        <span>Delivery</span>
                      }
                      {row.orderTypeId == 2 &&
                        <span>Pickup</span>
                      }
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-1'>
                    <div className='font-12'>
                      {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                        <div>
                          {itemObj.paymentMode == 'cod' &&
                            <span className='payment-style'>
                              Cash / {itemObj.paymentStatus}
                            </span>
                          }
                          {itemObj.paymentMode == 'razorpay' &&
                            <span>
                              {itemObj.paymentStatus == 'Pending' || itemObj.paymentStatus == 'Initiated' &&
                                <span>
                                  {currentContext.razorpayText}:
                                  <Link className="btn btn-address font-14" onClick={() => initiatePayment(itemObj.id)}
                                    title="pay">Pay</Link>
                                </span>
                              }
                              {itemObj.paymentStatus != 'Pending' && itemObj.paymentStatus != 'Initiated' &&
                                <span>
                                  {currentContext.razorpayText} / {itemObj.paymentStatus}
                                </span>
                              }
                            </span>
                          }
                          {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'razorpay' &&
                            <span className='payment-style'>
                              {itemObj.paymentMode} / {itemObj.paymentStatus}
                            </span>
                          }
                        </div>
                      ))
                      }
                    </div>
                    <div className='text-right'>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                      <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>

        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <MyOrderDetail orderId={orderId} handleClose={handleClose} />
        </Dialog>
        <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
          <MessageContainer orderId={orderId} isMyOrders={true} handleMessageClose={handleMessageClose} />
        </Drawer>
      </div>
    </div>
  );


  return (
    <div>{body}</div>
  );

}

export default MyOrders;