import React from 'react';
import './ServiceCard.scss';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
//import dummyImage from "./../../../assets/images/dummy.png";
import catBgImage from "./../../../assets//img/categorybackground.png";
import dummyImage from "./../../../assets/img/defaultcategory.png";
import cardbackground from "../../../assets/cardbackground.svg";
import deskcardbackground from "../../../assets/deskcardbackground.svg";
import tabcardbackground from "../../../assets/tabcardbackground.svg";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

const ServiceCard = (props: any) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1
        }
    };
    return (
        <div className="servicecard-ui">
            <div className='font-22 text-center font-weight-bold color-secondary pb-2 pt-4'>Categories</div>
            <Carousel responsive={responsive}
                autoPlay={false}
                showDots={false}
                arrows={true}
                infinite={false}
                swipeable={true}
                draggable={true}
            >
                {
                    props.items && props.items.map((item, i) => (
                        <div key={i} className="text-center task-card-style px-0 pointer"
                            onClick={() => props.navToStallHome(item)}>
                            <div style={{
                                backgroundImage: "url(" + catBgImage + ")", backgroundRepeat: "no-repeat", color: "#6D4B40",
                                backgroundSize: "47px auto", position: "relative", height: "50px", backgroundPosition: "center"
                            }}>
                                {!item.image && <img src={dummyImage} alt="img" className="dummyimg-style" ></img>}
                                {item.image && <img src={item.image} alt="img" className="img-style"></img>}
                            </div>
                            <div className="pt-1">{item.ServiceName}</div>
                        </div>
                    ))
                }
            </Carousel>
            {/* Desktop View */}
            {/* <div className="deskview pt-5" onClick={() => props.navToStallHome(props.item)}>
                <Card className="pointer" style={{ backgroundImage: "url(" + deskcardbackground + ")", backgroundRepeat: "no-repeat", borderRadius: "10px", color: "#6D4B40", display: "flex", justifyContent: "space-between", flexDirection: "column", width: "900px", height: "300px", }} >
                    <div className="row">
                        <div className="col-6 text-center pt-4">
                            {!props.item.image && <img src={dummyImage} alt="Service Image" width="250" height="250"></img>}
                            {props.item.image && <img src={props.item.image} alt="Service Image" width="250" height="250"></img>}
                        </div>
                        <div className="col-6 px-1">
                            <div className="font-24 text-center pt-3"><b>{props.item.ServiceName}</b></div>
                            <div className="font-16 pt-5 pl-3 pr-3 text-center" style={{ height: "180px" }}>
                                {props.item.Description}
                            </div>
                            <div className="row justify-content-between font-15 itemsrow pb-2">
                                <div><b>{props.item.StallCount} Stalls</b></div>
                                <div>
                                    {(props.item.ServiceBehaviorCode == 'ItemWise') &&
                                        <span><b>{props.item.ItemCount} Items</b></span>
                                    }
                                    {(props.item.ServiceBehaviorCode == 'Listings') &&
                                        <span><b>{props.item.ListingCount} Listings</b></span>
                                    }
                                    {(props.item.ServiceBehaviorCode == 'Bookings') &&
                                        <span><b>{props.item.CCListingCount} Listings</b></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div> */}

            {/* Tab View */}
            {/* <div className="tabview pt-5" onClick={() => props.navToStallHome(props.item)}>
                <Card className="pointer" style={{ backgroundImage: "url(" + tabcardbackground + ")", backgroundRepeat: "no-repeat", borderRadius: "10px", color: "#6D4B40", display: "flex", justifyContent: "space-between", flexDirection: "column", width: "600px", height: "250px", }} >
                    <div className="row">
                        <div className="col-6 text-center pt-4">
                            {!props.item.image && <img src={dummyImage} alt="Service Image" width="200" height="200"></img>}
                            {props.item.image && <img src={props.item.image} alt="Service Image" width="200" height="200"></img>}
                        </div>
                        <div className="col-6 px-1">
                            <div className="font-22 text-center pt-3"><b>{props.item.ServiceName}</b></div>
                            <div className="font-15 pt-5 pl-3 pr-3 text-center" style={{ height: "130px" }}>
                                {props.item.Description}
                            </div>
                            <div className="row justify-content-between font-15 itemsrow pb-2">
                                <div><b>{props.item.StallCount} Stalls</b></div>
                                <div>
                                    {(props.item.ServiceBehaviorCode == 'ItemWise') &&
                                        <span><b>{props.item.ItemCount} Items</b></span>
                                    }
                                    {(props.item.ServiceBehaviorCode == 'Listings') &&
                                        <span><b>{props.item.ListingCount} Listings</b></span>
                                    }
                                    {(props.item.ServiceBehaviorCode == 'Bookings') &&
                                        <span><b>{props.item.CCListingCount} Listings</b></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div> */}

            {/* Mobile View */}
            {/* <div className="mobileview pt-2" onClick={() => props.navToStallHome(props.item)}>
                <Card className="pointer" style={{ backgroundImage: "url(" + cardbackground + ")", backgroundRepeat: "no-repeat", borderRadius: "10px", color: "#6D4B40", display: "flex", justifyContent: "space-between", flexDirection: "column", width: "300px", }} >
                    <div className="row">
                        <div className="col-6 text-center my-auto">
                            {!props.item.icon && <img src={dummyImage} alt="Service Image" width="60" height="60"></img>}
                            {props.item.icon && <img src={props.item.icon} alt="Service Image" width="60" height="60"></img>}
                        </div>
                        <div className="col-6 px-1">
                            <div className="font-14 text-center pt-3"><b>{props.item.ServiceName}</b></div>
                            <div className="font-11 pt-2 pl-2 pr-3 text-center" style={{ height: "80px" }}>
                                {props.item.Description}
                            </div>
                            <div className="row justify-content-between font-12 itemsrow pb-2">
                                <div><b>{props.item.StallCount} Stalls</b></div>
                                <div>
                                    {(props.item.ServiceBehaviorCode == 'ItemWise') &&
                                        <span><b>{props.item.ItemCount} Items</b></span>
                                    }
                                    {(props.item.ServiceBehaviorCode == 'Listings') &&
                                        <span><b>{props.item.ListingCount} Listings</b></span>
                                    }
                                    {(props.item.ServiceBehaviorCode == 'Bookings') &&
                                        <span><b>{props.item.CCListingCount} Listings</b></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div> */}
        </div>
    );
}
export default ServiceCard;