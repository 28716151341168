import { Button, Dialog } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import './CommunityList.scss';
import { http } from '../../fx-core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const CommunityList = (props) => {

  const defaultCommuObj = {
    Id: 0,
    Name: ""
  };
  const [communityList, setCommunityList] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [selCommunity, setSelCommunity] = useState(defaultCommuObj);
  const [selCommunityList, setSelCommunityList] = useState([]);
  /*const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedItem, setSelectedItem] = useState({ id: 0 });*/

  const handleClose = () => {
    props.handleDialogClose();
  };

  const showMore = (item: any) => {
    const commuItem = { Id: item.Id, Name: item.Name };
    setSelCommunity(commuItem);
    getSelCommunityList(item.Id);
    setCurrentStep(2);
  };
  const navToBack = () => {
    setCurrentStep(1);
  }

  const getCommunityList = () => {
    let inputData = {
      communityId: props.tier2CommunityId
    }
    let apiOptions: any = {
      url: `community/locationwise/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getCommunityListCallBack(res);
    });
  };

  const getCommunityListCallBack = (res) => {
    setCommunityList(res.data.items);
    setCurrentStep(1);
  };

  const getSelCommunityList = (communityId) => {
    let inputData = {
      communityId: communityId
    }
    let apiOptions: any = {
      url: `community/parent/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getSelCommunityListCallBack(res);
    });
  };

  const getSelCommunityListCallBack = (res) => {
    setSelCommunityList(res.data);
  };

  const confirmSwitchCommunity = (event, item: any, isShowMore) => {
    /*let selItem = {
      id : isShowMore ? item.id : item.Id
    }
    setSelectedItem(selItem);
    setConfirmOpen(true);
    let name = isShowMore ? item.name : item.Name;
    setMessage("Are you sure to switch to " + name + " community?");*/
    let communityId = isShowMore ? item.id : item.Id;
    switchCommunityWithoutLogin(communityId);
    event.stopPropagation();
  };

  /*const onConfirmCallback = () => {
    setConfirmOpen(false);
    switchCommunityWithoutLogin();
  };

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };*/

  const switchCommunityWithoutLogin = (communityId) => {
    let inputData = {
      communityId: communityId
    };
    let apiOptions: any = {
      url: `community/switch/withoutlogin`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result && result.linkUrl) {
        window.open(result.linkUrl, '_self');
      }
    })
  };

  useEffect(() => {
    getCommunityList();
  }, []);


  return (
    <div className='community-list pb-5 pt-3'>
      <div className='float-right pr-3'><i className="fas fa-times-circle color-primary font-24 pointer" onClick={handleClose}></i></div>
      {
        currentStep == 1 &&
        <div>
          <div className='font-24 text-center py-4'>{props.tier2CommunityName}</div>
          <div className='row mx-3'>
            {
              communityList.length > 0 && communityList.map((commu: any, i) => (
                <div className='col-6 col-md-4 col-lg-3 text-center d-flex flex-column justify-content-between mb-5' key={i}>
                  <div>
                    <div className='community-name py-1'>{commu.Name}</div>
                    {
                      commu.items.length > 0 && commu.items.map((item: any, i) => (
                        <div key={i}>
                          {
                            i < 5 &&
                            <div className='pt-2 spl-community-name font-14 pointer' onClick={(e) => confirmSwitchCommunity(e, item, false)}>{item.Name}</div>
                          }
                        </div>
                      ))
                    }
                  </div>
                  <div>
                    {
                      commu.items.length > 5 &&
                      <div className='mt-3'>
                        <Button variant="contained" size="small" color="primary" onClick={() => showMore(commu)}>
                          Show More
                        </Button>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      }
      {
        currentStep == 2 &&
        <div>
          <div className='font-24 text-center py-4'>{selCommunity.Name}</div>
          <div className='row mx-3'>
            {
              selCommunityList.length > 0 && selCommunityList.map((item: any, i) => (
                <div className='col-6 col-md-4 col-lg-3 text-center pt-4 spl-community-list font-15 pointer' key={i} onClick={(e) => confirmSwitchCommunity(e, item, true)}>
                  {item.name}
                </div>
              ))
            }
          </div>
          <div className='d-flex justify-content-end px-4 pt-4'>
            <span className='pointer' onClick={navToBack}><u>Back</u></span>
          </div>
        </div>
      }
      {/* <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog> */}
    </div>
  );
}
export default CommunityList;