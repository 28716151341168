import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import ScheduleIcon from '@material-ui/icons/Schedule';
import './FxTime.styles.scss';

export const FxTime = (props: any) => {
    let defaultVal = null;
    let inputValue = "";
    if (props.value) {
        inputValue = props.value;
        defaultVal = new Date(inputValue);
    }
    const [timeVal, setTimeVal] = useState(defaultVal);
    if (props.visibility === false) {
        return <></>;
    }
    const { onValueChange, variant, size = "small" } = props;

    const onChange = (date: any, value: any) => {
        setTimeVal(date);
        //let formattedValue = value ? moment(value).format('hh:mm a') : "";
        const data = { name: props.name, value: value };
        onValueChange(data);
    };
    let className = props.className;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                variant="inline"
                size={size} 
                inputVariant={variant === 'outlined' ? 'outlined' : 'standard'}
                margin="normal"
                label={props.label}
                value={timeVal}
                inputValue={inputValue}
                onChange={onChange}
                autoOk={true}
                disabled={props.disabled}
                className={className}
                fullWidth={props.fullWidth}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                keyboardIcon={<ScheduleIcon />} />
        </MuiPickersUtilsProvider>
    );
};
