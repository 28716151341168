import React, { useEffect, useState } from 'react';
import './SaleOrderDetail.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { TableFooter, Button } from '@material-ui/core';
import { FxCurrency, http, general, formatService, AlertService } from '../../fx-core';
import _ from "lodash";
import Dialog from '@material-ui/core/Dialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const SaleOrderDetail = (props: any) => {
  const initialList = [];
  let defaultOrder = {
    saleOrderIdentifier: null,
    orderDate: null,
    orderTypeId: 0,
    stallName: null,
    orderTotal: 0,
    stallAddress: null,
    consumerName: '',
    consumerPhone: '',
    orderStatusId: 0
  }
  let defaultSaleOrderObj = {
    id: 0,
    orderStatusId: 0,
    orderTypeId: 0
  };
  const [saleOrder, setSaleOrder] = useState(defaultOrder);
  const [saleOrderLines, setSaleOrderLines] = useState(initialList);
  const [saleOrderPayments, setSaleOrderPayments] = useState(initialList);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(defaultSaleOrderObj);
  const [isCancelAction, setIsCancelAction] = useState(false);

  const getSaleOrderDetail = () => {
    let inputData = {
      id: props.saleOrderId
    };

    let apiOptions: any = {
      url: `saleorder/detail/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      getSaleOrderDetailCallBack(res)
    });
  };

  const getSaleOrderDetailCallBack = (res) => {
    let result = res.data;
    let orderLinesData: any = [];
    setSaleOrder(result.saleOrder);
    setSaleOrderPayments(result.saleOrderPayments);
    orderLinesData = result.saleOrderLines;

    for (let item of orderLinesData) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setSaleOrderLines(orderLinesData);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const setDefaultOrderValues = () => {
    setSelectedOrder(defaultSaleOrderObj);
    setMessage(null);
    setConfirmOpen(false);
    if (isCancelAction) {
      setIsCancelAction(false);
    }
  };

  const openConfirmDialogByStatus = (item) => {
    let msg: any = "";
    if (item.orderStatusId === 1) {
      msg = "Do you want to accept the order #" + item.saleOrderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 1) {
      msg = "Do you want to ship the order #" + item.saleOrderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 2) {
      msg = "Is the order #" + item.saleOrderIdentifier + " ready?";
    }
    else if (item.orderStatusId === 4 || item.orderStatusId === 5) {
      msg = "Do you want to deliver the order #" + item.saleOrderIdentifier + "?";
    }
    setSelectedOrder(item);
    setMessage(msg);
    setConfirmOpen(true);
  };

  const handleDialogClose = () => {
    setDefaultOrderValues()
  };

  const onConfirmCallback = (action, comments) => {
    if (selectedOrder.orderStatusId === 1) {
      if (action === 'cancel') {
        cancelOrder(comments);
      } else {
        markAsAccepted();
      }
    }
    else if (selectedOrder.orderStatusId === 2) {
      markAsReadyOrShipped();
    }
    else if (selectedOrder.orderStatusId === 4 || selectedOrder.orderStatusId === 5) {
      markOrderDelivered();
    }
  };

  const markAsAccepted = () => {
    let apiOptions = {
      url: `saleorder/${selectedOrder.id}/markAsAccepted`
    };
    http.put(apiOptions).then(res => {
      markAsAcceptedCallBack(res);
    });
  };

  const markAsAcceptedCallBack = (res) => {
    AlertService.showMsg({ message: "Order is accepted" });
    setDefaultOrderValues();
    getSaleOrderDetail();
  };

  const markAsReadyOrShipped = () => {
    let apiOptions = {
      url: `saleorder/${selectedOrder.id}/MarkAsReadyOrShipped`
    };
    http.put(apiOptions).then(res => {
      markAsReadyOrShippedCallBack(res);
    });
  };

  const markAsReadyOrShippedCallBack = (res) => {
    let successMsg = "";
    if (selectedOrder.orderTypeId === 1) {
      successMsg = "Order shipped";
    }
    else if (selectedOrder.orderTypeId === 2) {
      successMsg = "Order ready";
    }
    AlertService.showMsg({ message: successMsg });
    setDefaultOrderValues();
    getSaleOrderDetail();
  };

  const markOrderDelivered = () => {
    let inputData = {
      id: selectedOrder.id,
      currentDate: formatService.getCurrentDateForServer()
    }
    let apiOptions = {
      url: `saleorder/MarkOrderDelivered`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      markOrderDeliveredCallBack(res);
    });
  };

  const markOrderDeliveredCallBack = (res) => {
    AlertService.showMsg({ message: "Order delivered" });
    setDefaultOrderValues();
    getSaleOrderDetail();
  };

  const openConfirmDialogForCancel = (item) => {
    setSelectedOrder(item);
    setIsCancelAction(true);
    setConfirmOpen(true);
  };

  const cancelOrder = (comments) => {
    let inputData = {
      id: selectedOrder.id,
      cancelledRemarks: comments
    }
    let apiOptions = {
      url: `saleorder/MarkOrderCancelled`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      cancelOrderCallBack(res);
    });
  };

  const cancelOrderCallBack = (res) => {
    setDefaultOrderValues();
    getSaleOrderDetail();
  };

  useEffect(() => {
    getSaleOrderDetail();
  }, []);

  const body = (
    <div className='sale-order-detail'>
      <div className="row col-12 title-font py-1 mx-auto">
        <div className="col-11 pl-0">
          <span>Order Detail</span>
        </div>
        <div className="col-1 text-right pointer my-auto pr-2">
          <i className="fas fa-times-circle font-16" onClick={handleClose}></i>
        </div>
      </div>

      {/* Desktop View */}
      <div className='deskview'>
        <div className='row mr-3 my-3 ml-0'>
          <div className='col-7'>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >Items</TableCell>
                    <TableCell width="4%" >Qty</TableCell>
                    <TableCell width="1%" ></TableCell>
                    <TableCell width="23%" align="right" >Price</TableCell>
                    <TableCell width="15%" align="right" >Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleOrderLines.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell className="font-12">
                        {row.itemName}
                      </TableCell>
                      <TableCell>
                        {row.itemQuantity}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          {row.itemQuantity} * <FxCurrency value={row.price} />
                        </span>
                      </TableCell>
                      <TableCell align="right" style={{ width: 220 }}>
                        <span className="table-font">
                          <FxCurrency value={row.lineTotalPrice} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <span className="table-font">Total</span>
                    </TableCell>
                    <TableCell align="right"><span className="table-font">
                      <FxCurrency value={saleOrder.orderTotal} />
                    </span></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <div className='col-5 summary-detail font-14'>
            <div className='row'>
              <div className='col-4'>Order #</div>
              <div className='col-8'>: {saleOrder.saleOrderIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Ordered Date</div>
              <div className='col-8'>: {general.getFormattedDate(saleOrder.orderDate)}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Consumer</div>
              <div className='col-8'>:
                <span className='pl-1'>{saleOrder.consumerName}</span>
                <span className='pl-3'>{saleOrder.consumerPhone}</span>
              </div>
            </div>
            {saleOrderPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Order total</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Coupon Value</span>
                    }
                  </div>
                  <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>Payment Mode</div>
                  <div className='col-8'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'stripe' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span className="pr-1 payment-style">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Payment Status</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Discount Status</span>
                    }
                  </div>
                  <div className='col-8'>
                    : <span>{item.paymentStatus}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='col-12 text-right pt-2'>
            {
              saleOrder.orderStatusId === 1 &&
              <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(saleOrder)}>Accept</Button>
            }
            {
              (saleOrder.orderStatusId === 2 && saleOrder.orderTypeId === 1) &&
              <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(saleOrder)}>Ship</Button>
            }
            {
              (saleOrder.orderStatusId === 2 && saleOrder.orderTypeId === 2) &&
              <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(saleOrder)}>Ready</Button>
            }
            {
              (saleOrder.orderStatusId === 4 || saleOrder.orderStatusId === 5) &&
              <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(saleOrder)}>Deliver</Button>
            }
            {
              saleOrder.orderStatusId === 1 &&
              <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialogForCancel(saleOrder)}>Cancel</Button>
            }
          </div>
        </div>
      </div>

      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={isCancelAction ? "cancel" : ""} />
      </Dialog>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default SaleOrderDetail;