import React, { useEffect, useState } from 'react';
import './SpecialEventEnrol.scss';
import { FxSelect, FxDate, FxTime, FxInput, http, localstorage, formatService, formValidator } from '../../fx-core';
import { Button } from '@material-ui/core';

const SpecialEventEnrol = (props) => {

    const defaultItem = {
        serviceId: -1,
        communityId: -1,
        stallId: -1,
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: ""
    };
    let defaultAnswerMap = {};
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});
    const [stalls, setStalls] = useState([]);
    const [services, setServices] = useState([]);
    const [splCommunities, setSplCommunities] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [queOptions, setQueOptions] = useState([]);
    const [answerMap, setAnswerMap] = useState(defaultAnswerMap);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        item.validations = getInputValidations(item.name);
        const validationResult = formValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
        if (item.name == "communityId") {
            getCommunityQuestionOptions(item.value);
        }
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                ProviderStalls: { default: true },
                Services: { default: true },
                SpecialCommunities: { default: true }
            },
            filters: {
                userId: localstorage.getItem('userId'),
                communityId: localstorage.getItem("communityId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            initLookupCallBack(response);
        })
    }

    const initLookupCallBack = (res) => {
        let result = res.data;
        setStalls(result.ProviderStalls);
        setServices(result.Services);
        setSplCommunities(result.SpecialCommunities);
        if (props.id > 0) {
            getStallCommunityMapByServiceReqId();
        }
    };

    const getValidationMap = () => {
        let validationMap = {
            stallId: ["select"],
            communityId: ["select"],
            serviceId: ["select"],
            startDate: ["required"],
            startTime: ["required"],
            endDate: ["required"],
            endTime: ["required"]
        }
        return validationMap;
    };

    const getInputValidations = (name) => {
        let validationMap = getValidationMap();
        return validationMap[name];
    }

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidationMap();
        for (var key in validationMap) {
            let result = { name: key, value: item[key], validations: validationMap[key] }
            items.push(result);
        }
        let validationResult = formValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const submitServiceRequest = () => {
        const isValid = bulkValidate();
        if (isValid) {
            const queAnswers = prepareAnswers();
            let startDate = formatService.getDateStringForServer(item.startDate);
            let startTime = formatService.getTimeString(item.startTime);
            let endDate = formatService.getDateStringForServer(item.endDate);
            let endTime = formatService.getTimeString(item.endTime);
            const serviceReq = {
                serviceId: item.serviceId,
                communityId: item.communityId,
                stallId: item.stallId,
                currentDate: formatService.getCurrentDateForServer(),
                startDate: (startDate + ' ' + startTime),
                startTime: startTime,
                endDate: (endDate + ' ' + endTime),
                endTime: endTime
            };
            let inputData = {
                serviceRequest: serviceReq,
                serviceRequestQuestionAnswers: queAnswers
            };
            let apiOptions: any = {
                url: `servicerequest/special/comunity/save`,
                data: inputData
            };
            http.post(apiOptions).then(res => {
                submitServiceRequestCallback(res);
            });
        }
    }

    const submitServiceRequestCallback = (res) => {
        handleClose();
    }

    const prepareAnswers = () => {
        let answers: any = [];
        for (let key in answerMap) {
            let que: any = questions.find((q: any) => q.id == parseInt(key))
            if (que.questionTypeId == 3) { //3-Select
                let opts: any = queOptions.find((o: any) => o.questionId == parseInt(key) && o.id == answerMap[key]);
                let item = { questionId: parseInt(key), answerOptionId: answerMap[key], answerText: opts.optionText };
                answers.push(item);
            }
            else {
                let item = { questionId: parseInt(key), answerText: answerMap[key] };
                answers.push(item);
            }
        }
        return answers;
    }

    const handleClose = () => {
        props.handleClose();
    }

    const getStallCommunityMapByServiceReqId = () => {
        let apiOptions: any = {
            url: `stallcommunitymap/servicerequest/${props.id}`
        };
        http.get(apiOptions).then(res => {
            getStallCommunityMapByServiceReqIdCallBack(res);
        });
    };

    const getStallCommunityMapByServiceReqIdCallBack = (res) => {
        let result = res.data;
        result.startTime = result.startTime != null ? formatService.getConvertedTimeForDisplay(result.startTime) : null;
        result.endTime = result.endTime != null ? formatService.getConvertedTimeForDisplay(result.endTime) : null;
        setItem(result);
        getCommunityQuestionOptions(result.communityId);
        getServiceReqQueAns(result.serviceRequestId);
    };

    const getCommunityQuestionOptions = (communityId) => {
        if (communityId > -1) {
            let apiOptions: any = {
                url: `question/community/${communityId}`
            };
            http.get(apiOptions).then(res => {
                getCommunityQuestionOptionsCallBack(res);
            });
        }
        else {
            setQuestions([]);
            setQueOptions([]);
        }
    }

    const getCommunityQuestionOptionsCallBack = (res) => {
        let result: any = res.data;
        let ansMap: any = {};
        for (let question of result.questions) {
            ansMap[question.id.toString()] = "";
            question.identifier = question.id.toString();
        }
        for (let qo of result.questionOptions) {
            qo.code = qo.id.toString();
        }
        setAnswerMap(ansMap);
        setQuestions(result.questions);
        setQueOptions(result.questionOptions);
    }

    const getServiceReqQueAns = (id) => {
        let inputData = {
            id: id
        };
        let apiOptions: any = {
            url: `servicerequest/question/answers`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getServiceReqQueAnsCallBack(res);
        });
    };

    const getServiceReqQueAnsCallBack = (res) => {
        let result = res.data;
        let ansMap: any = {};
        for (let r of result) {
            if (r.answerOptionId > 0) {
                ansMap[r.questionId.toString()] = r.answerOptionId.toString();
            }
            else {
                ansMap[r.questionId.toString()] = r.answerText;
            }
        }
        setAnswerMap(ansMap);
    };

    //methods to change answerMap
    const handleAnswerInputChange = (item) => {
        handleAnswerItemChange({ [item.name]: item.value });
    };

    const handleAnswerItemChange = (data) => {
        setAnswerMap(prevState => {
            return { ...prevState, ...data }
        });
    };

    useEffect(() => {
        initLookup();
    }, [])

    return (
        <div className='spleventenrol-ui'>
            <div className='row title-font py-1 px-2 drawer-header'>
                <span className="col-11">
                    Special Event Enroll
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>
            <div className='mb-4 pb-5'>
            <div className='col-12 p-3 mt-5'>
                <FxSelect name="stallId" variant="outlined" label="Stall"
                    options={stalls}
                    selectedValue={item.stallId}
                    valueField="id"
                    displayField="text"
                    size="small"
                    onValueChange={handleInputChange}
                />
                {
                    hasError("stallId", "select") &&
                    <div className="error">Stall is required</div>
                }
            </div>
            <div className='col-12 p-3'>
                <FxSelect name="communityId" variant="outlined" label="Community"
                    options={splCommunities}
                    selectedValue={item.communityId}
                    valueField="id"
                    displayField="text"
                    size="small"
                    onValueChange={handleInputChange}
                />
                {
                    hasError("communityId", "select") &&
                    <div className="error">Community is required</div>
                }
            </div>
            <div className='col-12 p-3'>
                <FxSelect name="serviceId" variant="outlined" label="Service"
                    options={services}
                    selectedValue={item.serviceId}
                    valueField="id"
                    displayField="text"
                    size="small"
                    onValueChange={handleInputChange}
                />
                {
                    hasError("serviceId", "select") &&
                    <div className="error">Service is required</div>
                }
            </div>
            <div className='row col-12 p-3'>
                <div className='col-6'>
                    <FxDate
                        name="startDate"
                        variant="standard"
                        size="small"
                        label="Start Date"
                        value={item.startDate}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("startDate", "required") &&
                        <div className="error">Start Date is required</div>
                    }
                </div>
                <div className='col-6'>
                    <FxTime name="startTime" variant="outlined" label="Start Time"
                        value={item.startTime} onValueChange={handleInputChange} />
                    {
                        hasError("startTime", "required") &&
                        <div className="error">Start Time is required</div>
                    }
                </div>
            </div>
            <div className='row col-12 p-3'>
                <div className='col-6'>
                    <FxDate
                        name="endDate"
                        variant="standard"
                        size="small"
                        label="End Date"
                        value={item.endDate}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("endDate", "required") &&
                        <div className="error">End Date is required</div>
                    }
                </div>
                <div className='col-6'>
                    <FxTime name="endTime" variant="outlined" label="End Time"
                        value={item.endTime} onValueChange={handleInputChange} />
                    {
                        hasError("endTime", "required") &&
                        <div className="error">End Time is required</div>
                    }
                </div>
            </div>
            {
                questions.length > 0 && questions.map((que: any, i) => (
                    <div key={i}>
                        {
                            que.questionTypeId == 1 &&
                            <div className='col-12 p-3'>
                                <FxInput
                                    name={que.identifier}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    label={que.description}
                                    value={answerMap[que.identifier]}
                                    onValueChange={handleAnswerInputChange}
                                />
                            </div>
                        }
                        {
                            que.questionTypeId == 2 &&
                            <div className='col-12 p-3'>
                                <FxDate
                                    name={que.identifier}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    label={que.description}
                                    value={answerMap[que.identifier]}
                                    onValueChange={handleAnswerInputChange}
                                />
                            </div>
                        }
                        {
                            que.questionTypeId == 3 &&
                            <div className='col-12 p-3'>
                                <FxSelect
                                    name={que.identifier}
                                    label={que.description}
                                    options={queOptions.filter((qo: any) => qo.questionId == que.id)}
                                    selectedValue={answerMap[que.identifier]}
                                    variant="outlined"
                                    valueField="code"
                                    displayField="optionText"
                                    size="small"
                                    onValueChange={handleAnswerInputChange}
                                />
                            </div>
                        }
                    </div>
                ))
            }
            </div>
            {
                props.id == 0 &&
                <div className='px-4 py-2 drawer-footer'>
                    <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={submitServiceRequest}><b>Submit</b></Button>
                </div>
            }
        </div>
    );
}

export default SpecialEventEnrol;