import React, { useState } from 'react';
import './PaymentsContainer.scss';
import { useNavigationService } from '../../fx-core';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import CommunitySubscriptions from '../CommunitySubscriptions/CommunitySubscriptions';
import CommunityAccountBalance from '../CommunityAccountBalance/CommunityAccountBalance';
import CommunityPosts from '../CommunityPosts/CommunityPosts';
import CommunityLiveShopping from '../CommunityLiveShopping/CommunityLiveShopping';
import CommunityCoupons from '../CommunityCoupons/CommunityCoupons';

const PaymentsContainer = (props) => {
  const { routeData, back } = useNavigationService();

  const headerList = [
    { id: 'isSubscription', display: 'Subscription' },
    { id: 'isAccounts', display: 'Account Balance' },
    { id: 'isPosts', display: 'Posts' },
    { id: 'isLiveShopping', display: 'Live Shopping' },
    { id: 'isCoupons', display: 'Coupons' }
  ];

  const [value, setValue] = useState('isSubscription');

  const selectedHeader = (item) => {
    setValue(item.id);
  }

  const navToBack = () => {
    back();
  };

  const body = (
    <div className="paymentscontainer-ui pt-5">
      <div className='row title-font custom-head pl-4 pt-5'>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
            <b>{routeData.communityName}</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Payments</Typography>
        </Breadcrumbs>
      </div>
      <div className="content-container">
        <div className="content">
          <div className="row col-12 mt-2 justify-content-center">
            {
              headerList.length > 0 && headerList.map((item, i) => (
                <div key={i} className={item.id == value ? 'menubtn px-2 pointer' : 'menubtn-selected px-2 pointer'}
                  onClick={() => selectedHeader(item)}>
                  {item.display}
                </div>
              ))
            }
          </div>
          <div className='mt-1'>
            {
              value == 'isSubscription' &&
              <CommunitySubscriptions communityId={routeData.communityId} parent={routeData.parent} />
            }
            {
              value == 'isAccounts' &&
              <CommunityAccountBalance communityId={routeData.communityId} parent={routeData.parent} />
            }
            {
              value == 'isPosts' &&
              <CommunityPosts communityId={routeData.communityId} parent={routeData.parent} />
            }
            {
              value == 'isLiveShopping' &&
              <CommunityLiveShopping communityId={routeData.communityId} parent={routeData.parent} />
            }
            {
              value == 'isCoupons' &&
              <CommunityCoupons communityId={routeData.communityId} parent={routeData.parent} />
            }
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default PaymentsContainer;