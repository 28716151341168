import React, { useEffect, useState } from 'react';
import './ShareOptions.scss';
import { http } from '../../fx-core';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareOptions = (props: any) => {

  const [shareText, setShareText] = useState(null);
  const [emailSubject, setEmailSubject] = useState(props.id > 0 ? "Localites - Share Business" : "Localites - Share");

  const getShareTextByLocalBusinessId = () => {
    let apiOptions: any = {
      url: `localbusinessdirectory/${props.id}/sharetext`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      setShareText(result.shareText);
    });
  };

  const getReferralText = () => {
    let apiOptions: any = {
      url: `user/sharereferraltext/${props.userId}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      setShareText(result.referralText);
    })
  }

  const getShareTextByLiveStreamId = () => {
    let apiOptions: any = {
      url: `livestream/${props.liveStreamId}/sharetext`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      setShareText(result.shareText);
    });
  };

  const getShareTextByCouponId = () => {
    let apiOptions: any = {
      url: `coupon/${props.couponId}/sharetext`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      setShareText(result.shareText);
    });
  };

  const handleClose = () => {
    props.closeShareDialog()
  };

  useEffect(() => {
    if (props.id > 0) {
      getShareTextByLocalBusinessId();
    }
    if (props.userId > 0) {
      getReferralText();
    }
    if (props.liveStreamId > 0) {
      getShareTextByLiveStreamId();
    }
    if (props.couponId > 0) {
      getShareTextByCouponId();
    }
  }, []);


  const body = (
    <div className="content-container1 share-options">
      <div className="row justify-content-between title-font px-3">
        <div className="py-1 pl-3">
          {
            props.id > 0 &&
            <span>Share Local Business Post</span>
          }
          {
            props.userId > 0 &&
            <span>Share Friends</span>
          }
          {
            props.liveStreamId > 0 &&
            <span>Share Livestream Event</span>
          }
        </div>
        <div className="text-right pointer my-auto">
          <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
        </div>
      </div>

      <div className="m-2 py-4 px-4 mt-2">
        <div className="row justify-content-between">
          <span>
            <TwitterShareButton url={shareText}>
              <TwitterIcon size={32} round />
              <div className='px-3'>Twitter</div>
            </TwitterShareButton>
          </span>
          <span>
            <FacebookShareButton url={"https://app.localites.in/"} quote={shareText}>
              <FacebookIcon size={32} round />
              <div className='px-3'>Facebook</div>
            </FacebookShareButton>
          </span>
          <span>
            <WhatsappShareButton url={shareText}>
              <WhatsappIcon size={32} round />
              <div className='px-3'>Whatsapp</div>
            </WhatsappShareButton>
          </span>
          <span>
            <EmailShareButton url={shareText} subject={emailSubject} >
              <EmailIcon size={32} round />
              <div className='px-3'>Email</div>
            </EmailShareButton>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ShareOptions;