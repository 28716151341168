import React, { useEffect, useState } from 'react';
import './ManageBookings.scss';
import { http, localstorage, general, FxSelect, FxCurrency, AlertService } from '../../fx-core';
import { Fab, Button, Paper, Table, TableBody, makeStyles, TableCell, TableContainer, TableHead, TableRow, Drawer, Dialog } from '@material-ui/core';
import { NoRecords } from '../../NoRecords/NoRecords';
import BookingForm from '../BookingForm/BookingForm';

const styles = makeStyles((theme) => ({
    drawerWidth: {
        [theme.breakpoints.down(599)]: {
            width: '90%'
        },
        [theme.breakpoints.up(600)]: {
            width: '70%'
        },
        [theme.breakpoints.up(1025)]: {
            width: '40%'
        }
    }
}));

const ManageBookings = (props) => {
    const classes = styles();
    const defaultFilters = {
        stallId: -1
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [stalls, setStalls] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [bookingFormOpen, setBookingFormOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            stallId: filters.stallId
        }
        if (item.name == 'stallId') {
            filterObj.stallId = item.value;
        }
        getStallBookings(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                ProviderStalls: { default: false }
            },
            filters: {
                userId: localstorage.getItem('userId'),
                communityId: localstorage.getItem("communityId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            initLookupCallBack(response);
        });
    };

    const initLookupCallBack = (res) => {
        let result = res.data;
        let stalls = result.ProviderStalls;
        if (stalls.length > 0) {
            handleInputChange({ name: 'stallId', value: stalls[0].id });
        }
        setStalls(stalls);
    };

    const getStallBookings = (data) => {
        let inputData = {
            filters: {
                stallId: data.stallId
            }
        };
        let apiOptions: any = {
            url: `stallbooking/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getStallBookingsCallBack(res);
        })
    };

    const getStallBookingsCallBack = (res) => {
        const result = res.data;
        for (let item of result) {
            item.imageUrl = general.getImageUrl(item.imagePath);
        }
        setBookings(result);
    };

    const createOrEditBooking = (id) => {
        setBookingFormOpen(true);
        setSelectedId(id);
    };

    const handleBookingFormClose = () => {
        setBookingFormOpen(false);
        setSelectedId(0);
        getStallBookings(filters);
    };

    const updateIsActive = (row) => {
        let apiOptions: any = {
            url: `stallbooking/updateisactive/${row.id}`,
            data: {}
        };
        http.put(apiOptions).then(res => {
            updateIsActiveCallBack(res);
        });
    };

    const updateIsActiveCallBack = (res) => {
        AlertService.showSuccessMsg();
        getStallBookings(filters);
    };

    useEffect(() => {
        initLookup();
    }, []);

    return (
        <div className='managebookings-ui pt-5'>
            <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
                <span className="col-8 pt-3 py-1 pl-4">
                    <span>Bookings</span>
                </span>
            </div>

            <div className="row col-12 my-3 ml-1 mr-5 justify-content-between">
                <div className="col-3">
                    <FxSelect name="stallId" variant="outlined" label="Stalls"
                        options={stalls}
                        selectedValue={filters.stallId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>
                <div className="col-3 pt-1 text-right">
                    <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        onClick={() => createOrEditBooking(0)}>
                        <i className="fas fa-plus pr-2"></i>
                        Add
                    </Fab>
                </div>
            </div>
            <div className='p-2 mx-3'>
                <div className='mb-4'>
                    <TableContainer component={Paper}>
                        <Table size="small" className='table-striped'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Stall</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Duration(Mins)</TableCell>
                                    <TableCell>Price Per Seat</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookings.length == 0 &&
                                    <TableCell colSpan={6}>
                                        <NoRecords />
                                    </TableCell>
                                }
                                {bookings.length > 0 && bookings.map((row: any, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{row.stallName}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.durationInMins}</TableCell>
                                        <TableCell>
                                            <FxCurrency value={row.pricePerSeat} />
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='outlined' size='small' className='button-style mr-2'
                                                onClick={() => createOrEditBooking(row.id)}>Edit
                                            </Button>
                                            <span className="ml-1">
                                                <Button variant="outlined" size="small" className='button-style mr-2'
                                                    onClick={() => updateIsActive(row)}> {row.isActive ? "InActive" : "Active"}
                                                </Button>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={bookingFormOpen} onClose={handleBookingFormClose}>
                <BookingForm id={selectedId} stallId={filters.stallId} handleClose={handleBookingFormClose} />
            </Drawer>
        </div>
    );
};

export default ManageBookings;