import React from 'react';
import moment from 'moment';

export const FxDateDisplay = (props:any) => {

    const getDateDisplay = () => {
        let getDate = props.value;
        if(getDate) {
            const dateDisplay = moment(getDate).format('DD/MM/YYYY');
            return dateDisplay;
        }
    }

    return(
        <span>
          {getDateDisplay()}
        </span>       
    );
};