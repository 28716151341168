import React, { useEffect, useState } from 'react';
import './DeliveryLocations.scss';
import { FxSelect, http, AlertService, FxInput } from '../../fx-core';
import { Dialog, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Fab } from '@material-ui/core';
import _ from "lodash";
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const DeliveryLocations = (props) => {

  const [locations, setLocations] = useState([]);
  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [message, setMessage] = useState(null);
  const [stallDeliveryLocationId, setStallDeliveryLocationId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [error, setError] = useState(null);
  const [selIndex, setSelIndex] = useState(-1);
  const [locationError, setLocationError] = useState(null);

  const handleSelectedItem = (item) => {
    let allLocations = deliveryLocations.map(d => {
      if (d.index == item.index) {
        d.deliveryCharge = item.deliveryCharge;
        d.locationId = item.locationId;
        d.locationName = item.locationName;
        d.showLocationError = item.showLocationError;
        d.minimumOrderAmount = item.minimumOrderAmount;
      }
      return d;
    });
    setDeliveryLocations(allLocations);
  };

  const handleInputChange = (data, item, index) => {
    item.index = index;
    if (data.name == 'deliveryCharge') {
      item.deliveryCharge = data.value;
    }
    else if (data.name == 'locationId') {
      let locationExists = deliveryLocations.filter(i => i.locationId == data.value && i.index != index);
      if (locationExists.length > 0) {
        item.showLocationError = true;
        setLocationError("Location exists for this stall. Please select another one");
      }
      else {
        item.showLocationError = false;
        setLocationError("");
      }
      item.locationId = data.value;
      let locationObj = _.find(locations, { id: item.locationId });
      item.locationName = locationObj.text;
    }
    else if (data.name == 'minimumOrderAmount') {
      item.minimumOrderAmount = data.value;
    }
    handleSelectedItem(item);
  }

  const initLookup = () => {
    let inputData = {
      lookups: {
        Locations: { default: true }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setLocations(response.data.Locations);
      getDeliveryLocations();
    })
  }

  const getDeliveryLocations = () => {
    let inputData = {
      stallId: props.stallId
    }
    let apiOptions: any = {
      url: `stalldeliverylocation/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result.length > 0) {
        setDeliveryLocations(result);
      }
      else if (deliveryLocations.length == 0) {
        addNewDeliveryLocation();
      }
    })
  };

  const addNewDeliveryLocation = () => {
    let newItem = [{ id: 0, locationId: -1, deliveryCharge: null, minimumOrderAmount: null, stallId: props.stallId, locationName: "" }];
    setDeliveryLocations([...deliveryLocations, ...newItem]);
  }

  const saveDeliveryLocations = () => {
    let hasError = false;
    let isDuplicate = false;
    let allLocations = deliveryLocations.map(item => {
      item.deliveryCharge = item.deliveryCharge == null ? "" : item.deliveryCharge + '';
      if (item.locationId == -1 || !item.deliveryCharge) {
        item.showError = true;
        hasError = true;
        setError("This field is required");
      }
      if (item.showLocationError) {
        isDuplicate = true;
      }
      return item;
    });
    setDeliveryLocations(allLocations);
    if (hasError || isDuplicate) {
      return;
    }
    let inputData = {
      stallDeliveryLocations: deliveryLocations
    }
    let apiOptions: any = {
      url: `stalldeliverylocation/save`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      saveDeliveryLocationsCallback();
    })
  }

  const saveDeliveryLocationsCallback = () => {
    AlertService.showSuccessMsg();
    props.handleDeliveryLocationsClose();
  }

  const confirmDelete = (item, index) => {
    setStallDeliveryLocationId(item.id);
    setSelIndex(index);
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + item.locationName + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if (stallDeliveryLocationId > 0) {
      deleteDeliveryLocation();
    }
    else {
      deleteNewItem();
    }
  }

  const deleteNewItem = () => {
    const filteredLocations = deliveryLocations.filter((d, i) => i != selIndex);
    setDeliveryLocations(filteredLocations);
    setSelIndex(-1);
  };

  const deleteDeliveryLocation = () => {
    let inputData = {
      id: stallDeliveryLocationId,
    };
    let apiOptions: any = {
      url: `stalldeliverylocation/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getDeliveryLocations();
    })
  };

  const handleClose = () => {
    props.handleDeliveryLocationsClose();
  }


  useEffect(() => {
    initLookup();
  }, []);

  return (
    <div className='deliverylocations-ui'>
      <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
        <span className="col-11">
          <span>Delivery Location</span>
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>

      <div className='drawer-combo'>
        <span className='d-flex justify-content-end pr-3 py-3'>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={addNewDeliveryLocation}
          >
            <i className="fas fa-plus pr-2"></i>
            Add
          </Fab>
        </span>
      </div>
      <div className='mx-3 my-3 py-5'>
        <div className='my-4 pt-3 delivery-location-scroll'>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell width="60%">Location</TableCell>
                  <TableCell width="20%">Delivery Charge ($)</TableCell>
                  <TableCell width="15%">Min Order Amount ($)</TableCell>
                  <TableCell className='float-right'>Action</TableCell>
                </TableRow>
              </TableHead>
              {deliveryLocations.map((row, i) => (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FxSelect name="locationId" variant="outlined" options={locations}
                        selectedValue={row.locationId} valueField="id"
                        displayField="text" size="small" fullWidth onValueChange={(e) => handleInputChange(e, row, i)} />
                      {row.showError && row.locationId < 0 &&
                        <div className='error'>{error}</div>
                      }
                      {row.showLocationError &&
                        <div className='error'>{locationError}</div>
                      }
                    </TableCell>
                    <TableCell>
                      <FxInput type="number" name="deliveryCharge" variant="outlined" fullWidth size="small"
                        value={row.deliveryCharge} onValueChange={(e) => handleInputChange(e, row, i)} />
                      {row.showError && !row.deliveryCharge &&
                        <div className='error'>{error}</div>
                      }
                    </TableCell>
                    <TableCell>
                      <FxInput type="number" name="minimumOrderAmount" variant="outlined" fullWidth size="small"
                        value={row.minimumOrderAmount} onValueChange={(e) => handleInputChange(e, row, i)} />
                    </TableCell>
                    <TableCell>
                      <span className='float-right'>
                        <i className="fas fa-trash-alt font-13 pointer" onClick={() => confirmDelete(row, i)}></i>
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className='px-3 py-2 drawer-footer'>
        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveDeliveryLocations}><b>Save</b></Button>
      </div>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default DeliveryLocations;