import React, { useEffect, useState } from 'react';
import './StallHome.scss';
import StallFilters from './StallFilters/StallFilters';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { FxInput, formatService, http, general, useNavigationService, localstorage } from './../fx-core';
import Drawer from '@material-ui/core/Drawer';
import StallFiltersMobile from './StallFiltersMobile/StallFiltersMobile';
import filtericon from '../../assets/filtericon.svg';
import StallCard from './StallCard/StallCard';
import nostalls from '../../assets/nostalls.svg';

const useStyles = makeStyles({
    drawerPaper: {
        height: '60%',
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
    },
});

const StallHome = (props: any) => {
    const classes = useStyles();
    const { getRouteData, navigate, back, pageState } = useNavigationService();
    console.log('pageState', pageState);

    let defaultItem: any = {
        searchText: "",
        categoryIds: [],
        isDeliveryEnabled: false,
        isPickupEnabled: false
    };

    if (pageState && pageState.item) {
        defaultItem = { ...defaultItem, ...pageState.item };
    }

    const [item, setItem] = useState(defaultItem);
    const routeData = getRouteData();
    const [serviceId, setServiceId] = useState(0);
    const [stallDetails, setStallDetails] = useState([]);
    const [stallCount, setStallCount] = useState(0);
    const [serviceName, setServiceName] = useState("");

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        getStallsDetails({ "searchText": item.value }, serviceId);
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const navToStall = (slug: any) => {
        let navData = {
            targetRoute: '/stalls/' + slug,
            targetTitle: "Stall",
            targetData: {},
            sourceState: { item },
            eventSource: '',
        };
        navigate(navData);
    }
    const navToCustomerDashboard = (item: any) => {
        back();
    }

    const callbackData = (data: any) => {
        handleItemChange({ ["categoryIds"]: data.categoryIds });
        handleItemChange({ ["isDeliveryEnabled"]: data.isDeliveryEnabled });
        handleItemChange({ ["isPickupEnabled"]: data.isPickupEnabled });
        getStallsDetails(data, serviceId);
    }
    const getServiceBySlug = () => {
        const slug = props.match.params.serviceSlug;
        let apiOptions: any = {
            url: `portal/service/${slug}`
        };
        http.get(apiOptions).then(res => {
            getServiceBySlugCallBack(res);
        });
    }

    const getServiceBySlugCallBack = (res) => {
        let result = res.data;
        if(result) {
            const id = result.id;
            setServiceName(result.serviceName);
            setServiceId(id);
            getStallsDetails(defaultItem, id);
        }
        else {
            getStallsDetails(defaultItem, serviceId);
        }
    }

    const getStallsDetails = (data: any, serviceId) => {
        let inputData = {
            serviceId: serviceId,
            currentDate: formatService.getCurrentDateTime(),
            categoryIds: data.categoryIds ? data.categoryIds.join(",") : "",
            isDeliveryEnabled: data.isDeliveryEnabled,
            isPickupEnabled: data.isPickupEnabled,
            stallName: data.searchText ? data.searchText : "",
            communityId: localstorage.getItem("communityId")
        };
        let apiOptions: any = {
            url: `portal/service/stalls`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.items;
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
            }
            setStallDetails(result);
            setStallCount(result.length);
        });
    }

    const [openfilter, setOpenfilter] = useState(false);
    const toggleDrawerfilter = () => {
        setOpenfilter(!openfilter);
    };
    useEffect(() => {
        getServiceBySlug();
    }, []);

    return (
        <div className="pt-5 stallhome-ui">
            <div className="title-font custom-head pt-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
                        <b>Dashboard</b>
                    </span>
                    <Typography color="textPrimary" className="breadcrumbs-style">{serviceName}</Typography>
                </Breadcrumbs>
            </div>

            {/* Desktop View */}
            <div className="row pt-2 deskview">
                <div className="col-2 pt-4" style={{ background: "#D7D7D7" }}>
                    <StallFilters defaultItem={defaultItem} serviceId={serviceId} callbackData={callbackData} />
                </div>
                <div className="col-10 px-3 pl-5 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Stalls"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>

                    <div className="row">
                        {
                            stallDetails.length > 0 && stallDetails.map((item, i) => (
                                <div className="col-3">
                                    <StallCard item={item} navToStall={navToStall} />
                                </div>
                            ))
                        }
                        {
                            stallDetails.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No stalls available.</b></div>
                            </div>
                        }
                    </div>

                </div>
            </div>

             {/* Tablet View */}
             <div className="pt-3 tabview">
                <div className="row pr-4 pl-3 pt-5">
                    <div className="pr-0 col-1 my-auto pt-2">
                        <img src={filtericon} alt="filtericon" onClick={toggleDrawerfilter}></img>
                    </div>
                    <div className="col-11 pt-4 pb-2 pr-0 pl-4">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Stalls"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>
                </div>
                <div className="px-2 pb-5 row">
                    {
                        stallDetails.length > 0 && stallDetails.map((item, i) => (
                            <div className="col-4">
                                <StallCard item={item} navToStall={navToStall} />
                            </div>
                        ))
                    }
                    {
                        stallDetails.length < 1 &&
                        <div className="py-4 text-center">
                            <img src={nostalls} alt="no stalls"></img>
                            <div className="pt-4 font-17"><b> Sorry, No stalls available.</b></div>
                        </div>
                    }
                </div>
            </div>

            {/* Mobile View */}
            <div className="pt-3 mobileview">
                <div className="row pr-4 pl-3 pt-5">
                    <div className="pr-0 col-1 my-auto pt-2">
                        <img src={filtericon} alt="filtericon" onClick={toggleDrawerfilter}></img>
                    </div>
                    <div className="col-11 pt-4 pb-2 pr-0 pl-4">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Stalls"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>
                </div>
                <div className="px-2 pb-5">
                    {
                        stallDetails.length > 0 && stallDetails.map((item, i) => (
                            <div>
                                <StallCard item={item} navToStall={navToStall} />
                            </div>
                        ))
                    }
                    {
                        stallDetails.length < 1 &&
                        <div className="py-4 text-center">
                            <img src={nostalls} alt="no stalls"></img>
                            <div className="pt-4 font-17"><b> Sorry, No stalls available.</b></div>
                        </div>
                    }
                </div>
            </div>
            <Drawer anchor="bottom" open={openfilter} onClose={toggleDrawerfilter} classes={{ paper: classes.drawerPaper, }}>
                <StallFiltersMobile defaultItem={defaultItem} serviceId={serviceId} callbackData={callbackData} />
            </Drawer>
        </div>

    );
}
export default StallHome;