import React, { useEffect, useState } from 'react';
import './ServiceRequestConfirmDialog.scss';
import { Button } from '@material-ui/core';
import { FxInput, formValidator } from '../../fx-core';

const ServiceRequestConfirmDialog = (props: any) => {

  let defaultItem = {
    amount: "",
    comments: ""
  };
  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  };

  const handleItemChange = (item) => {
    setItem(prevState => {
      return { ...prevState, ...item }
    });
  }

  const handleClose = () => {
    props.handleDialogClose();
  }

  const getValidationMap = () => {
    let validationMap = {
      amount: ["required"],
      comments: ["required"]
    }
    return validationMap;
  };

  const getInputValidations = (name) => {
    let validationMap = getValidationMap();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidationMap();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };

  const confirmDialog = () => {
    const isValid = bulkValidate();
    if (isValid) {
      props.onConfirmCallback(item);
    }
  }


  useEffect(() => {
  }, []);

  const body = (
    <div className="confirmContainer confirm-dialog">
      <div className="row justify-content-between title-font px-3">
        <div className="py-1 pl-3">
          <span>Confirm</span>
        </div>
        <div className="text-right pointer my-auto">
          <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
        </div>
      </div>
      <div className='m-2 pl-3'>
        {props.message}
      </div>
      <div className="row m-2">
        <div className="col-12 font-14">
          <FxInput name="amount" variant="outlined" label="Amount" size="small" type="number"
            value={item.amount} onValueChange={handleInputChange}
          />
        </div>
        {
          hasError("amount", "required") &&
          <div className="error">Amount is required</div>
        }
      </div>
      <div className="row m-2">
        <div className="col-12 font-14">
          <FxInput name="comments" variant="outlined" label="Comments" size="small"
            value={item.comments} onValueChange={handleInputChange}
          />
        </div>
        {
          hasError("comments", "required") &&
          <div className="error">Comments is required</div>
        }
      </div>

      <div className="row justify-content-end pb-2 pr-4">
        <div>
          <span className="mr-2">
            <Button variant="contained" size="small" onClick={handleClose}>
              No
            </Button>
          </span>
          <span>
            <Button variant="contained" size="small" color="primary" onClick={confirmDialog}>
              Yes
            </Button>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ServiceRequestConfirmDialog;