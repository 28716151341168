import React, { useState, useEffect } from 'react';
import './MyReviewForm.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { http, AlertService, FxTextarea } from '../../fx-core';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';

export const MyReviewForm = (props) => {
    const defaultItem = {
        reviewComment: "",
        rating: 0,
        id: props.reviewId ? props.reviewId : 0
    };

    const [item, setItem] = useState(defaultItem);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
    }

    const handleRatingChange = (value) => {
        handleItemChange({ "rating": value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const saveReview = () => {
        if (!item.reviewComment) {
            AlertService.showErrorMsg("Comments is required");
            return;
        }
        if (item.rating == 0) {
            AlertService.showErrorMsg("Rating is required");
            return;
        }
        if (item.id) {
            updateReview();
        }
        else {
            addReview();
        }
    };

    const addReview = () => {
        let inputData = {
            entityTypeId: 1, //1-Platform
            reviewComment: item.reviewComment,
            rating: item.rating,
            id: 0
        }
        let apiOptions: any = {
            url: `myreview/add`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveReviewCallBack(res);
        });
    }

    const updateReview = () => {
        let apiOptions: any = {
            url: `myreview/update`,
            data: item
        };
        http.put(apiOptions).then(res => {
            saveReviewCallBack(res);
        });
    }

    const saveReviewCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.onSaveCallback();
    }

    const getReviewById = () => {
        let apiOptions: any = {
            url: `review/${props.reviewId}`
        };
        http.get(apiOptions).then(res => {
            setItem(res.data);
        });
    };

    useEffect(() => {
        if (props.reviewId > 0) {
            getReviewById();
        }
    }, []);

    return (
        <div className="myreviewform-ui">
            <div className="row justify-content-between title-font py-1 mx-auto px-3">
                <div className="py-1">
                    <span>Review</span>
                </div>
                <div className="float-right pointer" onClick={props.closeReviewForm}>
                    <HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>

            <div className="m-4">
                <div className="m-1">
                    <FxTextarea name="reviewComment" variant="outlined" label="Comments" fullWidth
                        value={item.reviewComment} onValueChange={handleInputChange} />
                </div>

                <div className="m-1 pt-3">
                    <Rating name="rating" value={item.rating} onChange={(event, val) => handleRatingChange(val)} />
                </div>

                <div className="m-1 text-center">
                    <Button variant="contained" size="medium" color="primary" onClick={saveReview}>
                        Save
                    </Button></div>
            </div>
        </div>
    );
};