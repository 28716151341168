import React, { useEffect, useState } from 'react';
import './StallItemForm.scss';
import { FxTextarea, FxMultiSelect, http, localstorage, FxInput, AlertService, general, formValidator } from '../../fx-core';
import { Button, Checkbox, Link, Dialog } from '@material-ui/core';
import * as _ from 'lodash';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import Carousel from 'react-material-ui-carousel';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';

const StallItemForm = (props) => {

  let defaultItem = {
    itemTagId: 0,
    serviceName: "",
    stallName: "",
    itemName: "",
    description: "",
    deliveryTime: 0,
    isActive: false,
    itemTags: "",
    communityId: 0,
    id: 0
  };
  const [item, setItem] = useState(defaultItem);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem("communityId")));
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [stalls, setStalls] = useState([]);
  const [itemTagList, setItemTagLists] = useState([]);
  const [tagLimitError, setTagLimitError] = useState("");
  const [images, setImages] = useState([]);
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [errors, setErrors] = useState({});

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }

  const getItemTags = () => {
    let inputData = {
      id: communityId
    }
    let apiOptions: any = {
      url: `itemtag/community/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setItemTagLists(result.itemTags);
      getStallItemById();
    })
  };

  const getStallItemById = () => {
    let inputData = {
      id: props.stallItemId
    }
    let apiOptions: any = {
      url: `stallitem/${inputData.id}/tags`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      getStallItemByIdCallback(result);
    })
  };

  const getStallItemByIdCallback = (res) => {
    setItem(res);
    //handleItemChange({ "isActive": res.isActive });
    getStallItemImages(res.id);
  }

  const getValidationMap = () => {
    let validationMap: any = {
      itemName: ["required", "maxlen"],
      description: ["maxlen"],
    };
    return validationMap;
  };

  const getInputValidations = (name) => {
    let validationMap = getValidationMap();
    return validationMap[name];
  };

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidationMap();
    for (var key in validationMap) {
      if (key == "itemName") {
        let result = { name: key, value: item[key], validations: validationMap[key], param: 25 }
        items.push(result);
      }
      else if (key == "description") {
        let result = { name: key, value: item[key], validations: validationMap[key], param: 200 }
        items.push(result);
      }
      else {
        let result = { name: key, value: item[key], validations: validationMap[key] }
        items.push(result);
      }
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  };

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  };

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };

  const saveStallItem = () => {
    const isValid = bulkValidate();
    if (isValid) {
      if (item.itemTags) {
        let tagArr = item.itemTags.split(",");
        if (tagArr.length > 3) {
          setTagLimitError("Max three tags only allowed");
          return;
        }
      }
      let apiOptions: any = {
        url: `stallitem/save`,
        data: item
      };
      http.put(apiOptions).then(res => {
        AlertService.showSuccessMsg();
        props.handleStallItemFormClose();
      })
    }
  };

  const handleClose = () => {
    props.handleStallItemFormClose();
  }

  const handleImageChange = (selectedIdx) => {
    setSelectedIndex(selectedIdx);
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: item.id,
      cameraConfig: { url: `stallitem/savecameraimage` },
      fileUploadConfig: { url: `stallitem/savefileimage` },
      isCloseOnSuccess: true
    };
    if (item.communityId > 0) {
      photoCaptureConfig = {
        id: item.id,
        cameraConfig: { url: `stallitem/${item.communityId}/savecameraimage` },
        fileUploadConfig: { url: `stallitem/${item.communityId}/savefileimage` },
        isCloseOnSuccess: true
      };
    }
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    getStallItemImages(item.id);
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const updateDefaultImage = () => {
    let itemObj: any = images[selectedIndex];
    let inputData = {
      id: itemObj.id
    };
    let apiOptions: any = {
      url: `stallitem/updateDefaultImage`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg("Default image updated successfully");
      getStallItemImages(item.id);
    });
  }

  const deleteImage = () => {
    let itemObj: any = images[selectedIndex];
    let inputData = {
      id: itemObj.id
    };
    let apiOptions: any = {
      url: `stallitem/deleteImage`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      AlertService.showSuccessMsg("Image deleted successfully");
      getStallItemImages(item.id);
    });

  }

  const getStallItemImages = (id) => {
    let inputData = {
      id: id
    };
    let apiOptions: any = {
      url: `stallitem/${inputData.id}/images`
    };
    http.post(apiOptions).then(response => {
      getStallItemImagesCallback(response.data);
    });
  }

  const getStallItemImagesCallback = (res) => {
    for (let item of res) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setImages(res);
    setSelectedIndex(0);
  }

  useEffect(() => {
    getItemTags();
  }, []);

  return (
    <div className='stallitemform-ui'>
      <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
        <span className="col-11">
          <span>Stall Item Form</span>
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>
      <div className='row justify-content-center pt-5'>
        <div className="col-xs-3 col-md-12 col-lg-12 text-center">
          {(images && images.length > 0) &&
            <Carousel onChange={handleImageChange}>
              {
                images && images.map((itemObj: any) => (
                  <div className="text-center">
                    <img src={itemObj.imageUrl} alt="image"></img>
                  </div>
                ))}
            </Carousel>
          }
          {images.length == 0 &&
            <span>
              <img src={dummyImage} alt="dummy" width={100} height={100}></img>
              <div className="text-center camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </span>
          }
          {images && images.length > 0 &&
            <span className='row pb-3 pt-2'>
              <span className='col-5 text-right'>
                <Link component="button" onClick={updateDefaultImage}>Set as default</Link>
              </span>
              <span className='col-2'>
                <span className="text-center camera-align px-3" onClick={openPhotoCapture}>
                  <CameraAltIcon className='pointer' />
                </span>
              </span>
              <span className='col-5 text-left'>
                <i className="fas fa-trash-alt pointer font-18" onClick={deleteImage}></i>
              </span>
            </span>
          }
        </div>
      </div>

      <div className='pt-1'>
        <div className='row p-3'>
          <div className="col-12"><b>Service Name:</b>
            <span className='pl-1'>{item.serviceName}</span>
          </div>
        </div>
        <div className='row p-3'>
          <div className="col-12"><b>Stall Name:</b>
            <span className='pl-1'>{item.stallName}</span>
          </div>
        </div>
        <div className='row p-3'>
          <div className="col-12"><b>Item Name:</b>
            <FxInput name="itemName" variant="outlined" size="small" fullWidth
              value={item.itemName} onValueChange={handleInputChange} />
            {
              hasError("itemName", "required") &&
              <div className="error">Item Name is required</div>
            }
            {
              hasError("itemName", "maxlen") &&
              <div className="error">Item Name should be less than 25 characters</div>
            }
          </div>
        </div>

        <div className='row p-3'>
          <div className='col-12'>
            <div><b>Description</b></div>
            <FxTextarea name="description" variant="outlined" size="small" fullWidth maxRows={4}
              value={item.description} onValueChange={handleInputChange} />
            {
              hasError("description", "maxlen") &&
              <div className="error">Description should be less than 200 characters</div>
            }
          </div>
        </div>

        <div className='col-12 p-3'>
          <FxMultiSelect name="itemTags" variant="outlined" label="Tags" fullWidth
            options={itemTagList} selectedValue={item.itemTags}
            valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
          {tagLimitError &&
            <div className='error'>{tagLimitError}</div>
          }
        </div>

        <div className='col-12 p-3'>
          <FxInput type="number" name="deliveryTime" label="Delivery Time" value={item.deliveryTime} onValueChange={handleInputChange}
            variant="outlined" fullWidth size="small" />
        </div>
        <div className="col-12 p-2 pb-5">
          <Checkbox name="isActive" color="primary" checked={item.isActive}
            value={item.isActive} onChange={handleCheckbox} />
          Is Active
        </div>

        <div className='px-3 py-2 drawer-footer'>
          <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveStallItem}><b>Save</b></Button>
        </div>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
    </div>
  );
}
export default StallItemForm;