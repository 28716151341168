import React, { useEffect, useState } from 'react';
import './Subscriptions.scss';
import { FxDateDisplay, http, FxCurrency, localstorage, useNavigationService, AlertService } from '../../fx-core';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab, Drawer, makeStyles, Button, Link } from '@material-ui/core';
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));
const Subscriptions = (props) => {
  const classes = styles();
  const Razorpay = useRazorpay();
  
  const from = props.match.params.from ? props.match.params.from : "";

  const { navigate } = useNavigationService();
  const [stallId, setStallId] = useState(props.match.params.id ? parseInt(props.match.params.id) : 0);
  const [userId, setUserId] = useState(parseInt(localstorage.getItem("userId")));
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionFormOpen, setSubscriptionFormOpen] = useState(false);

  const getSubscriptions = () => {
    let inputData: any = {
      userId: userId,
      stallId: stallId
    };
    let apiOptions: any = {
      url: `providersubscription/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setSubscriptions(result);
    })
  };

  const handleClose = () => {
    props.handleSubscriptionsClose();
  }

  const openSubscriptionForm = (id) => {
    setSubscriptionFormOpen(true);
  }

  const handleSubscriptionFormClose = () => {
    setSubscriptionFormOpen(false);
    getSubscriptions();
  }

  const initiatePayment = (id) => {
    let inputData = {
      entityTypeId: 1,
      entityId: id
    };
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item = resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        getSubscriptions();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const navToMyStallList = () => {
    let navData = {
      targetRoute: '/provider/mystalls',
      targetTitle: "My Stalls",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const navToProviderDashboard = () => {
    let navData = {
      targetRoute: '/provider/providerdashboard/' + stallId,
      targetTitle: "Provider Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  };   

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <div className='subscriptions-ui pt-5'>
      <div className="title-font custom-head pt-4 mt-3 pl-4">
        <Breadcrumbs aria-label="breadcrumb">
          {
            from == "pd" &&
            <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToProviderDashboard}>
              <b>Provider Dashboard</b>
            </Link>
          }
          {
            from == "ms" &&
            <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToMyStallList}>
              <b>Stalls</b>
            </Link>
          }
          <Typography color="primary" className="breadcrumbs-style">Subscriptions</Typography>
        </Breadcrumbs>
      </div>

      <div className='pt-2'>
        <div className='col-12 pb-2'>
          <span className="float-right pointer">
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={openSubscriptionForm}>
              <i className="fas fa-plus pr-2"></i>
              Buy Subscription
            </Fab>
          </span>
        </div>
      </div>

      <div className='my-4 pt-3 mx-3 subscription-scroll'>
        <TableContainer component={Paper}>
          <Table stickyHeader size="small" className='table-striped'>
            <TableHead>
              <TableRow>
                <TableCell>Plan</TableCell>
                <TableCell>Stall Name</TableCell>
                <TableCell><span className='float-right'>Amount</span></TableCell>
                <TableCell>Start Date - End Date</TableCell>
                <TableCell>Payment</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((row: any, i) => (
                <TableRow>
                  <TableCell>{row.subscriptionPlan}</TableCell>
                  <TableCell>
                    <span>
                      {row.stallName}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className='float-right'>
                      <FxCurrency value={row.amount} />
                    </span>
                  </TableCell>
                  <TableCell>
                    <FxDateDisplay value={row.startDate} /> - <FxDateDisplay value={row.endDate} />
                  </TableCell>
                  <TableCell>
                    {row.subscriptionPaymentMode &&
                      <span>{row.subscriptionPaymentMode}
                        <span> - {row.paymentStatus}</span>
                      </span>
                    }
                  </TableCell>
                  <TableCell>
                    {row.subscriptionPaymentMode == 'razorpay' && row.paymentStatus != 'Paid' &&
                      <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => initiatePayment(row.id)}>Pay</Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={subscriptionFormOpen} onClose={handleSubscriptionFormClose}>
        <SubscriptionForm userId={userId} stallId={stallId} handleSubscriptionFormClose={handleSubscriptionFormClose} />
      </Drawer>
    </div>
  );
}
export default Subscriptions;