import React, { useEffect, useState } from 'react';
import './FeatureCouponForm.scss';
import { Button } from '@material-ui/core';
import { http, formValidator, AlertService, FxInput, FxSelect, FxDate, formatService } from '../../fx-core';
import * as _ from 'lodash';

const FeatureCouponForm = (props) => {
    const currentDate = formatService.getCurrentDateForServer();
    const defaultCoupon = {
        id: props.id,
        couponCode: "",
        couponValue: "",
        couponValueTypeId: 2, //2-value
        validFrom: currentDate,
        validTo: formatService.getYearEndDate(currentDate),
        entityId: -1,
        entityTypeId: 7, //7-Feature
        couponTypeId: 2, //2-Promotion
        communityId: props.communityId
    };
    const defaultAmountDetails = [
        { code: "ten", text: 10, symbol: "$" },
        { code: "twenty", text: 20, symbol: "$" },
        { code: "fifty", text: 50, symbol: "$" },
        { code: "hundred", text: 100, symbol: "$" },
    ];
    const [coupon, setCoupon] = useState(defaultCoupon);
    const [featureCoupon, setFeatureCoupon] = useState([]);
    const [errors, setErrors] = useState({});
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [amountDetails, setAmountDetails] = useState(defaultAmountDetails);
    const [selAmtCode, setSelAmtCode] = useState("");

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        if (item.name == "entityId") {
            item.value = item.value == -1 ? "" : item.value + '';
            handleItemChange({ couponValue: "" });
            if (item.value) {
                getCouponValueByFeature(item.value);
            }
        }
        else if (item.name == "couponValue") {
            item.value = item.value ? item.value + '' : "";
        }
        item.validations = getInputValidations(item.name);
        const validationResult = formValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setCoupon(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getValidationMap = () => {
        let validationMap = {
            couponCode: ["required"],
            couponValue: ["required"],
            entityId: ["required"],
            validFrom: ["required"],
            validTo: ["required"]
        };
        return validationMap;
    };

    const getInputValidations = (name) => {
        let validationMap = getValidationMap();
        return validationMap[name];
    };

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidationMap();
        for (var key in validationMap) {
            if (key == "entityId") {
                let val = coupon[key] == -1 ? "" : coupon[key] + '';
                let rec = { name: key, value: val, validations: validationMap[key] }
                items.push(rec);
            }
            else if (key == "couponValue") {
                let val = coupon[key] == null ? "" : coupon[key] + '';
                let rec = { name: key, value: val, validations: validationMap[key] }
                items.push(rec);
            }
            else {
                let result = { name: key, value: coupon[key], validations: validationMap[key] }
                items.push(result);
            }
        }
        let validationResult = formValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    };

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    };

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveCoupon = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions = {
                url: 'coupon/save',
                data: coupon
            };
            http.post(apiOptions).then(response => {
                saveCouponCallBack(response);
            });
        }
    };

    const saveCouponCallBack = (res) => {
        AlertService.showSuccessMsg();
        navToCouponList();
    };

    const navToCouponList = () => {
        props.handleClose();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                FeatureCoupon: { default: true },
                SubscriptionPlans: { default: false }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            const result = response.data;
            initLookupCallback(result);
        })
    };

    const initLookupCallback = (result) => {
        setFeatureCoupon(result.FeatureCoupon);
        setSubscriptionPlans(result.SubscriptionPlans)
        getCouponById();
    };

    const getCouponById = () => {
        if (coupon.id > 0) {
            let apiOptions = {
                url: `coupon/${coupon.id}`,
                data: {}
            };
            http.get(apiOptions).then(response => {
                getCouponByIdCallBack(response);
            });
        }
    };

    const getCouponByIdCallBack = (res) => {
        const result = res.data;
        if (result.entityId == 3) { //3-Account
            let amtDetail: any = _.find(amountDetails, { text: result.couponValue });
            setSelAmtCode(amtDetail.code);
        }
        setCoupon(result);
    };

    const getCouponValueByFeature = (featureId) => {
        if (featureId == 1) { //1-StallSubscription
            let casualPlan: any = _.find(subscriptionPlans, { text: "Casual" });
            let apiOptions = {
                url: `subscriptionplan/${casualPlan.id}`,
            };
            http.get(apiOptions).then(response => {
                const result = response.data;
                if (result && result.amount > 0) {
                    handleInputChange({ name: "couponValue", value: result.amount });
                }
                else {
                    handleInputChange({ name: "couponValue", value: "" });
                    AlertService.showErrorMsg("Price not configured Casual subscription plan.")
                }
            });
        }
        else if (featureId == 2 || featureId == 4 || featureId == 5) { // 2- LocalDirectory, 4-LiveStream, 5-LiveStreamWithRecording
            let apiOptions = {
                url: `coupon/feature/price/${featureId}`,
            };
            http.get(apiOptions).then(response => {
                const result = response.data;
                if (result && result.price > 0) {
                    handleInputChange({ name: "couponValue", value: result.price });
                }
                else {
                    handleInputChange({ name: "couponValue", value: "" });
                    AlertService.showErrorMsg("Price not configured.")
                }
            });
        }
    };

    const onAmountSelect = (itemObj) => {
        let item = { couponValue: itemObj.text };
        setSelAmtCode(itemObj.code);
        handleItemChange(item);
    };

    useEffect(() => {
        initLookup();
    }, []);

    return (
        <div className='featurecouponform-ui'>
            <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
                <span className="col-11">
                    <span>Coupon</span>
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={navToCouponList}></i>
                </span>
            </div>
            <div className='drawer-combo pt-1'>
                <div className="m-4">
                    <div className="m-1">
                        <FxSelect name="entityId" variant="outlined" label="Feature"
                            options={featureCoupon}
                            selectedValue={coupon.entityId}
                            disabled={coupon.id > 0}
                            valueField="id"
                            displayField="text"
                            size="small"
                            fullWidth
                            onValueChange={handleInputChange}
                            required={true} />
                        {hasError("entityId", "required") && (
                            <div className="error">Feature is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxInput name="couponCode" variant="outlined" label="Coupon Code" fullWidth required={true}
                            value={coupon.couponCode} onValueChange={handleInputChange} disabled={coupon.id > 0} />
                        {hasError("couponCode", "required") && (
                            <div className="error">Coupon Code is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        {
                            coupon.entityId != 3 &&
                            <FxInput type="number" name="couponValue" variant="outlined" label="Coupon Value" fullWidth
                                value={coupon.couponValue} onValueChange={handleInputChange} required={true} disabled={true} />
                        }
                        {
                            coupon.entityId == 3 &&
                            <div className='m-1 row'>
                                {
                                    amountDetails.map((amt, i) => (
                                        <div key={i} className="m-1">
                                            <Button variant="contained" size="medium"
                                                color={selAmtCode == amt.code ? "primary" : "secondary"}
                                                onClick={() => onAmountSelect(amt)}>
                                                <span className='amount'>{amt.symbol}{amt.text}</span>
                                            </Button>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        {
                            coupon.id == 0 && coupon.entityId == 1 &&
                            <div className="error">Casual subscription plan amount only defaulted</div>
                        }
                        {hasError("couponValue", "required") && (
                            <div className="error">Coupon Value is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxDate name="validFrom" variant="outlined" label="Valid From" fullWidth
                            value={coupon.validFrom} onValueChange={handleInputChange} required={true} minDate={currentDate}
                        />
                        {hasError("validFrom", "required") && (
                            <div className="error">Valid From is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxDate name="validTo" variant="outlined" label="Valid To" fullWidth
                            value={coupon.validTo} onValueChange={handleInputChange} required={true} minDate={currentDate}
                        />
                        {hasError("validTo", "required") && (
                            <div className="error">Valid To is required</div>
                        )}
                    </div>
                </div>
                <div className='px-3 py-2 drawer-footer'>
                    <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveCoupon}><b>Save</b></Button>
                </div>
            </div>
        </div >
    )
};

export default FeatureCouponForm;

