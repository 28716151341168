import React, { useEffect, useState } from 'react';
import './LiveStreamLanding.scss';
import { http, localstorage, useNavigationService, formatService, session, general } from '../fx-core';
import { useDispatch } from 'react-redux';
import RegisterComponent from '../RegisterComponent/RegisterComponent';
import SignIn from '../SignIn/SignIn';
import GuestComponent from '../GuestComponent/GuestComponent';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import LiveStreamCard from '../LiveStreamCard/LiveStreamCard';
import LiveStreamCardMobile from '../LiveStreamCardMobile/LiveStreamCardMobile';
import { Link } from '@material-ui/core';
import * as signalR from "@microsoft/signalr";
import { AppSettings } from '../utils/AppSettings';

const LiveStreamLanding = (props) => {
  const defaultItem = {
    liveStreamName: "",
    description: "",
    liveStreamDate: "",
    duration: null,
    startTime: "",
    endTime: "",
    stallName: "",
    stallItemName: "",
    imageUrl: "",
    stallItemId: 0,
    stallItemImage: "",
    liveStreamStatusId: 0
  };
  const { navigate } = useNavigationService();
  const [liveStreamHex, setLiveStreamHex] = useState(null);
  const [communityId, setCommunityId] = useState(0);
  const globalDispatch = useDispatch();
  const [value, setValue] = React.useState('guest');
  const [item, setItem] = useState(defaultItem);
  const [connectionId, setConnectionId] = React.useState(null);
  const [notifyEvent, setNotifyEvent] = React.useState(false);
  const [liveStreamId, setLiveStreamId] = useState(0);

  const notificationUrl = `${AppSettings.APP_API_BASE_URL}/newordernotification`;

  // const hubConnection = new signalR.HubConnectionBuilder()
  //   .withUrl(notificationUrl)
  //   .configureLogging(signalR.LogLevel.Information)
  //   .build();

  const validateCommunity = (code: any) => {
    let inputData = { code: code || "dev" };

    let apiOptions = {
      url: 'portal/validate/community/code',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result: any = response.data;
      let community: any = result.community;
      setCommunityId(community.id);
      localstorage.setItem('imagStorageUrl', result.imageStorageUrl);
      globalDispatch({ type: "set-community-details", payload: community });
      validateCommunityCallback();
    });
  }

  const validateCommunityCallback = () => {
    let userIdHex = props.match.params.uh ? props.match.params.uh : "";
    let liveStreamHex = props.match.params.lh ? props.match.params.lh : "";
    if (liveStreamHex) {
      setLiveStreamHex(liveStreamHex);
    }
    if (userIdHex) {
      resolveUser(userIdHex);
      joinLiveStream(liveStreamHex);
    }
    else{
      getLiveStreamByHexId(liveStreamHex);
    }
  }

  const handleGuestCallback = (name: any) => {
    joinLiveStreamAsGuest(name);
  }

  const navToLogin = () => {
    setValue("signin");
  }

  const navToRegister = () => {
    setValue("signup");
  }

  const handleSignInCallBack = () => {
    joinLiveStream(liveStreamHex);
  }
  const handleRegisterCallBack = () => {
    setValue("signin");
  }

  const forgotPwdCallBack = () => {
    setValue("signin");
  }

  const showForgotPwd = () => {
    setValue("fgtpwd");
  }

  const backAction = () => {
    setValue("guest");
  }

  const joinLiveStreamAsGuest = (name) => {
    let navData = {
      targetRoute: `/provider/livestreamdetailinfo/${liveStreamHex}/${name}`,
      targetTitle: "Live Stream",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const joinLiveStream = (liveStreamHex) => {
    let navData = {
      targetRoute: `/provider/livestreamdetail/${liveStreamHex}`,
      targetTitle: "Live Stream",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }


  const getLiveStreamByHexId = (hexId) => {
    let apiOptions: any = {
      url: `livestream/hex/${hexId}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      result.startTime = formatService.getConvertedTimeForDisplay(result.startTime);
      result.endTime = formatService.getConvertedTimeForDisplay(result.endTime);
      result.imageUrl = general.getImageUrl(result.stallImagePath);
      result.stallItemImage = general.getImageUrl(result.stallItemImagePath);
      setItem(result);
      setLiveStreamId(result.id);
      //openConnection(result.hostUserId, result.id);
    });
  }

  const resolveUser = (uHex) => {
    let apiOptions: any = {
      url: `security/${uHex}/resolveuser`
    };
    http.get(apiOptions).then(response => {
      signInCallBack(response);
    })
  }

  const signInCallBack = (response) => {
    let result = response.data;
    let authToken = result.authToken;

    let userDisplayName = authToken.firstName;
    if (authToken.lastName) {
      userDisplayName = `${userDisplayName} ${authToken.lastName}`;
    }

    session.setItem('id_token', "token " + authToken.sessionId);
    localstorage.setItem('ltk', authToken.sessionId);
    localstorage.setItem('userId', authToken.userId);
    localstorage.setItem('roleCode', authToken.roleCode);
    localstorage.setItem('userDisplayName', userDisplayName);
    localstorage.setItem('username', authToken.username);
    localstorage.setItem('userRoles', JSON.stringify(result.roles));
    globalDispatch({ type: "LOGIN_SUCCESS", payload: { isLogin: true } });
  }


  const openConnection = (loggedInUserId, lvId) => {
    // hubConnection.start().then(data => {
    //   console.log("connection started");
    //   if (hubConnection.connectionId) {
    //     setConnectionId(hubConnection.connectionId);
    //     saveUserHubMap(loggedInUserId, hubConnection.connectionId);

    //     hubConnection.on("LIVESTREAM_STARTED", data => {
    //       console.log("Livestream info", data);
    //       if (data && data.liveStreamId == lvId) {
    //         setNotifyEvent(true);
    //         if (hubConnection.connectionId) {
    //           closeConnection();
    //         }
    //         return;
    //       }
    //     });
    //   }
    // });
  }

  const saveUserHubMap = (userId, connectionId) => {
    let inputData = {
      userId: userId,
      hubId: connectionId
    }
    let apiOptions: any = {
      url: `userhubmap/add`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      console.log("connection inserted");
    })
  };

  const closeConnection = () => {
    // hubConnection.stop().then(data => {
    //   console.log("connection stopped");
    // });
  }

  useEffect(() => {
    let code = props.match.params.code ? props.match.params.code : "";
    validateCommunity(code);
  }, []);

  return (
    <div className='lvlanding-ui'>
      <div className='deskview'>
        <div className="pb-3">
          <LiveStreamCard liveStreamObj={item} />
        </div>
      </div>
      <div className='mobileview'>
        <div className='pt-5'>
          <span className='justify-content-center d-flex font-weight-bold font-20 pt-3 pb-1'>{item.liveStreamName}</span>
        </div>
        <div className="px-2 pb-3">
          <LiveStreamCardMobile liveStreamObj={item} />
        </div>
      </div>
      {item.liveStreamStatusId == 2 &&
        <div>
          {notifyEvent &&
            <div className='row d-flex justify-content-center'>
              <div className='col-12 text-center'>
                <span className='font-24 color-grey text-center'>
                  <b>
                    Event started by host... Please join...
                  </b>
                </span>
              </div>
            </div>
          }
          <div className='login-area-ht d-flex justify-content-center align-items-center'>
            {
              value == 'guest' &&
              <div className='w-100 d-flex justify-content-center'>
                <div className='col-sm-10 col-md-4'>
                  <GuestComponent handleGuestCallback={handleGuestCallback} />
                  <div className='text-center'>
                    <b>(or)</b>
                  </div>
                  <div className='text-center py-4'>
                    <Link onClick={navToLogin} className="font-weight-bold pointer">Login</Link>
                  </div>
                </div>
              </div>
            }
            {
              value == 'signin' &&
              <div className="w-100 d-flex justify-content-center">
                <div className="col-sm-10 col-md-4">
                  <SignIn handleSignInCallBack={handleSignInCallBack} showForgotPwd={showForgotPwd} backAction={backAction}
                    navToRegister={navToRegister} fromLanding={true} />
                </div>
              </div>
            }
            {
              value == 'signup' &&
              <div className="w-100 d-flex justify-content-center">
                <div className="col-sm-10 col-md-4">
                  <RegisterComponent handleRegisterCallBack={handleRegisterCallBack} fromLanding={true} />
                </div>
              </div>
            }
            {
              value == 'fgtpwd' &&
              <div className="w-100 d-flex justify-content-center">
                <div className="col-sm-10 col-md-4">
                  <ForgotPassword forgotPwdCallBack={forgotPwdCallBack} fromLanding={true} />
                </div>
              </div>
            }
          </div>
        </div>
      }
      {(item.liveStreamStatusId == 3) &&
        <div className='d-flex justify-content-center align-items-center close-area'>
          <span className='font-24 color-grey'>
            <b>
              This event is closed
            </b>
          </span>
        </div>
      }
      {(item.liveStreamStatusId == 1) &&
        <div className='d-flex justify-content-center align-items-center close-area'>
          <span className='font-24 color-grey'>
            <b>
              Event not started by host, Please wait...
            </b>
          </span>
        </div>
      }
    </div>
  );
}
export default LiveStreamLanding;