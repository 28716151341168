import React, { useEffect, useState } from 'react';
import './BookingServiceHome.scss';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useNavigationService, http, formatService, general, localstorage, FxInput } from '../fx-core';
import Typography from '@material-ui/core/Typography';
import nostalls from '../../assets/nostalls.svg';
import BookingServiceCard from '../BookingServiceCard/BookingServiceCard';
import StallCard from '../StallHome/StallCard/StallCard';

const BookingServiceHome = (props: any) => {
    const { navigate, back } = useNavigationService();

    let defaultFilter: any = {
        stallName: ""
    };

    const [filters, setFilters] = useState(defaultFilter);
    const [serviceId, setServiceId] = useState(0);
    const [bookingStalls, setBookingStalls] = useState([]);
    const [serviceName, setServiceName] = useState(null);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            stallName: filters.stallName
        };
        if (item.name == "stallName") {
            filterObj.stallName = item.value;
        }
        getBookingStalls(filterObj, serviceId);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getServiceBySlug = () => {
        const slug = props.match.params.serviceSlug;
        let apiOptions: any = {
            url: `portal/service/${slug}`
        };
        http.get(apiOptions).then(res => {
            getServiceBySlugCallBack(res);
        });
    }

    const getServiceBySlugCallBack = (res) => {
        const result = res.data;
        if(result && result.id) {
            const id = result.id;
            setServiceName(result.serviceName);
            setServiceId(id);
            getBookingStalls(defaultFilter, id);
        }
        else {
            getBookingStalls(defaultFilter, serviceId);
        }
    }

    const getBookingStalls = (data: any, serviceId) => {
        let inputData = {
            serviceId: serviceId,
            currentDate: formatService.getCurrentDateTime(),
            stallName: data.stallName,
            communityId: localstorage.getItem("communityId")
        };
        let apiOptions: any = {
            url: `portal/service/stalls`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getBookingStallsCallBack(res);
        });
    }

    const getBookingStallsCallBack = (res) => {
        let result = res.data.items;
        for (let item of result) {
            item.imageUrl = general.getImageUrl(item.imagePath);
        }
        setBookingStalls(result);
    }

    const navToStallListing = (slug: any) => {
        let navData = {
            targetRoute: '/stalls/' + slug,
            targetTitle: "Bookings",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    };

    const navToCustomerDashboard = (item: any) => {
        back();
    }

    useEffect(() => {
        getServiceBySlug();
    }, []);


    return (
        <div className="pt-5 pb-5 bookingservicehome-ui">
            <div className="title-font custom-head pt-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
                        <b>Dashboard</b>
                    </span>
                    <Typography color="textPrimary" className="breadcrumbs-style">{serviceName}</Typography>
                </Breadcrumbs>
            </div>

            {/* Desktop View */}
            <div className="row pt-2 deskview">
                <div className="col-12 px-3 pl-5 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="stallName" variant="outlined" label="Search Stalls" fullWidth size="small"
                            value={filters.stallName}
                            onValueChange={handleInputChange}
                        />
                    </div>
                    <div className="row">
                        {
                            bookingStalls.length > 0 && bookingStalls.map((item, i) => (
                                <div className="col-4">
                                    <StallCard item={item} navToStall={navToStallListing} />
                                </div>
                            ))
                        }
                        {
                            bookingStalls.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No stalls available.</b></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BookingServiceHome;