import React, { useEffect, useState } from 'react';
import './ListingServiceHome.scss';
import comingsoon from '../../assets/comingsoon.svg';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useNavigationService, http, formatService, general,localstorage,FxInput } from '../fx-core';
import Typography from '@material-ui/core/Typography';
import nostalls from '../../assets/nostalls.svg';
import ListingServiceCard from './ListingServiceCard/ListingServiceCard';
import ListingDetail from '../ListingDetail/ListingDetail';
import Drawer from '@material-ui/core/Drawer';

const ListingServiceHome = (props: any) => {
    const { getRouteData, navigate, back, pageState } = useNavigationService();
    const navToCustomerDashboard = (item: any) => {
        back();
    }
    let defaultItem: any = {
        searchText: "",
        categoryIds: [],
        isDeliveryEnabled: false,
        isPickupEnabled: false
    };

    const [item, setItem] = useState(defaultItem);
    const [serviceId, setServiceId] = useState(props.match.params.serviceId ? parseInt(props.match.params.serviceId) : 0);
    const [listingServiceDetails, setListingServiceDetails] = useState([]);
    const [serviceName, setServiceName] = useState(null);
    const [serviceCode, setServiceCode] = useState(null);
    const [detailComponentOpen, setDetailComponentOpen] = useState(false);
    const [saleId, setSaleId] = useState(0);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        getListingServices({ "searchText": item.value });
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getServiceById = () => {
        let inputData = {
            id: serviceId
        };
        let apiOptions: any = {
            url: `portal/service/edit/${inputData.id}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            setServiceName(response.data.serviceName);
            setServiceCode(response.data.serviceCode);
        });
    }


    const getListingServices = (data: any) => {
        let inputData = {
            serviceId: serviceId,
            currentDate: formatService.getCurrentDateTime(),
            title: data.searchText ? data.searchText : "",
            communityId: localstorage.getItem("communityId")
        };
        let apiOptions: any = {
            url: `portal/sale/location/listings`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.items;
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
                let currentDateTime = formatService.getCurrentDateTime();
                let startDateTime = formatService.getConvertedDateTime(item.startDateTime);
                if (currentDateTime < startDateTime) {
                    item.isStart = true;
                }
                else {
                    item.isStart = false;
                }
            }
            setListingServiceDetails(result);
        });
    }

    const navToDetail = (id: any) => {
        setSaleId(id);
        setDetailComponentOpen(true);
    }

    const handleDetailClose = () => {
        setDetailComponentOpen(false);
    }

    const navToListingSale = (id: any, stallId: any) => {
        setSaleId(id);
        let  parent= 'listingservicehome';
        let navData = {
            //targetRoute: '/sale/' + serviceId + '/' + id + '/' + parent,
            targetRoute: '/stall/' + serviceId + '/' + stallId + '/' + id +'/' + parent,
            targetTitle: "Sale",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    };

    useEffect(() => {
        getServiceById();
        getListingServices(defaultItem);
    }, []);


    return (
        <div className="pt-5 pb-5 listinghome-ui">
            <div className="title-font custom-head pt-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
                        <b>Dashboard</b>
                    </span>
                    <Typography color="textPrimary" className="breadcrumbs-style">{serviceName}</Typography>
                </Breadcrumbs>
            </div>

            {/* Desktop View */}
            <div className="row pt-2 deskview">
                <div className="col-12 px-3 pl-5 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Listings"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>

                    <div className="row">
                        {
                            listingServiceDetails.length > 0 && listingServiceDetails.map((item, i) => (
                                <div className="col-4">
                                    <ListingServiceCard item={item} navToDetail={navToDetail} navToListingSale={navToListingSale} />
                                </div>
                            ))
                        }
                        {
                            listingServiceDetails.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* Tablet View */}
            <div className="pt-2 tabview">
                <div className="pr-4 pl-3 pt-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Listings"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>

                    <div className="row">
                        {
                            listingServiceDetails.length > 0 && listingServiceDetails.map((item, i) => (
                                <div className="col-6">
                                    <ListingServiceCard item={item} navToDetail={navToDetail} navToListingSale={navToListingSale} />
                                </div>
                            ))
                        }
                        {
                            listingServiceDetails.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                            </div>
                        }
                    </div>
                </div>
            </div>


            {/* Mobile View */}
            <div className="pb-5 pl-1 text-center mobileview">
            <div className="col-12 px-3 pl-4 pt-5 pb-5">
                    <div className="pt-5 pb-2 col-12 pl-0">
                        <FxInput name="searchText" value={item.searchText} variant="outlined" label="Search Listings"
                            onValueChange={handleInputChange} fullWidth size="small"
                        />
                    </div>

                    <div className="text-center">
                        {
                            listingServiceDetails.length > 0 && listingServiceDetails.map((item, i) => (
                                <div>
                                    <ListingServiceCard item={item} navToDetail={navToDetail} navToListingSale={navToListingSale} />
                                </div>
                            ))
                        }
                        {
                            listingServiceDetails.length < 1 &&
                            <div className="py-5 mx-auto">
                                <img src={nostalls} alt="no stalls"></img>
                                <div className="pt-4 font-17"><b> Sorry, No listings available.</b></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Drawer anchor={"right"} open={detailComponentOpen} onClose={handleDetailClose}>
                <ListingDetail handleDetailClose={handleDetailClose} saleId={saleId} />
            </Drawer>
        </div>
    );
}
export default ListingServiceHome;