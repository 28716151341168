import React from 'react';
import './CommunityLink.scss';

export const CommunityLink = (props) => {

    const handleClose = () => {
        props.closeDialog()
    };

    const openLink = () => {
        window.open(props.link, '_self');
    };

    return (
        <div className="modalalign">
            <div className="row justify-content-between title-font px-3">
                <div className="py-1 pl-3">
                    <span>Community Switch</span>
                </div>
                <div className="text-right pointer my-auto">
                    <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
                </div>
            </div>
            <div className="col-12 mt-2 ml-2">
                <span>
                    {props.message}                    
                </span>
            </div>
            <div className="col-12 ml-2 mb-2">
                <a href='#' onClick={openLink}>click here</a> to login.
            </div>
        </div>
    );
};