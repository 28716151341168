import React, { useState, useEffect } from 'react';
import './SaleRequestSummary.scss';
import { http, general, useNavigationService, FxDateDisplay, FxDateTimeDisplay, FxCurrency } from '../../../fx-core';
import dummyImage from "../../../../assets/images/dummy.png";
import Button from '@material-ui/core/Button';
import _ from "lodash";
import Carousel from 'react-material-ui-carousel'
import DateIcon from '@material-ui/icons/DateRange';

const SaleRequestSummary = (props: any) => {

    let defaultItem = {
        serviceRequestType: "",
        stallId: 0,
        stallName: "",
        requestedByName: null
    };
    let defaultSale = {
        stallAddress: "",
        stallId: 0,
        stallName: "",
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        title: null,
        description: null
    };
    const { navigate } = useNavigationService();
    const [serviceRequest, setServiceRequest] = useState(defaultItem);
    const [sale, setSale] = useState(defaultSale);
    const [saleItemDetails, setSaleItemDetails] = useState([]);
    const [images, setImages] = useState([]);

    const getSaleServiceReqSummary = () => {
        let apiOptions: any = {
            url: `servicerequest/${props.serviceRequestId}/sale`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            getSummaryCallBack(result);
        });
    }

    const getSummaryCallBack = (res) => {
        setServiceRequest(res.serviceRequest);
        setSale(res.sale);
        let itemDetails = _.map((_.groupBy(res.saleItemDetails, function (i) {
            return i.saleItemGroupName
        })), function (items, key) { return { key: key, saleItems: items }; });
        setSaleItemDetails(itemDetails);
        for (let item of itemDetails) {
            for (let saleItem of item.saleItems) {
                saleItem.imageUrl = general.getImageUrl(saleItem.imagePath);
            }
        }
        getSaleImages(res.sale.id);
    }

    const getSaleImages = (saleId) => {
        let inputData = {
            id: saleId
        };
        let apiOptions: any = {
            url: `sale/${inputData.id}/images`
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
            }
            setImages(result);
        });
    }

    const openMyServiceRequests = () => {
        let navData = {
            targetRoute: '/provider/providerservicerequests',
            targetTitle: 'My Service Request',
            sourceState: {},
            eventSource: 'providerservicerequests',
        };
        navigate(navData);
    }

    useEffect(() => {
        getSaleServiceReqSummary();
    }, []);

    return (
        <div className="salereq-summary-ui">
            <div>
                <div className="successicon text-center">
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
                        <path d="M1 6.83048L6.37273 12L17 1" stroke="#73B934" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </div>
                <div className="font-20 mt-4 mb-2">Success!</div>
                <div className="successfullymsg">
                    <span>Your sale request have been submitted.</span>
                    <span>Thank you.</span>
                </div>

                {serviceRequest.stallId > 0 && props.subscriptionStatus == 'InActive' &&
                    <div className='m-3'>
                        <span className='text-danger'>
                            <b>You do not have active subscription for the stall {serviceRequest.stallName}. Please contact
                                administrator.</b>
                        </span>
                    </div>
                }
                {serviceRequest.stallName &&
                    <div className='mb-2 text-center font-18 color-primary stallname'>
                        <span>{serviceRequest.stallName}</span>
                    </div>
                }

                <div className='col-12'>
                    {(images && images.length > 0) &&
                        <Carousel>
                            {
                                images && images.map((itemObj) => (
                                    <div className="text-center">
                                        <img src={itemObj.imageUrl} alt="image" width={350} height={350}></img>
                                    </div>
                                ))}
                        </Carousel>
                    }
                    {images.length == 0 &&
                        <div className="text-center">
                            <img src={dummyImage} alt="dummy" width={350} height={350}></img>
                        </div>
                    }
                </div>

                <div className='py-2'>
                    <span className='pl-4'>
                        <i className="fas fa-map-marker-alt pr-1"></i>
                        <span>{sale.stallAddress}</span>
                    </span>
                </div>
                <div className='py-2 pl-2'>
                    <span className='col-3'>
                        <i className="fas fa-user pr-1"></i>
                        {serviceRequest.requestedByName}</span>
                    {/* <span className="col-6">
                        <DateIcon fontSize="inherit" />
                        <FxDateDisplay value={sale.startDate} />
                        {sale.startTime &&
                            <span className="pl-1">
                                <i className="far fa-clock color-aaa px-1"></i>
                                <FxDateTimeDisplay value={sale.startTime} displayType="time" />
                            </span>
                        }
                    </span>
                    <span className="col-6">
                        <DateIcon fontSize="inherit" />
                        <FxDateDisplay value={sale.endDate} />
                        {sale.endTime &&
                            <span className="pl-1">
                                <i className="far fa-clock color-aaa px-1"></i>
                                <FxDateTimeDisplay value={sale.endTime} displayType="time" />
                            </span>
                        }
                    </span> */}
                </div>
                <div className="col-12 ml-2 pt-3">
                    <b>{sale.title}</b>
                </div>
                <div className="col-12 ml-2">
                    {sale.description &&
                        <span>{sale.description}</span>
                    }
                </div>

                {saleItemDetails && saleItemDetails.map((itemObj: any) => (
                    <div className="col-12 pl-2 pt-5">
                        <div className='pl-3'> {itemObj.key}</div>
                        {itemObj.saleItems && itemObj.saleItems.map((item) => (
                            <div className='row listitem'>
                                <div className="col-3">
                                    {!item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                                    {item.imageUrl && <img src={item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
                                </div>
                                <div className="row col-7">
                                    <span className='col-10'>{item.itemName}</span>
                                    <span className="col-2 float-right">
                                        <FxCurrency value={item.price} />
                                    </span>
                                    <span className='col-12'>{item.description}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="row p-4">
                <Button variant="contained" size="small" color="primary" fullWidth
                    onClick={openMyServiceRequests} >
                    Click here to view my service requests
                </Button>
            </div>
        </div>

    );
}
export default SaleRequestSummary;