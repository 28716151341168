import React, { useEffect, useState } from 'react';
import './StallBookingEntry.scss';
import { Breadcrumbs, Typography, Button, Drawer, makeStyles } from '@material-ui/core';
import { http, general, formValidator, localstorage, FxDate, FxDateDisplay, FxInput, FxTextarea, FxCurrency, FxMobile, formatService, AlertService } from '../../fx-core';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import LoginContainer from '../../LoginContainer/LoginContainer';
import { PaymentMethod } from '../../OrderConfirmation/PaymentMethod/PaymentMethod';
import { Coupons } from '../../OrderConfirmation/Coupons/Copuons';
import { nanoid } from 'nanoid';
import useRazorpay from '../../utils/useRazorPay';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const StallBookingEntry = (props) => {
  const classes = styles();
  const login = useSelector<any, any>(s => s.login);
  const Razorpay = useRazorpay();

  let defaultBooking: any = {
    name: "",
    stallId: -1,
    durationInMins: 0,
    bookingTypeId: 1,
    pricePerSeat: 0,
    unit: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    maxCapacityPerSlot: 0,
    maxSeatPerBooking: 0,
    stopBookingBeforeNDays: 0,
    stopBookingMessage: "",
    id: -1
  };
  let defaultItem: any = {
    bookingDate: "",
    bookingTime: "",
    seatCount: "",
    contactName: "",
    requestNotes: "",
    mobile: "",
    countryCode: "+91",
    bookingTimeDisplay: "",
    totalAmount: 0,
    paymentMode: "razorpay",
    userCouponId: 0,
    couponId: 0,
    couponValueType: 2, //2-Value
    couponValue: 0
  };

  const allPaymentOptions = [
    { text: 'Cash', name: 'paymentoption', value: 'cod', checked: false },
    { text: 'Razorpay (UPI, Credit/Debit Card, NetBanking)', name: 'paymentoption', value: 'razorpay', checked: true }
  ];

  const [booking, setBooking] = useState(defaultBooking);
  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [openLogin, setOpenLogin] = useState(false);
  const [dayWiseBookingHours, setDayWiseBookingHours] = useState([]);
  const [selSlotUid, setSelSlotUid] = useState(0);
  const [countryCode, setCountryCode] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [allowBooking, setAllowBooking] = useState(true);
  const [bookingErrorMsg, setBookingErrorMsg] = useState("");

  const showBookingDetails = () => {
    props.showBookingDetails();
  };

  const getBookingById = () => {
    let apiOptions: any = {
      url: `portal/stall/booking/${props.bookingId}`,
      data: {}
    };
    http.get(apiOptions).then(res => {
      getBookingByIdCallBack(res);
    });
  };

  const getBookingByIdCallBack = (res) => {
    let item: any = res.data;
    item.imageUrl = general.getImageUrl(item.imagePath);
    if (item.bookingTypeId == 2) {
      item.startTime = formatService.getConvertedTimeForDisplay(item.startTime);
      item.endTime = formatService.getConvertedTimeForDisplay(item.endTime);

      const currentDate = formatService.getCurrentDateForServer();
      const selectedDate = formatService.getDateStringForServer(item.startDate);
      const stopBeforNDate = formatService.substractDays(selectedDate, item.stopBookingBeforeNDays);
      let isValid = false;
      let errorMsg = "";
      if (item.stopBookingBeforeNDays > 0) {
        if (currentDate < stopBeforNDate) {
          isValid = true;
        }
        else {
          errorMsg = "stopped " + (item.stopBookingBeforeNDays == 1 ? " 1 day" : item.stopBookingBeforeNDays + " days");
        }
      }
      if (!isValid) {
        setAllowBooking(false);
        const msg = item.stopBookingMessage;
        if (msg && msg.length > 0) {
          setBookingErrorMsg(msg);
        }
        else {
          const general_msg = "Booking " + errorMsg + " prior. Kindly contact stall owner for further details.";
          setBookingErrorMsg(general_msg);
        }
      }
    }
    if (item.maxCapacityPerSlot == 1) {
      handleItemChange({ seatCount: 1 });
    }
    setBooking(item);
  };

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
    if (item.name == "bookingDate" && item.value) {
      handleDayWiseBookingHours(item.value);
    }
  };

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getValidationMap = () => {
    let validationMap: any = {};
    if (booking.bookingTypeId == 1) {
      validationMap.bookingDate = ["required", "pastdate"];
    }
    validationMap.seatCount = ["number"];
    validationMap.contactName = ["required"];
    validationMap.mobile = ["required", "mobileno"];
    return validationMap;
  };

  const getInputValidations = (name) => {
    let validationMap = getValidationMap();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidationMap();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };

  const navToLogin = () => {
    setOpenLogin(true);
  };

  const handleLoginClose = () => {
    setOpenLogin(false);
  };

  const onLoginCallBack = () => {
    handleLoginClose();
  };

  const submitRequest = () => {
    if (!login.isLogin) {
      navToLogin();
      return;
    }
    const isValid = bulkValidate();
    if (isValid) {
      if (booking.bookingTypeId == 1 && !item.bookingTime) {
        AlertService.showErrorMsg("Please select the slot.");
        return;
      }
      if (item.seatCount > booking.maxSeatPerBooking) {
        AlertService.showErrorMsg("Max seat count per booking is " + booking.maxSeatPerBooking);
        return;
      }
      getPaymentOptions(booking.stallId);
      getAvailableCoupons(booking.stallId);
      handleItemChange({ totalAmount: item.seatCount * booking.pricePerSeat });
      setCurrentStep(2);
    }
  };

  const backToRequest = () => {
    setCurrentStep(1);
  };

  const bookRequest = () => {
    let bookingDate = "";
    let bookingTime = "";
    if (booking.bookingTypeId == 1) { // 1-Recurring
      bookingDate = formatService.getDateStringForServer(item.bookingDate);
      bookingTime = formatService.getTimeString(item.bookingTime)
    }
    else if (booking.bookingTypeId == 2) { //2- One Off
      bookingDate = formatService.getDateStringForServer(booking.startDate);
      bookingTime = formatService.getTimeString(booking.startTime)
    }
    let inputData = {
      communityId: localstorage.getItem("communityId"),
      stallId: booking.stallId,
      stallBookingId: booking.id,
      userId: localstorage.getItem("userId"),
      bookingDate: bookingDate,
      bookingTime: bookingTime,
      seatCount: item.seatCount,
      contactName: item.contactName,
      contactNumber: item.countryCode + item.mobile,
      requestNotes: item.requestNotes,
      pricePerSeat: booking.pricePerSeat,
      paymentMode: item.paymentMode,
      userCouponId: item.userCouponId,
      couponId: item.couponId
    };
    let apiOptions: any = {
      url: `stallbookingrequest/book`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      bookRequestCallBack(res);
    });
  };

  const bookRequestCallBack = (res) => {
    const id = res.data;
    AlertService.showSuccessMsg();
    if (item.paymentMode == 'razorpay') {
      initiatePayment(id);
    }
    else {
      showBookingDetails();
    }
  };

  const initiatePayment = (id) => {
    let apiOptions: any = {
      url: `stallbookingrequest/GetPaymentLink/${id}`
    };
    http.get(apiOptions).then(response => {
      if(response && response.data && response.data.result) {
        openPaymentLink(response);
      }
      showBookingDetails();
    });
  }

  const openPaymentLink = (resp: any) => {
    var item =  resp.data.result;
    let externalOrderId = item.externalOrderId;
    let description = item.description;
    let customerName = item.name;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {      
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        showBookingDetails();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `stallbookingrequest/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const handleDayWiseBookingHours = (selDate) => {
    setBookingErrorMsg("");
    const currentDate = formatService.getCurrentDateForServer();
    let isPastDate = formatService.isBefore(currentDate, selDate);
    if (!isPastDate) {
      const selectedDate = formatService.getDateStringForServer(selDate);
      const allowBeforNDate = formatService.substractDays(selectedDate, booking.allowBookingBeforeNDays);
      const stopBeforNDate = formatService.substractDays(selectedDate, booking.stopBookingBeforeNDays);
      let isValid = false;
      let errorMsg = "";
      if (booking.allowBookingBeforeNDays > 0 && booking.stopBookingBeforeNDays > 0) {
        if (currentDate >= allowBeforNDate && currentDate <= stopBeforNDate) {
          isValid = true;
        }
        else {
          errorMsg = "stopped " + (booking.stopBookingBeforeNDays == 1 ? " 1 day" : booking.stopBookingBeforeNDays + " days");
        }
      }
      else if (booking.allowBookingBeforeNDays > 0) {
        if (currentDate >= allowBeforNDate) {
          isValid = true;
        }
        else {
          errorMsg = "allowed " + (booking.allowBookingBeforeNDays == 1 ? " 1 day" : booking.allowBookingBeforeNDays + " days");
        }
      }
      else if (booking.stopBookingBeforeNDays > 0) {
        if (currentDate <= stopBeforNDate) {
          isValid = true;
        }
        else {
          errorMsg = "stopped " + (booking.stopBookingBeforeNDays == 1 ? " 1 day" : booking.stopBookingBeforeNDays + " days");
        }
      }
      if (isValid) {
        getAvailableSlots(selDate);
      }
      else {
        setDayWiseBookingHours([]);
        const msg = booking.stopBookingMessage;
        if (msg && msg.length > 0) {
          setBookingErrorMsg(msg);
        }
        else {
          const general_msg = "Booking " + errorMsg + " prior. Kindly contact stall owner for further details.";
          setBookingErrorMsg(general_msg);
        }
      }
    }
    else {
      setDayWiseBookingHours([]);
    }
  };

  const getAvailableSlots = (selDate) => {
    let inputData = {
      bookingDate: formatService.getDateStringForServer(selDate),
      stallBookingId: booking.id,
      duration: booking.durationInMins,
      maxCapacityPerSlot: booking.maxCapacityPerSlot
    };
    let apiOptions: any = {
      url: `portal/booking/slot/available`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAvailableSlotsCallBack(res);
    });
  };

  const getAvailableSlotsCallBack = (res) => {
    let result = res.data;
    for (let item of result) {
      item.uid = nanoid();
      item.startTime = formatService.getConvertedTimeForDisplay(item.startTime);
      item.endTime = formatService.getConvertedTimeForDisplay(item.endTime);
    }
    setDayWiseBookingHours(result);
  };

  const onSelectHour = (item) => {
    if (item.uid != selSlotUid) {
      let bookingTimeDisplay = formatService.getConvertedTimeForDisplay(item.startTime);
      handleItemChange({ bookingTime: item.startTime, bookingTimeDisplay });
      setSelSlotUid(item.uid);
    }
    else if (item.uid == selSlotUid) {
      handleItemChange({ bookingTime: "", bookingTimeDisplay: "" });
      setSelSlotUid(0);
    }
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        CountryCode: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      initLookupCallBack(res);
    })
  };

  const initLookupCallBack = (res) => {
    let result = res.data;
    setCountryCode(result.CountryCode);
  };

  const getPaymentOptions = (stallId) => {
    let apiOptions: any = {
      url: `paymentoption/active/stall/${stallId}`
    };
    http.get(apiOptions).then(res => {
      getPaymentsCallBack(res);
    })
  };

  const getPaymentsCallBack = (res) => {
    let paymentOptionList = res.data;
    let paymentModes: any = [];
    for (let item of paymentOptionList) {
      let lookupItem = _.find(allPaymentOptions, { value: item.paymentOptionCode });
      if (lookupItem) {
        paymentModes.push(lookupItem);
      }
    }
    setPaymentOptions(paymentModes);
  };

  const onPaymentModeChange = (selected) => {
    const data = { paymentMode: selected.value };
    handleItemChange(data);
    let tmpPayModes: any = paymentOptions.map((item: any) => {
      if (item.value === selected.value) {
        item.checked = true;
      }
      else {
        item.checked = false;
      }
      return item;
    });
    setPaymentOptions(tmpPayModes);
  };

  const getAvailableCoupons = (stallId) => {
    let inputData = {
      entityTypeId: 3, // 3-Stall
      entityId: stallId
    };
    let apiOptions: any = {
      url: `coupon/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getAvailableCouponsCallBack(res);
    })
  };

  const getAvailableCouponsCallBack = (res) => {
    setCouponList(res.data);
  };

  const selectedCoupon = (coupon) => {
    let couponObj: any = {};
    if (item.userCouponId == coupon.id || item.couponId == coupon.id) {
      couponObj.userCouponId = 0;
      couponObj.couponValue = 0;
      couponObj.couponId = 0;
      couponObj.couponValueTypeId = 2;
    }
    else {
      couponObj.userCouponId = coupon.userCouponId;
      couponObj.couponValue = coupon.couponValue;
      couponObj.couponId = coupon.couponId;
      couponObj.couponValueTypeId = coupon.couponValueTypeId;
    }
    handleItemChange(couponObj);
  };

  useEffect(() => {
    initLookup();
    getBookingById();
  }, []);

  return (
    <div className='bookingentry-ui'>
      <div className="title-font pt-2 mt-1">
        <Breadcrumbs aria-label="breadcrumb">
          <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={showBookingDetails}>
            <b>All Bookings</b>
          </span>
          <Typography color="textPrimary" className="breadcrumbs-style">{booking.name}</Typography>
        </Breadcrumbs>
      </div>
      {/* Desktop View */}
      <div className="deskview">
        {
          currentStep == 1 &&
          <div>
            <div className="col-12 row pt-2">
              <div className='col-6'>
                <div className='p-2 row justify-content-between'>
                  <div>
                    <span className='bold-text pl-3'>Total Time : </span>
                    <span className='pl-2'>{booking.durationInMins} mins</span>
                  </div>
                  <div>
                    {
                      !booking.unit &&
                      <span className='bold-text pl-3'>Price : </span>
                    }
                    {
                      booking.unit &&
                      <span className='bold-text pl-3'>Price / {booking.unit} : </span>
                    }
                    <span className='pl-2'>
                      <FxCurrency value={booking.pricePerSeat} />
                    </span>
                  </div>
                </div>
                {
                  booking.bookingTypeId == 1 &&
                  <div>
                    <div className='p-3'>
                      <FxDate
                        name="bookingDate" variant="standard" size="small" label="Booking Date" fullWidth
                        value={item.bookingDate} onValueChange={handleInputChange}
                      />
                      {
                        hasError("bookingDate", "required") &&
                        <div className="error">Booking Date is required</div>
                      }
                      {
                        hasError("bookingDate", "pastdate") &&
                        <div className="error">Booking Date should be future date</div>
                      }
                    </div>
                    <div className='p-3'>
                      {
                        dayWiseBookingHours.length > 0 && dayWiseBookingHours.map((h: any, i) => (
                          <span className="mt-1 col-4 pt-2 pr-2 pl-1" key={i}>
                            <button className={h.uid == selSlotUid ? "slot-btn slot-btn-active" : "slot-btn"}
                              onClick={() => onSelectHour(h)}>
                              {h.startTime} - {h.endTime}
                            </button>
                          </span>
                        ))
                      }
                      {
                        item.bookingDate && dayWiseBookingHours.length == 0 &&
                        <div className='text-center'>
                          {
                            bookingErrorMsg &&
                            <div className='error'>{bookingErrorMsg}</div>
                          }
                          <span>No slots available...</span>
                        </div>
                      }
                    </div>
                  </div>
                }
                {
                  booking.bookingTypeId == 2 &&
                  <div>
                    <div className='p-3'>
                      <div className='row'>
                        <span className='pr-2 text-bold'>Start</span>
                        <FxDateDisplay
                          value={booking.startDate}
                        />
                        <span className='pl-2'>
                          <i className="far fa-clock color-aaa px-1"></i>
                          {booking.startTime}
                        </span>
                      </div>
                      <div className='row'>
                        <span className='pr-3 text-bold'>End</span>
                        <FxDateDisplay
                          value={booking.endDate}
                        />
                        <span className='pl-2'>
                          <i className="far fa-clock color-aaa px-1"></i>
                          {booking.endTime}
                        </span>
                      </div>
                    </div>
                    {
                      !allowBooking &&
                      <div className="error">
                        {bookingErrorMsg}
                      </div>
                    }
                  </div>
                }
              </div>
              <div className='col-6'>
                <div className='p-3'>
                  <FxInput name="contactName" variant="outlined" size="small" label="Contact Name" fullWidth
                    value={item.contactName} onValueChange={handleInputChange} />
                  {
                    hasError("contactName", "required") &&
                    <div className="error">Contact Name is required</div>
                  }
                </div>
                <div className='p-3'>
                  <FxMobile value={item.mobile} onValueChange={handleInputChange} countryCode={countryCode} />
                  {
                    hasError("mobile", "required") &&
                    <div className="error">Contact Number is required</div>
                  }
                  {
                    hasError("mobile", "mobileno") &&
                    <div className="error">Please enter valid mobile number</div>
                  }
                </div>
                {
                  booking.maxCapacityPerSlot > 1 &&
                  <div className='p-3'>
                    <FxInput name="seatCount" type="number" variant="outlined" size="small" label="Seat Count" fullWidth
                      value={item.seatCount} onValueChange={handleInputChange} />
                    {
                      hasError("seatCount", "number") &&
                      <div className="error">Seat Count is required</div>
                    }
                  </div>
                }
                <div className='p-3'>
                  <FxTextarea name="requestNotes" variant="outlined" size="small" label="Notes" fullWidth
                    value={item.requestNotes} onValueChange={handleInputChange} />
                </div>
              </div>
            </div>
            <div className='text-center'>
              <Button color="primary" variant="contained" onClick={submitRequest} disabled={!allowBooking}><b>Submit</b></Button>
            </div>
          </div>
        }
        {
          currentStep == 2 &&
          <div>
            <div className="col-12 row pt-2">
              <div className="col-12 row py-3 ml-1">
                <h4>Booking Confirmation</h4>
              </div>
              <div className='col-4'>
                {
                  item.totalAmount > 0 &&
                  <div>
                    <div>
                      <PaymentMethod
                        paymentOptions={paymentOptions}
                        paymentMode={item.paymentMode}
                        onPaymentModeChange={onPaymentModeChange}
                      />
                    </div>
                    <div>
                      <Coupons
                        couponList={couponList}
                        selectedCoupon={selectedCoupon}
                        selectedUserCouponId={item.userCouponId}
                        selectedCouponId={item.couponId}
                      />
                    </div>
                  </div>
                }
              </div>
              <div className='col-8'>
                <h5>Booking Summary</h5>
                <div className='row pt-1'>
                  <div className='col-6'>
                    <span className='color-grey'>Total Time : </span>
                    <span className='pl-2 bold-text'>{booking.durationInMins} mins</span>
                  </div>
                  <div className='col-6'>
                    {
                      !booking.unit &&
                      <span className='color-grey'>Price : </span>
                    }
                    {
                      booking.unit &&
                      <span className='color-grey'>Price / {booking.unit} : </span>
                    }
                    <span className='pl-2 bold-text'>
                      <FxCurrency value={booking.pricePerSeat} />
                    </span>
                  </div>
                </div>
                {
                  booking.bookingTypeId == 1 &&
                  <div className='row pt-2'>
                    <div className='col-6'>
                      <span className='color-grey'>Booking Date : </span>
                      <span className='pl-2 bold-text'>
                        <FxDateDisplay value={item.bookingDate} />
                      </span>
                    </div>
                    <div className='col-6'>
                      <span className='color-grey'>Booking Time : </span>
                      <span className='pl-2 bold-text'>{item.bookingTimeDisplay}</span>
                    </div>
                  </div>
                }
                {
                  booking.bookingTypeId == 2 &&
                  <div className='row pt-2'>
                    <div className='col-6'>
                      <span className='color-grey'>Booking Date : </span>
                      <span className='pl-2 bold-text'>
                        <FxDateDisplay value={booking.startDate} />
                      </span>
                    </div>
                    <div className='col-6'>
                      <span className='color-grey'>Booking Time : </span>
                      <span className='pl-2 bold-text'>{booking.startTime}</span>
                    </div>
                  </div>
                }
                <div className='row pt-2'>
                  <div className='col-6'>
                    <span className='color-grey'>Contact Name : </span>
                    <span className='pl-2 bold-text'>{item.contactName}</span>
                  </div>
                  <div className='col-6'>
                    <span className='color-grey'>Contact Number : </span>
                    <span className='pl-2 bold-text'>{item.countryCode} {item.mobile}</span>
                  </div>
                </div>
                <div className='row pt-2'>
                  <div className='col-6'>
                    <span className='color-grey'>Seat Count : </span>
                    <span className='pl-2 bold-text'>{item.seatCount}</span>
                  </div>
                  <div className='col-6'>
                    <span className='color-grey'>Amount : </span>
                    <span className='pl-2 bold-text'><FxCurrency value={item.totalAmount} /></span>
                  </div>
                </div>
                <div className='pt-3 pb-5'>
                  <span className='color-grey'>Notes : </span>
                  <span className='pl-2'>{item.requestNotes}</span>
                </div>
              </div>
            </div>
            <div className='col-12 text-center mt-2'>
              <Button className='mr-3' variant="outlined" onClick={backToRequest}><b>Back</b></Button>
              <Button color="primary" variant="contained" onClick={bookRequest}><b>Book Appointment</b></Button>
            </div>
          </div>
        }
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openLogin} onClose={handleLoginClose}>
        <LoginContainer handleLoginClose={handleLoginClose} onLoginCallBack={onLoginCallBack} />
      </Drawer>
    </div >
  );
};

export default StallBookingEntry;