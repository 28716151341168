import React, { useEffect, useState } from 'react';
import './MyBookingOrderDetail.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FxCurrency, http, general, formatService } from '../../fx-core';
import _ from "lodash";

const MyBookingOrderDetail = (props: any) => {

  let defaultBookingRequest = {
    bookingDate: "",
    bookingTime: "",
    seatCount: "",
    contactName: "",
    contactNumber: "",
    bookingRequestIdentifier: "",
    pricePerSeat: "",
    amount: "",
    stallName: "",
    bookingPayments: []
  }
  const [bookingRequest, setBookingRequest] = useState(defaultBookingRequest);

  const getBookingRequestById = () => {
    let inputData = {
      id: props.id
    };

    let apiOptions: any = {
      url: `stallbookingrequest/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      getBookingRequestByIdCallBack(res);
    });
  }

  const getBookingRequestByIdCallBack = (res) => {
    let result = res.data;
    result.bookingTime = formatService.getConvertedTimeForDisplay(result.bookingTime);
    setBookingRequest(result);
  }

  const handleClose = () => {
    props.handleClose();
  }

  useEffect(() => {
    getBookingRequestById();
  }, []);

  const body = (
    <div className='my-booking-order-detail'>
      <div className="row col-12 title-font py-1 mx-auto">
        <div className="col-11 pl-0">
          <span>Booking Detail</span>
        </div>
        <div className="col-1 text-right pointer px-0" onClick={handleClose}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>


      {/* Desktop View */}
      <div className='deskview'>
        <div className='row mr-3 my-3 ml-0'>
          <div className='col-12 summary-detail font-14'>
            <div className='row'>
              <div className='col-4'>Booking #</div>
              <div className='col-8'>: {bookingRequest.bookingRequestIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Booking Date</div>
              <div className='col-8'>: {general.getFormattedDate(bookingRequest.bookingDate)}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Booking Time</div>
              <div className='col-8'>: {bookingRequest.bookingTime}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Contact Name</div>
              <div className='col-8'>: {bookingRequest.contactName}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Contact Number</div>
              <div className='col-8'>: {bookingRequest.contactNumber}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Seat Count</div>
              <div className='col-8'>: {bookingRequest.seatCount}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Booking Total</div>
              <div className='col-8'>: <FxCurrency value={bookingRequest.amount} /></div>
            </div>
            {bookingRequest.bookingPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-4'>Amount</div>
                  <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>Payment Mode</div>
                  <div className='col-8'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'stripe' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span className="pr-1">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>Payment Status</div>
                  <div className='col-8'>
                    : <span>{item.paymentStatus}
                      {item.paymentMode == 'stripe' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                        <span>
                          Pay
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default MyBookingOrderDetail;