import React, { useEffect, useState } from 'react';
import './StallFilters.scss';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { http, localstorage } from '../../fx-core';

const StallFilters = (props: any) => {
    let defaultItem: any = {
        categoryIds: props.defaultItem.categoryIds,
        selectedCategory: false,
        isDeliveryEnabled: props.defaultItem.isDeliveryEnabled,
        isPickupEnabled: props.defaultItem.isPickupEnabled
    };

    const [item, setItem] = useState(defaultItem);
    const [categories, setCategories] = useState([]);

    const handleCheckbox = (event) => {
        if (event.target.name == "selectedCategory") {
            let selectedValue = parseInt(event.target.value);
            if (event.target.checked) {
                item.categoryIds.push(selectedValue);
            }
            else {
                let itemIdx = item.categoryIds.indexOf(selectedValue);
                item.categoryIds.splice(itemIdx, 1);
            }
            for (let itemObj of categories) {
                if (itemObj.id == selectedValue) {
                    itemObj.selectedCategory = event.target.checked;
                }
            }
            handleItemChange({ "categoryIds": item.categoryIds });
        }
        else {
            handleItemChange({ [event.target.name]: event.target.checked });
        }

        props.callbackData({
            categoryIds: item.categoryIds,
            isDeliveryEnabled: event.target.name == "isDeliveryEnabled" ? event.target.checked : item.isDeliveryEnabled,
            isPickupEnabled: event.target.name == "isPickupEnabled" ? event.target.checked : item.isPickupEnabled
        });
    }

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookUp = () => {
        getCategories();
    }

    const getCategories = () => {
        let inputData = {
            serviceId: props.serviceId,
            communityId: parseInt(localstorage.getItem('communityId'))
        }
        let apiOptions: any = {
            url: `portal/category/active`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.categories;
            if (item.categoryIds.length == 0) {
                setCategories(result);
            }
            else {
                computeSelectedCategory(result);
            }
        });
    }

    const computeSelectedCategory = (categories) => {
        for (let itemObj of categories) {
            for (let catgId of item.categoryIds) {
                if (itemObj.id == catgId) {
                    itemObj.selectedCategory = true;
                }
            }
        }
        setCategories(categories);
    }

    useEffect(() => {
        initLookUp();
    }, []);

    return (
        <div className="shopFilters-ui pt-5 px-3">
            <div className="row">
                <div className="col-1">
                    <i className="fas fa-filter pt-2"></i>
                </div>
                <div className="col-10 pl-2 font-20">
                    <b>Filters</b>
                </div>
            </div>
            <div>
                {
                    categories.length > 0 &&
                    <div className="font-19 text-medium pt-3">
                        Categories
                    </div>
                }

                {
                    categories.length > 0 && categories.map((item, i) => (
                        <div className="font-14">
                            <Checkbox name="selectedCategory" color="primary" value={item.id} checked={item.selectedCategory} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon
                                fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                            {item.categoryName}
                        </div>
                    ))
                }
                {/* <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    North Indian Cuisine
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    South Indian Cuisine
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Asian Cuisine
                </div> */}
            </div>
            <div>
                <div className="font-19 text-medium pt-3">
                    Delivery Type
                </div>
                <div className="font-14">
                    <Checkbox name="isDeliveryEnabled" color="primary" value={item.isDeliveryEnabled} checked={item.isDeliveryEnabled}
                        onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Delivery
                </div>
                <div className="font-14">
                    <Checkbox name="isPickupEnabled" color="primary" value={item.isPickupEnabled} checked={item.isPickupEnabled}
                        onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Pickup
                </div>
            </div>
        </div>
    );
}
export default StallFilters;