import { Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FxInput, FxTextarea, http, LocationSearchInput, AlertService, localstorage, formValidator, FxSelect, FxCurrency } from '../../fx-core';
import { FxRadioGroup } from '../../fx-core/FxRadioGroup/FxRadioGroup';
import './LocalDrForm.scss';
import Radio from '@material-ui/core/Radio';
import useRazorpay from '../../utils/useRazorPay';

const LocalDrForm = (props) => {
  const Razorpay = useRazorpay();
    
  const defaultItem = {
    localBusinessCategoryId: 0,
    title: "",
    shortDescription: "",
    longDescription: "",
    address: "",
    contactName: "",
    contactEmail: "",
    contactMobile: "",
    isShowPhone: false,
    isAcceptCalls: false,
    isAcceptText: false,
    isOkForOthersToContact: false,
    isListingInCommkit: false,
    isPremium: false,
    id: props.localBusinessDirectoryId ? props.localBusinessDirectoryId : 0,
    communityId: parseInt(localstorage.getItem("communityId")),
    userId: localstorage.getItem("userId") ? parseInt(localstorage.getItem("userId")) : 0,
    localBusinessCategoryText: "",
    otherCategory: "",
    stallId: -1,
    paymentMode: '-1',
    couponCode: "",
    paymentStatus: "Pending",
    postStatusId: 1
  };
  const [item, setItem] = useState(defaultItem);
  const lookup = {
    catgories: [
      {
        code: "partners",
        text: "Activity Partners"
      },
      {
        code: "artists",
        text: "Artists"
      },
      {
        code: "childcare",
        text: "Child Care"
      },
      {
        code: "generalcommunity",
        text: "General Community"
      },
      {
        code: "groups",
        text: "Groups"
      },
      {
        code: "newsviews",
        text: "Local News & Views"
      },
      {
        code: "lostfound",
        text: "Lost & Found"
      },
      {
        code: "missedconnections",
        text: "Missed Connections"
      },
      {
        code: "musicians",
        text: "Musicians"
      },
      {
        code: "pets",
        text: "Pets"
      },
      {
        code: "politics",
        text: "Politics"
      },
      {
        code: "rantsraves",
        text: "Rants & Raves"
      },
      {
        code: "rideshare",
        text: "Share Ride"
      },
      {
        code: "volunteers",
        text: "Volunteers"
      }
    ],
    emailOptions: [
      {
        code: "CLmail",
        text: "CL mail relay (Recommended)"
      },
      {
        code: "noReplies",
        text: "No replies to this email"
      }
    ]
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [otherCatgError, setOtherCatgError] = useState("");
  const [stalls, setStalls] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [amount, setAmount] = useState(0);

  const initLookup = () => {
    let inputData = {
      lookups: {
        LocalBusinessCategory: { default: false },
        ProviderStalls: { default: true },
        PaymentMode: { default: true }
      },
      filters: {
        userId: props.ownerId,
        communityId: parseInt(localstorage.getItem("communityId"))
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initLookupCallBack(response);
    })
  }

  const initLookupCallBack = (res) => {
    let result = res.data;
    for (let item of result.LocalBusinessCategory) {
      item.checked = false;
    }
    setCategories(result.LocalBusinessCategory);
    setStalls(result.ProviderStalls);
    setPaymentOptions(result.PaymentMode);
    if (props.localBusinessDirectoryId > 0) {
      setCurrentStep(2);
      getLocalBusinessById();
    }
  };

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
    if (event.target.name == 'isShowPhone' && event.target.checked == false) {
      handleItemChange({ "isAcceptCalls": false });
      handleItemChange({ "isAcceptText": false });
    }
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const onChangeCategory = (item) => {
    const data = { localBusinessCategoryId: item.id };
    handleItemChange(data);
    handleItemChange({ "localBusinessCategoryText": item.text });
    let tmpCategories: any = categories.map((itemObj: any) => {
      if (itemObj.id === item.id) {
        itemObj.checked = true;
      }
      else {
        itemObj.checked = false;
      }
      return itemObj;
    });
    setCategories(tmpCategories);
  };

  const getCategory = () => {
    if (!item.localBusinessCategoryId) {
      AlertService.showErrorMsg("Please select category");
      return;
    }
    if (item.localBusinessCategoryId == 9999 && !item.otherCategory) {
      setOtherCatgError("Other Category is required");
      return;
    }
    setCurrentStep(2);
  };

  const closeForm = () => {
    props.handleForm();
  }

  //Validation area starts
  const validationMap_Item = {
    title: ["required"],
    shortDescription: ["required"],
    longDescription: ["required"],
    address: ["required"],
    contactName: ["required"],
    contactEmail: ["required"],
    contactMobile: ["required"],
    paymentMode: ["select"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const addressSelected = (data) => {
    let inputData = { address: data.address, lat: data.lat, lng: data.lng };
    console.log(data);
    handleItemChange(inputData);
  };

  const onReceiveOtherDetails = (data) => {
    let inputData = { country: data.country, pinCode: data.pinCode };
    handleItemChange(inputData);
  }

  const saveLocalBusinessForm = () => {
    const isValid = bulkValidate();
    if (isValid) {
      if (item.isListingInCommkit && item.stallId == -1) {
        AlertService.showErrorMsg("Please select stall");
        return;
      }
      if (item.id > 0) {
        updateLocalBusinessForm();
      }
      else {
        addLocalBusinessForm();
      }
    }
  };

  const addLocalBusinessForm = () => {
    let apiOptions: any = {
      url: `localbusinessdirectory/add`,
      data: item
    };
    http.post(apiOptions).then(res => {
      saveLocalBusinessFormCallBack(res);
    });
  };

  const updateLocalBusinessForm = () => {
    let apiOptions: any = {
      url: `localbusinessdirectory/update`,
      data: item
    };
    http.put(apiOptions).then(res => {
      saveLocalBusinessFormCallBack(res);
    });
  };


  const saveLocalBusinessFormCallBack = (res) => {
    AlertService.showSuccessMsg();
    if(item.paymentMode == 'razorpay' && item.paymentStatus == "Pending") {
      initiatePayment(res.data);
    }
    else {
      closeForm();
    }
  }

  const getLocalBusinessById = () => {
    let apiOptions: any = {
      url: `localbusinessdirectory/${props.localBusinessDirectoryId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.paymentMode = result.paymentMode != null ? result.paymentMode : "-1";
      setItem(res.data);
    });
  };

  const initiatePayment = (localDirectoryId) => {
    let inputData = {
      entityTypeId: 2,
      entityId: localDirectoryId
    };
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
      closeForm();
    });
  }

  const openPaymentLink = (resp: any) => {
    var item =  resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {        
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const getLBDAmount = (featureId) => {
    let apiOptions = {
      url: `coupon/feature/price/${featureId}`,
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      if (result && result.price > 0) {
        setAmount(result.price);
      }
      else {
        setAmount(0);
        AlertService.showErrorMsg("Price not configured.")
      }
    });
  }

  useEffect(() => {
    initLookup();
    getLBDAmount(2);
  }, []);


  return (
    <div className='local-dr-form'>
      {currentStep == 1 &&
        <div className="py-3 text-center drawer-spacing">
          <div className='pb-2 font-20 radio-header'>
            Please choose a category
          </div>
          {
            categories.length > 0 && categories.map((itemVal: any, i) => (
              <div className="d-flex align-items-center pl-3 font-14" key={i}>
                <FormControlLabel
                  name="localBusinessCategoryId"
                  value={itemVal.id}
                  control={<Radio color="primary" size="small" />}
                  label={itemVal.text}
                  checked={itemVal.checked}
                  onChange={() => onChangeCategory(itemVal)}
                />
              </div>
            ))
          }
          {item.localBusinessCategoryId == 9999 &&
            <div className='col-12 col-md-5 pt-2'>
              <div><b>Other Category</b></div>
              <FxInput name="otherCategory" variant="outlined" fullWidth size="small"
                value={item.otherCategory} onValueChange={handleInputChange} />

              {otherCatgError && (
                <div className="error">{otherCatgError}</div>
              )}
            </div>
          }

          <div className='pt-3 text-right fixed-btn'>
            <Button color="primary" variant="contained" onClick={getCategory}><b>Continue</b></Button>
          </div>
        </div>
      }

      {currentStep == 2 &&
        <div className="pt-3 pb-5 mt-4 drawer-spacing">
          <div className='row'>
            <div className='col-12 pb-2'>
              {item.localBusinessCategoryText &&
                <span>
                  <div><b>Category</b></div>
                  <div>{item.localBusinessCategoryId == 9999 &&
                    <span>{item.otherCategory}</span>
                  }
                    {item.localBusinessCategoryId != 9999 &&
                      <span>{item.localBusinessCategoryText}</span>
                    }
                  </div>
                </span>
              }

            </div>
            <div className='col-12 col-md-5 pt-2'>
              <div><b>Business Name</b></div>
              <FxInput name="title" variant="outlined" fullWidth size="small"
                value={item.title} onValueChange={handleInputChange} />

              {hasError("title", "required") && (
                <div className="error">Field is required</div>
              )}
            </div>
            {/* <div className='col-5'>
              <div><b>City or Neighbourhood</b></div>
              <FxInput name="city" value="" variant="outlined" fullWidth size="small" />
            </div> */}
            {/* <div className='col-2'>
              <div><b>Postal Code</b></div>
              <FxInput name="postalCode" value="" variant="outlined" fullWidth size="small" />
            </div> */}

            {/* <div className='col-12 col-md-7 pt-2'>
              <div><b>Address</b></div>
              {(item.id > 0 && item.address) &&
                <LocationSearchInput address={item.address} name="address"
                  fullWidth locationSelected={addressSelected} onReceiveOtherDetails={onReceiveOtherDetails}
                  placeholder="Address" />}

              {(item.id == 0 || !item.address) && <LocationSearchInput address={item.address} name="address"
                fullWidth locationSelected={addressSelected} onReceiveOtherDetails={onReceiveOtherDetails}
                placeholder="Address" />}
              {hasError("address", "required") && (
                <div className="error">Field is required</div>
              )}
            </div> */}

            <div className='col-12 col-md-7 pt-2'>
              <div><b>Address</b></div>
              <FxTextarea name="address" variant="outlined" fullWidth size="small" label="Address"
                value={item.address} onValueChange={handleInputChange} maxRows={4} />
              {hasError("address", "required") && (
                <div className="error">Field is required</div>
              )}
            </div>
          </div>

          <div className='row pt-3'>
            <div className='col-12'>
              <div><b>Short Description</b></div>
              <FxTextarea name="shortDescription" variant="outlined" size="small" fullWidth maxRows={4}
                value={item.shortDescription} onValueChange={handleInputChange} />
              {hasError("shortDescription", "required") && (
                <div className="error">Field is required</div>
              )}
            </div>
          </div>

          <div className='row pt-3'>
            <div className='col-12'>
              <div><b>Long Description</b></div>
              <FxTextarea name="longDescription" variant="outlined" size="small" fullWidth maxRows={10}
                value={item.longDescription} onValueChange={handleInputChange} />
              {hasError("longDescription", "required") && (
                <div className="error">Field is required</div>
              )}
            </div>
          </div>

          <div className='row pt-4 px-3'>
            <div className='col-12 field-container'>
              <div className='field-title'>
                Contact Info
              </div>
              <div className='row pr-2'>
                <div className='col-12 pt-2'>
                  <div><b>Email</b></div>
                  <FxInput name="contactEmail" value={item.contactEmail} onValueChange={handleInputChange}
                    variant="outlined" fullWidth size="small" />
                  {hasError("contactEmail", "required") && (
                    <div className="error">Field is required</div>
                  )}
                  {/* <div className='pt-3 pb-1 radio-header'>
                    Email privacy options
                  </div>
                  <FxRadioGroup
                    name="category"
                    options={lookup.emailOptions}
                    valueField="code"
                    displayField="text"
                    color="default"
                  /> */}
                </div>
                <div className='col-12 mt-4 row pr-0'>
                  {/* <div className='inner-field-title'>
                    Phone / Text
                  </div> */}
                  <div className='col-12 col-md-7'>
                    <div><b>Phone Number</b></div>
                    <FxInput name="contactMobile" value={item.contactMobile} onValueChange={handleInputChange}
                      variant="outlined" fullWidth size="small" />
                    {hasError("contactMobile", "required") && (
                      <div className="error">Field is required</div>
                    )}
                  </div>
                  <div className='col-12 col-md-5 pt-2 pr-0'>
                    <FormGroup row>
                      <FormControlLabel control={<Checkbox name="isShowPhone" value={item.isShowPhone}
                        checked={item.isShowPhone} onChange={handleCheckbox} />} label="Show my number" />
                    </FormGroup>
                  </div>
                </div>
                {/* <FormGroup row>
                    <FormControlLabel control={<Checkbox name="isShowPhone" value={item.isShowPhone}
                      checked={item.isShowPhone} onChange={handleCheckbox} />} label="Show my number" />
                    {!item.isShowPhone &&
                      <span>
                        <FormControlLabel disabled control={<Checkbox name="isAcceptCalls" value={item.isAcceptCalls}
                          checked={item.isAcceptCalls} />} label="Phone calls OK" />
                        <FormControlLabel disabled control={<Checkbox name="isAcceptText" value={item.isAcceptText}
                          checked={item.isAcceptText} />} label="Text/SMS OK" />
                      </span>
                    }
                    {item.isShowPhone &&
                      <span>
                        <FormControlLabel control={<Checkbox name="isAcceptCalls" value={item.isAcceptCalls}
                          checked={item.isAcceptCalls} onChange={handleCheckbox} />} label="Phone calls OK" />
                        <FormControlLabel control={<Checkbox name="isAcceptText" value={item.isAcceptText}
                          checked={item.isAcceptText} onChange={handleCheckbox} />} label="Text/SMS OK" />
                      </span>
                    }
                  </FormGroup> */}
                <div className='col-12 pt-2'>
                  <div><b>Contact Name</b></div>
                  <FxInput name="contactName" value={item.contactName} onValueChange={handleInputChange}
                    variant="outlined" fullWidth size="small" />
                  {hasError("contactName", "required") && (
                    <div className="error">Field is required</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='pt-4'>
            <FormGroup>
              <FormControlLabel control={<Checkbox name="isListingInCommkit" value={item.isListingInCommkit}
                onChange={handleCheckbox} checked={item.isListingInCommkit} />}
                label="Already on Localites" />
            </FormGroup>
          </div>
          {
            item.isListingInCommkit &&
            <div className='row pt-4 px-3'>
              <FxSelect name="stallId" variant="outlined" label="Select Stall"
                options={stalls}
                selectedValue={item.stallId}
                valueField="id"
                displayField="text"
                size="small"
                style={{ width: 250 }}
                onValueChange={handleInputChange} />
            </div>
          }

          <div className='row pt-4'>
            <div className='col-6'>
              <FxSelect name="paymentMode" variant="outlined" label="Select Payment"
                disabled={item.paymentStatus === "Paid"}
                options={paymentOptions}
                selectedValue={item.paymentMode}
                valueField="code"
                displayField="text"
                size="small"
                style={{ width: 250 }}
                onValueChange={handleInputChange} />
              {hasError("paymentMode", "select") && (
                <div className="error">Field is required</div>
              )}
            </div>
            {
              item.paymentMode == 'coupon' &&
              <div className='col-6'>
                <FxInput name="couponCode" variant="outlined" label="Coupon" fullWidth size="small"
                  value={item.couponCode} onValueChange={handleInputChange} disabled={item.paymentStatus === "Paid"} />
              </div>
            }
          </div>
          {
            item.paymentMode == "accounttransfer" && item.postStatusId == 1 &&
            <div className='row pt-1 col-8'>
              <div>A/C No : 50200095946891</div>
              <div>Name : AOTEAROA SOLUTIONS PVT LTD</div>
              <div>IFSC: HDFC0002231</div>
              <div>Branch: SARAVANAMPATTI BRANCH</div>
              <div>Account Type: CURRENT</div>
              <div className='mt-2 text-danger'>
                Kindly transfer the amount and then finish. Request to be approved shortly.
              </div>
            </div>
          }
          <div className='row pt-3'>
            <span className='ml-3'>Total Amount</span>
            <span className='ml-2'>
              <FxCurrency value={amount} />
            </span>
          </div>
          <div className='pt-3'>
            <FormGroup>
              <FormControlLabel control={<Checkbox name="isOkForOthersToContact" value={item.isOkForOthersToContact}
                onChange={handleCheckbox} checked={item.isOkForOthersToContact} />}
                label="I agree to the standard Localites terms & conditions." />
            </FormGroup>
          </div>
          <div className='text-right fixed-btn pr-4'>
            <Button color="primary" variant="contained" className='btn-style' onClick={saveLocalBusinessForm}><b>Finish</b></Button>
          </div>
        </div>
      }
    </div>
  );
}
export default LocalDrForm;
