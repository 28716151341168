import React, { useEffect, useState } from 'react';
import './ListingOrderSummary.scss';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TableFooter } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { FxCurrency, http, general } from '../fx-core';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';

const ListingOrderSummary = (props: any) => {
  const dispatch = useDispatch();
  const [saleOrderId, setSaleOrderId] = useState(props.match.params.saleOrderId ? parseInt(props.match.params.saleOrderId) : 0);

  const initialList = [];
  let defaultSaleOrder = {
    saleOrderIdentifier: 0,
    orderDate: null,
    orderTotal: 0
  };

  let defaultContext = {
    stripeText: "Card",
    canOpenPaymentLink: false
  };

  const [saleOrder, setSaleOrder] = useState(defaultSaleOrder);
  const [lines, setLines] = useState(initialList);
  const [saleOrderPayments, setSaleOrderPayments] = useState(initialList);
  const [currentContext, setCurrentContext] = useState(defaultContext);

  const getSaleOrderDetail = () => {
    let inputData = {
      id: saleOrderId
    };

    let apiOptions: any = {
      url: `saleorder/detail/${inputData.id}`
    };

    http.get(apiOptions).then(res => {
      getSaleOrderDetailCallBack(res);
    })
  };

  const getSaleOrderDetailCallBack = (res) => {
    const result = res.data;
    setSaleOrder(result.saleOrder);
    for (let item of result.saleOrderLines) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setLines(result.saleOrderLines);
    setSaleOrderPayments(result.saleOrderPayments);
    emptyCart();
    validateAndInitPayment(result.saleOrderPayments);
  };

  const emptyCart = () => {
    dispatch({ type: "CLEAR_LISTING_CART", payload: {} });
  };

  const validateAndInitPayment = (payments) => {
    let item = payments.find(item => item.paymentMode == 'stripe'
      && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated'));
    const canShowLink = item ? true : false;
    if (canShowLink && !currentContext.canOpenPaymentLink) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ canOpenPaymentLink: true } }
      });
      initiatePayment();
    }
  };

  const initiatePayment = () => {
    let inputData = {
      id: saleOrderId
    };
    let apiOptions: any = {
      url: `saleorder/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getPaymentLinkCallback(res);
    });
  }

  const getPaymentLinkCallback = (res) => {
    const result = res.data;
    let url = result.checkoutUrl;
    if (url) {
      window.open(url);
    }
  };

  useEffect(() => {
    getSaleOrderDetail();
  }, []);

  return (
    <div className="ordersummary-ui">
      <div className="content-container marginTopStyle">

        {/* Desktop View */}
        <div className='deskview'>
          <div className='row mx-4 my-5'>
            <div className='col-8'>
              <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                <Table className="table-striped" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Items</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell className="table-font">
                          <span className='font-14'>{row.itemName}</span>
                        </TableCell>
                        <TableCell className="table-font">
                          <span>{row.itemQuantity}</span>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">{row.itemQuantity} * <FxCurrency value={row.price} />
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={row.lineTotalPrice} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <span className="table-font">Total</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={saleOrder.orderTotal} />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>

            <div className='col-4 summary-detail font-14'>
              <div className='row'>
                <div className='col-4'>Order #</div>
                <div className='col-8'>: {saleOrder.saleOrderIdentifier}</div>
              </div>
              <div className='row pt-1'>
                <div className='col-4'>Ordered Date</div>
                <div className='col-8'>: {general.getFormattedDate(saleOrder.orderDate)}</div>
              </div>
              {saleOrderPayments.map((item) => (
                <div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {(item.paymentMode == 'stripe' || item.paymentMode == 'cod') &&
                        <span>Order total</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span>Coupon Value</span>
                      }
                    </div>
                    <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>Payment Mode</div>
                    <div className='col-8'>
                      <span>: </span>
                      <span>
                        {item.paymentMode == 'cod' &&
                          <span className="pr-1">Cash</span>
                        }
                        {item.paymentMode == 'stripe' &&
                          <span className="pr-1">Card</span>
                        }
                        {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                          <span className="pr-1">{item.paymentMode}</span>
                        }
                      </span>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {(item.paymentMode == 'stripe' || item.paymentMode == 'cod') &&
                        <span>Payment Status</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span>Discount Status</span>
                      }
                    </div>
                    <div className='col-8'>
                      : <span>{item.paymentStatus}
                        {item.paymentMode == 'stripe' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                          <span>
                            Pay
                          </span>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


        {/* Tablet View */}
        <div className='tabview'>
          <div className='row mx-4 my-5'>
            <div className='col-8'>
              <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                <Table className="table-striped" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Items</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell className="table-font">
                          <span className='font-14'>{row.itemName}</span>
                        </TableCell>
                        <TableCell className="table-font">
                          <span>{row.itemQuantity}</span>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">{row.itemQuantity} * <FxCurrency value={row.price} />
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={row.lineTotalPrice} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <span className="table-font">Total</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={saleOrder.orderTotal} />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>

            <div className='col-4 summary-detail font-14'>
              <div className='row'>
                <div className='col-4'>Order #</div>
                <div className='col-8'>: {saleOrder.saleOrderIdentifier}</div>
              </div>
              <div className='row pt-1'>
                <div className='col-4'>Ordered Date</div>
                <div className='col-8'>: {general.getFormattedDate(saleOrder.orderDate)}</div>
              </div>
              {saleOrderPayments.map((item) => (
                <div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {(item.paymentMode == 'stripe' || item.paymentMode == 'cod') &&
                        <span>Order total</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span>Coupon Value</span>
                      }
                    </div>
                    <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>Payment Mode</div>
                    <div className='col-8'>
                      <span>: </span>
                      <span>
                        {item.paymentMode == 'cod' &&
                          <span className="pr-1">Cash</span>
                        }
                        {item.paymentMode == 'stripe' &&
                          <span className="pr-1">Card</span>
                        }
                        {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                          <span className="pr-1">{item.paymentMode}</span>
                        }
                      </span>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {(item.paymentMode == 'stripe' || item.paymentMode == 'cod') &&
                        <span>Payment Status</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span>Discount Status</span>
                      }
                    </div>
                    <div className='col-8'>
                      : <span>{item.paymentStatus}
                        {item.paymentMode == 'stripe' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                          <span>
                            Pay
                          </span>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className='mobileview'>
          <div className='mx-3 mb-5 mt-2'>
            <div className='summary-detail font-13 mb-3'>
              <div className='row'>
                <div className='col-5'>Order #</div>
                <div className='col-7'>: {saleOrder.saleOrderIdentifier}</div>
              </div>
              <div className='row pt-1'>
                <div className='col-5'>Ordered Date</div>
                <div className='col-7'>: {general.getFormattedDate(saleOrder.orderDate)}</div>
              </div>
              {saleOrderPayments.map((item) => (
                <div>
                  <div className='row pt-1'>
                    <div className='col-5'>
                      {(item.paymentMode == 'stripe' || item.paymentMode == 'cod') &&
                        <span>Order total</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span>Coupon Value</span>
                      }
                    </div>
                    <div className='col-7'>: <FxCurrency value={item.amount} /></div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-5'>Payment Mode</div>
                    <div className='col-7'>
                      <span>: </span>
                      <span>
                        {item.paymentMode == 'cod' &&
                          <span className="pr-1">Cash</span>
                        }
                        {item.paymentMode == 'stripe' &&
                          <span className="pr-1">Card</span>
                        }
                        {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                          <span className="pr-1">{item.paymentMode}</span>
                        }
                      </span>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-5'>
                      {(item.paymentMode == 'stripe' || item.paymentMode == 'cod') &&
                        <span>Payment Status</span>
                      }
                      {item.paymentMode != 'stripe' && item.paymentMode != 'cod' &&
                        <span>Discount Status</span>
                      }
                    </div>
                    <div className='col-7'>
                      : <span>{item.paymentStatus}
                        {item.paymentMode == 'stripe' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                          <span>
                            Pay
                          </span>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div>
              <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                <Table className="table-striped" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Items</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell align="right" className='px-1'>Price</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell className="table-font pr-1">
                          <span className='font-14'>{row.itemName}</span>
                        </TableCell>
                        <TableCell className="table-font">
                          <span>{row.itemQuantity}</span>
                        </TableCell>
                        <TableCell align="right" className='px-1'>
                          <span className="table-font">{row.itemQuantity} * <FxCurrency value={row.price} />
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={row.lineTotalPrice} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <span className="table-font">Total</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={saleOrder.orderTotal} />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingOrderSummary; 