import React, { useState, useEffect } from "react";
import './ListingFormContainer.scss';
import { http } from "../../fx-core";
import SaleForm from "../SaleForm/SaleForm";
import GardenPetProfArtForm from "../GardenPetProfArtForm/GardenPetProfArtForm";

const ListingFormContainer = (props) => {
    const [stallId, setStallId] = useState(props.stallId);
    const [serviceCode, setServiceCode] = useState(null);
    const [isEdit, setIsEdit] = useState(props.isEdit);
    const [serviceBehaviorCode, setServiceBehaviorCode] = useState("");

    const getServiceById = () => {
        let inputData = {
            id: props.serviceId
        };
        let apiOptions: any = {
            url: `portal/service/edit/${inputData.id}`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            setServiceCode(result.serviceCode);
            setServiceBehaviorCode(result.defaultBehaviorCode)
        });
    }

    const handleClose = () => {
        props.handleSaleFormClose();
    }

    useEffect(() => {
        getServiceById();
    }, []);

    return (
        <div className="listing-form-container">
            <div className='row title-font py-1 px-2 drawer-header'>
                <span className="col-11">
                    Sale Form
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>

            <div className='p-3 pt-5'>               
                {/* {
                    serviceBehaviorCode == "Listings" && serviceCode == 'Garage Sale' &&
                    <SaleForm stallId={stallId} saleId={props.saleId} isEdit={isEdit} handleClose={handleClose} />
                } */}                
                <GardenPetProfArtForm stallId={stallId} saleId={props.saleId} isEdit={isEdit} handleClose={handleClose}/>
            </div>
        </div>
    );
};

export default ListingFormContainer;