import React, { useEffect, useState } from 'react';
import './ConfirmDialog.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button } from '@material-ui/core';
import { FxInput, AlertService } from '../fx-core';

const ConfirmDialog = (props: any) => {

  const [comments, setComments] = useState(null);
  const [error, setError] = useState(null);

  const handleInputChange = (item) => {
    setComments(item.value);
  };

  const handleClose = () => {
    props.handleDialogClose();
  }

  const confirmDialog = () => {
    if (props.action && (props.action == 'cancel' || props.action == 'verify')) {
      if (!comments) {
        setError("This field is required");
        return;
      }
      props.onConfirmCallback(props.action, comments);
    }
    else {
      props.onConfirmCallback();
    }
  }


  useEffect(() => {
  }, []);

  const body = (
    <div className="confirmContainer confirm-dialog">
      <div className="row justify-content-between title-font px-3">
        <div className="py-1 pl-3">
          <span>Confirm</span>
        </div>
        <div className="text-right pointer my-auto">
          <i className="fas fa-times-circle font-16 pr-3" onClick={handleClose}></i>
        </div>
      </div>
      <div className='m-2 pl-3'>
        {props.message}
      </div>

      {(props.action == 'cancel' || props.action == 'verify') &&
        <div className="row m-2">
          <div className="col-12 font-14">
            <FxInput name="comments" variant="outlined" label="Comments" size="small"
              value={comments} onValueChange={handleInputChange}
            />
          </div>
          {error &&
            <div className="error"> {error} </div>}
        </div>
      }

      <div className="row justify-content-end pb-2 pr-4">
        <div>
          <span className="mr-2">
            <Button variant="contained" size="small" onClick={handleClose}>
              No
            </Button>
          </span>
          <span>
            <Button variant="contained" size="small" color="primary" onClick={confirmDialog}>
              Yes
            </Button>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default ConfirmDialog;