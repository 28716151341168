import React, { useEffect, useState } from 'react';
import './MyListingOrders.scss';
import { FxDateDisplay, FxCurrency, http } from '../../fx-core';
import Link from '@material-ui/core/Link';
import { Dialog, Drawer } from '@material-ui/core';
import MyListingOrderDetail from '../MyListingOrderDetail/MyListingOrderDetail';
import * as _ from 'lodash';
import { makeStyles, Button, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import MessageContainer from '../../MessageContainer/MessageContainer';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const MyListingOrders = (props: any) => {
  const classes = styles();
  const OrderStatus = [
    { id: -1, text: "All", code: "-1", isDefault: false, attributes: null },
    { id: 1000, text: "Open", code: "Open", isDefault: false, attributes: null },
    { id: 6, text: "Delivered", code: "Delivered", isDefault: false, attributes: null },
    { id: 7, text: "Cancelled", code: "Cancelled", isDefault: false, attributes: null }
  ];

  let defaultFilter: any = {
    orderStatusId: -1
  };
  const initialList = [];
  const [saleOrderList, setSaleOrderList] = useState(initialList);
  const [open, setOpen] = React.useState(false);
  const [saleOrderId, setSaleOrderId] = React.useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const [currentContext, setCurrentContext] = useState({ totalAmount: 0, stripeText: "Card" });
  const [selOrderStatusId, setSelOrderStatusId] = useState(-1);
  const [openMessage, setOpenMessage] = React.useState(false);

  const toggleDrawerMessage = () => {
    setOpenMessage(!openMessage);
  };

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let options = { filter: { [item.name]: item.value } };
    getSaleOrderList(options);
  }

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };


  const getSaleOrderList = (options: any = {}) => {
    let selectedStatusId = 1;
    if (options && options.filter) {
      selectedStatusId = options.filter.orderStatusId ? options.filter.orderStatusId : -1;
    }
    let inputData = { orderStatusId: selectedStatusId, userId: props.userId };

    let apiOptions: any = {
      url: 'saleorder/query',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setSaleOrderList(response.data);
    })
  }

  const viewOrderDetail = (item: any) => {
    setOpen(true);
    setSaleOrderId(item.id);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const initiatePayment = (id) => {
    let inputData = { id: id };
    let apiOptions: any = {
      url: 'saleorder/GetPaymentLink',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getPaymentLinkCallback(response);
    });
  }

  const getPaymentLinkCallback = (res) => {
    let result = res.data;
    let url = result.checkoutUrl;
    if (url) {
      window.open(url);
    }
  };

  const handleStatusChange = (selId) => {
    setSelOrderStatusId(selId);
    let item = { name: "orderStatusId", value: selId };
    handleInputChange(item);
  };

  const navToMessage = (id) => {
    setSaleOrderId(id);
    setOpenMessage(true);
  }

  const handleMessageClose = () => {
    setOpenMessage(false);
    setSaleOrderId(0);
  }

  useEffect(() => {
    let options = { filter: filter };
    getSaleOrderList(options);
  }, []);


  const body = (
    <div className="mylistingorders-ui">
      <div className="content-container">
        <div className="content">
          {/* <div className="mt-n4 pl-1">
            <h5>Orders</h5>
          </div> */}

          {/* Desktop View */}
          <div className='deskview'>
            <div className='ml-0 mt-4'>
              {OrderStatus.map((item, i) => (
                <span className='mr-3'>
                  {
                    selOrderStatusId === item.id &&
                    <Button variant='outlined' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                  {
                    selOrderStatusId !== item.id &&
                    <Button variant='contained' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                </span>
              ))
              }
            </div>

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell></TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saleOrderList.map((row, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'>#{row.saleOrderIdentifier}</TableCell>
                        <TableCell>{row.orderStatus}</TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.saleOrderPayments && row.saleOrderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span>
                                  {(itemObj.paymentStatus == 'Pending' || itemObj.paymentStatus == 'Initiated') &&
                                    <span>
                                      {currentContext.stripeText}:
                                      <Link className="btn btn-address font-14" onClick={() => initiatePayment(row.id)}
                                        title="pay">Pay</Link>
                                    </span>
                                  }
                                  {itemObj.paymentStatus != 'Pending' && itemObj.paymentStatus != 'Initiated' &&
                                    <span>
                                      {currentContext.stripeText} / {itemObj.paymentStatus}
                                    </span>
                                  }
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                          <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Tablet View */}
          <div className='tabview'>
            <div className='ml-0 mt-4'>
              {OrderStatus.map((item, i) => (
                <span className='mr-3'>
                  {
                    selOrderStatusId === item.id &&
                    <Button variant='outlined' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                  {
                    selOrderStatusId !== item.id &&
                    <Button variant='contained' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                </span>
              ))
              }
            </div>

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell></TableCell>
                      {/* <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saleOrderList.map((row, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'>#{row.saleOrderIdentifier}</TableCell>
                        <TableCell>{row.orderStatus}</TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.saleOrderPayments && row.saleOrderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span>
                                  {itemObj.paymentStatus == 'Pending' || itemObj.paymentStatus == 'Initiated' &&
                                    <span>
                                      {currentContext.stripeText}:
                                      <Link className="btn btn-address font-14" onClick={() => initiatePayment(itemObj.id)}
                                        title="pay">Pay</Link>
                                    </span>
                                  }
                                  {itemObj.paymentStatus != 'Pending' && itemObj.paymentStatus != 'Initiated' &&
                                    <span>
                                      {currentContext.stripeText} / {itemObj.paymentStatus}
                                    </span>
                                  }
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                          <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Mobile View */}
          <div className='mobileview mb-4'>
            <div className='ml-0 mt-4 mb-4'>
              {OrderStatus.map((item, i) => (
                <span className='mr-2'>
                  {
                    selOrderStatusId === item.id &&
                    <Button variant='outlined' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                  {
                    selOrderStatusId !== item.id &&
                    <Button variant='contained' size='small' color="primary" className='button-style' onClick={() => handleStatusChange(item.id)}>{item.text}</Button>
                  }
                </span>
              ))
              }
            </div>
            {saleOrderList.map((row, i) => (
              <div>
                <Card className="mt-3 py-2 px-3">
                  <div className='row justify-content-between px-2'>
                    <div>
                      <div className='font-13 bold-text'>#{row.saleOrderIdentifier}</div>
                      <div className='font-11'><FxDateDisplay value={row.orderDate} /></div>
                    </div>
                    <div className='text-right'>
                      <div className='font-13 bold-text'><FxCurrency value={row.orderTotal} /></div>
                      <div className='font-11'>{row.orderStatus}</div>
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-2'>
                    <div className='font-13'>{row.stallName}</div>
                    <div className='font-13 text-right'>
                      {row.orderTypeId == 1 &&
                        <span>Delivery</span>
                      }
                      {row.orderTypeId == 2 &&
                        <span>Pickup</span>
                      }
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-1'>
                    <div className='font-12'>
                      {row.saleOrderPayments && row.saleOrderPayments.map((itemObj, i) => (
                        <div>
                          {itemObj.paymentMode == 'cod' &&
                            <span>
                              Cash / {itemObj.paymentStatus}
                            </span>
                          }
                          {itemObj.paymentMode == 'stripe' &&
                            <span>
                              {itemObj.paymentStatus == 'Pending' || itemObj.paymentStatus == 'Initiated' &&
                                <span>
                                  {currentContext.stripeText}:
                                  <Link className="btn btn-address font-14" onClick={() => initiatePayment(itemObj.id)}
                                    title="pay">Pay</Link>
                                </span>
                              }
                              {itemObj.paymentStatus != 'Pending' && itemObj.paymentStatus != 'Initiated' &&
                                <span>
                                  {currentContext.stripeText} / {itemObj.paymentStatus}
                                </span>
                              }
                            </span>
                          }
                          {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                            <span>
                              {itemObj.paymentMode} / {itemObj.paymentStatus}
                            </span>
                          }
                        </div>
                      ))
                      }
                    </div>
                    <div className='text-right'>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                      <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => navToMessage(row.id)}>Direct</Button>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>

        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <MyListingOrderDetail saleOrderId={saleOrderId} handleClose={handleClose} />
        </Dialog>
        <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
          <MessageContainer saleOrderId={saleOrderId} isMyOrders={true} handleMessageClose={handleMessageClose} />
        </Drawer>
      </div>
    </div>
  );


  return (
    <div>{body}</div>
  );

}

export default MyListingOrders;