import React from 'react';
import moment from 'moment';
import { Constants } from '../helpers/constants';

export const FxDateTimeDisplay = (props: any) => {
    const getDateTimeDisplay = () => {
        let getDate = props.value;
        let displayType = props.displayType;

        if (getDate) {
            if (displayType == 'date') {
                const result = moment(getDate).format(Constants.DateFormat);
                return result;
            }
            if (displayType == 'datetime') {
                const result = moment(getDate).format(Constants.DisplayDateTimeFormat);
                return result;
            }
            if (displayType == 'time') {
                const result = moment(getDate, Constants.TimeFormat).format(Constants.ServerTimeFormat);
                return result;
            }
        }
    }


    return (
        <span>
            {getDateTimeDisplay()}
        </span>
    );
};