import React, { useEffect, useState } from 'react';
import './SaleItemForm.scss';
import { useNavigationService, http, general, AlertService, FxInput, FxCurrency } from '../../fx-core';
import { Button, Link, Dialog, Fab, Badge, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import dummyImage from "../../../assets/images/dummy.png";
import SaleItemDetailForm from '../SaleItemDetailForm/SaleItemDetailForm';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SaleItemForm = (props) => {
  const { navigate } = useNavigationService();
  let defaultGroupInfo = {
    name: "",
    id: 0,
    saleId: props.match.params.id ? parseInt(props.match.params.id) : 0
  };
  let defaultContext = {
    isShowGroup: false,
    isShowEditGroup: false,
    groupId: 0,
    saleId: 0,
    saleItemId: 0,
    saleItemGroupId: 0
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [groupInfo, setGroupInfo] = useState(defaultGroupInfo);
  const [groupDetails, setGroupDetails] = useState([]);
  const [saleItemDetailFormOpen, setSaleItemDetailFormOpen] = useState(false);
  const [saleItemGroupId, setSaleItemGroupId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [saleItemDetailId, setSaleItemDetailId] = useState(0);

  const handleInputChange = (item) => {
    handleGroupChange({ [item.name]: item.value });
  }

  const handleGroupChange = (data) => {
    setGroupInfo(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleCurrentContextChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Group section code starts
  const getSaleItemGroupWithDetail = () => {
    let inputData = {
      id: groupInfo.saleId
    };
    let apiOptions: any = {
      url: `saleitemgroup/detail/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        for (let detail of item.saleItems) {
          detail.imageUrl = general.getImageUrl(detail.imagePath);
        }
      }
      setGroupDetails(result);
    })
  };

  const openSaleItemGroup = () => {
    handleCurrentContextChange({ isShowGroup: true });
  };

  const cancelSaleItemGroup = () => {
    setGroupInfo(defaultGroupInfo);
    handleCurrentContextChange({ isShowGroup: false });
  }

  const editSaleItemGroup = (item) => {
    let contextObj = {
      groupId: item.id,
      isShowEditGroup: true
    };
    handleCurrentContextChange(contextObj);
    getSaleItemGroupById(item.id);
  }

  const cancelEditSaleItemGroup = () => {
    setGroupInfo(defaultGroupInfo);
    let contextObj = {
      groupId: 0,
      isShowEditGroup: false
    };
    handleCurrentContextChange(contextObj);
  }


  const getSaleItemGroupById = (groupId) => {
    let inputData = {
      id: groupId
    }
    let apiOptions: any = {
      url: `saleitemgroup/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setGroupInfo(result);
    })
  };

  const saveSaleItemGroup = () => {
    setError(null);
    if (!groupInfo.name) {
      setError("Name is required");
      return;
    }
    let apiOptions: any = {
      url: `saleitemgroup/save`,
      data: groupInfo
    };
    http.post(apiOptions).then(res => {
      saveSaleItemGroupCallback();
    })
  }

  const saveSaleItemGroupCallback = () => {
    if (currentContext.groupId) {
      handleCurrentContextChange({ groupId: 0 });
    }
    else {
      let contextObj = {
        isShowEditGroup: false,
        isShowGroup: false
      };
      handleCurrentContextChange(contextObj);
    }
    setGroupInfo(defaultGroupInfo);
    getSaleItemGroupWithDetail();
  }

  const confirmGroupDelete = (item) => {
    setSaleItemGroupId(item.id);
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + item.name + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if (saleItemGroupId > 0) {
      deleteSaleItemGroup();
    }
    else if (saleItemDetailId > 0) {
      deleteSaleItemDetail();
    }
  }

  const deleteSaleItemGroup = () => {
    let inputData = {
      id: saleItemGroupId,
    };
    let apiOptions: any = {
      url: `saleitemgroup/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getSaleItemGroupWithDetail();
    })
  };
  //Group Section Code Ends

  //Detail section code starts

  const addSaleItemDetail = (itemObj) => {
    let contextObj = {
      saleItemGroupId: itemObj.id,
      saleId: itemObj.saleId,
      saleItemId: 0
    };
    handleCurrentContextChange(contextObj);
    setSaleItemDetailFormOpen(true);
  }

  const handleSaleItemDetailClose = () => {
    setSaleItemDetailFormOpen(false);
    getSaleItemGroupWithDetail();
  }

  const editSaleItemDetail = (itemObj) => {
    let contextObj = {
      saleItemGroupId: itemObj.saleItemGroupId,
      saleId: itemObj.saleId,
      saleItemId: itemObj.id
    };    
    handleCurrentContextChange(contextObj);
    setSaleItemDetailFormOpen(true);
  }

  const confirmDetailDelete = (item) => {
    setSaleItemDetailId(item.id);
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + item.itemName + "?");
  }

  const deleteSaleItemDetail = () => {
    let inputData = {
      id: saleItemDetailId
    };
    let apiOptions: any = {
      url: `saleitem/${inputData.id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      getSaleItemGroupWithDetail();
    })
  };

  //Detail section code ends
  const navToManageListings = () => {
    let navData = {
      targetRoute: '/provider/managelistings',
      targetTitle: "Manage Listings",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    getSaleItemGroupWithDetail();
  }, []);

  return (
    <div className='saleitemform-ui pt-5'>
      <div className="title-font custom-head pt-4 mt-3 pl-4">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style pl-2" onClick={navToManageListings}>
            <b>Manage Listings</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Sale Item Form</Typography>
        </Breadcrumbs>
      </div>

      <div className='mx-3 pl-2 pt-3 my-3 text-center'>
        <div className='row justify-content-center pb-2'>
        <div className='row col-10 justify-content-between'>
          <div className='col-6'>            
            {currentContext.isShowGroup &&
              <div className='row'>
                <span className="col-8">
                  <FxInput name="name" variant="outlined" fullWidth size="small" label="Name"
                    value={groupInfo.name} onValueChange={handleInputChange} />
                  {error &&
                    <div className="error">{error}</div>
                  }
                </span>
                <span className='col-4 text-left'>
                  <Button variant='contained' color='primary' size='small' className='button-style mr-2' onClick={saveSaleItemGroup}>Save</Button>
                  <Button variant='contained' size='small' className='button-style' onClick={cancelSaleItemGroup}>Cancel</Button>
                </span>
              </div>
            }
          </div>
          <span className="float-right text-right pointer">
            <Fab variant="extended" size="medium" color="primary" onClick={openSaleItemGroup}>
              <i className="fas fa-plus pr-2"></i>
              Add Category
            </Fab>
          </span>
        </div>
        </div>

        <div className='row justify-content-center'>
        <div className='col-10 pb-5'>
          {groupDetails.map((row, i) => (
            <div>
              {/* Group Header display section code starts */}

              <div className='pt-3'>
                <Accordion defaultExpanded={true}>
                  {row.group.id != currentContext.groupId &&
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {row.group.name}
                    </AccordionSummary>
                  }
                  <AccordionDetails>
                    <div className='col-12 pt-2'>
                      {row.group.id != currentContext.groupId &&
                        <div className='row col-12'>
                          <span className='col-6 text-left'>
                            <Button variant='contained' color='primary' size='small' className='button-style mr-2' onClick={() => editSaleItemGroup(row.group)}>Edit Category</Button>
                            <Button variant='contained' size='small' className='button-style mr-2' onClick={() => confirmGroupDelete(row.group)}>Delete</Button>
                          </span>

                          <span className='col-6 text-right pr-4 pl-0'>
                            <Button variant='contained' color='primary' size='small' className='button-style' onClick={() => addSaleItemDetail(row.group)}>Add Item</Button>
                          </span>
                        </div>
                      }
                      {row.group.id == currentContext.groupId && currentContext.isShowEditGroup &&
                        <div className='row'>
                          <span className="col-6 text-left">
                            <FxInput name="name" variant="outlined" fullWidth size="small" label="Name"
                              value={groupInfo.name} onValueChange={handleInputChange} />
                            {error &&
                              <div className="error">{error}</div>
                            }
                          </span>
                          <span className='col-2 text-left'>
                            <Button variant='contained' color='primary' size='small' className='button-style mr-2' onClick={saveSaleItemGroup}>Save</Button>
                            <Button variant='contained' size='small' className='button-style' onClick={cancelEditSaleItemGroup}>Cancel</Button>
                          </span>
                        </div>
                      }

                      <div className='pt-3 row col-12'>
                        {row.saleItems.map((detailVal, i) => (
                          <div className='row col-12 items-border py-3'>
                            <span className='col-1'>
                              {!detailVal.imageUrl && <img src={dummyImage} alt="Stall" width="60" height="60"></img>}
                              {detailVal.imageUrl && <img src={detailVal.imageUrl} alt="Stall" width="60" height="60"></img>}
                            </span>
                            <span className='col-3 text-left'>
                              <span className='font-14 font-weight-bold'>
                                {detailVal.itemName}
                              </span>
                              <div className='font-14 pt-1'>
                                <span>
                                  {detailVal.price > 0 &&
                                    <FxCurrency value={detailVal.price} />
                                  }
                                </span>
                                <span className='px-2'>/</span>
                                <span>
                                  {detailVal.quantity > 0 &&
                                    <span>Qty: {detailVal.quantity}</span>
                                  }
                                </span>
                              </div>
                            </span>
                            <span className='col-7 font-14 text-left'>{detailVal.description}</span>
                            <span className='col-1 row justify-content-between'>
                              <span onClick={() => editSaleItemDetail(detailVal)} className="text-center pointer">
                                <div><i className="fas fa-edit"></i></div>
                                <div className='font-12'>Edit</div>
                              </span>
                              <span onClick={() => confirmDetailDelete(detailVal)} className="text-center pointer">
                                <div><i className="fas fa-trash-alt"></i></div>
                                <div className='font-12'>Delete</div>
                              </span>
                            </span>

                          </div>

                        ))
                        }
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

            </div>
          ))}
        </div>
        </div>
      </div>

      <div>
        <Dialog
          open={saleItemDetailFormOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleSaleItemDetailClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <SaleItemDetailForm saleId={currentContext.saleId} saleItemGroupId={currentContext.saleItemGroupId}
            saleItemId={currentContext.saleItemId} handleSaleItemDetailClose={handleSaleItemDetailClose} />
        </Dialog>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
        </Dialog>
      </div>
    </div>
  );
}
export default SaleItemForm;