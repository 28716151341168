import React, { useEffect, useState } from 'react';
import './ItemTagList.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Fab, Dialog, Drawer, makeStyles, Breadcrumbs, Typography } from '@material-ui/core';
import { http, FxInput, useNavigationService, AlertService } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import ItemTagForm from '../ItemTagForm/ItemTagForm';

const styles = makeStyles((theme) => ({
    drawerWidth: {
        [theme.breakpoints.down(599)]: {
            width: '90%'
        },
        [theme.breakpoints.up(600)]: {
            width: '70%'
        },
        [theme.breakpoints.up(1025)]: {
            width: '40%'
        }
    }
}));

const ItemTagList = () => {
    const { routeData, back, goTo } = useNavigationService();
    const classes = styles();

    let defaultFilter: any = {
        name: ""
    };
    const [filters, setFilters] = useState(defaultFilter);
    const [itemTags, setItemTags] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedId, setSelectedId] = useState(0);
    const [itemTagFormOpen, setItemTagFormOpen] = useState(false);

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            name: filters.name
        }
        if (item.name == "name") {
            filterObj.name = item.value;
        }
        getItemTags(filterObj);
    };

    const handleFilterChange = (data) => {
        setFilters(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getItemTags = (data: any) => {
        let inputData = {
            communityId: routeData.communityId,
            tagName: data.name
        };
        let apiOptions: any = {
            url: `itemtag/query`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getItemTagsCallBack(res);
        });
    };

    const getItemTagsCallBack = (res) => {
        let result = res.data;
        setItemTags(result);
    }

    const addOrEditItemTag = (id) => {
        setItemTagFormOpen(true);
        setSelectedId(id);
    }

    const handleItemTagFormClose = () => {
        setItemTagFormOpen(false);
        setSelectedId(0);
        getItemTags(filters);
    }

    const confirmDelete = (item) => {
        setConfirmOpen(true);
        setMessage("Are you sure to delete " + item.tagName + "?");
        setSelectedId(item.id);
    }

    const handleDialogClose = () => {
        setConfirmOpen(false);
        setSelectedId(0);
        setMessage("");
    };

    const onConfirmCallback = () => {
        const id = selectedId;
        deleteItemTag(id);
        handleDialogClose()
    };

    const deleteItemTag = (id) => {
        let apiOptions: any = {
            url: `itemtag/${id}`
        };
        http.delete(apiOptions).then(response => {
            AlertService.showMsg({ message: "Deleted successfully" });
            getItemTags(filters);
        })
    };

    const navToBack = () => {
        back();
    };

    const navToDashboard = () => {
        goTo(1);
    };

    useEffect(() => {
        getItemTags(filters);
    }, []);

    return (
        <div className='itemtag-list-ui pt-5'>
            <div className="title-font custom-head pl-4 pt-5">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToDashboard}>
                        <b>{routeData.communityName}</b>
                    </Link>
                    <Typography color="primary" className="breadcrumbs-style">
                        <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                            <b>Items</b>
                        </Link>
                    </Typography>
                    <Typography color="primary" className="breadcrumbs-style">Item Tags</Typography>
                </Breadcrumbs>
            </div>
            <div className='content-container'>
                <div className="content">
                    <div className='row my-4 mx-2'>
                        <div className="col-3">
                            <FxInput
                                name="name"
                                value={filters.name}
                                variant="outlined"
                                label="Search"
                                fullWidth
                                size="small"
                                onValueChange={handleInputChange}
                            />
                        </div>
                        <div className="col-3">
                        </div>
                        <div className='col-3'>
                        </div>
                        <div className='col-3'>
                            <span className='d-flex justify-content-end pr-3'>
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    color="primary"
                                    onClick={() => addOrEditItemTag(0)}
                                >
                                    <i className="fas fa-plus pr-2"></i>
                                    Add
                                </Fab>
                            </span>
                        </div>
                    </div>

                    <div className='row my-4 mx-4 pt-2'>
                        <TableContainer component={Paper}>
                            <Table className="table-striped" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        itemTags.length == 0 &&
                                        <TableCell colSpan={4}>
                                            <NoRecords />
                                        </TableCell>
                                    }
                                    {
                                        itemTags.length > 0 && itemTags.map((row: any, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {row.tagName}
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        <span className='pointer'><i className="fas fa-pencil-alt font-13" onClick={() => addOrEditItemTag(row.id)}></i></span>
                                                        <span className='ml-3 pointer'><i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row)}></i></span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <Dialog
                open={confirmOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
            </Dialog>
            <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={itemTagFormOpen} onClose={handleItemTagFormClose}>
                <ItemTagForm itemTagId={selectedId} communityId={routeData.communityId} handleItemTagFormClose={handleItemTagFormClose} />
            </Drawer>
        </div>
    );
}
export default ItemTagList;