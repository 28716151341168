import React from 'react';
import './FeaturedProducts.scss';
import Card from '@material-ui/core/Card';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import dummyImage from "./../../../assets/images/dummy.png";
import { FxCurrency } from '../../fx-core';
import { Button } from '@material-ui/core';

const FeaturedProducts = (props: any) => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  return (
    <div className="featuredproducts-ui">
      <div className='pb-4 pt-5 row justify-content-between'>
        <div className='font-22 pl-5 text-right font-weight-bold color-secondary col-7'>
          Featured
        </div>
        <div className='text-right pr-4 col-5'>
          <Button variant='outlined' size='small' className='button-style' onClick={props.viewAllFeatureItems}>
            View All
          </Button>
        </div>
      </div>
      <Carousel responsive={responsive}
        autoPlay={false}
        showDots={false}
        arrows={true}
        infinite={false}
        swipeable={true}
        draggable={true}
      >
        {
          props.products.length > 0 && props.products.map((item, i) => (
            <div className='product-card-wrapper'>
              <Card key={i} className='feature-product-card pointer' onClick={() => props.navToStallDetail(item)}>
                {!item.itemImageUrl && <img src={dummyImage} alt="img" className="itemImg" ></img>}
                {item.itemImageUrl && <img src={item.itemImageUrl} alt="img" className="itemImg"></img>}
                <div className="category-ui pt-2 font-17">
                  {item.itemName}
                </div>
                <div className="pt-2 details-ui d-flex justify-content-between">
                  <span>
                    {item.price > 0 &&
                      <div className="font-17 price-ui">
                        <FxCurrency value={item.price} />
                      </div>
                    }

                    {item.unit &&
                      <div className="font-13">{item.unit}</div>
                    }
                  </span>
                  <span className="float-right mb-2">
                    {!item.stallImageUrl && <i className="fas fa-store-alt font-30" ></i>}
                    {item.stallImageUrl && <img src={item.stallImageUrl} alt="img" className="stallImg"></img>}
                  </span>
                </div>
              </Card>
            </div>
          ))
        }
      </Carousel>
    </div>
  );
}
export default FeaturedProducts;