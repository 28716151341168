import React, { useEffect, useState } from 'react';
import './ManagersTrueFansBoard.scss';
import { http, useNavigationService, AlertService, localstorage } from '../../fx-core';
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Breadcrumbs, Typography, IconButton, Tooltip } from '@material-ui/core';
import { NoRecords } from '../../NoRecords/NoRecords';

const ManagersTrueFansBoard = (props: any) => {
  const { routeData, back } = useNavigationService();

  const initialList = [];
  const [items, setItems] = useState(initialList);
  const getList = () => {
    let inputData = {
      communityId: routeData.communityId
    };

    let apiOptions: any = {
      url: 'follow/stalls/dashboard/query',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      setItems(res.data);
    })
  };

  const shareTrueFans = () => {
    let inputData = {
      communityId: routeData.communityId,
      userId: localstorage.getItem("userId")
    };

    let apiOptions: any = {
      url: 'follow/dashboard/share',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      shareTrueFansCallBack(res);
    })
  };

  const shareTrueFansCallBack = (res) => {
    AlertService.showSuccessMsg("True Fans LeaderBoard sent to your email");
  };

  const navToBack = () => {
    back();
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="managerstruefansboard-ui pt-5">
      <div className="content-container">
        <div className="content">
          <div className="row title-font-breadcrumbs custom-head pl-4 pt-4">
            <div className='col-8'>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                  <b>{routeData.communityName}</b>
                </Link>
                <Typography color="primary" className="breadcrumbs-style">True Fans LeaderBoard</Typography>
              </Breadcrumbs>
            </div>
            <div className='text-right col-4 pr-3'>
              <Tooltip title="Share">
                <IconButton aria-label="share" className='py-0 pr-4'>
                  <i onClick={shareTrueFans} className="fas fa-share-alt"></i>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {/* Desktop View */}
          <div className='deskview'>
            <div className='my-4 mx-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Stall Name</TableCell>
                      <TableCell>Community</TableCell>
                      <TableCell>Fan Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.length == 0 &&
                      <TableCell colSpan={3}>
                        <NoRecords />
                      </TableCell>
                    }
                    {items.length > 0 && items.map((row: any, i) => (
                      <TableRow>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>{row.communityName}</TableCell>
                        <TableCell>{row.fanCount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagersTrueFansBoard;