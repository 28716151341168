import React, { useEffect, useState } from 'react';
import './InviteFriendForm.scss';
import { http } from '../../fx-core';
import _ from "lodash";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog } from '@material-ui/core';
import ShareOptions from '../../LocalDrCard/ShareOptions/ShareOptions';

const InviteFriendForm = (props: any) => {
  const [referralList, setReferralList] = useState([]);
  const [shareOpen, setShareOpen] = useState(false);

  const getMyReferrals = () => {
    let inputData: any = {
      filters: {
        userId: props.userId
      }
    }
    let apiOptions: any = {
      url: `user/referrals/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      let result = res.data;
      getReferralStatusAndSetText(result);
    })
  }

  const getReferralStatusAndSetText = (result) => {
    let inputData = {
      lookups: {
        ReferralStatus: { default: false }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      const referralStatus = response.data.ReferralStatus;
      for (let item of result) {
        let lookupItem = _.find(referralStatus, { id: item.referralStatus });
        if (lookupItem) {
          item.referralStatusText = lookupItem.text;
        }
      }
      setReferralList(result);
    })
  }

  const openShareDialog = () => {
    setShareOpen(true);
  }

  const closeShareDialog = () => {
    setShareOpen(false);
  };

  useEffect(() => {
    getMyReferrals();
  }, []);

  const body = (
    <div className="invitefriend-ui">
      <div className="row py-1 px-1 mx-auto">
        <div className="col-5 pl-0">
          <h5>Invite Friends</h5>
        </div>
        <div className="col-7 pr-0 text-right">
          <Button variant="contained" size="small" color="primary" onClick={openShareDialog}>
            Share your invite link
          </Button>
        </div>
      </div>
      <div className='row'>
        <div className="col-12 py-4 text-center">
          <span className="font-16 color-aaa">
            Share this link with your friends so they can support local with Localites & get a FREE order
          </span>
        </div>
        {
          referralList && referralList.length > 0 &&
          <div className='col-12 my-4'>
            <div className='row'>
              <span className="col-12 py-2 mx-2">
                <h6>Invited Friends</h6>
              </span>
            </div>

            <TableContainer component={Paper}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referralList.map((row, i) => (
                    <TableRow>
                      <TableCell>
                        <span><i className="fas fa-user-circle fa-2x pr-2"></i></span>
                        <span>{row.firstName}</span>
                        {
                          row.lastName && <span className="ml-1"> - {row.lastName}</span>
                        }
                      </TableCell>
                      <TableCell>
                        <span>{row.mobile}</span>
                        {
                          !row.mobile && row.email && <span className="ml-1"> - {row.email}</span>
                        }
                      </TableCell>
                      <TableCell>
                        {row.referralStatusText}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
      </div>
      <Dialog
        open={shareOpen}
        maxWidth="sm"
        fullWidth
        onClose={closeShareDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ShareOptions closeShareDialog={closeShareDialog} userId={props.userId} />
      </Dialog>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default InviteFriendForm;