import { Button, Dialog } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import './SpecialCommunities.scss';
import { http, localstorage } from '../fx-core';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';

const SpecialCommunities = (props) => {
  const dispatch = useDispatch();
  const uid = localstorage.getItem('userId');
  const cid = localstorage.getItem("communityId")
  const userId = (uid && uid != null) ? parseInt(uid) : 0;
  const defaultCommId = (cid && cid != null) ? parseInt(cid) : 0;
  const displayCount = 5;

  const [allSplCommunityList, setAllSplCommunityList] = useState([]);
  const [splCommunityList, setSplCommunityList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedItem, setSelectedItem] = useState({ id: 0 });
  const [baseCommunity, setBaseCommunity] = useState({ id: 0, name: "" });
  const [currentStep, setCurrentStep] = useState(1);

  const handleClose = () => {
    props.handleDialogClose();
  };

  const showMore = () => {
    setCurrentStep(2);
  };

  const getSpecialCommunities = () => {
    let apiOptions: any = {
      url: `community/specialcommunity/${defaultCommId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setAllSplCommunityList(result);
      const reducedItems: any = _.take(result, displayCount);
      setSplCommunityList(reducedItems);
    })
  };

  const getUserBaseCommunity = () => {
    let apiOptions: any = {
      url: `community/user/base`,
      data: {}
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setBaseCommunity(result);
    })
  };

  const confirmSwitchCommunity = (event, item: any) => {
    setSelectedItem(item);
    setConfirmOpen(true);
    setMessage("Are you sure to switch to " + item.name + " community?");
    event.stopPropagation();
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if (userId > 0) {
      switchCommunity();
    }
    else {
      switchCommunityWithoutLogin();
    }
  };

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const switchCommunity = () => {
    let inputData = {
      communityId: selectedItem.id
    };
    let apiOptions: any = {
      url: `community/switch`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result && result.linkUrl) {
        window.open(result.linkUrl, '_self');
      }
      logoutUser();
    })
  };

  const switchCommunityWithoutLogin = () => {
    let inputData = {
      communityId: selectedItem.id
    };
    let apiOptions: any = {
      url: `community/switch/withoutlogin`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result && result.linkUrl) {
        window.open(result.linkUrl, '_self');
      }
    })
  };

  const logoutUser = () => {
    dispatch({ type: "LOGOUT", payload: { isLogin: false } });
    dispatch({ type: "CLEAR_CART", payload: {} });
    dispatch({ type: "CLEAR_LISTING_CART", payload: {} });
  }

  useEffect(() => {
    getSpecialCommunities();
    if (userId > 0) {
      getUserBaseCommunity();
    }
  }, []);


  return (
    <div className='special-communities pb-5 pt-3'>
      <div className='float-right pr-3'><i className="fas fa-times-circle color-primary font-24 pointer" onClick={handleClose}></i></div>
      {
        currentStep == 1 &&
        <div className='row justify-content-center mx-5 mt-5'>
          <div className='col-12 text-center d-flex flex-column justify-content-between'>
            <div>
              {
                baseCommunity.id > 0 && baseCommunity.id != defaultCommId &&
                <div className='mb-4 pointer' onClick={(e) => confirmSwitchCommunity(e, baseCommunity)}>
                  <span className='community-name py-2 px-3 font-24'>{baseCommunity.name}</span>
                </div>
              }
              <div className='font-weight-bold'>Special Communities</div>
              {
                splCommunityList.length > 0 && splCommunityList.map((item: any, i) => (
                  <div key={i}>
                    {
                      i < displayCount &&
                      <div className='pt-4 spl-community-name font-14 pointer' onClick={(e) => confirmSwitchCommunity(e, item)}>{item.name}</div>
                    }
                  </div>
                ))
              }
            </div>
            <div>
              {
                allSplCommunityList.length > displayCount &&
                <div className='mt-4'>
                  <Button variant="contained" size="small" color="primary" onClick={showMore}>
                    Show More
                  </Button>
                </div>
              }
            </div>
          </div>
        </div>
      }
      {
        currentStep == 2 &&
        <div>
          {
            baseCommunity.id > 0 && baseCommunity.id != defaultCommId &&
            <div className='font-24 text-center py-4 pointer' onClick={(e) => confirmSwitchCommunity(e, baseCommunity)}>{baseCommunity.name}</div>
          }
          <div className='row mx-5'>
            {
              allSplCommunityList.length > 0 && allSplCommunityList.map((item: any, i) => (
                <div className='col-4 text-center pt-4 spl-community-list font-15 pointer' key={i} onClick={(e) => confirmSwitchCommunity(e, item)}>
                  {item.name}
                </div>
              ))
            }
          </div>
        </div>
      }
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default SpecialCommunities;