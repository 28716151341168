import React, { useEffect, useState } from 'react';
import './StallServiceMap.scss';
import { http, AlertService } from '../../fx-core';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@material-ui/core';

const StallServiceMap = (props) => {

    const [stallServices, setStallServices] = useState([]);

    const handleClose = () => {
        props.handleStallServiceMapClose();
    };

    const handleCheckbox = (event) => {
        let services = stallServices.map((item) => {
            if (item.serviceId == event.target.name) {
                item.isActive = event.target.checked;
            }
            return item;
        });
        setStallServices(services);
    };

    const getServiceMapByStallId = () => {
        let apiOptions: any = {
            url: `stall/servicemap/${props.stallId}`
        };
        http.get(apiOptions).then(res => {
            let result = res.data;
            result = result.filter(i => i.serviceCode != "ChildCare");
            setStallServices(result);
        });
    };

    const saveStallServiceMap = () => {
        let inputData = {
            stallServiceMaps: stallServices
        };
        let apiOptions = {
            url: 'stall/servicemap/save',
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveStallServiceMapCallBack(res);
        });
    };

    const saveStallServiceMapCallBack = (res) => {
        AlertService.showSuccessMsg();
        handleClose();
    };

    useEffect(() => {
        getServiceMapByStallId();
    }, []);

    return (
        <div className='stallservicemap-ui'>
            <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
                <span className="col-11">
                    <span>{props.stallName} Services</span>
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>
            <div className='my-4 pb-5 pt-5 px-3'>
                <TableContainer component={Paper}>
                    <Table stickyHeader size="small" className='table-striped'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Service Name</TableCell>
                                <TableCell>Is Active</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            stallServices.map((row, i) => (
                                <TableBody key={i}>
                                    <TableRow>
                                        <TableCell>
                                            <span>
                                                {row.serviceName}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <span>
                                                <Checkbox
                                                    name={row.serviceId}
                                                    color="primary"
                                                    checked={row.isActive}
                                                    value={row.isActive}
                                                    onChange={handleCheckbox}
                                                />
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))
                        }
                    </Table>
                </TableContainer>
            </div>
            <div className='px-3 py-2 drawer-footer'>
                <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveStallServiceMap}><b>Save</b></Button>
            </div>
        </div>
    );
}

export default StallServiceMap;