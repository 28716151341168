import React, { useEffect, useState } from 'react';
import './CommunityItemOrders.scss';
import { FxDate, FxSelect, FxDateDisplay, FxCurrency, http, formatService, useNavigationService, localstorage } from '../../fx-core';
import * as _ from 'lodash';
import { Button, Card, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, Breadcrumbs, Typography } from '@material-ui/core';
import CommunityItemOrderDetail from '../CommunityItemOrderDetail/CommunityItemOrderDetail';
import { NoRecords } from '../../NoRecords/NoRecords';

const CommunityItemOrders = (props: any) => {
  const { routeData, back } = useNavigationService();

  let defaultFilter: any = {
    fromDate: formatService.getYearStartDate(),
    toDate: formatService.getCurrentDateForServer(),
    orderStatusId: -1,
    stallId: -1,
    communityId: routeData.communityId
  };
  const initialList = [];

  const [orderList, setOrderList] = useState(initialList);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const [orderStatus, setOrderStatus] = useState(initialList);
  const [stalls, setStalls] = useState(initialList);
  const [communityList, setCommunityList] = useState([]);

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let filterObj = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      orderStatusId: filter.orderStatusId,
      stallId: filter.stallId,
      communityId: filter.communityId
    }
    if (item.name == "fromDate") {
      filterObj.fromDate = formatService.getDateStringForServer(item.value);
    }
    else if (item.name == "toDate") {
      filterObj.toDate = formatService.getDateStringForServer(item.value);
    }
    else if (item.name == "orderStatusId") {
      filterObj.orderStatusId = item.value;
    }
    else if (item.name == "stallId") {
      filterObj.stallId = item.value;
    }
    else if (item.name == 'communityId') {
      filterObj.communityId = item.value;
      initLookup(filterObj.communityId);
    }
    getOrderList(filterObj);
  };

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getOrderList = (filters) => {
    let inputData = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      stallId: filters.stallId,
      orderStatusId: filters.orderStatusId,
      communityId: filters.communityId
    };
    let apiOptions: any = {
      url: 'order/all/dashboard',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getOrderListCallBack(res);
    })
  };

  const getOrderListCallBack = (res) => {
    setOrderList(res.data);
  };

  const viewOrderDetail = (item: any) => {
    setOpen(true);
    setOrderId(item.id);
  };

  const handleClose = () => {
    setOpen(false);
    setOrderId(0);
  };

  const initLookup = (communityId) => {
    let inputData = {
      lookups: {
        OrderStatus: { default: true },
        Stalls: { default: true }
      },
      filters: {
        communityId: communityId,
        tier4Flag: true
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      lookupCallBack(res);
    });
  };

  const lookupCallBack = (res) => {
    let orderStatusRes = res.data.OrderStatus;
    let stalls = res.data.Stalls;
    if (orderStatusRes.length > 0) {
      let itemIdx = _.findIndex(orderStatusRes, { code: "Processing" });
      orderStatusRes.splice(itemIdx, 1);
      setOrderStatus(orderStatusRes);
    }
    setStalls(stalls);
  };

  const navToBack = () => {
    back();
  }

  const getMyRegionCommunities = () => {
    let inputData = {
      lookups: {
        MyRegionCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyRegionCommunitiesCallBack(response);
    })
  };

  const getMyRegionCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyRegionCommunities);
  };

  const getMyZoneCommunities = () => {
    let inputData = {
      lookups: {
        MyZoneCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyZoneCommunitiesCallBack(response);
    })
  }

  const getMyZoneCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyZoneCommunities);
  };

  const getMyAdminCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
  };

  const getMyAdminCommunitiesCallBack = (res) => {
    let result = res.data;
    let commuArr: any = [];
    for (let item of result) {
      let itemObj: any = {
        id: item.id,
        text: item.name
      };
      commuArr.push(itemObj);
    }
    setCommunityList(commuArr);
  };

  const initCalls = () => {
    if (routeData.parent == 'REGIONALMANAGER') {
      getMyRegionCommunities();
    }
    else if (routeData.parent == 'ZONALMANAGER') {
      getMyZoneCommunities();
    }
    else if (routeData.parent == 'COMMADMIN') {
      getMyAdminCommunities();
    }
  }

  useEffect(() => {
    initCalls();
    initLookup(filter.communityId);
    getOrderList(filter);
  }, []);

  const body = (
    <div className="communityitemorders-ui pt-5">
      <div className="content-container">
        <div className="content">
          <div className="title-font-breadcrumbs custom-head pl-4 pt-4">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
                <b>{routeData.communityName}</b>
              </Link>
              <Typography color="primary" className="breadcrumbs-style">Orders</Typography>
            </Breadcrumbs>
          </div>
          {/* Desktop View */}
          <div className='deskview'>
            <div className="row mt-2 px-5 justify-content-between">
              <div className="col-2 px-0">
                <FxDate
                  name="fromDate"
                  variant="standard"
                  size="small"
                  label="From Date"
                  value={filter.fromDate}
                  onValueChange={handleInputChange}
                />
              </div>
              <div className="col-2 px-0">
                <FxDate
                  name="toDate"
                  variant="standard"
                  size="small"
                  label="To Date"
                  value={filter.toDate}
                  onValueChange={handleInputChange}
                />
              </div>
              <div className="col-2 px-0 pt-4">
                <FxSelect name="orderStatusId" variant="outlined" label="Order Status"
                  options={orderStatus}
                  selectedValue={filter.orderStatusId}
                  valueField="id"
                  displayField="text"
                  size="small"
                  style={{ width: 260 }}
                  onValueChange={handleInputChange} />
              </div>
              <div className="col-2 px-0 pt-4">
                <FxSelect name="stallId" variant="outlined" label="Stall"
                  options={stalls}
                  selectedValue={filter.stallId}
                  valueField="id"
                  displayField="text"
                  size="small"
                  style={{ width: 260 }}
                  onValueChange={handleInputChange} />
              </div>
              <div className='col-2 px-0 pt-4'>
                <FxSelect name="communityId" variant="outlined"
                  label="Community"
                  options={communityList}
                  selectedValue={filter.communityId}
                  valueField="id"
                  displayField="text"
                  size="small"
                  style={{ width: 260 }}
                  onValueChange={handleInputChange} />
              </div>
            </div>

            <div className='my-4 mx-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell>Delivery Date</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell>Community</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.length == 0 &&
                      <TableCell colSpan={10}>
                        <NoRecords />
                      </TableCell>
                    }
                    {orderList.length > 0 && orderList.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.placedDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>
                          <div className='row'>
                            <span>{row.consumerName}</span>
                            <span className='pl-2'>{row.consumerPhone}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <span>{row.communityName}</span>
                        </TableCell>
                        <TableCell className='text-right'>#{row.orderIdentifier}</TableCell>
                        <TableCell>
                          {row.orderStatus}
                          {
                            row.orderStatus === 'Cancelled' &&
                            <span className='pl-2'>({row.cancelledRemarks})</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span className='payment-style'>
                                  {'Card :' + itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Tablet View */}
          <div className='tabview'>
            <div className='ml-0 mt-4'>
            </div>

            <div className='my-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell>Delivery Date</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row: any, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.placedDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>
                          <div className='row'>
                            <span>{row.consumerName}</span>
                            <span className='pl-2'>{row.consumerPhone}</span>
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>#{row.orderIdentifier}</TableCell>
                        <TableCell>
                          {row.orderStatus}
                          {
                            row.orderStatus === 'Cancelled' &&
                            <span className='pl-2'>({row.cancelledRemarks})</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span className='payment-style'>
                                  {'Card :' + itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Mobile View */}
          <div className='mobileview mb-4'>
            <div className='ml-0 mt-4 mb-4'>
            </div>
            {orderList.map((row: any, i) => (
              <div>
                <Card className="mt-3 py-2 px-3">
                  <div className='row justify-content-between px-2'>
                    <div>
                      <div className='font-13 bold-text'>#{row.orderIdentifier}</div>
                      <div className='font-11'><FxDateDisplay value={row.placedDate} /></div>
                    </div>
                    <div className='text-right'>
                      <div className='font-13 bold-text'><FxCurrency value={row.orderTotal} /></div>
                      <div className='font-11'>
                        {row.orderStatus}
                        {
                          row.orderStatus === 'Cancelled' &&
                          <span className='pl-2'>({row.cancelledRemarks})</span>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-2'>
                    <div className='font-13'>{row.stallName}</div>
                    <div className='font-13 text-right'>
                      {row.orderTypeId == 1 &&
                        <span>Delivery</span>
                      }
                      {row.orderTypeId == 2 &&
                        <span>Pickup</span>
                      }
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-1'>
                    <div className='font-13'>
                      {row.consumerName}
                    </div>
                    <div className='font-13'>
                      {row.consumerPhone}
                    </div>
                  </div>
                  <div className='row justify-content-between px-2 pt-1'>
                    <div className='font-12'>
                      {row.orderPayments && row.orderPayments.map((itemObj, i) => (
                        <div>
                          {itemObj.paymentMode == 'cod' &&
                            <span className='payment-style'>
                              Cash / {itemObj.paymentStatus}
                            </span>
                          }
                          {itemObj.paymentMode == 'stripe' &&
                            <span className='payment-style'>
                              {'Card :' + itemObj.paymentStatus}
                            </span>
                          }
                          {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                            <span className='payment-style'>
                              {itemObj.paymentMode} / {itemObj.paymentStatus}
                            </span>
                          }
                        </div>
                      ))
                      }
                    </div>
                    <div className='text-right'>
                      <Button variant='outlined' size='small' className='button-style' onClick={() => viewOrderDetail(row)}>View Order</Button>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>

        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <CommunityItemOrderDetail orderId={orderId} handleClose={handleClose} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );
}

export default CommunityItemOrders;