import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import './ForgotPassword.scss';
import { AlertService, FxInput, http } from '../fx-core';
import { Link } from '@material-ui/core';

const ForgotPassword = (props: any) => {

  let defaultItem: any = {
    identity: '',
    otp: '',
    newPassword: '',
    confirmPassword: '',
    id: 0
  };

  const [item, setItem] = useState(defaultItem);
  const [error, setError] = useState(null);
  const [minError, setMinError] = useState(null);
  const [matchError, setMatchError] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getOTP = () => {
    if (!item.identity) {
      setError("This field is required");
      return;
    }
    let inputData = {
      identity: item.identity
    }
    let apiOptions: any = {
      url: `users/my/password/otp/${inputData.identity}`
    };
    http.get(apiOptions).then(res => {
      setCurrentStep(2);
      setError(null);
    })
  };

  const verifyOTP = () => {
    if (!item.otp) {
      setError("Code is required");
      return;
    }
    let inputData = {
      identity: item.identity,
      otp: item.otp
    }
    let apiOptions: any = {
      url: 'users/fgtpwd/verifyotp', data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result.userId > 0) {
        handleItemChange({ "id": result.userId });
        setCurrentStep(3);
        setError(null);
      }
      else {
        AlertService.showErrorMsg("Please enter valid code");
      }
    })
  };

  const updatePassword = () => {
    setError(null);
    setMinError(null);
    setMatchError(null);
    if (!item.newPassword || !item.confirmPassword) {
      setError(true);
      return;
    }
    if (item.newPassword != item.confirmPassword) {
      setMatchError("Passwords must match");
      return;
    }

    let inputData = {
      identity: item.identity,
      otp: item.otp,
      password: item.newPassword,
      id: item.id
    }
    let apiOptions: any = {
      url: 'users/fgtpwd/save', data: inputData
    };
    http.put(apiOptions).then(res => {
      AlertService.showSuccessMsg();
      props.forgotPwdCallBack();
    })
  };

  const navToBack = () => {
    props.forgotPwdCallBack();
  }

  const body = (
    <div className="fgtpwd-ui mt-5">
      {currentStep == 1 &&
        <div className="m-2">
          <div className="m-1">
            <FxInput name="identity" variant="outlined" label="Username/Email/Mobile*"
              value={item.identity} onValueChange={handleInputChange} fullWidth />
            {error && !item.identity &&
              <div className="error"> {error} </div>}
          </div>

          <div className="m-1 pt-3 text-center">
            <Button variant="contained" size="medium" color="primary" fullWidth onClick={getOTP}>
              Send Code
            </Button>
          </div>
        </div>
      }

      {currentStep == 2 &&
        <div className="m-2">
          <div className="m-1">
            <FxInput name="otp" variant="outlined" label="Enter Code*" type="password"
              value={item.otp} onValueChange={handleInputChange} fullWidth />
            {error && !item.otp &&
              <div className="error"> {error} </div>}
          </div>

          <div className="m-1 pt-3 text-center">
            <Button variant="contained" size="medium" color="primary" fullWidth onClick={verifyOTP}>
              Verify Code
            </Button>
          </div>
        </div>
      }

      {currentStep == 3 &&
        <div className="m-2">
          <div className="m-1">
            <FxInput name="newPassword" variant="standard" label="New Password*" type="password"
              value={item.newPassword} onValueChange={handleInputChange} fullWidth />
            {error && !item.newPassword &&
              <div className="error"> New Password is required </div>}
            {minError &&
              <div className="error"> {minError} </div>}
          </div>

          <div className="m-1 pt-1">
            <FxInput name="confirmPassword" variant="standard" label="Confirm Password*" type="password"
              value={item.confirmPassword} onValueChange={handleInputChange} fullWidth />
            {error && !item.confirmPassword &&
              <div className="error"> Confirm Password is required </div>}
            {matchError &&
              <div className="error"> {matchError} </div>}
          </div>

          <div className="m-1 pt-3 text-center">
            <Button variant="contained" size="medium" color="primary" onClick={updatePassword}>
              Update
            </Button>
          </div>
        </div>
      }
      {props.fromLanding &&
        <div className="m-2 font-16 text-center py-3">
          <Link onClick={navToBack} className="font-weight-bold pointer">Back</Link>
        </div>
      }

    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}
export default ForgotPassword;
