import React, { useEffect, useState } from 'react';
import './StallTimings.scss';
import { FxTime, http, localstorage, formatService, AlertService } from '../../fx-core';
import { Dialog, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@material-ui/core';
import _ from "lodash";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const StallTimings = (props) => {

  const displayDayMap = {
    'su': { display: 'Sun', displayOrder: 1 },
    'mo': { display: 'Mon', displayOrder: 2 },
    'tu': { display: 'Tue', displayOrder: 3 },
    'we': { display: 'Wed', displayOrder: 4 },
    'th': { display: 'Thu', displayOrder: 5 },
    'fr': { display: 'Fri', displayOrder: 6 },
    'sa': { display: 'Sat', displayOrder: 7 }
  };
  const defaultStallTimings = [
    { id: 0, stallId: 0, communityId: 0, day: 'su', openTime: null, closeTime: null, isOpen: false, status: 0 },
    { id: 0, stallId: 0, communityId: 0, day: 'mo', openTime: null, closeTime: null, isOpen: false, status: 0 },
    { id: 0, stallId: 0, communityId: 0, day: 'tu', openTime: null, closeTime: null, isOpen: false, status: 0 },
    { id: 0, stallId: 0, communityId: 0, day: 'we', openTime: null, closeTime: null, isOpen: false, status: 0 },
    { id: 0, stallId: 0, communityId: 0, day: 'th', openTime: null, closeTime: null, isOpen: false, status: 0 },
    { id: 0, stallId: 0, communityId: 0, day: 'fr', openTime: null, closeTime: null, isOpen: false, status: 0 },
    { id: 0, stallId: 0, communityId: 0, day: 'sa', openTime: null, closeTime: null, isOpen: false, status: 0 }
  ];
  let defaultContext = {
    isOpenFlag: false
  }
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [communityId, setCommunityId] = useState(parseInt(localstorage.getItem('communityId')));
  const [stallTimings, setStallTimings] = useState(defaultStallTimings);
  const [message, setMessage] = useState(null);
  const [selectedItem, setSelectedItem] = useState({ id: 0, day: "", status: 0 });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleCurrentContextItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleOpenChange = (event, itemObj) => {
    if (event.target.checked) {
      itemObj.isOpen = true;
    }
    else {
      itemObj.isOpen = false;
    }
    setStallTimings(stallTimings);
    handleCurrentContextItemChange({ "isOpenFlag": itemObj.isOpen });
  }

  const handleDateChange = (data,item) => {
    if(data.name == 'openTime')
    {
      item.openTime = data.value;
    }
    else if(data.name == 'closeTime')
    {
      item.closeTime = data.value;
    }
  }

  const getStallTimings = () => {
    let inputData = {
      stallId: props.stallId,
      communityId: communityId
    }
    let apiOptions: any = {
      url: `stalltiming/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result.length > 0) {
        for (let item of result) {
          item.openTime = item.openTime != null ? formatService.getConvertedTimeForDisplay(item.openTime) : null;
          item.closeTime = item.closeTime != null ? formatService.getConvertedTimeForDisplay(item.closeTime) : null;
          item.displayOrder = displayDayMap[item.day].displayOrder;
        }
        let orderedStallTimings = _.orderBy(result, ['displayOrder'], ['asc']);
        setStallTimings(orderedStallTimings);
      }
    })
  };

  const addNewStallTiming = (item) => {
    let stallTimingList:any = [];
    for (let item of stallTimings) {
      stallTimingList.push(item);
    }
    let index = 0;
    if (item.id > 0) {
      index = _.findIndex(stallTimings, { id: item.id });
    }
    else {
      index = _.findIndex(stallTimings, { day: item.day });
    }
    let newItem = { id: 0, stallId: 0, communityId: 0, day: item.day, openTime: null, closeTime: null, isOpen: false, status: 0 };
    stallTimingList.splice(index + 1, 0, newItem);
    setStallTimings(stallTimingList);
  }

  const saveStallTimings = () => {
    for (let item of stallTimings) {
      item.stallId = props.stallId;
      item.communityId = communityId;
      item.openTime = item.openTime != null ? formatService.getTimeString(item.openTime) : null;
      item.closeTime = item.closeTime != null ? formatService.getTimeString(item.closeTime) : null;
    }
    let inputData = {
      stallId: props.stallId,
      communityId: communityId,
      stallTimingList: stallTimings
    }
    let apiOptions: any = {
      url: `stalltiming/save`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      saveStallTimingsCallback();
    })
  }

  const saveStallTimingsCallback = () => {
    AlertService.showSuccessMsg();
    props.handleStallTimingsClose();
  }

  const confirmDelete = (item: any, index: any) => {
    setSelectedIndex(index);
    setSelectedItem(item);
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + displayDayMap[item.day].display + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    deleteStallTiming();
  }

  const deleteStallTiming = () => {
    let lookupItem = stallTimings.filter(i => i.day == selectedItem.day && i.status != 2);
    if (lookupItem && lookupItem.length > 1) {
      if (selectedItem.id) {
        selectedItem.status = 2;
      }
      else {
        stallTimings.splice(selectedIndex, 1);
        setStallTimings(stallTimings);
      }
    }
    else {
      AlertService.showErrorMsg("There should be atleast one record for each day");
    }
  };

  const handleClose = () => {
    props.handleStallTimingsClose();
  }

  useEffect(() => {
    getStallTimings();
  }, []);

  return (
    <div className='stalltimings-ui'>
         <div className='row title-font py-1 justify-content-between px-2 drawer-header'>
        <span className="col-11">
          <span>Stall Timings</span>
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>        
      <div className='mx-3 py-5'>   
        <div className='my-4 stall-timing-scroll'>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell>Days</TableCell>
                  <TableCell>Open</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {stallTimings.map((row, i) => (
                <TableBody>
                  {row.status != 2 &&
                    <TableRow>
                      <TableCell>{displayDayMap[row.day].display}</TableCell>
                      <TableCell>
                        <span>
                          <Checkbox name="isOpen" color="primary" checked={row.isOpen} onChange={(e) => handleOpenChange(e, row)} icon={<CheckBoxOutlineBlankIcon
                            fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                        </span>
                      </TableCell>
                      <TableCell>
                        {row.isOpen &&
                          <FxTime name="openTime" variant="outlined" value={row.openTime} 
                          onValueChange={(e)=>handleDateChange(e,row)} className="timestyle" />
                        }
                        {!row.isOpen &&
                          <FxTime name="openTime" variant="outlined" value={row.openTime} disabled className="timestyle" />
                        }
                      </TableCell>
                      <TableCell>
                        {row.isOpen &&
                          <FxTime name="closeTime" variant="outlined" value={row.closeTime} 
                          onValueChange={(e)=>handleDateChange(e,row)} className="timestyle" />
                        }
                        {!row.isOpen &&
                          <FxTime name="closeTime" variant="outlined" value={row.closeTime} disabled className="timestyle" />
                        }
                      </TableCell>
                      <TableCell>
                        <span>
                          <span><i className="fas fa-trash-alt font-13 pointer" onClick={() => confirmDelete(row, i)}></i></span>
                          <span className='pl-4 ml-2'><i className="fas fa-plus font-13 pointer" onClick={() => addNewStallTiming(row)}></i></span>
                        </span>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>      
      </div>
      <div className='px-3 py-2 drawer-footer'>
          <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveStallTimings}><b>Save</b></Button>
        </div>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
    </div>
  );
}
export default StallTimings;