import React, { useEffect, useState } from 'react';
import { FxInput, http, AlertService, formValidator, FxSelect, FxTextarea, general } from '../../fx-core';
import './ServiceForm.scss';
import { Button, Dialog } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';

const ServiceForm = (props) => {
  const defaultItem = {
    serviceCode: "",
    serviceName: "",
    description: "",
    displayOrder: 0,
    defaultBehaviorId: -1,
    id: props.serviceId ? props.serviceId : 0,
    imagePath: "",
    imageUrl: ""
  };

  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [serviceBehaviors, setServiceBehaviors] = useState([]);
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});

  const initMethods = () => {
    initLookup();
    if (item.id > 0) {
      getServiceById();
    }
  }

  const initLookup = () => {
    let inputData = {
      lookups: {
        ServiceBehaviors: { default: true }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      initLookupCallBack(res);
    })
  }

  const initLookupCallBack = (res) => {
    const result = res.data;
    setServiceBehaviors(result.ServiceBehaviors);
  }

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const validationMap_Item = {
    serviceCode: ["required"],
    serviceName: ["required"],
    defaultBehaviorId: ["select"]
  };

  const getValidationMap = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidationMap();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidationMap();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const getServiceById = () => {
    let apiOptions: any = {
      url: `service/edit/${item.id}`
    };
    http.get(apiOptions).then(res => {
      getServiceByIdCallBack(res);
    })
  }

  const getServiceByIdCallBack = (res) => {
    let result = res.data;
    result.imageUrl = general.getImageUrl(result.imagePath);
    setItem(result);
  }

  const saveService = () => {
    const isValid = bulkValidate();
    if (isValid) {
      let apiOptions: any = {
        url: `service`,
        data: item
      };
      http.post(apiOptions).then(res => {
        saveServiceCallBack(res);
      })
    }
  };

  const saveServiceCallBack = (res) => {
    AlertService.showSuccessMsg();
    handleClose();
  }

  const handleClose = () => {
    props.closeServiceForm();
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: item.id,
      cameraConfig: { url: `service/savecameraimage` },
      fileUploadConfig: { url: `service/savefileimage` }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (item.id > 0) {
      getServiceById();
    }
    else {
      let imagePath = res.filePath;
      let imageUrl = general.getImageUrl(imagePath);
      let itemObj = {
        imagePath,
        imageUrl
      }
      handleItemChange(itemObj);
    }
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  useEffect(() => {
    initMethods();
  }, []);


  return (
    <div className='serviceform-ui'>
      <div className='row title-font py-1 px-2 drawer-header'>
        <span className="col-11">
          Service Form
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>

      <div className='p-3 pt-5'>
        <div className='row p-3'>
          <div className="col-2 text-center">
            <div>
              <a className="pointer">
                {!item.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                {item.imageUrl && <img src={item.imageUrl} alt="prod" width={100} height={100}></img>}
              </a>
              <div className="text-center camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </div>
          </div>
          <div className='col-12 p-3'>
            <FxInput name="serviceCode" variant="outlined" fullWidth size="small" label="Service Code"
              value={item.serviceCode} onValueChange={handleInputChange} disabled={item.id > 0} />
            {hasError("serviceCode", "required") &&
              <div className="error">Service Code is required</div>
            }
          </div>
          <div className='col-12 p-3'>
            <FxInput name="serviceName" variant="outlined" fullWidth size="small" label="Service Name"
              value={item.serviceName} onValueChange={handleInputChange} />
            {hasError("serviceName", "required") &&
              <div className="error">Service Name is required</div>
            }
          </div>
          <div className='col-12 p-3'>
            <FxTextarea name="description" variant="outlined" fullWidth size="small" label="Description"
              value={item.description} onValueChange={handleInputChange} maxRows={4} />
          </div>
          <div className='col-12 p-3'>
            <FxInput name="displayOrder" variant="outlined" fullWidth size="small" label="Display Order"
              value={item.displayOrder} onValueChange={handleInputChange} type="number" />
          </div>
          <div className='col-12 p-3'>
            <FxSelect name="defaultBehaviorId" variant="outlined" label="Default"
              options={serviceBehaviors}
              selectedValue={item.defaultBehaviorId}
              valueField="id"
              displayField="text"
              size="small"
              fullWidth
              onValueChange={handleInputChange} />
            {
              hasError("defaultBehaviorId", "select") &&
              <div className="error">Default is required</div>
            }
          </div>
        </div>
      </div>
      <div className='px-4 py-2 drawer-footer'>
        <div className='px-4 row'>
          <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveService}><b>Save</b></Button>
        </div>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
    </div>
  );
}
export default ServiceForm;
