import React, { useEffect, useState } from 'react';
import './CustomizeForm.scss';
import { FxCurrency, http, general } from '../../fx-core';
import * as _ from 'lodash';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import dummyImg from "../../../assets/images/dummy.png";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from '@material-ui/core/Dialog';
import ItemCustomizeAlert from '../ItemCustomizeAlert/ItemCustomizeAlert';
import { Checkbox } from '@material-ui/core';

const CustomizeForm = (props) => {

    let defaultItem: any = {
        itemName: ""
    };

    const initialList = [];
    const [stallItem, setStallItem] = useState(defaultItem);
    const [groupDetails, setGroupDetails] = useState(initialList);
    const [groupHeaderList, setGroupHeaderList] = useState(initialList);
    const [customizedInfoDetails, setCustomizedInfoDetails] = useState(initialList);
    const [cusAlertOpen, setCusAlertOpen] = useState(false);
    const [cusAlertMessage, setCusAlertMessage] = useState(initialList);

    const getStallItemById = () => {
        let inputData = {
            id: props.stallItemId
        }
        let apiOptions: any = {
            url: `portal/stallitem/${inputData.id}`
        };
        http.get(apiOptions).then(response => {
            getStallItemCallback(response.data);
        });
    };

    const getStallItemCallback = (res) => {
        setStallItem(res);
    };

    const getActiveGroupHeaderDetails = () => {
        let inputData = {
            id: props.stallItemId
        }
        let apiOptions: any = {
            url: `portal/stallitemcustomize/stallitem/${inputData.id}/active`,
        };
        http.get(apiOptions).then(response => {
            getActiveGroupHeaderDetailsCallback(response.data);
        });
    }

    const getActiveGroupHeaderDetailsCallback = (res) => {
        let groupHeaders: any = [];
        let customizeInfoArr: any = [];
        for (let item of res) {
            groupHeaders.push(item.header);
            for (let detail of item.details) {
                detail.imageUrl = general.getImageUrl(detail.imagePath);
                if (detail.isDefault) {
                    detail.isSelected = true;
                    detail.isChecked = true;
                    if (item.header.groupTypeId == 1) {
                        let r_details: any = {
                            stallItemId: props.stallItemId,
                            groupName: item.header.groupName,
                            stallItemCustomizeHeaderId: item.header.id,
                            stallItemCustomizeDetailId: detail.id,
                            stallItemCustomizeDetailPrice: detail.price,
                            description: detail.description,
                            groupTypeId: 1,
                            minCount: 0,
                            maxCount: 0
                        };
                        customizeInfoArr.push(r_details);
                    }
                    else if (item.header.groupTypeId == 2) {
                        let c_details: any = {
                            stallItemId: props.stallItemId,
                            groupName: item.header.groupName,
                            stallItemCustomizeHeaderId: item.header.id,
                            stallItemCustomizeDetailId: detail.id,
                            stallItemCustomizeDetailPrice: detail.price,
                            description: detail.description,
                            groupTypeId: 2,
                            minCount: item.header.minCount,
                            maxCount: item.header.maxCount
                        };
                        customizeInfoArr.push(c_details);
                    }
                }
                else {
                    detail.isSelected = false;
                    detail.isChecked = false;
                }
            }
        }
        setCustomizedInfoDetails(customizeInfoArr);
        setGroupDetails(res);
        setGroupHeaderList(groupHeaders);
    };

    const selectedDetailChange = (item) => {
        handleGroupDetailRadioSelection(item);
        computeCustomizedDetailForRadioType(item);
    };

    const handleGroupDetailRadioSelection = (item) => {
        let grpDetailArr: any = groupDetails.map((gd: any) => {
            if (gd.header.id == item.stallItemCustomizeHeaderId) {
                let details = gd.details.map((d) => {
                    if (d.id == item.id) {
                        d.isChecked = true;
                    }
                    else {
                        d.isChecked = false;
                    }
                    return d;
                });
                gd.details = details;
            }
            return gd;
        });
        setGroupDetails(grpDetailArr);
    };

    const computeCustomizedDetailForRadioType = (detail) => {
        let header: any = groupHeaderList.find((i: any) => i.id == detail.stallItemCustomizeHeaderId);
        //Need to check same header exists in this.customizedInfoDetails
        let customizedInfoArr: any = customizedInfoDetails.filter((d: any) => d.stallItemCustomizeHeaderId != detail.stallItemCustomizeHeaderId);
        let details: any = {
            stallItemId: props.stallItemId,
            groupName: header.groupName,
            stallItemCustomizeHeaderId: header.id,
            stallItemCustomizeDetailId: detail.id,
            stallItemCustomizeDetailPrice: detail.price,
            description: detail.description,
            groupTypeId: 1,
            minCount: 0,
            maxCount: 0
        };
        customizedInfoArr.push(details);
        setCustomizedInfoDetails(customizedInfoArr);
    };

    const handleGroupDetailSelection = (item, isSelected) => {
        let grpDetailArr: any = groupDetails.map((gd: any) => {
            if (gd.header.id == item.stallItemCustomizeHeaderId) {
                let details = gd.details.map((d) => {
                    if (d.id == item.id) {
                        d.isSelected = isSelected;
                    }
                    return d;
                });
                gd.details = details;
            }
            return gd;
        });
        setGroupDetails(grpDetailArr);
    };

    const addItem = (item) => {
        handleGroupDetailSelection(item, true);
        addCustomizedDetails(item);
    };

    const removeItem = (item) => {
        handleGroupDetailSelection(item, false);
        let cusInfoArr = customizedInfoDetails.filter((i: any) => i.stallItemCustomizeDetailId != item.id);
        setCustomizedInfoDetails(cusInfoArr);
    };

    const handleCheckbox = (e, item) => {
        let checkVal = e.target.checked;
        if (checkVal) {
            addItem(item);
        }
        else {
            removeItem(item);
        }
    };

    const addCustomizedDetails = (detail) => {
        let header: any = groupHeaderList.find((i: any) => i.id == detail.stallItemCustomizeHeaderId);
        let details: any = {
            stallItemId: props.stallItemId,
            groupName: header.groupName,
            stallItemCustomizeHeaderId: header.id,
            stallItemCustomizeDetailId: detail.id,
            stallItemCustomizeDetailPrice: detail.price,
            description: detail.description,
            groupTypeId: 2,
            minCount: header.minCount,
            maxCount: header.maxCount
        };
        let customInfoArr = customizedInfoDetails.concat(details);
        setCustomizedInfoDetails(customInfoArr);
    };

    const saveCustomize = () => {
        let latestCustomizedInfoDetails = _.orderBy(customizedInfoDetails, ['stallItemCustomizeHeaderId'], ['asc'])
        const customizedMultiInfo: any = latestCustomizedInfoDetails.filter((g: any) => g.groupTypeId == 2);
        if (customizedMultiInfo.length > 0) {
            let groupedHeaderMap = {};
            for (let item of customizedMultiInfo) {
                const stallItemCustomizeHeaderId = item.stallItemCustomizeHeaderId;
                if (!groupedHeaderMap[stallItemCustomizeHeaderId]) {
                    groupedHeaderMap[stallItemCustomizeHeaderId] = {
                        stallItemCustomizeHeaderId: stallItemCustomizeHeaderId,
                        groupName: item.groupName,
                        minCount: item.minCount,
                        maxCount: item.maxCount
                    };
                }
            }
            let errorMsgArr: any = [];
            for (let key in groupedHeaderMap) {
                const item = groupedHeaderMap[key];
                let details = _.filter(customizedMultiInfo, { stallItemCustomizeHeaderId: item.stallItemCustomizeHeaderId });
                let message = "";
                if (item.minCount > 0 && item.maxCount > 0) {
                    if (details.length < item.minCount && details.length < item.maxCount) {
                        message = "Min " + item.minCount + " & Max " + item.maxCount;
                    }
                    else if (details.length >= item.minCount && details.length < item.maxCount) {
                        message = "Max " + item.maxCount;
                    }
                    else if (details.length > item.maxCount) {
                        message = "Max " + item.maxCount;
                    }
                }
                else if (item.minCount > 0 && details.length < item.minCount) {
                    message = "Min " + item.minCount;
                }
                else if (item.maxCount > 0 && details.length > item.maxCount) {
                    message = "Max " + item.maxCount;
                }
                if (message) {
                    message += " condition not met!"
                    errorMsgArr.push({ groupName: item.groupName, messsage: message });
                }
            }
            if (errorMsgArr.length > 0) {
                setCusAlertMessage(errorMsgArr);
                openCusAlert();
            }
            else {
                props.handleCustomizedDetails(latestCustomizedInfoDetails, props.stallItemBatchId);
            }
        }
        else {
            props.handleCustomizedDetails(latestCustomizedInfoDetails, props.stallItemBatchId);
        }
    };

    const openCusAlert = () => {
        setCusAlertOpen(true);
    };

    const handleCusAlertClose = () => {
        setCusAlertOpen(false);
        setCusAlertMessage([]);
    };

    const closeCustomizeForm = () => {
        props.closeCustomizeForm();
    };

    useEffect(() => {
        getStallItemById();
        getActiveGroupHeaderDetails();
    }, []);

    return (
        <div className="customizeform-ui">
            <div>
                <div className="d-flex justify-content-between title-font-customize position-fixed py-1 px-4">

                    <div>
                        <span>{stallItem.itemName}</span>
                    </div>
                    <div className="pointer" onClick={closeCustomizeForm}>
                        <HighlightOffIcon fontSize="small" color="secondary" />
                    </div>
                </div>
                <div className="row px-4 pt-4 customize-content">
                    {
                        groupDetails.length > 0 && groupDetails.map((item: any, i) => (
                            <div className="col-12" key={i}>
                                <div className="pt-4 bold-text pb-2"> {item.header.groupName}</div>
                                {
                                    item.header.groupTypeId == 1 &&
                                    <div className="col-12">
                                        <RadioGroup row aria-label="position" name="position" defaultValue="eight">
                                            {
                                                item.details.length > 0 && item.details.map((itemObj, i) => (
                                                    <div className="col-12 row align-items-center alt-color" key={i}>
                                                        <div className="col-10">
                                                            <FormControlLabel
                                                                name={itemObj.description}
                                                                value={itemObj.id}
                                                                control={<Radio color="primary" size="small" />}
                                                                label={itemObj.description}
                                                                checked={itemObj.isChecked}
                                                                onChange={() => selectedDetailChange(itemObj)}
                                                            />
                                                        </div>
                                                        <div className="col-2 font-13">
                                                            {itemObj.price > 0 &&
                                                                <FxCurrency value={itemObj.price} />
                                                            }
                                                            {!itemObj.price &&
                                                                <FxCurrency value={0} />
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </RadioGroup>
                                    </div>
                                }
                                {
                                    item.header.groupTypeId == 2 &&
                                    <div className="col-12">
                                        {
                                            item.details.length > 0 && item.details.map((itemObj, i) => (
                                                <div className="col-12 row py-2 pl-2 align-items-center alt-color" key={i}>
                                                    <div className="col-10 d-flex align-items-center">
                                                        <Checkbox name={itemObj.id} color="primary" size="small" checked={itemObj.isSelected} value={itemObj.isSelected}
                                                            onChange={(e) => handleCheckbox(e, itemObj)} />
                                                        <div className='pl-2'>
                                                            {!itemObj.imageUrl && <img className="itemimg" src={dummyImg} alt="dummy"></img>}
                                                            {itemObj.imageUrl && <img className="itemimg" src={itemObj.imageUrl} alt="product"></img>}
                                                        </div>
                                                        <div className="pl-4 font-13">{itemObj.description}</div>
                                                    </div>
                                                    <div className='col-2 font-13'>
                                                        {itemObj.price > 0 &&
                                                            <FxCurrency value={itemObj.price} />
                                                        }
                                                        {!itemObj.price &&
                                                            <FxCurrency value={0} />
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="d-flex mt-4 py-2 pr-4 justify-content-end customize-footer">
                <span className="pl-2">
                    <Button variant="contained" size="medium" color='primary' onClick={saveCustomize}>Save</Button>
                </span>
            </div>
            <div>
                <Dialog
                    open={cusAlertOpen}
                    maxWidth="sm"
                    fullWidth
                    onClose={handleCusAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ItemCustomizeAlert errorMessages={cusAlertMessage} handleClose={handleCusAlertClose} />
                </Dialog>
            </div>
        </div>
    );
}
export default CustomizeForm;