import React, { useState, useEffect } from 'react';
import './ListingDetail.scss';
import Card from '@material-ui/core/Card';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import dummyImage from "./../../assets/images/dummy.png";
import { FxHumanDateDisplay, http, formatService, general, FxCurrency } from '../fx-core';
import Carousel from 'react-material-ui-carousel';
import LanguageIcon from '@material-ui/icons/Language';
import verifyTick from "../../assets/img/verifytick.png";

const ListingDetail = (props: any) => {
  let defaultItem: any = {
    stallName: "",
    stallAddress: "",
    title: "",
    startDateTime: "",
    endDateTime: "",
    description: "",
    stallContact: "",
    stallEmail: "",
    isStallVerified: false
  };

  const [item, setItem] = useState(defaultItem);
  const [images, setImages] = useState([]);
  const [imageCount, setImageCount] = useState(0);

  const getSaleById = () => {
    let apiOptions: any = {
      url: `portal/sale/${props.saleId}/stall`,
      data: {}
    };
    http.get(apiOptions).then(response => {
      getSaleCallBack(response.data);
    });
  }

  const getSaleCallBack = (item: any) => {
    item.imageUrl = general.getImageUrl(item.imagePath);
    let currentDateTime = formatService.getCurrentDateTime();
    let startDateTime = formatService.getConvertedDateTime(item.startDateTime);
    if (currentDateTime < startDateTime) {
      item.isStart = true;
    }
    else {
      item.isStart = false;
    }
    setItem(item);
  }

  const getSaleImages = () => {
    let apiOptions: any = {
      url: `portal/sale/${props.saleId}/images`,
      data: {}
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      for (let item of result) {
        item.imageUrl = general.getImageUrl(item.imagePath);
      }
      setImages(result);
      setImageCount(result.length);
    });
  }

  useEffect(() => {
    getSaleById();
    getSaleImages();
  }, []);

  return (
    <div className="listing-detail-ui">

      {/* Desktop View */}
      <div className="deskview pt-5">
        <div className="row justify-content-center">
          <Carousel>
            {
              images && images.map((item) => (
                <div className="text-center">
                  {/* {item.isDefault == true &&
                                        <div>Default Image</div>
                                    } */}
                  <div>
                    <img src={item.imageUrl} alt="image"></img>
                  </div>

                </div>
              ))}
          </Carousel>
        </div>
        <div className="row justify-content-center">
          {imageCount == 0 &&
            <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>
          }
        </div>
        <Card style={{ width: "400px", height: "200px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">

          <div>
            <div className="font-22 itemname-style text-center">
              {item.title}
            </div>
            <div>
              <span className="pl-2"> {item.stallName}</span>
              {
                item.isStallVerified &&
                <span className='ml-1'>
                  <img src={verifyTick} width="20" height="20" />
                </span>
              }
              <span className="float-right text-danger font-12 shopstatus-style">
                {item.isStart &&
                  <span>Starts
                    <span className="pl-1">
                      <FxHumanDateDisplay value={item.startDateTime} displayType="datetime" /></span>
                  </span>
                }
                {/* {!item.isStart &&
                                    <span>Ends
                                        <span className="pl-1">
                                            <FxHumanDateDisplay value={item.endDateTime} displayType="datetime" /></span>
                                    </span>
                                } */}
              </span>
            </div>
          </div>
          <div className="font-13 row pl-4">
            {item.stallAddress}
          </div>
          <div className="row pr-4 mr-1 pl-3 font-13 justify-content-between">
            <div className="row align-items-center pl-4 font-13 pt-1">
              <span><i className="fas fa-phone-alt"></i></span>
              {item.stallContact &&
                <span className="pl-1 font-12">{item.stallContact}</span>
              }
              {!item.stallContact &&
                <span className="pl-1">-----</span>
              }
            </div>
            <div className="row align-items-center pl-4 font-13 pt-1">
            <span><i className="fas fa-envelope"></i></span>
              {item.stallEmail &&
                <span className="pl-1 font-12">{item.stallEmail}</span>
              }
              {!item.stallEmail &&
                <span className="pl-1">-----</span>
              }
            </div>
          </div>
          <div className="row align-items-center pl-4 font-13 pt-1">
            <span><i className="fas fa-globe"></i></span>
            {item.website &&
              <span className='pl-1 font-12'>{item.website}</span>
            }
            {!item.website &&
              <span className='pl-1'>-----</span>
            }
          </div>
          <div className="font-12 text-success shopstatus-style pl-2">
            {item.description}
          </div>
        </Card>
      </div>

      {/* Mobile View */}
      <div className="mobileview pt-2">
        <div className="row justify-content-center">
          <Carousel>
            {
              images && images.map((item) => (
                <div className="text-center">
                  {/* {item.isDefault == true &&
                                        <div>Default Image</div>
                                    } */}
                  <div>
                    <img src={item.imageUrl} alt="image"></img>
                  </div>

                </div>
              ))}
          </Carousel>
        </div>
        <div className="row justify-content-center">
          {imageCount == 0 &&
            <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>
          }
        </div>
        <Card style={{ width: "350px", height: "180px" }} className="d-flex flex-column justify-content-between px-2 py-3">
          <div>
            <div className="font-22 itemname-style text-center">
              {item.title}
            </div>
            <div>
              <span className="pl-2"> {item.stallName}</span>
              {
                item.isStallVerified &&
                <span className='ml-1'>
                  <img src={verifyTick} width="20" height="20" />
                </span>
              }
              <span className="float-right text-danger font-12 shopstatus-style">
                {item.isStart &&
                  <span>Starts
                    <span className="pl-1">
                      <FxHumanDateDisplay value={item.startDateTime} displayType="datetime" /></span>
                  </span>
                }
                {!item.isStart &&
                  <span>Ends
                    <span className="pl-1">
                      <FxHumanDateDisplay value={item.endDateTime} displayType="datetime" /></span>
                  </span>
                }
              </span>
            </div>
          </div>
          <div className="font-13 row pl-4">
            {item.stallAddress}
          </div>
          <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
            <div className="row pl-3">
              <span> <PhoneIcon fontSize="inherit" /></span>
              <span className="pl-1">{item.stallContact}</span>
            </div>
            <div className="row px-0">
              <span> <EmailIcon fontSize="inherit" /></span>
              <span className="pl-1">{item.stallEmail}</span>
            </div>
          </div>
          {item.website &&
            <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
              <div className="row pl-3">
                <span><LanguageIcon fontSize='inherit' /></span>
                <span className="pl-1">{item.website}</span>
              </div>
            </div>
          }
          <div className="font-12 text-success shopstatus-style pl-2">
            {item.description}
          </div>
        </Card>
      </div>
    </div >
  );
}
export default ListingDetail;