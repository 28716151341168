import React, { useEffect, useState } from 'react';
import './Followers.scss';
import { http, useNavigationService } from '../../fx-core';
import { Badge } from '@material-ui/core';

const Followers = (props: any) => {
    const { navigate } = useNavigationService();
    let defaultContext = {
        followersCount: 0
    };
    const [currentContext, setCurrentContext] = useState(defaultContext);

    const getStallFollowers = () => {
        let inputData = {
            id: props.stallId
        }
        let apiOptions: any = {
            url: `follow/stall/${inputData.id}/count`,
            data: null
        };
        http.get(apiOptions).then(res => {
            const result = res.data;
            const data = { followersCount: result.followersCount };
            setCurrentContext(prevState => {
                return { ...prevState, ...data }
            });
        })
    };

    const openFollowedUsers = () => {
        let navData = {
            targetRoute: '/provider/followedusers/' + props.stallId,
            targetTitle: "Followed Users",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    }


    useEffect(() => {
        getStallFollowers();
    }, [props.stallId]);

    const body = (
        <div className="row followers-ui">
            {currentContext.followersCount > 0 &&
                <div className='col-12 pointer' onClick={openFollowedUsers}>
                    <span className='col-10'>True Fans</span>
                    <span className='col-2 float-right'>
                        <Badge badgeContent={currentContext.followersCount} color="primary"></Badge>
                    </span>
                </div>
            }
            {currentContext.followersCount == 0 &&
                <div className='col-12 pointer'>
                    <span className='col-10'>True Fans</span>
                    <span className='col-2 float-right'>
                        <Badge badgeContent="0" color="primary"></Badge>
                    </span>
                </div>
            }            
        </div>
    );

    return (
        <div>{body}</div>
    );

}

export default Followers;