import React, { useEffect, useState } from 'react';
import './SpecialCommunityList.scss';
import { FxInput, http, general } from '../../fx-core';
import { Button, Dialog, Paper, Table, TableBody, makeStyles, TableCell, TableContainer, TableHead, TableRow, Drawer } from '@material-ui/core';
import dummyImage from "../../../assets/images/dummy.png";
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import { NoRecords } from '../../NoRecords/NoRecords';
import SpecialCommunityEntryForm from '../SpecialCommunityEntryForm/SpecialCommunityEntryForm';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const SpecialCommunityList = (props) => {
  const classes = styles();
  let defaultFilter = {
    searchText: ""
  };
  let defaultSelItem = {
    id: 0,
    name: ""
  };
  const [filter, setFilter] = useState(defaultFilter);
  const [communityList, setCommunityList] = useState([]);
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});
  const [entryFormOpen, setEntryFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultSelItem);

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let filterObj = {
      searchText: filter.searchText
    }
    if (item.name == 'searchText') {
      filterObj.searchText = item.value;
    }
    getSplCommunityList(filterObj);
  };

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getSplCommunityList = (data) => {
    let inputData = {
      name: data.searchText
    };
    let apiOptions: any = {
      url: `community/admin/special/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        item.profileImageUrl = general.getImageUrl(item.profileImagePath);
      }
      setCommunityList(result);
    })
  }

  const openImages = (item: any) => {
    let config = {
      api: `community/${item.id}/images`,
      title: item.name
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
  }

  const manageSplCommunity = (item) => {
    setSelectedItem(item);
    setEntryFormOpen(true);
  }

  const handleEntryFormClose = () => {
    setEntryFormOpen(false);
    setSelectedItem(defaultSelItem);
  }

  useEffect(() => {
    getSplCommunityList(filter);
  }, []);

  return (
    <div className='splcommunitylist-ui pt-5'>
      <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
        <span className="col-8 pt-3 py-1 pl-4">
          <span>Special Communities</span>
        </span>
      </div>

      <div className="row col-12 my-3 ml-1 mr-5 justify-content-between">
        <div className="col-3">
          <FxInput name="searchText" variant="outlined" label="Search Community"
            size="small"
            value={filter.searchText}
            onValueChange={handleInputChange}
          />
        </div>
      </div>

      <div className='p-2 mx-3'>
        <div className='mb-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {communityList.length == 0 &&
                  <TableCell colSpan={5}>
                    <NoRecords />
                  </TableCell>
                }
                {communityList.length > 0 && communityList.map((row: any, i) => (
                  <TableRow>
                    <TableCell className='text-center'>
                      <span onClick={() => openImages(row)}>
                        {!row.profileImageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                        {row.profileImageUrl && <img src={row.profileImageUrl} alt="Stall" width="50" height="50"></img>}
                      </span>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.verificationStatus}</TableCell>
                    <TableCell>
                      <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => manageSplCommunity(row)}>Manage</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={entryFormOpen} onClose={handleEntryFormClose}>
        <SpecialCommunityEntryForm communityId={selectedItem.id} communityName={selectedItem.name} handleClose={handleEntryFormClose} />
      </Drawer>
    </div>
  );
}
export default SpecialCommunityList;