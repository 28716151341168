import React, { useEffect, useState } from 'react';
import './SubscriptionPlan.scss';
import { FxInput, FxDate, http, AlertService, formValidator, FxSelect, formatService, FxDateDisplay } from '../../../fx-core';
import * as _ from 'lodash';
import { Button } from '@material-ui/core';
import useRazorpay from '../../../utils/useRazorPay';

const SubscriptionPlan = (props) => {
  const Razorpay = useRazorpay();
  
  const defaultItem = {
    subscriptionPlanId: -1,
    startDate: "",
    endDate: "",
    amount: 0,
    subscriptionPaymentMode: "-1",
    stallId: props.stallId,
    userId: props.userId,
    id: props.subscriptionId > 0 ? props.subscriptionId : 0,
    couponCode: "",
    paymentStatus: "Pending",
  };
  const defaultSubscription = {
    subscriptionPlan: "",
    startDate: "",
    endDate: "",
    id: 0
  };
  const [item, setItem] = useState(defaultItem);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [subscription, setSubscription] = useState(defaultSubscription);

  const initLookup = () => {
    let inputData = {
      lookups: {
        SubscriptionPlans: { default: true },
        PaymentMode: { default: true }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      setSubscriptionPlans(result.SubscriptionPlans);
      setPaymentOptions(result.PaymentMode);
    })
    getSubscriptionByStallId();
  }

  const handleInputChange = (data) => {
    handleItemChange({ [data.name]: data.value });
    data.validations = getInputValidations(data.name);
    const validationResult = formValidator.validate(data);
    const validationData = { [data.name]: validationResult };
    updateErrors(validationData);
    if (data.name == "subscriptionPlanId" && data.value > 0) {
      getPlanDetailsByPlanId(data.value);
    }
    else if (data.name == "startDate") {
      loadEndDate(data.value, item.subscriptionPlanId);
    }
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getSubscriptionDetailsById = () => {
    let apiOptions: any = {
      url: `providersubscription/${props.subscriptionId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setItem(result);
    })
  }

  const getSubscriptionByStallId = () => {
    let inputData = {
      currentDate: formatService.getCurrentDateForServer(),
      stallId: item.stallId
    };
    let apiOptions: any = {
      url: `providersubscription/stall`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      if (result && result.subscription != null) {
        setSubscription(result.subscription);
        setCurrentStep(2);
      }
      // else if (props.subscriptionId > 0) {
      //   getSubscriptionDetailsById();
      // }
    })
  }

  //Validation area starts
  const validationMap_Item = {
    subscriptionPlanId: ["select"],
    startDate: ["required"],
    subscriptionPaymentMode: ["select"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const getPlanDetailsByPlanId = (planId) => {
    let apiOptions: any = {
      url: `subscriptionplan/${planId}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      let itemObj = {
        amount: result.amount
      };
      handleItemChange(itemObj);
      loadEndDate(item.startDate, planId);
    })
  }

  const loadEndDate = (startDate, planId) => {
    if (startDate) {
      let computedEndDate = computeEndDate(startDate, planId);
      handleItemChange({ endDate: computedEndDate });
    }
  }

  const computeEndDate = (startDate: any, planId: any) => {
    let endDate : any = null;
    let lookupItem : any = _.find(subscriptionPlans, { id: planId });
    if (lookupItem.code == 'Monthly') {
      endDate = formatService.getMonthEndDate(startDate);
    }
    else if (lookupItem.code == 'Yearly') {
      endDate = formatService.getYearEndDate(startDate);
    }
    return endDate;

  }

  const saveSubscription = () => {
    const isValid = bulkValidate();
    if (isValid) {
      item.startDate = formatService.getDateStringForServer(item.startDate);
      let apiOptions: any = {
        url: `providersubscription/save/payments`,
        data: item
      };
      http.post(apiOptions).then(res => {
        saveSubscriptionCallBack(res);
      })
    }
  };

  const navBackToReqContainer = (id) => {
    props.onSubscriptionCallback(id);
  }

  const saveSubscriptionCallBack = (res) => {
    let provSubscriptionId = res.data ? res.data.provSubscriptionId : 0;
    AlertService.showSuccessMsg();
    if (provSubscriptionId > 0 && item.subscriptionPaymentMode == 'razorpay' && item.paymentStatus == "Pending") {
      initiatePayment(provSubscriptionId);
    }
    else {
      navBackToReqContainer(provSubscriptionId);
    }
  }

  const initiatePayment = (provSubscriptionId) => {
    let inputData = {
      entityTypeId: 1,
      entityId: provSubscriptionId
    };
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res, provSubscriptionId);
    });
  }

  const openPaymentLink = (resp: any, provSubscriptionId: any) => {
    var item = resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        navBackToReqContainer(provSubscriptionId);
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const navigateBack = () => {
    navBackToReqContainer(0);
  }

  const navigateNext = () => {
    navBackToReqContainer(subscription.id);
  }

  useEffect(() => {
    initLookup();
  }, []);


  return (
    <div className='subscriptionplan-ui'>
      <div className='row justify-content-center pt-5 font-18 text-bold'>Subscription Plan</div>
      {currentStep == 1 &&
        <div className='row p-3 justify-content-center'>
          <div className='col-xs-12 col-md-8 p-3'>
            <FxSelect name="subscriptionPlanId" variant="outlined" label="Plan Name"
              options={subscriptionPlans}
              selectedValue={item.subscriptionPlanId}
              valueField="id"
              displayField="text"
              size="small"
              fullWidth
              onValueChange={handleInputChange} />
            {
              hasError("subscriptionPlanId", "select") &&
              <div className="error">Plan Name is required</div>
            }
          </div>

          <div className='col-xs-12 col-md-8 p-3'>
            <FxInput name="amount" variant="outlined" fullWidth size="small" label="Amount"
              value={item.amount || ""} disabled onValueChange={handleInputChange} />
          </div>

          <div className='col-xs-12 col-md-8 p-3'>
            <FxDate name="startDate" variant="outlined" size="small" fullWidth label="Start Date"
              value={item.startDate} onValueChange={handleInputChange} />
            {
              hasError("startDate", "required") &&
              <div className="error">Start Date is required</div>
            }
          </div>

          <div className='col-xs-12 col-md-8 p-3'>
            <FxDate name="endDate" variant="outlined" size="small" fullWidth label="End Date"
              value={item.endDate} onValueChange={handleInputChange} disabled />
          </div>

          <div className='col-xs-12 col-md-8 p-3'>
            <FxSelect name="subscriptionPaymentMode" variant="outlined" label="Select Payment"
              disabled={item.paymentStatus === "Paid"}
              options={paymentOptions}
              selectedValue={item.subscriptionPaymentMode}
              valueField="code"
              displayField="text"
              size="small"
              onValueChange={handleInputChange} />
            {hasError("subscriptionPaymentMode", "select") && (
              <div className="error row">Payment is required</div>
            )}
          </div>
          {
            item.subscriptionPaymentMode == 'coupon' &&
            <div className='col-xs-12 col-md-8 p-3'>
              <FxInput name="couponCode" variant="outlined" label="Coupon" fullWidth size="small"
                value={item.couponCode} onValueChange={handleInputChange} disabled={item.paymentStatus === "Paid"} />
            </div>
          }
          {
            item.subscriptionPaymentMode == "accounttransfer" &&
            <div className='col-xs-12 col-md-8 pt-1'>
              <div>A/C No : 50200095946891</div>
              <div>Name : AOTEAROA SOLUTIONS PVT LTD</div>
              <div>IFSC: HDFC0002231</div>
              <div>Branch: SARAVANAMPATTI BRANCH</div>
              <div>Account Type: CURRENT</div>
              <div className='mt-2 text-danger'>
                Kindly transfer the amount and then save. Request to be approved shortly.
              </div>
            </div>
          }
        </div>
      }
      {
        currentStep == 2 &&
        <div className='row p-3 pt-5 justify-content-center'>
          <div className='col-xs-12 col-md-8 p-3'>
            <span><b>Subscription Plan Name: </b></span>
            <span>{subscription.subscriptionPlan}</span>
          </div>
          <div className='col-xs-12 col-md-8 p-3'>
            <span><b>Start Date: </b></span>
            <span> <FxDateDisplay value={subscription.startDate} /></span>
          </div>
          <div className='col-xs-12 col-md-8 p-3'>
            <span><b>End Date: </b></span>
            <span><FxDateDisplay value={subscription.endDate} /></span>
          </div>
          <div className='col-xs-12 col-md-8 p-3'>
            <span><b>Status: </b></span>
            <span>Active</span>
          </div>          
        </div>
      }
      <div className='row justify-content-center pb-3'>
        {currentStep == 1 &&
          <div>
            <span className='pr-2'><Button variant="contained" size="medium" onClick={navigateBack}> Back </Button></span>
            <Button variant="contained" size="medium" color="primary" onClick={saveSubscription}>  Save  </Button>
          </div>
        }
        {currentStep == 2 &&
          <div>
            <span className='pr-2'><Button variant="contained" size="medium" onClick={navigateBack}> Back </Button></span>
            <Button variant="contained" size="medium" color="primary" onClick={navigateNext}>  Next  </Button>
          </div>
        }
      </div>
    </div>
  );
}
export default SubscriptionPlan;