import React from 'react';
import './ComingSoon.scss';
import comingsoon from '../../assets/comingsoon.svg';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useNavigationService } from './../fx-core';

const ComingSoon = (props: any) => {
    const { getRouteData, navigate, back, pageState } = useNavigationService();
    const navToCustomerDashboard = (item: any) => {
        back();
    }

    return (
        <div className="pt-5 pb-5 comingsoon-ui">
            <div className="title-font custom-head pt-3">
                <Breadcrumbs aria-label="breadcrumb">
                    <span color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCustomerDashboard}>
                        <b>Dashboard</b>
                    </span>
                </Breadcrumbs>
            </div>

            {/* Desktop View */}
            <div className="pt-5 pb-5 text-center deskview">
                <img className="pt-5 pb-5" src={comingsoon} alt="coming soon"></img>
            </div>   

            {/* Mobile View */}
            <div className="pt-5 pb-5 text-center mobileview">
                <img className="pt-5 pb-5" src={comingsoon} alt="coming soon" width="200px"></img>
            </div> 

        </div>
    );
}
export default ComingSoon;