import React, { ReactElement, useEffect } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Popper from '@material-ui/core/Popper';
import { bindToggle, bindPopper, bindHover } from 'material-ui-popup-state';
import Fade from '@material-ui/core/Fade';
import { ClickAwayListener } from '@material-ui/core';
import {
  usePopupState,
} from 'material-ui-popup-state/hooks'


const styles = makeStyles((theme) => ({
  popper: {
    [theme.breakpoints.down(599)]: {
      maxWidth: "80%", 
      marginRight: "5px"
    }
  }
}));

export const FxPopper = (props: any) => {
  const classes = styles();
  const popupState = usePopupState({ variant: 'popover', popupId: props.popperId });

  return (
      <>
          { props.icon && <span className="pointer" title={props.action} {...bindHover(popupState)}>
                <i className={props.icon +" icon-style"}/>
          </span>}
          { props.image && <span className="pointer" title={props.action} {...bindHover(popupState)}>
                <img src={props.image} className="icon-style"/>
          </span>}
          { !props.icon &&
            <a className="stmt-add-if mr-2" {...bindHover(popupState)}>
              {props.action}
            </a>
          }
          <Popper {...bindPopper(popupState)} transition className={classes.popper} style={{maxWidth: props.maxWidth, fontSize: props.fontSize}}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <ClickAwayListener onClickAway={() => popupState.setOpen(false)}>
                  <Paper className="paper-props" {...bindToggle(popupState)}>
                    {props.children}
                  </Paper>
                </ClickAwayListener>
              </Fade>
            )}
          </Popper>
      </>
  );
};