import React, { useEffect, useState } from 'react';
import './ItemList.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Fab, Dialog, Drawer, makeStyles, Button, Tooltip, IconButton } from '@material-ui/core';
import { http, FxInput, FxSelect, useNavigationService, AlertService, general } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import dummyImage from "../../../assets/images/dummy.png";
import ItemForm from '../ItemForm/ItemForm';
import ItemsUpload from '../ItemsUpload/ItemsUpload';
import ImageSlides from '../../general/ImageSlides/ImageSlides';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const ItemList = () => {
  const { navigate, routeData, back } = useNavigationService();
  const classes = styles();

  let defaultFilter: any = {
    name: "",
    serviceId: -1
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [services, setServices] = useState([]);
  const [items, setItems] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedId, setSelectedId] = useState(0);
  const [itemFormOpen, setItemFormOpen] = useState(false);
  const [itemsUploadOpen, setItemsUploadOpen] = useState(false);
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    let filterObj = {
      name: filters.name,
      serviceId: filters.serviceId
    }
    if (item.name == "name") {
      filterObj.name = item.value;
    }
    else if (item.name == "serviceId") {
      filterObj.serviceId = item.value;
    }
    getItems(filterObj);
  };

  const handleFilterChange = (data) => {
    setFilters(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getItems = (data: any) => {
    let inputData = {
      filters: {
        communityId: routeData.communityId,
        name: data.name,
        serviceId: data.serviceId
      }
    };
    let apiOptions: any = {
      url: `community/${routeData.communityId}/item/query`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      getItemsCallBack(res);
    });
  };

  const getItemsCallBack = (res) => {
    let result = res.data;
    for (let item of result) {
      item.imageUrl = general.getImageUrl(item.imagePath);
    }
    setItems(result);
  }

  const initLookup = () => {
    let inputData = {
      lookups: {
        Services: { default: true }
      },
      filters: {
        behaviorCodeFlag: true
      }
    };
    let apiOptions = {
      url: `options/lookup`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      initLookupCallBack(res);
    });
  }

  const initLookupCallBack = (res) => {
    let result = res.data;
    //let services = result.Services.filter(s => s.code == "-1" || s.code == "ItemWise");
    setServices(result.Services);
  }

  const addOrEditItem = (id) => {
    setItemFormOpen(true);
    setSelectedId(id);
  }

  const handleItemFormClose = () => {
    setItemFormOpen(false);
    setSelectedId(0);
    getItems(filters);
  }

  const confirmDelete = (item) => {
    setConfirmOpen(true);
    setMessage("Are you sure to delete " + item.name + "?");
    setSelectedId(item.id);
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setSelectedId(0);
    setMessage("");
  };

  const onConfirmCallback = () => {
    const id = selectedId;
    deleteItem(id);
    handleDialogClose()
  };

  const deleteItem = (id) => {
    let apiOptions: any = {
      url: `community/item/${id}`
    };
    http.delete(apiOptions).then(response => {
      AlertService.showMsg({ message: "Deleted successfully" });
      getItems(filters);
    })
  };

  const navToCategories = () => {
    let navData = {
      targetRoute: "/community/categories",
      targetTitle: "Categories",
      targetData: { communityId: routeData.communityId, communityName: routeData.communityName },
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const showUploadItems = () => {
    setItemsUploadOpen(true);
  }

  const handleItemsUploadClose = () => {
    setItemsUploadOpen(false);
    getItems(filters);
  }

  const navToItemTags = () => {
    let navData = {
      targetRoute: "/community/itemtags",
      targetTitle: "Item Tags",
      targetData: { communityId: routeData.communityId, communityName: routeData.communityName },
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  const openImages = (item: any) => {
    let config = {
      api: `community/item/${item.id}/images`,
      title: item.name
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
    setImageConfig({});
  }

  const navToBack = () => {
    back();
  };

  useEffect(() => {
    initLookup();
    getItems(filters);
  }, []);

  return (
    <div className='item-list-ui pt-4 mt-3'>
      <div className="row title-font custom-head pl-4 pt-5">
        <div className='col-8'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
              <b>{routeData.communityName}</b>
            </Link>
            <Typography color="primary" className="breadcrumbs-style">Items</Typography>
          </Breadcrumbs>
        </div>
        <div className='text-right col-4 pr-2'>
          <Tooltip title="Categories">
            <IconButton aria-label="categories" className='py-0 mr-2'>
              <i onClick={navToCategories} className="fas fa-th"></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Upload">
            <IconButton aria-label="upload" className='py-0 mr-2'>
              <i onClick={showUploadItems} className="fas fa-upload"></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Item Tags">
            <IconButton aria-label="item tags" className='py-0'>
              <i onClick={navToItemTags} className="fas fa-tags"></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className='content-container'>
        <div className="content">
          <div className='row my-4 mx-2'>
            <div className="col-3">
              <FxInput
                name="name"
                value={filters.name}
                variant="outlined"
                label="Search"
                fullWidth
                size="small"
                onValueChange={handleInputChange}
              />
            </div>
            <div className="col-3">
              <FxSelect name="serviceId" variant="outlined" label="Service"
                options={services}
                selectedValue={filters.serviceId}
                valueField="id"
                displayField="text"
                size="small"
                style={{ width: 260 }}
                onValueChange={handleInputChange} />
            </div>
            <div className='col-3'>

            </div>
            <div className='col-3'>
              <div className='d-flex justify-content-end pr-3'>
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  onClick={() => addOrEditItem(0)}
                >
                  <i className="fas fa-plus pr-2"></i>
                  Add
                </Fab>
              </div>
            </div>
          </div>

          <div className='row my-4 mx-4 pt-2'>
            <TableContainer component={Paper}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Service</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    items.length == 0 &&
                    <TableCell colSpan={4}>
                      <NoRecords />
                    </TableCell>
                  }
                  {
                    items.length > 0 && items.map((row: any, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <span className='pointer' onClick={() => openImages(row)}>
                            {!row.imageUrl && <img src={dummyImage} alt="Item" width="50" height="50"></img>}
                            {row.imageUrl && <img src={row.imageUrl} alt="Item" width="50" height="50"></img>}
                          </span>
                        </TableCell>
                        <TableCell>
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.serviceName}
                        </TableCell>
                        <TableCell>
                          <div>
                            <span className='pointer'><i className="fas fa-pencil-alt font-13" onClick={() => addOrEditItem(row.id)}></i></span>
                            <span className='ml-3 pointer'><i className="fas fa-trash-alt font-13" onClick={() => confirmDelete(row)}></i></span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={itemFormOpen} onClose={handleItemFormClose}>
        <ItemForm itemId={selectedId} communityId={routeData.communityId} handleItemFormClose={handleItemFormClose} />
      </Drawer>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={itemsUploadOpen} onClose={handleItemsUploadClose}>
        <ItemsUpload communityId={routeData.communityId} handleItemsUploadClose={handleItemsUploadClose} />
      </Drawer>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
    </div>
  );
}
export default ItemList;