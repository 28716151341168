import React, { useState, useEffect } from 'react';
import './UserAddress.scss';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { http } from './../../fx-core';

export const UserAddress = (props) => {
    const [addressList, setAddressList] = useState([]);

    const getAddressList = () => {
        let apiOptions: any = {
            url: `address/query/${props.userId}`,
            data: null
        };
        http.post(apiOptions).then(res => {
            const result = res.data;
            setAddressList(result);
        })
    };

    useEffect(() => {
        getAddressList();
    }, []);

    return (
        <div className="userAddress-ui">
            <div className="row col-12 title-font py-1 mx-auto">
                <div className="col-11 pl-0">
                    <span>Address List</span>
                </div>
                <div className="col-1 pr-0 float-right text-right pointer" onClick={props.closeAddressDialog}>
                    <HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="address-container mx-3 mb-3 mt-1">
                <TableContainer>
                    <Table className="table-striped" size="small">
                        {/* <TableHead>
                            <TableRow>
                                <TableCell className="table-head table-font"></TableCell>
                                <TableCell className="table-head table-font">Address</TableCell>
                                <TableCell className="table-head table-font">Action</TableCell>
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            {
                                addressList.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell className="table-font">
                                            <div>
                                                <span className="pr-1">{row.fullName}</span>
                                                {row.addressTypeId === 1 && <i className="fas fa-home"></i>}
                                                {row.addressTypeId === 2 && <i className="fas fa-building"></i>}
                                                {row.addressTypeId === 3 && <i className="far fa-address-card"></i>}
                                            </div>
                                        </TableCell>
                                        <TableCell className="table-font">
                                            <div>
                                                <div>
                                                <i className="fas fa-map-marker-alt mr-2"></i>
                                                <span>{row.fullAddress}</span>
                                                {
                                                    row.pinCode && <span className="ml-1"> - {row.pinCode}</span>
                                                }
                                                </div>
                                                <div className="pt-1">
                                                <i className="fas fa-phone  mr-1"></i>
                                                <span>{row.mobile}</span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="table-font text-right">
                                            <div>
                                                <button className="btn btn-sm rounded-2 btn-clr" onClick={() => props.onSelectAddress(row)}>Select</button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};