import validator from 'validator';
import moment from "moment";
import 'moment/min/locales';


const isEmptyJSONObject = (jsonObj: any) => {
    var propCount = Object.keys(jsonObj).length;
    var result = propCount == 0 ? true : false;
    return result;
}

export const formValidator = {

    bulkValidate(items: any) {
        let result: any = {};
        let hasError = false;
        for (let item of items) {
            const validationResult = formValidator.validate(item); // { name: 'name', value: '', validations: "["required"]"}
            result[item.name] = validationResult;
            if (!hasError) {
                hasError = Object.keys(validationResult).some(val => validationResult[val]);
            }
        }
        return { isValid: !hasError, errors: result };
    },

    validate(options: any) {
        const value = options.value || '';
        const name = options.name;

        if (!name) throw new Error('FormValidator.Validate - Name must not be empty.');

        const param = options.param;
        const validations = options.validations;

        let result: any = [];
        if (validations && validations.length) {
            /*  Result of each validation must be true if the input is invalid
                and false if valid. */
            validations.forEach((m: string | number) => {
                switch (m) {
                    case 'required':
                        result[m] = validator.isEmpty(value)
                        break;
                    case 'select':
                        if (value == -1) {
                            result[m] = true;
                        }
                        else {
                            result[m] = false;
                        }
                        break;
                    case 'email':
                        result[m] = !validator.isEmail(value)
                        break;
                    case 'number':
                        let num = value.toString();
                        result[m] = !validator.isNumeric(num);
                        break;
                    case 'integer':
                        result[m] = !validator.isInt(value)
                        break;
                    case 'alphanum':
                        result[m] = !validator.isAlphanumeric(value)
                        break;
                    case 'url':
                        result[m] = !validator.isURL(value)
                        break;
                    case 'minlen':
                        result[m] = !validator.isLength(value, { min: param })
                        break;
                    case 'maxlen':
                        result[m] = !validator.isLength(value, { max: param })
                        break;
                    case 'len':
                        const [min, max] = JSON.parse(param)
                        result[m] = !validator.isLength(value, { min, max })
                        break;
                    case 'min':
                        result[m] = !validator.isInt(value, { min: validator.toInt(param) })
                        break;
                    case 'max':
                        result[m] = !validator.isInt(value, { max: validator.toInt(param) })
                        break;
                    case 'list':
                        const list = JSON.parse(param)
                        result[m] = !validator.isIn(value, list)
                        break;
                    case 'mobileno':
                        const mobileRegex = /^[1-9][0-9]{9}$/;
                        if(value) {
                            const mobileValidator = mobileRegex.test(value);
                            result[m] = !mobileValidator;
                        }
                        else {
                            result[m] = true;
                        }
                        break;
                    case 'pastdate':
                        const dateVal = moment(value).format("YYYY-MM-DD");
                        const currentDate = moment().format("YYYY-MM-DD");
                        if (moment(dateVal).isBefore(currentDate)) {
                            result[m] = true;
                        } else {
                            result[m] = false;
                        }
                        break;
                    case 'futuredate':
                        const valueFromDatePicker = moment(value).format("YYYY-MM-DD");
                        const todayDate = moment().format("YYYY-MM-DD");
                        if (moment(valueFromDatePicker).isAfter(todayDate)) {
                            result[m] = true;
                        } else {
                            result[m] = false;
                        }
                        break;
                    case 'accnoformat':
                        let ACCTNO_REGEX = /^\d{2}\-\d{4}\-\d{7}\-(\d{2})$/;
                        if (value) {
                            const acctNoValidator = ACCTNO_REGEX.test(value);
                            result[m] = !acctNoValidator;
                        }
                        else {
                            result[m] = true;
                        }
                        break;
                    case 'price':
                        let v = value.toString();    
                        if(v && v == "0") {
                            result[m] = false;    
                        }
                        else {
                            result[m] = !validator.isNumeric(v);
                        }
                        break;
                    case 'pincode':
                        let pin_regex = /^[1-9][0-9]{5}$/;
                        if(value) {
                            const pinValidator = pin_regex.test(value);
                            result[m] = !pinValidator;
                        }
                        else {
                            result[m] = true;
                        }
                        break;
                    default:
                        throw new Error('Unrecognized validator.');
                }

            })
        }
        return result;
    }
}