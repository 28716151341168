import React, { useState } from 'react';
import './ItemCard.scss';
import Card from '@material-ui/core/Card';
import dummyImage from "../../../../assets/images/dummy.png";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const ItemCard = (props: any) => {
  let defaultItem = {
    selectedItem: false
  };

  const [item, setItem] = useState(defaultItem);

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleCheckbox = (event, itemObj) => {
    if (event.target.name == "selectedItem") {
      if (event.target.checked) {
        itemObj.categoryId = props.categoryId;
        itemObj.isSelected = true;
      }
      else {
        itemObj.isSelected = false;
      }
    }
    handleItemChange({ [event.target.name]: event.target.checked });
    props.callbackItemCard(itemObj);
  }

  return (
    <div className="itemcard-ui">
      {/* Desktop View */}
      <div className="row ml-2 p-4">
        <Card style={{ width: "250px", height: "270px" }} className="d-flex flex-column justify-content-between py-3 px-2">
          <div className="text-center py-2">
            {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
            {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
          </div>
          <div className="font-15 text-center itemname-style">
            {props.item.name}
          </div>
          <div className="text-center pt-2">
            <Checkbox name="selectedItem" color="primary" checked={props.item.isSelected} onChange={(e) => handleCheckbox(e, props.item)} icon={<CheckBoxOutlineBlankIcon
              fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
          </div>
        </Card>
      </div>
    </div>
  );
}
export default ItemCard;