import React, { useEffect, useState } from 'react';
import './DashboardSaleOrders.scss';
import { FxDateDisplay, FxCurrency, http, formatService, AlertService, general, useNavigationService } from '../../fx-core';
import * as _ from 'lodash';
import { Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import SaleOrderDetail from '../SaleOrderDetail/SaleOrderDetail';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const DashboardSaleOrders = (props: any) => {
  const { navigate } = useNavigationService();
  const initialList = [];
  const defaultOrderObj = {
    id: 0,
    orderStatusId: 0,
    orderTypeId: 0
  };
  const [stallId, setStallId] = useState(props.match.params.id ? parseInt(props.match.params.id) : 0);
  const [type, setType] = useState(props.match.params.type ? props.match.params.type : null);
  const [saleOrderList, setSaleOrderList] = useState(initialList);
  const [open, setOpen] = useState(false);
  const [saleOrderId, setSaleOrderId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(defaultOrderObj);
  const [isCancelAction, setIsCancelAction] = useState(false);

  const initMethod = () => {
    if (type == 'open') {
      getOpenSaleOrders();
    }
    else if (type == 'shipped') {
      getShippedSaleOrders();
    }
    else if (type == 'all') {
      getAllSaleOrders();
    }
  }

  const getOpenSaleOrders = () => {
    let inputData = {
      id: stallId
    }
    let apiOptions: any = {
      url: `saleorder/pendingorders/stall/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setSaleOrderList(result);
    })
  };

  const getShippedSaleOrders = () => {
    let inputData = {
      id: stallId
    }
    let apiOptions: any = {
      url: `saleorder/shipped/stall/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setSaleOrderList(result);
    })
  };

  const getAllSaleOrders = () => {
    let inputData = {
      id: stallId
    }
    let apiOptions: any = {
      url: `saleorder/stall/${inputData.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setSaleOrderList(result);
    })
  };

  const viewSaleOrderDetail = (item: any) => {
    setOpen(true);
    setSaleOrderId(item.id);
  };

  const handleClose = () => {
    setOpen(false);
    setSaleOrderId(0);
    initMethod();
  };

  const setDefaultValues = () => {
    setSelectedOrder(defaultOrderObj);
    setMessage(null);
    setConfirmOpen(false);
    if (isCancelAction) {
      setIsCancelAction(false);
    }
  };

  const openConfirmDialogByStatus = (item) => {
    let msg = "";
    if (item.orderStatusId === 1) {
      msg = "Do you want to accept the order #" + item.saleOrderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 1) {
      msg = "Do you want to ship the order #" + item.saleOrderIdentifier + "?";
    }
    else if (item.orderStatusId === 2 && item.orderTypeId === 2) {
      msg = "Is the order #" + item.saleOrderIdentifier + " ready?";
    }
    else if (item.orderStatusId === 4 || item.orderStatusId === 5) {
      msg = "Do you want to deliver the order #" + item.saleOrderIdentifier + "?";
    }
    setSelectedOrder(item);
    setMessage(msg);
    setConfirmOpen(true);
  };

  const handleDialogClose = () => {
    setDefaultValues()
  };

  const onConfirmCallback = (action, comments) => {
    if (selectedOrder.orderStatusId === 1) {
      if (action === 'cancel') {
        cancelOrder(comments);
      } else {
        markAsAccepted();
      }
    }
    else if (selectedOrder.orderStatusId === 2) {
      markAsReadyOrShipped();
    }
    else if (selectedOrder.orderStatusId === 4 || selectedOrder.orderStatusId === 5) {
      markOrderDelivered();
    }
  };

  const markAsAccepted = () => {
    let apiOptions = {
      url: `saleorder/${selectedOrder.id}/markAsAccepted`
    };
    http.put(apiOptions).then(res => {
      markAsAcceptedCallBack(res);
    });
  };

  const markAsAcceptedCallBack = (res) => {
    AlertService.showMsg({ message: "Order is accepted" });
    setDefaultValues();
    initMethod();
  };

  const markAsReadyOrShipped = () => {
    let apiOptions = {
      url: `saleorder/${selectedOrder.id}/MarkAsReadyOrShipped`
    };
    http.put(apiOptions).then(res => {
      markAsReadyOrShippedCallBack(res);
    });
  };

  const markAsReadyOrShippedCallBack = (res) => {
    let successMsg = "";
    if (selectedOrder.orderTypeId === 1) {
      successMsg = "Order shipped";
    }
    else if (selectedOrder.orderTypeId === 2) {
      successMsg = "Order ready";
    }
    AlertService.showMsg({ message: successMsg });
    setDefaultValues();
    initMethod();
  };

  const markOrderDelivered = () => {
    let inputData = {
      id: selectedOrder.id,
      currentDate: formatService.getCurrentDateForServer()
    }
    let apiOptions = {
      url: `saleorder/MarkOrderDelivered`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      markOrderDeliveredCallBack(res);
    });
  };

  const markOrderDeliveredCallBack = (res) => {
    AlertService.showMsg({ message: "Order delivered" });
    setDefaultValues();
    initMethod();
  };

  const openConfirmDialogForCancel = (item) => {
    setSelectedOrder(item);
    setIsCancelAction(true);
    setConfirmOpen(true);
  };

  const cancelOrder = (comments) => {
    let inputData = {
      id: selectedOrder.id,
      cancelledRemarks: comments
    }
    let apiOptions = {
      url: `saleorder/MarkOrderCancelled`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      cancelOrderCallBack(res);
    });
  };

  const cancelOrderCallBack = (res) => {
    setDefaultValues();
    initMethod();
  };

  const navToDashboard = () => {
    let navData = {
      targetRoute: '/provider/provdashboard/' + stallId,
      targetTitle: "Provider Dashboard",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    initMethod();
  }, [])


  const body = (
    <div className="saleorders-ui pt-5">
      <div className="content-container pt-4">
        <div className="content">
          <div className='pt-2'>
            <div className="title-font custom-head pl-4">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToDashboard}>
                  <b>Dashboard</b>
                </Link>
                <Typography color="primary" className="breadcrumbs-style">
                  {type == 'open' &&
                    <span>Open Orders</span>
                  }
                  {type == 'shipped' &&
                    <span>Delivery Orders</span>
                  }
                  {type == 'all' &&
                    <span>Total Orders</span>
                  }
                </Typography>
              </Breadcrumbs>
            </div>
            <div className='my-4 px-4'>
              <TableContainer component={Paper}>
                <Table size="small" className='table-striped'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Shop</TableCell>
                      <TableCell>Delivery Type</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell className='text-right'>Order#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className='text-right'>Amount</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saleOrderList.map((row, i) => (
                      <TableRow>
                        <TableCell><FxDateDisplay value={row.orderDate} /></TableCell>
                        <TableCell>{row.stallName}</TableCell>
                        <TableCell>
                          {row.orderTypeId == 1 &&
                            <span>Delivery</span>
                          }
                          {row.orderTypeId == 2 &&
                            <span>Pickup</span>
                          }
                        </TableCell>
                        <TableCell>
                          <div className='row'>
                            <span>{row.consumerName}</span>
                            <span className='pl-2'>{row.consumerPhone}</span>
                          </div>
                        </TableCell>
                        <TableCell className='text-right'>#{row.saleOrderIdentifier}</TableCell>
                        <TableCell>
                          {row.orderStatus}
                          {
                            row.orderStatus === 'Cancelled' &&
                            <span className='pl-2'>({row.cancelledRemarks})</span>
                          }
                        </TableCell>
                        <TableCell className='text-right'><FxCurrency value={row.orderTotal} /></TableCell>
                        <TableCell>
                          {row.saleOrderPayments && row.saleOrderPayments.map((itemObj, i) => (
                            <div>
                              {itemObj.paymentMode == 'cod' &&
                                <span className='payment-style'>
                                  Cash / {itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode == 'stripe' &&
                                <span className='payment-style'>
                                  {'Card :' + itemObj.paymentStatus}
                                </span>
                              }
                              {itemObj.paymentMode != 'cod' && itemObj.paymentMode != 'stripe' &&
                                <span className='payment-style'>
                                  {itemObj.paymentMode} / {itemObj.paymentStatus}
                                </span>
                              }
                            </div>
                          ))
                          }
                        </TableCell>
                        <TableCell>
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Accept</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 1) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ship</Button>
                          }
                          {
                            (row.orderStatusId === 2 && row.orderTypeId === 2) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Ready</Button>
                          }
                          {
                            (row.orderStatusId === 4 || row.orderStatusId === 5) &&
                            <Button variant='outlined' size='small' className='button-style' onClick={() => openConfirmDialogByStatus(row)}>Deliver</Button>
                          }
                          {
                            row.orderStatusId === 1 &&
                            <Button variant='outlined' size='small' className='button-style ml-2' onClick={() => openConfirmDialogForCancel(row)}>Cancel</Button>
                          }
                        </TableCell>
                        <TableCell>
                          <Button variant='outlined' size='small' className='button-style' onClick={() => viewSaleOrderDetail(row)}>View Order</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="text-center m-2">
              {saleOrderList && saleOrderList.length == 0 &&
                <span className='font-18'><b>No Sale Orders</b></span>
              }
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <SaleOrderDetail saleOrderId={saleOrderId} handleClose={handleClose} />
        </Dialog>
      </div>
      <div>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} action={isCancelAction ? "cancel" : ""} />
        </Dialog>
      </div>
    </div>
  );

  return (
    <div>{body}</div>
  );

}

export default DashboardSaleOrders;