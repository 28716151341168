import React, { useEffect, useState } from 'react';
import './ItemFilters.scss';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { http } from '../../fx-core';

const ItemFilters = (props: any) => {

    let defaultItem = {
        categoryIds: [],
        selectedCategory: false,
        tagIds: [],
        selectedTag: false
    };

    const [item, setItem] = useState(defaultItem);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);

    const initLookUp = () => {
        /* Categories and Tags not relevent to Listing and Booking
        Giri confirmed in call not to display the list will address later so commented the calls
        getCategories();
        getTags();*/
    }

    const handleCheckbox = (event) => {
        if (event.target.name == "selectedCategory") {
            let selectedValue = parseInt(event.target.value);
            if (event.target.checked) {
                item.categoryIds.push(selectedValue);
            }
            else {
                let itemIdx = item.categoryIds.indexOf(selectedValue);
                item.categoryIds.splice(itemIdx, 1);
            }
        }
        if (event.target.name == "selectedTag") {
            let selectedTag = parseInt(event.target.value);
            if (event.target.checked) {
                item.tagIds.push(selectedTag);
            }
            else {
                let itemIdx = item.tagIds.indexOf(selectedTag);
                item.tagIds.splice(itemIdx, 1);
            }
        }
        handleItemChange({ [event.target.name]: event.target.checked });
        props.callbackItemFilter({
            categoryIds: event.target.name == "selectedCategory" ? item.categoryIds : 0,
            tagIds: event.target.name == "selectedTag" ? item.tagIds : 0
        });
    }

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getCategories = () => {
        let inputData = {
            stallId: props.stallId
        }
        let apiOptions: any = {
            url: `portal/category/active/stall/serviceIds`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data.categories;
            setCategories(result);
        });
    }

    const getTags = () => {
        let apiOptions: any = {
            url: `portal/itemtag/stall/${props.stallId}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            let result = response.data.itemTags;
            setTags(result);
        });
    }

    useEffect(() => {
        initLookUp();
    }, []);

    return (
        <div className="itemFilters-ui pt-5 px-3">
            <div className="row">
                <div className="col-1">
                    <i className="fas fa-filter pt-2"></i>
                </div>
                <div className="col-10 pl-2 font-20">
                    <b>Filters</b>
                </div>
            </div>
            {(props.code == 'ItemWise') &&
                <span>
                    <div>
                        {categories.length > 0 &&
                            <div className="font-19 text-medium pt-3">
                                Categories
                            </div>
                        }

                        {
                            categories.length > 0 && categories.map((item, i) => (
                                <div className="font-14">
                                    <Checkbox name="selectedCategory" color="primary" value={item.id} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon
                                        fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                                    {item.categoryName}
                                </div>
                            ))
                        }
                        {/* <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    North Indian Cuisine
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    South Indian Cuisine
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Asian Cuisine
                </div> */}
                    </div>
                    <div>
                        {
                            tags.length > 0 &&
                            <div className="font-19 text-medium pt-3">
                                Tags
                            </div>
                        }
                        {
                            tags.length > 0 && tags.map((item, i) => (
                                <div className="font-14">
                                    <Checkbox name="selectedTag" color="primary" value={item.id} onChange={handleCheckbox} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                                    {item.text}
                                </div>
                            ))
                        }
                        {/* <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Sweet
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Spicy
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Nuts Free
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Gluten Free
                </div>
                <div className="pl-3 font-14">
                    <Checkbox name="cod" color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} />
                    Starter
                </div> */}
                    </div>
                </span>
            }

        </div>
    );
}
export default ItemFilters;