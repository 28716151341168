import React, { useState, useEffect } from "react";
import './MyNotifications.scss';
import { http, localstorage, FxDateTimeDisplay, FxDateTimeDisplayLocal } from '../fx-core';
import { Table, TableContainer, TableBody, TableCell, Paper, TableRow,makeStyles,Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MyOrderDetail from '../MyAccount/MyOrderDetail/MyOrderDetail';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ProviderItemOrderDetail from '../Provider/ProviderItemOrderDetail/ProviderItemOrderDetail';
import MessageContainer from '../MessageContainer/MessageContainer';

const styles = makeStyles((theme) => ({
    drawerWidth: {
      [theme.breakpoints.down(599)]: {
        width: '90%'
      },
      [theme.breakpoints.up(600)]: {
        width: '70%'
      },
      [theme.breakpoints.up(1025)]: {
        width: '40%'
      }
    }
  }));

const MyNotications = (props) => {
    const classes = styles();
    const [notifications, setNotifications] = useState([]);
    const [openConsumerOrderDetail, setConsumerOrderDetailOpen] = React.useState(false);
    const [openProviderOrderDetail, setProviderOrderDetailOpen] = React.useState(false);
    const [orderId, setOrderId] = React.useState(0);
    const [openMessage, setOpenMessage] = React.useState(false);

    const toggleDrawerMessage = () => {
        setOpenMessage(!openMessage);
      };

    const getMyNotications = () => {
        let userId = props.userId > 0 ? props.userId : localstorage.getItem('userId');
        let apiOptions = {
            url: `notification/user/${userId}`
        };
        http.get(apiOptions).then(res => getMyNoticationsCallBack(res));
    };

    const getMyNoticationsCallBack = (res: any) => {
        let result = res.data;
        for (let item of result) {
            if (item.notificationCategory == 'OrderAccepted' || item.notificationCategory == 'OrderReady'
                || item.notificationCategory == 'OrderShipped' || item.notificationCategory == 'OrderDelivered'
                || item.notificationCategory == 'OrderCancelled' || item.notificationCategory == 'ChangeDeliverySlot'
                || item.notificationCategory == 'NewOrderArrived' || item.notificationCategory == 'NewMessage'
                || item.notificationCategory == 'UnreadMessage') {
                item.showDetailButton = true;
            }
            else {
                item.showDetailButton = false;
            }
        }
        setNotifications(res.data);
    };

    const navToOrderDetail = (item) => {
        if (item.notificationCategory == 'NewOrderArrived' || item.notificationCategory == 'NewMessage' || item.notificationCategory == 'UnreadMessage') {
            if (item.notificationCategory == 'NewOrderArrived') {
                setProviderOrderDetailOpen(true);
                setOrderId(JSON.parse(item.notificationData).orderId);
            }
            else {            
                setOpenMessage(true);
            }
        }
        else {
            setConsumerOrderDetailOpen(true);
            setOrderId(JSON.parse(item.notificationData).orderId);
        }
    }

    const handleConsumerOrderDetailClose = () => {
        setConsumerOrderDetailOpen(false);
    }

    const handleProviderOrderDetailClose = () => {
        setProviderOrderDetailOpen(false);
    }

    const handleNotificationClose = () => {
        props.handleNotificationClose();
    }

    const handleMessageClose = () => {
        setOpenMessage(false);
    }

    useEffect(() => {
        getMyNotications();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between title-font py-1 px-4 sticky-top">
                <div>
                    <span>Notifications</span>
                </div>
                <div className="pointer" onClick={handleNotificationClose}>
                    <HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table stickyHeader className="table table-striped" size="small">
                    <TableBody>
                        {
                            notifications.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell className="font-16">
                                        <div>{row.message}</div>
                                        <div>
                                            <span className="label">
                                                <FxDateTimeDisplayLocal value={row.notificationDate} displayType="datetime" />
                                            </span>
                                            <span className="float-right">
                                                {row.showDetailButton &&
                                                    <Button variant="contained" size="small" fullWidth color="primary"
                                                        style={{ color: '#FFFFFF', fontWeight: 600 }}
                                                        onClick={() => navToOrderDetail(row)}>
                                                        Detail
                                                    </Button>
                                                }
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <Dialog
                    open={openConsumerOrderDetail}
                    maxWidth="md"
                    onClose={handleConsumerOrderDetailClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <MyOrderDetail orderId={orderId} handleClose={handleConsumerOrderDetailClose} />
                </Dialog>
                <Dialog
                    open={openProviderOrderDetail}
                    maxWidth="md"
                    onClose={handleProviderOrderDetailClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ProviderItemOrderDetail orderId={orderId} handleClose={handleProviderOrderDetailClose} />
                </Dialog>
                <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openMessage} onClose={toggleDrawerMessage}>
                    <MessageContainer handleMessageClose={handleMessageClose} />
                </Drawer>
            </div>
        </div >
    );
};

export default MyNotications;