import React, { useState, useEffect } from 'react';
import './ZoneDashboard.scss';
import { http, useNavigationService, FxSelect, formatService, FxCurrency, localstorage } from '../../fx-core';
import * as _ from 'lodash';
import { Typography } from '@material-ui/core';

const ZoneDashboard = (props: any) => {
    const zonalAdminDashboardList: any = [
        {
            "controlCode": "communityadminpayments",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Payments",
            "sRef": "/community/payments",
            "iconRef": "fas fa-file-invoice-dollar",
            "iconRefNo": 1,
            "translateRef": "",
            "displayOrder": 1,
            "valuePrefix": "currency"
        },
        {
            "controlCode": "communityadminincome",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Monthly Income",
            "sRef": "/community/income",
            "iconRef": "fas fa-receipt",
            "iconRefNo": 2,
            "translateRef": "",
            "displayOrder": 2,
            "valuePrefix": "currency"
        },
        {
            "controlCode": "communitystalls",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Stalls",
            "sRef": "/community/stalllist",
            "iconRef": "fas fa-store",
            "iconRefNo": 3,
            "translateRef": "",
            "displayOrder": 3,
            "valuePrefix": ""
        },
        {
            "controlCode": "communityusers",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Users",
            "sRef": "/community/userlist",
            "iconRef": "fas fa-users",
            "iconRefNo": 4,
            "translateRef": "",
            "displayOrder": 4,
            "valuePrefix": ""
        },
        {
            "controlCode": "communityorders",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Orders",
            "sRef": "/community/orders",
            "iconRef": "fas fa-list",
            "iconRefNo": 5,
            "translateRef": "",
            "displayOrder": 5,
            "valuePrefix": ""
        },
        {
            "controlCode": "communityordersamount",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Order Amount",
            "sRef": "/community/orders",
            "iconRef": "fas fa-money-bill-alt",
            "iconRefNo": 1,
            "translateRef": "",
            "displayOrder": 6,
            "valuePrefix": "currency"
        },
        {
            "controlCode": "communityproviders",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Providers",
            "sRef": "/community/providerlist",
            "iconRef": "fas fa-users",
            "iconRefNo": 2,
            "translateRef": "",
            "displayOrder": 7,
            "valuePrefix": ""
        },
        /*{
            "controlCode": "communitycategories",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Categories",
            "sRef": "",
            "iconRef": "fas fa-th",
            "iconRefNo": 3,
            "translateRef": "fas fa-list",
            "displayOrder": 8,
            "valuePrefix": ""
        },
        {
            "controlCode": "communityitems",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Items",
            "sRef": "/community/items",
            "iconRef": "fas fa-list",
            "iconRefNo": 4,
            "translateRef": "",
            "displayOrder": 9,
            "valuePrefix": ""
        },
        {
            "controlCode": "communitypost",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Posts",
            "sRef": "/communityposts",
            "iconRef": "fas fa-mail-bulk",
            "iconRefNo": 5,
            "translateRef": "",
            "displayOrder": 10,
            "valuePrefix": ""
        },
        {
            "controlCode": "communitycoupons",
            "parentControlCode": null,
            "controlType": "MAIN",
            "controlPosition": "INNER",
            "display": "Coupons",
            "sRef": "/community/couponlist",
            "iconRef": "fas fa-tag",
            "iconRefNo": 1,
            "translateRef": "",
            "displayOrder": 11,
            "valuePrefix": ""
        }*/
    ];

    const { navigate, pageState } = useNavigationService();

    let defaultFilter = {
        communityId: 0
    };
    const [filter, setFilter] = useState(defaultFilter);
    const [communityList, setCommunityList] = useState([]);
    const [result, setResult] = useState({});

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let filterObj = {
            communityId: filter.communityId
        }
        if (item.name == 'communityId') {
            filterObj.communityId = item.value;
        }
        getZonalDashboardDetails(filterObj);
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                MyZoneCommunities: { default: false }
            },
            filters: {
                userId: localstorage.getItem("userId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            initLookupCallBack(response);
        })
    }

    const initLookupCallBack = (res) => {
        let result = res.data;
        let zoneCommnities = result.MyZoneCommunities;
        let communityId = 0;
        setCommunityList(zoneCommnities);
        if (pageState && pageState.selCommunity) {
            communityId = pageState.selCommunity.id;
            handleInputChange({ name: 'communityId', value: communityId });

        }
        else if (zoneCommnities.length > 0) {
            communityId = zoneCommnities[0].id;
            handleInputChange({ name: 'communityId', value: communityId });
        }
    };

    const navToDetail = (e, item: any) => {
        e.stopPropagation();
        if (item.sRef) {
            const selCommunity: any = _.find(communityList, { id: filter.communityId });
            let navData = {
                targetRoute: item.sRef,
                targetTitle: item.display,
                targetData: { communityId: selCommunity.id, communityName: selCommunity.text, parent: 'ZONALMANAGER' },
                sourceState: { selCommunity },
                eventSource: '',
            };
            navigate(navData);
        }
    };

    const navToReviews = () => {
        const selCommunity: any = _.find(communityList, { id: filter.communityId });
        let navData = {
            targetRoute: "/community/reviews",
            targetTitle: 'Reviews',
            targetData: { communityId: selCommunity.id, communityName: selCommunity.text },
            sourceState: { selCommunity },
            eventSource: '',
        };
        navigate(navData);
    }

    const navToTrueFansBoard = () => {
        const selCommunity: any = _.find(communityList, { id: filter.communityId });
        let navData = {
            targetRoute: "/community/truefans",
            targetTitle: 'True Fans LeaderBoard',
            targetData: { communityId: selCommunity.id, communityName: selCommunity.text },
            sourceState: { selCommunity },
            eventSource: '',
        };
        navigate(navData);
    }

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getZonalDashboardDetails = (data) => {
        const monthStartDate = formatService.getMonthStartDate();
        const yearStartDate = formatService.getYearStartDate();
        const toDate = formatService.getCurrentDateForServer();
        let inputData = {
            ds: {
                resultset: [
                    { key: "communityadminpayments", type: "single" },
                    { key: "communityadminincome", type: "single" },
                    { key: "communitystalls", type: "single" },
                    { key: "communityusers", type: "single" },
                    { key: "communityorders", type: "single" },
                    { key: "communityordersamount", type: "single" },
                    { key: "communityproviders", type: "single" },
                    { key: "communityitems", type: "single" },
                    { key: "communitypost", type: "single" },
                    { key: "communitycoupons", type: "single" }
                ]
            },
            data: {
                communityId: data.communityId,
                fromDate: formatService.getDateStringForServer(yearStartDate),
                toDate: toDate,
                monthFromDate: formatService.getDateStringForServer(monthStartDate),
                roleCode: "ZONALMANAGER"
            }
        };
        let apiOptions: any = {
            url: `general/dbo/GetAdminDashboardDetails`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getZonalDashboardDetailsCallBack(res);
        });
    };

    const getZonalDashboardDetailsCallBack = (res) => {
        let result = res.data;
        setResult(result);
    }

    useEffect(() => {
        initLookup();
    }, []);

    return (
        <div className="zonedashboard-ui pt-5">
            <div className="title-font custom-head pl-4 pt-5">
                <Typography color="primary" className="breadcrumbs-style">Zonal Manager Dashboard</Typography>
            </div>
            <div className="content-container">
                <div className="content">
                    <div className="col-4" style={{ marginTop: "20px" }}>
                        <FxSelect name="communityId" variant="outlined"
                            label="Community"
                            options={communityList}
                            selectedValue={filter.communityId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                    <div className="row col-7 align-items-center mt-5 mb-3">
                        <div className="col-7 d-flex align-items-center txt-font-weight pointer" onClick={navToTrueFansBoard}>
                            <span>True Fans</span>
                            <span className="pl-2">Leader board</span>
                            <span className="pl-2"><i className="fas fa-play play-icon font-20 pt-1 pointer"></i></span>
                        </div>
                        <div className="col-5 d-flex align-items-center txt-font-weight pointer" onClick={navToReviews}>
                            <span>Reviews</span>
                            <span className="pl-2"><i className="fas fa-play play-icon font-20 pt-1 pointer"></i></span>
                        </div>
                    </div>
                    <div className="row col-7 align-items-center">
                        {
                            zonalAdminDashboardList.map((item, i) => (
                                <div key={i} className='col-4' onClick={(e) => navToDetail(e, item)}>
                                    <div className="servicecard-new pointer pl-3">
                                        {item.iconRefNo == 1 && <span className="px-2 py-1 mr-2 number1">
                                            <i className={item.iconRef}></i>
                                        </span>}
                                        {item.iconRefNo == 2 && <span className="px-2 py-1 mr-2 number2">
                                            <i className={item.iconRef}></i>
                                        </span>}
                                        {item.iconRefNo == 3 && <span className="px-2 py-1 mr-2 number3">
                                            <i className={item.iconRef}></i>
                                        </span>}
                                        {item.iconRefNo == 4 && <span className="px-2 py-1 mr-2 number4">
                                            <i className={item.iconRef}></i>
                                        </span>}
                                        {item.iconRefNo == 5 && <span className="px-2 py-1 mr-2 number5">
                                            <i className={item.iconRef}></i>
                                        </span>}
                                        {
                                            item.valuePrefix == 'currency' &&
                                            <span className="cardlbl-new">
                                                {item.display}
                                                {
                                                    result[item.controlCode] &&
                                                    <div className="pl-5">
                                                        <FxCurrency value={result[item.controlCode][item.controlCode]} />
                                                    </div>
                                                }
                                            </span>
                                        }
                                        {
                                            !item.valuePrefix &&
                                            <span className="cardlbl-new">
                                                {item.display}
                                                {
                                                    result[item.controlCode] &&
                                                    <div className="pl-5">
                                                        {result[item.controlCode][item.controlCode]}
                                                    </div>
                                                }
                                            </span>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ZoneDashboard;