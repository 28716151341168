import React, { useEffect, useState } from 'react';
import { FxInput, http, LocationSearchInput, AlertService, formValidator, general, FxSelect, FxTextarea } from '../../fx-core';
import './CommunityForm.scss';
import * as _ from 'lodash';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import { Button, Dialog } from '@material-ui/core';

const CommunityForm = (props) => {
  const defaultItem = {
    profileImagePath: "",
    profileImageUrl: "",
    userName: "",
    password: "",
    name: "",
    code: "",
    address: "",
    lat: "",
    lng: "",
    landmark: "",
    id: props.communityId ? props.communityId : 0,
    communityTypeId: 1,
    // locationId: -1
  };

  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [error, setError] = useState(false);
  const [communityType, setCommunityType] = useState([]);
  const [locations, setLocations] = useState([]);
  const [codeValidation, setCodeValidation] = useState(false);

  const initMethods = () => {
    initLookup();
    if (props.communityId > 0) {
      getCommunityById();
    }
  }

  const initLookup = () => {
    let inputData = {
      lookups: {
        CommunityType: { default: true },
        Locations: { default: true }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setCommunityType(response.data.CommunityType);
      setLocations(response.data.Locations);
    })
  }

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const validationMap_Item = {
    code: ["required"],
    name: ["required"]
    // communityTypeId: ["select"],
    // locationId: ["select"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const getCommunityById = () => {
    let apiOptions: any = {
      url: `community/${item.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.profileImageUrl = general.getImageUrl(result.profileImagePath);
      setItem(result);
    })
  }

  const addressSelected = (data) => {
    let inputData = { address: data.address, lat: data.lat, lng: data.lng };
    console.log(data);
    handleItemChange(inputData);
  };

  const patternValidation = (val) => {
    return /\s/g.test(val);
  };

  const saveCommunity = () => {
    setCodeValidation(false);
    setError(false);
    const isValid = bulkValidate();
    if (isValid) {
      let codeVal = patternValidation(item.code);
      if (codeVal) {
        setCodeValidation(true);
        return;
      }
      if (item.id == 0 && (!item.userName || !item.password)) {
        setError(true);
        return;
      }
      let apiOptions: any = {
        url: `community`,
        data: item
      };
      http.post(apiOptions).then(res => {
        saveCommunityCallBack(res.data);
      })
    }
  };

  const saveCommunityCallBack = (res) => {
    AlertService.showSuccessMsg();
    props.handleCommunityFormClose();
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: item.id,
      cameraConfig: { url: `community/savecameraimage` },
      fileUploadConfig: { url: `community/savefileimage` }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (item.id > 0) {
      getCommunityById();
    }
    else {
      let filePath = res.filePath;
      let imagePath = general.getImageUrl(filePath);
      handleItemChange({ "profileImagePath": filePath });
      handleItemChange({ "profileImageUrl": imagePath });
    }
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const handleClose = () => {
    props.handleCommunityFormClose();
  }

  useEffect(() => {
    initMethods();
  }, []);


  return (
    <div className='communityform-ui'>
      <div className='row title-font py-1 px-2 drawer-header'>
        <span className="col-11">
          Community Form
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>

      <div className='p-3 pt-5'>
        <div className='row p-3'>
          <div className="col-2 text-center">
            <div>
              <a className="pointer">
                {!item.profileImageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                {item.profileImageUrl && <img src={item.profileImageUrl} alt="prod" width={100} height={100}></img>}
              </a>
              <div className="text-center camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </div>
          </div>
          <div className='col-12 p-3'>
            {
              item.communityTypeId == 1 &&
              <Button color="primary" variant="contained" disabled={true}><b>Normal</b></Button>
            }
            {
              item.communityTypeId == 2 &&
              <Button color="primary" variant="contained" disabled={true}><b>Special</b></Button>
            }
          </div>
          {/* <div className='col-12 p-3'>
            <FxSelect name="communityTypeId" variant="outlined" label="CommunityType"
              options={communityType}
              selectedValue={item.communityTypeId}
              valueField="id"
              displayField="text"
              size="small"
              fullWidth
              onValueChange={handleInputChange} />
            {
              hasError("communityTypeId", "select") &&
              <div className="error">Community Type is required</div>
            }
          </div>

          <div className='col-12 p-3'>
            <FxSelect name="locationId" variant="outlined" label="Location"
              options={locations}
              selectedValue={item.locationId}
              valueField="id"
              displayField="text"
              size="small"
              fullWidth
              onValueChange={handleInputChange} />
            {
              hasError("locationId", "select") &&
              <div className="error">Location is required</div>
            }
          </div> */}
          <div className='col-12 p-3'>
            <FxInput name="code" variant="outlined" fullWidth size="small" label="Code"
              value={item.code} onValueChange={handleInputChange} disabled={item.id > 0} />
            {hasError("code", "required") &&
              <div className="error">Code is required</div>
            }
            {codeValidation &&
              <div className="error">Code should not contain space</div>
            }
          </div>
          <div className='col-12 p-3'>
            <FxInput name="name" variant="outlined" fullWidth size="small" label="Name"
              value={item.name} onValueChange={handleInputChange} />
            {hasError("name", "required") &&
              <div className="error">Name is required</div>
            }
          </div>
          {item.id == 0 &&
            <div className="col-12 p-3">
              <FxInput name="userName" variant="outlined" label="Username"
                fullWidth size="small" value={item.userName} onValueChange={handleInputChange}
              />
              {error && !item.userName &&
                <div className="error">Username is required</div>
              }
            </div>
          }
          {item.id == 0 &&
            <div className="col-12 p-3">
              <FxInput name="password" type="password" variant="outlined" label="Password"
                fullWidth size="small" value={item.password} onValueChange={handleInputChange}
              />
              {error && !item.password &&
                <div className="error">Password is required</div>
              }
            </div>
          }

          {/* <div className='col-12 p-3'>
            {(item.id > 0 && item.address) &&
              <LocationSearchInput address={item.address} name="address"
                fullWidth locationSelected={addressSelected} placeholder="Address" />}

            {(item.id == 0 || !item.address) && <LocationSearchInput address={item.address} name="address"
              fullWidth locationSelected={addressSelected} placeholder="Address" />}
          </div> */}

          <div className='col-12 p-3'>           
            <FxTextarea name="address" variant="outlined" fullWidth size="small" label="Address"
              value={item.address} onValueChange={handleInputChange} maxRows={4} />
          </div>
        </div>
      </div>
      <div className='px-4 py-2 drawer-footer'>
        <div className='px-4 row'>
          <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveCommunity}><b>Save</b></Button>
        </div>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
    </div>
  );
}
export default CommunityForm;
