import React, { useState, useEffect } from 'react';
import './LiveStreams.scss';
import { http, FxDateTimeDisplay, FxDateDisplay, formatService, general, useNavigationService } from '../fx-core';
import _ from "lodash";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const LiveStreams = (props: any) => {
  const [currentStep, setCurrentStep] = useState("running");
  const [liveStreamList, setLiveStreamList] = useState([]);
  const { navigate } = useNavigationService();

  const initMethods = (type) => {
    setCurrentStep(type);
    if (type == 'running') {
      openRunningList();
    }
    else if (type == 'upcoming') {
      openUpcomingList();
    }
  }

  const openRunningList = () => {
    getLiveStreamList('running');
  };

  const openUpcomingList = () => {
    getLiveStreamList('upcoming');
  };

  const getLiveStreamList = (type) => {
    let inputData = {
      type: type,
      currentDate: formatService.getCurrentDateForServer()
    }
    let apiOptions: any = {
      url: `livestream/all`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        item.imageUrl = general.getImageUrl(item.stallImagePath);
      }
      setLiveStreamList(result);
    })
  };

  const handleClose = () => {
    props.handleLiveStreamClose();
  }

  const prepareToJoin = (id) => {
    let apiOptions: any = {
      url: `livestream/${id}/prepareToJoin`,
    };
    http.put(apiOptions).then((res:any) => {
      joinLiveStream(res.data.lh);
    })
  };

  const joinLiveStream = (lh) => {
    props.handleLiveStreamClose();
    let navData = {
      targetRoute: `/provider/livestreamdetail/${lh}`,
      targetTitle: "Live Stream",
      targetData: {},
      sourceState: {},
      eventSource: '',
    };
    navigate(navData);
  }

  useEffect(() => {
    initMethods(currentStep);
  }, []);

  return (
    <div className="livestreams-ui">
      <div className="row justify-content-between title-font py-1 px-4 sticky-top">
        <div>
          <span>Live Shopping</span>
        </div>
        <div className="pointer" onClick={handleClose}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>
      <div className='row p-2 mb-3 mt-2 ml-1'>
        <div>
          <span onClick={() => initMethods('running')} className={currentStep == 'running' ? "px-1 pb-1 pointer selected-btn" : "px-1 pb-1 pointer btn-style"}>
            <span className='p-1'>Running</span>
          </span>
        </div>
        <div className='ml-2'>
          <span onClick={() => initMethods('upcoming')} className={currentStep == 'upcoming' ? "px-1 pb-1 pointer selected-btn" : "px-1 pb-1 pointer btn-style"}>
            <span className='p-1'>Upcoming</span>
          </span>
        </div>
      </div>

      <div className='deskview'>
        <TableContainer component={Paper}>
          <Table stickyHeader className="table table-striped" size="small">
            <TableBody>
              {liveStreamList.map((row, i) => (
                <TableRow>
                  <TableCell width="10%">
                    {!row.imageUrl && <i className="fas fa-user-circle dummy-image"></i>}
                    {row.imageUrl && <img src={row.imageUrl} alt="Item" width="50" height="50"></img>}
                  </TableCell>
                  <TableCell>
                    <div className='row'>
                      <div className='col-8'>
                        <div className='font-weight-bold'>{row.stallName}
                          <span className='ml-1'>
                            {row.liveStreamStatusId == 1 &&
                              <span className='btn-style-info'>Yet To Start</span>
                            }
                            {row.liveStreamStatusId == 2 &&
                              <span className='live-btn-style px-1'>                               
                                <span><i className="fas fa-circle font-6 align-middle"></i></span>
                              <span className='pl-1'>Live</span>                                                        
                              </span>
                            }
                          </span>
                        </div>
                        <div className='font-13 pt-1'>{row.liveStreamName}</div>
                        {row.stallItemId > 0 &&
                          <div className='font-12 pt-1'>{row.stallItemName}</div>
                        }
                        <div>
                          <span className='font-12 color-grey'>{row.description}</span>
                        </div>
                      </div>
                      <div className='col-4 text-right'>
                        <div className='font-12'>
                          <i className="fas fa-calendar color-aaa pr-2"></i>
                          <FxDateDisplay value={row.liveStreamDate} />
                        </div>
                        <div className='font-12 py-1'>
                          <span>
                            <i className="fas fa-clock color-aaa px-1"></i>
                            <FxDateTimeDisplay value={row.startTime} displayType="time" />
                          </span>
                          <span className='px-1'>-</span>
                          <span>
                            <FxDateTimeDisplay value={row.endTime} displayType="time" />
                          </span>
                        </div>
                        <div className='font-12 pb-1'>
                          <span><i className="fas fa-stopwatch color-aaa"></i></span>
                          <span> {row.duration} mins</span>
                        </div>
                        {row.liveStreamStatusId == 2 &&
                          <div className='mt-1'>
                            <Button variant='outlined' size='small' className='button-style' onClick={() => prepareToJoin(row.id)}>Join</Button>
                          </div>
                        }
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>


      <div className='mobileview'>
        <TableContainer component={Paper}>
          <Table stickyHeader className="table table-striped" size="small">
            <TableBody>
              {liveStreamList.map((row, i) => (               
                  <TableRow>
                    <TableCell>
                      <div>
                        <div className='d-flex'>
                          <div className='col-2 px-0'>
                            {!row.imageUrl && <i className="fas fa-user-circle dummy-image"></i>}
                            {row.imageUrl && <img src={row.imageUrl} alt="Item" width="50" height="50"></img>}
                          </div>
                          <div className='col-10 pr-0'>
                            <div className='font-weight-bold'>{row.stallName}
                              <span className='ml-1'>
                                {row.liveStreamStatusId == 1 &&
                                  <span className='btn-style-info'>Yet To Start</span>
                                }
                                {row.liveStreamStatusId == 2 &&
                                  <span className='live-btn-style px-1'>
                                    <span><i className="fas fa-circle font-6 align-middle"></i></span>
                                    <span className='pl-1'>Live</span>
                                    </span>
                                }
                              </span>
                            </div>
                            <div className='font-13 pt-1'>{row.liveStreamName}</div>
                            {row.stallItemId > 0 &&
                              <div className='font-12 pt-1'>{row.stallItemName}</div>
                            }
                            <div>
                              <span className='font-12 color-grey'>{row.description}</span>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='font-12'>
                            <i className="fas fa-calendar color-aaa pr-2"></i>
                            <FxDateDisplay value={row.liveStreamDate} />
                          </div>
                          <div className='font-12'>
                            <span>
                              <i className="fas fa-clock color-aaa px-1"></i>
                              <FxDateTimeDisplay value={row.startTime} displayType="time" />
                            </span>
                            <span className='px-1'>-</span>
                            <span>
                              <FxDateTimeDisplay value={row.endTime} displayType="time" />
                            </span>
                          </div>
                          <div className='font-12'>
                            <span><i className="fas fa-stopwatch color-aaa"></i></span>
                            <span> {row.duration} mins</span>
                          </div>
                          {row.liveStreamStatusId == 2 &&
                            <div>
                              <Button variant='outlined' size='small' className='button-style' onClick={() => prepareToJoin(row.id)}>Join</Button>
                            </div>
                          }
                        </div>
                      </div>
                    </TableCell>
                  </TableRow> 
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='p-4'>
        {liveStreamList && liveStreamList.length == 0 &&
          <div className='text-center'><b>No live shopping</b></div>
        }
      </div>

    </div>
  );
}
export default LiveStreams;