import React, { useState, useEffect } from 'react';
import './ProviderServices.scss';
import { http, useNavigationService, session, general } from '../../fx-core';
import ProviderServiceCard from '../ProviderServiceCard/ProviderServiceCard';
import _ from "lodash";
import readytoeat from '../../../assets/img/readytoeat.svg';
import readytoeatimage from '../../../assets/img/readytoeatimage.png';
import foodbag from '../../../assets/img/foodbag.svg';
import foodbagimage from '../../../assets/img/foodbagimage.png';
import freshproduce from '../../../assets/img/freshproduce.svg';
import freshproduceimage from '../../../assets/img/freshproduceimage.png';
import garagesale from '../../../assets/img/garagesale.svg';
import garageimage from '../../../assets/img/garageimage.png';
import garden from '../../../assets/img/garden.svg';
import gardenimage from '../../../assets/img/gardenimage.png';
import pet from '../../../assets/img/pet.svg';
import petimage from '../../../assets/img/petimage.png';
import professional from '../../../assets/img/professional.svg';
import professionalimage from '../../../assets/img/professionalimage.png';
import creativecraft from '../../../assets/img/creativecraft.svg';
import creativecraftimage from '../../../assets/img/creativecraftimage.png';

const ProviderServices = (props: any) => {

    const initialList = [];
    const { navigate, navigateEntry, clear } = useNavigationService();
    const [services, setServices] = useState(initialList);
    const dataMap = {
        "Ready To Eat": { "icon": readytoeat, "image": readytoeatimage, "description": "Authentic home made cuisines", displayOrder: 1 },
        "Food Bag": { "icon": foodbag, "image": foodbagimage, "description": "Master any cuisine", displayOrder: 2 },
        "Fresh Produce": { "icon": freshproduce, "image": freshproduceimage, "description": "Direct from local gardens to kitchen", displayOrder: 3 },
        "Garage Sale": { "icon": garagesale, "image": garageimage, "description": "Create a local community carnival", displayOrder: 4 },
        "Garden": { "icon": garden, "image": gardenimage, "description": "Your go to local gardening pro", displayOrder: 5 },
        "Pet": { "icon": pet, "image": petimage, "description": "All things for our four legged friends", displayOrder: 6 },
        "Professional": { "icon": professional, "image": professionalimage, "description": "Find the local experts", displayOrder: 7 },
        "CreativeCraft": { "icon": creativecraft, "image": creativecraftimage, "description": "Things made with hand, head & lots of heart", displayOrder: 8 },
    };

    const getCommunityByCode = () => {
        let inputData = {
            code: props.match.params.code
        };
        let apiOptions: any = {
            url: `community/code/${inputData.code}`,
            data: {}
        };
        http.get(apiOptions).then(response => {
            getProviderServices(response.data.id);
        });
    }

    const getProviderServices = (communityId) => {
        let apiOptions: any = {
            url: `portal/services/community/${communityId}`
        };

        http.get(apiOptions).then(res => {
            getProviderServicesCallBack(res);
        })
    };

    const getProviderServicesCallBack = (res) => {
        let result = res.data;
        //filtering except ChildCare as of current req
        //result = result.filter(i => i.defaultBehaviorCode != 'Bookings');
        for (let item of result) {
            //item.icon = dataMap[item.serviceCode]?.icon;
            //item.image = dataMap[item.serviceCode]?.image;
            item.image = general.getImageUrl(item.imagePath);
        }
        setServices(result);
    };

    const navToProviderSetup = (item) => {
        session.setItem('otherCategoryItems', JSON.stringify([]));
        let navData = {
            targetRoute: '/provider/createservicerequest/' + item.id,
            targetTitle: "Service Home",
            targetData: {},
            sourceState: {},
            eventSource: '',
        };
        navigate(navData);
    }

    useEffect(() => {
        getCommunityByCode();
    }, []);

    return (
        <div className="providerservices-ui">
            <div className="content-container marginTopStyle">
                {/* Desktop View */}
                <div className='deskview'>
                    <div className='row mx-4 my-5'>
                        {
                            services.length > 0 && services.map((item, i) => (
                                <div className="col-2">
                                    <ProviderServiceCard item={item} navToProviderSetup={navToProviderSetup} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProviderServices;