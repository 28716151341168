import { Button, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FxInput, FxTextarea, http, AlertService, localstorage, formValidator, FxSelect, FxDate, FxTime, formatService, FxCurrency } from '../../fx-core';
import './LiveStreamForm.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import useRazorpay from '../../utils/useRazorPay';

const LiveStreamForm = (props) => {
  const Razorpay = useRazorpay();
  
  const defaultItem = {
    liveStreamName: "",
    description: "",
    liveStreamDate: "",
    duration: 30,
    startTime: "",
    endTime: "",
    id: props.liveStreamId > 0 ? props.liveStreamId : 0,
    communityId: parseInt(localstorage.getItem("communityId")),
    userId: localstorage.getItem("userId") ? parseInt(localstorage.getItem("userId")) : 0,
    stallId: -1,
    stallItemId: 0,
    startTimeVal: "",
    endTimeVal: "",
    maxParticipants: 0,
    allowRecording: false,
    paymentMode: '-1',
    couponCode: "",
    paymentStatus: "Pending",
    liveStreamStatusId: 1
  };

  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [stalls, setStalls] = useState([]);
  const [stallItems, setStallItems] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [amount, setAmount] = useState(0);

  const initLookup = () => {
    let inputData = {
      lookups: {
        ProviderStalls: { default: true },
        PaymentMode: { default: true }
      },
      filters: {
        userId: item.userId,
        communityId: item.communityId
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initLookupCallBack(response);
    })
  }

  const initLookupCallBack = (res) => {
    let result = res.data;
    setPaymentOptions(result.PaymentMode);
    setStalls(result.ProviderStalls);
    if (item.id > 0) {
      getLiveStreamById();
    }
  };

  const getLiveStreamById = () => {
    let apiOptions: any = {
      url: `livestream/${item.id}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      result.startTimeVal = result.startTime != null ? formatService.getConvertedTimeForDisplay(result.startTime) : null;
      result.endTimeVal = result.endTime != null ? formatService.getConvertedTimeForDisplay(result.endTime) : null;
      result.paymentMode = result.paymentMode != null ? result.paymentMode : "-1";

      if (result.stallId > 0) {
        getStallItemsByStallId(result.stallId);
      }
      setItem(result);
    });
  };

  const handleInputChange = (data) => {
    handleItemChange({ [data.name]: data.value });
    data.validations = getInputValidations(data.name);
    const validationResult = formValidator.validate(data);
    const validationData = { [data.name]: validationResult };
    updateErrors(validationData);
    if (data.name == "stallId") {
      getStallItemsByStallId(data.value);
      handleItemChange({ "stallItemId": 0 });
    }
    if (data.name == "startTimeVal") {
      let calculatedEndTime = formatService.getTimeByAddedMins(data.value, item.duration);
      handleItemChange({ "endTimeVal": calculatedEndTime });
    }
    if (data.name == "duration") {
      let calculatedEndTime = formatService.getTimeByAddedMins(item.startTimeVal, data.value);
      handleItemChange({ "endTimeVal": calculatedEndTime });
    }
  }

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
    if (event.target.checked) {
      getLiveStreamAmount(5); // 5-LiveStreamWithRecording
    }
    else {
      getLiveStreamAmount(4); // 4-LiveStream
    }
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const validationMap_Item = {
    liveStreamName: ["required"],
    description: ["required"],
    liveStreamDate: ["required"],
    startTimeVal: ["required"],
    endTimeVal: ["required"],
    stallId: ["select"],
    paymentMode: ["select"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const saveLiveStreamForm = () => {
    const isValid = bulkValidate();
    if (isValid) {
      item.liveStreamDate = formatService.getConvertedDate(item.liveStreamDate);
      item.startTime = formatService.getTimeForServer(item.startTimeVal);
      item.endTime = formatService.getTimeForServer(item.endTimeVal);
      item.duration = item.duration ? item.duration : 0;
      item.maxParticipants = 0;
      saveLiveStream();
    }
  };

  const saveLiveStream = () => {
    let apiOptions: any = {
      url: `livestream/save`,
      data: item
    };
    http.post(apiOptions).then(res => {
      saveLiveStreamCallBack(res);
    });
  };


  const saveLiveStreamCallBack = (res) => {
    AlertService.showSuccessMsg();
    if (item.paymentMode == 'razorpay' && item.paymentStatus == "Pending") {
      initiatePayment(res.data);
    }
    else {
      handleClose();
    }
  }

  const getStallItemsByStallId = (id) => {
    let inputData = {
      id: id
    }
    let apiOptions: any = {
      url: `stallitem/stall/${inputData.id}/query`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      setStallItems(result);
    })
  };

  const handleClose = () => {
    props.handleLiveStreamFormClose();
  }

  const initiatePayment = (lvStreamId) => {
    let inputData = {
      entityTypeId: item.allowRecording ? 5 : 4,//5  - LiveStreamWithRecording and 4  - LiveStream
      entityId: lvStreamId
    };
    let apiOptions: any = {
      url: `payment/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
      handleClose();
    });
  }
  const openPaymentLink = (resp: any) => {
    var item =  resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.providerName;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {        
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `payment/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  const getLiveStreamAmount = (featureId) => {
    let apiOptions = {
      url: `coupon/feature/price/${featureId}`,
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      if (result && result.price > 0) {
        setAmount(result.price);
      }
      else {
        setAmount(0);
        AlertService.showErrorMsg("Price not configured.")
      }
    });
  }

  useEffect(() => {
    initLookup();
    getLiveStreamAmount(4); //4-LiveStream
  }, []);


  return (
    <div className='livestream-form'>
      <div className="row justify-content-between title-font py-1 px-4 sticky-top">
        <div>
          <span>Create Live Shopping</span>
        </div>
        <div className="pointer" onClick={handleClose}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>

      <div className="pt-3 pb-5 drawer-spacing">
        <div className='row p-3'>
          <div className='col-12'>
            <FxInput name="liveStreamName" variant="outlined" fullWidth size="small" label="Live Shopping Name*"
              value={item.liveStreamName} onValueChange={handleInputChange} />
            {hasError("liveStreamName", "required") && (
              <div className="error">Live Shopping Name is required</div>
            )}
          </div>
        </div>

        <div className='row p-3'>
          <div className='col-12'>
            <FxTextarea name="description" variant="outlined" size="small" label="Description*" fullWidth maxRows={4}
              value={item.description} onValueChange={handleInputChange} />
            {hasError("description", "required") && (
              <div className="error">Description is required</div>
            )}
          </div>
        </div>

        <div className='row px-3 pt-2'>
          <div className='col-6'>
            <FxDate name="liveStreamDate" variant="outlined" fullWidth label="Live Shopping Date*"
              value={item.liveStreamDate} onValueChange={handleInputChange} />
            {hasError("liveStreamDate", "required") &&
              <div className="error">Live Shopping Date is required</div>
            }
          </div>
          <div className='col-6 pt-3'>
            <FxInput type="number" name="duration" variant="outlined" fullWidth size="small" label="Duration (mins)"
              value={item.duration || ""} onValueChange={handleInputChange} />
            {hasError("duration", "required") &&
              <div className="error">Duration is required</div>
            }
          </div>
        </div>

        <div className='row p-3'>
          <div className='col-6'>
            <FxTime name="startTimeVal" variant="outlined" fullWidth label="Start Time*"
              value={item.startTimeVal} onValueChange={handleInputChange} />
            {hasError("startTimeVal", "required") &&
              <div className="error">Start Time is required</div>
            }
          </div>
          <div className='col-6'>
            <FxTime name="endTimeVal" variant="outlined" fullWidth label="End Time*"
              value={item.endTimeVal} onValueChange={handleInputChange} />
            {hasError("endTimeVal", "required") &&
              <div className="error">End Time is required</div>
            }
          </div>
        </div>

        <div className='row p-3'>
          <div className='col-6'>
            <FxSelect name="stallId" variant="outlined" label="Select Stall*"
              options={stalls}
              selectedValue={item.stallId}
              valueField="id"
              displayField="text"
              size="small"
              onValueChange={handleInputChange} />
            {hasError("stallId", "select") &&
              <div className="error">Stall is required</div>
            }
          </div>
          <div className='col-6'>
            <FxSelect name="stallItemId" variant="outlined" label="Select Stall Item"
              options={stallItems}
              selectedValue={item.stallItemId}
              valueField="id"
              displayField="itemName"
              size="small"
              onValueChange={handleInputChange} />
          </div>
        </div>
        <div className='row p-3'>
          <div className='col-6'>
            <FxSelect name="paymentMode" variant="outlined" label="Select Payment"
              disabled={item.paymentStatus === "Paid"}
              options={paymentOptions}
              selectedValue={item.paymentMode}
              valueField="code"
              displayField="text"
              size="small"
              onValueChange={handleInputChange} />
            {hasError("paymentMode", "select") && (
              <div className="error row">Payment is required</div>
            )}
          </div>
          {
            item.paymentMode == 'coupon' &&
            <div className='col-6'>
              <FxInput name="couponCode" variant="outlined" label="Coupon" fullWidth size="small"
                value={item.couponCode} onValueChange={handleInputChange} disabled={item.paymentStatus === "Paid"} />
            </div>
          }
        </div>
        {
          item.paymentMode == "accounttransfer" && item.liveStreamStatusId == 1 &&
          <div className='col-6'>
            <div>A/C No : 50200095946891</div>
            <div>Name : AOTEAROA SOLUTIONS PVT LTD</div>
            <div>IFSC: HDFC0002231</div>
            <div>Branch: SARAVANAMPATTI BRANCH</div>
            <div>Account Type: CURRENT</div>
            <div className='mt-2 text-danger'>
              Kindly transfer the amount and then save. Request to be approved shortly
            </div>
          </div>
        }
        <div className='row p-3'>
          {/* <div className='col-6'>
            <FxInput type="number" name="maxParticipants" variant="outlined" fullWidth size="small" label="Maximum Participants"
              value={item.maxParticipants || ""} onValueChange={handleInputChange} />
          </div> */}
          <div className='col-6'>
            <Checkbox name="allowRecording" color="primary" checked={item.allowRecording} value={item.allowRecording} onChange={handleCheckbox}
              disabled={item.paymentStatus === "Paid"} />
            Allow Recording
          </div>
        </div>
        <div className='row p-3'>
          <span className='ml-4'>Total Amount</span>
          <span className='ml-2'>
            <FxCurrency value={amount} />
          </span>
        </div>
      </div>
      <div className='px-3 py-2 drawer-footer'>
        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveLiveStreamForm}><b>Save</b></Button>
      </div>
    </div>
  );
}
export default LiveStreamForm;
