import React, { useEffect, useState } from 'react';
import './UserList.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@material-ui/core';
import { http, FxInput, useNavigationService, FxSelect, localstorage } from '../../fx-core';
import { NoRecords } from '../../NoRecords/NoRecords';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const UserList = () => {
  const { routeData, back } = useNavigationService();
  let defaultContext: any = {
    firstName: "",
    communityId: routeData.communityId
  };
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [users, setUsers] = useState([]);
  const [communityList, setCommunityList] = useState([]);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    let filterObj = {
      firstName: currentContext.firstName,
      communityId: currentContext.communityId
    }
    if (item.name == "firstName") {
      filterObj.firstName = item.value;
    }
    else if (item.name == 'communityId') {
      filterObj.communityId = item.value;
    }
    getUsers(filterObj);
  };

  const handleItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getUsers = (data: any) => {
    let inputData = {
      defaultCommunityId: data.communityId,
      firstName: data.firstName,
    };
    let apiOptions: any = {
      url: `user/community/dashboard`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      let result = res.data;
      setUsers(result);
    })
  };

  const navToBack = () => {
    back();
  };

  const getMyRegionCommunities = () => {
    let inputData = {
      lookups: {
        MyRegionCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyRegionCommunitiesCallBack(response);
    })
  };

  const getMyRegionCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyRegionCommunities);
  };

  const getMyZoneCommunities = () => {
    let inputData = {
      lookups: {
        MyZoneCommunities: { default: false }
      },
      filters: {
        userId: localstorage.getItem("userId")
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getMyZoneCommunitiesCallBack(response);
    })
  }

  const getMyZoneCommunitiesCallBack = (res) => {
    let result = res.data;
    setCommunityList(result.MyZoneCommunities);
  };

  const getMyAdminCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getMyAdminCommunitiesCallBack(res) });
  };

  const getMyAdminCommunitiesCallBack = (res) => {
    let result = res.data;
    let commuArr: any = [];
    for (let item of result) {
      let itemObj: any = {
        id: item.id,
        text: item.name
      };
      commuArr.push(itemObj);
    }
    setCommunityList(commuArr);
  };

  const initCalls = () => {
    if (routeData.parent == 'REGIONALMANAGER') {
      getMyRegionCommunities();
    }
    else if (routeData.parent == 'ZONALMANAGER') {
      getMyZoneCommunities();
    }
    else if (routeData.parent == 'COMMADMIN') {
      getMyAdminCommunities();
    }
  }

  useEffect(() => {
    initCalls();
    getUsers(currentContext);
  }, []);

  return (
    <div className='user-list-ui pt-4 mt-3'>
      <div className="title-font custom-head pl-4 pt-5">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToBack}>
            <b>{routeData.communityName}</b>
          </Link>
          <Typography color="primary" className="breadcrumbs-style">Users</Typography>
        </Breadcrumbs>
      </div>
      <div className='row my-4 mx-2'>
        <div className="col-3">
          <FxInput
            name="firstName"
            value={currentContext.firstName}
            variant="outlined"
            label="Search"
            fullWidth
            size="small"
            onValueChange={handleInputChange}
          />
        </div>
        <div className='col-3'>
          <FxSelect name="communityId" variant="outlined"
            label="Community"
            options={communityList}
            selectedValue={currentContext.communityId}
            valueField="id"
            displayField="text"
            size="small"
            onValueChange={handleInputChange} />
        </div>
      </div>

      <div className='row my-4 mx-4 pt-2'>
        <TableContainer component={Paper}>
          <Table className="table-striped" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Community</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length == 0 &&
                <TableCell colSpan={4}>
                  <NoRecords />
                </TableCell>
              }
              {
                users.length > 0 && users.map((row: any, i) => (
                  <TableRow>
                    <TableCell>
                      <span>{row.firstName}</span>
                      {
                        row.lastName &&
                        <span>{row.lastName}</span>
                      }
                    </TableCell>
                    <TableCell>
                      {row.communityName}
                    </TableCell>
                    <TableCell>
                      {row.mobile}
                    </TableCell>
                    <TableCell>
                      {row.email}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
export default UserList;