import React, { useEffect, useState } from 'react';
import './BookingForm.scss';
import { FxSelect, FxInput, FxTextarea, FxDate, FxTime, http, localstorage, formValidator, general, AlertService, formatService } from '../../fx-core';
import { Dialog, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import _ from 'lodash';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { nanoid } from 'nanoid';

const BookingForm = (props) => {
    let cid = localstorage.getItem("communityId");
    const communityId = (cid && cid != null) ? parseInt(cid) : 0
    const defaultBooking = {
        stallId: props.stallId,
        communityId: communityId,
        name: "",
        description: "",
        bookingModeId: 1,
        venueDetail: "",
        url: "",
        durationInMins: 0,
        pricePerSeat: "0",
        unit: "",
        maxCapacityPerSlot: 0,
        allowBookingBeforeNDays: 0,
        stopBookingBeforeNDays: 0,
        stopBookingMessage: "",
        maxSeatPerBooking: 0,
        bookingTypeId: 1,
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        imagePath: "",
        imageUrl: "",
        id: props.id
    };
    const displayDayMap = {
        'Sunday': { display: 'Sun', displayOrder: 1 },
        'Monday': { display: 'Mon', displayOrder: 2 },
        'Tuesday': { display: 'Tue', displayOrder: 3 },
        'Wednesday': { display: 'Wed', displayOrder: 4 },
        'Thursday': { display: 'Thu', displayOrder: 5 },
        'Friday': { display: 'Fri', displayOrder: 6 },
        'Saturday': { display: 'Sat', displayOrder: 7 }
    };
    const defaultBookingHours = [
        { id: 0, day: 'Sunday', startTime: "", endTime: "", uid: nanoid() },
        { id: 0, day: 'Monday', startTime: "", endTime: "", uid: nanoid() },
        { id: 0, day: 'Tuesday', startTime: "", endTime: "", uid: nanoid() },
        { id: 0, day: 'Wednesday', startTime: "", endTime: "", uid: nanoid() },
        { id: 0, day: 'Thursday', startTime: "", endTime: "", uid: nanoid() },
        { id: 0, day: 'Friday', startTime: "", endTime: "", uid: nanoid() },
        { id: 0, day: 'Saturday', startTime: "", endTime: "", uid: nanoid() }
    ];
    let bookingModeArr: any = []; //After getById call lookup state not updated. So added this global array for safer side.
    let bookingTypeArr: any = [];

    const [booking, setBooking] = useState(defaultBooking);
    const [errors, setErrors] = useState({});
    const [stalls, setStalls] = useState([]);
    const [bookingModes, setBookingModes] = useState([]);
    const [bookingTypes, setBookingTypes] = useState([]);
    const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
    const [uploadConfig, setUploadConfig] = useState({});
    const [imageSlideOpen, setImageSlideOpen] = useState(false);
    const [imageConfig, setImageConfig] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [bookingHours, setBookingHours] = useState(defaultBookingHours);
    const [message, setMessage] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleInputChange = (item) => {
        handleBookingChange({ [item.name]: item.value });
        item.validations = getInputValidations(item.name);
        const validationResult = formValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleBookingChange = (data) => {
        setBooking(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                ProviderStalls: { default: false },
                BookingMode: { default: false },
                BookingType: { default: false }
            },
            filters: {
                userId: localstorage.getItem('userId'),
                communityId: localstorage.getItem("communityId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            initLookupCallBack(response);
        })
    };

    const initLookupCallBack = (res) => {
        let result = res.data;
        setStalls(result.ProviderStalls);
        let modes: any = [];
        for (let mode of result.BookingMode) {
            modes.push({
                name: 'bokkingmode',
                text: mode.text,
                value: mode.id,
                checked: mode.id == 1
            });
        }
        bookingModeArr = modes;
        setBookingModes(modes);
        let types: any = [];
        for (let type of result.BookingType) {
            types.push({
                name: 'bokkingtype',
                text: type.text,
                value: type.id,
                checked: type.id == 1
            });
        }
        bookingTypeArr = types;
        setBookingTypes(types);
        if (booking.id > 0) {
            getStallBookingById();
        }
    };

    const getStallBookingById = () => {
        let apiOptions: any = {
            url: `stallbooking/${booking.id}`
        };
        http.get(apiOptions).then(res => {
            getStallBookingByIdCallBack(res);
        });
    };

    const getStallBookingByIdCallBack = (res) => {
        let result = res.data;
        result.imageUrl = general.getImageUrl(result.imagePath);
        const modes = updateBookingModeChecked({ value: result.bookingModeId });
        setBookingModes(modes);
        const types = updateBookingTypeChecked({ value: result.bookingTypeId });
        setBookingTypes(types);
        if (result.bookingTypeId == 2) {
            result.startTime = formatService.getConvertedTimeForDisplay(result.startTime);
            result.endTime = formatService.getConvertedTimeForDisplay(result.endTime);
        }
        setBooking(result);
        getStallBookingHours();
    };

    const getStallBookingHours = () => {
        let inputData = {
            id: booking.id
        }
        let apiOptions: any = {
            url: `stallbookinghours/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getStallBookingHoursCallBack(res);
        });
    };

    const getStallBookingHoursCallBack = (res) => {
        const result = res.data;
        if (result.length > 0) {
            for (let item of result) {
                item.startTime = item.startTime ? formatService.getConvertedTimeForDisplay(item.startTime) : "";
                item.endTime = item.endTime ? formatService.getConvertedTimeForDisplay(item.endTime) : "";
                item.displayOrder = displayDayMap[item.day].displayOrder;
                item.uid = nanoid();
            }
            let sortedHours: any = _.orderBy(result, ['displayOrder'], ['asc']);
            setBookingHours(sortedHours);
        }
    };

    const getValidationMap = () => {
        let validationMap: any = {};
        if (currentStep == 1) {
            validationMap.stallId = ["select"];
            validationMap.name = ["required"];
            validationMap.description = ["required"];
            if (booking.bookingModeId == 1) {
                validationMap.venueDetail = ["required"];
            }
            else if (booking.bookingModeId == 2) {
                validationMap.url = ["required"];
            }
        }
        else if (currentStep == 2) {
            validationMap.durationInMins = ["number"];
            validationMap.pricePerSeat = ["price"];
            validationMap.unit = ["required"];
            validationMap.maxCapacityPerSlot = ["number"];
            validationMap.allowBookingBeforeNDays = ["number"];
            validationMap.maxSeatPerBooking = ["number"];
        }
        else if (currentStep == 3 && booking.bookingTypeId == 2) {
            validationMap.startDate = ["required"];
            validationMap.startTime = ["required"];
            validationMap.endDate = ["required"];
            validationMap.endTime = ["required"];
        }
        return validationMap;
    };

    const getInputValidations = (name) => {
        let validationMap = getValidationMap();
        return validationMap[name];
    };

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidationMap();
        for (var key in validationMap) {
            let result = { name: key, value: booking[key], validations: validationMap[key] }
            items.push(result);
        }
        let validationResult = formValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    };

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    };

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveStallBooking = () => {
        const isValid = bulkValidate();
        if (isValid) {
            if (booking.id > 0) {
                updateStallBooking();
            }
            else {
                addStallBooking();
            }
        }
    };

    const getBookingData = () => {
        const venueDetail = booking.bookingModeId == 1 ? booking.venueDetail : "";
        const url = booking.bookingModeId == 2 ? booking.url : "";
        const startDate = booking.bookingTypeId == 2 ? formatService.getDateStringForServer(booking.startDate) : null;
        const startTime = booking.bookingTypeId == 2 ? formatService.getTimeString(booking.startTime) : null;
        const endDate = booking.bookingTypeId == 2 ? formatService.getDateStringForServer(booking.endDate) : null;
        const endTime = booking.bookingTypeId == 2 ? formatService.getTimeString(booking.endTime) : null;
        let item: any = {
            id: booking.id,
            communityId: booking.communityId,
            imagePath: booking.imagePath,
            stallId: booking.stallId,
            name: booking.name,
            description: booking.description,
            durationInMins: booking.durationInMins,
            pricePerSeat: booking.pricePerSeat,
            maxCapacityPerSlot: booking.maxCapacityPerSlot,
            allowBookingBeforeNDays: booking.allowBookingBeforeNDays,
            maxSeatPerBooking: booking.maxSeatPerBooking,
            bookingModeId: booking.bookingModeId,
            venueDetail: venueDetail,
            url: url,
            bookingTypeId: booking.bookingTypeId,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            unit: booking.unit,
            stopBookingBeforeNDays: booking.stopBookingBeforeNDays,
            stopBookingMessage: booking.stopBookingMessage
        };
        return item;
    };

    const getBookingHoursData = () => {
        let res: any = [];
        for (let item of bookingHours) {
            res.push({
                id: item.id,
                stallId: booking.stallId,
                communityId: communityId,
                day: item.day,
                startTime: item.startTime != "" ? formatService.getTimeString(item.startTime) : "",
                endTime: item.endTime != "" ? formatService.getTimeString(item.endTime) : "",
                status: 1
            });
        }
        return res;
    };

    const addStallBooking = () => {
        const bookingData = getBookingData();
        const bookingHrs = getBookingHoursData();
        let inputData: any = {
            stallBooking: bookingData,
            stallBookingHours: bookingHrs
        };
        let apiOptions: any = {
            url: `stallbooking/add`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            saveStallBookingCallback(res);
        });
    };

    const updateStallBooking = () => {
        const bookingData = getBookingData();
        const bookingHrs = getBookingHoursData();
        let inputData: any = {
            stallBooking: bookingData,
            stallBookingHours: bookingHrs
        };
        let apiOptions: any = {
            url: `stallbooking/update`,
            data: inputData
        };
        http.put(apiOptions).then(res => {
            saveStallBookingCallback(res);
        });
    };

    const saveStallBookingCallback = (res) => {
        AlertService.showSuccessMsg();
        handleClose();
    };

    const openPhotoCapture = () => {
        let photoCaptureConfig = {
            id: booking.id,
            cameraConfig: { url: `stallbooking/savecameraimage` },
            fileUploadConfig: { url: `stallbooking/savefileimage` }
        };
        if (booking.communityId > 0) {
            photoCaptureConfig = {
                id: booking.id,
                cameraConfig: { url: `stallbooking/${booking.communityId}/savecameraimage` },
                fileUploadConfig: { url: `stallbooking/${booking.communityId}/savefileimage` }
            };
        }
        setUploadConfig(photoCaptureConfig);
        setPhotoCaptureOpen(true);
    };

    const onUploadCallBack = (res: any) => {
        setPhotoCaptureOpen(false);
        if (booking.id > 0) {
            getStallBookingById();
        }
        else {
            let imagePath = res.filePath;
            let imageUrl = general.getImageUrl(imagePath);
            handleBookingChange({ imagePath: imagePath, imageUrl: imageUrl });
        }
    };

    const handlePhotoCaptureClose = () => {
        setPhotoCaptureOpen(false);
    };

    const openImages = () => {
        let config = {
            api: `stallbookingimagemap/${booking.id}/images`,
            title: booking.name,
            updateDefaultImageApi: `stallbookingimagemap/update/defaultimage`,
            deleteImageApi: `stallbookingimagemap/delete/image`,
        };
        setImageConfig(config);
        setImageSlideOpen(true);
    };

    const handleImageSlideClose = () => {
        setImageSlideOpen(false);
        setImageConfig({});
        getStallBookingById();
    };

    const updateBookingModeChecked = (selected) => {
        let items: any = [];
        if (bookingModes.length > 0) {
            items = bookingModes.map((item: any) => {
                item.checked = false;
                if (item.value === selected.value) {
                    item.checked = true;
                }
                return item;
            });
        }
        else {
            items = bookingModeArr.map((item: any) => {
                item.checked = false;
                if (item.value === selected.value) {
                    item.checked = true;
                }
                return item;
            });
        }
        return items;
    };

    const updateBookingTypeChecked = (selected) => {
        let items: any = [];
        if (bookingTypes.length > 0) {
            items = bookingTypes.map((item: any) => {
                item.checked = false;
                if (item.value === selected.value) {
                    item.checked = true;
                }
                return item;
            });
        }
        else {
            items = bookingTypeArr.map((item: any) => {
                item.checked = false;
                if (item.value === selected.value) {
                    item.checked = true;
                }
                return item;
            });
        }
        return items;
    };

    const handleBookingModeChange = (selected) => {
        const data = { bookingModeId: selected.value };
        handleBookingChange(data);
        let items = updateBookingModeChecked(selected);
        setBookingModes(items);
    };

    const handleBookingTypeChange = (selected) => {
        const data = { bookingTypeId: selected.value };
        handleBookingChange(data);
        let items = updateBookingTypeChecked(selected);
        setBookingTypes(items);
    };

    const handleTimeChange = (event, item) => {
        let items = bookingHours.map(h => {
            if (h.uid == item.uid) {
                if (event.name == 'startTime') {
                    item.startTime = event.value;
                }
                else if (event.name == 'endTime') {
                    item.endTime = event.value;
                }
            }
            return h;
        });
        setBookingHours(items);
    };

    const addNewBookingHour = (event: any, item: any) => {
        event.stopPropagation();
        let items: any = []
        for (let bh of bookingHours) {
            items.push(bh);
        }
        let index = _.findIndex(bookingHours, { uid: item.uid });
        let newItem = { id: 0, day: item.day, startTime: "", endTime: "", uid: nanoid() };
        items.splice(index + 1, 0, newItem);
        setBookingHours(items);
    };

    const confirmDelete = (event: any, item: any) => {
        event.stopPropagation();
        setConfirmOpen(true);
        setSelectedItem(item);
        setMessage("Are you sure to delete " + displayDayMap[item.day].display + "?");
    };

    const handleDialogClose = () => {
        setConfirmOpen(false);
        setSelectedItem({});
    };

    const onConfirmCallback = () => {
        const item = selectedItem;
        deleteBookingHours(item);
        handleDialogClose();
    };

    const deleteBookingHours = (item: any) => {
        let dayItems = bookingHours.filter(b => b.day == item.day);
        if (dayItems.length > 1) {
            if (item.id > 0) {
                let apiOptions: any = {
                    url: `stallbookinghours/${item.id}`
                };
                http.delete(apiOptions).then(res => {
                    deleteBookingHoursCallBack(res);
                });
            }
            else {
                let filterHours = bookingHours.filter(h => h.uid != item.uid);
                setBookingHours(filterHours);
                AlertService.showSuccessMsg();
            }
        }
        else {
            AlertService.showErrorMsg("There should be atleast one record for each day");
        }
    };

    const deleteBookingHoursCallBack = (res) => {
        let id = res.data;
        let filterHours = bookingHours.filter(i => i.id != id);
        setBookingHours(filterHours);
        AlertService.showSuccessMsg();
    };

    const handleNext = () => {
        const isValid = bulkValidate();
        if (isValid) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleClose = () => {
        props.handleClose();
    };

    useEffect(() => {
        initLookup();
    }, [])

    return (
        <div className='bookingform-ui'>
            <div className='row title-font py-1 px-2 drawer-header'>
                <span className="col-11">
                    Booking Form
                </span>
                <span className="col-1 text-center">
                    <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
                </span>
            </div>
            <div className='mb-4 pb-5'>
                {
                    currentStep == 1 &&
                    <div>
                        <div className='p-3 pt-5'>
                            <div className='row'>
                                <div className="col-2 text-center">
                                    <div>
                                        {
                                            booking.id > 0 &&
                                            <a className="pointer" onClick={openImages}>
                                                {!booking.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                                                {booking.imageUrl && <img src={booking.imageUrl} alt="prod" width={100} height={100}></img>}
                                            </a>
                                        }
                                        {
                                            booking.id == 0 &&
                                            <a className="pointer">
                                                {!booking.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                                                {booking.imageUrl && <img src={booking.imageUrl} alt="prod" width={100} height={100}></img>}
                                            </a>
                                        }
                                        <div className="text-center camera-align" onClick={openPhotoCapture}>
                                            <CameraAltIcon className='pointer' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 p-3'>
                            {
                                bookingModes.map((item: any, i) => (
                                    <span key={i}>
                                        <FormControlLabel
                                            name={item.name}
                                            value={item.value}
                                            control={<Radio color="primary" size="small" />}
                                            label={item.text}
                                            checked={item.checked}
                                            onChange={() => handleBookingModeChange(item)}
                                        />
                                    </span>
                                ))
                            }
                        </div>
                        {
                            booking.bookingModeId == 1 &&
                            <div className='col-12 p-3'>
                                <FxInput name="venueDetail" variant="outlined" fullWidth size="small" label="Venue Details"
                                    value={booking.venueDetail}
                                    onValueChange={handleInputChange} />
                                {
                                    hasError("venueDetail", "required") &&
                                    <div className="error">Venue Details is required</div>
                                }
                            </div>
                        }
                        {
                            booking.bookingModeId == 2 &&
                            <div className='col-12 p-3'>
                                <FxInput name="url" variant="outlined" fullWidth size="small" label="URL"
                                    value={booking.url}
                                    onValueChange={handleInputChange} />
                                {
                                    hasError("url", "required") &&
                                    <div className="error">URL is required</div>
                                }
                            </div>
                        }
                        <div className='col-12 p-3'>
                            <FxSelect name="stallId" variant="outlined" fullWidth size="small" label="Stall"
                                options={stalls}
                                selectedValue={booking.stallId}
                                valueField="id"
                                displayField="text"
                                disabled={booking.id > 0}
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("stallId", "select") &&
                                <div className="error">Stall is required</div>
                            }
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="name" variant="outlined" fullWidth size="small" label="Name"
                                value={booking.name}
                                onValueChange={handleInputChange} />
                            {
                                hasError("name", "required") &&
                                <div className="error">Name is required</div>
                            }
                        </div>
                        <div className='col-12 p-3'>
                            <FxTextarea name="description" variant="outlined" fullWidth size="small" label="Description"
                                value={booking.description}
                                onValueChange={handleInputChange}
                                maxRows={4}
                            />
                            {
                                hasError("description", "required") &&
                                <div className="error">Description is required</div>
                            }
                        </div>
                    </div>
                }
                {
                    currentStep == 2 &&
                    <div className='pt-5'>
                        <div className='col-12 p-3'>
                            <FxInput name="durationInMins" variant="outlined" fullWidth size="small" label="Duration In Minutes"
                                type="number"
                                value={booking.durationInMins}
                                onValueChange={handleInputChange} />
                            {
                                hasError("durationInMins", "number") &&
                                <div className="error">Duration In Minutes is required</div>
                            }
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="pricePerSeat" variant="outlined" fullWidth size="small" label="Price"
                                type="number"
                                value={booking.pricePerSeat}
                                onValueChange={handleInputChange} />
                            {
                                hasError("pricePerSeat", "price") &&
                                <div className="error">Price is required</div>
                            }
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="unit" variant="outlined" fullWidth size="small" label="Unit"
                                value={booking.unit}
                                onValueChange={handleInputChange} />
                            {
                                hasError("unit", "required") &&
                                <div className="error">Unit is required</div>
                            }
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="maxCapacityPerSlot" variant="outlined" fullWidth size="small" label="Max Capacity Per Slot"
                                type="number"
                                value={booking.maxCapacityPerSlot}
                                onValueChange={handleInputChange} />
                            {
                                hasError("maxCapacityPerSlot", "number") &&
                                <div className="error">Max Capacity Slot is required</div>
                            }
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="allowBookingBeforeNDays" variant="outlined" fullWidth size="small" label="Allow Booking Before N Days"
                                type="number"
                                value={booking.allowBookingBeforeNDays}
                                onValueChange={handleInputChange} />
                            {
                                hasError("allowBookingBeforeNDays", "number") &&
                                <div className="error">Allow Booking Before N Days is required</div>
                            }
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="stopBookingBeforeNDays" variant="outlined" fullWidth size="small" label="Stop Booking Before N Days"
                                type="number"
                                value={booking.stopBookingBeforeNDays}
                                onValueChange={handleInputChange} />
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="stopBookingMessage" variant="outlined" fullWidth size="small" label="Stop Booking Alert Message"
                                value={booking.stopBookingMessage}
                                onValueChange={handleInputChange} />
                        </div>
                        <div className='col-12 p-3'>
                            <FxInput name="maxSeatPerBooking" variant="outlined" fullWidth size="small" label="Max Seat Per Booking"
                                type="number"
                                value={booking.maxSeatPerBooking}
                                onValueChange={handleInputChange} />
                            {
                                hasError("maxSeatPerBooking", "number") &&
                                <div className="error">Max Seat Per Booking is required</div>
                            }
                        </div>
                    </div>
                }
                {
                    currentStep == 3 &&
                    <div className='pt-5'>
                        <div className='col-12 p-3'>
                            {
                                bookingTypes.map((item: any, i) => (
                                    <span key={i}>
                                        <FormControlLabel
                                            name={item.name}
                                            value={item.value}
                                            control={<Radio color="primary" size="small" />}
                                            label={item.text}
                                            checked={item.checked}
                                            onChange={() => handleBookingTypeChange(item)}
                                        />
                                    </span>
                                ))
                            }
                        </div>
                        {
                            booking.bookingTypeId == 1 &&
                            <div className='mx-3 py-5'>
                                <div className='my-4 stall-timing-scroll'>
                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" className='table-striped'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Days</TableCell>
                                                    <TableCell>Start Time</TableCell>
                                                    <TableCell>End Time</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                bookingHours.map((row: any, i) => (
                                                    <TableBody key={i}>
                                                        <TableRow>
                                                            <TableCell>{displayDayMap[row.day].display}</TableCell>
                                                            <TableCell>
                                                                <FxTime name="startTime" variant="outlined" value={row.startTime}
                                                                    onValueChange={(e) => handleTimeChange(e, row)} className="timestyle" />
                                                            </TableCell>
                                                            <TableCell>
                                                                <FxTime name="endTime" variant="outlined" value={row.endTime}
                                                                    onValueChange={(e) => handleTimeChange(e, row)} className="timestyle" />
                                                            </TableCell>
                                                            <TableCell>
                                                                <span>
                                                                    <span>
                                                                        <i className="fas fa-trash-alt font-13 pointer" onClick={(e) => confirmDelete(e, row)}></i>
                                                                    </span>
                                                                    <span className='pl-4 ml-2'>
                                                                        <i className="fas fa-plus font-13 pointer" onClick={(e) => addNewBookingHour(e, row)}></i>
                                                                    </span>
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                ))
                                            }
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        }
                        {
                            booking.bookingTypeId == 2 &&
                            <div>
                                <div className='row col-12 p-3'>
                                    <div className='col-6'>
                                        <FxDate
                                            name="startDate"
                                            variant="standard"
                                            size="small"
                                            label="Start Date"
                                            value={booking.startDate}
                                            onValueChange={handleInputChange}
                                        />
                                        {
                                            hasError("startDate", "required") &&
                                            <div className="error">Start Date is required</div>
                                        }
                                    </div>
                                    <div className='col-6'>
                                        <FxTime name="startTime" variant="outlined" label="Start Time"
                                            value={booking.startTime} onValueChange={handleInputChange} />
                                        {
                                            hasError("startTime", "required") &&
                                            <div className="error">Start Time is required</div>
                                        }
                                    </div>
                                </div>
                                <div className='row col-12 p-3'>
                                    <div className='col-6'>
                                        <FxDate
                                            name="endDate"
                                            variant="standard"
                                            size="small"
                                            label="End Date"
                                            value={booking.endDate}
                                            onValueChange={handleInputChange}
                                        />
                                        {
                                            hasError("endDate", "required") &&
                                            <div className="error">End Date is required</div>
                                        }
                                    </div>
                                    <div className='col-6'>
                                        <FxTime name="endTime" variant="outlined" label="End Time"
                                            value={booking.endTime} onValueChange={handleInputChange} />
                                        {
                                            hasError("endTime", "required") &&
                                            <div className="error">End Time is required</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <div className='px-4 py-2 drawer-footer'>
                {
                    currentStep == 1 &&
                    <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={handleNext}><b>Next</b></Button>
                }
                {
                    currentStep == 2 &&
                    <div>
                        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={handleNext}><b>Next</b></Button>
                        <div className='pt-2'>
                            <Button variant="outlined" fullWidth className='btn-style' onClick={handleBack}><b>Back</b></Button>
                        </div>
                    </div>
                }
                {
                    currentStep == 3 &&
                    <div>
                        <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveStallBooking}><b>Save</b></Button>
                        <div className='pt-3'>
                            <Button variant="outlined" fullWidth className='btn-style' onClick={handleBack}><b>Back</b></Button>
                        </div>
                    </div>
                }
            </div>
            <Dialog
                open={photoCaptureOpen}
                onClose={handlePhotoCaptureClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
            </Dialog>
            <Dialog
                open={imageSlideOpen}
                onClose={handleImageSlideClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
            </Dialog>
            <Dialog
                open={confirmOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
            </Dialog>
        </div>
    );
}

export default BookingForm;