import React, { useEffect, useState } from 'react';
import './CommunityList.scss';
import { FxInput, http, FxSelect, general, AlertService } from '../../fx-core';
import dummyImage from "../../../assets/images/dummy.png";
import { Button, Fab, Dialog, Drawer, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import CommunityForm from '../CommunityForm/CommunityForm';
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import ManageUsers from '../ManageUsers/ManageUsers';
import { NoRecords } from '../../NoRecords/NoRecords';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const CommunityList = (props) => {
  const classes = styles();
  let defaultFilter = {
    searchText: "",
    verificationStatusId: -1
  };
  const [filter, setFilter] = useState(defaultFilter);
  const [verificationStatus, setVerificationStatus] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [communityId, setCommunityId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [openCommForm, setOpenCommForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ id: 0 });
  const [action, setAction] = useState("");
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});
  const [manageUsersOpen, setManageUsersOpen] = useState(false);
  const [communityName, setCommunityName] = useState(null);
  const [parent, setParent] = useState("superadmin");

  const initLookup = () => {
    let inputData = {
      lookups: {
        VerificationStatus: { default: true }
      },
      filters: {
      }
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      initLookupCallBack(response);
    })
  }

  const initLookupCallBack = (res) => {
    let result = res.data;
    result.VerificationStatus[0].text = "All";
    setVerificationStatus(result.VerificationStatus);
    getCommunityList({ "searchText": filter.searchText, "verificationStatusId": filter.verificationStatusId });
  };

  const handleInputChange = (item) => {
    handleFilterChange({ [item.name]: item.value });
    if (item.name == 'searchText') {
      getCommunityList({ "searchText": item.value, "verificationStatusId": filter.verificationStatusId });
    }
    else if (item.name == 'verificationStatusId') {
      getCommunityList({ "searchText": filter.searchText, "verificationStatusId": item.value });
    }
  };

  const handleFilterChange = (data) => {
    setFilter(prevState => {
      return { ...prevState, ...data }
    });
  };

  const getCommunityList = (data) => {
    let inputData = {
      name: data.searchText,
      verificationStatusId: data.verificationStatusId
    };
    let apiOptions: any = {
      url: `community/superadmin/list`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      const result = res.data;
      for (let item of result) {
        item.profileImageUrl = general.getImageUrl(item.profileImagePath);
      }
      setCommunityList(result);
    })
  };

  const openCommunityForm = (id) => {
    setCommunityId(id);
    setOpenCommForm(true);
  }

  const handleCommunityFormClose = () => {
    setOpenCommForm(false);
    setCommunityId(0);
    getCommunityList(filter);
  }

  const confirmDisableOrEnable = (item: any, action) => {
    setSelectedItem(item);
    setConfirmOpen(true);
    setAction(action);
    setMessage("Do you want to " + action + " " + item.name + "?");
  }

  const handleDialogClose = () => {
    setConfirmOpen(false);
    setAction("");
    setSelectedItem({ id: 0 });
  };

  const onConfirmCallback = () => {
    setConfirmOpen(false);
    if(action == "disable") {
      handleDisable(selectedItem);
    }
    else {
      handleEnable(selectedItem);
    }
  }

  const handleDisable = (item: any) => {
    let inputData = {
      id: item.id,
      isDisabled: true
    };
    let apiOptions: any = {
      url: `community/updatedisble`,
      data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      setAction("");
      setSelectedItem({ id: 0 });      
      getCommunityList(filter);
    })
  };

  const handleEnable = (item: any) => {
    let inputData = {
      id: item.id,
      isDisabled: false
    };
    let apiOptions: any = {
      url: `community/updatedisble`,
      data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
      setAction("");
      setSelectedItem({ id: 0 });      
      getCommunityList(filter);
    })
  };  

  const openImages = (item: any) => {
    let config = {
      api: `community/${item.id}/images`,
      title: item.name
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
  }

  const manageUsers = (item) => {
    setCommunityId(item.id);
    setCommunityName(item.name);
    setManageUsersOpen(true);
  }

  const handleManageUsersClose = () => {
    setCommunityId(0);
    setCommunityName("");
    setManageUsersOpen(false);
    getCommunityList(filter);
  }

  useEffect(() => {
    initLookup();
  }, []);

  return (
    <div className='communitylist-ui pt-5'>
      <div className='row title-font align-items-center justify-content-between pt-4 px-4'>
        <span className="col-8 pt-3 py-1 pl-4">
          <span>Communities</span>
        </span>
      </div>

      <div className="row col-12 my-3 ml-1 mr-5 justify-content-between">
        <div className="col-3">
          <FxInput name="searchText" variant="outlined" label="Search Community"
            size="small"
            value={filter.searchText}
            onValueChange={handleInputChange}
          />
        </div>
        <div className="col-3">
          <FxSelect name="verificationStatusId" variant="outlined" label="Verification Status"
            options={verificationStatus}
            selectedValue={filter.verificationStatusId}
            valueField="id"
            displayField="text"
            size="small"
            onValueChange={handleInputChange} />
        </div>
        <div className="col-3">
        </div>        
        <div className="col-3 pt-1 text-right">
          {/* <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => openCommunityForm(0)}>
            <i className="fas fa-plus pr-2"></i>
            Add
          </Fab> */}
        </div>
      </div>

      <div className='p-2 mx-3'>
        <div className='mb-4'>
          <TableContainer component={Paper}>
            <Table size="small" className='table-striped'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Tier</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {communityList.length == 0 &&
                  <TableCell colSpan={5}>
                    <NoRecords />
                  </TableCell>
                }
                {communityList.length > 0 && communityList.map((row: any, i) => (
                  <TableRow>
                    <TableCell className='text-center'>
                      <span onClick={() => openImages(row)}>
                        {!row.profileImageUrl && <img src={dummyImage} alt="Stall" width="50" height="50"></img>}
                        {row.profileImageUrl && <img src={row.profileImageUrl} alt="Stall" width="50" height="50"></img>}
                      </span>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {
                        row.tier &&
                        <span>Tier-{row.tier}</span>
                      }
                    </TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.verificationStatus}</TableCell>
                    <TableCell>
                      <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => openCommunityForm(row.id)}>Edit</Button>
                      <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => manageUsers(row)}>Manage Users</Button>
                      {
                        !row.isDisabled &&
                        <span title='Disable'>
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmDisableOrEnable(row, "disable")}>Disable</Button>
                        </span>
                      }
                      {
                        row.isDisabled &&
                        <span title='Enable'>
                          <Button variant='outlined' size='small' className='button-style mr-2' onClick={() => confirmDisableOrEnable(row, "enable")}>Enable</Button>
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={openCommForm} onClose={handleCommunityFormClose}>
        <CommunityForm communityId={communityId} handleCommunityFormClose={handleCommunityFormClose} />
      </Drawer>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ConfirmDialog message={message} action={action} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
      </Dialog>
      <Dialog
        open={manageUsersOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleManageUsersClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ManageUsers communityId={communityId} parent={parent} communityName={communityName} handleManageUsersClose={handleManageUsersClose} />
      </Dialog>
    </div>
  );
}
export default CommunityList;