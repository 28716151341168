import React, { useState } from 'react';
import './CommentDialog.scss';
import { FxInput, AlertService } from '../fx-core';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const CommentDialog = (props) => {

    const [comment, setComment] = useState("");

    const handleInputChange = (item) => {
        setComment(item.value);
    };

    const handleClose = () => {
        props.closeCommentDialog()
    };

    const confirmOk = () => {
        if (comment.trim().length <= 0) {
            AlertService.showErrorMsg("Please specify comments");
            return;
        }
        props.handleComment(comment);
    };

    return (
        <div className="modalalign">
            <div className="col-12 title-font">
                <span className="col-10">
                    <span>Confirm</span>
                </span>
                <span className="col-1 float-right pointer mr-3" onClick={handleClose}>
                    <CloseIcon fontSize="small" color="secondary" />
                </span>
            </div>
            <div className='m-2'>
                {props.message}
            </div>
            <div className="p-2">
                <FxInput name="comment" variant="outlined" label="Comments" size="small"
                    value={comment}
                    onValueChange={handleInputChange}
                />
            </div>
            <div className="text-right p-2">
                <span>
                    <Button variant="contained" size="small" color="primary" onClick={handleClose}>No</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={confirmOk}>Yes</Button>
                </span>
            </div>
        </div>
    )
};