import React, { useEffect, useState } from 'react';
import { FxInput, FxTextarea, http, LocationSearchInput, AlertService, localstorage, formValidator, general, FxSelect } from '../../fx-core';
import './StallForm.scss';
import * as _ from 'lodash';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import { Button, Checkbox, Link, Dialog } from '@material-ui/core';

const StallForm = (props) => {
  const defaultItem = {
    imagePath: "",
    imageUrl: "",
    name: "",
    description: "",
    address: "",
    isDeliveryEnabled: false,
    isPickupEnabled: false,
    stallOpenStatusId: -1,
    isActive: true,
    newOrderNotification: false,
    locationId: 0,
    id: props.stallId ? props.stallId : 0,
    communityId: 0,
    //userId: parseInt(localstorage.getItem("userId")),
    //ownerId: parseInt(localstorage.getItem("userId")),
    serviceId: props.serviceId ? props.serviceId : 0,
    stallStatusId: 1, //Pending,
    website: "",
    isFeatured: false,
    isVerified: false
  };
  const defaultContext = {
    serviceName: "",
    showPayAndDeliveryOptions: true,
    isStripeConfigured: false
  };

  const [item, setItem] = useState(defaultItem);
  const [currentContext, setCurrentContext] = useState(defaultContext);
  const [stallOpenStatus, setStallOpenStatus] = useState([]);
  const [locations, setLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});

  const initLookup = () => {
    let inputData = {
      lookups: {
        StallOpenStatus: { default: true },
        Tier4Locations: { default: true }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      setStallOpenStatus(response.data.StallOpenStatus);
      setLocations(response.data.Tier4Locations);
      handleItemChange({ "stallOpenStatusId": -1 });
      initMethods();
    })
  }

  const initMethods = () => {
    if (props.stallId > 0) {//Edit Stall form
      if (props.showPayAndDelivery) {
        handleCurrentContextItemChange({ "showPayAndDeliveryOptions": true });
      }
      getStallById();
    }
    else if (props.serviceId > 0) {//Create Stall form
      getServiceById();
    }
  }

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleCurrentContextItemChange = (data) => {
    setCurrentContext(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const validationMap_Item = {
    name: ["required", "maxlen"],
    address: ["required"],
    stallOpenStatusId: ["select"]
  };

  const getValidations = () => {
    let validationMap;
    validationMap = validationMap_Item;
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    for (var key in validationMap) {
      if (key == "name") {
        let result = { name: key, value: item[key], validations: validationMap[key], param: 25 }
        items.push(result);
      }
      else {
        let result = { name: key, value: item[key], validations: validationMap[key] }
        items.push(result);
      }
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const getServiceById = () => {
    let inputData = {
      id: item.serviceId
    };
    let apiOptions: any = {
      url: `portal/service/edit/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      handleCurrentContextItemChange({ "serviceName": response.data.serviceName });
      if (result.serviceCode == "Ready To Eat" || result.serviceCode == "Food Bag" || result.serviceCode == "Fresh Produce" ||
        result.serviceCode == 'Garden' || result.serviceCode == 'Pet' || result.serviceCode == 'Professional' ||
        result.serviceCode == 'CreativeCraft') {
        handleCurrentContextItemChange({ "showPayAndDeliveryOptions": true });
      }
      else {
        handleCurrentContextItemChange({ "showPayAndDeliveryOptions": false });
      }
    });
  }

  const getStallById = () => {
    let apiOptions: any = {
      url: `stall/${item.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.imageUrl = general.getImageUrl(result.imagePath);
      setItem(result);
      let itemObj = {
        isDeliveryEnabled: result.isDeliveryEnabled,
        isPickupEnabled: result.isPickupEnabled,
        isActive: result.isActive,
        newOrderNotification: result.newOrderNotification
      };
      handleItemChange(itemObj);
    })
  }

  const addressSelected = (data) => {
    let inputData = { address: data.address, lat: data.lat, lng: data.lng };
    console.log(data);
    handleItemChange(inputData);
  };

  const saveStall = () => {
    const isValid = bulkValidate();
    if (isValid) {
      // if (currentContext.showPayAndDeliveryOptions && !item.isDeliveryEnabled && !item.isPickupEnabled) {
      //   AlertService.showErrorMsg("Please select any of the delivery options");
      //   return;
      // }
      let inputData = {
        Stall: item,
        PaymentOptions: []
      }
      let apiOptions: any = {
        url: `stall`,
        data: inputData
      };
      http.post(apiOptions).then(res => {
        saveStallCallBack(res.data);
      })
    }
  };

  const saveStallCallBack = (res) => {
    if (res && res.message) {
      AlertService.showErrorMsg(res.message);
      return;
    }
    AlertService.showSuccessMsg();
    if (item.id > 0) {
      props.handleStallFormClose();
    }
    else {
      props.onFormCallBack(res);
    }
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: item.id,
      cameraConfig: { url: `stall/savecameraimage` },
      fileUploadConfig: { url: `stall/savefileimage` }
    };
    if (item.communityId > 0) {
      photoCaptureConfig = {
        id: item.id,
        cameraConfig: { url: `stall/${item.communityId}/savecameraimage` },
        fileUploadConfig: { url: `stall/${item.communityId}/savefileimage` }
      };
    }
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (item.id > 0) {
      getStallById();
    }
    else {
      let filePath = res.filePath;
      let imagePath = general.getImageUrl(filePath);
      handleItemChange({ "imagePath": filePath });
      handleItemChange({ "imageUrl": imagePath });
    }
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const handleClose = () => {
    props.handleStallFormClose();
  }

  useEffect(() => {
    initLookup();
  }, []);


  return (
    <div className='stallform-ui'>
      <div className='row title-font py-1 px-2 drawer-header'>
        <span className="col-11">
          Stall Form
        </span>
        <span className="col-1 text-center">
          <i className="fas fa-times-circle font-16 pointer" onClick={handleClose}></i>
        </span>
      </div>

      <div className='drawer-content'>
        <div className='row p-3'>
          <div className="col-2 text-center">
            <div>
              <a className="pointer">
                {!item.imageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                {item.imageUrl && <img src={item.imageUrl} alt="prod" width={100} height={100}></img>}
              </a>
              <div className="text-center camera-align" onClick={openPhotoCapture}>
                <CameraAltIcon className='pointer' />
              </div>
            </div>
          </div>
          <div className='col-2'></div>
          <div className='col-8'>
            {currentContext.serviceName &&
              <div>Service Name:
                <span className='pl-1'><b>{currentContext.serviceName}</b></span>
              </div>
            }
          </div>
        </div>
        <div className='col-12 p-3'>
          <FxInput name="name" variant="outlined" fullWidth size="small" label="Name"
            value={item.name} onValueChange={handleInputChange} />
          {hasError("name", "required") &&
            <div className="error">Name is required</div>
          }
           {
              hasError("name", "maxlen") &&
              <div className="error">Name should be less than 25 characters</div>
            }
        </div>

        <div className='col-12 p-3'>
          <FxTextarea name="description" variant="outlined" size="small" fullWidth maxRows={4} label="Description"
            value={item.description} onValueChange={handleInputChange} />
        </div>

        {/* <div className='p-3'>
          {(item.id > 0 && item.address) &&
            <LocationSearchInput address={item.address} name="address"
              fullWidth locationSelected={addressSelected} placeholder="Address" />}

          {(item.id == 0 || !item.address) && <LocationSearchInput address={item.address} name="address"
            fullWidth locationSelected={addressSelected} placeholder="Address" />}
          {hasError("address", "required") &&
            <div className="error">Address is required</div>
          }
        </div> */}

        <div className='p-3'>
          <FxTextarea name="address" variant="outlined" fullWidth size="small" label="Address"
            value={item.address} onValueChange={handleInputChange} maxRows={4} />
          {hasError("address", "required") &&
            <div className="error">Address is required</div>
          }
        </div>

        <div className='col-12 p-3'>
          <FxSelect name="stallOpenStatusId" variant="outlined" label="Stall Open Status"
            options={stallOpenStatus}
            selectedValue={item.stallOpenStatusId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
          {
            hasError("stallOpenStatusId", "select") &&
            <div className="error">Stall Open Status is required</div>
          }
        </div>

        <div className='col-12 p-3'>
          <FxSelect name="locationId" variant="outlined" label="Location"
            options={locations}
            selectedValue={item.locationId}
            valueField="id"
            displayField="text"
            size="small"
            fullWidth
            onValueChange={handleInputChange} />
        </div>

        <div className='col-12 p-3'>
          <FxInput name="website" variant="outlined" fullWidth size="small" label="Website"
            value={item.website} onValueChange={handleInputChange} />
        </div>

        <div className='row px-3'>
          <div className='col-6'>
            <Checkbox name="isActive" color="primary" checked={item.isActive} value={item.isActive} onChange={handleCheckbox} />
            Active
          </div>
          <div className='col-6'>
            <Checkbox name="newOrderNotification" color="primary" checked={item.newOrderNotification} value={item.newOrderNotification} onChange={handleCheckbox} />
            New Order Notification
          </div>
        </div>

        <div className='row px-3'>
          <div className='col-6'>
            <Checkbox name="isFeatured" color="primary" checked={item.isFeatured} value={item.isFeatured} onChange={handleCheckbox} />
            Featured
          </div>
          <div className='col-6'>
            <Checkbox name="isVerified" color="primary" checked={item.isVerified} value={item.isVerified} onChange={handleCheckbox} />
            Verified
          </div>
        </div>

        {currentContext.showPayAndDeliveryOptions &&
          <div className='p-3 pb-5'>
            <div className='font-14 bold-text pb-2'>Delivery Options</div>
            <div className='row'>
              <div className='col-6'>
                <Checkbox name="isDeliveryEnabled" color="primary" checked={item.isDeliveryEnabled} value={item.isDeliveryEnabled} onChange={handleCheckbox} />
                Delivery
              </div>
              <div className='col-6'>
                <Checkbox name="isPickupEnabled" color="primary" checked={item.isPickupEnabled} value={item.isPickupEnabled} onChange={handleCheckbox} />
                Pickup
              </div>
            </div>
          </div>
        }
      </div>
      <div className='px-4 py-2 drawer-footer'>
        {
          !props.isView &&
          <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveStall}><b>Save</b></Button>
        }
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
    </div>
  );
}
export default StallForm;
