import React, { useState, useEffect } from 'react';
import './SaleServiceRequestDetail.scss';
import { http, general, useNavigationService, FxDateDisplay, FxDateTimeDisplay, FxCurrency, AlertService, formatService } from '../../../fx-core';
import dummyImage from "../../../../assets/images/dummy.png";
import { Button, Dialog } from '@material-ui/core';
import _ from "lodash";
import Carousel from 'react-material-ui-carousel'
import DateIcon from '@material-ui/icons/DateRange';
import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog';
import ServiceRequestConfirmDialog from '../../ServiceRequestConfirmDialog/ServiceRequestConfirmDialog';

const SaleServiceRequestDetail = (props: any) => {

    let defaultItem = {
        serviceRequestType: "",
        stallId: 0,
        stallName: "",
        requestedByName: null,
        serviceRequestIdentifier: "",
        serviceRequestStatusId: 0
    };
    let defaultSale = {
        stallAddress: "",
        stallId: 0,
        stallName: "",
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        title: null,
        description: null
    };
    let defaultSplEventItem = {
        communityName: "",
        serviceName: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: ""
    };
    const { navigate } = useNavigationService();
    const [serviceRequest, setServiceRequest] = useState(defaultItem);
    const [sale, setSale] = useState(defaultSale);
    const [saleItemDetails, setSaleItemDetails] = useState([]);
    const [images, setImages] = useState([]);
    const [showSpecialEvent, setShowSpecialEvent] = useState(false);
    const [specialEventItem, setSpecialEventItem] = useState(defaultSplEventItem);
    const [questions, setQuestions] = useState([]);
    const [queOptions, setQueOptions] = useState([]);
    const [answerMap, setAnswerMap] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [action, setAction] = useState("");
    const [splEvtConfirmOpen, setSplEvtConfirmOpen] = useState(false);

    const getSaleServiceReqSummary = () => {
        let apiOptions: any = {
            url: `servicerequest/${props.serviceRequestId}/sale`
        };
        http.get(apiOptions).then(response => {
            let result = response.data;
            getSummaryCallBack(result);
        });
    }

    const getSummaryCallBack = (res) => {
        let serviceRequest = res.serviceRequest;
        setServiceRequest(res.serviceRequest);
        if (res.sale) {
            setSale(res.sale);
            getSaleImages(res.sale.id);
        }
        let itemDetails: any = _.map((_.groupBy(res.saleItemDetails, function (i) {
            return i.saleItemGroupName
        })), function (items, key) { return { key: key, saleItems: items }; });
        setSaleItemDetails(itemDetails);
        for (let item of itemDetails) {
            for (let saleItem of item.saleItems) {
                saleItem.imageUrl = general.getImageUrl(saleItem.imagePath);
            }
        }
        if (serviceRequest.communityTypeId == 2) {
            setShowSpecialEvent(true);
            getStallCommunityMapByServiceReqId();
        }
    }

    const getSaleImages = (saleId) => {
        let inputData = {
            id: saleId
        };
        let apiOptions: any = {
            url: `sale/${inputData.id}/images`
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
            }
            setImages(result);
        });
    }

    const getStallCommunityMapByServiceReqId = () => {
        let apiOptions: any = {
            url: `stallcommunitymap/servicerequest/${props.serviceRequestId}`
        };
        http.get(apiOptions).then(res => {
            getStallCommunityMapByServiceReqIdCallBack(res);
        });
    };

    const getStallCommunityMapByServiceReqIdCallBack = (res) => {
        let result = res.data;
        result.startTime = result.startTime != null ? formatService.getConvertedTimeForDisplay(result.startTime) : null;
        result.endTime = result.endTime != null ? formatService.getConvertedTimeForDisplay(result.endTime) : null;
        setSpecialEventItem(result);
        getCommunityQuestionOptions(result.communityId);
    };

    const getCommunityQuestionOptions = (communityId) => {
        let apiOptions: any = {
            url: `question/community/${communityId}`
        };
        http.get(apiOptions).then(res => {
            getCommunityQuestionOptionsCallBack(res);
        });
    }

    const getCommunityQuestionOptionsCallBack = (res) => {
        let result: any = res.data;
        let ansMap: any = {};
        for (let question of result.questions) {
            ansMap[question.id.toString()] = "";
            question.identifier = question.id.toString();
        }
        setAnswerMap(ansMap);
        setQuestions(result.questions);
        setQueOptions(result.questionOptions);
        getServiceReqQueAns(props.serviceRequestId);
    }

    const getServiceReqQueAns = (id) => {
        let inputData = {
            id: id
        };
        let apiOptions: any = {
            url: `servicerequest/question/answers`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getServiceReqQueAnsCallBack(res);
        });
    };

    const getServiceReqQueAnsCallBack = (res) => {
        let result = res.data;
        let ansMap: any = {};
        for (let r of result) {
            ansMap[r.questionId.toString()] = r.answerText;
        }
        setAnswerMap(ansMap);
    };

    const confirmApprove = () => {
        let msg = "Do you want to approve #" + serviceRequest.serviceRequestIdentifier + "?";
        setMessage(msg);
        if (showSpecialEvent) {
            setSplEvtConfirmOpen(true);
        }
        else {
            setConfirmOpen(true);
        }
        setAction("approve");
    }

    const confirmReject = () => {
        let msg = "Do you want to reject #" + serviceRequest.serviceRequestIdentifier + "?";
        setMessage(msg);
        setConfirmOpen(true);
        setAction("reject");
    }

    const handleDialogClose = () => {
        setConfirmOpen(false);
        setMessage("");
        setAction("")
    };

    const onConfirmCallback = (ctx, comments) => {
        let item: any = serviceRequest;
        item.comments = comments;
        if (action == "approve") {
            item.serviceRequestStatusId = 2;
        }
        if (action == "reject") {
            item.serviceRequestStatusId = 3;
        }
        updateServiceReqStatus(item);
        handleDialogClose();
    };

    const handleSplEvtDialogClose = () => {
        setSplEvtConfirmOpen(false);
        setMessage("");
        setAction("")
    };

    const onSplEvtConfirmCallback = (itemObj) => {
        let item: any = serviceRequest;
        item.amount = itemObj.amount;
        item.comments = itemObj.comments;
        item.serviceRequestStatusId = 2;
        updateServiceReqStatus(item);
        handleDialogClose();
    };

    const updateServiceReqStatus = (item) => {
        let apiOptions = {
            url: 'servicerequest/updatestatus',
            data: item
        };
        http.put(apiOptions).then(res => {
            updateServiceReqStatusCallBack(res);
        });
    }

    const updateServiceReqStatusCallBack = (res) => {
        AlertService.showMsg({ message: "Service request status updated successfully" });
        handleClose();
    }

    const handleClose = () => {
        props.handleClose();
    }

    useEffect(() => {
        getSaleServiceReqSummary();
    }, []);

    return (
        <div className="salereq-summary-ui">
            <div>
                {serviceRequest.stallName &&
                    <div className='mb-2 text-center font-18 color-primary stallname'>
                        <span>{serviceRequest.stallName}</span>
                    </div>
                }
                {
                    sale.title &&
                    <div>
                        <div className='col-12'>
                            {(images && images.length > 0) &&
                                <Carousel>
                                    {
                                        images && images.map((itemObj: any) => (
                                            <div className="text-center">
                                                <img src={itemObj.imageUrl} alt="image" width={350} height={350}></img>
                                            </div>
                                        ))}
                                </Carousel>
                            }
                            {images.length == 0 &&
                                <div className="text-center">
                                    <img src={dummyImage} alt="dummy" width={350} height={350}></img>
                                </div>
                            }
                        </div>

                        <div className='py-2'>
                            <span className='pl-4'>
                                <i className="fas fa-map-marker-alt pr-1"></i>
                                <span>{sale.stallAddress}</span>
                            </span>
                        </div>
                        <div className='py-2 pl-2'>
                            <span className='col-3'>
                                <i className="fas fa-user pr-1"></i>
                                {serviceRequest.requestedByName}</span>
                            <span className="col-6">
                                <DateIcon fontSize="inherit" />
                                <FxDateDisplay value={sale.startDate} />
                                {sale.startTime &&
                                    <span className="pl-1">
                                        <i className="far fa-clock color-aaa px-1"></i>
                                        <FxDateTimeDisplay value={sale.startTime} displayType="time" />
                                    </span>
                                }
                            </span>
                            <span className="col-6">
                                <DateIcon fontSize="inherit" />
                                <FxDateDisplay value={sale.endDate} />
                                {sale.endTime &&
                                    <span className="pl-1">
                                        <i className="far fa-clock color-aaa px-1"></i>
                                        <FxDateTimeDisplay value={sale.endTime} displayType="time" />
                                    </span>
                                }
                            </span>
                        </div>
                        <div className="col-12 ml-2 pt-3">
                            <b>{sale.title}</b>
                        </div>
                        <div className="col-12 ml-2">
                            {sale.description &&
                                <span>{sale.description}</span>
                            }
                        </div>

                        {saleItemDetails && saleItemDetails.map((itemObj: any) => (
                            <div className="col-12 pl-2 pt-5">
                                <div className='pl-3'> {itemObj.key}</div>
                                {itemObj.saleItems && itemObj.saleItems.map((item) => (
                                    <div className='row listitem'>
                                        <div className="col-3">
                                            {!item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                                            {item.imageUrl && <img src={item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
                                        </div>
                                        <div className="row col-7">
                                            <span className='col-10'>{item.itemName}</span>
                                            <span className="col-2 float-right">
                                                <FxCurrency value={item.price} />
                                            </span>
                                            <span className='col-12'>{item.description}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                }
            </div>
            {
                showSpecialEvent &&
                <div className="mt-2">
                    <div className='row pt-3 pl-5'>
                        <span className='col-4 text-left label'>
                            Community
                        </span>
                        <span className='col-8 text-left'>
                            {specialEventItem.communityName}
                        </span>
                    </div>
                    <div className='row pt-3 pl-5'>
                        <span className='col-4 text-left label'>
                            Service
                        </span>
                        <span className='col-8 text-left'>
                            {specialEventItem.serviceName}
                        </span>
                    </div>
                    <div className='row pt-3 pl-5'>
                        <span className='col-4 text-left label'>
                            Start Date Time
                        </span>
                        <span className='col-8 text-left'>
                            <FxDateDisplay value={specialEventItem.startDate} />
                            <span className='pl-2'>
                                <i className="far fa-clock color-aaa px-1"></i>
                                {specialEventItem.startTime}
                            </span>
                        </span>
                    </div>
                    <div className='row pt-3 pl-5'>
                        <span className='col-4 text-left label'>
                            End Date Time
                        </span>
                        <span className='col-8 text-left'>
                            <FxDateDisplay value={specialEventItem.endDate} />
                            <span className='pl-2'>
                                <i className="far fa-clock color-aaa px-1"></i>
                                {specialEventItem.endTime}
                            </span>
                        </span>
                    </div>
                    <div className='px-5 pt-3 row'>
                        <span className='divider'></span>
                    </div>
                    <div className='pl-5 pt-3 row'>
                        <div className='text-left col-4 label'>
                            Questions
                        </div>
                        <div className='col-8 text-left'>
                            {
                                questions.length > 0 && questions.map((que: any, i) => (
                                    <div key={i}>
                                        {
                                            que.questionTypeId == 1 &&
                                            <div className='text-left pb-3'>
                                                <div className='question'>
                                                    {que.description}
                                                </div>
                                                <div className='pl-4 font-weight-bold font-15'>
                                                    {answerMap[que.identifier]}
                                                </div>
                                            </div>
                                        }
                                        {
                                            que.questionTypeId == 2 &&
                                            <div className='text-left pb-3'>
                                                <div className='question'>
                                                    {que.description}
                                                </div>
                                                <div className='pl-4 font-weight-bold font-15'>
                                                    <FxDateDisplay value={answerMap[que.identifier]} />
                                                </div>
                                            </div>
                                        }
                                        {
                                            que.questionTypeId == 3 &&
                                            <div className='text-left pb-3'>
                                                <div className='question'>
                                                    {que.description}
                                                </div>
                                                <div className='pl-4 font-weight-bold font-15'>
                                                    {answerMap[que.identifier]}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
            {
                serviceRequest.serviceRequestStatusId == 1 &&
                <div className='px-4 py-2 drawer-footer text-right'>
                    <Button variant="outlined" size='small' className='btn-style' onClick={confirmReject}>Reject</Button>
                    <Button variant="outlined" size='small' className='btn-style ml-3' onClick={confirmApprove}>Approve</Button>
                </div>
            }

            <Dialog
                open={confirmOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} action={"verify"} handleDialogClose={handleDialogClose} onConfirmCallback={onConfirmCallback} />
            </Dialog>
            <Dialog
                open={splEvtConfirmOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleSplEvtDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ServiceRequestConfirmDialog message={message} handleDialogClose={handleSplEvtDialogClose} onConfirmCallback={onSplEvtConfirmCallback} />
            </Dialog>
        </div>

    );
}
export default SaleServiceRequestDetail;