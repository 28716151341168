import React from "react";
import "./AccountDeletion.scss";
import DeleteImg from "../../assets/images/delete-icon.png";

const AccountDeletion = (props: any) => {

  const openLink = () => {
    window.open('https://app.localites.in/', '_blank');
  }
  return (
    <div className="account-deletion">
      <div style={{ paddingTop: "124px" }}>
        <div style={{ textAlign: "center", paddingBottom: "16px" }}>
          <img src={DeleteImg} alt="delete" width="180" height="180" style={{ objectFit: "cover" }}></img>
        </div>
        <div style={{ textAlign: "center", fontSize: "22px", fontWeight: 600, letterSpacing: "0.52px", paddingBottom: "28px" }}>Steps to delete account</div>
        <div>
          <ul style={{ lineHeight: 1.5 }}>
            <li>
              <div style={{ letterSpacing: "0.28px", paddingBottom: "16px", }}>
                Log in to your Localites &nbsp;<span className="span-link" onClick={openLink}>(https://app.localites.in/)</span>
                &nbsp;account using your username and password.
              </div>
            </li>
            <li>
              <div style={{ letterSpacing: "0.28px", paddingBottom: "16px", }}>
                Navigate to your  profile section.
              </div>
            </li>
            <li>
              <div style={{ letterSpacing: "0.28px", paddingBottom: "16px", }}>
                Look for an option to delete your account. This action labeled as &quot;Delete Account&quot;.
              </div>
            </li>
            <li>
              <div style={{ letterSpacing: "0.28px", paddingBottom: "16px", }}>
                Follow the provided instructions to confirm the deletion of your account.
              </div>
            </li>
            <li>
              <div style={{ letterSpacing: "0.28px", paddingBottom: "16px", }}>
                Once you&apos;ve confirmed, your account should be deleted, and you&apos;ll typically receive confirmation of the deletion.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AccountDeletion; 