import React, { useEffect, useState } from 'react';
import './MyOrderDetail.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { TableFooter } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FxCurrency, http, general, formatService, AlertService } from '../../fx-core';
import _ from "lodash";
import Link from '@material-ui/core/Link';
import useRazorpay from '../../utils/useRazorPay';

const MyOrderDetail = (props: any) => {
  const Razorpay = useRazorpay();
  
  const initialList = [];
  let defaultOrder = {
    orderIdentifier: null,
    placedDate: null,
    orderTypeId: 0,
    stallName: null,
    deliveryCharge: 0,
    orderTotal: 0,
    stallAddress: null
  }
  let defaultAddress = {
    fullAddress: null,
    fullName: null
  }

  let defaultSlot = {
    description: null,
    startTime: null,
    endTime: null
  }
  let defaultContex = {
    isShowCoupon: false,
    couponApplied: 0,
    balanceToPay: 0
  };

  const [order, setOrder] = useState(defaultOrder);
  const [orderLines, setOrderLines] = useState(initialList);
  const [address, setAddress] = useState(defaultAddress);
  const [orderPayments, setOrderPayments] = useState(initialList);
  const [deliverySlot, setDeliverySlot] = useState(defaultSlot);
  const [currentContext, setCurrentContext] = useState(defaultContex);

  const getOrderDetail = () => {
    let inputData = {
      id: props.orderId
    };

    let apiOptions: any = {
      url: `order/detail/${inputData.id}`
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      let deliverySlotData: any = {};
      let orderLinesData: any = [];
      for (let item of result) {
        setOrder(item.order);
        setAddress(item.address);
        setOrderPayments(item.orderPayments);
        deliverySlotData = item.deliverySlot;
        orderLinesData = item.orderLines;
        canShowCouponInfo(item.orderPayments);
      }
      setDeliverySlot(deliverySlotData);

      for (let item of orderLinesData) {
        item.imageUrl = general.getImageUrl(item.imagePath);
        item.orderLineModifiers = _.map((_.groupBy(item.orderLineModifiers, function (i) {
          return i.groupName
        })), function (items, key) { return { key: key, displayModifiers: items }; });
      }
      setOrderLines(orderLinesData);
    })
  }

  const handleClose = () => {
    props.handleClose();
  }

  const canShowCouponInfo = (orderPaymentList) => {
    let lookupItem = _.find(orderPaymentList, { paymentMode: 'coupon' });
    if (lookupItem) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ isShowCoupon: true } }
      });
    }
    computeCouponInfo(orderPaymentList);
  }

  const computeCouponInfo = (orderPaymentList) => {
    if (orderPaymentList && orderPaymentList.length > 1) {
      for (let item of orderPaymentList) {
        if (item.paymentMode == 'coupon') {
          setCurrentContext(prevState => {
            return { ...prevState, ...{ couponApplied: item.amount } }
          });
        }
        else if (item.paymentMode != 'coupon') {
          setCurrentContext(prevState => {
            return { ...prevState, ...{ balanceToPay: item.amount } }
          });
        }
      }
    }
    else if (orderPaymentList && orderPaymentList.length == 1) {
      for (let item of orderPaymentList) {
        if (item.paymentMode == 'coupon') {
          setCurrentContext(prevState => {
            return { ...prevState, ...{ couponApplied: item.amount } }
          });
        }
        setCurrentContext(prevState => {
          return { ...prevState, ...{ balanceToPay: 0 } }
        });
      }
    }
  }

  const initiatePayment = () => {
    let inputData = {
      id: props.orderId
    };
    let apiOptions: any = {
      url: `order/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      openPaymentLink(res);
    });
  }

  const openPaymentLink = (resp: any) => {
    var item = resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.name;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        getOrderDetail();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `order/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  useEffect(() => {
    getOrderDetail();
  }, []);

  const body = (
    <div className='my-order-detail'>
      <div className="row col-12 title-font py-1 mx-auto">
        <div className="col-11 pl-0">
          <span>Order Detail</span>
        </div>
        <div className="col-1 text-right pointer px-0" onClick={handleClose}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>


      {/* Desktop View */}
      <div className='deskview'>
        <div className='row mr-3 my-3 ml-0'>
          <div className='col-7'>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >Items</TableCell>
                    <TableCell width="4%" >Qty</TableCell>
                    <TableCell width="1%" ></TableCell>
                    <TableCell width="23%" align="right" >Price</TableCell>
                    <TableCell width="15%" align="right" >Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderLines.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell className="font-12">
                        {row.itemName}
                        <div className='font-11 extras-txt'>
                          {row.orderLineModifiers && row.orderLineModifiers.map((custom, i) => (
                            <div>
                              <span>{custom.key}-</span>
                              {custom.displayModifiers && custom.displayModifiers.map((detail, idx) => (
                                <span>{detail.description}
                                  {idx != (custom.displayModifiers.length - 1) &&
                                    <span>, </span>
                                  }
                                  {i != (row.orderLineModifiers.length - 1) &&
                                    <span>, </span>
                                  }
                                </span>
                              ))}
                            </div>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell className="table-font">
                        <span>{row.unit}</span>
                      </TableCell>
                      <TableCell>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          {row.itemQuantity} * <FxCurrency value={row.price} />
                          {row.lineModifierAmount > 0 &&
                            <span>+ <FxCurrency value={row.lineModifierAmount} /></span>
                          }
                        </span>
                      </TableCell>
                      <TableCell align="right" style={{ width: 220 }}>
                        <span className="table-font">
                          <FxCurrency value={row.lineTotalPrice} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>

                  {order.deliveryCharge > 0 &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <span className="table-font">Delivery Charge</span>
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        <FxCurrency value={order.deliveryCharge} />
                      </span></TableCell>
                    </TableRow>
                  }

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <span className="table-font">Total</span>
                    </TableCell>
                    <TableCell align="right"><span className="table-font">
                      <FxCurrency value={order.orderTotal} />
                    </span></TableCell>
                  </TableRow>
                  {
                    currentContext.isShowCoupon &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Coupon Applied
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        <FxCurrency value={currentContext.couponApplied} />
                      </span></TableCell>
                    </TableRow>
                  }

                  {
                    currentContext.isShowCoupon &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Balance to Pay
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        {currentContext.balanceToPay > 0 &&
                          <span><FxCurrency value={currentContext.balanceToPay} /></span>
                        }
                        {!currentContext.balanceToPay &&
                          <span><FxCurrency value={currentContext.balanceToPay} /><sup>00</sup>
                          </span>
                        }
                      </span></TableCell>
                    </TableRow>
                  }

                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <div className='col-5 summary-detail font-14'>
            <div className='row'>
              <div className='col-4'>Order #</div>
              <div className='col-8'>: {order.orderIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Ordered Date</div>
              <div className='col-8'>: {general.getFormattedDate(order.placedDate)}</div>
            </div>
            {orderPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Order total</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Coupon Value</span>
                    }
                  </div>
                  <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>Payment Mode</div>
                  <div className='col-8'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'razorpay' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'razorpay' && item.paymentMode != 'cod' &&
                        <span className="pr-1 payment-style">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Payment Status</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Discount Status</span>
                    }
                  </div>
                  <div className='col-8'>
                    : <span>{item.paymentStatus}
                      {item.paymentMode == 'razorpay' && (item.paymentStatus == 'Pending' || item == 'Initiated') &&
                        <span className='pl-1'>
                          <Link className="btn btn-address font-14" onClick={initiatePayment} title="pay">Pay</Link>
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {deliverySlot &&
              <div className='row pt-1'>
                <div className='col-4'>
                  {order.orderTypeId === 1 &&
                    <span>Delivery</span>
                  }
                  {order.orderTypeId === 2 &&
                    <span>Pickup</span>
                  }
                </div>
                <div className='col-8'>: {general.getFormattedTime(deliverySlot.startTime)} - {general.getFormattedTime(deliverySlot.endTime)}</div>
              </div>
            }
            {order.orderTypeId === 1 &&
              <div className='row pt-1'>
                <div className='col-4'>Delivery Address</div>
                <div className='col-8'>: {address.fullAddress}</div>
              </div>
            }
            {order.orderTypeId === 2 &&
              <div className='row pt-1'>
                <div className='col-4'>Pickup Address</div>
                <div className='col-8'>: {order.stallAddress}</div>
              </div>
            }
          </div>
        </div>
      </div>


      {/* Tablet View */}
      <div className='tabview'>
        <div className='row mr-3 my-3 ml-0'>
          <div className='col-7'>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >Items</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell align="right" >Price</TableCell>
                    <TableCell align="right" >Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderLines.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell className="table-font">
                        {row.itemName}
                        <div className='font-11 extras-txt'>
                          {row.orderLineModifiers && row.orderLineModifiers.map((custom, i) => (
                            <div>
                              <span>{custom.key}-</span>
                              {custom.displayModifiers && custom.displayModifiers.map((detail, idx) => (
                                <span>{detail.description}
                                  {idx != (custom.displayModifiers.length - 1) &&
                                    <span>, </span>
                                  }
                                  {i != (row.orderLineModifiers.length - 1) &&
                                    <span>, </span>
                                  }
                                </span>
                              ))}
                            </div>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell className="table-font">
                        <span>{row.unit}</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          {row.itemQuantity} * <FxCurrency value={row.price} />
                          {row.lineModifierAmount > 0 &&
                            <span>+ <FxCurrency value={row.lineModifierAmount} /></span>
                          }
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={row.lineTotalPrice} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>

                  {order.deliveryCharge > 0 &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <span className="table-font">Delivery Charge</span>
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        <FxCurrency value={order.deliveryCharge} />
                      </span></TableCell>
                    </TableRow>
                  }

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <span className="table-font">Total</span>
                    </TableCell>
                    <TableCell align="right"><span className="table-font">
                      <FxCurrency value={order.orderTotal} />
                    </span></TableCell>
                  </TableRow>

                  {
                    currentContext.isShowCoupon &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Coupon Applied
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        <FxCurrency value={currentContext.couponApplied} />
                      </span></TableCell>
                    </TableRow>
                  }

                  {
                    currentContext.isShowCoupon &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Balance to Pay
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        {currentContext.balanceToPay > 0 &&
                          <span><FxCurrency value={currentContext.balanceToPay} /></span>
                        }
                        {!currentContext.balanceToPay &&
                          <span><FxCurrency value={currentContext.balanceToPay} /><sup>00</sup>
                          </span>
                        }
                      </span></TableCell>
                    </TableRow>
                  }


                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <div className='col-5 summary-detail font-14'>
            <div className='row'>
              <div className='col-4'>Order #</div>
              <div className='col-8'>: {order.orderIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-4'>Ordered Date</div>
              <div className='col-8'>: {general.getFormattedDate(order.placedDate)}</div>
            </div>
            {orderPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Order total</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Coupon Value</span>
                    }
                  </div>
                  <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>Payment Mode</div>
                  <div className='col-8'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'razorpay' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'razorpay' && item.paymentMode != 'cod' &&
                        <span className="pr-1 payment-style">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-4'>
                    {item.paymentMode != 'coupon' &&
                      <span>Payment Status</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Discount Status</span>
                    }
                  </div>
                  <div className='col-8'>
                    : <span>{item.paymentStatus}
                      {item.paymentMode == 'razorpay' && (item.paymentStatus == 'Pending' || item == 'Initiated') &&
                        <span className='pl-1'>
                          <Link className="btn btn-address font-14" onClick={initiatePayment} title="pay">Pay</Link>
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {deliverySlot &&
              <div className='row pt-1'>
                <div className='col-4'>
                  {order.orderTypeId === 1 &&
                    <span>Delivery</span>
                  }
                  {order.orderTypeId === 2 &&
                    <span>Pickup</span>
                  }
                </div>
                <div className='col-8'>: {general.getFormattedTime(deliverySlot.startTime)} - {general.getFormattedTime(deliverySlot.endTime)}</div>
              </div>
            }
            {order.orderTypeId === 1 &&
              <div className='row pt-1'>
                <div className='col-4'>Delivery Address</div>
                <div className='col-8'>: {address.fullAddress}</div>
              </div>
            }
            {order.orderTypeId === 2 &&
              <div className='row pt-1'>
                <div className='col-4'>Pickup Address</div>
                <div className='col-8'>: {order.stallAddress}</div>
              </div>
            }
          </div>
        </div>
      </div>


      {/* Mobile View */}
      <div className='mobileview'>
        <div className='mx-2 my-3 ml-0'>
          <div className='summary-detail font-13 mb-3'>
            <div className='row'>
              <div className='col-5'>Order #</div>
              <div className='col-7'>: {order.orderIdentifier}</div>
            </div>
            <div className='row pt-1'>
              <div className='col-5'>Ordered Date</div>
              <div className='col-7'>: {general.getFormattedDate(order.placedDate)}</div>
            </div>
            {orderPayments.map((item: any) => (
              <div>
                <div className='row pt-1'>
                  <div className='col-5'>
                    {item.paymentMode != 'coupon' &&
                      <span>Order total</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Coupon Value</span>
                    }
                  </div>
                  <div className='col-7'>: <FxCurrency value={item.amount} /></div>
                </div>
                <div className='row pt-1'>
                  <div className='col-5'>Payment Mode</div>
                  <div className='col-7'>
                    <span>: </span>
                    <span>
                      {item.paymentMode == 'cod' &&
                        <span className="pr-1">Cash</span>
                      }
                      {item.paymentMode == 'razorpay' &&
                        <span className="pr-1">Card</span>
                      }
                      {item.paymentMode != 'razorpay' && item.paymentMode != 'cod' &&
                        <span className="pr-1 payment-style">{item.paymentMode}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className='row pt-1'>
                  <div className='col-5'>
                    {item.paymentMode != 'coupon' &&
                      <span>Payment Status</span>
                    }
                    {item.paymentMode == 'coupon' &&
                      <span>Discount Status</span>
                    }
                  </div>
                  <div className='col-7'>
                    : <span>{item.paymentStatus}
                      {item.paymentMode == 'razorpay' && (item.paymentStatus == 'Pending' || item == 'Initiated') &&
                        <span className='pl-1'>
                          <Link className="btn btn-address font-14" onClick={initiatePayment} title="pay">Pay</Link>
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {deliverySlot &&
              <div className='row pt-1'>
                <div className='col-5'>
                  {order.orderTypeId === 1 &&
                    <span>Delivery</span>
                  }
                  {order.orderTypeId === 2 &&
                    <span>Pickup</span>
                  }
                </div>
                <div className='col-7'>: {general.getFormattedTime(deliverySlot.startTime)} - {general.getFormattedTime(deliverySlot.endTime)}</div>
              </div>
            }
            {order.orderTypeId === 1 &&
              <div className='row pt-1'>
                <div className='col-5'>Delivery Address</div>
                <div className='col-7'>: {address.fullAddress}</div>
              </div>
            }
            {order.orderTypeId === 2 &&
              <div className='row pt-1'>
                <div className='col-5'>Pickup Address</div>
                <div className='col-7'>: {order.stallAddress}</div>
              </div>
            }
          </div>

          <div>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table className="table-striped" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >Items</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderLines.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell className="table-font pr-0">
                        {row.itemName}
                        <div className='font-10 extras-txt'>
                          {row.orderLineModifiers && row.orderLineModifiers.map((custom, i) => (
                            <div>
                              <span>{custom.key}-</span>
                              {custom.displayModifiers && custom.displayModifiers.map((detail, idx) => (
                                <span>{detail.description}
                                  {idx != (custom.displayModifiers.length - 1) &&
                                    <span>, </span>
                                  }
                                  {i != (row.orderLineModifiers.length - 1) &&
                                    <span>, </span>
                                  }
                                </span>
                              ))}
                            </div>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell className="table-font">
                        <span>{row.unit}</span>
                      </TableCell>
                      <TableCell align="right" className='px-0'>
                        <span className="table-font">
                          {row.itemQuantity} * <FxCurrency value={row.price} />
                          {row.lineModifierAmount > 0 &&
                            <span>+ <FxCurrency value={row.lineModifierAmount} /></span>
                          }
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={row.lineTotalPrice} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>

                  {order.deliveryCharge > 0 &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <span className="table-font">Delivery Charge</span>
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        <FxCurrency value={order.deliveryCharge} />
                      </span></TableCell>
                    </TableRow>
                  }

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <span className="table-font">Total</span>
                    </TableCell>
                    <TableCell align="right"><span className="table-font">
                      <FxCurrency value={order.orderTotal} />
                    </span></TableCell>
                  </TableRow>
                  {
                    currentContext.isShowCoupon &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Coupon Applied
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        <FxCurrency value={currentContext.couponApplied} />
                      </span></TableCell>
                    </TableRow>
                  }

                  {
                    currentContext.isShowCoupon &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Balance to Pay
                      </TableCell>
                      <TableCell align="right"><span className="table-font">
                        {currentContext.balanceToPay > 0 &&
                          <span><FxCurrency value={currentContext.balanceToPay} /></span>
                        }
                        {!currentContext.balanceToPay &&
                          <span><FxCurrency value={currentContext.balanceToPay} /><sup>00</sup>
                          </span>
                        }
                      </span></TableCell>
                    </TableRow>
                  }

                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default MyOrderDetail;