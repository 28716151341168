import React from 'react';
import moment from 'moment';
import { Constants } from '../helpers/constants';

export const FxDateTimeDisplayLocal = (props: any) => {
    const getDateTimeDisplay = () => {
        let inputDateVal = props.value;
        let displayType = props.displayType;

        if (inputDateVal) {
            let inputDate = getLocalDateTimeFromUTC(inputDateVal);
            if (displayType == 'date') {
                const result = moment(inputDate).format(Constants.DateFormat);
                return result;
            }
            if (displayType == 'datetime') {
                const result = moment(inputDate).format(Constants.DisplayDateTimeFormat);
                return result;
            }
            if (displayType == 'time') {
                const result = moment(inputDate, Constants.DateTimeFormat).format(Constants.TimeFormat12Hour);
                return result;
            }
        }
    }

    const getLocalDateTimeFromUTC = (inputDate:any) => {
        var result = {};
        if(inputDate) {
            let temp = moment.utc(inputDate).local();
            result = temp.format(Constants.DateTimeFormat);
        }
        return result;
      }

      
    return (
        <span>
            {getDateTimeDisplay()}
        </span>
    );
};