import React, { useEffect, useState } from 'react';
import { http, formatService, FxDateTimeDisplay, FxDateDisplay, general, localstorage } from '../fx-core';
import './LiveStreamDetail.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import noImage from "../../assets/images/no-image.png";
import Iframe from 'react-iframe';
import { AppSettings } from '../utils/AppSettings';
import LiveStreamCard from '../LiveStreamCard/LiveStreamCard';
import LiveStreamCardMobile from '../LiveStreamCardMobile/LiveStreamCardMobile';
import { Drawer, makeStyles } from '@material-ui/core';
import LiveStreamOrder from '../LiveStreamOrder/LiveStreamOrder';

const styles = makeStyles((theme) => ({
  drawerWidth: {
    [theme.breakpoints.down(599)]: {
      width: '90%'
    },
    [theme.breakpoints.up(600)]: {
      width: '70%'
    },
    [theme.breakpoints.up(1025)]: {
      width: '40%'
    }
  }
}));

const LiveStreamDetail = (props) => {
  const classes = styles();
  const uid = localstorage.getItem('userId');
  const userId = (uid && uid != null) ? parseInt(uid) : 0;
  const defaultItem = {
    liveStreamName: "",
    description: "",
    liveStreamDate: "",
    duration: null,
    startTime: "",
    endTime: "",
    stallName: "",
    stallItemName: "",
    imageUrl: "",
    stallItemId: 0,
    stallItemImage: "",
    stallId: 0
  };
  const [liveStreamHex, setLiveStreamHex] = useState(props.match.params.lh);
  const [name, setname] = useState(props.match.params.name);

  const [item, setItem] = useState(defaultItem);
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);

  const getLiveStreamById = () => {
    let apiOptions: any = {
      url: `livestream/hex/${liveStreamHex}`
    };
    http.get(apiOptions).then(res => {
      let result = res.data;
      result.startTime = formatService.getConvertedTimeForDisplay(result.startTime);
      result.endTime = formatService.getConvertedTimeForDisplay(result.endTime);
      result.imageUrl = general.getImageUrl(result.stallImagePath);
      result.stallItemImage = general.getImageUrl(result.stallItemImagePath);
      let userId = parseInt(localstorage.getItem('userId'));
      let participantType = userId == result.hostUserId ? "host" : "na";
      let username = name ? name : localstorage.getItem('username');
      let url = `https://commkit-lv.web.app/join/live${result.id}/${username}/${participantType}/${liveStreamHex}`;
      setUrl(url);
      setItem(result);
    });
  };

  const openOrder = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    getLiveStreamById();
  }, []);

  const iframeProps: any = { display: "initial" };

  function resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }

  return (
    <div className='livestream-detail'>
      <div className='deskview'>
        <div className="px-2 pt-3 pb-1">
          <LiveStreamCard liveStreamObj={item} openOrder={openOrder} userId={userId} />
        </div>
        <div className="row mx-4 px-3">
          {url && <div className="col-12 iframe-container justify-content-center">
            <Iframe url={url}
              width="100%"
              id="myId"
              className="myClassname"
              {...iframeProps}
              allow="camera *;microphone *"
              position="relative"
              frameBorder={0} />
          </div>}
        </div>
      </div>

      <div className='mobileview'>
        <div>
          <span className='justify-content-center d-flex font-weight-bold font-20 pt-2'>{item.liveStreamName}</span>
        </div>
        <div className='mx-1 px-1'>
          {url && <div className="iframe-container justify-content-center">
            <Iframe url={url}
              width="100%"
              id="myId"
              className="myClassname"
              {...iframeProps}
              allow="camera *;microphone *"
              position="relative"
              frameBorder={0} />
          </div>}
        </div>
        <div className="px-1 pb-3 pb-1">
          <LiveStreamCardMobile liveStreamObj={item} openOrder={openOrder} userId={userId} />
        </div>
      </div>
      <Drawer anchor={"right"} classes={{ paper: classes.drawerWidth }} open={open} onClose={handleClose}>
        <LiveStreamOrder stallId={item.stallId} stallItemId={item.stallItemId} handleClose={handleClose} />
      </Drawer>
    </div>
  );
}
export default LiveStreamDetail;
