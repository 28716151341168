import React, { useState } from 'react';
import './ChildCareServiceCard.scss';
import Card from '@material-ui/core/Card';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import dummyImage from "./../../assets/images/dummy.png";
import { FxDateDisplay, FxCurrency, FxDateTimeDisplay } from '../fx-core';
import DateIcon from '@material-ui/icons/DateRange';
import verifyTick from "./../../assets/img/verifytick.png";

const ChildCareServiceCard = (props: any) => {

    return (
        <div className="ccservice-ui">
            {/* Desktop View */}
            <div className="deskview pt-5">
                <Card style={{ width: "350px", height: "380px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
                    <div className="row justify-content-center">
                        {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="150" height="150" className="itemimage"></img>}
                        {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="150" height="150" className="itemimage"></img>}
                    </div>
                    <div>
                        <div className="font-22 itemname-style pl-3">
                            {props.item.stallName}
                            {
                                props.item.isVerified &&
                                <span className='ml-1'>
                                    <img src={verifyTick} width="20" height="20" />
                                </span>
                            }
                            <span className="pl-2 float-right">
                                <FxCurrency value={props.item.charges} />
                            </span>
                        </div>
                        <div className="row pl-4 justify-content-between pr-3 font-13">
                            <div className="pl-2">
                                <span> <DateIcon fontSize="inherit" />
                                    <FxDateDisplay value={props.item.fromDate} />
                                    <p>
                                        <FxDateTimeDisplay value={props.item.startTime} displayType="time" />
                                    </p>
                                </span>
                            </div>
                            <div>
                                <span>
                                    <span> <DateIcon fontSize="inherit" /></span>
                                    <FxDateDisplay value={props.item.toDate} />
                                    <p className="pl-2">
                                        <FxDateTimeDisplay value={props.item.endTime} displayType="time" />
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="font-14 row pl-4 pr-3">
                        {props.item.stallAddress}
                    </div>
                    <div className="row align-items-center pl-4 font-13 pt-1">
                        <span><i className="fas fa-phone-alt"></i></span>
                        {props.item.providerMobile &&
                            <span className="pl-1 font-12">{props.item.providerMobile}</span>
                        }
                        {!props.item.providerMobile &&
                            <span className="pl-1">-----</span>
                        }
                    </div>
                    <div className="row align-items-center pl-4 font-13 pt-1">
                        <span><i className="fas fa-envelope"></i></span>
                        {props.item.providerEmail &&
                            <span className="pl-1 font-12">{props.item.providerEmail}</span>
                        }
                        {!props.item.providerEmail &&
                            <span className="pl-1">-----</span>
                        }
                    </div>
                    <div className="row align-items-center pl-4 font-13 pt-1">
                        <span><i className="fas fa-globe"></i></span>
                        {props.item.website &&
                            <span className='pl-1 font-12'>{props.item.website}</span>
                        }
                        {!props.item.website &&
                            <span className='pl-1'>-----</span>
                        }
                    </div>
                </Card>
            </div>

            {/* Tablet View */}
            <div className="tabview pt-5">
                <Card style={{ height: "200px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
                    <div className="row pl-1">
                        <div className="col-3">
                            {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                            {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
                        </div>
                        <div className="col-9 pl-5 d-flex flex-column justify-content-between">
                            <div className="font-16 itemname-style text-left">
                                {props.item.stallName}
                                {
                                    props.item.isVerified &&
                                    <span className='ml-1'>
                                        <img src={verifyTick} width="20" height="20" />
                                    </span>
                                }
                            </div>
                            <div className="text-left itemname-style">
                                <FxCurrency value={props.item.charges} />
                            </div>
                            <div className="row font-12 justify-content-between pl-3 pr-3">
                                <div>
                                    <span> <DateIcon fontSize="inherit" />
                                        <FxDateDisplay value={props.item.fromDate} />
                                        <p>
                                            <FxDateTimeDisplay value={props.item.startTime} displayType="time" />
                                        </p>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <DateIcon fontSize="inherit" />
                                        <FxDateDisplay value={props.item.toDate} />
                                        <p className="pl-2">
                                            <FxDateTimeDisplay value={props.item.endTime} displayType="time" />
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="font-13 row pl-4 text-left">
                        {props.item.stallAddress}
                    </div>
                    <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
                        <div className="row pl-3">
                            <span> <PhoneIcon fontSize="inherit" /></span>
                            <span className="pl-1">{props.item.providerMobile}</span>
                        </div>
                        <div className="row px-0">
                            <span> <EmailIcon fontSize="inherit" /></span>
                            <span className="pl-1">{props.item.providerEmail}</span>
                        </div>
                    </div>
                </Card>
            </div>

            {/* Mobile View */}
            <div className="mobileview pt-2">
                <Card style={{ height: "200px" }} className="d-flex flex-column justify-content-between px-2 py-3 pointer">
                    <div className="row pl-1">
                        <div className="col-3">
                            {!props.item.imageUrl && <img src={dummyImage} alt="Item" width="100" height="100" className="itemimage"></img>}
                            {props.item.imageUrl && <img src={props.item.imageUrl} alt="Item" width="100" height="100" className="itemimage"></img>}
                        </div>
                        <div className="col-9 pl-4 d-flex flex-column justify-content-between">
                            <div className="font-16 itemname-style text-left">
                                {props.item.stallName}
                                {
                                    props.item.isVerified &&
                                    <span className='ml-1'>
                                        <img src={verifyTick} width="20" height="20" />
                                    </span>
                                }
                            </div>
                            <div className="text-left itemname-style">
                                <FxCurrency value={props.item.charges} />
                            </div>
                            <div className="row font-11 justify-content-between pl-3">
                                <div>
                                    <span> <DateIcon fontSize="inherit" />
                                        <FxDateDisplay value={props.item.fromDate} />
                                        <p>
                                            <FxDateTimeDisplay value={props.item.startTime} displayType="time" />
                                        </p>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <DateIcon fontSize="inherit" />
                                        <FxDateDisplay value={props.item.toDate} />
                                        <p className="pl-2">
                                            <FxDateTimeDisplay value={props.item.endTime} displayType="time" />
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="font-13 row pl-4 text-left">
                        {props.item.stallAddress}
                    </div>
                    <div className="row pr-4 mr-1 pl-4 font-13 justify-content-between">
                        <div className="row pl-3">
                            <span> <PhoneIcon fontSize="inherit" /></span>
                            <span className="pl-1">{props.item.providerMobile}</span>
                        </div>
                        <div className="row px-0">
                            <span> <EmailIcon fontSize="inherit" /></span>
                            <span className="pl-1">{props.item.providerEmail}</span>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}
export default ChildCareServiceCard;