import React, { useEffect, useState } from 'react';
import { FxInput, http, LocationSearchInput, AlertService, formValidator, general, localstorage, FxSelect, FxTextarea } from '../../fx-core';
import './CommunityProfile.scss';
import * as _ from 'lodash';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import dummyImage from "../../../assets/images/dummy.png";
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import { Button, Dialog, Typography } from '@material-ui/core';

const CommunityProfile = (props) => {
  const cid = localstorage.getItem("communityId");
  const defaultItem = {
    profileImagePath: "",
    profileImageUrl: "",
    name: "",
    code: "",
    address: "",
    id: (cid && cid != null) ? parseInt(cid) : 0,
    communityTypeId: 1,
    accountNumber: "",
    accountName: ""
  };

  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [codeValidation, setCodeValidation] = useState(false);
  const [communityList, setCommunityList] = useState([]);
  const [selCommunityId, setSelCommunityId] = useState(defaultItem.id);

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
    item.validations = getInputValidations(item.name);
    const validationResult = formValidator.validate(item);
    const validationData = { [item.name]: validationResult };
    updateErrors(validationData);
  }

  const handleCommunityChange = (item) => {
    let id = item.value;
    setSelCommunityId(id);
    getSelCommunityInfo(id);
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  //Validation area starts
  const getValidations = () => {
    let validationMap = {
      code: ["required"],
      name: ["required"]
    }
    return validationMap;
  }

  const getValidationsWithAcc = () => {
    let validationMap = {
      code: ["required"],
      name: ["required"],
      accountNumber: ["required", "accnoformat"],
      accountName: ["required"]
    }
    return validationMap;
  }

  const getInputValidations = (name) => {
    let validationMap = getValidations();
    if (item.communityTypeId == 2) {
      validationMap = getValidationsWithAcc();
    }
    return validationMap[name];
  }

  const bulkValidate = () => {
    let items: any = [];
    let validationMap = getValidations();
    if (item.communityTypeId == 2) {
      validationMap = getValidationsWithAcc();
    }
    for (var key in validationMap) {
      let result = { name: key, value: item[key], validations: validationMap[key] }
      items.push(result);
    }
    let validationResult = formValidator.bulkValidate(items);
    updateErrors(validationResult.errors);
    return validationResult.isValid;
  }

  const updateErrors = (validationData) => {
    setErrors(prevState => {
      return { ...prevState, ...validationData }
    });
  }

  const hasError = (field, validationMethod) => {
    return (
      errors &&
      errors[field] &&
      errors[field][validationMethod]
    );
  };
  //Validation area ends

  const getCommunityById = () => {
    let apiOptions: any = {
      url: `community/${item.id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.profileImageUrl = general.getImageUrl(result.profileImagePath);
      setItem(result);
    })
  }

  const addressSelected = (data) => {
    let inputData = { address: data.address, lat: data.lat, lng: data.lng };
    handleItemChange(inputData);
  };

  const patternValidation = (val) => {
    return /\s/g.test(val);
  };

  const saveCommunity = () => {
    setCodeValidation(false);
    const isValid = bulkValidate();
    if (isValid) {
      let codeVal = patternValidation(item.code);
      if (codeVal) {
        setCodeValidation(true);
        return;
      }
      let apiOptions: any = {
        url: `community`,
        data: item
      };
      http.post(apiOptions).then(res => {
        saveCommunityCallBack(res.data);
      })
    }
  };

  const saveCommunityCallBack = (res) => {
    AlertService.showSuccessMsg();
    getCommunityById();
  }

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: item.id,
      cameraConfig: { url: `community/savecameraimage` },
      fileUploadConfig: { url: `community/savefileimage` }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    if (item.id > 0) {
      getCommunityById();
    }
    else {
      let filePath = res.filePath;
      let imagePath = general.getImageUrl(filePath);
      handleItemChange({ "profileImagePath": filePath });
      handleItemChange({ "profileImageUrl": imagePath });
    }
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
  }

  const getSelCommunityInfo = (id) => {
    let apiOptions: any = {
      url: `community/${id}`
    };
    http.get(apiOptions).then(res => {
      const result = res.data;
      result.profileImageUrl = general.getImageUrl(result.profileImagePath);
      setItem(result);
    })
  }

  const getUserRoleCommunities = () => {
    let apiOptions: any = {
      url: 'community/role/list',
      data: {}
    };
    http.post(apiOptions).then(res => { getUserRoleCommunitiesCallBack(res) });
  };

  const getUserRoleCommunitiesCallBack = (res) => {
    let communityList = res.data;
    setCommunityList(communityList);
  };

  useEffect(() => {
    getUserRoleCommunities();
    getCommunityById();
  }, []);


  return (
    <div className='communityprofile-ui pt-5'>
      <div className="title-font custom-head pl-4 pt-5">
        <Typography color="primary" className="breadcrumbs-style">Community Profile</Typography>
      </div>
      <div className='col-12 row justify-content-center'>
        <div className='col-3'></div>
        <div className='col-6'>
          <div className='p-3 pt-5'>
            <FxSelect name="communityId" variant="outlined"
              label="Community"
              options={communityList}
              selectedValue={selCommunityId}
              valueField="id"
              displayField="name"
              size="small"
              onValueChange={handleCommunityChange} />
          </div>
          <div className='p-3 pt-5'>
            <div className='row p-3'>
              <div className="col-2 text-center">
                <div>
                  <a className="pointer">
                    {!item.profileImageUrl && <img src={dummyImage} alt="dummy" width={100} height={100}></img>}
                    {item.profileImageUrl && <img src={item.profileImageUrl} alt="prod" width={100} height={100}></img>}
                  </a>
                  <div className="text-center camera-align" onClick={openPhotoCapture}>
                    <CameraAltIcon className='pointer' />
                  </div>
                </div>
              </div>
              <div className='col-12 p-3'>
                <FxInput name="code" variant="outlined" fullWidth size="small" label="Code"
                  value={item.code} onValueChange={handleInputChange} disabled={item.id > 0} />
                {hasError("code", "required") &&
                  <div className="error">Code is required</div>
                }
                {codeValidation &&
                  <div className="error">Code should not contain space</div>
                }
              </div>
              <div className='col-12 p-3'>
                <FxInput name="name" variant="outlined" fullWidth size="small" label="Name"
                  value={item.name} onValueChange={handleInputChange} />
                {hasError("name", "required") &&
                  <div className="error">Name is required</div>
                }
              </div>

              {/* <div className='col-12 p-3'>
                {(item.id > 0 && item.address) &&
                  <LocationSearchInput address={item.address} name="address"
                    fullWidth locationSelected={addressSelected} placeholder="Address" />}

                {(item.id == 0 || !item.address) && <LocationSearchInput address={item.address} name="address"
                  fullWidth locationSelected={addressSelected} placeholder="Address" />}
              </div> */}

              <div className='col-12 p-3'>
                <FxTextarea name="address" variant="outlined" fullWidth size="small" label="Address"
                  value={item.address} onValueChange={handleInputChange} maxRows={4} />
              </div>
            </div>
            {
              item.communityTypeId == 2 &&
              <div className='col-12'>
                <div>
                  <FxInput name="accountNumber" variant="outlined" label="Account Number" fullWidth required={true} type="number"
                    value={item.accountNumber} onValueChange={handleInputChange} />
                  {hasError("accountNumber", "required") && (
                    <div className="error">Account Number is required</div>
                  )}
                </div>
                <div className='pt-4'>
                  <FxInput name="accountName" variant="outlined" label="Account Name" fullWidth required={true}
                    value={item.accountName} onValueChange={handleInputChange} />
                  {hasError("accountName", "required") && (
                    <div className="error">Account Name is required</div>
                  )}
                </div>
              </div>
            }
          </div>
          <div className='px-4 py-2'>
            <div className='px-4 row'>
              <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveCommunity}><b>Save</b></Button>
            </div>
          </div>
        </div>
        <div className='col-3'></div>
      </div>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
    </div>
  );
}
export default CommunityProfile;
