import React, { useEffect, useState } from 'react';
import './LocalDrCard.scss';
import LocalDrList from './LocalDrList/LocalDrList';

const LocalDrCard = (props) => {
  const [communityCode, setCommunityCode] = useState("");
  
  useEffect(() => {
    let code = props.match.params.code ? props.match.params.code : "";
    setCommunityCode(code)
  },[]);

  return (
    <div className='pt-5'>
      <LocalDrList communityCode={communityCode} />
    </div>
  );
}
export default LocalDrCard;